export const DriverProficiencyQualification = [
  {
    "label": "AA Proficiency",
    "value": "2"
  },
  {
    "label": "Accelerated Access Scheme",
    "value": "K"
  },
  {
    "label": "Advanced Driving Test",
    "value": "4"
  },
  {
    "label": "Armed Forces Driver Training",
    "value": "O"
  },
  {
    "label": "Direct Access Scheme",
    "value": "N"
  },
  {
    "label": "DSA Certificate Of Competence (Enhanced Rider Bonus)",
    "value": "I"
  },
  {
    "label": "Institute Of Advance Motorists Etc",
    "value": "1"
  },
  {
    "label": "Motorcycle - Advanced Police Motorcyclists",
    "value": "7"
  },
  {
    "label": "Motorcycle - British MC Fed Blue Riband",
    "value": "L"
  },
  {
    "label": "Motorcycle - British MC Fed Cert of Proficiency",
    "value": "M"
  },
  {
    "label": "Motorcycle - Compulsory Basic Training",
    "value": "8"
  },
  {
    "label": "Motorcycle - CSM Rider Training Stage 1",
    "value": "H"
  },
  {
    "label": "Motorcycle - CSM Rider Training Stage 2",
    "value": "J"
  },
  {
    "label": "Motorcycle - Driving Standards Agency Examiners Motorcycle Test",
    "value": "9"
  },
  {
    "label": "Motorcycle - Institute Of Advanced Motorists - Motorcycle Test",
    "value": "A"
  },
  {
    "label": "Motorcycle - Motag Advanced Rider Training Course",
    "value": "Y"
  },
  {
    "label": "Motorcycle - Motag Instructors Course",
    "value": "Z"
  },
  {
    "label": "Motorcycle - Motorcycle Rider Training Association",
    "value": "U"
  },
  {
    "label": "Motorcycle - National MC Training Scheme Gold",
    "value": "P"
  },
  {
    "label": "Motorcycle - Other",
    "value": "Q"
  },
  {
    "label": "Motorcycle - Theory Test",
    "value": "B"
  },
  {
    "label": "Motorcycle - Vision MC Training Advanced Rider - Gold",
    "value": "V"
  },
  {
    "label": "Motorcycle - Vision MC Training Advanced Rider - Silver",
    "value": "W"
  },
  {
    "label": "Motorcycle - Vision MC Training Advanced Rider Bronze",
    "value": "T"
  },
  {
    "label": "Motorcycle -ROSPA Test Gold Standard",
    "value": "G"
  },
  {
    "label": "Motorcylce - National MC Training Scheme Silver",
    "value": "R"
  },
  {
    "label": "NDT Driver Training",
    "value": "C"
  },
  {
    "label": "No Code Available",
    "value": "6"
  },
  {
    "label": "Pass Plus",
    "value": "3"
  },
  {
    "label": "RAC Driver Training",
    "value": "X"
  },
  {
    "label": "Registered Under Part 5 Road Traffic Act",
    "value": "5"
  },
  {
    "label": "ROSPA Advanced Driving Test",
    "value": "D"
  },
  {
    "label": "ROSPA Defensive Driving Course",
    "value": "E"
  },
  {
    "label": "ROSPA Driver Training",
    "value": "F"
  },
  {
    "label": "The Edge Initiative",
    "value": "S"
  }
]