import { get } from "lodash";
import { IStringIndex, TOperation, TFormField } from "./types";

export const isOperation = (obj: any) => {
  return typeof obj === "object" && typeof obj?.operator === "string" && Array.isArray(obj?.values);
};

export const getNumberValue = (field: TFormField, n: any, formValues: IStringIndex<any>) => {
  if (n === undefined || typeof n === "number") {
    return n;
  }
  let result;
  if (isOperation(n)) {
    result = getOperationResult(n as TOperation, formValues);
  } else if (typeof n === "string") {
    result = get(formValues, n, 0);
  }
  if (typeof result === "number") {
    if (field.variant === "float") {
      result = Number(result.toFixed(2));
    } else {
      result = Math.floor(result);
    }
  }
  return result ?? 0;
};

const populateNumberArray = (numbers: any[], formValues: IStringIndex<any>) => {
  const results: number[] = [];
  for (let n of numbers) {
    if (typeof n === "number") {
      results.push(n);
    }
    if (isOperation(n)) {
      results.push(getOperationResult(n, formValues));
    }
    // Should be a JSON path / field name pointing to a number value
    if (typeof n === "string") {
      const fieldValue = get(formValues, n, undefined);
      if (typeof fieldValue === "number") {
        results.push(fieldValue);
      }
    }
  }
  return results;
};

export const getOperationResult = (operation: TOperation, formValues: IStringIndex<any>): any => {
  const { operator, values } = operation;
  const numberArray = populateNumberArray(values, formValues);
  if (numberArray.length !== values.length) {
    return 0;
  }
  switch (operator) {
    case "min": {
      return Math.min(...numberArray);
    }
    case "max": {
      return Math.max(...numberArray);
    }
    case "*": {
      return numberArray.reduce((prev, current) => prev * current);
    }
    case "/": {
      return numberArray.reduce((prev, current) => prev / current);
    }
    case "+": {
      return numberArray.reduce((prev, current) => prev + current);
    }
    case "-": {
      return numberArray.reduce((prev, current) => prev - current);
    }
  }
};
