import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import { TFormField, TJourneyConfig } from "../../types"
import { ExpandMore } from "@mui/icons-material"
import { HTMLField } from "./HTMLField"

export const AccordionField = (props: {
    field: TFormField,
    config: TJourneyConfig,
}) => {
    return (
        <div>
         {props?.field?.fields?.map((segment, idx) => (
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls={props.field.name + "-panel-" + idx}
                    id={props.field.name + "-panel-" + idx}
                >
                    <Typography>{segment.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <HTMLField field={segment} />
                    </Typography>
                </AccordionDetails>
            </Accordion>          
            ))} 
        </div>
    )
}