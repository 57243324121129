// =================================================================================================
// This is basically a radio button. Can select one option from a group.
// =================================================================================================

import { Button, Checkbox, FormControlLabel, Stack, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { getFieldOptions } from '../../formHandling';
import { JourneyContext } from '../../JourneyContext';
import { TFormField, TFieldChangeHandler, TJourneyConfig } from '../../types';
import moment from 'moment';

// =================================================================================================
// Main component
// =================================================================================================

export const OptionField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
  config: TJourneyConfig;
}) => {
  const theme = useTheme();
  const { field, value, changeHandler, config } = props;
  const [basisOfCover, setBasisOfCover] = useState<any>();
  const journeyContext = useContext(JourneyContext);
  // Options can either be an array of strings, or a { label: string, value: any } object
  const localOptions = getFieldOptions(field, journeyContext.formData ?? { values: {}, validations: {} });

  const localChangeHandler = (newValue: any) => {
    if (!field.required && localOptions !== undefined) {
      for (let o of localOptions) {
        if (o.value === value) {
          changeHandler(field, undefined);
          return;
        }
      }
    }
    changeHandler(field, newValue);
  };

  // useEffect(() => {
  //   const yearOfManufacture = journeyContext?.formData?.values?.yearOfManufacture
  //   let responseFromBasisOfCover;
  //   if(yearOfManufacture){
  //     responseFromBasisOfCover = basisForCoverLogic(yearOfManufacture)
  //     if(!responseFromBasisOfCover){
  //       setBasisOfCover(undefined)
  //     }
  //     setBasisOfCover(responseFromBasisOfCover)
  //     localChangeHandler(responseFromBasisOfCover)
  //   }
  // },[journeyContext?.formData?.values?.yearOfManufacture])

  // -----------------------------------------------------------------------------------------------
  // Render helpers
  // -----------------------------------------------------------------------------------------------

  const renderButtons = () => {
    return (
      <Stack direction="row" alignItems="center" spacing={0} className="optionField" gap={2} flexWrap="wrap">
        {localOptions.map((option, index) => {
          return config?.renderVersion === 'V2' ? (
            <Button
              className="optionButton"
              color={value === option.value ? 'secondary' : 'primary'}
              disabled={field.disabled || option.disabled}
              key={index}
              size="large"
              variant={value === option.value ? 'contained' : 'outlined'}
              sx={
                value === option.value
                  ? {
                      textTransform: 'none',
                      color: theme.palette.primary.main,
                      border: `1px solid ${field.disabled || option.disabled ? 'none' : theme.palette.primary.main}`,
                    }
                  : { textTransform: 'none',bgcolor:"white" }
              }
              onClick={() => localChangeHandler(option.value)}
            >
              {option.label || '(label missing)'}
            </Button>
          ) : (
            <Button
              className="optionButton"
              color={'primary'}
              disabled={field.disabled || option.disabled}
              key={index}
              size="large"
              variant={value === option.value ? 'contained' : 'outlined'}
              sx={{ textTransform: 'none' }}
              onClick={() => localChangeHandler(option.value)}
            >
              {option.label || '(label missing)'}
            </Button>
          );
        })}
      </Stack>
    );
  };

  const renderCheckboxes = () => {
    return (
      <Stack>
        {localOptions.map((option, index) => {
          return (
            <FormControlLabel
              disabled={field.disabled || option.disabled}
              key={index}
              control={
                <Checkbox
                  checked={(value && value.includes(option.value)) ?? false}
                  onChange={(event, checked) => {
                    const newValue = value ? [...value] : [];
                    if (checked) {
                      newValue.push(option.value);
                    } else {
                      const idx = newValue.findIndex((v) => v === option.value);
                      newValue.splice(idx, 1);
                    }
                    if (newValue.length === 0) {
                      changeHandler(field, undefined);
                    } else {
                      changeHandler(field, newValue);
                    }
                  }}
                />
              }
              label={option.label}
            />
          );
        })}
      </Stack>
    );
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------

  return field.variant === 'checkbox' ? renderCheckboxes() : renderButtons();
};

const basisForCoverLogic = (date: any) => {
  const firstDateParts = date.split('-');

  const firstDate: any = moment(`${firstDateParts[0]}, 01`);
  const currentDate = moment();

  const currentDiff1 = currentDate.diff(firstDate, 'years');
  console.log('curent', currentDiff1);

  if (currentDiff1 > 10) {
    return 'Market Value';
  }
};
