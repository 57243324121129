export const VehicleModification = [
  {
    "label": "A-Frame Fitment",
    "value": "240"
  },
  {
    "label": "Adapted For Disability",
    "value": "167"
  },
  {
    "label": "Additional Airbags Fitted",
    "value": "211"
  },
  {
    "label": "Additional Seating",
    "value": "69"
  },
  {
    "label": "Additional Windows",
    "value": "70"
  },
  {
    "label": "After-Market Exhaust System approved for road use",
    "value": "84"
  },
  {
    "label": "Air Conditioning",
    "value": "78"
  },
  {
    "label": "Air Filter",
    "value": "83"
  },
  {
    "label": "Alarms/Immobilisers/Tracking Devices",
    "value": "1"
  },
  {
    "label": "Alphadot",
    "value": "132"
  },
  {
    "label": "Altered Pedal Configuration",
    "value": "71"
  },
  {
    "label": "Animal/Dog Barriers (including Guards, Gates, Dividers and Cages)",
    "value": "239"
  },
  {
    "label": "Awning -  Upgraded",
    "value": "208"
  },
  {
    "label": "Awning - Added",
    "value": "207"
  },
  {
    "label": "Badges Indicating Increased Performance",
    "value": "48"
  },
  {
    "label": "Bar Ends",
    "value": "50"
  },
  {
    "label": "Belly Pan",
    "value": "85"
  },
  {
    "label": "Biodegradable Fuel",
    "value": "163"
  },
  {
    "label": "Bleed / Relief Valve",
    "value": "122"
  },
  {
    "label": "Blueprinting",
    "value": "3"
  },
  {
    "label": "Bluetooth Kit",
    "value": "210"
  },
  {
    "label": "Body Coloured Bumpers",
    "value": "171"
  },
  {
    "label": "Body Kit",
    "value": "49"
  },
  {
    "label": "Bonnet Bulge",
    "value": "4"
  },
  {
    "label": "Bored-out Engine",
    "value": "5"
  },
  {
    "label": "Braided Brake Hoses",
    "value": "86"
  },
  {
    "label": "Bull Bars",
    "value": "6"
  },
  {
    "label": "Bumpers - Alloy",
    "value": "212"
  },
  {
    "label": "Bumpers - Chrome",
    "value": "213"
  },
  {
    "label": "Bumpers - Steel",
    "value": "214"
  },
  {
    "label": "Cabriolet Conversion",
    "value": "169"
  },
  {
    "label": "Camera - In Car CCTV",
    "value": "238"
  },
  {
    "label": "Camper Van Conversion",
    "value": "232"
  },
  {
    "label": "Car Phones",
    "value": "7"
  },
  {
    "label": "Carbon Fibre Bonnet",
    "value": "125"
  },
  {
    "label": "Carburettor Changed",
    "value": "95"
  },
  {
    "label": "Catalytic Converter Removed",
    "value": "151"
  },
  {
    "label": "Chain Guards",
    "value": "51"
  },
  {
    "label": "Changing to a Convertible",
    "value": "126"
  },
  {
    "label": "Chassis Changes",
    "value": "172"
  },
  {
    "label": "Chipping - Engine Management System Changes",
    "value": "8"
  },
  {
    "label": "Chromed Engine",
    "value": "112"
  },
  {
    "label": "Climate Control",
    "value": "222"
  },
  {
    "label": "Cooling Fan - Added",
    "value": "225"
  },
  {
    "label": "Cooling Fan - Upgrade",
    "value": "226"
  },
  {
    "label": "Crash Bars",
    "value": "52"
  },
  {
    "label": "Crash Bungs",
    "value": "87"
  },
  {
    "label": "Cruise Control",
    "value": "217"
  },
  {
    "label": "Dashboard Changes",
    "value": "9"
  },
  {
    "label": "Datatag",
    "value": "133"
  },
  {
    "label": "De-Handled",
    "value": "128"
  },
  {
    "label": "De-Locked",
    "value": "97"
  },
  {
    "label": "De-Restrictor Kits",
    "value": "139"
  },
  {
    "label": "Debadged",
    "value": "145"
  },
  {
    "label": "Dress Up Kit",
    "value": "134"
  },
  {
    "label": "Driving Lights",
    "value": "11"
  },
  {
    "label": "Dual Controls",
    "value": "12"
  },
  {
    "label": "Dump Valve",
    "value": "104"
  },
  {
    "label": "DynoJet Stage 2 Kit",
    "value": "195"
  },
  {
    "label": "DynoJet Stage 3 Plus Kit",
    "value": "196"
  },
  {
    "label": "EcoTek Valve",
    "value": "106"
  },
  {
    "label": "Electric Conversion",
    "value": "246"
  },
  {
    "label": "Electronic Ignition",
    "value": "206"
  },
  {
    "label": "Emergency Services Blue Light",
    "value": "166"
  },
  {
    "label": "End Can",
    "value": "89"
  },
  {
    "label": "Engine Casing Guards",
    "value": "53"
  },
  {
    "label": "Engine Chip/Remap 00-10% inc BHP",
    "value": "197"
  },
  {
    "label": "Engine Chip/Remap 11-25% inc BHP",
    "value": "198"
  },
  {
    "label": "Engine Chip/Remap 26%+ inc BHP",
    "value": "199"
  },
  {
    "label": "Engine Tuned",
    "value": "98"
  },
  {
    "label": "Engraving - Custom",
    "value": "179"
  },
  {
    "label": "Exhaust System Changes - Cosmetic And Performance",
    "value": "156"
  },
  {
    "label": "Exhaust System Changes - Performance",
    "value": "157"
  },
  {
    "label": "External Racking",
    "value": "159"
  },
  {
    "label": "Fairings",
    "value": "54"
  },
  {
    "label": "Fibreglass Panels",
    "value": "154"
  },
  {
    "label": "Fixtures and Fittings (Campervan conversion)",
    "value": "244"
  },
  {
    "label": "Flared Wheelarches",
    "value": "14"
  },
  {
    "label": "Flared Wings",
    "value": "15"
  },
  {
    "label": "Fog Lamps",
    "value": "16"
  },
  {
    "label": "Foot Rests",
    "value": "193"
  },
  {
    "label": "Fork Braces",
    "value": "55"
  },
  {
    "label": "Forks - Extended",
    "value": "140"
  },
  {
    "label": "Forks - Other",
    "value": "192"
  },
  {
    "label": "Frame Modified",
    "value": "56"
  },
  {
    "label": "Front Dashcam",
    "value": "242"
  },
  {
    "label": "Front Spoiler/Airdam",
    "value": "17"
  },
  {
    "label": "Galvanised Chassis",
    "value": "234"
  },
  {
    "label": "Gearing Change",
    "value": "57"
  },
  {
    "label": "Grab Rails",
    "value": "58"
  },
  {
    "label": "Graphics-Customised Promotional/Advertising",
    "value": "176"
  },
  {
    "label": "Graphics/Decals - Decoration/Non-Advertising",
    "value": "177"
  },
  {
    "label": "Hand Controls",
    "value": "18"
  },
  {
    "label": "Handlebar Position Altered",
    "value": "59"
  },
  {
    "label": "Heated Hand Grips",
    "value": "90"
  },
  {
    "label": "High Level Brake Light",
    "value": "19"
  },
  {
    "label": "High Roof",
    "value": "168"
  },
  {
    "label": "Hoist",
    "value": "72"
  },
  {
    "label": "Hugger",
    "value": "91"
  },
  {
    "label": "Hybrid Conversion",
    "value": "247"
  },
  {
    "label": "Indicators - Added",
    "value": "203"
  },
  {
    "label": "Indicators - Uprated",
    "value": "202"
  },
  {
    "label": "Induction Kit (Non-Turbo)",
    "value": "107"
  },
  {
    "label": "Induction Kit (Turbo Vehicle)",
    "value": "108"
  },
  {
    "label": "Infra Red Controls",
    "value": "73"
  },
  {
    "label": "Intercooler - Bigger",
    "value": "110"
  },
  {
    "label": "Intercooler - Front Mount",
    "value": "152"
  },
  {
    "label": "Interior Changes",
    "value": "200"
  },
  {
    "label": "Internal Racking",
    "value": "158"
  },
  {
    "label": "Internal Shelving",
    "value": "160"
  },
  {
    "label": "Lambo Style Door Conversion",
    "value": "141"
  },
  {
    "label": "Larger Speakers",
    "value": "20"
  },
  {
    "label": "Light Cluster changed",
    "value": "99"
  },
  {
    "label": "Locking Wheel Nuts",
    "value": "21"
  },
  {
    "label": "Lowered Seat Rails",
    "value": "181"
  },
  {
    "label": "LPG Conversion",
    "value": "79"
  },
  {
    "label": "Luggage System/panniers",
    "value": "60"
  },
  {
    "label": "Magnetic/Removable Signs",
    "value": "80"
  },
  {
    "label": "Manifold",
    "value": "123"
  },
  {
    "label": "Miscellaneous Engine Alterations",
    "value": "22"
  },
  {
    "label": "Molassi/Polini Kit",
    "value": "182"
  },
  {
    "label": "Murals",
    "value": "23"
  },
  {
    "label": "Neon Lights",
    "value": "100"
  },
  {
    "label": "Nitrous Oxide Kit Fitted",
    "value": "61"
  },
  {
    "label": "Non-standard Engine",
    "value": "24"
  },
  {
    "label": "Non-standard Re-spray",
    "value": "25"
  },
  {
    "label": "Oil Pressure Gauge - Added",
    "value": "227"
  },
  {
    "label": "Oil Pressure Gauge - Upgrade",
    "value": "228"
  },
  {
    "label": "Other",
    "value": "999"
  },
  {
    "label": "Overdrive",
    "value": "224"
  },
  {
    "label": "Paint Protection Film",
    "value": "248"
  },
  {
    "label": "Paint Work - Non Standard",
    "value": "62"
  },
  {
    "label": "Paint Work - Race Replica",
    "value": "180"
  },
  {
    "label": "Painted Signs (Greater than 25% of side and door)",
    "value": "82"
  },
  {
    "label": "Painted Signs (Under 25% of side and door)",
    "value": "81"
  },
  {
    "label": "Parking Camera - Inside Vehicle",
    "value": "237"
  },
  {
    "label": "Parking Camera - Outside Vehicle",
    "value": "236"
  },
  {
    "label": "Parking Sensors",
    "value": "103"
  },
  {
    "label": "Petrol Tank Changes",
    "value": "190"
  },
  {
    "label": "Pop Up Roof - Added",
    "value": "220"
  },
  {
    "label": "Pop Up Roof - Upgrade",
    "value": "221"
  },
  {
    "label": "Power Boost Valve",
    "value": "109"
  },
  {
    "label": "Power Commander Kit",
    "value": "189"
  },
  {
    "label": "Push Button Start",
    "value": "153"
  },
  {
    "label": "Quick Shift Gear Lever",
    "value": "117"
  },
  {
    "label": "Radiator Bars",
    "value": "63"
  },
  {
    "label": "Rally Lights",
    "value": "26"
  },
  {
    "label": "Rear Dashcam",
    "value": "243"
  },
  {
    "label": "Rear Roof Spoiler",
    "value": "27"
  },
  {
    "label": "Rear Sets",
    "value": "135"
  },
  {
    "label": "Rear Spoiler/Aerofoil",
    "value": "28"
  },
  {
    "label": "Rear Valance",
    "value": "29"
  },
  {
    "label": "Refrigerated",
    "value": "164"
  },
  {
    "label": "Restrictor",
    "value": "183"
  },
  {
    "label": "Right Hand Drive Conversion",
    "value": "219"
  },
  {
    "label": "Road Conversion",
    "value": "184"
  },
  {
    "label": "Rocksliders",
    "value": "143"
  },
  {
    "label": "Roll Bars",
    "value": "204"
  },
  {
    "label": "Roll Cage",
    "value": "131"
  },
  {
    "label": "Roof Rack",
    "value": "165"
  },
  {
    "label": "Satellite Navigation Equipment",
    "value": "162"
  },
  {
    "label": "Scottoiler",
    "value": "92"
  },
  {
    "label": "Screen Changes",
    "value": "136"
  },
  {
    "label": "Seat Cowl",
    "value": "137"
  },
  {
    "label": "Seatbelt Harnesses",
    "value": "129"
  },
  {
    "label": "Seats - Custom",
    "value": "178"
  },
  {
    "label": "Seats - Removal",
    "value": "74"
  },
  {
    "label": "Seats - Replacement",
    "value": "30"
  },
  {
    "label": "Side Skirts/Sills",
    "value": "31"
  },
  {
    "label": "Side Steps",
    "value": "101"
  },
  {
    "label": "Sissy Bar",
    "value": "94"
  },
  {
    "label": "Slick Tyres",
    "value": "64"
  },
  {
    "label": "Snorkel",
    "value": "173"
  },
  {
    "label": "Solar Panels",
    "value": "245"
  },
  {
    "label": "Spoilers - Multiple",
    "value": "170"
  },
  {
    "label": "Sports Steering Wheel",
    "value": "32"
  },
  {
    "label": "Spot Lights",
    "value": "33"
  },
  {
    "label": "Stage 1 Head",
    "value": "118"
  },
  {
    "label": "Stage 2 Head",
    "value": "119"
  },
  {
    "label": "Stage 3 Head",
    "value": "120"
  },
  {
    "label": "Stage 4 Head",
    "value": "121"
  },
  {
    "label": "Standard Engine Replacement",
    "value": "34"
  },
  {
    "label": "Steering Change",
    "value": "65"
  },
  {
    "label": "Steering Damper",
    "value": "93"
  },
  {
    "label": "Streetfighter Kit",
    "value": "185"
  },
  {
    "label": "Strengthening Brackets",
    "value": "124"
  },
  {
    "label": "Stripes",
    "value": "35"
  },
  {
    "label": "Struct Brace",
    "value": "130"
  },
  {
    "label": "Sump Guard",
    "value": "215"
  },
  {
    "label": "Sunroof",
    "value": "36"
  },
  {
    "label": "Supercharging",
    "value": "37"
  },
  {
    "label": "Suspension - Airbag/Hydraulic",
    "value": "148"
  },
  {
    "label": "Suspension - Other",
    "value": "150"
  },
  {
    "label": "Suspension - Uprated/Lowered/Stiffened Springs & Dampers",
    "value": "149"
  },
  {
    "label": "Tail Lift",
    "value": "68"
  },
  {
    "label": "Tank Pads",
    "value": "66"
  },
  {
    "label": "Telematics Device",
    "value": "235"
  },
  {
    "label": "Throttle Bodies Conversion (Non-Turbo)",
    "value": "114"
  },
  {
    "label": "Throttle Bodies Conversion (Turbo)",
    "value": "115"
  },
  {
    "label": "Tinted Windows",
    "value": "75"
  },
  {
    "label": "Towbar",
    "value": "39"
  },
  {
    "label": "Transmission Changes",
    "value": "40"
  },
  {
    "label": "Transmission Guard",
    "value": "216"
  },
  {
    "label": "Trial Tyres",
    "value": "67"
  },
  {
    "label": "Truck Bed Tonneau Cover",
    "value": "241"
  },
  {
    "label": "TS/TS1 Kit",
    "value": "186"
  },
  {
    "label": "Turbo Boost Controller",
    "value": "147"
  },
  {
    "label": "Turbo Timer",
    "value": "105"
  },
  {
    "label": "Turbocharging",
    "value": "41"
  },
  {
    "label": "Twin Carb",
    "value": "96"
  },
  {
    "label": "Unleaded Fuel Conversion",
    "value": "209"
  },
  {
    "label": "Upgraded External Mirrors",
    "value": "174"
  },
  {
    "label": "Upgraded Front Grille",
    "value": "175"
  },
  {
    "label": "Upholstery Changes",
    "value": "42"
  },
  {
    "label": "Uprated Actuator",
    "value": "111"
  },
  {
    "label": "Uprated Anti-Roll Bars",
    "value": "142"
  },
  {
    "label": "Uprated Belt/Variater Kit",
    "value": "191"
  },
  {
    "label": "Uprated Brakes",
    "value": "43"
  },
  {
    "label": "Uprated Cam",
    "value": "116"
  },
  {
    "label": "Uprated Clutch",
    "value": "113"
  },
  {
    "label": "Uprated Fuel Injection",
    "value": "218"
  },
  {
    "label": "Uprated Fuel Pump",
    "value": "146"
  },
  {
    "label": "Uprated Head Lamps",
    "value": "223"
  },
  {
    "label": "Uprated Headlight Bulbs",
    "value": "127"
  },
  {
    "label": "Uprated Stereo Systems",
    "value": "44"
  },
  {
    "label": "Vinyl Wrap Bodywork",
    "value": "231"
  },
  {
    "label": "Water Injection System",
    "value": "144"
  },
  {
    "label": "Water Tank(s)",
    "value": "161"
  },
  {
    "label": "Wheel Spacers",
    "value": "102"
  },
  {
    "label": "Wheel Trims",
    "value": "45"
  },
  {
    "label": "Wheelchair Clamps/Straps",
    "value": "76"
  },
  {
    "label": "Wheelchair Lift/Ramp/Winch",
    "value": "77"
  },
  {
    "label": "Wheels - Alloy",
    "value": "2"
  },
  {
    "label": "Wheels - Carbon",
    "value": "188"
  },
  {
    "label": "Wheels - Magnesium",
    "value": "187"
  },
  {
    "label": "Wheels - Wire",
    "value": "201"
  },
  {
    "label": "Wider Tyres",
    "value": "46"
  },
  {
    "label": "Wider Wheels",
    "value": "47"
  },
  {
    "label": "Winch",
    "value": "205"
  },
  {
    "label": "Wipers - Added",
    "value": "229"
  },
  {
    "label": "Wipers - Upgrade",
    "value": "230"
  }
]