// This must not change during the life of the policy
export type TLifetimeId = {
  lifetimeId: string;
};

// We regard the combination of the following as a unique identifier
export type TDriverIdentifier = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
};

// Evans pointed out that vehicles come with unique identifiers out of the box
export type TVehicleIdentifier = {
  VIN: string;
};

export type THistoricDriver = TDriverIdentifier & TLifetimeId;
export type THistoricVehicle = TVehicleIdentifier & TLifetimeId;

export type THistoricIds = {
  drivers: THistoricDriver[];
  vehicles: THistoricVehicle[];
  lastId: string;
};

// let historicIds: THistoricIds = {
//   drivers: [],
//   vehicles: [],
//   lastId: "00000",
// };

const getNewId = (lastId: string) => {
  const idAsNumber = Number(lastId);
  return (idAsNumber + 1 + "").padStart(5, "0");
};

export const getUpdatedVehicleIds = (currentIds: THistoricIds, vehicle: TVehicleIdentifier) => {
  if (vehicle.VIN) {
    const newIds = { ...currentIds };
    const existingVehicle = currentIds.vehicles.find((v) => v.VIN === vehicle.VIN);
    if (!existingVehicle) {
      const newId = getNewId(currentIds.lastId);
      newIds.lastId = newId;
      newIds.vehicles.push({ ...vehicle, lifetimeId: newId });
      return newIds;
    }
  }
  return currentIds;
};

export const getUpdatedDriverIds = (currentIds: THistoricIds, drivers: TDriverIdentifier[]) => {
  const newIds = { ...currentIds };
  for (let driver of drivers) {
    const existingDriver = currentIds.drivers.find(
      (d) =>
        d.firstName.toUpperCase() === driver.firstName.toUpperCase() &&
        d.lastName.toUpperCase() === driver.lastName.toUpperCase() &&
        d.dateOfBirth === driver.dateOfBirth
    );
    if (!existingDriver) {
      const newId = getNewId(currentIds.lastId);
      newIds.lastId = newId;
      newIds.drivers.push({ ...driver, lifetimeId: newId });
    }
  }
  return newIds;
};
