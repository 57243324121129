export const NCBIntroEntitlementReason = [
  {
    "label": "Accelerated NCB",
    "value": "20"
  },
  {
    "label": "Agricultural Or Forestry Vehicle",
    "value": "17"
  },
  {
    "label": "Combination Of Experience (Only Used In NCB Segment)",
    "value": "1"
  },
  {
    "label": "Commercial Vehicle",
    "value": "2"
  },
  {
    "label": "Company Car - Business Only",
    "value": "21"
  },
  {
    "label": "Company Car - Including Social",
    "value": "22"
  },
  {
    "label": "Company Car Experience",
    "value": "3"
  },
  {
    "label": "Cross Holding",
    "value": "4"
  },
  {
    "label": "Family Member",
    "value": "5"
  },
  {
    "label": "Fleet",
    "value": "16"
  },
  {
    "label": "Free Insurance",
    "value": "6"
  },
  {
    "label": "Householder",
    "value": "7"
  },
  {
    "label": "Motor Cycle",
    "value": "8"
  },
  {
    "label": "Named Driver Experience",
    "value": "9"
  },
  {
    "label": "Other",
    "value": "23"
  },
  {
    "label": "Policy Interest Transfer",
    "value": "10"
  },
  {
    "label": "Policyholders Civil Partner",
    "value": "18"
  },
  {
    "label": "Previous Employers Declaration",
    "value": "19"
  },
  {
    "label": "Private Car Bonus",
    "value": "11"
  },
  {
    "label": "Private Hire",
    "value": "12"
  },
  {
    "label": "Public Hire",
    "value": "13"
  },
  {
    "label": "Second Car",
    "value": "15"
  },
  {
    "label": "Spouse Of Policyholder",
    "value": "14"
  }
]