import {
  Alert,
  AlertTitle,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { TAlert, TFieldChangeHandler, TFormField } from '../../types';
import validator from 'validator';

import { LoadingButton } from '@mui/lab';
import { validateBank } from '../../apiCalls';
import { Done } from '@mui/icons-material';
import CustomAlert from './CustomAlert';

export const doDirectDebitFieldValidation = (field: TFormField, value: any): boolean => {
  return value?.isValid === true || value?.paperMandate === true;
};

export const DirectDebitField = (props: { field: TFormField; value: any; changeHandler: TFieldChangeHandler }) => {
  const { field, value, changeHandler } = props;
  const theme = useTheme();

  const resetAll = () => {
    setAccountHolder('');
    setConfirmation(false);
    setDebitDate('');
    setSortCode('');
    setAccountNumber('');
    setAccountHolderError('');
    setAccountNumberError('');
    setSortCodeError('');
    setBankResponse(undefined);
    setLookupError(undefined);
  };

  const [accountHolder, setAccountHolder] = useState('');
  const [confirmation, setConfirmation] = useState(false);
  const [debitDate, setDebitDate] = useState('');
  const [sortCode, setSortCode] = useState('');
  const [accountNumber, setAccountNumber] = useState('');
  const [accountHolderError, setAccountHolderError] = useState('');
  const [accountNumberError, setAccountNumberError] = useState('');
  const [sortCodeError, setSortCodeError] = useState('');
  const [bankResponse, setBankResponse] = useState<any>(undefined);
  const [lookupError, setLookupError] = useState<TAlert | undefined>();
  const [paperMandate, setPaperMandate] = useState(false);

  const [busy, setBusy] = useState(false);

  const accountNumberChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeHandler(field, undefined);
    const value = event.target.value;
    setAccountNumber(value);
    if (value.length === 0) {
      setAccountNumberError('This field is required.');
    } else if (!validator.isNumeric(value) || value.length < 8) {
      setAccountNumberError('Please enter an 8-digit account number.');
    } else {
      setAccountNumberError('');
    }
  };

  const sortCodeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeHandler(field, undefined);
    const value = event.target.value;
    setSortCode(value);
    if (value.length === 0) {
      setSortCodeError('This field is required.');
    } else if (!validator.isNumeric(value) || value.length < 6) {
      setSortCodeError('Please enter the 6-digit sort code without hyphens, e.g. 123456.');
    } else {
      setSortCodeError('');
    }
  };

  const debitDateChanged = (event: SelectChangeEvent<string>) => {
    changeHandler(field, undefined);
    setDebitDate(event.target.value);
  };

  const confirmationChanged = (event: any, checked: boolean) => {
    changeHandler(field, undefined);
    setConfirmation(checked);
  };

  const accountHolderChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    changeHandler(field, undefined);
    setAccountHolder(event.target.value);
    if (event.target.value.trim().length === 0) {
      setAccountHolderError('This field is required.');
    } else {
      setAccountHolderError('');
    }
  };

  const renderLogos = () => {
    return (
      <Stack direction="row" justifyContent="space-between">
        <img src="img/NF/nf_logo.png" alt="National Friendly" width="160" />
        <img src="img/NF/dd_logo.png" alt="Direct Debit" height="40" width="100" />
      </Stack>
    );
  };

  const renderAddressDetails = () => {
    return (
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <div style={{ flex: 1 }}>
          <p>National Friendly</p>
          <p>11-12 Queen Square</p>
          <p>Bristol</p>
          <p>BS1 4NT</p>
        </div>
        <div style={{ flex: 1 }}>
          <p>Service User Number:</p>
          <p>677902</p>
          <p>Reference:</p>
        </div>
      </Stack>
    );
  };

  const renderCollectionDate = () => {
    if (field.params?.hideFields?.includes('collectionDate')) return <></>;

    if (field.variant === 'minimal') {
      return (
        <Stack spacing={2}>
          <p>Preferred direct debit collection date:</p>
          <FormControl style={{ flex: 1 }} disabled={busy || paperMandate} required>
            <InputLabel id="select-label">Day of month</InputLabel>
            <Select
              labelId="select-label"
              id="simple-select"
              label="Day of month"
              value={debitDate}
              onChange={debitDateChanged}
            >
              {[...Array(28).keys()].map((num) => (
                <MenuItem key={num + 1} value={num + 1}>
                  {num + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Stack>
      );
    }
    return (
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <p style={{ flex: 1 }}>Preferred direct debit collection date:</p>
        <FormControl style={{ flex: 1 }} size="small" disabled={busy || paperMandate} required>
          <InputLabel id="select-label">Day of month</InputLabel>
          <Select
            labelId="select-label"
            id="simple-select"
            label="Day of month"
            value={debitDate}
            onChange={debitDateChanged}
          >
            {[...Array(28).keys()].map((num) => (
              <MenuItem key={num + 1} value={num + 1}>
                {num + 1}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
    );
  };

  const renderConfirmation = () => {
    return (
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <div style={{ flex: 1 }}>
          I am the account holder and the only person needed to authorise direct debits on this account or if this is a
          company bank account I am authorised to set up new direct debits on this account and only one signatory is
          required.*
        </div>
        <Stack style={{ flex: 1 }}>
          <FormControlLabel
            onChange={confirmationChanged}
            disabled={busy || paperMandate}
            control={<Checkbox checked={confirmation} />}
            label="Yes"
            disableTypography={true}
          />
        </Stack>
      </Stack>
    );
  };

  const renderAccountHolderName = () => {
    if (field.variant === 'minimal') {
      return (
        <Stack spacing={2}>
          <p>Account holder's name:</p>
          <TextField
            required
            value={accountHolder}
            onChange={accountHolderChanged}
            error={accountHolderError.length > 0}
            helperText={accountHolderError}
            disabled={busy || paperMandate}
            label="Name"
            variant="outlined"
            fullWidth
          />
        </Stack>
      );
    }
    return (
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <div style={{ flex: 1 }}>Account holder's name:</div>
        <div style={{ flex: 1 }}>
          <TextField
            required
            value={accountHolder}
            onChange={accountHolderChanged}
            error={accountHolderError.length > 0}
            helperText={accountHolderError}
            disabled={busy || paperMandate}
            label="Name"
            variant="outlined"
            size="small"
            fullWidth
          />
        </div>
      </Stack>
    );
  };

  const renderAccountNumber = () => {
    if (field.variant === 'minimal') {
      return (
        <Stack spacing={2}>
          <p>Account number:</p>
          <TextField
            required
            disabled={busy || paperMandate}
            value={accountNumber}
            label="Number"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 8, style: { fontFamily: 'monospace', fontSize: 18 } }}
            onChange={accountNumberChanged}
            error={accountNumberError.length > 0}
            helperText={accountNumberError}
          />
        </Stack>
      );
    }
    return (
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <div style={{ flex: 1 }}>Account number:</div>
        <div style={{ flex: 1 }}>
          <TextField
            required
            disabled={busy || paperMandate}
            value={accountNumber}
            label="Number"
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{ maxLength: 8, style: { fontFamily: 'monospace', fontSize: 18 } }}
            onChange={accountNumberChanged}
            error={accountNumberError.length > 0}
            helperText={accountNumberError}
          />
        </div>
      </Stack>
    );
  };

  const renderSortCode = () => {
    if (field.variant === 'minimal') {
      return (
        <Stack spacing={2}>
          <p>Sort code:</p>
          <TextField
            required
            disabled={busy || paperMandate}
            value={sortCode}
            label="Code"
            variant="outlined"
            fullWidth
            inputProps={{ maxLength: 6, style: { fontFamily: 'monospace', fontSize: 18 } }}
            onChange={sortCodeChanged}
            error={sortCodeError.length > 0}
            helperText={sortCodeError}
          />
        </Stack>
      );
    }
    return (
      <Stack direction="row" justifyContent="space-between" spacing={1}>
        <div style={{ flex: 1 }}>Sort code:</div>
        <div style={{ flex: 1 }}>
          <TextField
            required
            disabled={busy || paperMandate}
            value={sortCode}
            label="Code"
            variant="outlined"
            size="small"
            fullWidth
            inputProps={{ maxLength: 6, style: { fontFamily: 'monospace', fontSize: 18 } }}
            onChange={sortCodeChanged}
            error={sortCodeError.length > 0}
            helperText={sortCodeError}
          />
        </div>
      </Stack>
    );
  };

  const renderDate = () => {
    return new Date().toLocaleDateString('en-GB');
  };

  const validateAccount = async () => {
    setLookupError(undefined);
    setBankResponse(undefined);
    setBusy(true);
    let response = await validateBank(sortCode, accountNumber);
    setBusy(false);
    if (response === null) {
      setLookupError({
        body: 'There was a problem validating your bank account, please wait a moment and try again.',
        severity: 'error',
      });
    } else {
      console.log(JSON.stringify(response));
      const isValid = response.Valid === 'Valid';
      if (isValid) {
        setBankResponse(response);
        changeHandler(field, {
          accountHolder: accountHolder.trim(),
          accountNumber,
          sortCode,
          debitDate,
          isValid,
          paperMandate,
        });
      } else {
        setLookupError({
          body: 'There was a problem validating your bank account, please make sure you have entered the correct details and try again.',
          severity: 'error',
        });
      }
    }
  };

  const allDetailsFilled = () => {
    return field.variant === 'minimal'
      ? accountHolder.trim().length > 0 && accountNumber.length === 8 && sortCode.length === 6
      : confirmation === true &&
          accountHolder.trim().length > 0 &&
          debitDate &&
          accountNumber.length === 8 &&
          sortCode.length === 6;
  };

  const renderLookupError = () => {
    return lookupError ? (
      <CustomAlert title={lookupError?.title} severity={lookupError.severity} onClose={() => setLookupError(undefined)}>
        {lookupError.body}
      </CustomAlert>
    ) : null;
  };

  const renderLookup = () => {
    if (field.variant === 'minimal') {
      const btnDisabled = busy || !allDetailsFilled() || paperMandate;
      const isVerified = value?.isValid && !btnDisabled;
      return (
        <Stack spacing={2}>
          {/* <p>Please complete the above fields and press the button below.</p> */}
          <LoadingButton
            startIcon={isVerified ? <Done color="primary" /> : null}
            sx={{
              bgcolor: 'white',
              color: theme?.palette?.primary?.main,
              marginLeft: 'auto',
              // paddingX:4,
              minWidth: 150,
              textTransform: 'none',
            }}
            loading={busy}
            color="primary"
            variant="outlined"
            disabled={btnDisabled}
            onClick={validateAccount}
          >
            {isVerified ? 'Verified' : 'Verify'}
          </LoadingButton>
        </Stack>
      );
    }
    return (
      <Stack direction="row" spacing={1}>
        <div style={{ flex: 1 }}></div>
        <div style={{ flex: 1 }}>
          {!value?.isValid && (
            <>
              <p>Please complete the above fields and press the button below.</p>
              <br />
              <LoadingButton
                loading={busy}
                variant="contained"
                fullWidth
                disabled={busy || !allDetailsFilled() || paperMandate}
                onClick={validateAccount}
              >
                Check Account Details
              </LoadingButton>
            </>
          )}
        </div>
      </Stack>
    );
  };

  const renderBankDetails = () => {
    return (
      <CustomAlert severity="success">
        Account details successfully verified
        {/* <AlertTitle>Account validated</AlertTitle>
        <div style={{ flex: 1 }}>
          {bankResponse?.Address?.Address?.Lines?.map((line: string) =>
            line.length > 0 ? <p key={line}>{line}</p> : undefined
          )}
        </div> */}
      </CustomAlert>
    );
  };

  if (field.variant === 'minimal') {
    return (
      <Stack spacing={3}>
        {renderCollectionDate()}
        {renderAccountHolderName()}
        {renderAccountNumber()}
        {renderSortCode()}
        {renderLookup()}
        {renderLookupError()}
        {value?.isValid && bankResponse !== undefined && renderBankDetails()}
      </Stack>
    );
  }

  return (
    <Stack spacing={2}>
      <p>Please complete the direct debit instruction below.</p>
      <Stack className="htmlField" style={{ maxHeight: 'none', overflow: 'hidden' }} spacing={2}>
        {renderLogos()}
        <h2>Instruction to your Bank or Building Society to pay by Direct Debit</h2>
        {renderAddressDetails()}
        {renderCollectionDate()}
        {renderConfirmation()}
        {renderAccountHolderName()}
        {renderAccountNumber()}
        {renderSortCode()}
        {renderLookup()}
        {renderLookupError()}
        {value?.isValid && bankResponse !== undefined && renderBankDetails()}
        <p>
          <b>Instruction to your Bank or Building Society</b>
          <br />
          Please pay <b>National Friendly</b> Direct Debits from the account detailed in this Instruction subject to the
          safeguards assured by the Direct Debit Guarantee. I understand that this Instruction may remain with{' '}
          <b>National Friendly</b> and if so, details will be passed electronically to my Bank/Building Society.
        </p>
        <b>NOTE: Banks and Building Societies may not accept Direct Debit Instructions for some types of accounts.</b>
        {renderDate()}
      </Stack>
      <p>
        If you do not want to provide your bank information now, please indicate below and we will send you a paper
        direct debit mandate.
      </p>
      <b>Please note, this will result in a delay in the issuing of your policy.</b>
      <FormControlLabel
        disabled={busy}
        control={
          <Switch
            onChange={(event, checked) => {
              setPaperMandate(checked);
              if (checked) {
                resetAll();
                changeHandler(field, { paperMandate: true });
              } else {
                changeHandler(field, undefined);
              }
            }}
          />
        }
        disableTypography={true}
        label="Send me a paper mandate by post."
      />
    </Stack>
  );
};
