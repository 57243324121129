export const Trade = [
  { label: "Fencing Contractors", value: "Fencing Contractors" },
  { label: "Garden Centres", value: "Garden Centres" },
  { label: "Gardeners", value: "Gardeners" },
  { label: "Landscape Gardeners", value: "Landscape Gardeners" },
  { label: "Shed/Garage Supply & Erection", value: "Shed/Garage Supply & Erection" },
  { label: "Aerodromes/Airfields", value: "Aerodromes/Airfields" },
  { label: "Animal Breeders/Kennels", value: "Animal Breeders/Kennels" },
  { label: "Funeral Directors", value: "Funeral Directors" },
  { label: "Veterinary Surgeries", value: "Veterinary Surgeries" },

  { label: "Car Valets", value: "Car Valets" },
  { label: "Carpet Beaters and Cleaners", value: "Carpet Beaters and Cleaners" },
  { label: "Chimney Sweeps", value: "Chimney Sweeps" },
  { label: "Cleaners (Domestic, Shops, Offices)", value: "Cleaners (Domestic, Shops, Offices)" },
  { label: "Cleaning Contractors (Commercial)", value: "Cleaning Contractors (Commercial)" },
  { label: "Drain Cleaners", value: "Drain Cleaners" },
  {
    label: "Dry Cleaners / Laundry (high street and attended only)",
    value: "Dry Cleaners / Laundry (high street and attended only)",
  },
  { label: "Vehicle Valets (Commercial)", value: "Vehicle Valets (Commercial)" },
  { label: "Window Cleaners", value: "Window Cleaners" },
  {
    label: "Window Cleaners (Ground level/Internal only)",
    value: "Window Cleaners (Ground level/Internal only)",
  },
  { label: "Boot & Shoe Distributors", value: "Boot & Shoe Distributors" },
  { label: "Boot & Shoe Manufacturers", value: "Boot & Shoe Manufacturers" },
  { label: "Designers & Pattern makers", value: "Designers & Pattern makers" },
  { label: "Embroiderers", value: "Embroiderers" },
  { label: "Hat & Fashion accessory Distributors", value: "Hat & Fashion accessory Distributors" },
  { label: "Hat Manufacturers", value: "Hat Manufacturers" },
  { label: "Tailors & Dressmakers", value: "Tailors & Dressmakers" },
  { label: "Wig manufacturers", value: "Wig manufacturers" },
  {
    label: "Workwear, Uniforms & Safety Clothing Distributors (UK/EU Only)",
    value: "Workwear, Uniforms & Safety Clothing Distributors (UK/EU Only)",
  },
  {
    label: "Charitable Organisations - Non Manual Activities Only",
    value: "Charitable Organisations - Non Manual Activities Only",
  },
  {
    label: "Charitable Organisations including Non-Hazardous Fund Raising Activities",
    value: "Charitable Organisations including Non-Hazardous Fund Raising Activities",
  },
  { label: "Community Centres", value: "Community Centres" },
  { label: "First Aiders", value: "First Aiders" },
  { label: "Other Educational Establishment", value: "Other Educational Establishment" },
  { label: "Pavilions & Scout Huts", value: "Pavilions & Scout Huts" },
  { label: "School or College", value: "School or College" },
  { label: "Training Workshops - Craftsmen", value: "Training Workshops - Craftsmen" },
  { label: "Bathroom Fitters", value: "Bathroom Fitters" },
  { label: "Blind Suppliers & Installers", value: "Blind Suppliers & Installers" },
  { label: "Carpet Suppliers & Fitters", value: "Carpet Suppliers & Fitters" },
  { label: "Curtain Suppliers & Fitters", value: "Curtain Suppliers & Fitters" },
  { label: "Damp Proofing/Timber Treatment", value: "Damp Proofing/Timber Treatment" },
  {
    label: "Disabled Equipment Supply/Installation",
    value: "Disabled Equipment Supply/Installation",
  },
  { label: "Exhibition Stand Contractors", value: "Exhibition Stand Contractors" },
  { label: "Fire Surround Supply/Installers", value: "Fire Surround Supply/Installers" },
  { label: "Flooring Contractors", value: "Flooring Contractors" },
  { label: "Glazing Supply & Installation", value: "Glazing Supply & Installation" },
  { label: "Insulation Contractors", value: "Insulation Contractors" },
  { label: "Interior Designers/Decorators", value: "Interior Designers/Decorators" },
  { label: "Kitchen Fitters", value: "Kitchen Fitters" },
  { label: "Locksmiths", value: "Locksmiths" },
  { label: "Marquee Supply & Erection", value: "Marquee Supply & Erection" },
  { label: "Painters & Decorators", value: "Painters & Decorators" },
  {
    label: "Painters & Decorators - inc Spray Painting",
    value: "Painters & Decorators - inc Spray Painting",
  },
  { label: "Pest Control", value: "Pest Control" },
  {
    label: "Plasterers (including Artexing, Coving and Dry Lining)",
    value: "Plasterers (including Artexing, Coving and Dry Lining)",
  },
  { label: "Property Maintenance", value: "Property Maintenance" },
  { label: "Racking Installers", value: "Racking Installers" },
  {
    label: "Sealant Contractors (Mastic & the like)",
    value: "Sealant Contractors (Mastic & the like)",
  },
  { label: "Shopfitters", value: "Shopfitters" },
  { label: "Sign Erectors", value: "Sign Erectors" },
  { label: "Sign Writers", value: "Sign Writers" },
  { label: "Staging & Set Erectors", value: "Staging & Set Erectors" },
  { label: "Stonemasons", value: "Stonemasons" },
  {
    label: "Suspended Ceiling/Partitioning Contractors",
    value: "Suspended Ceiling/Partitioning Contractors",
  },
  { label: "Tilers (Walls & Floors)", value: "Tilers (Walls & Floors)" },
  {
    label: "Vending Machine Contractors (to include hire, service and filling up)",
    value: "Vending Machine Contractors (to include hire, service and filling up)",
  },
  { label: "Windscreen Fitters/Repairers/Etchers", value: "Windscreen Fitters/Repairers/Etchers" },
  { label: "Bricklayers", value: "Bricklayers" },
  { label: "Builders / Contractors Office", value: "Builders / Contractors Office" },
  { label: "Cladding Contractors", value: "Cladding Contractors" },
  { label: "Concrete Contractors", value: "Concrete Contractors" },
  { label: "Curtain Wallers", value: "Curtain Wallers" },
  { label: "Draught Proofers", value: "Draught Proofers" },
  { label: "Flue Liners", value: "Flue Liners" },
  {
    label: "Ground workers - maximum depth of 2 metres",
    value: "Ground workers - maximum depth of 2 metres",
  },
  {
    label: "Ground workers - maximum depth of 5 metres",
    value: "Ground workers - maximum depth of 5 metres",
  },
  {
    label: "Guttering & Facia Board Supply/Installation",
    value: "Guttering & Facia Board Supply/Installation",
  },
  { label: "Hoarding Contractors", value: "Hoarding Contractors" },
  { label: "Loft Conversion Contractors", value: "Loft Conversion Contractors" },
  { label: "Mast Erectors", value: "Mast Erectors" },
  { label: "Paving Contractors", value: "Paving Contractors" },
  { label: "Plant Operators / Drivers", value: "Plant Operators / Drivers" },
  {
    label: "Street Furniture Supply & Installation",
    value: "Street Furniture Supply & Installation",
  },
  { label: "Surfacing Contractors", value: "Surfacing Contractors" },
  { label: "Swimming Pool Supply & Installation", value: "Swimming Pool Supply & Installation" },
  { label: "Telegraph Pole Installers", value: "Telegraph Pole Installers" },
  { label: "Aggregate Distributors", value: "Aggregate Distributors" },
  {
    label: "Alarm & Security Product Distributors",
    value: "Alarm & Security Product Distributors",
  },
  { label: "Animal Feed Distributors", value: "Animal Feed Distributors" },
  { label: "Artificial Limb Distributors", value: "Artificial Limb Distributors" },
  { label: "Bag & Luggage Distributors", value: "Bag & Luggage Distributors" },
  { label: "Basket & Wicker Product Distributors", value: "Basket & Wicker Product Distributors" },
  { label: "Battery Distributors", value: "Battery Distributors" },
  { label: "Bottle Distributors", value: "Bottle Distributors" },
  { label: "Builders Merchants and the like", value: "Builders Merchants and the like" },
  { label: "Cable Distributors", value: "Cable Distributors" },
  { label: "Camera Distributors", value: "Camera Distributors" },
  { label: "Camping Equipment Distributors", value: "Camping Equipment Distributors" },
  { label: "Candle & Wax Product Distributors", value: "Candle & Wax Product Distributors" },
  { label: "Caravans and Caravan Dealers", value: "Caravans and Caravan Dealers" },
  { label: "Carpet Dealers / Distributors", value: "Carpet Dealers / Distributors" },
  { label: "Cash & Carry", value: "Cash & Carry" },
  { label: "Cassette, CD, DVD & Record Dealers", value: "Cassette, CD, DVD & Record Dealers" },
  { label: "Chandlers", value: "Chandlers" },
  { label: "Clock & Watch Distributors", value: "Clock & Watch Distributors" },
  {
    label: "Computer and Ancillary Equipment Distributors",
    value: "Computer and Ancillary Equipment Distributors",
  },
  { label: "Contraceptive Distributors", value: "Contraceptive Distributors" },
  { label: "Cosmetic Distributors", value: "Cosmetic Distributors" },
  {
    label: "DIY Equipment & Decorating Products Distributors",
    value: "DIY Equipment & Decorating Products Distributors",
  },
  {
    label: "Electrical Equipment Distributors (Domestic)",
    value: "Electrical Equipment Distributors (Domestic)",
  },
  {
    label: "Electronic Equipment Distributors (Commercial)",
    value: "Electronic Equipment Distributors (Commercial)",
  },
  {
    label: "Fancy Goods Distributors (EU/UK Only)",
    value: "Fancy Goods Distributors (EU/UK Only)",
  },
  {
    label: "Forwarding and Shipping Agents, Receiving Offices",
    value: "Forwarding and Shipping Agents, Receiving Offices",
  },
  { label: "Furniture Distributors", value: "Furniture Distributors" },
  { label: "Glazing Distributors", value: "Glazing Distributors" },
  {
    label: "Hardware and Ironmongery Distributors",
    value: "Hardware and Ironmongery Distributors",
  },
  {
    label: "Kitchenware / Household Goods Distributors",
    value: "Kitchenware / Household Goods Distributors",
  },
  { label: "Lighting Distributors", value: "Lighting Distributors" },
  { label: "Mail Order / Internet Suppliers", value: "Mail Order / Internet Suppliers" },
  {
    label: "Medical & Laboratory Equipment Distributors",
    value: "Medical & Laboratory Equipment Distributors",
  },
  { label: "Musical Instrument Distributors", value: "Musical Instrument Distributors" },
  { label: "Nursery Equipment Distributors", value: "Nursery Equipment Distributors" },
  { label: "Office Equipment Distributors", value: "Office Equipment Distributors" },
  { label: "Paper & Cardboard Distributors", value: "Paper & Cardboard Distributors" },
  {
    label: "Pet Product Distributors (UK/EU Only)",
    value: "Pet Product Distributors (UK/EU Only)",
  },
  {
    label: "Plant & Mechanical Equipment Distributors",
    value: "Plant & Mechanical Equipment Distributors",
  },
  { label: "Sporting Equipment Distributors", value: "Sporting Equipment Distributors" },
  { label: "Stationery Distributors", value: "Stationery Distributors" },
  { label: "Telephone Equipment Distribution", value: "Telephone Equipment Distribution" },
  { label: "Toiletry Distributors", value: "Toiletry Distributors" },
  { label: "Toy Distributors", value: "Toy Distributors" },
  {
    label: "Wheelchair & Disabled Equipment Distributors",
    value: "Wheelchair & Disabled Equipment Distributors",
  },
  {
    label: "Aerial / Satellite Dish Supply & Erection",
    value: "Aerial / Satellite Dish Supply & Erection",
  },
  {
    label: "Air Conditioning/Refrigeration Engineers",
    value: "Air Conditioning/Refrigeration Engineers",
  },
  {
    label: "Amusement / Leisure Equipment Engineers",
    value: "Amusement / Leisure Equipment Engineers",
  },
  {
    label: "Car Audio/Alarm Suppliers & Installers",
    value: "Car Audio/Alarm Suppliers & Installers",
  },
  { label: "Commissioning/Service Engineers", value: "Commissioning/Service Engineers" },
  { label: "Computer - Engineers", value: "Computer - Engineers" },
  { label: "Data Cabling", value: "Data Cabling" },
  { label: "Domestic Appliance Engineers", value: "Domestic Appliance Engineers" },
  { label: "Doors/Shutters Installation", value: "Doors/Shutters Installation" },
  { label: "Ductwork Engineers", value: "Ductwork Engineers" },
  {
    label: "Electrical Contractors (Domestic / Commercial)",
    value: "Electrical Contractors (Domestic / Commercial)",
  },
  { label: "Enamellers", value: "Enamellers" },
  { label: "Mechanical Engineering", value: "Mechanical Engineering" },
  {
    label: "Medical & Laboratory Equipment Engineers",
    value: "Medical & Laboratory Equipment Engineers",
  },
  { label: "Office Equipment Engineers", value: "Office Equipment Engineers" },
  {
    label: "Plumbing & Gas Fitters & Heating Engineers",
    value: "Plumbing & Gas Fitters & Heating Engineers",
  },
  {
    label: "Solar Panel & Renewable Energy Engineers",
    value: "Solar Panel & Renewable Energy Engineers",
  },
  { label: "Sound & Lighting Engineers", value: "Sound & Lighting Engineers" },
  { label: "Sporting Equipment Engineers", value: "Sporting Equipment Engineers" },
  { label: "Street Lighting Engineers", value: "Street Lighting Engineers" },
  { label: "Telephone Equipment Engineers", value: "Telephone Equipment Engineers" },
  { label: "Bakeries", value: "Bakeries" },
  { label: "Beverage Manufacturers (soft drinks)", value: "Beverage Manufacturers (soft drinks)" },
  { label: "Breweries", value: "Breweries" },
  { label: "Butchers - Wholesale", value: "Butchers - Wholesale" },
  { label: "Catering Contractors", value: "Catering Contractors" },
  { label: "Catering Deliveries", value: "Catering Deliveries" },
  { label: "Coffee Powder Manufacturers", value: "Coffee Powder Manufacturers" },
  {
    label: "Cold Storage - Wholesale Food and Drink",
    value: "Cold Storage - Wholesale Food and Drink",
  },
  {
    label: "Confectionary (sweet and chocolate) Manufacturers",
    value: "Confectionary (sweet and chocolate) Manufacturers",
  },
  { label: "Dairies", value: "Dairies" },
  {
    label: "Fish Wholesalers/Processors (ex filleting)",
    value: "Fish Wholesalers/Processors (ex filleting)",
  },
  {
    label: "Fish Wholesalers/Processors (inc filleting)",
    value: "Fish Wholesalers/Processors (inc filleting)",
  },
  { label: "Food & Drink Distributors", value: "Food & Drink Distributors" },
  { label: "Food Manufacturers (cooking)", value: "Food Manufacturers (cooking)" },
  { label: "Food Preservers, Packers & Canners", value: "Food Preservers, Packers & Canners" },
  {
    label: "Food Preservers, Packers & Canners - other",
    value: "Food Preservers, Packers & Canners - other",
  },
  {
    label: "Fruit, Vegetable and Flower Wholesalers",
    value: "Fruit, Vegetable and Flower Wholesalers",
  },
  { label: "Jam Manufacturers", value: "Jam Manufacturers" },
  { label: "Milkmen", value: "Milkmen" },
  { label: "Mineral Water Manufacturers", value: "Mineral Water Manufacturers" },
  { label: "Mustard and Spice Manufacturers", value: "Mustard and Spice Manufacturers" },
  {
    label: "Natural / Organic Health Product Distributors",
    value: "Natural / Organic Health Product Distributors",
  },
  { label: "Pickle Manufacturers", value: "Pickle Manufacturers" },
  { label: "Potato Crisp Manufacturers", value: "Potato Crisp Manufacturers" },
  { label: "Wineries", value: "Wineries" },
  { label: "Amusement Machine Supply & Hire", value: "Amusement Machine Supply & Hire" },
  { label: "Athletic Clubs", value: "Athletic Clubs" },
  { label: "Bingo Halls", value: "Bingo Halls" },
  { label: "Bowling Alleys", value: "Bowling Alleys" },
  { label: "Bowling Greens", value: "Bowling Greens" },
  { label: "Camping and Caravan Sites", value: "Camping and Caravan Sites" },
  { label: "Church Hall", value: "Church Hall" },
  { label: "Cinemas", value: "Cinemas" },
  {
    label: "Corporate Hospitality (Conferences etc)",
    value: "Corporate Hospitality (Conferences etc)",
  },
  { label: "Dance Instructors / Studios", value: "Dance Instructors / Studios" },
  { label: "Dance/Music/Drama/Theatre Groups", value: "Dance/Music/Drama/Theatre Groups" },
  { label: "Entertainers", value: "Entertainers" },
  {
    label: "Event Promotions (Sporting/Musical/Drama)",
    value: "Event Promotions (Sporting/Musical/Drama)",
  },
  { label: "Exhibition & Conference Halls", value: "Exhibition & Conference Halls" },
  { label: "Fishing Lakes", value: "Fishing Lakes" },
  { label: "Function / Banqueting Suites", value: "Function / Banqueting Suites" },
  { label: "Gymnasiums", value: "Gymnasiums" },
  { label: "Health Clubs/Sauna", value: "Health Clubs/Sauna" },
  { label: "Holiday Camps", value: "Holiday Camps" },
  { label: "Leisure Centres", value: "Leisure Centres" },
  { label: "Mobile Discos/DJ's", value: "Mobile Discos/DJ's" },
  { label: "Museums", value: "Museums" },
  { label: "Nature & Conservation Centres", value: "Nature & Conservation Centres" },
  { label: "Nightclub", value: "Nightclub" },
  { label: "Party Organisers", value: "Party Organisers" },
  { label: "Race Courses", value: "Race Courses" },
  { label: "Religious Establishment", value: "Religious Establishment" },
  { label: "Roller Skating Parks", value: "Roller Skating Parks" },
  { label: "Social Clubs", value: "Social Clubs" },
  { label: "Social Club (with Deep Fat Frying)", value: "Social Club (with Deep Fat Frying)" },
  { label: "Social Club (no Deep Fat Frying)", value: "Social Club (no Deep Fat Frying)" },
  { label: "Sport Clubs", value: "Sport Clubs" },
  { label: "Sports Instructors", value: "Sports Instructors" },
  { label: "Theatres", value: "Theatres" },
  { label: "Tour Guides", value: "Tour Guides" },
  { label: "Water Sports", value: "Water Sports" },
  { label: "Abrasive Manufacturers", value: "Abrasive Manufacturers" },
  {
    label: "Aerial & Satellite Dish Manufacturers",
    value: "Aerial & Satellite Dish Manufacturers",
  },
  { label: "Alarm Manufacturers", value: "Alarm Manufacturers" },
  {
    label: "Amusement Machine Manufacturers - Metal",
    value: "Amusement Machine Manufacturers - Metal",
  },
  {
    label: "Amusement Machine Manufacturers - Plastic",
    value: "Amusement Machine Manufacturers - Plastic",
  },
  { label: "Artificial Flower Manufacturers", value: "Artificial Flower Manufacturers" },
  { label: "Bag Manufacturer - Fabric/Leather", value: "Bag Manufacturer - Fabric/Leather" },
  { label: "Bag Manufacturer - Plastic", value: "Bag Manufacturer - Plastic" },
  {
    label: "Barrel Makers, Drums and Kegs - Metal",
    value: "Barrel Makers, Drums and Kegs - Metal",
  },
  {
    label: "Barrel Makers, Drums and Kegs - Plastic",
    value: "Barrel Makers, Drums and Kegs - Plastic",
  },
  { label: "Basket & Wicker Manufacturers", value: "Basket & Wicker Manufacturers" },
  { label: "Bedding/Mattress Manufacturers", value: "Bedding/Mattress Manufacturers" },
  { label: "Bicycle Manufacturers", value: "Bicycle Manufacturers" },
  { label: "Blind Manufacturers", value: "Blind Manufacturers" },
  { label: "Boiler Manufacturers", value: "Boiler Manufacturers" },
  { label: "Bottle Manufacturers", value: "Bottle Manufacturers" },
  {
    label: "Brick & Tile Manufacturers incl flint millers",
    value: "Brick & Tile Manufacturers incl flint millers",
  },
  { label: "Brush Manufacturers", value: "Brush Manufacturers" },
  {
    label: "Button, Buckel, Hook and Eye Manufacturers",
    value: "Button, Buckel, Hook and Eye Manufacturers",
  },
  { label: "Cable Manufacturers", value: "Cable Manufacturers" },
  { label: "Camera Manufacturers", value: "Camera Manufacturers" },
  { label: "Camping Equipment Manufacturers", value: "Camping Equipment Manufacturers" },
  { label: "Caravan Manufacturers", value: "Caravan Manufacturers" },
  { label: "Cardboard Box/Tube Manufacturers", value: "Cardboard Box/Tube Manufacturers" },
  { label: "Carpet Manufacturers", value: "Carpet Manufacturers" },
  {
    label: "Cassette, CD, DVD & Record Manufacturers",
    value: "Cassette, CD, DVD & Record Manufacturers",
  },
  { label: "Chemical Works", value: "Chemical Works" },
  {
    label: "Christmas Cracker and Decorations Manufacturers",
    value: "Christmas Cracker and Decorations Manufacturers",
  },
  { label: "Clock Makers", value: "Clock Makers" },
  { label: "Computer - Manufacturers", value: "Computer - Manufacturers" },
  {
    label: "Construction Plant & Machinery Manufacturers",
    value: "Construction Plant & Machinery Manufacturers",
  },
  { label: "Contraceptive Manufacturers", value: "Contraceptive Manufacturers" },
  { label: "Cosmetic Product Manufacturers", value: "Cosmetic Product Manufacturers" },
  { label: "Curtain Manufacturers", value: "Curtain Manufacturers" },
  { label: "Curtain Rail Manufacturers", value: "Curtain Rail Manufacturers" },
  { label: "Cutlery Manufacturers", value: "Cutlery Manufacturers" },
  { label: "Domestic Machinery Manufacturers", value: "Domestic Machinery Manufacturers" },
  { label: "Electric Blanket Manufacturers", value: "Electric Blanket Manufacturers" },
  { label: "Electric Fire & Heater Manufacturers", value: "Electric Fire & Heater Manufacturers" },
  { label: "Electric Sign Manufacturers", value: "Electric Sign Manufacturers" },
  { label: "Electronic Equipment Manufacturers", value: "Electronic Equipment Manufacturers" },
  {
    label: "Factory & Industrial Machinery Manufacturers",
    value: "Factory & Industrial Machinery Manufacturers",
  },
  { label: "Fishing Equipment Manufacturers", value: "Fishing Equipment Manufacturers" },
  { label: "Fishing Net Manufacturers", value: "Fishing Net Manufacturers" },
  { label: "Flag Manufacturers", value: "Flag Manufacturers" },
  { label: "Glassware Manufacturers", value: "Glassware Manufacturers" },
  { label: "Glazing Manufacturers", value: "Glazing Manufacturers" },
  { label: "Hardware Goods Manufacturers", value: "Hardware Goods Manufacturers" },
  { label: "Jewellery Manufacturers", value: "Jewellery Manufacturers" },
  { label: "Ladder Manufacturers", value: "Ladder Manufacturers" },
  { label: "Lamp & Lighting Manufacturers", value: "Lamp & Lighting Manufacturers" },
  { label: "Lamp Shade Manufacturers", value: "Lamp Shade Manufacturers" },
  { label: "Lens Makers", value: "Lens Makers" },
  { label: "Lock and Safe Manufacturers", value: "Lock and Safe Manufacturers" },
  {
    label: "Measuring Monitoring & Recording Equipment Manufacturers",
    value: "Measuring Monitoring & Recording Equipment Manufacturers",
  },
  { label: "Microwave Equipment Manufacturers", value: "Microwave Equipment Manufacturers" },
  { label: "Mirror Manufacturers", value: "Mirror Manufacturers" },
  { label: "Mop Manufacturers", value: "Mop Manufacturers" },
  { label: "Musical Instrument Manufacturers", value: "Musical Instrument Manufacturers" },
  { label: "Neon Sign Manufacturers", value: "Neon Sign Manufacturers" },
  { label: "Nursery Product Manufacturers", value: "Nursery Product Manufacturers" },
  {
    label: "Office Business Machinery Manufacturers",
    value: "Office Business Machinery Manufacturers",
  },
  { label: "Picture Frame Manufacturers", value: "Picture Frame Manufacturers" },
  { label: "Potteries", value: "Potteries" },
  { label: "Printers", value: "Printers" },
  { label: "Printing Machinery Manufacturers", value: "Printing Machinery Manufacturers" },
  {
    label: "Refrigerators and Refrigerating Plant Manufacturers",
    value: "Refrigerators and Refrigerating Plant Manufacturers",
  },
  { label: "Sailmakers and Tanners", value: "Sailmakers and Tanners" },
  { label: "Sports Equipment Manufacturers", value: "Sports Equipment Manufacturers" },
  { label: "Spring Manufacturers", value: "Spring Manufacturers" },
  { label: "Stationery Manufacturers", value: "Stationery Manufacturers" },
  { label: "Tent and Marquee Manufacturers", value: "Tent and Marquee Manufacturers" },
  { label: "Toiletries Manufacturers", value: "Toiletries Manufacturers" },
  { label: "Toy Manufacturers", value: "Toy Manufacturers" },
  {
    label: "TV, Hi-Fi, Audio and Communcation Equipment Manufacturers",
    value: "TV, Hi-Fi, Audio and Communcation Equipment Manufacturers",
  },
  { label: "Umbrella Manufacturers", value: "Umbrella Manufacturers" },
  { label: "Vending Machine Manufacturers", value: "Vending Machine Manufacturers" },
  { label: "Wall paper Manufacturers", value: "Wall paper Manufacturers" },
  { label: "Wheelchair Manufacturers", value: "Wheelchair Manufacturers" },
  { label: "Film Manufacturers", value: "Film Manufacturers" },
  { label: "Film Processing", value: "Film Processing" },
  { label: "Photographers (inc filming)", value: "Photographers (inc filming)" },
  { label: "Recording Studios", value: "Recording Studios" },
  { label: "Art Metal Working", value: "Art Metal Working" },
  { label: "Bolt/Nut Manufacturers", value: "Bolt/Nut Manufacturers" },
  { label: "Metal Fabricators (Premises)", value: "Metal Fabricators (Premises)" },
  { label: "Metal Polishers", value: "Metal Polishers" },
  { label: "Precision Engineers", value: "Precision Engineers" },
  { label: "Roller Shutter Manufacturers", value: "Roller Shutter Manufacturers" },
  { label: "Sheet Metal workers (Premises)", value: "Sheet Metal workers (Premises)" },
  { label: "Silversmiths", value: "Silversmiths" },

  { label: "Accountants", value: "Accountants" },
  { label: "Analytical Laboratories", value: "Analytical Laboratories" },
  { label: "Architects", value: "Architects" },
  { label: "Clerical / Offices", value: "Clerical / Offices" },
  { label: "Computer / Website Consultants", value: "Computer / Website Consultants" },
  { label: "Consulting Engineers", value: "Consulting Engineers" },
  { label: "Estate Agents (not Auctioneers)", value: "Estate Agents (not Auctioneers)" },
  { label: "Health & Safety Consultants", value: "Health & Safety Consultants" },
  { label: "Insurance Brokers", value: "Insurance Brokers" },
  { label: "Management Consultants", value: "Management Consultants" },
  { label: "Marketing / Sales Consultants", value: "Marketing / Sales Consultants" },
  { label: "Opticians", value: "Opticians" },
  { label: "Private Hire", value: "Private Hire" },
  { label: "Recruitment Consultant", value: "Recruitment Consultant" },
  { label: "Site Supervisors", value: "Site Supervisors" },
  {
    label: "Surgeries - Doctors, Dentists, Chiropodists, alternative remedies etc",
    value: "Surgeries - Doctors, Dentists, Chiropodists, alternative remedies etc",
  },
  { label: "Surveyors", value: "Surveyors" },
  {
    label: "Surveyors - Testing for hazardous materials",
    value: "Surveyors - Testing for hazardous materials",
  },
  { label: "Taxi Operator", value: "Taxi Operator" },
  { label: "Telesales", value: "Telesales" },
  { label: "Trainers / Tutors (Non Manual)", value: "Trainers / Tutors (Non Manual)" },
  { label: "Travel Agents and Tour Operators", value: "Travel Agents and Tour Operators" },
  {
    label: "Aggregate / Sand / Ballast Distribution",
    value: "Aggregate / Sand / Ballast Distribution",
  },
  { label: "Bookbinders / Print Finishers", value: "Bookbinders / Print Finishers" },
  { label: "Engravers", value: "Engravers" },
  { label: "Laminators", value: "Laminators" },
  { label: "Packagers", value: "Packagers" },
  { label: "Upholsterers", value: "Upholsterers" },
  { label: "Car Parks (open air parking)", value: "Car Parks (open air parking)" },
  { label: "Antiques", value: "Antiques" },
  { label: "Art, Craft & Needlework Shops", value: "Art, Craft & Needlework Shops" },
  {
    label: "Asian Sweet Shop (with Deep Fat Frying)",
    value: "Asian Sweet Shop (with Deep Fat Frying)",
  },
  {
    label: "Asian Sweet Shop (no Deep Fat Frying)",
    value: "Asian Sweet Shop (no Deep Fat Frying)",
  },
  { label: "Baby Wear", value: "Baby Wear" },
  { label: "Bakers", value: "Bakers" },
  { label: "Basket & Wicker Shops", value: "Basket & Wicker Shops" },
  { label: "Bathroom Equipment Shops", value: "Bathroom Equipment Shops" },
  { label: "Bed and Breakfast", value: "Bed and Breakfast" },
  { label: "Beds", value: "Beds" },
  { label: "Betting Shops", value: "Betting Shops" },
  { label: "Bicycle Shops", value: "Bicycle Shops" },
  { label: "Booksellers", value: "Booksellers" },
  { label: "Bridal Wear", value: "Bridal Wear" },
  { label: "Butchers", value: "Butchers" },
  { label: "Cafes & Sandwich Shops", value: "Cafes & Sandwich Shops" },
  {
    label: "Cafes & Sandwich Shops (with Deep Fat Frying)",
    value: "Cafes & Sandwich Shops (with Deep Fat Frying)",
  },
  {
    label: "Cafes & Sandwich Shops (no Deep Fat Frying)",
    value: "Cafes & Sandwich Shops (no Deep Fat Frying)",
  },
  { label: "Care Home", value: "Care Home" },
  { label: "Carpet Retailers", value: "Carpet Retailers" },
  { label: "Ceramic Tile Shops", value: "Ceramic Tile Shops" },
  { label: "Ceramics and Pottery", value: "Ceramics and Pottery" },
  { label: "Chandlers shop", value: "Chandlers shop" },
  { label: "Chemist Shops", value: "Chemist Shops" },
  { label: "China and Glass Shops", value: "China and Glass Shops" },
  { label: "Clothing Shops - Designer", value: "Clothing Shops - Designer" },
  {
    label: "Clothing Shops excluding Leather & Suede",
    value: "Clothing Shops excluding Leather & Suede",
  },
  {
    label: "Clothing Shops including Leather & Suede",
    value: "Clothing Shops including Leather & Suede",
  },
  { label: "Coffee Shop", value: "Coffee Shop" },
  { label: "Coffee Shop (with Deep Fat Frying)", value: "Coffee Shop (with Deep Fat Frying)" },
  { label: "Coffee Shop (no Deep Fat Frying)", value: "Coffee Shop (no Deep Fat Frying)" },
  { label: "Computer & Ancillary Equipment Shops", value: "Computer & Ancillary Equipment Shops" },
  { label: "Confectionary Shops", value: "Confectionary Shops" },
  { label: "Curtain & Blind Shops", value: "Curtain & Blind Shops" },
  { label: "Delicatessens", value: "Delicatessens" },
  { label: "Department Stores", value: "Department Stores" },
  { label: "Do It Yourself Shops", value: "Do It Yourself Shops" },
  { label: "Drapers - Excluding made-up clothing", value: "Drapers - Excluding made-up clothing" },
  { label: "Dress Hire", value: "Dress Hire" },
  { label: "Electrical Shops", value: "Electrical Shops" },
  {
    label: "Electronic (E-Cig) Cigarette/Vape Shop",
    value: "Electronic (E-Cig) Cigarette/Vape Shop",
  },
  { label: "Fabric", value: "Fabric" },
  { label: "Farm Shop", value: "Farm Shop" },
  { label: "Fashion Accessories", value: "Fashion Accessories" },
  {
    label: "Fashion Boutique (excluding second hand)",
    value: "Fashion Boutique (excluding second hand)",
  },
  { label: "Fish & Chip Shop", value: "Fish & Chip Shop" },
  { label: "Fishing Tackle Shops", value: "Fishing Tackle Shops" },
  { label: "Fishmongers", value: "Fishmongers" },
  { label: "Frozen Food Shops", value: "Frozen Food Shops" },
  { label: "Fuel", value: "Fuel" },
  { label: "Furniture Shops", value: "Furniture Shops" },
  { label: "Furriers", value: "Furriers" },
  { label: "General Stores", value: "General Stores" },
  { label: "Gift Shops", value: "Gift Shops" },
  { label: "Golf Shop", value: "Golf Shop" },
  { label: "Greengrocers / Florists", value: "Greengrocers / Florists" },
  { label: "Greeting Card Shop", value: "Greeting Card Shop" },
  { label: "Grocers", value: "Grocers" },
  { label: "Guest House (with Deep Fat Frying)", value: "Guest House (with Deep Fat Frying)" },
  { label: "Guest House (no Deep Fat Frying)", value: "Guest House (no Deep Fat Frying)" },
  { label: "Gunsmiths", value: "Gunsmiths" },
  { label: "Haberdashery", value: "Haberdashery" },
  { label: "Hairdressers & Beauticians", value: "Hairdressers & Beauticians" },
  {
    label: "Hairdressers & Beauticians (inc mobile)",
    value: "Hairdressers & Beauticians (inc mobile)",
  },
  { label: "Hairpiece and Wig", value: "Hairpiece and Wig" },
  { label: "Handicraft", value: "Handicraft" },
  { label: "Hardware Shops", value: "Hardware Shops" },
  { label: "Health Food Shops", value: "Health Food Shops" },
  { label: "Herbs and Spices", value: "Herbs and Spices" },
  { label: "Home Brew Materials", value: "Home Brew Materials" },
  { label: "Horticultural Goods", value: "Horticultural Goods" },
  { label: "Hostel", value: "Hostel" },
  { label: "Hotels / Guest House (others)", value: "Hotels / Guest House (others)" },
  { label: "Hotel (with Deep Fat Frying)", value: "Hotel (with Deep Fat Frying)" },
  { label: "Hotel (no Deep Fat Frying)", value: "Hotel (no Deep Fat Frying)" },
  { label: "Ice Cream Parlours", value: "Ice Cream Parlours" },
  { label: "In Car Entertainment", value: "In Car Entertainment" },
  { label: "Internet Café (with Deep Fat Frying)", value: "Internet Café (with Deep Fat Frying)" },
  { label: "Internet Café (no Deep Fat Frying)", value: "Internet Café (no Deep Fat Frying)" },
  { label: "Ironmongers", value: "Ironmongers" },
  { label: "Kitchen/Bathroom Unit Showroom", value: "Kitchen/Bathroom Unit Showroom" },
  {
    label: "Launderettes / Dry Cleaners (high street and attended only)",
    value: "Launderettes / Dry Cleaners (high street and attended only)",
  },
  {
    label: "Launderettes / Dry Cleaners (unattended)",
    value: "Launderettes / Dry Cleaners (unattended)",
  },
  { label: "Leather & Travel Goods Shops", value: "Leather & Travel Goods Shops" },
  { label: "Leisurewear", value: "Leisurewear" },
  { label: "Lighting Shops", value: "Lighting Shops" },
  { label: "Lingerie Shop", value: "Lingerie Shop" },
  { label: "Luggage and Handbag", value: "Luggage and Handbag" },
  { label: "Market Hall", value: "Market Hall" },
  { label: "Maternitywear", value: "Maternitywear" },
  { label: "Meat Wholesale/Packers", value: "Meat Wholesale/Packers" },
  { label: "Mobile Phone (excluding second hand)", value: "Mobile Phone (excluding second hand)" },
  { label: "Model & Hobby Shops", value: "Model & Hobby Shops" },
  { label: "Motor Trade", value: "Motor Trade" },
  { label: "Musical Instrument Shops", value: "Musical Instrument Shops" },
  { label: "Newsagents", value: "Newsagents" },
  { label: "Nursery Goods & Pram Retailers", value: "Nursery Goods & Pram Retailers" },
  { label: "Off Licence", value: "Off Licence" },
  { label: "Office Equipment & Furniture Shops", value: "Office Equipment & Furniture Shops" },
  { label: "Organic Foods", value: "Organic Foods" },
  { label: "Paint and Wallpaper Shops", value: "Paint and Wallpaper Shops" },
  { label: "Pawnbrokers", value: "Pawnbrokers" },
  { label: "Petrol Station Shop", value: "Petrol Station Shop" },
  { label: "Photo Processing", value: "Photo Processing" },
  { label: "Photocopying/Instant Print", value: "Photocopying/Instant Print" },
  { label: "Picture Framers", value: "Picture Framers" },
  {
    label: "Pine and Cane Furniture (excluding second hand)",
    value: "Pine and Cane Furniture (excluding second hand)",
  },
  { label: "Pond Supplies", value: "Pond Supplies" },
  { label: "Pool and Snooker Table", value: "Pool and Snooker Table" },
  { label: "Post Office/Sub Post Office", value: "Post Office/Sub Post Office" },
  { label: "Pound Shop", value: "Pound Shop" },
  { label: "Public Houses (Drinks and Food only)", value: "Public Houses (Drinks and Food only)" },
  { label: "Public Houses (with Deep Fat Frying)", value: "Public Houses (with Deep Fat Frying)" },
  { label: "Public Houses (no Deep Fat Frying)", value: "Public Houses (no Deep Fat Frying)" },
  { label: "Record, CD, DVD  & Cassette Shops", value: "Record, CD, DVD  & Cassette Shops" },
  { label: "Residential Home", value: "Residential Home" },
  { label: "Restaurants", value: "Restaurants" },
  {
    label: "Restaurant - African (with Deep Fat Frying)",
    value: "Restaurant - African (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - African (no Deep Fat Frying)",
    value: "Restaurant - African (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - American (with Deep Fat Frying)",
    value: "Restaurant - American (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - American (no Deep Fat Frying)",
    value: "Restaurant - American (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - Carribean (with Deep Fat Frying)",
    value: "Restaurant - Carribean (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - Carribean (no Deep Fat Frying)",
    value: "Restaurant - Carribean (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - Chinese (with Deep Fat Frying)",
    value: "Restaurant - Chinese (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - Chinese (no Deep Fat Frying)",
    value: "Restaurant - Chinese (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - English (with Deep Fat Frying)",
    value: "Restaurant - English (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - English (no Deep Fat Frying)",
    value: "Restaurant - English (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - European (with Deep Fat Frying)",
    value: "Restaurant - European (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - European (no Deep Fat Frying)",
    value: "Restaurant - European (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - French (with Deep Fat Frying)",
    value: "Restaurant - French (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - French (no Deep Fat Frying)",
    value: "Restaurant - French (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - Greek (with Deep Fat Frying)",
    value: "Restaurant - Greek (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - Greek (no Deep Fat Frying)",
    value: "Restaurant - Greek (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - Indian (with Deep Fat Frying)",
    value: "Restaurant - Indian (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - Indian (no Deep Fat Frying)",
    value: "Restaurant - Indian (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - Italian (with Deep Fat Frying)",
    value: "Restaurant - Italian (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - Italian (no Deep Fat Frying)",
    value: "Restaurant - Italian (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - Japanese (with Deep Fat Frying)",
    value: "Restaurant - Japanese (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - Japanese (no Deep Fat Frying)",
    value: "Restaurant - Japanese (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - Lebanese (with Deep Fat Frying)",
    value: "Restaurant - Lebanese (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - Lebanese (no Deep Fat Frying)",
    value: "Restaurant - Lebanese (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - Mediterranean (with Deep Fat Frying)",
    value: "Restaurant - Mediterranean (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - Mediterranean (no Deep Fat Frying)",
    value: "Restaurant - Mediterranean (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - Mexican (with Deep Fat Frying)",
    value: "Restaurant - Mexican (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - Mexican (no Deep Fat Frying)",
    value: "Restaurant - Mexican (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - Pizza (with Deep Fat Frying)",
    value: "Restaurant - Pizza (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - Pizza (no Deep Fat Frying)",
    value: "Restaurant - Pizza (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - Thai (with Deep Fat Frying)",
    value: "Restaurant - Thai (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - Thai (no Deep Fat Frying)",
    value: "Restaurant - Thai (no Deep Fat Frying)",
  },
  {
    label: "Restaurant - Vegetarian (with Deep Fat Frying)",
    value: "Restaurant - Vegetarian (with Deep Fat Frying)",
  },
  {
    label: "Restaurant - Vegetarian (no Deep Fat Frying)",
    value: "Restaurant - Vegetarian (no Deep Fat Frying)",
  },
  { label: "Second Hand Goods", value: "Second Hand Goods" },
  { label: "Sex Shop", value: "Sex Shop" },
  { label: "Shoe Repairers & key cutters", value: "Shoe Repairers & key cutters" },
  { label: "Shoe Shops", value: "Shoe Shops" },
  {
    label: "Showroom - Bedroom/Kicthen/Window/Fireplace",
    value: "Showroom - Bedroom/Kicthen/Window/Fireplace",
  },
  { label: "Soft Furnishing Shops", value: "Soft Furnishing Shops" },
  { label: "Sport & Outdoor Equipment Shops", value: "Sport & Outdoor Equipment Shops" },
  { label: "Stationery and Greeting Card Shops", value: "Stationery and Greeting Card Shops" },
  { label: "Sweet Shop", value: "Sweet Shop" },
  { label: "Takeaways", value: "Takeaways" },
  { label: "Takeaway - American", value: "Takeaway - American" },
  { label: "Takeaway - Chicken", value: "Takeaway - Chicken" },
  { label: "Takeaway - Chinese", value: "Takeaway - Chinese" },
  { label: "Takeaway - Fast Food", value: "Takeaway - Fast Food" },
  { label: "Takeaway - Indian", value: "Takeaway - Indian" },
  { label: "Takeaway - Kebab", value: "Takeaway - Kebab" },
  { label: "Takeaway - Pizza", value: "Takeaway - Pizza" },
  { label: "Tanning Shop", value: "Tanning Shop" },
  { label: "Tattooist", value: "Tattooist" },
  { label: "Tea Room", value: "Tea Room" },
  { label: "Tiles", value: "Tiles" },
  { label: "Tobacconist", value: "Tobacconist" },
  { label: "Tool Hirers", value: "Tool Hirers" },
  { label: "Tools (excluding second hand)", value: "Tools (excluding second hand)" },
  { label: "Toy Shops", value: "Toy Shops" },
  { label: "Trophy Shop", value: "Trophy Shop" },
  { label: "TV, Hi-Fi & Audio Shops", value: "TV, Hi-Fi & Audio Shops" },
  { label: "Wine Bar", value: "Wine Bar" },
  { label: "Wine Bar (with Deep Fat Frying)", value: "Wine Bar (with Deep Fat Frying)" },
  { label: "Wine Bar (no Deep Fat Frying)", value: "Wine Bar (no Deep Fat Frying)" },
  { label: "Wool and Needlework Shop", value: "Wool and Needlework Shop" },
  { label: "Woollen Goods Shop", value: "Woollen Goods Shop" },
  { label: "Yacht Chandlery", value: "Yacht Chandlery" },
  { label: "Car & Van Hirers", value: "Car & Van Hirers" },
  { label: "Couriers - Others", value: "Couriers - Others" },
  { label: "Driver Training", value: "Driver Training" },
  { label: "Drivers / Delivery Men", value: "Drivers / Delivery Men" },
  { label: "Fork Lift Truck Drivers / Trainers", value: "Fork Lift Truck Drivers / Trainers" },
  { label: "Hauliers - General", value: "Hauliers - General" },
  { label: "Carpenters/Joiners", value: "Carpenters/Joiners" },
  { label: "French Polishers", value: "French Polishers" },
  {
    label: "Furniture Manufacturers (Ex Bed/Sofa)",
    value: "Furniture Manufacturers (Ex Bed/Sofa)",
  },
  { label: "Not Listed", value: "Not Listed" },
  { label: "Aerated Water, Mineral Waters", value: "Aerated Water, Mineral Waters" },
  {
    label: "Agricultural Machinery and Implements",
    value: "Agricultural Machinery and Implements",
  },
  { label: "Alarm Systems", value: "Alarm Systems" },
  { label: "Artificial Flowers", value: "Artificial Flowers" },
  { label: "Bed and Table Linen", value: "Bed and Table Linen" },
  { label: "Beer, Cider and Soft Drinks", value: "Beer, Cider and Soft Drinks" },
  { label: "Belts, Braces and Piece Goods", value: "Belts, Braces and Piece Goods" },
  { label: "Bone, Hone or Ivory", value: "Bone, Hone or Ivory" },
  { label: "Books, Paper or Stationery", value: "Books, Paper or Stationery" },
  { label: "Boot and Shoe", value: "Boot and Shoe" },
  { label: "Bottlers and Canners", value: "Bottlers and Canners" },
  { label: "Bottles", value: "Bottles" },
  { label: "Bricks", value: "Bricks" },
  { label: "Canned or Bottled Food", value: "Canned or Bottled Food" },
  { label: "Carpets Rugs and Floor Coverings", value: "Carpets Rugs and Floor Coverings" },
  { label: "Ceramic Tiles Retailer", value: "Ceramic Tiles Retailer" },
  { label: "China and Glassware", value: "China and Glassware" },
  { label: "Cleaning Materials and Products", value: "Cleaning Materials and Products" },
  { label: "Clothing", value: "Clothing" },
  { label: "Coffee, Tea and Cocoa", value: "Coffee, Tea and Cocoa" },
  { label: "Combs and Hair Products", value: "Combs and Hair Products" },
  { label: "Computer Equipment", value: "Computer Equipment" },
  { label: "Confectionery", value: "Confectionery" },
  { label: "Cosmetics and Beauty Products", value: "Cosmetics and Beauty Products" },
  {
    label: "Curtains, Drapes, Blinds and Soft Furnishings",
    value: "Curtains, Drapes, Blinds and Soft Furnishings",
  },
  { label: "Dairy Produce", value: "Dairy Produce" },
  { label: "Double Glazing", value: "Double Glazing" },
  { label: "Earthenware", value: "Earthenware" },
  { label: "Electric Cable", value: "Electric Cable" },
  { label: "Electrical Appliances", value: "Electrical Appliances" },
  { label: "Electrical Commercial Equipment", value: "Electrical Commercial Equipment" },
  {
    label: "Electrical Components and Electricians Supplies",
    value: "Electrical Components and Electricians Supplies",
  },
  { label: "Fancy Goods", value: "Fancy Goods" },
  { label: "Festive, Carnival, Tricks, Jokes", value: "Festive, Carnival, Tricks, Jokes" },
  { label: "Flax, Scutch and Linen", value: "Flax, Scutch and Linen" },
  { label: "Flowers", value: "Flowers" },
  { label: "Footwear", value: "Footwear" },
  { label: "Fruit and Vegetables", value: "Fruit and Vegetables" },
  { label: "Furniture", value: "Furniture" },
  {
    label: "Gardening Implements, Lawn Mowers, Strimmers and other",
    value: "Gardening Implements, Lawn Mowers, Strimmers and other",
  },
  { label: "Gardening Products and Tools", value: "Gardening Products and Tools" },
  { label: "Glass", value: "Glass" },
  { label: "Gloves", value: "Gloves" },
  { label: "Granaries, Corn Stores and Seedsment", value: "Granaries, Corn Stores and Seedsment" },
  { label: "Granite and Marble Products", value: "Granite and Marble Products" },
  { label: "Groceries and Provisions", value: "Groceries and Provisions" },
  { label: "Gymnasium and Athletic Equipment", value: "Gymnasium and Athletic Equipment" },
  { label: "Hardware and Ironmongery", value: "Hardware and Ironmongery" },
  { label: "Hops", value: "Hops" },
  { label: "Hosiery Goods", value: "Hosiery Goods" },
  { label: "Iron and Steel Stockholders", value: "Iron and Steel Stockholders" },
  { label: "Jam, Jelly and Marmalade", value: "Jam, Jelly and Marmalade" },
  { label: "Jewellery", value: "Jewellery" },
  {
    label: "Kitchen Utensils and General Household Goods",
    value: "Kitchen Utensils and General Household Goods",
  },
  { label: "Knitting or Sewing Machines", value: "Knitting or Sewing Machines" },
  { label: "Lace", value: "Lace" },
  { label: "Leather Goods", value: "Leather Goods" },
  {
    label: "Light Fittings, Lamps and Electrical Components",
    value: "Light Fittings, Lamps and Electrical Components",
  },
  { label: "Linoleum and Floorcloth", value: "Linoleum and Floorcloth" },
  { label: "Machinery", value: "Machinery" },
  { label: "Millinery", value: "Millinery" },
  { label: "Motor Spares and Accessories", value: "Motor Spares and Accessories" },
  { label: "Musical Instruments", value: "Musical Instruments" },
  { label: "Newspapers, Magazines", value: "Newspapers, Magazines" },
  { label: "Nursery Equipment or Baby Products", value: "Nursery Equipment or Baby Products" },
  { label: "Office Furniture and Equipment", value: "Office Furniture and Equipment" },
  {
    label: "Office Stationery and General Supplies",
    value: "Office Stationery and General Supplies",
  },
  {
    label: "Paints, Decorating Products and Materials",
    value: "Paints, Decorating Products and Materials",
  },
  { label: "Paints, Varnish, Lacquer and Enamel", value: "Paints, Varnish, Lacquer and Enamel" },
  {
    label: "Paper, Plastic Bags and Packing Materials",
    value: "Paper, Plastic Bags and Packing Materials",
  },
  { label: "Perfumes", value: "Perfumes" },
  { label: "Photographic Equipment", value: "Photographic Equipment" },
  { label: "Plastics and Plastic Goods", value: "Plastics and Plastic Goods" },
  { label: "Pottery", value: "Pottery" },
  { label: "Raw Fruit and Vegetables", value: "Raw Fruit and Vegetables" },
  { label: "Rope, String, Twine and Hemp", value: "Rope, String, Twine and Hemp" },
  { label: "Sack and Bag", value: "Sack and Bag" },
  { label: "Salt Merchants", value: "Salt Merchants" },
  {
    label: "Sand, Gravel, Cement, Plaster and Brick",
    value: "Sand, Gravel, Cement, Plaster and Brick",
  },
  { label: "Sanitary Ware", value: "Sanitary Ware" },
  {
    label: "Sauce, Pickle, Meat Product or Preserved Food",
    value: "Sauce, Pickle, Meat Product or Preserved Food",
  },
  { label: "Sewing or Knitting Machines", value: "Sewing or Knitting Machines" },
  { label: "Smoking Sundries and Requisites", value: "Smoking Sundries and Requisites" },
  {
    label: "Soap Washing Powder Candle and Glycerine",
    value: "Soap Washing Powder Candle and Glycerine",
  },
  { label: "Soft Drinks", value: "Soft Drinks" },
  { label: "Sports Equipment", value: "Sports Equipment" },
  { label: "Stationery and Stationers Sundries", value: "Stationery and Stationers Sundries" },
  { label: "Steel Stockholders", value: "Steel Stockholders" },
  { label: "Textiles", value: "Textiles" },
  { label: "Theatrical Scenery", value: "Theatrical Scenery" },
  { label: "Timber", value: "Timber" },
  { label: "Toys and Games", value: "Toys and Games" },
  { label: "Wallpapers", value: "Wallpapers" },
  { label: "Wines and Spirits", value: "Wines and Spirits" },
];
