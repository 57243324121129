import { Chip, Stack, Typography } from '@mui/material';
import { AddressElement, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { forwardRef, useContext, useImperativeHandle } from 'react';
import { IStringIndex, TQuote } from '../../../types';
import { PaymentSectionProps } from './PaymentSection';
import { PaymentContext } from '../../../PaymentContext';
import QuoteBox from '../../FormFields/QuoteBox';
import { formatToMoney } from '../../../utils/common';

interface CheckoutFormProps extends Partial<PaymentSectionProps> {
  values: IStringIndex<any>;
  quote: TQuote;
  submitQuoteUpdate: ((values: IStringIndex<any>) => void) | undefined;
  paymentProccessed: boolean;
  amount: number | string | undefined;
  isMTA: boolean;
}

const CheckoutForm = forwardRef(
  ({ config, values, quote, submitQuoteUpdate, setBusy, paymentProccessed, amount, isMTA }: CheckoutFormProps, ref) => {
    const stripe = useStripe();
    const elements = useElements();
    const { paymentConfirmed, updatePaymentStatus, updatePaymentDetails } = useContext(PaymentContext);

    const isMonthly = values?.paymentFrequency === 'MONTHLY';

    const renderVersion = config?.renderVersion;

    //@ts-ignore
    // const testMode = stripe && stripe["_keyMode"] === "test";

    useImperativeHandle(ref, () => ({
      async triggerPayment(cleanValues: any) {
        return await handleSubmit(null, cleanValues);
      },
    }));
    const handleSubmit = async (e: any, cleanValues: any) => {
      setBusy!(true);
      e?.preventDefault();

      if (paymentProccessed) {
        submitQuoteUpdate!(cleanValues);
        return;
      }

      if (!stripe || !elements) {
        return;
      }

      const results = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      });

      if (results.error) {
        setBusy!(false);
        if (results.error.payment_intent?.status === 'succeeded') {
          updatePaymentStatus(true);
          updatePaymentDetails(results.error.payment_intent);
          return { ...cleanValues, paymentIntent: results.error.payment_intent };
        }
        return results;
      } else {
        setBusy!(false);
        updatePaymentStatus(true);
        updatePaymentDetails(results.paymentIntent);
        return { ...cleanValues, paymentIntent: results.paymentIntent };
      }
    };

    if (paymentProccessed) return <></>;

    const address = values?.quote?.proposer?.address || values?.address;
    return (
      <form>
        <Stack spacing={2}>
          {config?.renderVersion === 'V2' && (
            <QuoteBox
              showDeposit={isMonthly}
              field={
                isMonthly
                  ? QuoteBoxs(amount, values?.MTA).Monthly
                  : isMTA
                    ? QuoteBoxs(amount, values?.MTA).AnnualMTA
                    : QuoteBoxs(amount, values?.MTA).Annual
              }
              config={config!}
              formData={{ values, validations: {} }}
            />
          )}
          <Typography variant="h6" fontWeight={700} color={renderVersion === 'V2' ? 'primary' : ''}>
            Card Details
          </Typography>
          <PaymentElement />
          <Typography variant="h6" fontWeight={700} color={renderVersion === 'V2' ? 'primary' : ''}>
            Billing Details
          </Typography>
          <AddressElement
            options={{
              mode: 'billing',
              defaultValues: {
                name: quote?.proposerName,
                address: {
                  postal_code: address?.postcode ?? address?.postCode,
                  country: 'GB',
                  city: address?.city ?? address?.town,
                  line1: address?.line1 ?? address?.addressLine1,
                  line2: address?.line2 ?? address?.addressLine2,
                },
              },
            }}
          />
        </Stack>
        {config?.renderVersion !== 'V2' && (
          <Typography marginTop={5} align="center" variant="h6" fontWeight={600}>
            Amount: £{Number(amount).toFixed(2)}
          </Typography>
        )}
      </form>
    );
  },
);

export default CheckoutForm;

const QuoteBoxs = (amount: any, quote: any): any => ({
  Monthly: {
    name: 'quoteSummaryMonthlyMinimal',
    type: 'quoteBox',
    variant: 'MonthlyWordingMinimal',
    title: 'Deposit payable today',
  },
  Annual: {
    name: 'quoteSummaryAnnualMinimal',
    type: 'quoteBox',
    variant: 'DEFAULT',
    title: 'Annual payment',
    subtitle: 'Incl. Insurance Premium Tax (IPT) at 12%',
  },
  AnnualMTA: {
    name: 'quoteSummaryAnnualMinimal',
    type: 'quoteBox',
    variant: 'DEFAULT',
    title: 'New Annual payment',
    lineItems: [
      { label: 'Previous yearly premium', value: `£${formatToMoney(quote?.oldPremium)}` },
      { label: 'Policy adjustment fee', value: `£${quote?.mtaFee}` },
      { label: 'New one-off payment (Payable today)', value: `£${amount}` },
    ],
    subtitle: 'Incl. Insurance Premium Tax (IPT) at 12%',
  },
});
