import { Button, IconButton, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {
  IStringIndex,
  TFieldChangeHandler,
  TFormData,
  TFormField,
  TJourneyConfig,
} from "../../../types";
import { validateField } from "../../../fieldValidation";
import { renderFields } from "../fields";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";

export interface PreviewArrayFieldProps {
  config: TJourneyConfig;
  fields: TFormField[];
  value: any;
  onChange: any;
  onDelete: (index: number) => void;
  index: number;
}
export function PreviewArrayField(props: PreviewArrayFieldProps) {
  const { config, fields, value, onChange, onDelete, index } = props;
  const [localFormData, setLocalFormData] = useState<TFormData>({
    values: { ...value },
    validations: {},
  });
  const [expanded, setExpanded] = useState(true);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    const validations: IStringIndex<boolean> = {};
    for (let f of fields) {
      validations[f.name] = validateField(f, localFormData.values[f.name], localFormData.values);
    }
    const newLocalFormData = { values: value, validations };
    setLocalFormData(newLocalFormData);
  }, [fields, localFormData.values, value]);

  const localChangeHandler: TFieldChangeHandler = (field: TFormField, value: any) => {
    const newFormData: TFormData = { ...localFormData };
    newFormData.values[field.name] = value;
    newFormData.validations[field.name] = validateField(field, value, newFormData.values);
    setLocalFormData(newFormData);
    onChange(newFormData);
  };

  return (
    <Stack spacing={2}>
      {/* <Stack  direction="row" spacing={1} alignItems="center">
        <Typography style={{ flex: 1 }}>{`${index + 1} | ${title}`}</Typography>
        <IconButton
          color="primary"
          onClick={toggleExpanded}
          //disabled={disabled}
        >
          {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </Stack> */}
      {/* {expanded && (
        <> */}
      <Stack spacing={2}>
        {renderFields(config, fields, localFormData, localChangeHandler, false, "DEFAULT")}
      </Stack>
      <Stack direction="row" spacing={2} sx={{ placeContent: "flex-end" }}>
        <Button
          onClick={() => onDelete(index)}
          variant="contained"
          endIcon={<DeleteForeverOutlinedIcon />}
        >
          Delete
        </Button>
      </Stack>
      {/* </> */}
      {/* )} */}
    </Stack>
  );
}
