// =================================================================================================
// For displaying information to the user
// =================================================================================================

import { HelpOutline } from "@mui/icons-material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Stack,
  useTheme,
} from "@mui/material";
import { useContext, useState } from "react";
import { TFormField, TJourneyConfig } from "../../types";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { renderObject, THtmlObject } from "../../renderHTML";
import { JourneyContext } from "../../JourneyContext";
import { getLinks } from "../../formHandling";
import { DialogBox } from "../DialogBox";

// =================================================================================================
// Main component
// =================================================================================================

export const InfoField = (props: { field: TFormField; config?: TJourneyConfig }) => {
  const journeyContext = useContext(JourneyContext);
  const formData = journeyContext.formData;

  const theme = useTheme();
  const { field, config } = props;

  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------

  if (!field.dialog && !(field.variant === "benefits")) {
    return null;
  }

  return (
    <div className="InfoField" onClick={field.disabled ? undefined : toggleDialog}>
      <Stack spacing={1} direction="row" alignItems={"center"}>
        <IconButton
          color="primary"
          aria-label="HelpOutlineIcon"
          component="span"
          disabled={field.disabled}
        >
          <HelpOutline />
        </IconButton>
        <span style={!field.disabled ? { color: theme.palette.primary.main } : undefined}>
          {field.title}
        </span>
      </Stack>
      <DialogBox
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        field={field}
        config={config}
      />
    </div>
  );
};
