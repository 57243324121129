import { FormHelperText, Stack, TextField as TextFieldMUI } from "@mui/material";
import { useState } from "react";
import { TFormField } from "../../../types";
import { MAX_MEASUREMENTS } from "./MeasurementsField";
import validator from "validator";

export const ImperialWeight = (props: {
  field: TFormField;
  pounds: number;
  errors: { stone: string; pounds: string };
  onChange: (stone: string, pounds: string) => void;
}) => {
  const { field, errors } = props;

  const [stone, setStone] = useState(props.pounds ? Math.trunc(props.pounds / 14) + "" : "");
  const [pounds, setPounds] = useState(props.pounds ? (props.pounds % 14).toFixed(1) : "");

  const stoneAndPoundError = () => {
    const validStone = validator.isInt(stone || "0") && Number(stone) >= 0;
    const validPounds = validator.isDecimal(pounds || "0") && Number(pounds) >= 0;
    if (errors.stone.length > 0 && errors.pounds.length > 0) {
      if (validStone || validPounds) return true;
    }

    return false;
  };

  return (
    <>
      <p style={{ marginTop: 24 }}>What is your weight?</p>
      <Stack direction="row" spacing={2}>
        <TextFieldMUI
          disabled={field.disabled}
          fullWidth
          variant="outlined"
          label="Stone"
          value={stone}
          onChange={(event) => {
            setStone(event.target.value);
            props.onChange(event.target.value, pounds);
          }}
          required={field.required}
          error={errors.stone.length > 0}
          helperText={errors.stone || ""}
        />
        <TextFieldMUI
          disabled={field.disabled}
          fullWidth
          variant="outlined"
          label="Pounds"
          value={pounds}
          onChange={(event) => {
            setPounds(event.target.value);
            props.onChange(stone, event.target.value);
          }}
          required={field.required}
          error={errors.pounds.length > 0}
          helperText={errors.pounds || ""}
        />
      </Stack>
      <FormHelperText
        hidden={!stoneAndPoundError()}
        style={{ margin: "-20px auto 0 auto" }}
        error={true}
      >
        {"Weight must not exceed " +
          MAX_MEASUREMENTS.Imperial.STONE +
          "st, " +
          MAX_MEASUREMENTS.Imperial.POUNDS +
          "lb"}
      </FormHelperText>
    </>
  );
};
