export const DrivingFrequency = [
  {
    label: "Main",
    value: "M",
  },
  {
    label: "Frequent",
    value: "F",
  },
  {
    label: "Excluded",
    value: "E",
  },
  {
    label: "Infrequent",
    value: "C",
  },
  {
    label: "Non Driving",
    value: "N",
  },
  {
    label: "Not Available",
    value: "0",
  },
  {
    label: "Rare",
    value: "R",
  },
  {
    label: "Temporary",
    value: "T",
  },
];
