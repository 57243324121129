import { Container, Stack, Button } from "@mui/material";
// import axios from "axios";
import { useState } from "react";
import { getSectionedAnswers } from "../formHandling";
import { IStringIndex, TFormData, TJourneyConfig } from "../types";
import { populateTemplate } from "../utils";
import { updateHistoricIds, buildPolicyPayload } from "../ERS/payload";
import JsonViewer from "./JsonViewer";

export const DebugView = (props: { config: TJourneyConfig; formData: TFormData }) => {
  const { config, formData } = props;
  const isERS = config.product.id === "pro-75791180-70b9-4ec7-8bb5-d20a69787d69";

  const [isVisible, setIsVisible] = useState(false);
  const [debugView, setDebugView] = useState<
    "FormData" | "Config" | "New" | "Patch" | "Bound" | "MTA" | "ERS"
  >(isERS ? "ERS" : "FormData");
  const valuesCopy = JSON.parse(JSON.stringify(formData.values));
  const quote = valuesCopy.quote;
  let newApi = populateTemplate(config?.APIs?.new, valuesCopy);
  let patchApi = populateTemplate(config?.APIs?.patch, valuesCopy);
  let boundApi = populateTemplate(config?.APIs?.bound, valuesCopy);
  let mtaApi = populateTemplate(config?.APIs?.mta, valuesCopy);

  let testPolicy: any = undefined;

  const referrals: IStringIndex<any> = {};
  if (config.referrals) {
    for (let [fieldName, fieldValue] of Object.entries(config.referrals)) {
      if (valuesCopy[fieldName] === fieldValue) {
        referrals[fieldName] = fieldValue;
      }
    }
  }

  const answers = getSectionedAnswers(config, valuesCopy);
  delete valuesCopy.quote;
  delete valuesCopy.MTA;

  if (newApi?.payload) {
    newApi.payload.formValues = valuesCopy;
    newApi.payload.answers = answers;
  }
  if (patchApi?.payload) {
    patchApi.payload.formValues = valuesCopy;
    patchApi.payload.answers = answers;
    if (patchApi.payload.quote) {
      patchApi.payload.quote.referrals = referrals;
    }
  }
  if (boundApi?.payload) {
    boundApi.payload.formValues = valuesCopy;
    boundApi.payload.answers = answers;
    if (boundApi.payload.quote) {
      boundApi.payload.quote.referrals = referrals;
    }
  }
  if (mtaApi?.payload) {
    mtaApi.payload.formValues = valuesCopy;
    mtaApi.payload.answers = answers;
    if (mtaApi.payload.quote) {
      mtaApi.payload.quote.referrals = referrals;
    }
  }

  //   <a
  //
  // >
  //   DOWNLOAD DATA AS JSON
  // </a>

  const renderERSdebug = () => {
    if (valuesCopy.historicIds) {
      updateHistoricIds(valuesCopy);
      testPolicy = buildPolicyPayload(valuesCopy);
    }
    const quoteRef = quote?.quoteRef;
    if (!quoteRef) {
      return <p>Please complete the "Get Quote" step.</p>;
    }
    const policy = JSON.stringify(testPolicy, null, 2);
    return (
      <>
        <p>
          Quote reference:{" "}
          <b>
            <a
              href={`data:text/json;charset=utf-8,${encodeURIComponent(policy)}`}
              download={`${quoteRef} request.json`}
            >
              {quoteRef}
            </a>
          </b>
        </p>
        <hr />
        <br />
        {policy}
      </>
    );
  };

  return isVisible ? (
    <Container
      maxWidth="sm"
      sx={{
        backgroundColor: "white",
        padding: 2,
        marginTop: 3,
        borderRadius: 4,
        maxHeight: 400,
        overflowY: "auto",
      }}
    >
      <Stack direction="row" alignItems="center" spacing={2}>
        {isERS && (
          <Button
            size="small"
            onClick={() => setDebugView("ERS")}
            variant={debugView === "ERS" ? "contained" : "outlined"}
          >
            ERS Testing
          </Button>
        )}
        <Button
          size="small"
          onClick={() => setDebugView("FormData")}
          variant={debugView === "FormData" ? "contained" : "outlined"}
        >
          FormData
        </Button>
        <Button
          size="small"
          onClick={() => setDebugView("Config")}
          variant={debugView === "Config" ? "contained" : "outlined"}
        >
          Config
        </Button>
        {config.APIs?.new && (
          <Button
            size="small"
            onClick={() => setDebugView("New")}
            variant={debugView === "New" ? "contained" : "outlined"}
          >
            New
          </Button>
        )}
        {config.APIs?.patch && (
          <Button
            size="small"
            onClick={() => setDebugView("Patch")}
            variant={debugView === "Patch" ? "contained" : "outlined"}
          >
            Patch
          </Button>
        )}
        {config?.APIs?.bound && (
          <Button
            size="small"
            onClick={() => setDebugView("Bound")}
            variant={debugView === "Bound" ? "contained" : "outlined"}
          >
            Bound
          </Button>
        )}
        {config?.APIs?.mta && (
          <Button
            size="small"
            onClick={() => setDebugView("MTA")}
            variant={debugView === "MTA" ? "contained" : "outlined"}
          >
            MTA
          </Button>
        )}
      </Stack>
      <br />
      {/* {["FormData", "Config", "New", "Patch", "Bound", "MTA"].includes(debugView) && (
        <ReactJson
          displayDataTypes={false}
          
          collapsed={1}
          theme={"shapeshifter:inverted"}
          src={
            debugView === "FormData"
              ? formData
              : debugView === "Config"
              ? config
              : debugView === "New"
              ? newApi
              : debugView === "Patch"
              ? patchApi
              : debugView === "Bound"
              ? boundApi
              : debugView === "MTA"
              ? mtaApi
              : null
          }
        />
      )} */}
      {debugView === "ERS" && renderERSdebug()}
        {debugView === "FormData" && <JsonViewer data={formData}/>}
        {debugView === "Config" && <JsonViewer data={config}/>}
        {debugView === "New" && <JsonViewer data={newApi}/>}
        {debugView === "Patch" && <JsonViewer data={patchApi}/>}
        {debugView === "Bound" && <JsonViewer data={boundApi}/>}
        {debugView === "MTA" && <JsonViewer data={mtaApi}/>}
        {debugView === "ERS" && renderERSdebug()}
      
      {/* {debugView !== "FormData" && <Button onClick={testApi}>SHOOT TO THRILL</Button>} */}
    </Container>
  ) : (
    <Container sx={{ marginTop: 3, justifyContent: "flex-end" }} maxWidth="sm">
      <Button size="small" variant="contained" onClick={() => setIsVisible(true)}>
        Show Debug View
      </Button>{" "}
    </Container>
  );
};
