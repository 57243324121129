export const AbodeType = [
  {
    label: "Sited Caravan",
    value: "05",
  },
  {
    label: "Boat",
    value: "03",
  },
  {
    label: "Flat",
    value: "02",
  },
  {
    label: "House",
    value: "01",
  },
  {
    label: "Licenced Premesis",
    value: "04",
  },
  {
    label: "Unsited Caravan",
    value: "06",
  },
  {
    label: "Other",
    value: "99",
  },
];
