export const DrivingLicence = [
  {
    "label": "A1 (up to 125 cc, 11 kw, 14.6 bhp)",
    "value": "L"
  },
  {
    "label": "A2 Licence (Restricted full test)",
    "value": "8"
  },
  {
    "label": "AM (Moped Licence)",
    "value": "7"
  },
  {
    "label": "Category A Full Motorcycle Licence",
    "value": "M"
  },
  {
    "label": "Full EU Licence",
    "value": "E"
  },
  {
    "label": "Full European (Non-EU)",
    "value": "H"
  },
  {
    "label": "Full International Licence",
    "value": "N"
  },
  {
    "label": "Full UK Car Licence",
    "value": "F"
  },
  {
    "label": "HGV Class 1",
    "value": "T"
  },
  {
    "label": "HGV Class 2",
    "value": "V"
  },
  {
    "label": "HGV Class 3",
    "value": "W"
  },
  {
    "label": "Other",
    "value": "Z"
  },
  {
    "label": "Provisional (Eire)",
    "value": "G"
  },
  {
    "label": "Provisional (UK) Car Licence",
    "value": "P"
  },
  {
    "label": "Provisional (UK) HGV Licence",
    "value": "9"
  }
]