import React from 'react';
import { TBranding, TFieldChangeHandler, TFormData, TFormField, TJourneyConfig, TJourneyMode } from '../../types';
import { Stack, Typography } from '@mui/material';
import { OptionField } from './OptionField';

export default function MarketingConsent({
  field,
  value,
  changeHandler,
  formData,
  mode,
  config,
}: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
  formData: TFormData;
  mode: TJourneyMode;
  branding: TBranding | undefined;
  config: TJourneyConfig;
}) {
  return (
    <Stack direction={'column'} spacing={4}>
      <Typography fontWeight={400} fontSize={14} fontFamily={'Inter'}>
        From time to time, we may contact you with relevant marketing information by email, phone or post. For more
        details about how we use your information and your rights, please refer to our 
        <a href="https://www.stubbenedge.com/privacy-policy/" target="_blank" rel="noreferrer">
          Privacy policy
        </a>
         and 
        <a href="https://www.stubbenedge.com/terms-of-use/" target="_blank" rel="noreferrer">
          Terms of use
        </a>
        . 
      </Typography>

      <Stack direction={'column'} spacing={1}>
        <Typography fontWeight={500} fontSize={14} fontFamily={'Inter'}>
          I consent to being sent marketing communications:
        </Typography>
        <OptionField field={field} changeHandler={changeHandler} config={config} value={value} />
      </Stack>
    </Stack>
  );
}