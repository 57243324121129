import { Config, DeploymentHost, TEnvironment } from './types';

// =================================================================================================
// Services map indicates the API endpoints for each deployment host
// =================================================================================================
const endPoint = process.env.REACT_APP_TEMP_ENDPOINT
  ? `${process.env.REACT_APP_TEMP_ENDPOINT}v1`
  : 'https://tew7iqjz4k.execute-api.eu-west-3.amazonaws.com/pb-dev-3/v1';
const servicesMap = {
  development: {
    quoteBaseUrl: 'https://products.segdevelopment.com/pb-api/quotes',
    pbQuotesApi: endPoint,
    nfQuoteBaseUrl: 'https://esh8h720oi.execute-api.eu-west-1.amazonaws.com/dev/quote',
  },

  staging: {
    quoteBaseUrl: 'https://products.segstaging.com/pb-api/quotes',
    pbQuotesApi: endPoint,
    nfQuoteBaseUrl: 'https://7xvluwvvt2.execute-api.eu-west-1.amazonaws.com/dev/quote',
  },

  uat: {
    quoteBaseUrl: 'https://products.seguat.com/pb-api/quotes',
    pbQuotesApi: endPoint,
    nfQuoteBaseUrl: 'https://8w4ke8dq4m.execute-api.eu-west-1.amazonaws.com/endPointdev/quote',
  },

  production: {
    quoteBaseUrl: 'https://products.stubbenedge.com/pb-api/quotes',
    pbQuotesApi: endPoint,
    nfQuoteBaseUrl: 'https://lo3n2u2l6a.execute-api.eu-west-1.amazonaws.com/dev/quote',
  },
};

// =================================================================================================
// Given a hostname, return its associated environment
// e.g. "products.segdevelopment.com" is "development"
// =========================================================================1========================

export const getEnvironment = (hostname: DeploymentHost): TEnvironment => {
  if (hostname.includes('stubbenedge.com')) return 'production';

  switch (hostname) {
    case 'localhost':
      return 'localhost';

    default:
    case 'products.segdevelopment.com':
    case 'seg-pb-whitelabeljourney-dev.s3-website-eu-west-1.amazonaws.com':
      return 'development';

    case 'products.segstaging.com':
    case 'seg-pb-whitelabeljourney-staging.s3-website-eu-west-1.amazonaws.com':
      return 'staging';

    case 'products.seguat.com':
      return 'uat';

    case 'products.stubbenedge.com':
      return 'production';
  }
};

// =================================================================================================
// Given a hostname, return a complete config of the API endpoints
// =================================================================================================

export default function getConfig(hostname: DeploymentHost): Config {
  switch (getEnvironment(hostname)) {
    case 'localhost':
    case 'development':
      return {
        NF_QUOTE_BASE_URL: servicesMap.development.nfQuoteBaseUrl,
        PB_V1_API: servicesMap.development.pbQuotesApi,
        QUOTE_BASE_URL: servicesMap.development.quoteBaseUrl,
      };

    case 'staging':
      return {
        NF_QUOTE_BASE_URL: servicesMap.staging.nfQuoteBaseUrl,
        PB_V1_API: servicesMap.staging.pbQuotesApi,
        QUOTE_BASE_URL: servicesMap.staging.quoteBaseUrl,
      };

    case 'uat':
      return {
        NF_QUOTE_BASE_URL: servicesMap.uat.nfQuoteBaseUrl,
        PB_V1_API: servicesMap.uat.pbQuotesApi,
        QUOTE_BASE_URL: servicesMap.uat.quoteBaseUrl,
      };

    case 'production':
      return {
        NF_QUOTE_BASE_URL: servicesMap.production.nfQuoteBaseUrl,
        PB_V1_API: servicesMap.production.pbQuotesApi,
        QUOTE_BASE_URL: servicesMap.production.quoteBaseUrl,
      };
  }
}
