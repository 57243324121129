import moment from "moment";
import { IStringIndex } from "../types";
import { TClaim } from "../types/ERS/TClaim";
import { TDisability } from "../types/ERS/TDisability";
import { TDriver } from "../types/ERS/TDriver";
import { TMotorConviction } from "../types/ERS/TMotorConviction";
import { TNonMotorConviction } from "../types/ERS/TNonMotorConviction";
import { TOccupation } from "../types/ERS/TOccupation";
import { TPolicy } from "../types/ERS/TPolicy";
import { TVehicleDriverAssignment } from "../types/ERS/TVehicleDriverAssignment";
import { TVehicleSecurity } from "../types/ERS/TVehicleSecurity";
import {
  getUpdatedVehicleIds,
  TDriverIdentifier,
  getUpdatedDriverIds,
  THistoricVehicle,
  THistoricDriver,
} from "./lifetimeIDs";

export const updateHistoricIds = (formValues: IStringIndex<any>) => {
  // Vehicle check
  const vehicle = formValues.vehicle;
  if (vehicle?.VIN) {
    const newIds = getUpdatedVehicleIds(formValues.historicIds, { VIN: vehicle.VIN });
    formValues.historicIds = newIds;
  }
  // Policyholder is first driver
  if (formValues.firstName && formValues.lastName && formValues.dateOfBirth) {
    const policyholderId: TDriverIdentifier = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      dateOfBirth: formValues.dateOfBirth,
    };
    if (formValues.drivers) {
      const driverIds: TDriverIdentifier[] = formValues.drivers.map((d: any) => ({
        firstName: d.firstName,
        lastName: d.lastName,
        dateOfBirth: d.dateOfBirth,
      }));
      formValues.historicIds = getUpdatedDriverIds(formValues.historicIds, [
        policyholderId,
        ...driverIds,
      ]);
    } else {
      formValues.historicIds = getUpdatedDriverIds(formValues.historicIds, [policyholderId]);
    }
  }
};

const changeDateFormat = (DD_MM_YYYY = "") => {
  const parts = DD_MM_YYYY.split("/");
  if (parts.length !== 3) {
    return "";
  }
  return `${parts[2]}-${parts[1]}-${parts[0]}`;
};

const getVehicleLifetimeId = (formValues: IStringIndex<any>) => {
  const VIN = formValues.vehicle?.VIN;
  if (!VIN) {
    return "";
  }
  const match = formValues.historicIds.vehicles.find((v: THistoricVehicle) => v.VIN === VIN);
  return match?.lifetimeId ?? "";
};

const getDriverLifetimeId = (
  firstName: string,
  lastName: string,
  dateOfBirth: string,
  formValues: IStringIndex<any>
) => {
  const match = formValues.historicIds.drivers.find(
    (d: THistoricDriver) =>
      d.firstName === firstName && d.lastName === lastName && d.dateOfBirth === dateOfBirth
  );
  return match?.lifetimeId ?? "";
};

const getTodaysDate = () => {
  const now = new Date();
  return moment(now).format("YYYY-MM-DD");
};

const isVehicleLeftHandDrive = (formValues: IStringIndex<any>) => {
  if (formValues["leftHandDrive"]) {
    return formValues["leftHandDrive"] === "Left-Hand Drive";
  } else {
    return formValues["vehicle"]?.isLeftHandDrive === "Yes";
  }
};

const getVehicleModifications = (formValues: IStringIndex<any>) => {
  type TModValue = { totalValue: string; items: { label: string; value: string }[] };
  const mods: IStringIndex<TModValue> = formValues["modifications"] || {};
  const isMedical = formValues["forMedicalReasons"] === "Yes";
  const modifications: { detailCode: string; value: number; medicalReasons: boolean }[] = [];
  for (let value of Object.values(mods)) {
    const total = Number(value.totalValue) || 0;
    const perItem = Math.floor(total / value.items.length);
    for (let item of value.items) {
      modifications.push({ detailCode: item.value, value: perItem, medicalReasons: isMedical });
    }
  }
  return modifications;
};

const getPolicyholderLicencePassedDate = (formValues: IStringIndex<any>) => {
  const years = Number(formValues["licenseYears"]) || 0;
  const months = Number(formValues["licenseMonths"]) || 0;
  const licencePassedDate = moment()
    .subtract(years, "years")
    .subtract(months, "months")
    .format("YYYY-MM-DD");
  return licencePassedDate;
};

const getDriverOccupations = (formValues: IStringIndex<any>) => {
  const occupations: TOccupation[] = [];

  const occupationStatus = formValues["occupationStatus"];
  const jobSector = formValues["jobSector"];
  const jobTitle = formValues["jobTitle"];
  const typeOfEducation = formValues["typeOfFullTimeEducation"]; // Also job title

  occupations.push({
    employmentType: occupationStatus,
    businessType: jobSector,
    occupationCode: jobTitle || typeOfEducation,
    fullTime: true,
    primaryOccupation: true,
  });

  if (formValues["otherOccupation"] === "Yes") {
    const occupationStatus = formValues["otherOccupationStatus"];
    const jobTitle = formValues["otherJobTitle"];
    const jobSector = formValues["otherJobSector"];
    occupations.push({
      employmentType: occupationStatus,
      businessType: jobSector,
      occupationCode: jobTitle,
      fullTime: false,
      primaryOccupation: false,
    });
  }

  return occupations;
};

const getDriverLicencePassedDate = (driver: IStringIndex<any>) => {
  const years = Number(driver["licenseYears"]) || 0;
  const months = Number(driver["licenseMonths"]) || 0;
  const licencePassedDate = moment()
    .subtract(years, "years")
    .subtract(months, "months")
    .format("YYYY-MM-DD");
  return licencePassedDate;
};

const getDriverLengthInUk = (formValues: IStringIndex<any>) => {
  if (formValues["UKResidentSinceBirth"]) {
    return moment().diff(moment(formValues["dateOfBirth"], "DD/MM/YYYY"), "years");
  } else {
    return moment().diff(moment(formValues["UKResidentSince"], "MM/YYYY"), "years");
  }
};

const getClaims = (formValues: IStringIndex<any>) => {
  const claims: TClaim[] = [];
  for (let c of formValues["claims"] ?? []) {
    claims.push({
      claimType: c.claimDescription,
      claimDate: changeDateFormat(c.dateOfIncident),
      accidentalDamageCost: c.accidentalDamageCost,
      conviction: c.resultedInConviction === "Yes",
      fault: c.driverAtFault === "Yes",
      personalInjury: c.personalInjury === "Yes",
      settled: c.claimSettled === "Yes",
      riskClass: "MOTOR", // I guess
    });
  }
  return claims;
};

const getMotorConvictions = (formValues: IStringIndex<any>) => {
  const motorConvictions: TMotorConviction[] = [];
  for (let c of formValues["motoringConvictions"] ?? []) {
    motorConvictions.push({
      convictionCode: c.typeOfConviction,
      convictionDate: changeDateFormat(c.dateOfConviction),
      disqualified: c.disqualification === "Yes",
      fine: c.fineAmount || 0,
      fixedPenalty: c.fixedPenalty === "Yes",
      offenceDate: changeDateFormat(c.dateOfOffence),
      points: c.penaltyPoints || 0,
      disqualifiedMonths: c.lengthOfBan,
      reading: c.alcoholReading,
      readingType: c.methodOfTest,
    });
  }
  return motorConvictions;
};

const getDrivers = (formValues: IStringIndex<any>) => {
  const drivers: TDriver[] = [
    getPolicyholderDriver(formValues),
    ...getAdditionalDrivers(formValues),
  ];
  return drivers;
};

const getPolicyholderDriver = (formValues: IStringIndex<any>) => {
  return {
    lifetimeId: getDriverLifetimeId(
      formValues["firstName"],
      formValues["lastName"],
      formValues["dateOfBirth"],
      formValues
    ),
    title: formValues["title"],
    firstname: formValues["firstName"],
    surname: formValues["lastName"],
    dateOfBirth: changeDateFormat(formValues["dateOfBirth"]),
    gender: formValues["genderAtBirth"],
    maritalStatus: formValues["maritalStatus"],
    residentSinceBirth: formValues["UKResidentSinceBirth"],
    driverLengthInUk: getDriverLengthInUk(formValues),
    driverPermanentUkresident: true, // ???
    relationshipToProposer: "P",
    homeOwner: formValues["homeOwner"] === "Yes",
    licenceType: formValues["typeOfLicence"],
    licencePassedDate: getPolicyholderLicencePassedDate(formValues),
    accidentsOrClaims: formValues["involvedInAccidentLast5Years"] === "Yes",
    motoringConvictions: formValues["motoringConvictionsInLast5Years"] === "Yes",
    nonMotoringConvictions: formValues["unspentNonMotoringConvictions"] === "Yes",
    disability: formValues["haveDisabilities"] === "Yes",
    disabilities:
      formValues["haveDisabilities"] === "Yes" ? getDisabilities(formValues) : undefined,
    specialTermsImposed: formValues["specialTermsImposed"] === "Yes",
    specialtermsReason: formValues["specialTermsReason"],
    motorInsuranceRefused: formValues["motorInsuranceRefused"] === "Yes",
    insuranceRefusedReason: formValues["insuranceRefusedReason"],
    previousMotorInsuranceCancelledOrVoided:
      formValues["policyholderPolicyVoidedOrDeclined"] === "Yes",
    cancelledVoidedReason: formValues["policyholderPolicyVoidedOrDeclinedReason"],
    countyCourtjudgement: formValues["subjectToCCJ"] === "Yes",
    drivingOtherVehiclesAllowed: false, // There is only a single car on this policy
    numberOfVehiclesAccessTo: Number(formValues["numberOfVehiclesInHousehold"]) ?? 0,
    occupations: getDriverOccupations(formValues),
    proficiency: formValues["qualificationType"],
    claims:
      formValues["involvedInAccidentLast5Years"] === "Yes" ? getClaims(formValues) : undefined,
    motorConvictions:
      formValues["motoringConvictions"] !== undefined ? getMotorConvictions(formValues) : undefined,
    nonMotorConvictions:
      formValues["unspentNonMotoringConvictions"] === "Yes"
        ? ([
            {
              offenceDate: changeDateFormat(formValues["dateOfOffence"]),
              offenceType: formValues["convictionType"],
            },
          ] as TNonMotorConviction[])
        : undefined,
  } as TDriver;
};

const getAdditionalDrivers = (formValues: IStringIndex<any>) => {
  const additionalDrivers: TDriver[] = [];
  const drivers = formValues["drivers"] || [];
  for (let driver of drivers) {
    const additionalDriver: TDriver = {
      lifetimeId: getDriverLifetimeId(
        driver.firstName,
        driver.lastName,
        driver.dateOfBirth,
        formValues
      ),
      title: driver.title,
      firstname: driver.firstName,
      surname: driver.lastName,
      dateOfBirth: changeDateFormat(driver.dateOfBirth),
      gender: driver.genderAtBirth,
      maritalStatus: driver.maritalStatus,
      residentSinceBirth: driver.UKResidentSinceBirth,
      driverLengthInUk: getDriverLengthInUk(driver),
      driverPermanentUkresident: true, // ???
      relationshipToProposer: driver.relationshipToProposer,
      homeOwner: driver.homeOwner === "Yes",
      licenceType: driver.typeOfLicence,
      licencePassedDate: getDriverLicencePassedDate(driver),
      accidentsOrClaims: driver.involvedInAccidentLast5Years === "Yes",
      motoringConvictions: driver.motoringConvictionsInLast5Years === "Yes",
      nonMotoringConvictions: driver.unspentNonMotoringConvictions === "Yes",
      disability: driver.haveDisabilities === "Yes",
      disabilities: driver.haveDisabilities === "Yes" ? getDisabilities(driver) : undefined,
      specialTermsImposed: driver.specialTermsImposed === "Yes",
      specialtermsReason: driver.specialTermsReason,
      motorInsuranceRefused: formValues["motorInsuranceRefused"] === "Yes",
      insuranceRefusedReason: formValues["insuranceRefusedReason"],
      previousMotorInsuranceCancelledOrVoided: driver.policyVoidedOrDeclined === "Yes",
      cancelledVoidedReason: driver.policyVoidedOrDeclinedReason,
      countyCourtjudgement: driver.subjectToCCJ === "Yes",
      drivingOtherVehiclesAllowed: false, // There is only a single car on this policy
      numberOfVehiclesAccessTo: driver.driveOtherVehicles === "Yes" ? 2 : 1,
      occupations: getDriverOccupations(driver),
      claims: driver["involvedInAccidentLast5Years"] === "Yes" ? getClaims(driver) : undefined,
      motorConvictions:
        driver["motoringConvictions"] !== undefined ? getMotorConvictions(driver) : undefined,
      nonMotorConvictions:
        driver["unspentNonMotoringConvictions"] === "Yes"
          ? ([
              {
                offenceDate: changeDateFormat(driver["dateOfOffence"]),
                offenceType: driver["convictionType"],
              },
            ] as TNonMotorConviction[])
          : undefined,
    };
    additionalDrivers.push(additionalDriver);
  }

  return additionalDrivers;
};

const getConditionLength = (formValues: IStringIndex<any>) => {
  const conditionLength = Number(formValues["conditionLength"]);
  if (conditionLength) {
    return conditionLength;
  }

  const dateOfBirth = moment(formValues["dateOfBirth"], "DD/MM/YYYY");
  return moment().diff(dateOfBirth, "years");
};

const getDisabilities = (formValues: IStringIndex<any>) => {
  const disabilities: TDisability[] = [];
  disabilities.push({
    conditionCode: formValues["conditionCode"],
    medicalRequired: formValues["medicalRequired"] === "Yes",
    durationInYears: getConditionLength(formValues),
    dvlaAware: [0, 1, 2, 3].includes(formValues["restrictedLicence"]),
    restrictedLicence: [1, 2, 3].includes(formValues["restrictedLicence"]),
  });
  return disabilities;
};

const getDrivingRestrictions = (formValues: IStringIndex<any>) => {
  const drivers = formValues["drivers"] ?? [];
  switch (drivers.length) {
    case 0:
      return "1";
    case 1:
      return "5";
    case 2:
      return "B";
    case 3:
      return "C";
    case 4:
      return "D";
    default:
      return "4";
  }
};

const getSecurityDevice = (
  formValues: IStringIndex<any>
): { securityDeviceFitted: boolean; securityDevices?: TVehicleSecurity[] } => {
  // User specified
  switch (formValues["hasSecurityDevice"]) {
    case "Manufacturer Fitted":
      return {
        securityDeviceFitted: true,
        securityDevices: [
          {
            deviceType: "100", // Standard Manufacturer Fitted
            fittedBy: "4", // Vehicle Manufacturer
          },
        ],
      };
    case "No Security":
      return {
        securityDeviceFitted: false,
      };
    case "Other":
      return {
        securityDeviceFitted: true,
        securityDevices: [
          {
            deviceType: formValues["otherSecurityDeviceType"],
            fittedBy: "5", // Other
          },
        ],
      };
  }

  // Use values from UKVD lookup
  const vehicle = formValues["vehicle"] ?? {};
  if (vehicle.hasAlarmOrImmobiliser) {
    return {
      securityDeviceFitted: true,
      securityDevices: [
        {
          deviceType: "100", // Standard Manufacturer Fitted
          fittedBy: "4", // Vehicle Manufacturer
        },
      ],
    };
  } else {
    return {
      securityDeviceFitted: false,
    };
  }
};

export const buildPolicyPayload = (formValues: IStringIndex<any>) => {
  // This just for readability as I had to keep double checking
  let postalAddressDifferent = false;
  if (formValues["postalAddressSame"] === "No") {
    postalAddressDifferent = true;
  }
  // Build the actual policy object
  const policy: TPolicy = {
    externalUniqueId: crypto.randomUUID(),
    voluntaryExcess: Number(formValues["voluntaryExcessAmount"]) || 0,
    product: {
      schemeCode: "Ventura_Car_7320",
      productCode: "Private_Car",
    },
    brokerAgency: {
      referenceNumber: "20904",
    },
    policyholder: {
      partyType: "INDIVIDUAL",
      title: formValues["title"],
      firstname: formValues["firstName"],
      surname: formValues["lastName"],
      dateOfBirth: changeDateFormat(formValues["dateOfBirth"]),
      gender: formValues["genderAtBirth"],
      telephone: formValues["telephoneNumber"],
      email: formValues["emailAddress"],
      homeOwner: formValues["homeOwner"] === "Yes",
      abodeType: formValues["abodeType"],
      driver: true,
      legalCoverRequired: false, // ?
      postalAddressDifferent: postalAddressDifferent,
      address: {
        addressLine1: formValues["address"]?.line1,
        postcode: formValues["address"]?.postcode,
      },
      // This is dumb as heck, you have to supply correspondenceAddress even
      // if you indicate it is the same
      correspondenceAddress: postalAddressDifferent
        ? {
            addressLine1: formValues["correspondenceAddress"]?.line1,
            postcode: formValues["correspondenceAddress"]?.postcode,
          }
        : {
            addressLine1: formValues["address"]?.line1,
            postcode: formValues["address"]?.postcode,
          },
    },
    vehicles: [
      {
        lifetimeId: getVehicleLifetimeId(formValues),
        abiCode: Number(formValues["vehicle"]?.abiCode) || 0,
        cover: formValues["coverType"],
        overnightPostcode:
          formValues["vehicleOvernightPostcode"] ?? formValues["address"]?.postcode,
        type: "Private Car",
        bodyType: formValues["vehicle"]?.bodyStyle?.value ?? formValues["vehicleType"],
        use: formValues["primaryUse"],
        registrationNumber: formValues["vehicle"]?.VRM,
        make: formValues["vehicle"]?.make,
        model: formValues["vehicle"]?.model,
        cubicCapacity: Number(formValues["vehicle"]?.engine) || 0,
        fuelType:
          formValues["vehicle"]?.fuel?.value ??
          formValues["fuelType"] ??
          formValues["hybridFuelType"],
        transmission: formValues["vehicle"]?.transmission?.value ?? formValues["transmissionType"],
        year: Number(formValues["vehicle"]?.year) || 0,
        value: formValues["estimatedValue"] || 0,
        dateOfPurchase: changeDateFormat(formValues["purchaseDate"]) || getTodaysDate(),
        overnightLocation: formValues["keptOvernight"],
        owner: formValues["legalOwner"] || "1",
        keeper: formValues["registeredKeeper"] || "1",
        leftHandDrive: isVehicleLeftHandDrive(formValues),
        annualMileage: Number(formValues["vehicleMileage"]),
        registeredInUk: true,
        ncbYears: Number(formValues["ncbYears"]),
        ncbType: formValues["ncbSource"] || "11", // Defaulting to "Private Car" ?
        ncbProtectionRequired: formValues["protectNcb"] === "Yes",
        modified: formValues["isVehicleModified"] === "Yes",
        modifications: getVehicleModifications(formValues),
        drivingRestrictions: getDrivingRestrictions(formValues),
        vehicleValueType: "M", // Not asked
        ...getSecurityDevice(formValues),
      },
    ],
    drivers: getDrivers(formValues),
    vehicleDriverAssignments: getVehicleDriverAssignments(formValues),
  };
  const now = moment();
  const effectiveStartDateTime = moment(formValues["coverStartDate"], "DD/MM/YYYY")
    .add(now.hours(), "hours")
    .add(now.minutes() + 5, "minutes")
    .add(now.seconds(), "seconds")
    .add(now.milliseconds(), "milliseconds")
    .toDate();
  return {
    effectiveStartDateTime: new Date(effectiveStartDateTime),
    policy,
  };
};

const getVehicleDriverAssignments = (formValues: IStringIndex<any>) => {
  const assignments = formValues["assignments"] ?? [];
  const vehicleDriverAssignments: TVehicleDriverAssignment[] = [];
  if (assignments.length === 0) {
    return [
      {
        vehicleLifetimeId: getVehicleLifetimeId(formValues),
        driverLifetimeId: getDriverLifetimeId(
          formValues["firstName"],
          formValues["lastName"],
          formValues["dateOfBirth"],
          formValues
        ),
        drivingFrequency: "M",
      },
    ];
  }
  for (let a of assignments) {
    vehicleDriverAssignments.push({
      vehicleLifetimeId: getVehicleLifetimeId(formValues),
      driverLifetimeId: getDriverLifetimeId(a.firstName, a.lastName, a.dateOfBirth, formValues),
      drivingFrequency: a.drivingFrequency,
    });
  }
  return vehicleDriverAssignments;
};
