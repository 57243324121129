export const PartyType = [
  {
    label: "Individual",
    value: "INDIVIDUAL",
  },
  {
    label: "Organisation",
    value: "ORGANISATION",
  },
];
