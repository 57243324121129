import { createRoot } from 'react-dom/client';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import Cookies from 'js-cookie';

import Home from './pages/Home';
import './index.css';
import ProductsContextProvider from './ProductsApiContext';
import PaymentContextProvider from './PaymentContext';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const cookieBranding = Cookies.get('branding');
const fetchedBranding = cookieBranding && JSON.parse(decodeURIComponent(cookieBranding as string));

const container = document.getElementById('wljContainer')!;
const root = createRoot(container);

const queryClient = new QueryClient();

root.render(
  <QueryClientProvider client={queryClient}>
    <ProductsContextProvider>
      <PaymentContextProvider>
        <Home fetchedBranding={fetchedBranding} />
      </PaymentContextProvider>
    </ProductsContextProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
);
