// =================================================================================================
// Select an item from a list of options, with autocomplete search.
// =================================================================================================

import { Autocomplete, Checkbox, FormControlLabel, TextField } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { getFieldOptions, replacePlaceholders } from '../../formHandling';
import { JourneyContext } from '../../JourneyContext';
import { TFieldChangeHandler, TFormData, TFormField } from '../../types';
import { groupItems } from './SelectField';

// =================================================================================================
// Main component
// =================================================================================================

export const AutocompleteField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
  formData: TFormData;
}) => {
  // -----------------------------------------------------------------------------------------------
  // Component state and initial values
  // -----------------------------------------------------------------------------------------------
  const [validationError, setValidationError] = useState<string | undefined>();
  const { field, changeHandler, formData } = props;
  const fieldChecked = formData?.values?.[`${field?.name}Checked`];
  const [fieldDisabled, setfieldDisabled] = useState(fieldChecked ?? field.disabled);
  const [checked, setChecked] = useState(fieldChecked);
  const journeyContext = useContext(JourneyContext);
  // Options can either be an array of strings, or a { label: string, value: any } object
  const localOptions = getFieldOptions(field, journeyContext.formData);

  const groups = field?.params?.groups;
  const groupedItems = groupItems(localOptions, groups);

  // Works differently from other similar MUI components. We have to do this match ourselves.
  const getInitialValue = (initialValue: any) => {
    if (fieldChecked) {
      return null;
    }
    const match = localOptions.find((option) => option.value === initialValue);
    return match || null;
  };

  const [value, setValue] = useState(getInitialValue(props.value));

  // useEffect(() => {
  //   setValue(getInitialValue(props.value));
  // }, [props.value]);

  // -----------------------------------------------------------------------------------------------
  // Change handler and validation
  // -----------------------------------------------------------------------------------------------

  const localChangeHandler = (selectedOption: any) => {
    setValue(selectedOption);
    if (selectedOption === null) {
      changeHandler(field, undefined);
      if (field.required) {
        setValidationError('This field is required.');
      }
    } else {
      if (validationError) {
        setValidationError(undefined);
      }
      changeHandler(field, selectedOption.value);
    }
  };

  const handleCheckboxChange = (e: any) => {
    const value = e.target.checked;
    setChecked(value);
    if (value) {
      const valueWhenChecked = field?.params?.checkboxProperties?.valueWhenChecked?.startsWith('${')
        ? replacePlaceholders(props.formData.values, field?.params?.checkboxProperties?.valueWhenChecked)
        : field?.params?.checkboxProperties?.valueWhenChecked;

      changeHandler(field, valueWhenChecked ?? 'N/A');
      changeHandler({ name: `${field.name}Checked` } as any, value);
      setValue(null);
      setfieldDisabled(true);
    } else {
      setfieldDisabled(field.disabled);
      changeHandler(field, undefined);
      changeHandler({ name: `${field.name}Checked` } as any, value);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------

  // Extract ungrouped items
  const groupedOptionValues = groupedItems?.flatMap((group) => group.options.map((option) => option.value));
  const ungroupedItems = localOptions?.filter((option) => !groupedOptionValues?.includes(option.value));

  // Combine ungrouped items with grouped items
  const combinedOptions = groups
    ? [...ungroupedItems, ...(groupedItems?.flatMap((group) => group.options) as any)]
    : [];

  const placeholder = field?.placeholder;
  return (
    <>
      {field?.params?.hasCheckbox && (
        <FormControlLabel
          sx={{
            color: '#565E73',
            fontSize: 14,
            fontWeight: 500,
          }}
          control={
            <Checkbox
              disabled={field.disabled}
              size="small"
              color="primary"
              onChange={handleCheckboxChange}
              checked={checked}
              value={checked}
            />
          }
          label={field?.params?.checkboxProperties?.label}
        />
      )}
      <Autocomplete
        disabled={fieldDisabled}
        value={value}
        onChange={(event: any, selected: any) => {
          localChangeHandler(selected);
        }}
        groupBy={(option) => {
          if (!groups) return '';
          const group = groupedItems?.find((group) => group.options.includes(option));
          return group ? group.label : '';
        }}
        isOptionEqualToValue={(a, b) => a.value === b.value}
        options={groups ? combinedOptions : localOptions}
        getOptionDisabled={(opt) => opt.disabled}
        fullWidth
        renderInput={(params) => {
          return (
            <TextField
              autoComplete="autocomplete_off_random"
              {...params}
              label={field.title}
              InputProps={{
                ...params.InputProps,
              }}
              required={field.required}
              helperText={validationError || ''}
              error={Boolean(validationError)}
              placeholder={placeholder}
            />
          );
        }}
      />
    </>
  );
};
