import { Alert, AlertTitle, Container, Dialog, DialogContent, Stack, Typography } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { getTitleFromKey, toTitleCase } from '../utils';
import { TJourneyConfig } from '../types';
import CustomAlert from './FormFields/CustomAlert';
import { DocumentItem } from './FormFields/DocumentsField';

// =================================================================================================
// Main component
// =================================================================================================

export const MTASuccessPage = (props: { MTA: any; config?: TJourneyConfig }) => {
  const { MTA, config } = props;

  // -----------------------------------------------------------------------------------------------
  // Render helpers
  // -----------------------------------------------------------------------------------------------

  const renderLink = (doc: { description?: string; url: string; key: string }) => {
    if (doc.url && doc.key) {
      return (
        <Stack direction="row" alignItems="top" spacing={1} key={doc.key}>
          <OpenInNewIcon sx={{ color: '#009BF5' }} />
          <a
            href={doc.url}
            target="_blank"
            rel="noreferrer"
            style={{ color: '#009BF5', textDecoration: 'none', textTransform: 'capitalize' }}
          >
            {doc?.description ?? getTitleFromKey(doc.key)}
          </a>
        </Stack>
      );
    }
    return undefined;
  };

  // -----------------------------------------------------------------------------------------------
  // Main rendering
  // -----------------------------------------------------------------------------------------------

  if (MTA === null) {
    return null;
  }

  if (config?.renderVersion === 'V2') {
    return (
      <Dialog open={true} scroll="paper" fullWidth PaperProps={{ sx: { width: '100%', margin: '0px' } }}>
        <DialogContent className="dialogContent">
          <Stack sx={{ width: '100%', height: '100%' }} justifyContent="center" alignItems="center">
            <Container maxWidth="sm" sx={{ backgroundColor: 'white', borderRadius: '20px', padding: 4 }}>
              <Stack spacing={3}>
                {MTA?.proposer?.fullName && (
                  <Typography variant="h5" textAlign={'center'} fontWeight={600} fontFamily={'Poppins'}>
                    Dear {toTitleCase(MTA?.proposer?.fullName)}
                  </Typography>
                )}
                <CustomAlert
                  title="MTA Successful"
                  severity="success"
                  sx={{ border: '1px solid rgba(0,0,0,0.5)', width: '100%' }}
                >
                  <Stack spacing={2}>The policy has been updated!</Stack>
                </CustomAlert>
                {MTA?.policyNumber &&
                <p>
                  Your policy number is <b>{MTA?.policyNumber}</b>
                </p>
                }
                {MTA?.proposer?.email && (
                  <p>
                    Email will be sent to <b>{MTA?.proposer?.email}</b>
                  </p>
                )}

                {MTA?.documents && MTA?.documents.length > 0 && (
                  <Stack direction={'column'} spacing={1} width={'100%'}>
                    {MTA?.documents.map((doc: { url: string; key: string; description?: string }) => (
                      <DocumentItem
                        doc={{
                          name: doc?.description ?? getTitleFromKey(doc.key),
                          link: doc?.url,
                        }}
                      />
                    ))}
                  </Stack>
                )}
              </Stack>
            </Container>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  }
  return (
    <Dialog open={true} scroll="paper" fullWidth PaperProps={{ sx: { width: '100%', margin: '0px' } }}>
      <DialogContent className="dialogContent">
        <Stack sx={{ width: '100%', height: '100%' }} justifyContent="center" alignItems="center">
          <Container maxWidth="sm" sx={{ backgroundColor: 'white', borderRadius: '20px', padding: 4 }}>
            <Stack justifyContent="center" alignItems="center" spacing={3}>
              {MTA?.proposerName && <h2>Dear {toTitleCase(MTA?.proposerName)}</h2>}
              <Alert severity="success" sx={{ border: '1px solid rgba(0,0,0,0.5)', width: '100%' }}>
                {<AlertTitle>MTA Successful</AlertTitle>}
                <Stack spacing={2}>The policy has been updated!</Stack>
              </Alert>

              {MTA?.policyNumber && (
                <p>
                  Your policy number is <b>{MTA?.policyNumber}</b>
                </p>
              )}
              {MTA?.proposerEmail && (
                <p>
                  Email will be sent to <b>{MTA?.proposerEmail}</b>
                </p>
              )}
              {MTA?.documents &&
                MTA?.documents.length > 0 &&
                MTA?.documents.map((doc: { url: string; key: string }) => renderLink(doc))}
            </Stack>
          </Container>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
