import { LoadingButton } from "@mui/lab";
import { Container } from "@mui/material";

export const SubmitButton = (props: {
  label: string;
  disabled?: boolean;
  loading?: boolean;
  onSubmit: () => void;
  renderVersion:"V1" | "V2"
}) => {

  if(props.renderVersion === "V2"){
    return <Container
      maxWidth="sm"
      sx={{ backgroundColor: "white", marginTop: -2, paddingY: 4, borderRadius: "20px" }}
    >
      <LoadingButton
        className="submitButtonV2"
        fullWidth
        variant="contained"
        size="large"
        disabled={props.disabled}
        loading={props.loading}
        onClick={props.onSubmit}
      >
        {props.label}
      </LoadingButton>
    </Container>
  }
  return (
    <Container
      maxWidth="sm"
      sx={{ backgroundColor: "white", marginTop: -2, paddingY: 4, borderRadius: "20px" }}
    >
      <LoadingButton
        className="submitButton"
        fullWidth
        variant="contained"
        size="large"
        disabled={props.disabled}
        loading={props.loading}
        onClick={props.onSubmit}
      >
        {props.label}
      </LoadingButton>
    </Container>
  );
};
