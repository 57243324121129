export const RadiusOfOperation = [
  {
    label: "None",
    value: "0",
  },
  {
    label: "Less Than 100 Miles Uk",
    value: "1",
  },
  {
    label: "Greater Than Or Equal To 100 Miles",
    value: "2",
  },
  {
    label: "Less Than Or Equal To 30 Days Eu Use",
    value: "3",
  },
  {
    label: "Greater Than 30 Days And Less Than 90 Days Eu Use",
    value: "4",
  },
  {
    label: "Outside Eu",
    value: "5",
  },
  {
    label: "Greater Than Or Equal To 90 Days Eu Use",
    value: "6",
  },
];
