import { Box, Button, Modal, Stack, Typography, useTheme } from '@mui/material';
import { IoMdCheckmark } from 'react-icons/io';
import { MdClose } from 'react-icons/md';
import { IoMdClose } from 'react-icons/io';
import { FiInfo } from 'react-icons/fi';
import React, { useState } from 'react';
import { TFormData, TFormField, TJourneyConfig } from '../../types';
import { MdOutlineInfo } from 'react-icons/md';
import { DocumentItem } from './DocumentsField';
import _ from 'lodash';

export default function CoverInfoModal(props: { field: TFormField; config: TJourneyConfig; formData: TFormData }) {
  const [open, setOpen] = useState(false);
  const { field, config } = props;
  const theme = useTheme();

  const brandingColor = theme.palette.primary.main;

  let documents;
  if (props?.field?.dialog?.documents) {
    if (typeof props.field.dialog.documents === 'string') {
      documents = _.get(props.formData.values, props.field.dialog.documents);
      documents = documents?.map((doc:any) => ({ link: doc.url, name: doc?.name ?? doc.description }));
    }else {
      documents = props?.field?.dialog?.documents
    }
  }

  return (
    <>
      <Button
        size="large"
        onClick={() => setOpen(true)}
        style={{
          textTransform: 'none',
        }}
        startIcon={<MdOutlineInfo />}
        variant="text"
      >
        {field?.title}
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <>
          <Box
            sx={{
              position: 'absolute' as 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              bgcolor: 'background.paper',
              border: '2px solid #E9EAFF',
              borderRadius: 4,
              boxShadow: 24,
              p: 4,
            }}
          >
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Stack>
                <Typography
                  fontSize={16}
                  textTransform={'uppercase'}
                  fontFamily={'Poppins'}
                  fontWeight={600}
                  color={'primary'}
                >
                  {field?.dialog?.title}
                </Typography>
                {field?.dialog?.title2 && (
                  <Typography fontSize={30} fontFamily={'Poppins'} fontWeight={600} color={'#1C1D21'}>
                    {field?.dialog?.title2}
                  </Typography>
                )}
                {field?.dialog?.subtitle && (
                  <Typography fontSize={20} fontFamily={'Poppins'} fontWeight={600} color={'#565E73'}>
                    {field?.dialog?.subtitle}
                  </Typography>
                )}
              </Stack>
              <IoMdClose size={24} onClick={() => setOpen(false)} />
            </Stack>
            <div
              style={{
                margin: '20px 0 20px 0',
                height: '1px',
                width: '100%',
                backgroundColor: '#E9EAFF',
              }}
            />
            <Stack spacing={4}>
              <Typography fontSize={14} color={'#464C5E'} fontWeight={400} fontFamily={'sans-serif'}>
                {field?.dialog?.description}
              </Typography>

              <Stack fontFamily={'sans-serif'} spacing={4}>
                {field?.dialog?.benefits && (
                  <div>
                    <Typography fontSize={18} color={'primary'} fontWeight={600} fontFamily={'Poppins'}>
                      {`What's covered`}
                    </Typography>
                    {field?.dialog?.benefits?.map((item: any, index: number) => (
                      <Stack key={index} direction={'row'} spacing={1} marginTop={1} alignItems={'start'}>
                        <IoMdCheckmark color={brandingColor} size={15} />
                        <Typography fontSize={14} fontWeight={400} color={'#464C5E'}>
                          {item}
                        </Typography>
                      </Stack>
                    ))}
                  </div>
                )}
                {field?.dialog?.antiBenefits && (
                  <div>
                    <Typography fontSize={18} color={'#464C5E'} fontWeight={600} fontFamily={'Poppins'}>
                      {`What isn't covered`}
                    </Typography>
                    {field?.dialog?.antiBenefits?.map((item: any, index: number) => (
                      <Stack key={index} direction={'row'} spacing={1} marginTop={1} alignItems={'start'}>
                        <IoMdClose color={'#464C5E'} size={15} />
                        <Typography fontSize={14} fontWeight={400} color={'#464C5E'}>
                          {item}
                        </Typography>
                      </Stack>
                    ))}
                  </div>
                )}

                {documents && (
                  <Stack spacing={1}>
                    {documents?.map((doc: { name: string; link: string }, index: number) => (
                      <DocumentItem key={index} doc={doc} />
                    ))}
                  </Stack>
                )}
              </Stack>
            </Stack>
            <div
              style={{
                margin: '20px 0 20px 0',
                height: '1px',
                width: '100%',
                backgroundColor: '#E9EAFF',
              }}
            />
            <Stack alignItems={'center'} justifyContent={'center'}>
              <Button onClick={() => setOpen(false)} color="primary" fullWidth variant="contained">
                Got it
              </Button>
            </Stack>
          </Box>
        </>
      </Modal>
    </>
  );
}
