export const CameraType = [
  {
    label: "Forward Facing",
    value: "Forward_Facing",
  },
  {
    label: "Forward Facing And Internal",
    value: "For&Int_Facing",
  },
  {
    label: "Rear Facing",
    value: "Rear_Facing",
  },
];
