export const Cover = [
  {
    label: "Comprehensive",
    value: "COMP",
  },
  {
    label: "Damage Third Party Fire And Theft",
    value: "DTPFT",
  },
  {
    label: "Damage Third Party Only",
    value: "DTPO",
  },
  {
    label: "Damage Fire And Theft",
    value: "DFT",
  },
  {
    label: "Third Party Fire And Theft",
    value: "TPFT",
  },
  {
    label: "Third Party Only",
    value: "TPO",
  },
  {
    label: "Fire And Theft",
    value: "FT",
  },
];
