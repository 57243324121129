import { Stack, TextField } from '@mui/material';
import { JsonViewer as JSONViewer, defineDataType } from '@textea/json-viewer';
import { useState } from 'react';

export default function JsonViewer({ data }: { data: any }) {
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event: any) => {
    setSearchQuery(event.target.value);
  };

 const filterJsonByKey = (obj:any, query:any) => {
    if (!query) return obj;

    const lowerCaseQuery = query.toLowerCase();

    const filterObject = (obj:any) => {
      if (typeof obj !== 'object' || obj === null) {
        return obj;
      }

      return Object.keys(obj).reduce((acc:any, key) => {
        const value = obj[key];
        if (key.toLowerCase().includes(lowerCaseQuery)) {
          acc[key] = value;
        } else if (typeof value === 'object' && value !== null) {
          const filteredValue = filterObject(value);
          if (Object.keys(filteredValue).length > 0) {
            acc[key] = filteredValue;
          }
        }
        return acc;
      }, {});
    };

    return filterObject(obj);
  };

  const filteredJson = filterJsonByKey(data, searchQuery);

  return (
    <Stack direction={"column"} spacing={2}>
      <TextField fullWidth variant='filled' size='small' type="text" placeholder="Search..." value={searchQuery} onChange={handleSearchChange} />
      <JSONViewer displayDataTypes={false} highlightUpdates value={filteredJson} />
    </Stack>
  );
}
