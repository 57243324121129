import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { TFieldChangeHandler, TFormData, TFormField, TJourneyConfig } from '../../types';
import { Box, Button, List, ListItem, ListItemIcon, ListItemText, Stack, Typography, useTheme } from '@mui/material';
import { IoCheckmarkSharp } from 'react-icons/io5';
import { GoDotFill } from 'react-icons/go';
import { BiChevronDown } from 'react-icons/bi';
import { BiChevronUp } from 'react-icons/bi';
import { replacePlaceholders } from '../../formHandling';
import _ from 'lodash';
import { Circle, Close, Minimize, Remove } from '@mui/icons-material';

const variants = {
  checkmark: {
    Icon: (
      <IoCheckmarkSharp
        size={20}
        style={{
          marginRight: 6,
          marginTop: 4,
        }}
      />
    ),
  },
  bullet: {
    Icon: (
      <GoDotFill
        size={10}
        style={{
          marginRight: 6,
          marginTop: 4,
        }}
      />
    ),
  },
};

export default function SummaryListBox(props: { field: TFormField; config: TJourneyConfig; formData: TFormData }) {
  const theme = useTheme();
  const { formData, field, config } = props;
  const brandingColor = theme?.palette?.primary?.main;
  let { title, listItems, params, generalExclusions } = props.field;
  const [showAll, setShowAll] = useState(false);

  const dataSource = params?.dataSource;

  if (dataSource?.listItems) {
    listItems = _.get(formData?.values, dataSource?.listItems ?? '')?.map((item: any) => ({
      text: item?.description ?? item?.title,
      subText: item?.subText ?? item?.subTitle,
      covered: item?.covered,
    }));
  }

  if (dataSource?.generalExclusions) {
    generalExclusions = _.get(formData?.values, dataSource?.generalExclusions ?? '');
  }

  const variant = (props?.field?.variant as keyof typeof variants) ?? 'bullet';

  const hidePagination = !params?.pagination;

  const maxPerView = !hidePagination ? params?.maxItems : listItems?.length;

  const visibleItems = showAll
    ? listItems?.map((item) => ({ ...item, covered: 'covered' in item ? item?.covered : true }))
    : listItems?.slice(0, maxPerView)?.map((item) => ({ ...item, covered: 'covered' in item ? item?.covered : true }));

  const showPaginationBtn = !hidePagination && listItems && maxPerView && listItems.length > maxPerView;

  return (
    <div
      style={{
        fontFamily: 'Inter',
        border: '2px solid #E9EAFF',
        padding: 16,
        borderRadius: 8,
        backgroundColor: 'white',
      }}
    >
      {title && (
        <Typography marginBottom={2} fontSize={18} fontWeight={600} color={'primary'}>
          {title}
        </Typography>
      )}
      <List dense>
        {visibleItems?.map((item, index) => (
          <ListItem sx={{ marginBottom: -1 }} disableGutters alignItems="flex-start">
            <ListItemIcon sx={{ minWidth: '30px' }}>
              {variant === 'bullet' ? (
                <Circle sx={{ fontSize: 12,marginTop:0.3, color: theme.palette.primary.main }} />
              ) : item?.covered ? (
                <CheckIcon sx={{ fontSize: 20, color: theme.palette.primary.main }} />
              ) : (
                <Close sx={{ fontSize: 20, color: '#98A2B3' }} />
              )}
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography fontSize={16} color={'#1A2133'} fontWeight={500}>
                  {replacePlaceholders(props.formData.values, item.text)}
                </Typography>
              }
              secondary={
                !item?.subText ? null : (
                  <p
                    style={{
                      fontSize: 14,
                      color: '#677185',
                      fontStyle: 'normal',
                      fontWeight: 400,
                    }}
                  >
                    {replacePlaceholders(props.formData.values, item?.subText ?? '')}
                  </p>
                )
              }
            />
          </ListItem>
        ))}
      </List>

      {(showAll || !showPaginationBtn) && generalExclusions && generalExclusions?.length > 0 && (
        <Stack spacing={1}>
          <div
            style={{
              margin: '20px 0 20px 0',
              height: '1px',
              width: '100%',
              backgroundColor: '#E9EAFF',
            }}
          />
          <Typography fontSize={16} fontWeight={600} color={'#464C5E'}>
            General exclusions
          </Typography>

          <Box fontFamily={'Inter'} paddingLeft={3} color={'#D0D5DD'}>
            <ul>
              {generalExclusions?.map((item, index) => (
                <li style={{ marginBottom: 4 }} key={`item-${index}`}>
                  <Typography fontSize={16} color={'#1A2133'}>
                    {item?.title}
                  </Typography>
                  <Typography fontSize={14} color={'#677185'}>
                    {item?.subTitle}
                  </Typography>
                  {/* Sub list */}
                  {item?.items && (
                    <Box paddingLeft={0} color={'#D0D5DD'}>
                      <ul style={{ listStyle: 'none', paddingLeft: -2 }}>
                        {item?.items?.map((subItem, subIndex) => (
                          <li key={`sub-${subIndex}`}>
                            <Stack direction={'row'} alignItems={'flex-start'} spacing={2}>
                              <Remove sx={{ width: 20 }} />
                              <Typography paddingTop={0.2} fontSize={14} color={'#677185'}>
                                {subItem}
                              </Typography>
                            </Stack>
                          </li>
                        ))}
                      </ul>
                    </Box>
                  )}
                </li>
              ))}
            </ul>
          </Box>
          {field?.content && <Typography fontSize={16} color={'#1A2133'}>{field?.content}</Typography>}
        </Stack>
      )}

      {showPaginationBtn && (
        <Button
          variant="text"
          style={{
            textTransform: 'capitalize',
          }}
          startIcon={showAll ? <BiChevronUp /> : <BiChevronDown />}
          onClick={() => setShowAll(!showAll)}
          color="primary"
        >
          {showAll ? 'Show Less' : 'Show More'}
        </Button>
      )}
    </div>
  );
}
