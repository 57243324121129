export const Titles = [
  { label: "Mr", value: "Mr" },
  { label: "Mrs", value: "Mrs" },
  { label: "Ms", value: "Ms" },
  { label: "Miss", value: "Miss" },
  { label: "Mx", value: "Mx" },
  { label: "Admiral", value: "Admiral" },
  { label: "Advocate", value: "Advocate" },
  { label: "Air Chief Marshal", value: "Air Chief Marshal" },
  { label: "Air Commodore", value: "Air Commodore" },
  { label: "Air Marshal", value: "Air Marshal" },
  { label: "Air Vice Marshall", value: "Air Vice Marshall" },
  { label: "Airman", value: "Airman" },
  { label: "An tUasal", value: "An tUasal" },
  { label: "Baron", value: "Baron" },
  { label: "Baroness", value: "Baroness" },
  { label: "Bean", value: "Bean" },
  { label: "Bombardier", value: "Bombardier" },
  { label: "Brigadier", value: "Brigadier" },
  { label: "Brother", value: "Brother" },
  { label: "Canon", value: "Canon" },
  { label: "Captain", value: "Captain" },
  { label: "Cardinal", value: "Cardinal" },
  { label: "Chief", value: "Chief" },
  { label: "Colonel", value: "Colonel" },
  { label: "Colour Sargeant", value: "Colour Sargeant" },
  { label: "Commander", value: "Commander" },
  { label: "Commodore", value: "Commodore" },
  { label: "Corporal", value: "Corporal" },
  { label: "Councillor", value: "Councillor" },
  { label: "Count", value: "Count" },
  { label: "Countess", value: "Countess" },
  { label: "Dame", value: "Dame" },
  { label: "Dean", value: "Dean" },
  { label: "Doctor", value: "Doctor" },
  { label: "Duchess", value: "Duchess" },
  { label: "Estate Of", value: "Estate Of" },
  { label: "Executor(s) of", value: "Executor(s) of" },
  { label: "Father", value: "Father" },
  { label: "Field Marshal", value: "Field Marshal" },
  { label: "Flight Lieutenant", value: "Flight Lieutenant" },
  { label: "Flight Officer", value: "Flight Officer" },
  { label: "Flight Sergeant", value: "Flight Sergeant" },
  { label: "General", value: "General" },
  { label: "Group Captain", value: "Group Captain" },
  { label: "Gunner", value: "Gunner" },
  { label: "Her Excellency", value: "Her Excellency" },
  { label: "His Excellency", value: "His Excellency" },
  { label: "Honourable", value: "Honourable" },
  { label: "HRH", value: "HRH" },
  { label: "Judge", value: "Judge" },
  { label: "Lady", value: "Lady" },
  { label: "Lance Bombardier", value: "Lance Bombardier" },
  { label: "Lance Corporal", value: "Lance Corporal" },
  { label: "Lieutenant", value: "Lieutenant" },
  { label: "Lieutenant Colonel", value: "Lieutenant Colonel" },
  { label: "Lieutenant Commander", value: "Lieutenant Commander" },
  { label: "Lieutenant General", value: "Lieutenant General" },
  { label: "Lord", value: "Lord" },
  { label: "Madam", value: "Madam" },
  { label: "Major", value: "Major" },
  { label: "Major General", value: "Major General" },
  { label: "Marquis", value: "Marquis" },
  { label: "Marshal Of The Royal Air Force", value: "Marshal Of The Royal Air Force" },
  { label: "Master", value: "Master" },
  { label: "Master Sergeant", value: "Master Sergeant" },
  { label: "Mother", value: "Mother" },
  { label: "Mr Justice", value: "Mr Justice" },
  { label: "Pilot Officer", value: "Pilot Officer" },
  { label: "Prince", value: "Prince" },
  { label: "Princess", value: "Princess" },
  { label: "Private", value: "Private" },
  { label: "Professor", value: "Professor" },
  { label: "Provost", value: "Provost" },
  { label: "Rabbi", value: "Rabbi" },
  { label: "Rear Admiral", value: "Rear Admiral" },
  { label: "Regimental Sergeant Major", value: "Regimental Sergeant Major" },
  { label: "Reverend", value: "Reverend" },
  { label: "Reverend Doctor", value: "Reverend Doctor" },
  { label: "Right Reverend", value: "Right Reverend" },
  { label: "Sachem", value: "Sachem" },
  { label: "Sergeant", value: "Sergeant" },
  { label: "Sheikh", value: "Sheikh" },
  { label: "Sheriff", value: "Sheriff" },
  { label: "Sir", value: "Sir" },
  { label: "Sister", value: "Sister" },
  { label: "Squadron Leader", value: "Squadron Leader" },
  { label: "Staff Sergreant", value: "Staff Sergreant" },
  { label: "Surgeon Captain", value: "Surgeon Captain" },
  { label: "The Duke of", value: "The Duke of" },
  { label: "The Earl of", value: "The Earl of" },
  { label: "The Honourable Lady", value: "The Honourable Lady" },
  { label: "The Honourable Miss", value: "The Honourable Miss" },
  { label: "The Honourable Mrs", value: "The Honourable Mrs" },
  { label: "The Honourable Sir", value: "The Honourable Sir" },
  { label: "The Marquess of", value: "The Marquess of" },
  { label: "The Most Reverend", value: "The Most Reverend" },
  { label: "The Right Honourable", value: "The Right Honourable" },
  { label: "The Venerable", value: "The Venerable" },
  { label: "Trustees Of", value: "Trustees Of" },
  { label: "Very Reverend", value: "Very Reverend" },
  { label: "Vice Admiral", value: "Vice Admiral" },
  { label: "Viscount", value: "Viscount" },
  { label: "Viscountess", value: "Viscountess" },
  { label: "Warrant Officer 1", value: "Warrant Officer 1" },
  { label: "Warrant Officer 2", value: "Warrant Officer 2" },
  { label: "Wing Commander", value: "Wing Commander" },
];
