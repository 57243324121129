import { Button, Container, Grid, Stack, Box, Theme, createTheme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dispatch, SetStateAction, useContext, useState } from 'react';
import { TBranding, TJourneyConfig, TJourneyMode, TProductData } from '../types';
import { Branding } from './Branding';
import { LoadingIndicator } from './LoadingIndicator';
import { ProductsContext } from '../ProductsApiContext';
import { getEnvParams } from '../utils';
import { getJourneyById, getJourneyBId } from '../journeyConfigs';
import { V2RenderVersionThemeExtras } from '../defaultBranding';
import { hexToRGBA } from '../utils';

const envParams = getEnvParams();

export type TJourneyPickerProps = {
  mode: TJourneyMode;
  journeys: TProductData[];
  branding?: TBranding;
  setActiveJourney: (journey: TJourneyConfig) => void;
  productsLoading: boolean;
  theme: Theme;
  setTheme: Dispatch<SetStateAction<Theme>>;
};

export const JourneyPicker = (props: TJourneyPickerProps) => {
  const { journeys, mode, setActiveJourney, productsLoading } = props;
  const matches = useMediaQuery('(min-width:1200px)');
  const [isLoading, setIsLoading] = useState(false);
  const { updateProductData } = useContext(ProductsContext);
  const { productId } = envParams;

  const openJourney = async (obj: TProductData) => {
    if (mode === 'TEST') {
      setActiveJourney(obj);
      return;
    } else {
      setIsLoading(true);
      const journeyConfig = getJourneyBId(obj.id, journeys);
      setActiveJourney({
        ...journeyConfig,
        product: {
          ...journeyConfig?.product,
          title: journeyConfig?.product?.title ?? obj?.productName,
        },
      } as TJourneyConfig);
      updateProductData(obj);

      const v2Theme = createTheme(
        journeyConfig?.renderVersion === 'V2'
          ? {
              ...V2RenderVersionThemeExtras,
              palette: {
                primary: {
                  main: props.theme.palette.primary.main ?? '#3B90CF',
                },
                secondary: {
                  main: hexToRGBA(props.theme.palette.primary.main, 0.08),
                },
              },
            }
          : {
              palette: {
                primary: {
                  main: props.theme.palette.primary.main ?? '#3B90CF',
                },
              },
            },
      );

      console.log(journeyConfig?.renderVersion, v2Theme);

      props.setTheme(v2Theme as any);
    }
    setIsLoading(false);
  };

  const renderShopfrontText = () => {
    return (
      <>
        <h1 className="shopfront">Online products</h1>
        <h4>Insurance made simple, just for you.</h4>
        <p>
          Demystify the insurance process! We’re committed to providing you with the best insurance options for your
          specific needs. Get covered effortlessly today using our easy-to-use platform.
        </p>
        <p>Browse our comprehensive covers - instant quotes are just a few clicks away.</p>
      </>
    );
  };

  const renderJourneyCards = () => {
    return (
      <Grid container rowSpacing={3} spacing={3}>
        {journeys.map((journey) => (
          <Grid
            key={journey.productName ?? journey.product.title}
            item
            xs={12}
            sm={6}
            lg={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {renderJourneyCard(journey)}
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderInitialJourneySection = () => {
    return <Stack spacing={3} style={{ backgroundColor: 'red' }}></Stack>;
  };

  const renderInitialJourneyCard = () => {
    return (
      <Stack className="card fade-out" spacing={2} alignItems="center" justifyContent="space-between">
        <Stack width={'100%'} spacing={2}>
          <div
            className="cardImage"
            style={{
              backgroundSize: 'cover',
            }}
          ></div>
          <h3 className="cardHeader" style={{ whiteSpace: 'pre-wrap' }}></h3>
        </Stack>
        <p className="cardText"></p>
        <div
          className="emptyBox"
          style={{
            backgroundSize: 'cover',
          }}
        ></div>
        <Button
          variant="contained"
          className="cardButton"
          sx={{ textTransform: 'none', fontFamily: 'Poppins', backgroundColor: props.branding?.main_color }}
        ></Button>
      </Stack>
    );
  };

  const initalEmptyCards = [
    renderInitialJourneyCard,
    renderInitialJourneyCard,
    renderInitialJourneyCard,
    renderInitialJourneyCard,
    renderInitialJourneyCard,
    renderInitialJourneyCard,
  ];

  const renderInitialJourneyCards = () => {
    return (
      <Grid container rowSpacing={3} spacing={3}>
        {initalEmptyCards.map((card, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            lg={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            {renderInitialJourneyCard()}
          </Grid>
        ))}
      </Grid>
    );
  };

  const renderJourneyCard = (journey: TProductData) => {
    // in test mode the data comes from local configs
    const getImagePath =
      mode === 'TEST'
        ? `url(img/products/${journey.product.image ?? 'placeholder.png'})`
        : `url(${journey.images.full ?? 'placeholder.png'})`;

    return (
      <Stack className="card fade-in" spacing={2} alignItems="center" justifyContent="space-between">
        <Stack width={'100%'} spacing={2}>
          <div
            className="cardImage"
            style={{
              backgroundImage: getImagePath,
              backgroundSize: 'cover',
            }}
          ></div>
          <h3 className="cardHeader" style={{ whiteSpace: 'pre-wrap' }}>
            {journey.productName ?? journey.product.title}
          </h3>
          {journey.subtitle && <small className="cardSubtitle">{journey.subtitle}</small>}
        </Stack>
        <p className="cardText">{journey.description ?? journey?.product?.description}</p>
        <Button
          variant="contained"
          className="cardButton"
          onClick={() => openJourney(journey)}
          sx={{ textTransform: 'none', fontFamily: 'Poppins' }}
        >
          Buy now
        </Button>
      </Stack>
    );
  };

  console.log('tester', journeys.length, productsLoading);
  let count = 0;
  return matches ? (
    <div style={{ width: '100%' }}>
      {props.branding && <Branding branding={props.branding} />}
      {productId != null && productsLoading && (
        <Box
          id="wljHeader"
          sx={{
            backgroundColor: props.branding?.main_color,
            height: '10vh',
            width: '100vw',
            minHeight: '96px',
          }}
        ></Box>
      )}
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Container maxWidth="xl">
            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20 }}>
              <div style={{ flex: 1, padding: 20 }}>
                <Stack spacing={3}>{renderShopfrontText()}</Stack>
              </div>
              <div style={{ flex: 3, padding: 32 }}>
                {productsLoading ? renderInitialJourneyCards() : renderJourneyCards()}
              </div>
            </div>
          </Container>
          {journeys.length === 0 && !productsLoading && (
            <Container maxWidth="sm" sx={{ backgroundColor: 'F4F5FA', marginTop: 4 }}>
              <h3>There are no products available.</h3>
            </Container>
          )}
        </>
      )}
    </div>
  ) : (
    <>
      {props.branding && <Branding branding={props.branding} />}
      {productId != null && productsLoading && (
        <Box
          id="wljHeader"
          sx={{
            backgroundColor: props.branding?.main_color,
            height: '10vh',
            width: '100vw',
            minHeight: '96px',
          }}
        ></Box>
      )}
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          <Container maxWidth="sm" sx={{ marginTop: 4 }}>
            <Stack spacing={3}>{renderShopfrontText()}</Stack>
          </Container>
          {journeys.length > 0 && (
            <Container maxWidth="md" sx={{ marginTop: 8, marginBottom: 8 }}>
              {productsLoading ? renderInitialJourneyCards() : renderJourneyCards()}
            </Container>
          )}
          {journeys.length === 0 && !productsLoading && (
            <Container maxWidth="sm" sx={{ backgroundColor: 'F4F5FA', marginTop: 4 }}>
              <h3>There are no products available.</h3>
            </Container>
          )}
        </>
      )}
    </>
  );
};
