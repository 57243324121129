import React, { useContext } from 'react';
import { TBranding, TFormData, TJourneyConfig } from '../types';
import { Stack, Typography } from '@mui/material';
import { AppliedContext } from '../AppliedContext';

export default function DynamicFooter(props: {
  config?: TJourneyConfig;
  formData?: TFormData;
  branding: TBranding | undefined;
}) {
  const { branding } = props;
  const { selectedAppliedQuote } = useContext(AppliedContext);


  const brokerFooter = branding?.regulatoryFooter;

  const contactEmail = branding?.servicing_email;
  const contactNumber = branding?.servicing_phone;

  const insurerName = selectedAppliedQuote?.schemeInsurerName;
  const insurerFRN = selectedAppliedQuote?.frn;

  return (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      justifyContent={'space-evenly'}
      spacing={2}
      padding={4}
      marginTop={"15%"}
      borderTop={'1px solid #E9EAFF'}
    >
      <Stack fontSize={12} spacing={1} fontFamily={'Inter'} color={'#667085'} maxWidth={1024}>
        <Typography>{brokerFooter}</Typography>

        <Typography>
          Stubben Edge (Risk) Limited is authorised and regulated by the Financial Conduct Authority FRN:943286, with
          company number 09073942 and is registered in England and Wales with its registered office at 77 Cornhill,
          London, EC3V 3QQ.
        </Typography>
        {insurerName && (
          <Typography>
            The policy is underwritten by {insurerName}. {insurerName} is registered in the UK and is authorised by the
            Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential
            Regulation Authority. Firm Reference Number: {insurerFRN}
          </Typography>
        )}
      </Stack>
      <Stack fontSize={12} fontFamily={'Inter'} minWidth={300}>
        <Typography color={branding?.main_color ?? 'primary'}>Need help?</Typography>
        <Typography color={'#565E73'}>
          Call <b>{contactNumber}</b> or email <b>{contactEmail}</b>
        </Typography>
      </Stack>
    </Stack>
  );
}
