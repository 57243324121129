import { IconButton, LinearProgress, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  TJourneySection,
  TFormData,
  TFieldChangeHandler,
  TJourneyConfig,
  TJourneyMode,
  IStringIndex,
  TBranding,
} from '../types';
import { getEnvParams, substituteForHeadings } from '../utils';
import { renderFields } from './FormFields/fields';
import PaymentSection from './payment/stripe/PaymentSection';
import { Dispatch, SetStateAction } from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { JourneyStepper } from './JourneyStepper';

export const JourneySection = (props: {
  section: TJourneySection;
  formData: TFormData;
  changeHandler: TFieldChangeHandler;
  disabled?: boolean;
  config: TJourneyConfig;
  mode: TJourneyMode;
  branding?: TBranding;
  indexes?: { active: number; quote: number; final: number };
  submitQuoteUpdate?: (values: IStringIndex<any>) => void;
  paymentComponentRef?: any;
  setBusy?: Dispatch<SetStateAction<boolean>>;
  setFormData?: Dispatch<SetStateAction<TFormData>>;
  onBack?: () => void;
}) => {
  const {
    section,
    formData,
    changeHandler,
    disabled,
    config,
    mode,
    indexes,
    submitQuoteUpdate,
    onBack,
    setFormData,
    setBusy,
  } = props;
  const { values } = formData;
  const theme = useTheme();

  // console.log("Render journey section", section.name);
  // UGLY
  const progress =
    indexes?.active !== undefined &&
    // indexes?.quote !== undefined &&
    indexes?.final !== undefined &&
    // indexes.quote > 0 &&
    indexes.final > 0
      ? Math.floor((indexes.active / indexes.final) * 100)
      : undefined;

  let stepToQuote = indexes?.quote;

  if (stepToQuote! > 0 && indexes!.active !== 0) {
    stepToQuote = stepToQuote! - indexes!.active;
  }

  const productId = config?.product.id;

  let currentStep;
  if (indexes !== undefined) {
    currentStep = indexes?.active === 0 ? indexes!.final + 1 : indexes!.final + 1 - indexes!.active;
  }

  const stepText = getStepperText(indexes, formData, config);

  const renderBackButton = () => {
    return (
      <Stack direction="row" alignItems="center">
        <IconButton color="primary" component="span" onClick={onBack!} disabled={disabled} title="Back">
          <ArrowBackIcon />
        </IconButton>
      </Stack>
    );
  };

  return (
    <Stack spacing={3}>
      {config?.renderVersion === 'V2' ? (
        <JourneyStepper
          steps={config.sections?.map((s) => s.name)}
          currentStep={indexes?.active ?? 0}
          config={config}
        />
      ) : (
        progress !== undefined && (
          <>
            <LinearProgress variant="determinate" value={progress} />
            <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
              {onBack ? renderBackButton() : <div></div>}
              <p>{stepText}</p>
            </Stack>
          </>
        )
      )}
      {section.heading &&
        (config?.renderVersion === 'V2' ? (
          <Typography color={'primary'} fontSize={32} fontWeight={600} fontFamily={'Poppins'}>
            {substituteForHeadings(section.heading, values)}
          </Typography>
        ) : (
          <h1 className="sectionHeading">{substituteForHeadings(section.heading, values)}</h1>
        ))}
      {section?.subHeading && (
        <h2 className="sectionSubHeading">{substituteForHeadings(section.subHeading, values)}</h2>
      )}
      {section.description && <p style={{ marginTop: 24 }}>{section.description}</p>}
      {section?.isPayment && (
        <PaymentSection
          setBusy={props.setBusy}
          paymentComponentRef={props.paymentComponentRef}
          submitQuoteUpdate={submitQuoteUpdate}
          formData={formData}
          config={config}
        />
      )}
      {section.fields && (
        <Stack spacing={2}>
          {renderFields(config, section.fields, formData, changeHandler, disabled, mode, setFormData, setBusy)}
        </Stack>
      )}
    </Stack>
  );
};

function getStepperText(
  indexes: { active: number; quote: number; final: number } | undefined,
  formData: TFormData,
  config: TJourneyConfig,
) {
  if (!indexes) return;

  const usesOldStep = [
    'pro-87b397be-396b-4f36-9590-873548e94f5e',
    'pro-af131b02-5d32-45be-ab7f-721199896c69',
    'pro-75791180-70b9-4ec7-8bb5-d20a69787d69',
  ];

  const { active, quote, final } = indexes;

  if (usesOldStep.includes(config?.product?.id ?? '')) {
    return `Step ${active + 1} of ${final + 1}`;
  }

  const isMonthly = formData?.values?.paymentFrequency === 'MONTHLY';
  const PCL_STEPS = config?.sections?.filter((section) =>
    ['monthlyPaymentConfirmation', 'premiumCredit'].includes(section.name),
  )?.length;
  const STRIPE_STEPS = 1;

  if (active < quote) {
    const steps = quote - active;
    return `${steps} simple step${steps !== 1 ? 's' : ''} to see your tailored quote`;
  }

  const stepsToBuyOnline = isMonthly ? final - active : final - PCL_STEPS - active + STRIPE_STEPS;

  if (active < final) {
    return `${stepsToBuyOnline} simple step${stepsToBuyOnline !== 1 ? 's' : ''} to buy online`;
  }

  if (active === final) {
    return 'Final step';
  }

  return;
}
