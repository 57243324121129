export const FuelType = [
  {
    "label": "Alcohol",
    "value": "013"
  },
  {
    "label": "Biofuel - Petrol",
    "value": "011"
  },
  {
    "label": "Biofuel Only",
    "value": "010"
  },
  {
    "label": "CNG - Petrol",
    "value": "014"
  },
  {
    "label": "Compressed Natural Gas (CNG)",
    "value": "006"
  },
  {
    "label": "Diesel",
    "value": "001"
  },
  {
    "label": "Electric - Diesel",
    "value": "008"
  },
  {
    "label": "Electric - Petrol",
    "value": "009"
  },
  {
    "label": "Electric Only",
    "value": "007"
  },
  {
    "label": "LPG",
    "value": "003"
  },
  {
    "label": "LPG - Petrol",
    "value": "012"
  },
  {
    "label": "Petrol",
    "value": "002"
  }
]