import { useContext, useEffect, useState } from 'react';
import { JourneyContext } from '../../JourneyContext';
import { Alert } from '@mui/material';
import { TFormData, TFormField, TJourneyConfig } from '../../types';
import { replacePlaceholders } from '../../formHandling';
import CustomAlert from './CustomAlert';

// eslint-disable-next-line no-template-curly-in-string
const servicePhoneString = '${SERVICE_PHONE}';

export const Blocker = (props: { field: TFormField; formData: TFormData; config: TJourneyConfig }) => {
  const journeyContext = useContext(JourneyContext);
  const title = props.field.title;
  const servicePhoneNumber = journeyContext?.branding?.servicing_phone;

  if (title?.includes(servicePhoneString)) {
    const splittedTitle = title.split(servicePhoneString);

    if (splittedTitle.length === 2) {
      return (
        <p>
          {splittedTitle[0]}
          <a href={`tel:${servicePhoneNumber}`} style={{ color: 'rgb(0,0,250)' }}>
            {servicePhoneNumber}
          </a>
          {splittedTitle[1]}
        </p>
      );
    }
  }

  if (props?.config?.renderVersion === 'V2') {
    return <CustomAlert variant={'filled'} severity={'error'}>{replacePlaceholders(props.formData.values, props.field.title ?? '')}</CustomAlert>;
  }

  if (props.field.variant && ['warning', 'error'].includes(props.field.variant)) {
    return (
      <Alert sx={{ fontWeight: '600' }} variant="standard" severity={(props.field.variant as any) ?? 'info'}>
        {replacePlaceholders(props.formData.values, props.field.title ?? '')}
      </Alert>
    );
  }
  return <b>{props.field.title}</b>;
};
