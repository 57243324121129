import { Stack, TextField, Button } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";

const regEx = new RegExp(
  /(^[A-Z]{2}[0-9]{2}\s?[A-Z]{3}$)|(^[A-Z][0-9]{1,3}[A-Z]{3}$)|(^[A-Z]{3}[0-9]{1,3}[A-Z]$)|(^[0-9]{1,4}[A-Z]{1,2}$)|(^[0-9]{1,3}[A-Z]{1,3}$)|(^[A-Z]{1,2}[0-9]{1,4}$)|(^[A-Z]{1,3}[0-9]{1,3}$)|(^[A-Z]{1,3}[0-9]{1,4}$)|(^[0-9]{3}[DX]{1}[0-9]{3}$)/
);

const getInputProps = (value: string, valid: boolean) => {
  return value
    ? {
        style: { fontWeight: "bold", backgroundColor: valid ? "lightgreen" : "rgba(255,0,0,0.25)" },
      }
    : { style: { fontWeight: "bold" } };
};

export const RegistrationInput = (props: {
  VRM: string;
  onSubmit: (VRM: string) => void;
  busy: boolean;
}) => {
  const [VRM, setVRM] = useState(props.VRM);
  const valid = regEx.test(VRM);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newVRM = event.target.value.toUpperCase().replace(/[^0-9a-z]/gi, "");
    setVRM(newVRM);
  };

  const handleSubmit = () => {
    setVRM(VRM.trim());
    props.onSubmit(VRM);
  };

  return (
    <Stack direction="row" spacing={2} alignItems="center">
      <TextField
        value={VRM}
        onChange={handleChange}
        required
        variant="outlined"
        inputProps={getInputProps(VRM, valid)}
        disabled={props.busy}
        sx={{ flex: 1 }}
      />
      <LoadingButton
        startIcon={<SearchIcon />}
        variant="contained"
        onClick={handleSubmit}
        disabled={!regEx.test(VRM)}
        loading={props.busy}
        size="large"
        sx={{ flex: 1 }}
      >
        Find Vehicle
      </LoadingButton>
    </Stack>
  );
};
