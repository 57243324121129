import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { TFieldChangeHandler, TFieldOption, TFormField } from '../../types';
import { useMemo, useState } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function SelectMultiField(props: { field: TFormField; value: any; changeHandler: TFieldChangeHandler }) {
  const { field, value, changeHandler } = props;

  return (
    <Autocomplete
      disabled={field.disabled}
      multiple
      id={field.name}
      value={value ?? []}
      onChange={(_event, newValue) => {
        changeHandler(field, newValue);
      }}
      options={field.options ?? []}
      disableCloseOnSelect
      getOptionLabel={(option) => option.label}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
          {option.label}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => <TextField {...params} label={field.title} placeholder="" />}
    />
  );
}
