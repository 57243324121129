import { Alert, AlertTitle, Box, Container } from "@mui/material";

export const CenteredError = (props: { title?: string; content?: string }) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh">
      <Container maxWidth="sm" sx={{ backgroundColor: "white", borderRadius: "20px", padding: 4 }}>
        <Alert severity="error" sx={{ border: "1px solid rgba(0,0,0,0.5)", width: "100%" }}>
          {<AlertTitle>{props?.title || "Uh oh"}</AlertTitle>}
          {props?.content || "An error has occurred."}
        </Alert>
      </Container>
    </Box>
  );
};
