import { Typography } from '@mui/material';
import React from 'react';
import { TFormField } from '../../types';

export default function SectionHeading({ field }: { field: TFormField }) {
  return (
    <Typography marginTop={3} fontSize={24} fontFamily={'Poppins'} fontWeight={600} color={'primary'}>
      {field.content}
    </Typography>
  );
}
