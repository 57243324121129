import { createContext, useState } from "react";
import React from "react";

export const PaymentContext = createContext<any>({});

function PaymentContextProvider({ children }: any) {
  const [paymentConfirmed, setPaymentConfirmed] = useState<boolean>(false);
  const [paymentDetails, setPaymentDetails] = useState(null);

  const updatePaymentDetails = (value: any) => setPaymentDetails(value);
  const updatePaymentStatus = (value: boolean) => setPaymentConfirmed(value);
  return (
    <PaymentContext.Provider value={{ paymentConfirmed, updatePaymentStatus,paymentDetails,updatePaymentDetails }}>
      {children}
    </PaymentContext.Provider>
  );
}

export default PaymentContextProvider;
