export const TaxiPSVLicencingAuthorities = [
  {
    "label": "Aberdeen City, AB25 3RF",
    "value": "362"
  },
  {
    "label": "Allerdale Borough Council, CA14 3YJ",
    "value": "002"
  },
  {
    "label": "Amber Valley Borough Council, DE5 3XE",
    "value": "004"
  },
  {
    "label": "Angus, DD8 1AN",
    "value": "364"
  },
  {
    "label": "Argyll & Bute, PA31 8RT",
    "value": "365"
  },
  {
    "label": "Arun District Council, BN17 5LF",
    "value": "005"
  },
  {
    "label": "Ashfield District Council, NG17 8DA",
    "value": "006"
  },
  {
    "label": "Ashford Borough Council, TN23 1PL",
    "value": "007"
  },
  {
    "label": "Badenoch & Strathspey (Kingussie), PH21 1EJ",
    "value": "439"
  },
  {
    "label": "Barnsley Metropolitan Council, S70 2HG",
    "value": "012"
  },
  {
    "label": "Barrow-in-Furness Brgh Cnl, LA14 2LD",
    "value": "013"
  },
  {
    "label": "Basildon District Council, SS14 1DL",
    "value": "014"
  },
  {
    "label": "Basingstoke & Deane Brgh Cnl, RG21 4AH",
    "value": "015"
  },
  {
    "label": "Bassetlaw District Council, S80 2AH",
    "value": "016"
  },
  {
    "label": "Bath & North East Somerset Cnl, BA1 3EL",
    "value": "017"
  },
  {
    "label": "Bedford Borough Council, MK42 9AP",
    "value": "018"
  },
  {
    "label": "Belfast, BT2 8GD",
    "value": "435"
  },
  {
    "label": "Blaby District Council, LE19 2EP",
    "value": "021"
  },
  {
    "label": "Blackburn with Darwen Brgh Cnl, BB1 7DY",
    "value": "022"
  },
  {
    "label": "Blackpool Borough Council, FY1 4PW",
    "value": "023"
  },
  {
    "label": "Blaneau Gwent County Brgh Cnl, NP23 6XB",
    "value": "394"
  },
  {
    "label": "Blyth Valley Borough Council, NE25 0DX",
    "value": "024"
  },
  {
    "label": "Bolsover District Council, S44 6NF",
    "value": "025"
  },
  {
    "label": "Bolton Metropolitan Brgh Cnl, BL1 1RU",
    "value": "026"
  },
  {
    "label": "Boston Borough Council, PE21 8QR",
    "value": "027"
  },
  {
    "label": "Bournemouth Borough Council, BH2 6DY",
    "value": "028"
  },
  {
    "label": "Bracknell Forest Borough Cnl, RG12 1JD",
    "value": "029"
  },
  {
    "label": "Braintree District Council, CM7 9HB",
    "value": "030"
  },
  {
    "label": "Breckland Council, NR19 1EE",
    "value": "031"
  },
  {
    "label": "Brentwood Borough Council, CM15 8AY",
    "value": "033"
  },
  {
    "label": "Bridgend County Borough Cnl, CF31 4WB",
    "value": "395"
  },
  {
    "label": "Bridgnorth District Council, WV16 5AA",
    "value": "034"
  },
  {
    "label": "Brighton & Hove Council, BN3 3BQ",
    "value": "035"
  },
  {
    "label": "Bristol City Council, BS3 4AG",
    "value": "036"
  },
  {
    "label": "Broadland District Council, NR7 0DU",
    "value": "037"
  },
  {
    "label": "Bromsgrove District Council, B61 8DA",
    "value": "039"
  },
  {
    "label": "Broxbourne Borough Council, EN8 9XQ",
    "value": "040"
  },
  {
    "label": "Broxtowe Borough Council, NG9 1AB",
    "value": "041"
  },
  {
    "label": "Buckinghamshire Council, HP20 1UA",
    "value": "460"
  },
  {
    "label": "Burnley Borough Council, BB11 2BY",
    "value": "042"
  },
  {
    "label": "Bury Metropolitan Brgh Cnl, BL9 0SW",
    "value": "043"
  },
  {
    "label": "Caerphilly County Borough Cnl, CF82 7PG",
    "value": "396"
  },
  {
    "label": "Caithness (Wick), KW1 4AB",
    "value": "440"
  },
  {
    "label": "Calderdale Metro Brgh Cnl, HX1 1PS",
    "value": "044"
  },
  {
    "label": "Cambridge City Council, CB2 1BY",
    "value": "045"
  },
  {
    "label": "Cannock Chase District Council, WS11 1BG",
    "value": "047"
  },
  {
    "label": "Canterbury City Council, CT1 1YW",
    "value": "048"
  },
  {
    "label": "Cardiff County Council, CF10 3ND",
    "value": "397"
  },
  {
    "label": "Carlisle City Council, CA3 8QG",
    "value": "050"
  },
  {
    "label": "Carmarthenshire County Council, SA31 1LE",
    "value": "398"
  },
  {
    "label": "Castle Point Borough Council, SS7 1TF",
    "value": "053"
  },
  {
    "label": "Central Bedfordshire District Cnl, LU6 1LF",
    "value": "187"
  },
  {
    "label": "Ceredigion County Council, SA46 0PA",
    "value": "399"
  },
  {
    "label": "Chelmsford Borough Council, CM1 1JE",
    "value": "055"
  },
  {
    "label": "Cheltenham Borough Council, GL50 1PP",
    "value": "056"
  },
  {
    "label": "Cherwell District Council, OX15 4AA",
    "value": "057"
  },
  {
    "label": "Cheshire East Council, CW1 2BJ",
    "value": "448"
  },
  {
    "label": "Chester City Council, CH1 2HS",
    "value": "058"
  },
  {
    "label": "Chester-le-Street District Cnl, DH3 3UT",
    "value": "060"
  },
  {
    "label": "Chesterfield Borough Council, S40 1LP",
    "value": "059"
  },
  {
    "label": "Chichester District Council, PO19 1TY",
    "value": "061"
  },
  {
    "label": "Christchurch Borough Council, BH23 1AZ",
    "value": "064"
  },
  {
    "label": "City and County of Swansea Cnl, SA1 3SN",
    "value": "400"
  },
  {
    "label": "City of Birmingham, B6 5RQ",
    "value": "065"
  },
  {
    "label": "City of Bradford, BD7 1PU",
    "value": "066"
  },
  {
    "label": "City of Coventry, CV3 4AR",
    "value": "067"
  },
  {
    "label": "City of Edinburgh, EH1 1YJ",
    "value": "373"
  },
  {
    "label": "City of Leeds, LS9 7RY",
    "value": "068"
  },
  {
    "label": "City of Liverpool, L2 2DH",
    "value": "069"
  },
  {
    "label": "City of Newcastle-Upon-Tyne, NE6 5BD",
    "value": "071"
  },
  {
    "label": "City of Sheffield, S1 2HH",
    "value": "072"
  },
  {
    "label": "City of Sunderland, SR2 7DN",
    "value": "073"
  },
  {
    "label": "City of Wakefield, WF4 5DA",
    "value": "074"
  },
  {
    "label": "Clackmannanshire, FK10 2AD",
    "value": "366"
  },
  {
    "label": "Colchester Borough Council, CO3 3WG",
    "value": "077"
  },
  {
    "label": "Copeland Borough Council, CA28 7NY",
    "value": "079"
  },
  {
    "label": "Cornwall Council (Carrick Zone), TR1 1EB",
    "value": "051"
  },
  {
    "label": "Cornwall Council (Kerrier Zone), TR14 8SX",
    "value": "162"
  },
  {
    "label": "Cornwall Council (North Cornwall Zone), PL27 6TW",
    "value": "199"
  },
  {
    "label": "Cornwall Council (Penwith Zone), TR18 3QW",
    "value": "223"
  },
  {
    "label": "Cornwall Council (Restormel Zone), PL25 5DR",
    "value": "235"
  },
  {
    "label": "Craven District Council, BD23 1FJ",
    "value": "082"
  },
  {
    "label": "Crawley Borough Council, RH10 1UZ",
    "value": "083"
  },
  {
    "label": "Darlington Borough Council, DL1 5RF",
    "value": "087"
  },
  {
    "label": "Dartford Borough Council, DA1 1DR",
    "value": "088"
  },
  {
    "label": "Derby City Council, DE1 2FS",
    "value": "090"
  },
  {
    "label": "Derbyshire Dales District Cnl, DE4 3NN",
    "value": "091"
  },
  {
    "label": "Dudley Metropolitan Borough, DY1 1HL",
    "value": "096"
  },
  {
    "label": "Dumfries & Galloway, DG9 7JJ",
    "value": "367"
  },
  {
    "label": "Dundee City, DD1 3BY",
    "value": "368"
  },
  {
    "label": "Durham City Council, DH1 9HZ",
    "value": "097"
  },
  {
    "label": "East Cambridgeshire Dist Cnl, CB7 4EE",
    "value": "100"
  },
  {
    "label": "East Devon District Council, EX10 8HL",
    "value": "101"
  },
  {
    "label": "East Dorset District Council, BH21 4HN",
    "value": "102"
  },
  {
    "label": "East Dunbartonshire, G66 1TF",
    "value": "370"
  },
  {
    "label": "East Hampshire District Cnl, GU31 4EX",
    "value": "103"
  },
  {
    "label": "East Hertfordshire Dist Cnl, SG13 8EQ",
    "value": "104"
  },
  {
    "label": "East Kilbride, G74 1AB",
    "value": "436"
  },
  {
    "label": "East Lindsey District Council, LN11 8UP",
    "value": "105"
  },
  {
    "label": "East Lothian, EH41 3HA",
    "value": "371"
  },
  {
    "label": "East Riding Council, HU17 9BA",
    "value": "107"
  },
  {
    "label": "East Staffordshire Borough Cnl, DE14 2JJ",
    "value": "108"
  },
  {
    "label": "East Suffolk Council, IP12 1RT",
    "value": "456"
  },
  {
    "label": "Eastbourne Borough Council, BN21 4TW",
    "value": "109"
  },
  {
    "label": "Eastleigh Borough Council, SO50 9YN",
    "value": "110"
  },
  {
    "label": "Eden District Council, CA11 7QF",
    "value": "111"
  },
  {
    "label": "Ellesmere Port & Neston BC, CH65 0BE",
    "value": "112"
  },
  {
    "label": "Elmbridge Borough Council, KT10 9SD",
    "value": "113"
  },
  {
    "label": "Epping Forest Borough Council, CM16 4BZ",
    "value": "115"
  },
  {
    "label": "Epsom & Ewell Borough Council, KT18 5BY",
    "value": "116"
  },
  {
    "label": "Erewash Borough Council, DE7 5RP",
    "value": "117"
  },
  {
    "label": "Exeter City Council, EX1 1RQ",
    "value": "118"
  },
  {
    "label": "Falkirk, FK2 7YZ",
    "value": "374"
  },
  {
    "label": "Fareham Borough Council, PO16 7AZ",
    "value": "119"
  },
  {
    "label": "Fenland District Council, PE15 8NQ",
    "value": "120"
  },
  {
    "label": "Fife, KY7 5LT",
    "value": "375"
  },
  {
    "label": "Flintshire County Council, CH7 6NB",
    "value": "403"
  },
  {
    "label": "Fylde Borough Council, FY8 1LW",
    "value": "123"
  },
  {
    "label": "Gateshead Metropolitan BC, NE8 1HH",
    "value": "124"
  },
  {
    "label": "Gedling Borough Council, NG5 6LU",
    "value": "125"
  },
  {
    "label": "Gloucester City Council, GL1 2EQ",
    "value": "127"
  },
  {
    "label": "Gosport Borough Council, PO12 1EB",
    "value": "128"
  },
  {
    "label": "Gravesham Borough Council, DA12 1AU",
    "value": "129"
  },
  {
    "label": "Great Yarmouth Borough Council, NR30 2QF",
    "value": "130"
  },
  {
    "label": "Guernsey (Environment Department) GY1 1FH",
    "value": "292"
  },
  {
    "label": "Guildford Borough Council, GU1 1QE",
    "value": "132"
  },
  {
    "label": "Gwynedd County Council, LL55 1SH",
    "value": "404"
  },
  {
    "label": "Halton Borough Council, WA8 7QF",
    "value": "134"
  },
  {
    "label": "Hambleton District Council, DL6 2UU",
    "value": "135"
  },
  {
    "label": "Harborough District Council, LE16 7AG",
    "value": "137"
  },
  {
    "label": "Harlow District Council, CM20 1WG",
    "value": "139"
  },
  {
    "label": "Harrogate Borough Council, HG1 2SG",
    "value": "140"
  },
  {
    "label": "Hart District Council, GU51 4AE",
    "value": "142"
  },
  {
    "label": "Hartlepool Borough Council, TS24 8AY",
    "value": "143"
  },
  {
    "label": "Hastings Borough Council, TN34 3UY",
    "value": "144"
  },
  {
    "label": "Havant Borough Council, PO9 2AX",
    "value": "145"
  },
  {
    "label": "Hertsmere Borough Council, WD6 1WA",
    "value": "148"
  },
  {
    "label": "High Peak Borough Council, SK17 6EL",
    "value": "149"
  },
  {
    "label": "Highland, IV1 1JJ",
    "value": "377"
  },
  {
    "label": "Hinckley & Bosworth Brgh Cnl, LE10 0FR",
    "value": "151"
  },
  {
    "label": "Horsham District Council, RH12 1RL",
    "value": "152"
  },
  {
    "label": "Huntingdonshire District Cnl, PE29 3TN",
    "value": "154"
  },
  {
    "label": "Hyndburn Borough Council, BB5 1NJ",
    "value": "155"
  },
  {
    "label": "Inverclyde, PA15 1LX",
    "value": "378"
  },
  {
    "label": "Ipswich Borough Council, IP1 2DE",
    "value": "156"
  },
  {
    "label": "Isle of Anglesey County Cnl, LL77 7TW",
    "value": "405"
  },
  {
    "label": "Isle of Man (Douglas Borough Council),  IM1 1ET",
    "value": "418"
  },
  {
    "label": "Isle of Wight Council, PO30 2EH",
    "value": "157"
  },
  {
    "label": "Isles of Scilly Council TR21 0LW",
    "value": "158"
  },
  {
    "label": "Jersey (St Helier, Dvr & Veh Stds Dept) JE1 3UE",
    "value": "095"
  },
  {
    "label": "Kennet District Council, SN10 2AT",
    "value": "160"
  },
  {
    "label": "Kingston Upon Hull City Cnl, HU9 1DB",
    "value": "165"
  },
  {
    "label": "Kirklees Metropolitan BC, HD5 9AA",
    "value": "167"
  },
  {
    "label": "Knowsley Metropolitan BC, L36 9YU",
    "value": "168"
  },
  {
    "label": "Lancaster City Council, LA1 1PJ",
    "value": "170"
  },
  {
    "label": "Leicester City Council, LE1 6FB",
    "value": "171"
  },
  {
    "label": "Lichfield District Council, WS13 6YZ",
    "value": "175"
  },
  {
    "label": "Lincoln City Council, LN1 1DD",
    "value": "176"
  },
  {
    "label": "Luton Borough Council, LU1 2BQ",
    "value": "178"
  },
  {
    "label": "Maldon District Council, CM9 5DL",
    "value": "181"
  },
  {
    "label": "Malvern Hills District Council, WR14 3AF",
    "value": "182"
  },
  {
    "label": "Mansfield District Council, NG19 7BH",
    "value": "183"
  },
  {
    "label": "Medway Council, ME4 4TR",
    "value": "420"
  },
  {
    "label": "Melton Borough Council, LE13 0UL",
    "value": "184"
  },
  {
    "label": "Mendip District Council, BA4 5BT",
    "value": "185"
  },
  {
    "label": "Merthyr Tydfil County Brgh Cnl, CF47 8XF",
    "value": "406"
  },
  {
    "label": "Mid Suffolk District Council, IP6 8DL",
    "value": "189"
  },
  {
    "label": "Mid Sussex District Council, RH16 1SS",
    "value": "190"
  },
  {
    "label": "Middlesbrough Council, TS1 1QP",
    "value": "191"
  },
  {
    "label": "Midlothian, EH22 1DJ",
    "value": "379"
  },
  {
    "label": "Milton Keynes Council, MK6 1LY",
    "value": "192"
  },
  {
    "label": "Mole Valley District Council, RH4 1SJ",
    "value": "193"
  },
  {
    "label": "Monmouthshire County Council, NP7 6EL",
    "value": "407"
  },
  {
    "label": "Moray, IV30 1BX",
    "value": "380"
  },
  {
    "label": "Nairn, IV12 4AU",
    "value": "442"
  },
  {
    "label": "Neath Port Talbot County BC, SA11 3QZ",
    "value": "408"
  },
  {
    "label": "New Forest District Council, SO43 7PA",
    "value": "194"
  },
  {
    "label": "Newark & Sherwood District Cnl, NG23 5QX",
    "value": "195"
  },
  {
    "label": "Newport County Borough Council, NP20 4UR",
    "value": "409"
  },
  {
    "label": "North Ayrshire, KA12 8EE",
    "value": "381"
  },
  {
    "label": "North Devon District Council, EX31 1EA",
    "value": "200"
  },
  {
    "label": "North Dorset District Council, DT11 7LL",
    "value": "201"
  },
  {
    "label": "North East Derbyshire Dist Cnl, S40 1LF",
    "value": "202"
  },
  {
    "label": "North East Lincolnshire Cnl, DN31 1HU",
    "value": "203"
  },
  {
    "label": "North Hertfordshire Dist Cnl, SG6 3JF",
    "value": "204"
  },
  {
    "label": "North Kesteven District Cnl, NG34 7EF",
    "value": "205"
  },
  {
    "label": "North Lanarkshire, ML1 1AB",
    "value": "382"
  },
  {
    "label": "North Lincolnshire Council, DN15 6XQ",
    "value": "206"
  },
  {
    "label": "North Norfolk District Council, NR27 9EN",
    "value": "207"
  },
  {
    "label": "North Northamptonshire Council, NN17 1QG",
    "value": "457"
  },
  {
    "label": "North Shropshire District Cnl, SY4 5DB",
    "value": "208"
  },
  {
    "label": "North Somerset Council, BS23 1UJ",
    "value": "209"
  },
  {
    "label": "North Tyneside Metro Brgh Cnl, NE12 6UB",
    "value": "210"
  },
  {
    "label": "North Warwickshire Brgh Cnl, CV9 1DE",
    "value": "211"
  },
  {
    "label": "North West Leicestershire BC, LE67 3FJ",
    "value": "212"
  },
  {
    "label": "Norwich City Council, NR2 1NH",
    "value": "215"
  },
  {
    "label": "Nottingham City Council, NG2 3AH",
    "value": "216"
  },
  {
    "label": "Nuneaton & Bedworth Brgh Cnl, CV11 5AA",
    "value": "217"
  },
  {
    "label": "Oadby and Wigston Borough Cnl, LE18 2DR",
    "value": "218"
  },
  {
    "label": "Oldham Metropolitan Brgh Cnl, OL1 2JA",
    "value": "219"
  },
  {
    "label": "Orkney Islands, KW15 1NY",
    "value": "383"
  },
  {
    "label": "Oswestry Borough Council, SY11 1JR",
    "value": "220"
  },
  {
    "label": "Oxford City Council, OX1 1DS",
    "value": "221"
  },
  {
    "label": "Pembrokeshire County Council, SA61 1TP",
    "value": "410"
  },
  {
    "label": "Pendle Borough Council, BB9 7LG",
    "value": "222"
  },
  {
    "label": "Perth & Kinross, PH1 5PH",
    "value": "384"
  },
  {
    "label": "Peterborough City Council, PE1 1FZ",
    "value": "224"
  },
  {
    "label": "Plymouth City Council, PL1 2AH",
    "value": "225"
  },
  {
    "label": "Poole Borough Council, BH15 1TN",
    "value": "226"
  },
  {
    "label": "Portsmouth City Council, PO1 2AL",
    "value": "227"
  },
  {
    "label": "Powys County Council, LD1 5LG",
    "value": "411"
  },
  {
    "label": "Preston Borough Council, PR1 2RL",
    "value": "228"
  },
  {
    "label": "PSV Eastern, CB2 1NP",
    "value": "430"
  },
  {
    "label": "PSV Jersey, JE4 8QS",
    "value": "424"
  },
  {
    "label": "PSV North East, LS9 6NF",
    "value": "426"
  },
  {
    "label": "PSV North West, WA3 2SH",
    "value": "427"
  },
  {
    "label": "PSV Northern Ireland, BT12 6QL",
    "value": "428"
  },
  {
    "label": "PSV Scotland, EH3 9SE",
    "value": "429"
  },
  {
    "label": "PSV Wales, CF10 4UX",
    "value": "432"
  },
  {
    "label": "PSV West Midlands, B15 1PL",
    "value": "433"
  },
  {
    "label": "PSV Western, BS1 6EH",
    "value": "431"
  },
  {
    "label": "Purbeck District Council, BH20 4PP",
    "value": "229"
  },
  {
    "label": "Reading Borough Council, RG1 2LU",
    "value": "230"
  },
  {
    "label": "Redcar & Cleveland Council, TS14 7FD",
    "value": "232"
  },
  {
    "label": "Redditch Borough Council, B98 8AH",
    "value": "233"
  },
  {
    "label": "Reigate & Banstead Borough Cnl, RH2 0SH",
    "value": "234"
  },
  {
    "label": "Renfrewshire, PA1 1TT",
    "value": "385"
  },
  {
    "label": "Rhondda Cynon Taff County BC, CF37 1UB",
    "value": "412"
  },
  {
    "label": "Ribble Valley Borough Council, BB7 2RA",
    "value": "236"
  },
  {
    "label": "Richmondshire District Council, DL10 4JX",
    "value": "238"
  },
  {
    "label": "Rochdale Metropolitan BC, OL16 1XU",
    "value": "239"
  },
  {
    "label": "Ross & Cromarty (Dingwall), IV15 9QN",
    "value": "443"
  },
  {
    "label": "Rossendale Borough Council, BB4 7LZ",
    "value": "242"
  },
  {
    "label": "Rother District Council, TN39 3JX",
    "value": "243"
  },
  {
    "label": "Rotherham Borough Council, S65 1UF",
    "value": "244"
  },
  {
    "label": "Rugby Borough Council, CV21 2RR",
    "value": "245"
  },
  {
    "label": "Runnymede Borough Council, KT15 2AH",
    "value": "246"
  },
  {
    "label": "Rushcliffe Borough Council, NG2 5FE",
    "value": "247"
  },
  {
    "label": "Rushmoor Borough Council, GU14 7JU",
    "value": "248"
  },
  {
    "label": "Rutland County Council, LE15 6HP",
    "value": "249"
  },
  {
    "label": "Ryedale District Council, YO17 7HH",
    "value": "250"
  },
  {
    "label": "Salford City Council, M27 5DA",
    "value": "251"
  },
  {
    "label": "Scarborough Borough Council, YO11 2HG",
    "value": "254"
  },
  {
    "label": "Scottish Borders, TD6 0SA",
    "value": "386"
  },
  {
    "label": "Sedgefield Borough Council, DL16 6JQ",
    "value": "255"
  },
  {
    "label": "Sedgemoor District Council, TA6 3AR",
    "value": "256"
  },
  {
    "label": "Sefton Metropolitan BC, L20 3NJ",
    "value": "257"
  },
  {
    "label": "Selby District Council, YO8 9FT",
    "value": "258"
  },
  {
    "label": "Shepway District Council, CT20 2QY",
    "value": "261"
  },
  {
    "label": "Shetland Islands, ZE1 0NT",
    "value": "387"
  },
  {
    "label": "Skye & Lochalsh (Isle Of Skye), IV49 9AB",
    "value": "445"
  },
  {
    "label": "Slough Borough Council, SL1 1JL",
    "value": "263"
  },
  {
    "label": "Solihull Metropolitan BC, B91 2LW",
    "value": "264"
  },
  {
    "label": "South Ayrshire, KA7 1UT",
    "value": "388"
  },
  {
    "label": "South Derbyshire District Cnl, DE11 7PJ",
    "value": "268"
  },
  {
    "label": "South East and Metropolitan, BN21 4QT",
    "value": "425"
  },
  {
    "label": "South Gloucestershire Council, BS37 0DD",
    "value": "269"
  },
  {
    "label": "South Hams District Council, TQ9 5NE",
    "value": "270"
  },
  {
    "label": "South Kesteven District Cnl, NG31 6PZ",
    "value": "273"
  },
  {
    "label": "South Lakeland District Cnl, LA9 4UD",
    "value": "274"
  },
  {
    "label": "South Lanarkshire Council, G72 0BP",
    "value": "389"
  },
  {
    "label": "South Norfolk District Council, NR15 2XE",
    "value": "275"
  },
  {
    "label": "South Ribble Borough Council, PR25 1DH",
    "value": "278"
  },
  {
    "label": "South Shropshire District Cnl, SY8 1DG",
    "value": "279"
  },
  {
    "label": "South Somerset District Cnl, BA20 2HT",
    "value": "280"
  },
  {
    "label": "South Staffordshire Dist Cnl, WV8 1PX",
    "value": "281"
  },
  {
    "label": "South Tyneside Borough Council, NE33 2RL",
    "value": "282"
  },
  {
    "label": "Southampton City Council, SO14 7LY Or SO18 9LA",
    "value": "283"
  },
  {
    "label": "Southend-on-Sea Borough Cnl, SS2 6ER",
    "value": "284"
  },
  {
    "label": "Spelthorne Borough Council, TW18 1XB",
    "value": "286"
  },
  {
    "label": "St Albans City Council, AL1 3JE",
    "value": "287"
  },
  {
    "label": "Stafford Borough Council, ST16 3AQ",
    "value": "290"
  },
  {
    "label": "Staffordshire Moorlands BC, ST13 6HQ",
    "value": "291"
  },
  {
    "label": "Stevenage Borough Council, SG1 1HN",
    "value": "293"
  },
  {
    "label": "Stirling, FK8 2ET",
    "value": "390"
  },
  {
    "label": "Stockport Metropolitan BC, SK1 3XE",
    "value": "294"
  },
  {
    "label": "Stockton-on-Tees Council, TS18 1XD",
    "value": "295"
  },
  {
    "label": "Stoke-on-Trent City Council, ST4 1RJ",
    "value": "296"
  },
  {
    "label": "Stratford-on-Avon District Cnl, CV37 6HX",
    "value": "297"
  },
  {
    "label": "Stroud District Council, GL5 4UB",
    "value": "298"
  },
  {
    "label": "Sutherland (Golspie), KW10 6RB",
    "value": "444"
  },
  {
    "label": "Swale Borough Council, ME10 3HT",
    "value": "302"
  },
  {
    "label": "Swindon Borough Council, SN1 2JH",
    "value": "303"
  },
  {
    "label": "Tameside Metropolitan Council, M43 6QU",
    "value": "304"
  },
  {
    "label": "Tamworth Borough Council, B79 7BZ",
    "value": "305"
  },
  {
    "label": "Tandridge District Council, RH8 9DB",
    "value": "306"
  },
  {
    "label": "Taunton Deane Borough Council, TA1 1HE",
    "value": "307"
  },
  {
    "label": "Teesdale District Council, DL12 8EL",
    "value": "308"
  },
  {
    "label": "Teignbridge District Council, TQ12 4XX",
    "value": "309"
  },
  {
    "label": "Telford & Wrekin Council, TF3 4JA",
    "value": "421"
  },
  {
    "label": "Tendring District Council, CO16 9AJ",
    "value": "310"
  },
  {
    "label": "Test Valley Borough Council, SP10 3AJ",
    "value": "311"
  },
  {
    "label": "Tewkesbury Borough Council, GL20 5TT",
    "value": "312"
  },
  {
    "label": "Three Rivers District Council, WD3 1RL",
    "value": "315"
  },
  {
    "label": "Thurrock Borough Council, RM17 6SL",
    "value": "316"
  },
  {
    "label": "Tonbridge & Malling Brgh Cnl, ME19 4LZ",
    "value": "317"
  },
  {
    "label": "Torbay Borough Council, TQ1 3DR",
    "value": "318"
  },
  {
    "label": "Torfaen County Borough Council, NP4 0LS",
    "value": "413"
  },
  {
    "label": "Torridge District Council, EX39 2QG",
    "value": "319"
  },
  {
    "label": "Trafford Borough Council, M32 0YU",
    "value": "321"
  },
  {
    "label": "Tunbridge Wells Borough Cnl, TN1 1RS",
    "value": "322"
  },
  {
    "label": "Vale of Glamorgan County BC, CF63 4RU",
    "value": "414"
  },
  {
    "label": "Vale of White Horse Dist Cnl, OX14 3JE",
    "value": "326"
  },
  {
    "label": "Vale Royal Borough Council, CW7 1AH",
    "value": "325"
  },
  {
    "label": "Walsall Metropolitan BC, WS1 1TP",
    "value": "327"
  },
  {
    "label": "Wansbeck District Council, NE63 9XL",
    "value": "330"
  },
  {
    "label": "Warrington Borough Council, WA1 2NH",
    "value": "331"
  },
  {
    "label": "Warwick District Council, CV32 5HZ",
    "value": "332"
  },
  {
    "label": "Watford Borough Council, WD17 3EX",
    "value": "333"
  },
  {
    "label": "Wealden District Council, BN27 2AX",
    "value": "336"
  },
  {
    "label": "Wear Valley District Council, DL15 9ES",
    "value": "337"
  },
  {
    "label": "West Berkshire District Council, RG14 5LD",
    "value": "422"
  },
  {
    "label": "West Devon Borough Council, PL19 0BZ",
    "value": "340"
  },
  {
    "label": "West Dorset District Council, DT1 1UZ",
    "value": "341"
  },
  {
    "label": "West Dunbartonshire, G81 1RF",
    "value": "391"
  },
  {
    "label": "West Lancashire District Cnl, L39 2DF",
    "value": "342"
  },
  {
    "label": "West Lindsey District Council, DN21 5DH",
    "value": "343"
  },
  {
    "label": "West Lothian, EH54 6QG",
    "value": "392"
  },
  {
    "label": "West Northamptonshire Council, NN1 1ED",
    "value": "458"
  },
  {
    "label": "West Oxfordshire District Cnl, OX28 1PB",
    "value": "344"
  },
  {
    "label": "West Somerset District Council, TA4 4QA",
    "value": "345"
  },
  {
    "label": "West Suffolk Council, IP33 3YU",
    "value": "459"
  },
  {
    "label": "Weymouth & Portland Brgh Cnl, DT4 8TA",
    "value": "347"
  },
  {
    "label": "Wigan Metropolitan Brgh Cnl, WN1 1YN",
    "value": "348"
  },
  {
    "label": "Wiltshire District Council, BA14 8JN",
    "value": "449"
  },
  {
    "label": "Winchester City Council, SO23 9LJ",
    "value": "349"
  },
  {
    "label": "Wirral Metropolitan Brgh Cnl, CH44 8ED",
    "value": "351"
  },
  {
    "label": "Woking Borough Council, GU21 6YL",
    "value": "352"
  },
  {
    "label": "Wokingham District Council, RG40 1BN",
    "value": "353"
  },
  {
    "label": "Wolverhampton Metropolitan BC, WV1 1DA",
    "value": "354"
  },
  {
    "label": "Worcester City Council, WR1 3ES",
    "value": "355"
  },
  {
    "label": "Worthing Borough Council, BN11 2SA",
    "value": "356"
  },
  {
    "label": "Wychavon District Council, WR10 1PT",
    "value": "358"
  },
  {
    "label": "Wyre Forest District Council, DY13 8UL",
    "value": "361"
  }
]