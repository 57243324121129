import { getEnvironment } from "../config";
import { DeploymentHost, TBranding, TFormData, TJourneyConfig } from "../types";
import { getEnvParams } from "../utils";

type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

const getEvent = () => "product_onboarding"; // static for now
const getTennantID = (branding?: TBranding) => (branding ? branding.name : "tennant_id"); //

const getJourneySection = (config: TJourneyConfig, idx: number) => config.sections[idx];
const getJourneyProductTitle = (config: TJourneyConfig) => config.product.title;

export const dispatchTrackingEvent = (
  config: TJourneyConfig,
  data: TFormData,
  sectionIdx: number,
  branding?: TBranding
) => {
  const product = getJourneyProductTitle(config);
  const event = getEvent();
  const step = getJourneySection(config, sectionIdx);
  const tennantID = getTennantID(branding);

  return window.dataLayer.push({
    ...data,
    event,
    product,
    step,
    tennantID,
    environment: getEnvironment(getEnvParams().hostname as DeploymentHost),
  });
};
