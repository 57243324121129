import { Engine } from './rulesEngine/json-rules-engine';

import { IStringIndex, TFormField } from '../types';
import { validateField } from '../fieldValidation';
import moment from 'moment';

export const initRulesEngine = () => {
  const engine = new Engine();

  for (const [name, func] of Object.entries(customOperators)) {
    engine.addOperator(name, func);
  }

  return engine;
};

export function handleUpdateRule(
  result: any,
  newValues: IStringIndex<any>,
  newValidations: IStringIndex<boolean>,
  field: TFormField,
) {
  if (result && result.conditionMet) {
    const eventType = result.results.event?.type;
    if (eventType === 'update') {
      const params = result.results.event?.params ?? {};
      const fieldKeys = Object.keys(params);
      for (let key of fieldKeys) {
        const targetKey = params[key].match(/\${(.*?)}/)?.[1];
        const targetFieldValue = newValues[targetKey] ?? params[key];
        // console.log(`Updating [${key}] to value of [${targetFieldValue}]`);
        newValues[key] = targetFieldValue;
        newValidations[key] = validateField(field, newValues[key], newValues);
      }
    }
    if (eventType === 'updateMultiple') {
      const params = result.results.event?.params ?? {};
      const fieldKeys = Object.keys(params);

      for (let key of fieldKeys) {
        const paramConfig = params[key];
        console.log(paramConfig);
        if (typeof paramConfig === 'object' && paramConfig.key && paramConfig.maps) {
          const targetKey = paramConfig.key;
          const targetFieldValue = newValues[targetKey];
          if (targetFieldValue && paramConfig.maps[targetFieldValue]) {
            newValues[key] = paramConfig.maps[targetFieldValue];
          }
        } else {
          const targetKey = paramConfig.match(/\${(.*?)}/)?.[1];
          const targetFieldValue = newValues[targetKey] ?? paramConfig;
          newValues[key] = targetFieldValue;
        }
        newValidations[key] = validateField(field, newValues[key], newValues);
      }
    }
  } else {
    field.dependents?.forEach((dependent) => {
      newValues[dependent] = undefined;
      newValidations[dependent] = validateField(field, newValues[dependent], newValues);
    });
  }
}

export async function applyRule(rule: any, fact: any) {
  const engine = initRulesEngine();
  engine.addRule(rule);
  try {
    const results = await engine.run(fact);
    return { conditionMet: results.events.length > 0, results: results.results[0] };
  } catch (error) {
    console.error('An error occurred while applying the rule:', error);
    return false;
  }
}

const customOperators = {
  dateLessThan: (faceValue: any, jsonValue: any) => {
    const { unit, num, dateType = 'full' } = jsonValue;
    let inputDate;
    if (dateType === 'year') {
      inputDate = moment({ year: faceValue }, 'DD-MM-YYYY');
    } else {
      inputDate = moment(faceValue, 'DD-MM-YYYY');
    }
    const currentDate = moment();
    const futureDate = inputDate.add(num, unit);

    return currentDate.isBefore(futureDate);
  },
  dateGreaterThan: (faceValue: any, jsonValue: any) => {
    const { unit, num, dateType = 'full' } = jsonValue;
    let inputDate;
    if (dateType === 'year') {
      inputDate = moment({ year: faceValue }, 'DD-MM-YYYY');
    } else {
      inputDate = moment(faceValue, 'DD-MM-YYYY');
    }
    const currentDate = moment();
    const futureDate = currentDate.add(num, unit);

    return inputDate.isAfter(futureDate);
  },
  dateInPast: (factValue: any) => {
    const inputDate = moment(factValue, 'DD-MM-YYYY');
    const currentDate = moment();

    return inputDate.isBefore(currentDate, 'day');
  },
  sizeGreaterThan: (faceValue: any, jsonValue: any) => {
    return faceValue.length > jsonValue;
  },
  sizeLessThan: (faceValue: any, jsonValue: any) => {
    return faceValue.length < jsonValue;
  },
};
