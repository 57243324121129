export const ConvictionCode = [
  { label: "AC10 - Failing To Stop After an Accident", value: "AC10" },
  { label: "AC12 - Aiding And Abetting - AC10", value: "AC12" },
  { label: "AC14 - Causing Or Permitting - AC10", value: "AC14" },
  { label: "AC16 - Inciting - AC10", value: "AC16" },
  { label: "AC20 - Failure To Report Within 24 Hours", value: "AC20" },
  { label: "AC22 - Aiding And Abetting - AC20", value: "AC22" },
  { label: "AC24 - Causing Or Permitting - AC20", value: "AC24" },
  { label: "AC26 - Inciting - AC20", value: "AC26" },
  { label: "AC30 - Undefined Accident Offence", value: "AC30" },
  { label: "AC32 - Aiding And Abetting - AC30", value: "AC32" },
  { label: "AC34 - Causing Or Permitting - AC30", value: "AC34" },
  { label: "AC36 - Inciting - AC30", value: "AC36" },
  { label: "BA10 - Driving While Disqualified by Order of Court", value: "BA10" },
  { label: "BA12 - Aiding And Abetting - BA10", value: "BA12" },
  { label: "BA14 - Causing Or Permitting - BA10", value: "BA14" },
  { label: "BA16 - Inciting - BA10", value: "BA16" },
  { label: "BA30 - Attempting To Drive While Disqualified by Order of Court", value: "BA30" },
  { label: "BA32 - Aiding And Abetting - BA30", value: "BA32" },
  { label: "BA34 - Causing Or Permitting - BA30", value: "BA34" },
  { label: "BA36 - Inciting - BA30", value: "BA36" },
  { label: "BA40 - Causing Death By Driving Whilst Disqualified", value: "BA40" },
  { label: "BA42 - Aiding And Abetting - BA40", value: "BA42" },
  { label: "BA44 - Causing Or Permitting - BA40", value: "BA44" },
  { label: "BA46 - Inciting - BA40", value: "BA46" },
  { label: "BA60 - Causing Serious Injury By Driving While Disqualified", value: "BA60" },
  { label: "BA62 - Aiding And Abetting - BA60", value: "BA62" },
  { label: "BA64 - Causing Or Permitting - BA60", value: "BA64" },
  { label: "BA66 - Inciting - BA60", value: "BA66" },
  { label: "CD10 - Driving Without Due Care And Attention", value: "CD10" },
  { label: "CD12 - Aiding And Abetting - CD10", value: "CD12" },
  { label: "CD14 - Causing Or Permitting - CD10", value: "CD14" },
  { label: "CD16 - Inciting - CD10", value: "CD16" },
  { label: "CD20 - Driving Without Reasonable Consideration For Other Road Users", value: "CD20" },
  { label: "CD22 - Aiding And Abetting - CD20", value: "CD22" },
  { label: "CD24 - Causing Or Permitting - CD20", value: "CD24" },
  { label: "CD26 - Inciting - CD20", value: "CD26" },
  { label: "CD30 - Driving Without Due Care Or Reasonable Consideration", value: "CD30" },
  { label: "CD32 - Aiding And Abetting - CD30", value: "CD32" },
  {
    label: "CD33 - Causing serious injury by careless or inconsiderate driving - CD33",
    value: "CD33",
  },
  { label: "CD34 - Causing Or Permitting - CD30", value: "CD34" },
  { label: "CD36 - Inciting - CD30", value: "CD36" },
  { label: "CD40 - Causing Death By Careless Driving When Unfit Through Drink", value: "CD40" },
  { label: "CD42 - Aiding And Abetting - CD40", value: "CD42" },
  { label: "CD44 - Causing Or Permitting - CD40", value: "CD44" },
  { label: "CD46 - Inciting - CD40", value: "CD46" },
  { label: "CD50 - Causing Death By Careless Driving When Unfit Through Drugs", value: "CD50" },
  { label: "CD52 - Aiding And Abetting - CD50", value: "CD52" },
  { label: "CD54 - Causing Or Permitting - CD50", value: "CD54" },
  { label: "CD56 - Inciting - CD50", value: "CD56" },
  { label: "CD60 - Causing Death By Careless Driving With Above Limit Alcohol", value: "CD60" },
  { label: "CD62 - Aiding And Abetting - CD60", value: "CD62" },
  { label: "CD64 - Causing Or Permitting - CD60", value: "CD64" },
  { label: "CD66 - Inciting - CD60", value: "CD66" },
  { label: "CD70 - Cause Death By Careless Driving and Not Supply A Specimen", value: "CD70" },
  { label: "CD72 - Aiding And Abetting - CD70", value: "CD72" },
  { label: "CD74 - Causing Or Permitting - CD70", value: "CD74" },
  { label: "CD76 - Inciting - CD70", value: "CD76" },
  { label: "CD80 - Causing Death By Careless Or Inconsiderate Driving", value: "CD80" },
  { label: "CD82 - Aiding And Abetting - CD80", value: "CD82" },
  { label: "CD84 - Causing Or Permitting - CD80", value: "CD84" },
  { label: "CD86 - Inciting - CD80", value: "CD86" },
  { label: "CD90 - Causing Death By Driving Unlicensed Uninsured Or Disqualified", value: "CD90" },
  { label: "CD92 - Aiding And Abetting - CD90", value: "CD92" },
  { label: "CD94 - Causing Or Permitting - CD90", value: "CD94" },
  { label: "CD96 - Inciting - CD90", value: "CD96" },
  { label: "CU10 - Using a Vehicle With Defective Brakes", value: "CU10" },
  { label: "CU12 - Aiding And Abetting - CU10", value: "CU12" },
  { label: "CU14 - Causing Or Permitting - CU10", value: "CU14" },
  { label: "CU16 - Inciting - CU10", value: "CU16" },
  { label: "CU20 - Dangerous vehicle condition (Excl Brakes Steering Or Tyres)", value: "CU20" },
  { label: "CU22 - Aiding And Abetting - CU20", value: "CU22" },
  { label: "CU24 - Causing Or Permitting - CU20", value: "CU24" },
  { label: "CU26 - Inciting - CU20", value: "CU26" },
  { label: "CU30 - Using a Vehicle With Defective Tyre(s)", value: "CU30" },
  { label: "CU32 - Aiding And Abetting - CU30", value: "CU32" },
  { label: "CU34 - Causing Or Permitting - CU30", value: "CU34" },
  { label: "CU36 - Inciting - CU30", value: "CU36" },
  { label: "CU40 - Using a Vehicle With Defective Steering", value: "CU40" },
  { label: "CU42 - Aiding And Abetting - CU40", value: "CU42" },
  { label: "CU44 - Causing Or Permitting - CU40", value: "CU44" },
  { label: "CU46 - Inciting - CU40", value: "CU46" },
  { label: "CU50 - Dangerous Load or Passengers", value: "CU50" },
  { label: "CU52 - Aiding And Abetting - CU50", value: "CU52" },
  { label: "CU54 - Causing Or Permitting - CU50", value: "CU54" },
  { label: "CU56 - Inciting - CU50", value: "CU56" },
  { label: "CU80 - Breach of vehicle control eg Mobile Phone", value: "CU80" },
  { label: "CU82 - Aiding And Abetting - CU80", value: "CU82" },
  { label: "CU84 - Causing Or Permitting - CU80", value: "CU84" },
  { label: "CU86 - Inciting - CU80", value: "CU86" },
  { label: "DD10 - Causing Serious Injury by Dangerous Driving", value: "DD10" },
  { label: "DD12 - Aiding And Abetting - DD10", value: "DD12" },
  { label: "DD14 - Causing Or Permitting - DD10", value: "DD14" },
  { label: "DD16 - Inciting - DD10", value: "DD16" },
  { label: "DD40 - Dangerous Driving", value: "DD40" },
  { label: "DD42 - Aiding And Abetting - DD40", value: "DD42" },
  { label: "DD44 - Causing Or Permitting - DD40", value: "DD44" },
  { label: "DD46 - Inciting - DD40", value: "DD46" },
  { label: "DD60 - Manslaughter Or Culpable Homicide While Driving a Vehicle", value: "DD60" },
  { label: "DD62 - Aiding And Abetting - DD60", value: "DD62" },
  { label: "DD64 - Causing Or Permitting - DD60", value: "DD64" },
  { label: "DD66 - Inciting - DD60", value: "DD66" },
  { label: "DD80 - Causing Death By Dangerous Driving", value: "DD80" },
  { label: "DD82 - Aiding And Abetting - DD80", value: "DD82" },
  { label: "DD84 - Causing Or Permitting - DD80", value: "DD84" },
  { label: "DD86 - Inciting - DD80", value: "DD86" },
  { label: "DD90 - Furious Driving", value: "DD90" },
  { label: "DD92 - Aiding And Abetting - DD90", value: "DD92" },
  { label: "DD94 - Causing Or Permitting - DD90", value: "DD94" },
  { label: "DD96 - Inciting - DD90", value: "DD96" },
  {
    label: "DG10 - Driving Or Attempting To Drive With Drug Level Above Specified Limit",
    value: "DG10",
  },
  { label: "DG12 - Aiding And Abetting - DG10", value: "DG12" },
  { label: "DG14 - Causing Or Permitting - DG10", value: "DG14" },
  { label: "DG16 - Inciting - DG10", value: "DG16" },
  { label: "DG40 - In Charge Of A Vehicle While Drug Level Above Specified Limit", value: "DG40" },
  { label: "DG42 - Aiding And Abetting - DG40", value: "DG42" },
  { label: "DG44 - Causing Or Permitting - DG40", value: "DG44" },
  { label: "DG46 - Inciting - DG40", value: "DG46" },
  {
    label: "DG60 - Causing Death By Careless Driving With Drug Level Above The Limit",
    value: "DG60",
  },
  { label: "DG62 - Aiding And Abetting - DG60", value: "DG62" },
  { label: "DG64 - Causing Or Permitting - DG60", value: "DG64" },
  { label: "DG66 - Inciting - DG60", value: "DG66" },
  { label: "DR10 - Driving Or Attempting to Drive With Alcohol Level Above Limit", value: "DR10" },
  { label: "DR12 - Aiding And Abetting - DR10", value: "DR12" },
  { label: "DR14 - Causing Or Permitting - DR10", value: "DR14" },
  { label: "DR16 - Inciting - DR10", value: "DR16" },
  { label: "DR20 - Driving Or Attempting to Drive While Unfit Through Drink", value: "DR20" },
  { label: "DR22 - Aiding And Abetting - DR20", value: "DR22" },
  { label: "DR24 - Causing Or Permitting - DR20", value: "DR24" },
  { label: "DR26 - Inciting - DR20", value: "DR26" },
  { label: "DR30 - Failure to supply specimen whilst driving/attempting to drive", value: "DR30" },
  { label: "DR32 - Aiding And Abetting - DR30", value: "DR32" },
  { label: "DR34 - Causing Or Permitting - DR30", value: "DR34" },
  { label: "DR36 - Inciting - DR30", value: "DR36" },
  { label: "DR40 - In Charge Of a Vehicle While Alcohol Level Above Limit", value: "DR40" },
  { label: "DR42 - Aiding And Abetting - DR40", value: "DR42" },
  { label: "DR44 - Causing Or Permitting - DR40", value: "DR44" },
  { label: "DR46 - Inciting - DR40", value: "DR46" },
  { label: "DR50 - In Charge Of a Vehicle While Unfit Through Drink", value: "DR50" },
  { label: "DR52 - Aiding And Abetting - DR50", value: "DR52" },
  { label: "DR54 - Causing Or Permitting - DR50", value: "DR54" },
  { label: "DR56 - Inciting - DR50", value: "DR56" },
  { label: "DR60 - Failure to provide specimen other than according to DR30", value: "DR60" },
  { label: "DR62 - Aiding And Abetting - DR60", value: "DR62" },
  { label: "DR64 - Causing Or Permitting - DR60", value: "DR64" },
  { label: "DR66 - Inciting - DR60", value: "DR66" },
  { label: "DR70 - Failing To Provide Specimen For Breath Test", value: "DR70" },
  { label: "DR72 - Aiding And Abetting - DR70", value: "DR72" },
  { label: "DR74 - Causing Or Permitting - DR70", value: "DR74" },
  { label: "DR76 - Inciting - DR70", value: "DR76" },
  { label: "DR80 - Driving Or Attempting To Drive When Unfit Through Drugs", value: "DR80" },
  { label: "DR82 - Aiding And Abetting - DR80", value: "DR82" },
  { label: "DR84 - Causing Or Inciting - DR80", value: "DR84" },
  { label: "DR86 - Inciting - DR80", value: "DR86" },
  { label: "DR90 - In Charge Of A Vehicle When Unfit Through Drugs", value: "DR90" },
  { label: "DR92 - Aiding And Abetting - DR90", value: "DR92" },
  { label: "DR94 - Causing Or Permitting - DR90", value: "DR94" },
  { label: "DR96 - Inciting - DR90", value: "DR96" },
  { label: "IN10 - Using a Vehicle Uninsured Against Third Party Risks", value: "IN10" },
  { label: "IN12 - Aiding And Abetting - IN10", value: "IN12" },
  { label: "IN14 - Causing Or Permitting - IN10", value: "IN14" },
  { label: "IN16 - Inciting - IN10", value: "IN16" },
  { label: "LC20 - Driving otherwise than in accordance with a licence", value: "LC20" },
  { label: "LC22 - Aiding Abetting - LC20", value: "LC22" },
  { label: "LC24 - Causing Permitting -LC20", value: "LC24" },
  { label: "LC26 - Inciting - LC20", value: "LC26" },
  { label: "LC30 - Driving After False Decl Re Fitness When Licence Applied For", value: "LC30" },
  { label: "LC32 - Aiding And Abetting - LC30", value: "LC32" },
  { label: "LC34 - Causing Or Permitting - LC30", value: "LC34" },
  { label: "LC36 - Inciting - LC30", value: "LC36" },
  { label: "LC40 - Driving A Vehicle Having Failed To Notify A Disability", value: "LC40" },
  { label: "LC42 - Aiding And Abetting - LC40", value: "LC42" },
  { label: "LC44 - Causing Or Permitting - LC40", value: "LC44" },
  { label: "LC46 - Inciting - LC40", value: "LC46" },
  { label: "LC50 - Driving After A Licence Revoked Or Refused On Medical Grounds", value: "LC50" },
  { label: "LC52 - Aiding And Abetting - LC50", value: "LC52" },
  { label: "LC54 - Causing Or Permitting - LC50", value: "LC54" },
  { label: "LC56 - Inciting - LC50", value: "LC56" },
  { label: "MR09 - Reckless Or Dangerous Driving", value: "MR09" },
  { label: "MR19 - Wilful Failure to Stop and Report Following a Road Accident", value: "MR19" },
  { label: "MR29 - Driving Vehicle Under the Influence Affecting Driver Abilities", value: "MR29" },
  { label: "MR39 - Driving a Vehicle Faster Than the Permitted Speed", value: "MR39" },
  { label: "MR49 - Driving a Vehicle Whilst Disqualified", value: "MR49" },
  { label: "MR59 - Other Driving Disqualification Offence", value: "MR59" },
  { label: "MS10 - Leaving Vehicle In Dangerous Position", value: "MS10" },
  { label: "MS12 - Aiding And Abetting - MS10", value: "MS12" },
  { label: "MS14 - Causing Or Permitting - MS10", value: "MS14" },
  { label: "MS16 - Inciting - MS10", value: "MS16" },
  { label: "MS20 - Unlawful Pillion Riding", value: "MS20" },
  { label: "MS22 - Aiding And Abetting - MS20", value: "MS22" },
  { label: "MS24 - Causing Or Permitting - MS20", value: "MS24" },
  { label: "MS26 - Inciting - MS20", value: "MS26" },
  { label: "MS30 - Play Street Offences", value: "MS30" },
  { label: "MS32 - Aiding And Abetting - MS30", value: "MS32" },
  { label: "MS34 - Causing Or Permitting - MS30", value: "MS34" },
  { label: "MS36 - Inciting - MS30", value: "MS36" },
  { label: "MS50 - Motor Racing On Highway", value: "MS50" },
  { label: "MS52 - Aiding And Abetting - MS50", value: "MS52" },
  { label: "MS54 - Causing Or Permitting - MS50", value: "MS54" },
  { label: "MS56 - Inciting - MS50", value: "MS56" },
  { label: "MS60 - Offence Not Covered By Other Codes", value: "MS60" },
  { label: "MS62 - Aiding And Abetting - MS60", value: "MS62" },
  { label: "MS64 - Causing Or Permitting - MS60", value: "MS64" },
  { label: "MS66 - Inciting - MS60", value: "MS66" },
  { label: "MS70 - Driving With Uncorrected Defective Eyesight", value: "MS70" },
  { label: "MS72 - Aiding And Abetting - MS70", value: "MS72" },
  { label: "MS74 - Causing Or Permitting - MS70", value: "MS74" },
  { label: "MS76 - Inciting - MS70", value: "MS76" },
  { label: "MS80 - Refusing To Submit To An Eyesight Test", value: "MS80" },
  { label: "MS82 - Aiding And Abetting - MS80", value: "MS82" },
  { label: "MS84 - Causing Or Permitting - MS80", value: "MS84" },
  { label: "MS86 - Inciting - MS80", value: "MS86" },
  { label: "MS90 - Failure To Give Information As To Identity Of Driver Etc", value: "MS90" },
  { label: "MS92 - Aiding And Abetting - MS90", value: "MS92" },
  { label: "MS94 - Causing Or Permitting - MS90", value: "MS94" },
  { label: "MS96 - Inciting - MS90", value: "MS96" },
  { label: "MW10 - Contravening Special Road Regulations (Excl Speed)", value: "MW10" },
  { label: "MW12 - Aiding And Abetting - MW10", value: "MW12" },
  { label: "MW14 - Causing Or Permitting - MW10", value: "MW14" },
  { label: "MW16 - Inciting - MW10", value: "MW16" },
  { label: "NEND - Non Endorsable Offence", value: "NEND" },
  { label: "PC10 - Undefined Contravention of Pedestrian Crossing Regulations", value: "PC10" },
  { label: "PC12 - Aiding And Abetting - PC10", value: "PC12" },
  { label: "PC14 - Causing Or Permitting - PC10", value: "PC14" },
  { label: "PC16 - Inciting - PC10", value: "PC16" },
  { label: "PC20 - Pedestrian crossing contravention with moving vehicle", value: "PC20" },
  { label: "PC22 - Aiding And Abetting - PC20", value: "PC22" },
  { label: "PC24 - Causing Or Permitting - PC20", value: "PC24" },
  { label: "PC26 - Inciting - PC20", value: "PC26" },
  { label: "PC30 - Pedestrian crossing contravention with stationary vehicle", value: "PC30" },
  { label: "PC32 - Aiding And Abetting - PC30", value: "PC32" },
  { label: "PC34 - Causing Or Permitting - PC30", value: "PC34" },
  { label: "PC36 - Inciting - PC30", value: "PC36" },
  { label: "SP10 - Exceeding Goods Vehicle Speed Limits", value: "SP10" },
  { label: "SP12 - Aiding And Abetting - SP10", value: "SP12" },
  { label: "SP14 - Causing Or Permitting - SP10", value: "SP14" },
  { label: "SP16 - Inciting - SP10", value: "SP16" },
  { label: "SP20 - Speeding (Excl Goods Or Passenger Vehicles)", value: "SP20" },
  { label: "SP22 - Aiding And Abetting - SP20", value: "SP22" },
  { label: "SP24 - Causing Or Permitting - SP20", value: "SP24" },
  { label: "SP26 - Inciting - SP20", value: "SP26" },
  { label: "SP30 - Exceeding Statutory Speed Limit On a Public Road", value: "SP30" },
  { label: "SP32 - Aiding And Abetting - SP30", value: "SP32" },
  { label: "SP34 - Causing Or Permitting - SP30", value: "SP34" },
  { label: "SP36 - Inciting - SP30", value: "SP36" },
  { label: "SP40 - Exceeding Passenger Vehicle Speed Limit", value: "SP40" },
  { label: "SP42 - Aiding And Abetting - SP40", value: "SP42" },
  { label: "SP44 - Causing Or Permitting - SP40", value: "SP44" },
  { label: "SP46 - Inciting - SP40", value: "SP46" },
  { label: "SP50 - Exceeding Speed Limit On a Motorway", value: "SP50" },
  { label: "SP52 - Aiding And Abetting - SP50", value: "SP52" },
  { label: "SP54 - Causing Or Permitting - SP50", value: "SP54" },
  { label: "SP56 - Inciting - SP50", value: "SP56" },
  { label: "TS10 - Failing To Comply With Traffic Light Signals", value: "TS10" },
  { label: "TS12 - Aiding And Abetting - TS10", value: "TS12" },
  { label: "TS14 - Causing Or Permitting - TS10", value: "TS14" },
  { label: "TS16 - Inciting - TS10", value: "TS16" },
  { label: "TS20 - Failing To Comply With Double White Lines", value: "TS20" },
  { label: "TS22 - Aiding And Abetting - TS20", value: "TS22" },
  { label: "TS24 - Causing Or Permitting - TS20", value: "TS24" },
  { label: "TS26 - Inciting - TS20", value: "TS26" },
  { label: "TS30 - Failing To Comply With Stop Sign", value: "TS30" },
  { label: "TS32 - Aiding And Abetting - TS30", value: "TS32" },
  { label: "TS34 - Causing Or Permitting - TS30", value: "TS34" },
  { label: "TS36 - Inciting - TS30", value: "TS36" },
  { label: "TS40 - Failing To Comply With Direction Of a Constable/Warden", value: "TS40" },
  { label: "TS42 - Aiding And Abetting - TS40", value: "TS42" },
  { label: "TS44 - Causing Or Permitting - TS40", value: "TS44" },
  { label: "TS46 - Inciting - TS40", value: "TS46" },
  { label: "TS50 - Traffic Signs Offence (Ex Lights Stop Or Double White Line)", value: "TS50" },
  { label: "TS52 - Aiding And Abetting - TS50", value: "TS52" },
  { label: "TS54 - Causing Or Permitting - TS50", value: "TS54" },
  { label: "TS56 - Inciting - TS50", value: "TS56" },
  { label: "TS60 - Failing To Comply With a School Crossing Patrol Sign", value: "TS60" },
  { label: "TS62 - Aiding And Abetting - TS60", value: "TS62" },
  { label: "TS64 - Causing Or Permitting - TS60", value: "TS64" },
  { label: "TS66 - Inciting - TS60", value: "TS66" },
  { label: "TS70 - Undefined Failure To Comply With a Traffic Direction Sign", value: "TS70" },
  { label: "TS72 - Aiding And Abetting - TS70", value: "TS72" },
  { label: "TS74 - Causing Or Permitting - TS70", value: "TS74" },
  { label: "TS76 - Inciting - TS70", value: "TS76" },
  { label: "TT99 - Disqualified under totting up procedure", value: "TT99" },
  { label: "UT50 - Aggravated Taking Of A Vehicle", value: "UT50" },
  { label: "UT52 - Aiding And Abetting - UT50", value: "UT52" },
  { label: "UT54 - Causing Or Permitting - UT50", value: "UT54" },
  { label: "UT56 - Inciting - UT50", value: "UT56" },
  { label: "Z010 - Tachograph Offence", value: "Z010" },
  { label: "Z011 - Ban From Driving In Any EU Country (Excluding The UK)", value: "Z011" },
];
