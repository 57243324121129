import React from 'react';
import { CheckCircleOutline, ErrorOutline, WarningAmberOutlined } from '@mui/icons-material';
import { AlertTitle, Alert as MuiAlert, AlertProps as MuiAlertProps } from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';
import { hexToRGBA } from '../../utils';

interface CustomAlertProps extends MuiAlertProps {
  title?: string;
  hideIcon?: boolean;
}

const ICONS: Record<string, JSX.Element> = {
  info: <ErrorOutline />,
  warning: <ErrorOutline />,
  error: <WarningAmberOutlined />,
  success: <CheckCircleOutline />,
  default: <ErrorOutline />,
};

const COLORS = {
  info: {
    filled: { backgroundColor: '#EFF8FF', borderColor: '#E6F4FF', iconColor: '#009BF5' },
    outlined: { backgroundColor: 'white', borderColor: '#E6F4FF', iconColor: '#009BF5' },
  },
  warning: {
    filled: { backgroundColor: '#FFFBEA', borderColor: '#FFEFC7', iconColor: '#DC6903' },
    outlined: { backgroundColor: 'white', borderColor: '#FFEFC7', iconColor: '#DC6903' },
  },
  error: {
    filled: { backgroundColor: '#FEF3F2', borderColor: '#FEE5E3', iconColor: '#DE3024' },
    outlined: { backgroundColor: 'white', borderColor: '#FEE5E3', iconColor: '#DE3024' },
  },
  success: {
    filled: { backgroundColor: '#EDFDF2', borderColor: '#D3F8E0', iconColor: '#0B9055' },
    outlined: { backgroundColor: 'white', borderColor: '#D3F8E0', iconColor: '#0B9055' },
  },
  default: {
    filled: { backgroundColor: '#F9FAFb', borderColor: '#D1D4DD', iconColor: '#009BF5' },
    outlined: { backgroundColor: 'white', borderColor: '#D1D4DD', iconColor: '#009BF5' },
  },
} as Record<Severity, any>;

type Severity = 'info' | 'warning' | 'error' | 'success' | 'default';
type Variant = 'filled' | 'outlined' | 'standard';

const getColorStyles = (severity: Severity, variant: Variant, theme: Theme) => {
  if (severity === 'info') {
    return {
      backgroundColor: variant === 'outlined' ? 'white' : hexToRGBA(theme.palette.primary.main, 0.02),
      borderColor: theme.palette.primary.main,
      '& .MuiAlertTitle-root': { color: '#1A2133' },
      '& .MuiAlert-icon': { color: theme.palette.primary.main },
    };
  }

  const { backgroundColor, borderColor, iconColor } = COLORS[severity][variant];
  return {
    backgroundColor,
    borderColor,
    '& .MuiAlertTitle-root': { color: '#1A2133' },
    '& .MuiAlert-icon': { color: iconColor },
  };
};

const StyledAlert = styled(MuiAlert)<CustomAlertProps>(
  ({ severity = 'default' as Severity, variant = 'filled' as Variant, theme }) => ({
    ...getColorStyles(severity, variant, theme),
    borderWidth: 2,
    color: '#565E73',
  }),
);

const CustomAlert: React.FC<CustomAlertProps> = ({
  title,
  hideIcon,
  children,
  severity = 'default' as Severity,
  ...props
}) => {
  const theme = useTheme();
  const brandingFg = hexToRGBA(theme.palette.primary.main, 0.2);
  const variant = props.variant || 'filled';
  const icon = ICONS[severity];
  const borderColor = severity === 'info' ? brandingFg : COLORS[severity]?.outlined?.borderColor;

  return (
    <StyledAlert
      {...props}
      severity={severity === 'default' ? 'info' : severity}
      variant={variant}
      icon={hideIcon ? <></> : icon}
      sx={{
        ...props.sx,
        padding:0,
        paddingX:1,
        borderRadius: 2,
        border: `3px solid ${borderColor}`,
        fontSize: 10,
      }}
    >
      {title && <AlertTitle sx={{ fontWeight: 600 }}>{title}</AlertTitle>}
      {children}
    </StyledAlert>
  );
};

export default CustomAlert;
