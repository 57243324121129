import { InputAdornment, TextField as TextFieldMUI } from "@mui/material";
import { useState } from "react";
import { TFormField } from "../../../types";
import { MAX_MEASUREMENTS } from "./MeasurementsField";

export const MetricWeight = (props: {
  field: TFormField;
  kg: number;
  onChange: (kg: string) => void;
  error: string;
}) => {
  const { field, error } = props;
  const [kg, setKg] = useState(props.kg ? props.kg.toFixed(1) : "");
  return (
    <>
      <p style={{ marginTop: 24 }}>What is your weight?</p>
      <TextFieldMUI
        disabled={field.disabled}
        fullWidth
        variant="outlined"
        label="Kilograms"
        InputProps={{
          endAdornment: <InputAdornment position="end">kg</InputAdornment>,
        }}
        value={kg}
        onChange={(event) => {
          setKg(event.target.value);
          props.onChange(event.target.value);
        }}
        required={field.required}
        helperText={error || ""}
        error={error.length > 0 || Number(kg) > MAX_MEASUREMENTS.Metric.KG}
      />
    </>
  );
};
