import {
  Box,
  Step,
  StepConnector,
  StepIconProps,
  StepLabel,
  Stepper,
  Typography,
  stepConnectorClasses,
  styled,
  useTheme,
} from '@mui/material';
import { IoMdCheckmark } from 'react-icons/io';
import { TJourneyConfig } from '../types';

const StepperConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 15,
    left: 'calc(-50% + 27px)',
    right: 'calc(50% + 27px)',
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.grey[300],

    borderRadius: 2,
  },
}));

const StepperIconRoot = styled('div')<{ ownerState: { active?: boolean } }>(({ theme, ownerState }) => ({
  color: 'white',
  backgroundColor: theme.palette.grey[300],
  display: 'flex',
  height: 32,
  width: 32,
  borderRadius: '50%',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1,
  fontSize: 16,
  fontWeight: '400',
  ...(ownerState.active && {
    backgroundColor: theme.palette.primary.main,
  }),
}));

const StepperIcon = (props: StepIconProps) => {
  const { active, completed, className, icon } = props;
  const theme = useTheme();
  const brandingForeground = hexToRGBA(theme?.palette?.primary?.main);

  return (
    <StepperIconRoot
      ownerState={{ active }}
      className={className}
      style={completed ? { backgroundColor: brandingForeground } : {}}
    >
      <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {completed ? <IoMdCheckmark size={20} color={theme?.palette?.primary?.main} /> : icon}
      </Box>
    </StepperIconRoot>
  );
};

export const JourneyStepper = (props: { currentStep: number; steps: string[]; config: TJourneyConfig }) => {
  const currentSection = props?.config?.sections[props?.currentStep];
  const steps = ['cover', 'quote', 'summary', 'payment'];

  const currentStep = steps.indexOf(currentSection?.step?.toLowerCase() ?? currentSection?.name?.toLowerCase());

  return (
    <Stepper sx={{marginLeft:{md:-8}}} activeStep={currentStep} alternativeLabel connector={<StepperConnector />} orientation="horizontal">
      {steps.map((label, idx) => (
        <Step  key={label}>
          <StepLabel sx={{width:{md:150}}} StepIconComponent={StepperIcon} StepIconProps={{ icon: idx + 1 }}>
            {currentStep === idx ? (
              <Typography sx={{marginTop:-1, fontFamily:"Inter", fontWeight: 400, fontSize: 16, textTransform: 'capitalize' }}>{label}</Typography>
            ) : (
              <Typography
                sx={{marginTop:-1, fontFamily:"Inter",
                  fontWeight: 400,
                  fontSize: 16,
                  textTransform: 'capitalize',
                  color: idx < currentStep ? '#98A2B3' : '#D0D5DD',
                }}
              >
                {label}
              </Typography>
            )}
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

const hexToRGBA = (hex: string) =>
  `rgba(${parseInt(hex.slice(1, 3), 16)}, ${parseInt(hex.slice(3, 5), 16)}, ${parseInt(hex.slice(5, 7), 16)}, 0.2)`;
