import {
  Box,
  Card,
  Checkbox,
  Chip,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { useState } from "react";
import Modifications from "../../codeLists/Polaris/modifications.json";
import { IStringIndex, TFieldChangeHandler, TFormField, TValidationResult } from "../../types";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import validator from "validator";

type TItem = { label: string; value: string };
type TCategoryValue = { totalValue: string; items: TItem[] };
type TModificationFieldValue = IStringIndex<TCategoryValue>;

const Expandable = (props: {
  heading: string;
  children: any;
  index: number;
  count: number;
  totalValue: number;
}) => {
  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  const [expanded, setExpanded] = useState(false);

  return (
    <Stack
      spacing={1}
      sx={
        props.index < Modifications.length - 1
          ? { paddingBottom: 2, borderBottom: "1px solid rgba(0,0,0,0.25)" }
          : undefined
      }
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <b
          onClick={toggleExpanded}
          style={{ flex: 1, fontWeight: 500, cursor: "pointer", lineHeight: 2 }}
        >
          {props.heading}{" "}
          {props.count > 0 &&
            (!expanded && props.totalValue === 0 ? (
              <Tooltip title="Please provide the total value of the modifications in this category.">
                <Chip label={"Value missing"} color="primary" sx={{ height: 24, marginLeft: 2 }} />
              </Tooltip>
            ) : (
              <Chip
                label={props.count + ""}
                color="primary"
                sx={{ width: 36, height: 24, marginLeft: 2 }}
              />
            ))}
        </b>
        <IconButton onClick={toggleExpanded} color="primary">
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </IconButton>
      </Stack>
      {expanded && <Stack>{props.children}</Stack>}
    </Stack>
  );
};

export const doModificationsFieldValidation = (
  field: TFormField,
  value: TModificationFieldValue
): TValidationResult => {
  /*
  Invalid if
    * Value is not an object
    * No items in any category
    * Total value cannot be parsed as a number
    * Total value is <= 0  
  */
  if (typeof value !== "object") {
    return { valid: false, errorMsg: "Malformed value" };
  }
  let itemCount = 0;
  for (let [categoryName, categoryValue] of Object.entries(value)) {
    if (categoryValue.items) {
      if (validator.isInt(categoryValue.totalValue) && Number(categoryValue.totalValue) > 0) {
        itemCount += categoryValue.items.length;
      } else {
        return {
          valid: false,
          errorMsg:
            "Please provide the total value of the modifications for each category with selected items.",
        };
      }
    }
  }
  return itemCount > 0
    ? { valid: true }
    : { valid: false, errorMsg: "Please select at least one item." };
};

export const ModificationsField = (props: {
  field: TFormField;
  value: TModificationFieldValue;
  changeHandler: TFieldChangeHandler;
}) => {
  const [value, setValue] = useState<TModificationFieldValue>(props.value ?? {});
  const [totalValueErrors, setTotalValueErrors] = useState<IStringIndex<string>>({});
  const [mainError, setMainError] = useState(
    Object.keys(value).length > 0 ? "" : "Please select at least one item."
  );

  const validateAndChange = (newValue: TModificationFieldValue) => {
    const validationResult = doModificationsFieldValidation(props.field, newValue);
    if (validationResult.valid) {
      setMainError("");
      props.changeHandler(props.field, newValue);
    } else {
      setMainError(validationResult.errorMsg!);
      props.changeHandler(props.field, undefined);
    }
  };

  const selectionChanged = (categoryIndex: number, itemIndex: number, checked: boolean) => {
    const category = Modifications[categoryIndex];
    const item = category.items[itemIndex];
    const newValue = { ...value };
    if (checked) {
      // Add, if existing
      if (newValue[category.name]) {
        newValue[category.name].items.push(item);
      } else {
        // Create otherwise
        newValue[category.name] = { totalValue: "", items: [item] };
      }
      setValue(newValue);
    } else {
      const existingIndex = value[category.name].items.findIndex((opt) => opt.value === item.value);
      if (existingIndex !== -1) {
        newValue[category.name].items.splice(existingIndex, 1);
        // Clear if last item unchecked
        if (newValue[category.name].items.length === 0) {
          delete newValue[category.name];
          setValue(newValue);
        } else {
          setValue(newValue);
        }
      }
    }
    validateAndChange(newValue);
  };

  const totalChanged = (categoryIndex: number, totalString: string) => {
    const category = Modifications[categoryIndex];
    const newValue = { ...value };
    newValue[category.name].totalValue = totalString;
    setValue(newValue);
    if (!validator.isInt(totalString) || Number(totalString) <= 0) {
      setTotalValueErrors({
        ...totalValueErrors,
        [category.name]: "Please enter a valid, positive integer number.",
      });
    } else {
      setTotalValueErrors({ ...totalValueErrors, [category.name]: "" });
    }
    validateAndChange(newValue);
  };

  const renderModificationCategory = (m: { name: string; items: TItem[]; index: number }) => {
    const totalValueError = totalValueErrors[m.name];
    const itemCount = value[m.name]?.items.length ?? 0;
    return (
      <Expandable
        heading={m.name}
        index={m.index}
        key={m.name}
        count={itemCount}
        totalValue={Number(value[m.name]?.totalValue) || 0}
      >
        {m.items.map((i, index) => (
          <FormControlLabel
            key={i.label}
            control={
              <Checkbox
                checked={
                  value[m.name]
                    ? value[m.name].items.findIndex((item) => item.value === i.value) !== -1
                    : false
                }
              />
            }
            label={i.label}
            onChange={(event: any, checked: boolean) => selectionChanged(m.index, index, checked)}
          />
        ))}
        {itemCount > 0 && (
          <Stack spacing={2} sx={{ marginTop: 2 }}>
            <p>
              Please provide the total value of the modifications you have added in this category:
            </p>
            <TextField
              fullWidth
              value={value[m.name]?.totalValue || ""}
              label="Total value"
              variant="outlined"
              InputProps={{
                startAdornment: <InputAdornment position="start">£</InputAdornment>,
              }}
              onChange={(event: any) => totalChanged(m.index, event.target.value)}
              error={Boolean(totalValueError)}
              helperText={totalValueError}
              required={true}
            />
          </Stack>
        )}
      </Expandable>
    );
  };

  return (
    <Stack spacing={3}>
      <Card sx={{ padding: 2, borderRadius: 2 }}>
        <Stack spacing={2}>
          {Modifications.map((m, index) => renderModificationCategory({ ...m, index }))}
        </Stack>
      </Card>
      {mainError && <b>{mainError}</b>}
    </Stack>
  );
};
