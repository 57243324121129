export const PermittedDriver = [
  {
    label: "Any Driver Excluding Drivers Under 60",
    value: "0",
  },
  {
    label: "Insured Only",
    value: "1",
  },
  {
    label: "Insured And Spouse",
    value: "2",
  },
  {
    label: "Named Drivers Excluding Proposer",
    value: "3",
  },
  {
    label: "Any Driver",
    value: "4",
  },
  {
    label: "Insured And 1 Named Driver",
    value: "5",
  },
  {
    label: "One Named Driver Excluding Proposer",
    value: "6",
  },
  {
    label: "Insured And Named Drivers",
    value: "7",
  },
  {
    label: "As Disclosed To The Company",
    value: "8",
  },
  {
    label: "Insured And Civil Partner",
    value: "9",
  },
  {
    label: "Civil Partner",
    value: "A",
  },
  {
    label: "Insured And 2 Named Drivers",
    value: "B",
  },
  {
    label: "Insured And 3 Named Drivers",
    value: "C",
  },
  {
    label: "Insured And 4 Named Drivers",
    value: "D",
  },
  {
    label: "Any Driver Including Policyholder Excluding Named Persons",
    value: "E",
  },
  {
    label: "Any Driver Excluding Policyholder",
    value: "F",
  },
  {
    label: "Any Driver Excluding Policyholder And Named Persons",
    value: "G",
  },
  {
    label: "Any Driver Excluding Drivers Under 21",
    value: "H",
  },
  {
    label: "Any Driver Excluding Drivers Under 25",
    value: "I",
  },
  {
    label: "Any Driver Excluding Drivers Under 30",
    value: "J",
  },
  {
    label: "Insured And Parent",
    value: "K",
  },
  {
    label: "Insured And Parents",
    value: "L",
  },
  {
    label: "Any Driver Excluding Drivers Under 26",
    value: "M",
  },
  {
    label: "Any Driver Excluding Drivers Under 21 Other Than Those Named",
    value: "N",
  },
  {
    label: "Any Driver Excluding Drivers Under 25 Other Than Those Named",
    value: "O",
  },
  {
    label: "Any Driver Excluding Drivers Under 26 Other Than Those Named",
    value: "P",
  },
  {
    label: "Any Driver Excluding Drivers Under 30 Other Than Those Named",
    value: "Q",
  },
  {
    label: "Insured And 5 Named Drivers",
    value: "R",
  },
  {
    label: "Insured And 6 Named Drivers",
    value: "S",
  },
  {
    label: "Any Driver Excluding Drivers Under 35",
    value: "T",
  },
  {
    label: "Any Driver Excluding Drivers Under 40",
    value: "U",
  },
  {
    label: "Any Driver Excluding Drivers Under 50",
    value: "V",
  },
  {
    label: "Insured And Partner",
    value: "W",
  },
  {
    label: "Any Authorised Licensed Driver",
    value: "X",
  },
  {
    label: "Any Authorised Licensed Employee",
    value: "Y",
  },
  {
    label: "No Code Available",
    value: "Z",
  },
  {
    label: "One Named Driver",
    value: "026",
  },
  {
    label: "Two Named Drivers",
    value: "014",
  },
  {
    label: "Three Named Drivers",
    value: "015",
  },
  {
    label: "Four Named Drivers",
    value: "016",
  },
  {
    label: "Five Named Drivers",
    value: "028",
  },
];
