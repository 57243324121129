import { FormControl, InputLabel, MenuItem, Select, Stack } from "@mui/material";
import { useState, useContext, useEffect } from "react";
import { JourneyContext } from "../../JourneyContext";
import { TFormField } from "../../types";

type TVehicleDriverAssignment = {
  lastName: string;
  firstName: string;
  dateOfBirth: string;
  drivingFrequency?: string;
};

const options = [
  { label: "Main", value: "M" },
  { label: "Frequent", value: "F" },
  { label: "Infrequent (Casual)", value: "C" },
  { label: "Rare", value: "R" },
];

export const doVehicleDriverAssignmentsValidation = (field: TFormField, value: any) => {
  if (Array.isArray(value) && value.length > 0) {
    const mainDriver = value.filter((a: TVehicleDriverAssignment) => a.drivingFrequency === "M");
    const unspecified = value.filter(
      (a: TVehicleDriverAssignment) => a.drivingFrequency === undefined
    );
    return mainDriver.length === 1 && unspecified.length === 0;
  }
  return false;
};

export const VehicleDriverAssignments = (props: any) => {
  const updateAssignment = (driver: TVehicleDriverAssignment, event: any) => {
    const newAssignments = [...assignments];
    const index = newAssignments.findIndex(
      (a: any) =>
        a.lastName === driver.lastName &&
        a.firstName === driver.firstName &&
        a.dateOfBirth === driver.dateOfBirth
    );
    newAssignments[index].drivingFrequency = event.target.value;
    setAssignments(newAssignments);

    if (doVehicleDriverAssignmentsValidation(props.field, newAssignments)) {
      props.changeHandler(props.field, newAssignments);
    } else {
      props.changeHandler(props.field, undefined);
    }
  };

  const renderAssignmentControl = (driver: TVehicleDriverAssignment) => {
    const key = driver.firstName + driver.lastName + driver.dateOfBirth;
    return (
      <Stack spacing={2} key={key}>
        <p>
          How often does {driver.firstName} {driver.lastName} drive this vehicle?
        </p>
        <FormControl fullWidth required>
          <InputLabel id={key + "-label"}>Driving frequency</InputLabel>
          <Select
            labelId={key + "-label"}
            label="Driving frequency"
            value={driver.drivingFrequency ?? ""}
            onChange={(event: any) => updateAssignment(driver, event)}
            MenuProps={{
              style: {
                maxHeight: 400,
              },
            }}
          >
            {options.map((option, index) => {
              return (
                <MenuItem
                  key={index}
                  value={option.value}
                  disabled={
                    index === 0 &&
                    assignments.findIndex((a: any) => a.drivingFrequency === "M") !== -1
                  }
                >
                  {option.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Stack>
    );
  };

  const journeyContext = useContext(JourneyContext);
  const { values } = journeyContext.formData;
  const policyholder = {
    firstName: values["firstName"] ?? "${firstName}",
    lastName: values["lastName"] ?? "${lastName}",
    dateOfBirth: values["dateOfBirth"] ?? "${dateOfBirth}",
  };

  const drivers = (values["drivers"] ?? []).map((driver: TVehicleDriverAssignment) => ({
    firstName: driver.firstName ?? "${firstName}",
    lastName: driver.lastName ?? "${lastName}",
    dateOfBirth: driver.dateOfBirth ?? "${dateOfBirth}",
  }));

  const [assignments, setAssignments] = useState<TVehicleDriverAssignment[]>(
    props.value ?? [policyholder, ...drivers]
  );

  const contextDrivers = journeyContext.formData.values["drivers"];

  // I am the king of trash mountain
  useEffect(() => {
    if (contextDrivers.length + 1 > assignments.length) {
      // A driver can only be added to the end
      const c = contextDrivers[contextDrivers.length - 1];
      setAssignments([
        ...assignments,
        { firstName: c.firstName, lastName: c.lastName, dateOfBirth: c.dateOfBirth },
      ]);
      props.changeHandler(props.field, undefined); // Can no longer be valid
    } else if (contextDrivers.length + 1 < assignments.length) {
      // A driver can be deleted from anywhere
      // Find index of deleted driver
      for (let i = 1; i < assignments.length; i++) {
        const a = assignments[i];
        if (
          contextDrivers.findIndex(
            (d: TVehicleDriverAssignment) =>
              d.firstName === a.firstName &&
              d.lastName === a.lastName &&
              d.dateOfBirth === a.dateOfBirth
          ) === -1
        ) {
          const newAssignments = [...assignments];
          newAssignments.splice(i, 1);
          setAssignments(newAssignments);
          if (doVehicleDriverAssignmentsValidation(props.field, newAssignments)) {
            props.changeHandler(props.field, newAssignments);
          } else {
            props.changeHandler(props.field, undefined);
          }
          break;
        }
      }
    } else {
      // A driver can be edited from anywhere, indexes are preserved
      const newAssignments = [assignments[0]];
      for (let i = 0; i < contextDrivers.length; i++) {
        newAssignments[i + 1] = {
          firstName: contextDrivers[i].firstName,
          lastName: contextDrivers[i].lastName,
          dateOfBirth: contextDrivers[i].dateOfBirth,
          drivingFrequency: assignments[i + 1].drivingFrequency,
        };
      }
      setAssignments(newAssignments);
      if (doVehicleDriverAssignmentsValidation(props.field, newAssignments)) {
        props.changeHandler(props.field, newAssignments);
      } else {
        props.changeHandler(props.field, undefined);
      }
    }
  }, [contextDrivers, contextDrivers.length]);

  const mainDriver = assignments.filter(
    (a: TVehicleDriverAssignment) => a.drivingFrequency === "M"
  );
  const unspecified = assignments.filter(
    (a: TVehicleDriverAssignment) => a.drivingFrequency === undefined
  );

  return (
    <Stack spacing={3}>
      {assignments.map((a: any) => renderAssignmentControl(a))}
      {unspecified.length > 0 ? (
        <b>Please specify the driving frequency for all drivers.</b>
      ) : (
        mainDriver.length === 0 && <b>One driver must be the main driver.</b>
      )}
    </Stack>
  );
};
