import { TFormField } from '../../types';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import { ReactNode, useEffect, useState } from 'react';

import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import WarningIcon from '@mui/icons-material/Warning';

const variants = {
  info: {
    Icon: <ErrorOutlineIcon color="primary" fontSize="small" />,
    titleColor: 'primary',
  },
  warning: {
    Icon: <WarningIcon color="primary" fontSize="small" />,
    titleColor: 'primary',
  },
};

const defaultTitle = 'Important Information';

export const MessageNotificationField = (props: { field: TFormField }) => {
  const theme = useTheme();

  const variant = props?.field?.variant ?? 'info';

  return (
    <Stack
      spacing={1}
      sx={{
        bgcolor: 'white',
        border: 1,
        borderRadius: 2,
        borderColor: theme.palette.primary.main,
        padding: 1.5,
      }}
    >
      <Stack direction="row" spacing={1} sx={{ alignItems: 'center' }}>
        {variants[variant as keyof typeof variants].Icon}
        <Typography
          paddingLeft={1}
          fontSize={16}
          fontWeight={600}
          fontFamily={'Inter'}
          color={variants[variant as keyof typeof variants].titleColor}
        >
          {props.field.title ?? defaultTitle}
        </Typography>
      </Stack>
      <Box>
        <Typography fontSize={12} fontWeight={400} fontFamily={'Inter'} color={'#565E73'}>
          {Array.isArray(props.field?.content)
            ? props.field.content?.map((paragraph,index) => (
                <p
                  style={{
                    marginTop: index > 0 ? 10 : 0,
                  }}
                >
                  {paragraph}
                </p>
              ))
            : props.field.content}
        </Typography>
      </Box>
    </Stack>
  );
};
