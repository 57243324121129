export const MedicalCondition = [
  {
    label: "Achondroplasia",
    value: "AC",
  },
  {
    label: "Acute Psychosis",
    value: "40",
  },
  {
    label: "Addisons Disease",
    value: "DP",
  },
  {
    label: "Alcoholism",
    value: "41",
  },
  {
    label: "Alveolitis",
    value: "DS",
  },
  {
    label: "Alzheimers Disease",
    value: "42",
  },
  {
    label: "Amnesia",
    value: "DT",
  },
  {
    label: "Anaemia",
    value: "FF",
  },
  {
    label: "Anaphylactic Shock",
    value: "DR",
  },
  {
    label: "Aneurysm",
    value: "FG",
  },
  {
    label: "Angina",
    value: "33",
  },
  {
    label: "Ankylosing Spondylitis",
    value: "AL",
  },
  {
    label: "Anorexia",
    value: "AM",
  },
  {
    label: "Anxiety",
    value: "43",
  },
  {
    label: "Arthritis",
    value: "34",
  },
  {
    label: "Asthma",
    value: "87",
  },
  {
    label: "Atherosclerosis",
    value: "AN",
  },
  {
    label: "Atrial Fibrillation",
    value: "DV",
  },
  {
    label: "Autoimmune Disease",
    value: "AP",
  },
  {
    label: "Bi Polar",
    value: "FK",
  },
  {
    label: "Bladder Cancer",
    value: "AQ",
  },
  {
    label: "Breast Cancer",
    value: "AR",
  },
  {
    label: "Bronchitis",
    value: "AS",
  },
  {
    label: "Brucellosis",
    value: "AT",
  },
  {
    label: "Bulimia",
    value: "AV",
  },
  {
    label: "Bursitis",
    value: "AW",
  },
  {
    label: "Cancer",
    value: "44",
  },
  {
    label: "Cancer of the Uteria Cervix",
    value: "AX",
  },
  {
    label: "Cardio Vascular System",
    value: "AY",
  },
  {
    label: "Cataract - Both Eyes",
    value: "45",
  },
  {
    label: "Cataract - Left Eye",
    value: "46",
  },
  {
    label: "Cataract - Right Eye",
    value: "47",
  },
  {
    label: "Cerebral Haemorrhage",
    value: "48",
  },
  {
    label: "Cerebral Palsy",
    value: "49",
  },
  {
    label: "Cerebrovascular Accident (CVA)",
    value: "AZ",
  },
  {
    label: "Chronic Fatigue Syndrome",
    value: "DW",
  },
  {
    label: "Chronic Obstructive Pulmonary Disease (COPD)",
    value: "BA",
  },
  {
    label: "Cirrhosos of the Liver",
    value: "DX",
  },
  {
    label: "Coeliac Disease",
    value: "BB",
  },
  {
    label: "Colitis",
    value: "BC",
  },
  {
    label: "Colorectal Cancer",
    value: "BD",
  },
  {
    label: "Colour Blindness",
    value: "29",
  },
  {
    label: "Congestive Heart Failure",
    value: "BE",
  },
  {
    label: "Cornea Transplant",
    value: "97",
  },
  {
    label: "Coronary Artery Disease",
    value: "BF",
  },
  {
    label: "Creutzfeldt - Jakob Disease",
    value: "CN",
  },
  {
    label: "Crohns Disease",
    value: "BG",
  },
  {
    label: "Cushings Disease/Syndrome",
    value: "DY",
  },
  {
    label: "Cystic Fibrosis",
    value: "BH",
  },
  {
    label: "Deaf",
    value: "13",
  },
  {
    label: "Deep Vein Thrombosis (DVT)",
    value: "BJ",
  },
  {
    label: "Defective Hearing (Corrected By Hearing Aid)",
    value: "32",
  },
  {
    label: "Dementia",
    value: "BK",
  },
  {
    label: "Depression",
    value: "50",
  },
  {
    label: "Diabetes (Controlled By Diet)",
    value: "16",
  },
  {
    label: "Diabetes (Controlled By Insulin)",
    value: "17",
  },
  {
    label: "Diabetes (Controlled By Tablets)",
    value: "18",
  },
  {
    label: "Diabetic",
    value: "14",
  },
  {
    label: "Diplopia - Double Vision",
    value: "AD",
  },
  {
    label: "Drug Addiction",
    value: "51",
  },
  {
    label: "Duodenal Ulcer",
    value: "BL",
  },
  {
    label: "Dyspepsia",
    value: "EA",
  },
  {
    label: "Eczema",
    value: "EB",
  },
  {
    label: "Embolism",
    value: "52",
  },
  {
    label: "Emphysema",
    value: "53",
  },
  {
    label: "Endocarditis",
    value: "BM",
  },
  {
    label: "Endometrial Cancer",
    value: "BN",
  },
  {
    label: "Endometriosis",
    value: "BP",
  },
  {
    label: "Epileptic",
    value: "15",
  },
  {
    label: "Fibroids",
    value: "BQ",
  },
  {
    label: "Fibrositis",
    value: "BR",
  },
  {
    label: "Gallstones",
    value: "BS",
  },
  {
    label: "Gastric Ulcer",
    value: "BT",
  },
  {
    label: "Gastrointestinal Tract",
    value: "BV",
  },
  {
    label: "Genitourinary Tract",
    value: "BW",
  },
  {
    label: "Glaucoma - Both Eyes",
    value: "54",
  },
  {
    label: "Glaucoma - Left Eye",
    value: "55",
  },
  {
    label: "Glaucoma - Right Eye",
    value: "56",
  },
  {
    label: "Glioma",
    value: "BX",
  },
  {
    label: "Gout",
    value: "AG",
  },
  {
    label: "Graves Disease (Thyrotoxicosis)",
    value: "BY",
  },
  {
    label: "Haemolytic Uraemic Syndrome",
    value: "BZ",
  },
  {
    label: "Haemophilia",
    value: "CA",
  },
  {
    label: "Haemophilic Arthropathy",
    value: "CB",
  },
  {
    label: "Haemorrhage",
    value: "CC",
  },
  {
    label: "Haemorrhoids",
    value: "EC",
  },
  {
    label: "Heart Attack - Multiple",
    value: "37",
  },
  {
    label: "Heart Attack - Single",
    value: "38",
  },
  {
    label: "Heart By-pass Operation",
    value: "35",
  },
  {
    label: "Heart Condition (Controlled By Drugs)",
    value: "19",
  },
  {
    label: "Heart Condition (Controlled By Pacemaker)",
    value: "31",
  },
  {
    label: "Heart Condition (No Drugs Required)",
    value: "20",
  },
  {
    label: "Heart Surgery - Other",
    value: "93",
  },
  {
    label: "Heart Transplant",
    value: "36",
  },
  {
    label: "Heart Valve Replacement",
    value: "92",
  },
  {
    label: "Hepatitis",
    value: "CD",
  },
  {
    label: "Hepatocirrhosis",
    value: "CE",
  },
  {
    label: "Hepatoma",
    value: "CF",
  },
  {
    label: "Hernia",
    value: "AH",
  },
  {
    label: "High Blood Pressure",
    value: "57",
  },
  {
    label: "HIV",
    value: "58",
  },
  {
    label: "Hodgkins Disease",
    value: "88",
  },
  {
    label: "Huntingtons Chorea (Disease)",
    value: "CG",
  },
  {
    label: "Hydrocephalus",
    value: "ED",
  },
  {
    label: "Hypertension",
    value: "59",
  },
  {
    label: "Hypothyroidism",
    value: "EE",
  },
  {
    label: "Ileitis",
    value: "CH",
  },
  {
    label: "Immunodeficiency",
    value: "CJ",
  },
  {
    label: "Impetigo",
    value: "CK",
  },
  {
    label: "Implanted Cardiac Defibrillator",
    value: "FM",
  },
  {
    label: "Irritable Bowel Syndrome",
    value: "CL",
  },
  {
    label: "Ischaemic Heart Disease",
    value: "CM",
  },
  {
    label: "Jaundice",
    value: "CP",
  },
  {
    label: "Kidney Transplant",
    value: "96",
  },
  {
    label: "Laryngeal Cancer",
    value: "CQ",
  },
  {
    label: "Leukaemia",
    value: "CR",
  },
  {
    label: "Loss Of Finger",
    value: "60",
  },
  {
    label: "Loss Of Limb",
    value: "27",
  },
  {
    label: "Loss Of Thumb",
    value: "61",
  },
  {
    label: "Loss Of Toe",
    value: "62",
  },
  {
    label: "Loss One Arm",
    value: "21",
  },
  {
    label: "Loss One Eye",
    value: "22",
  },
  {
    label: "Loss One Leg",
    value: "23",
  },
  {
    label: "Loss/Use Both Arms",
    value: "63",
  },
  {
    label: "Loss/Use Both Feet",
    value: "64",
  },
  {
    label: "Loss/Use Both Hands",
    value: "65",
  },
  {
    label: "Loss/Use Left Arm",
    value: "05",
  },
  {
    label: "Loss/Use Left Eye Caused By Disease - Full Vision In Right Eye",
    value: "66",
  },
  {
    label: "Loss/Use Left Eye Caused By Disease - Partial Vision In Right Eye",
    value: "67",
  },
  {
    label: "Loss/Use Left Eye Not Caused By Disease - Partial Vision In Right Eye",
    value: "68",
  },
  {
    label: "Loss/Use Left Foot",
    value: "07",
  },
  {
    label: "Loss/Use Left Hand",
    value: "03",
  },
  {
    label: "Loss/Use Left Leg",
    value: "09",
  },
  {
    label: "Loss/Use Of Both Legs",
    value: "39",
  },
  {
    label: "Loss/Use Right Arm",
    value: "04",
  },
  {
    label: "Loss/Use Right Eye Caused By Disease - Full Vision In Left Eye",
    value: "69",
  },
  {
    label: "Loss/Use Right Eye Caused By Disease - Partial Vision In Left Eye",
    value: "70",
  },
  {
    label: "Loss/Use Right Eye Not Caused By Disease - Partial Vision In Left Eye",
    value: "71",
  },
  {
    label: "Loss/Use Right Foot",
    value: "06",
  },
  {
    label: "Loss/Use Right Hand",
    value: "02",
  },
  {
    label: "Loss/Use Right Leg",
    value: "08",
  },
  {
    label: "Low Blood Pressure",
    value: "72",
  },
  {
    label: "Lumbago",
    value: "89",
  },
  {
    label: "Lung Cancer",
    value: "CS",
  },
  {
    label: "Lung Transplant",
    value: "95",
  },
  {
    label: "Lupus",
    value: "CT",
  },
  {
    label: "Lymphoma",
    value: "CV",
  },
  {
    label: "Manic Depression",
    value: "73",
  },
  {
    label: "Melanoma",
    value: "CW",
  },
  {
    label: "Menieres Disease",
    value: "EF",
  },
  {
    label: "Meningitis",
    value: "EG",
  },
  {
    label: "Mental/Nervous Disability",
    value: "28",
  },
  {
    label: "Motor Neurone Disease",
    value: "74",
  },
  {
    label: "Multiple Infirmities",
    value: "24",
  },
  {
    label: "Multiple Sclerosis",
    value: "75",
  },
  {
    label: "Muscular Dystrophy",
    value: "76",
  },
  {
    label: "Myalgia Encephala-Myelitis",
    value: "77",
  },
  {
    label: "Myasthenia Gravis - Chronic Fatigue Syndrome",
    value: "FH",
  },
  {
    label: "Myeloma",
    value: "CX",
  },
  {
    label: "Myocardial Infarction",
    value: "CY",
  },
  {
    label: "Myopathy",
    value: "90",
  },
  {
    label: "Narcolepsy",
    value: "78",
  },
  {
    label: "Nephrotic Syndrome",
    value: "EH",
  },
  {
    label: "Neuralgia",
    value: "CZ",
  },
  {
    label: "Night Blindness",
    value: "30",
  },
  {
    label: "No Sight Left Eye",
    value: "11",
  },
  {
    label: "No Sight Right Eye",
    value: "10",
  },
  {
    label: "Non-cancerous Tumour",
    value: "FL",
  },
  {
    label: "Non-Hodgkins Lymphoma",
    value: "DA",
  },
  {
    label: "Oedema",
    value: "DB",
  },
  {
    label: "Oesophagitis",
    value: "EJ",
  },
  {
    label: "Osteoarthritis",
    value: "DC",
  },
  {
    label: "Osteoporosis",
    value: "DD",
  },
  {
    label: "Other",
    value: "99",
  },
  {
    label: "Ovarian Cancer",
    value: "DE",
  },
  {
    label: "Over Active Thyroid Gland",
    value: "AA",
  },
  {
    label: "Pagets Disease",
    value: "EK",
  },
  {
    label: "Pancreatic Cancer",
    value: "DF",
  },
  {
    label: "Paraplegia",
    value: "79",
  },
  {
    label: "Parkinsons Disease",
    value: "80",
  },
  {
    label: "Partially Deaf",
    value: "12",
  },
  {
    label: "Pericarditis",
    value: "EL",
  },
  {
    label: "Peritonitis",
    value: "EM",
  },
  {
    label: "Petit Mal",
    value: "81",
  },
  {
    label: "Pleurisy",
    value: "EN",
  },
  {
    label: "Pneumonia",
    value: "EP",
  },
  {
    label: "Poliomyelitis",
    value: "82",
  },
  {
    label: "Polycystic Kidney Disease",
    value: "ER",
  },
  {
    label: "Polycythemia",
    value: "ES",
  },
  {
    label: "Pregnancy",
    value: "ET",
  },
  {
    label: "Prostate Cancer",
    value: "DG",
  },
  {
    label: "Psychiatric Disorder",
    value: "94",
  },
  {
    label: "Pulmonary Embolism",
    value: "EV",
  },
  {
    label: "Pulmonary Fibrosis",
    value: "EW",
  },
  {
    label: "Raynauds Disease",
    value: "EX",
  },
  {
    label: "Restriction Of Movement In Limbs, Spine Or Joints",
    value: "25",
  },
  {
    label: "Rheumatism",
    value: "83",
  },
  {
    label: "Rheumatoid Arthritis",
    value: "AE",
  },
  {
    label: "Rhinitis",
    value: "FE",
  },
  {
    label: "Sarcoidosis",
    value: "EY",
  },
  {
    label: "Schizophrenia",
    value: "84",
  },
  {
    label: "Sleep Apnoea",
    value: "FJ",
  },
  {
    label: "Spina Bifida",
    value: "91",
  },
  {
    label: "Stroke",
    value: "26",
  },
  {
    label: "Systemic Lupis Erythematosus (SLE)",
    value: "FA",
  },
  {
    label: "Testicular Cancer",
    value: "DH",
  },
  {
    label: "Thrombosis",
    value: "86",
  },
  {
    label: "Thyroid Cancer",
    value: "DJ",
  },
  {
    label: "Tinnitus",
    value: "FB",
  },
  {
    label: "Transient Ischaemic Attack",
    value: "85",
  },
  {
    label: "Tuberculosis",
    value: "DK",
  },
  {
    label: "Tumours of the Nervous System",
    value: "DL",
  },
  {
    label: "Under Active Thyroid Gland",
    value: "AB",
  },
  {
    label: "Upper Respiratory Tract Infections",
    value: "DM",
  },
  {
    label: "Urinary Tract Infections",
    value: "DN",
  },
  {
    label: "Varicose Veins",
    value: "FC",
  },
  {
    label: "Vertigo",
    value: "AF",
  },
  {
    label: "Wolf Parkinson White Syndrome",
    value: "FD",
  },
];
