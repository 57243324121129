export const PreferredContactMethod = [
  {
    label: "Phone",
    value: "PHONE",
  },
  {
    label: "Email",
    value: "EMAIL",
  },
  {
    label: "Sms",
    value: "SMS",
  },
  {
    label: "Post",
    value: "POST",
  },
];
