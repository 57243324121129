export const Sex = [
  {
    "label": "Female",
    "value": "F"
  },
  {
    "label": "Male",
    "value": "M"
  },
  {
    "label": "Not Applicable",
    "value": "N"
  }
]