export const ReleaseType = [
  {
    label: "Full Term",
    value: "F",
  },
  {
    label: "Early Release",
    value: "E",
  },
  {
    label: "Not Applicable",
    value: "N",
  },
];
