export const NonStandardUse = [
  {
    label: "Deliveries Limited Radius Miles",
    value: "U65",
  },
  {
    label: "Scrap Waste Limited Miles Radius",
    value: "U66",
  },
  {
    label: "Deliveries",
    value: "U67",
  },
  {
    label: "Scrap Waste",
    value: "U68",
  },
  {
    label: "Public Private Hire",
    value: "U71",
  },
  {
    label: "Contract Carried",
    value: "U72",
  },
  {
    label: "Contract Hire",
    value: "U59",
  },
  {
    label: "Stage Carriage",
    value: "U64",
  },
  {
    label: "Carriage Of Non Fare Paying Passengers",
    value: "U70",
  },
  {
    label: "Carriage Of Passengers Section 19 Permit",
    value: "U13",
  },
  {
    label: "Carriage Of Passengers Section 22 Permit",
    value: "U14",
  },
  {
    label: "Employee Transportation Only",
    value: "U25",
  },
  {
    label: "Loaned Under Section 19 Permit",
    value: "U32",
  },
  {
    label: "Taxibus Under A Special Psv Operators License",
    value: "U54",
  },
  {
    label: "Private Hire Schools And Hospital Use",
    value: "U61",
  },
  {
    label: "Other Uses Not Listed Above",
    value: "U73",
  },
  {
    label: "Wedding Funeral And Special Occasions Hire",
    value: "U74",
  },
  {
    label: "Agriculture",
    value: "U01",
  },
  {
    label: "Fast Food Delivery",
    value: "U80",
  },
];
