import { Button, Container, Drawer, Hidden, Skeleton, Stack, Typography, Zoom } from '@mui/material';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useLayoutEffect, useState } from 'react';
import QuoteListingSideOptions from './QuoteListingSideOptions';
import {
  IStringIndex,
  QuoteRequestResponse,
  TAlert,
  TBranding,
  TFieldChangeHandler,
  TFormData,
  TJourneyConfig,
  TQuote,
} from '../../types';
import { MessageNotificationField } from '../FormFields/MessageNotificationField';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import QuoteListingCard from './QuoteListingCard';
import QuoteListingError from './QuoteListingError';
import { useTheme } from '@mui/material';
import { ModeEditOutline } from '@mui/icons-material';
import { useQuery } from '@tanstack/react-query';
import { patchQuote } from '../../apiCalls';
import _ from 'lodash';

function sortByProperty<T extends QuoteRequestResponse>(array: T[], property: keyof T): T[] {
  return _.sortBy(array, property);
}
export interface QuoteListingProps {
  handleSubmit?: (e: any, selectedQuote?: TQuote) => Promise<void>;
  changeHandler: TFieldChangeHandler;
  formData: TFormData;
  config: TJourneyConfig;
  backHandler?: () => void;
  isSubmitting?: boolean;
  submitError?: TAlert;
  quoteListingError?: boolean;
  branding?: TBranding;
  activeIndex?: number;
  setFormData?: Dispatch<SetStateAction<TFormData>>;
  setQuoteListingError?: Dispatch<SetStateAction<boolean | null>>;
  disablePrePayment?: boolean;
}

export default function QuoteListingPage({
  changeHandler,
  config,
  formData,
  backHandler,
  handleSubmit,
  isSubmitting,
  submitError,
  quoteListingError = false,
  branding,
  activeIndex,
  setQuoteListingError,
  setFormData,
  disablePrePayment,
}: QuoteListingProps) {
  const [comparativeQuotes, setcomparativeQuotes] = useState<QuoteRequestResponse[]>(
    formData?.values?.quote?.comparativeQuotes,
  );

  const [openEditor, setOpenEditor] = useState(false);
  const [isError, setIsError] = useState(quoteListingError);

  const paymentFrequency = formData?.values?.paymentFrequency;

  const {
    data,
    isLoading: isFetchingMonthly,
    error,
    isFetching: isRefetchingMonthly,
    refetch,
  } = useQuery({
    queryFn: async () => {
      const formValues = formData?.values;

      if (!formValues) return Promise.resolve({});

      const cleanValues: IStringIndex<any> = {};
      const apiConfig = config?.APIs?.patch;
      for (let [fieldName, fieldValue] of Object.entries(formValues)) {
        if (typeof fieldValue === 'string') {
          cleanValues[fieldName] = fieldValue.replace(/\s+/g, ' ').trim();
        } else {
          cleanValues[fieldName] = fieldValue;
        }
      }

      const res = await patchQuote(config, apiConfig, cleanValues, 'comparativeQuotesMonthly');
      const updatedComparativeQuotes: QuoteRequestResponse[] = res?.[0]?.comparativeQuotes;
      if (comparativeQuotes) {
        setcomparativeQuotes((prev) =>
          prev?.map((cQuote) => {
            const updated = updatedComparativeQuotes?.find((q) => q.schemeRef === cQuote.schemeRef);
            return updated ? { ...cQuote, ...updated } : cQuote;
          }),
        );
      }
      setFormData?.({
        values: {
          ...cleanValues,
          quote: res?.[0],
        },
        validations: { ...formData.validations },
      });
      return res;
    },
    queryKey: ['comparativeQuotesMonthly', paymentFrequency, formData?.values?.quote?.quoteId],
    refetchOnMount: true,
    enabled: paymentFrequency === "MONTHLY"
  });

  useLayoutEffect(() => {
    if (isError) {
      setQuoteListingError?.(true);
    }
  }, [isError]);

  return (
    <Container maxWidth="xl" sx={{ display: 'flex', alignItems: 'start' }}>
      {isError ? (
        <Container
          sx={{ paddingY: 10, width: '100%', alignItems: 'center', justifyContent: 'center', display: 'flex' }}
        >
          <QuoteListingError backHandler={backHandler} />
        </Container>
      ) : (
        <>
          {/* Side Options */}
          <Hidden mdDown>
            <QuoteListingSideOptions
              disablePrePayment={disablePrePayment}
              setFormData={setFormData}
              refetchMonthly={refetch as any}
              setcomparativeQuotes={setcomparativeQuotes}
              setIsError={setIsError}
              activeIndex={activeIndex}
              branding={branding}
              isSubmitting={isSubmitting}
              handleSubmit={handleSubmit}
              backHandler={backHandler}
              changeHandler={changeHandler}
              config={config}
              formData={formData}
            />
          </Hidden>
          {/* Main Container */}
          <Stack marginTop={5} direction={'column'} spacing={2} marginLeft={{ xs: 0, md: 2 }} width={'100%'}>
            <Stack
              direction={{ xs: 'row', md: 'column' }}
              alignItems={{ xs: 'center', md: 'start' }}
              justifyContent={'space-between'}
            >
              <Typography color={'primary'} fontSize={{ xs: 20, md: 30 }} fontWeight={600} fontFamily={'Poppins'}>
                Your quotes
              </Typography>
              <Hidden mdUp>
                <Button
                  startIcon={<ModeEditOutline />}
                  sx={{ textTransform: 'none' }}
                  variant="contained"
                  size="small"
                  onClick={() => setOpenEditor(true)}
                >
                  Edit quote
                </Button>
              </Hidden>
            </Stack>
            <Hidden mdUp>
              <Drawer anchor="bottom" open={openEditor} onClose={() => setOpenEditor(false)}>
                <QuoteListingSideOptions
                  refetchMonthly={refetch}
                  setcomparativeQuotes={setcomparativeQuotes}
                  setIsError={setIsError}
                  activeIndex={activeIndex}
                  branding={branding}
                  isSubmitting={isSubmitting}
                  handleSubmit={handleSubmit}
                  backHandler={backHandler}
                  changeHandler={changeHandler}
                  config={config}
                  formData={formData}
                />
              </Drawer>
            </Hidden>
            <Stack
              alignItems={'center'}
              direction={'row'}
              spacing={1}
              border={'2px solid #E9EAFF'}
              borderRadius={2}
              paddingX={1}
              paddingY={1}
              sx={{
                bgcolor: 'white',
              }}
            >
              <ErrorOutlineIcon color="primary" sx={{ marginRight: 1 }} />
              <Typography fontSize={14} fontWeight={400} fontFamily={'Inter'} color={'#464C5E'}>
                Before purchasing, compare cover levels and types as product features vary. Paying monthly will be more
                expensive than paying in one payment as interest is included.
              </Typography>
            </Stack>

            <Typography color={'primary'} fontSize={16} fontWeight={700} fontFamily={'Inter'}>
              {comparativeQuotes?.length ?? 0}{' '}
              <span style={{ color: '#1A2133' }}>{comparativeQuotes?.length > 1 ? 'quotes found' : 'quote found'}</span>
            </Typography>
            {sortByProperty(comparativeQuotes, 'premiumIncIPT')?.map((quote: any, index: number) =>
              isRefetchingMonthly || isFetchingMonthly ? (
                <Skeleton variant="rectangular" width={'100%'} height={150} sx={{ borderRadius: 2 }} />
              ) : (
                <QuoteListingCard
                  setFormData={setFormData}
                  submitError={submitError}
                  isSubmitting={isSubmitting}
                  handleSubmit={handleSubmit}
                  quote={quote}
                  key={quote.quoteId}
                  config={config}
                  formData={formData}
                />
              ),
            )}
          </Stack>
        </>
      )}
    </Container>
  );
}
