// =================================================================================================
// Very simple toggle switch for e.g. confirming details
// =================================================================================================

import { Checkbox, Stack, Switch, Typography, useTheme } from '@mui/material';
import { TFormField, TFieldChangeHandler, TJourneyConfig, TFormData } from '../../types';
import { DialogBox } from '../DialogBox';
import { useState } from 'react';
import CoverInfoModal from './CoverInfoModal';

// =================================================================================================
// Main component
// =================================================================================================

export const SwitchInfoField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
  config: TJourneyConfig;
  formData: TFormData;
}) => {
  const theme = useTheme();
  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const { field, value, changeHandler, config } = props;

  const onValue = field.switchParams?.onValue ?? true;
  const offValue = field.switchParams?.offValue ?? false;

  if (config?.renderVersion === 'V2') {
    return (
      <>
        <Stack direction="row" alignItems="flex-start">
          <Checkbox
            size="small"
            sx={{
              marginTop: -1,
            }}
            color="primary"
            id={field.name}
            disabled={field.disabled}
            checked={value === onValue}
            onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
              changeHandler(field, checked ? onValue : offValue);
            }}
          />
          <Typography fontSize={14} fontFamily={'Inter'}>
            <label
              style={{
                marginTop: 7,
                cursor: field.disabled ? 'default' : 'pointer',
              }}
              htmlFor={field.name}
            >
              {field?.props?.text}{' '}
            </label>{' '}
            <span
              onClick={toggleDialog}
              style={{
                textDecoration: 'underline',
                cursor: 'pointer',
                color: theme.palette.primary.main,
              }}
            >
              {field?.props?.link}
            </span>
          </Typography>
        </Stack>
        <DialogBox open={dialogOpen} onClose={() => setDialogOpen(false)} field={field} config={config} />
      </>
    );
  }

  return (
    <div>
      <Stack direction="row" alignItems="flex-start">
        <Switch
          id={field.name}
          disabled={field.disabled}
          checked={value === onValue}
          onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            changeHandler(field, checked ? onValue : offValue);
          }}
        />
        <div>
          <label
            style={{
              marginTop: 7,
              cursor: field.disabled ? 'default' : 'pointer',
            }}
            htmlFor={field.name}
          >
            {field?.props?.text}{' '}
          </label>{' '}
          <span
            onClick={toggleDialog}
            style={{
              textDecoration: 'underline',
              cursor: 'pointer',
              color: 'skyblue',
            }}
          >
            {field?.props?.link}
          </span>
        </div>
      </Stack>
      <DialogBox open={dialogOpen} onClose={() => setDialogOpen(false)} field={field} config={config} />
    </div>
  );
};
