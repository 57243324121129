import {
  Alert,
  AlertTitle,
  Container,
  createTheme,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import {
  completeQuote,
  getBranding,
  getProductBroker,
  getQuote,
  getQuoteSummary,
} from '../apiCalls';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { getEnvParams, getTitleFromKey, toTitleCase } from '../utils';
import Slide from '@mui/material/Slide';
import { LoadingIndicator } from './LoadingIndicator';
import { CenteredError } from './CenteredError';
import { PaymentDetails, TBranding, TError, TJourneyConfig, TProductData } from '../types';
import completePaymentResponse from '../mockedResponses/completePaymentResponse.json';
import { defaultBrand, defaultTheme } from '../defaultBranding';
import { Branding } from './Branding';
import Confetti from './Confetti';
import moment from 'moment';
import { RegulatoryFooter } from './RegulatoryFooter';
import { getJourneyById } from '../journeyConfigs';
import { ProductsContext } from '../ProductsApiContext';

// =================================================================================================
// Main component
// =================================================================================================

type TCompletePaymentResult = {
  productName: string;
  documents: any;
  proposerName: string;
  proposerEmail: string;
  policyNumber: string;
  paymentDetails: Partial<PaymentDetails>;
  inceptionDate: string;
  endDate: string;
  monthlyPremium: string;
};

const { isEmbedded } = getEnvParams();

export const SuccessPage = (props: { quoteId: string | null,branding:TBranding }) => {
  const { quoteId } = props;
  const [mode, setMode] = useState<'LOADING' | 'SUCCESS' | 'PROCESSED' | 'ERROR' | 'REFERRED'>(
    'LOADING'
  );
  const [quote, setQuote] = useState<TCompletePaymentResult | null>(null);
  const [journey, setJourney] = useState<TJourneyConfig | null>(null);
  const [error, setError] = useState<TError>({});
  const [theme, setTheme] = useState(defaultTheme);
  const [branding, setBranding] = useState<TBranding>(defaultBrand);
  const [brokerConfigPayload, setBrokerConfigPayload] = useState<{
    productId: string;
    brokerId: string;
  } | null>(null);
  const [showEmailMessage, setShowEmailMessage] = useState(false);
  const { product } = useContext(ProductsContext);

  const isScratchAndDent1Edge = journey?.product?.id === 'pro-e81ce45f-6909-485f-81d7-f7935065446d';

  useLayoutEffect(() => {
    (async () => {
      if (brokerConfigPayload) {
        const { brokerId, productId } = brokerConfigPayload;
        const response = await getProductBroker(brokerId, productId);
        const brokerDetails = response.data[brokerId];
        setShowEmailMessage(brokerDetails.sendWelcomeEmail);
      }
    })();
  }, [brokerConfigPayload]);

  const handleBranding = (branding: TBranding) => {
    try {
      const brandedTheme = createTheme({
        palette: {
          primary: {
            main: branding.main_color,
          },
        },
      });
      if (branding.name) {
        document.title = branding.name;
      }
      setBranding(branding);
      setTheme(brandedTheme);
    } catch (e) {
      return false;
    }
    return true;
  };

  const handleCompleteQuote = async (data: any) => {
    if (data?.state === 'REFERRED' || data?.state === 'BOUND') {
      const quote = {
        productName: data.productName,
        documents: data.documents,
        insurerName: data.insurerName,
        proposerName: data.proposerName,
        proposerEmail: data.proposerEmail,
        policyNumber: data.policyNumber,
        paymentDetails: {} as Partial<PaymentDetails>,
        inceptionDate: data.inceptionDate,
        endDate: data.endDate,
        monthlyPremium: data.monthlyPremium,
      };
      if (quoteId) {
        const fullQuote = await getQuote(quoteId);
        if (fullQuote?.paymentDetails) {
          quote.paymentDetails = fullQuote.paymentDetails;
          if (fullQuote.paymentProvider === 'stripe') {
            quote.paymentDetails.amount = `${Number(quote.paymentDetails.amount) / 100}`;
            quote.paymentDetails.paymentFrequency = fullQuote.paymentFrequency.toLowerCase();
          }
        }
        setBrokerConfigPayload({
          productId: fullQuote.productId,
          brokerId: fullQuote.mainBrokerId,
        });
      }
      setQuote(quote);
      if (data?.state === 'REFERRED') {
        setMode('REFERRED');
      } else if (data?.state === 'BOUND') {
        setMode('SUCCESS');
      }
    } else {
      setMode('ERROR');
      setError({
        title: 'Could Not Process Payment',
        content: 'There was a problem processing this transaction.',
      });
    }
  };

  useEffect(() => {
    if (quoteId === 'TEST') {
      handleCompleteQuote(completePaymentResponse);
    } else if (quoteId) {
      const doApiCalls = async () => {
        const summary = await getQuoteSummary(quoteId);
        // We couldn't even get the summary
        if (summary === null) {
          setMode('ERROR');
          setError({
            title: 'Quote not found',
            content: 'Could not find the quote you are trying to process.',
          });
          return;
        }

        // const journey = getJourneyById(summary.productId);
        const journey = product?.journeyConfig as any
        // Failed to get journey config
        if (journey === null) {
          setMode('ERROR');
          setError({
            title: 'Problem Fetching Data',
            content: 'The journey config could not be fetched.',
          });
          return;
        } else {
          setJourney(journey);
        }

        if (summary.mainBrokerId) {
          // const branding = await getBranding(summary.mainBrokerId);
          const branding = props?.branding
          if (branding === null) {
            setMode('ERROR');
            setError({
              title: 'Problem Fetching Data',
              content: 'The branding could not be fetched.',
            });
            return;
          }
          if (!handleBranding(branding)) {
            setMode('ERROR');
            setError({
              title: 'Unexpected branding response',
              content: `The format of the branding response is invalid or incomplete.`,
            });
            return;
          }
        }

        // This one has already been processed
        if (
          summary.state === 'BOUND' ||
          summary.state === 'EXPIRED' ||
          summary.state === 'REFERRED'
        ) {
          handleCompleteQuote(summary);
          return;
        }

        // What we're hoping for
        if (summary.state === 'QUOTE') {
          const data = await completeQuote(quoteId);
          handleCompleteQuote(data);
        } else {
          setMode('ERROR');
          setError({
            title: 'Invalid Quote State',
            content: 'The quote is not in a state that can be worked with.',
          });
        }
      };

      doApiCalls();
    }
  }, [quoteId]);

  // -----------------------------------------------------------------------------------------------
  // Render helpers
  // -----------------------------------------------------------------------------------------------

  const renderReferred = () => {
    if (!quote) {
      return;
    }
    return (
      <Container maxWidth="sm" sx={{ borderRadius: '20px' }} className="journeyContainer">
        <Stack justifyContent="center" alignItems="center" spacing={2}>
          <h2>Thank you</h2>
          <p>
            Thank you for completing the application. Your responses have been referred to a member
            of our team who will review and be in contact shortly.
          </p>
        </Stack>
      </Container>
    );
  };

  const renderSuccess = () => {
    if (!quote) {
      return;
    }
    // This is terrible, but it will have to do for now
    const yearlyText =
      journey?.product?.id === 'pro-af131b02-5d32-45be-ab7f-721199896c69' ||
      journey?.product?.id === 'pro-87b397be-396b-4f36-9590-873548e94f5e'
        ? 'yearly'
        : 'one-off';
    return (
      <>
        <Confetti />
        <Slide direction="up" in={true} mountOnEnter unmountOnExit timeout={1000}>
          <Container maxWidth="sm" sx={{ borderRadius: '20px' }} className="journeyContainer">
            <Stack justifyContent="center" alignItems="center" spacing={2}>
              {quote.proposerName && (
                <h1 className="sectionHeading">Congrats {toTitleCase(quote.proposerName)}!</h1>
              )}
              {quote?.paymentDetails?.pcStatus === '202' ? (
                <Alert severity="info" sx={{ border: '1px solid rgba(0,0,0,0.5)', width: '100%' }}>
                  {<AlertTitle>Pending Application</AlertTitle>}
                  <Stack spacing={2}>
                    Your deposit payment was confirmed successfully. However Your Premium Credit
                    Application is pending review
                  </Stack>
                </Alert>
              ) : (
                <Alert
                  severity="success"
                  sx={{ border: '1px solid rgba(0,0,0,0.5)', width: '100%' }}
                >
                  {<AlertTitle>Payment Confirmed</AlertTitle>}
                  <Stack spacing={2}>Your payment details were confirmed successfully!</Stack>
                </Alert>
              )}

              {quote.policyNumber && !isScratchAndDent1Edge && (
                <span>
                  You now have a {productName} policy and your policy number is <b>{quote.policyNumber}</b>
                </span>
              )}

              {quote.policyNumber &&
                quote.paymentDetails?.paymentFrequency !== 'monthly' &&
                isScratchAndDent1Edge && (
                  <span>
                    You now have {productName} and we have debited your premium. Your policy number
                    is <b>{quote.policyNumber}</b>
                  </span>
                )}

              {quote.policyNumber &&
                quote.paymentDetails?.paymentFrequency === 'monthly' &&
                isScratchAndDent1Edge && (
                  <span>
                    You now have {productName} and we have debited your deposit premium and arranged
                    finance for you to pay the remaining premium monthly. Your policy number is{' '}
                    <b>{quote.policyNumber}</b>
                  </span>
                )}

              {showEmailMessage && quote.proposerEmail && isScratchAndDent1Edge && (
                <p>
                  A welcome email will be sent to <b>{quote.proposerEmail}</b>
                </p>
              )}

              {quote.inceptionDate && (
                <span>
                  Your policy starts on{' '}
                  {moment(quote.inceptionDate, 'DD-MM-YYYY').format('DD MMMM YYYY')}
                </span>
              )}

              {quote.paymentDetails?.paymentFrequency !== 'monthly' &&
                quote.paymentDetails?.amount && (
                  <span>
                    Your {yearlyText} payment will be £
                    {Number(quote.paymentDetails.amount).toLocaleString('en-GB', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                )}

              {quote.paymentDetails?.paymentFrequency === 'monthly' &&
                quote.monthlyPremium &&
                quote?.paymentDetails?.pcStatus !== '202' && (
                  <span>
                    {!isScratchAndDent1Edge ? (
                      <>
                        Your monthly payment will be £
                        {Number(quote.monthlyPremium).toLocaleString('en-GB', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </>
                    ) : (
                      <>
                        Your first monthly payment will be £
                        {quote.paymentDetails.FirstCollectionAmount}
                      </>
                    )}
                  </span>
                )}

              {quote.paymentDetails?.paymentFrequency === 'monthly' &&
                quote.paymentDetails?.NumberOfCollections &&
                quote?.paymentDetails?.pcStatus !== '202' && (
                  <span>
                    {!isScratchAndDent1Edge ? (
                      <>The number of payments will be {quote.paymentDetails.NumberOfCollections}</>
                    ) : (
                      <>
                        Followed by {quote.paymentDetails.NumberOfCollections - 1} monthly payments
                        of £
                        {Number(quote.monthlyPremium).toLocaleString('en-GB', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </>
                    )}
                  </span>
                )}

              {journey?.product.includesIPT && (
                <p>All premiums are inclusive of Insurance Premium Tax(IPT) which is 12%</p>
              )}

              {quote.paymentDetails?.paymentFrequency === 'monthly' &&
                quote.paymentDetails?.FirstPaymentDate &&
                quote?.paymentDetails?.pcStatus !== '202' &&
                !isScratchAndDent1Edge && (
                  <span>
                    Payment will be taken on{' '}
                    {moment(quote.paymentDetails.FirstPaymentDate, 'YYYY-MM-DD').format(
                      'DD MMMM YYYY'
                    )}
                  </span>
                )}

              {showEmailMessage && quote.proposerEmail && (
                <span>
                  A welcome email will be sent to <b>{quote.proposerEmail}</b>
                </span>
              )}
              {quote.documents &&
                quote.documents.length > 0 &&
                quote.documents.map((doc: { url: string; key: string }) => renderLink(doc))}
            </Stack>
          </Container>
        </Slide>
      </>
    );
  };

  const renderLink = (doc: { url: string; key: string }) => {
    if (doc.url && doc.key) {
      return (
        <Stack direction="row" alignItems="top" spacing={1} key={doc.key}>
          <OpenInNewIcon sx={{ color: '#009BF5' }} />
          <a
            href={doc.url}
            target="_blank"
            rel="noreferrer"
            style={{ color: '#009BF5', textDecoration: 'none', textTransform: 'capitalize' }}
          >
            {getTitleFromKey(doc.key)}
          </a>
        </Stack>
      );
    }
    return undefined;
  };

  // -----------------------------------------------------------------------------------------------
  // Main rendering
  // -----------------------------------------------------------------------------------------------

  if (quoteId === null) {
    return null;
  }

  if (mode === 'LOADING') {
    return <LoadingIndicator />;
  }

  if (mode === 'ERROR') {
    return <CenteredError title={error.title} content={error.content} />;
  }

  //const productName = quote?.productName ? `${quote.productName} Insurance` : "";
  const productName = journey?.product.title || product?.productName || '';
  const subTitle = journey?.product.subtitle ?? '';

  return (
    <div
      style={{ display: 'flex', minHeight: '100vh', flexDirection: 'column', overflow: 'hidden' }}
    >
      <div style={{ flex: 1 }}>
        {!isEmbedded && <Branding branding={branding} />}
        <div
          id="wljHeader"
          style={{
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            paddingBottom: 32,
          }}
        >
          <Container maxWidth="sm" sx={{ paddingTop: 2 }}>
            <>
              <Typography id="productTitle" variant="h1" sx={{ color: 'primary.contrastText' }}>
                {productName}
              </Typography>
              {subTitle && <small className="headerSubtitle">{subTitle}</small>}
            </>
          </Container>
        </div>
        {mode === 'SUCCESS' && renderSuccess()}
        {mode === 'REFERRED' && renderReferred()}
      </div>
      {!isEmbedded && journey && (
        <RegulatoryFooter config={journey} formData={{ validations: {}, values: { quote } }} />
      )}
    </div>
  );
};
