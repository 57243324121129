export const TransmissionType = [
  {
    "label": "Automatic",
    "value": "001"
  },
  {
    "label": "Manual",
    "value": "002"
  },
  {
    "label": "Semi Automatic",
    "value": "003"
  }
]