import { Button, hexToRgb, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import QuoteListingErrorSvg from './QuoteListingErrorSvg';
import { ArrowBack } from '@mui/icons-material';
import { QuoteListingProps } from './QuoteListingPage';

interface QuoteListingErrorProps extends Partial<QuoteListingProps> {
  isMTA?: boolean;
}
export default function QuoteListingError({ backHandler, isMTA }: QuoteListingErrorProps) {
  const theme = useTheme();
  const themeColor = theme.palette.primary.main;
  const fgColor1 = hexToRgb(themeColor)?.replace(')', `, ${0.6})`);
  const fgColor2 = hexToRgb(themeColor)?.replace(')', `, ${0.2})`);
  return (
    <Stack spacing={4} alignItems={'center'}>
      <Stack
        spacing={1}
        maxWidth={480}
        textAlign={'center'}
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        width={'100%'}
      >
        <QuoteListingErrorSvg themeColor={themeColor} fgColor1={fgColor1} fgColor2={fgColor2} />
        {isMTA ? (
          <>
            <Typography fontSize={18} fontWeight={600} fontFamily={'Poppins'} color={'primary'}>
              No quote available
            </Typography>
            <Typography marginBottom={4} fontSize={14} fontWeight={400} fontFamily={'Inter'} color={'#565E73'}>
              We are unable to obtain a quote from your insurer based on your change requests. Please contact the number on screen to discuss your options.
            </Typography>
          </>
        ) : (
          <>
            <Typography fontSize={18} fontWeight={600} fontFamily={'Poppins'} color={'primary'}>
              No quotes available
            </Typography>
            <Typography fontSize={14} fontWeight={600} fontFamily={'Inter'} color={'#565E73'}>
              We were unable to provide a quote for your car insurance.
            </Typography>
            <Typography marginBottom={4} fontSize={14} fontWeight={400} fontFamily={'Inter'} color={'#565E73'}>
              This may be because of incomplete or inaccurate information provided, vehicle eligibility criteria not
              being met, or previous claims history or convictions affecting your eligibility. Please review your
              answered cover questions and try again, or contact our support team for assistance.
            </Typography>
          </>
        )}
      </Stack>
      <Button
        onClick={backHandler}
        startIcon={<ArrowBack />}
        variant="outlined"
        size="small"
        sx={{
          width: 'fit-content',
          textTransform: 'none',
        }}
      >
        Go back to cover questions
      </Button>
    </Stack>
  );
}
