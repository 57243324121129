export const VehicleOwnerKeeperStatus = [
  {
    "label": "Brother or Sister",
    "value": "M"
  },
  {
    "label": "Civil Partner of Director/Partner",
    "value": "G"
  },
  {
    "label": "Company Director",
    "value": "J"
  },
  {
    "label": "Company Other Than Proposer",
    "value": "3"
  },
  {
    "label": "Contract Hire",
    "value": "L"
  },
  {
    "label": "Employee",
    "value": "B"
  },
  {
    "label": "Employer",
    "value": "A"
  },
  {
    "label": "Garage",
    "value": "C"
  },
  {
    "label": "Other",
    "value": "9"
  },
  {
    "label": "Other Family Member",
    "value": "H"
  },
  {
    "label": "Parent",
    "value": "6"
  },
  {
    "label": "Partner - Civil",
    "value": "E"
  },
  {
    "label": "Partner - Common Law",
    "value": "7"
  },
  {
    "label": "Proposer/Policyholder",
    "value": "1"
  },
  {
    "label": "Proposers Business Partner",
    "value": "D"
  },
  {
    "label": "Son or Daughter",
    "value": "8"
  },
  {
    "label": "Spouse",
    "value": "2"
  },
  {
    "label": "Spouse of Director/Partner",
    "value": "F"
  },
  {
    "label": "Third Party",
    "value": "5"
  },
  {
    "label": "Trust",
    "value": "K"
  },
  {
    "label": "Vehicle Leasing Company",
    "value": "4"
  }
]