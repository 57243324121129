export const EmployersBusinessCategory = [
  { label: "Abattoir", value: "188" },
  { label: "Accountancy", value: "1" },
  { label: "Acoustic Engineer", value: "189" },
  { label: "Actuarial Consultancy", value: "190" },
  { label: "Acupuncture", value: "191" },
  { label: "Addressing/Circularising Services", value: "881" },
  { label: "Adjuster", value: "192" },
  { label: "Advertising", value: "2" },
  { label: "Aerial Erector", value: "193" },
  { label: "Aerial Manufacturer", value: "194" },
  { label: "Aerial Photography", value: "195" },
  { label: "Aerial Supplier", value: "196" },
  { label: "Aerial Survey", value: "197" },
  { label: "Aerospace Industry", value: "798" },
  { label: "Agricultural Engineer", value: "198" },
  { label: "Agricultural Produce Distribution", value: "882" },
  { label: "Agriculture", value: "199" },
  { label: "Air Conditioning", value: "936" },
  { label: "Aircraft Construction", value: "200" },
  { label: "Aircraft Maintenance", value: "201" },
  { label: "Aircraft Repair", value: "202" },
  { label: "Airline", value: "203" },
  { label: "Airport Services", value: "906" },
  { label: "Ambulance Authority", value: "204" },
  { label: "Amusement", value: "5" },
  { label: "Amusement Arcade", value: "6" },
  { label: "Amusement Machine Supplier", value: "205" },
  { label: "Animal Breeding", value: "206" },
  { label: "Animal Feeds", value: "799" },
  { label: "Animal Rescue Home", value: "838" },
  { label: "Animal Training", value: "907" },
  { label: "Anthropology", value: "898" },
  { label: "Antique Restoration", value: "207" },
  { label: "Antiques", value: "8" },
  { label: "Arable Farming", value: "901" },
  { label: "Arbitration", value: "208" },
  { label: "Arboriculture", value: "977" },
  { label: "Architecture", value: "9" },
  { label: "Armed Forces - Foreign", value: "163" },
  { label: "Armed Forces - Republic Of Ireland", value: "162" },
  { label: "Armed Forces - USA", value: "209" },
  { label: "Art", value: "210" },
  { label: "Art Gallery", value: "211" },
  { label: "Art Restoration", value: "212" },
  { label: "Art Valuation", value: "213" },
  { label: "Asphalt Contractor", value: "214" },
  { label: "Assessor", value: "215" },
  { label: "Astrology", value: "216" },
  { label: "Astronomy", value: "217" },
  { label: "Auction House", value: "218" },
  { label: "Auctioneer", value: "14" },
  { label: "Auditors", value: "808" },
  { label: "Auxiliary Plant", value: "952" },
  { label: "Baby Food Manufacturer", value: "219" },
  { label: "Baby Goods Manufacturer", value: "220" },
  { label: "Baby Goods Shop", value: "221" },
  { label: "Bagpipe Maker", value: "222" },
  { label: "Bakers Supplies", value: "223" },
  { label: "Bakery", value: "224" },
  { label: "Banking", value: "15" },
  { label: "Barrel Makers", value: "225" },
  { label: "Bathroom Design", value: "226" },
  { label: "Bathroom Installation", value: "227" },
  { label: "Beauticians", value: "171" },
  { label: "Beauty Salon", value: "809" },
  { label: "Bed And Breakfast - Licensed", value: "944" },
  { label: "Bed And Breakfast - Unlicensed", value: "945" },
  { label: "Betting Shop", value: "228" },
  { label: "Bicycle Manufacturer", value: "955" },
  { label: "Bingo Hall", value: "839" },
  { label: "Blacksmith", value: "229" },
  { label: "Blast Cleaning", value: "230" },
  { label: "Blind Installation", value: "231" },
  { label: "Blind Manufacturer", value: "232" },
  { label: "Boarding Kennel", value: "233" },
  { label: "Boat Builder", value: "234" },
  { label: "Boat Hirer", value: "235" },
  { label: "Bookmaker - Off Course", value: "236" },
  { label: "Bookmaker - On Course", value: "237" },
  { label: "Booksellers", value: "238" },
  { label: "Bottled Gas Supplier", value: "239" },
  { label: "Brass Foundry", value: "240" },
  { label: "Breakdown Recovery", value: "241" },
  { label: "Breeding", value: "242" },
  { label: "Brewery", value: "18" },
  { label: "Brewery Transport", value: "243" },
  { label: "Brick Manufacturer", value: "244" },
  { label: "Brick Supplier", value: "245" },
  { label: "British Army", value: "933" },
  { label: "Broadcasting", value: "19" },
  { label: "Builder", value: "246" },
  { label: "Builders Merchant", value: "247" },
  { label: "Building Society", value: "20" },
  { label: "Building Trade", value: "21" },
  { label: "Business Consultancy", value: "248" },
  { label: "Business Systems", value: "822" },
  { label: "Business Training", value: "249" },
  { label: "Butchers", value: "250" },
  { label: "Cabinet Maker", value: "251" },
  { label: "Cable Manufacturer", value: "252" },
  { label: "Cable TV Installation", value: "840" },
  { label: "Cafe", value: "253" },
  { label: "Camp Site", value: "254" },
  { label: "Candle Dealer", value: "255" },
  { label: "Car Accessory Dealer", value: "256" },
  { label: "Car Delivery", value: "257" },
  { label: "Car Hire", value: "258" },
  { label: "Car Park Operator", value: "259" },
  { label: "Car Sales", value: "260" },
  { label: "Car Valeting", value: "261" },
  { label: "Caravan Hirer", value: "262" },
  { label: "Caravan Park - Residential", value: "970" },
  { label: "Caravan Sales", value: "263" },
  { label: "Caravan Service", value: "264" },
  { label: "Caravan Site", value: "265" },
  { label: "Carpentry", value: "266" },
  { label: "Carpet Fitting", value: "267" },
  { label: "Cartography", value: "268" },
  { label: "Cash & Carry", value: "269" },
  { label: "Casino", value: "23" },
  { label: "Cask Maker", value: "270" },
  { label: "Catalogue Co-ordination", value: "884" },
  { label: "Catalogue Distribution", value: "883" },
  { label: "Catering - Licensed", value: "24" },
  { label: "Catering - Unlicensed", value: "25" },
  { label: "Cattery", value: "271" },
  { label: "Ceiling Contractor", value: "913" },
  { label: "Cement Suppliers", value: "272" },
  { label: "Central Heating Services", value: "273" },
  { label: "Charity", value: "26" },
  { label: "Chartering", value: "274" },
  { label: "Chemical Industry", value: "810" },
  { label: "Chemical Manufacturer", value: "817" },
  { label: "Chemist Shop", value: "275" },
  { label: "Child Minding", value: "854" },
  { label: "Childrens Home", value: "958" },
  { label: "Childrens Panel", value: "276" },
  { label: "Chimney Sweeping", value: "277" },
  { label: "Chiropody", value: "278" },
  { label: "Choreography", value: "279" },
  { label: "Church", value: "960" },
  { label: "Church Army", value: "959" },
  { label: "Cinema", value: "27" },
  { label: "Circus", value: "28" },
  { label: "Citizens Advice Bureau", value: "893" },
  { label: "Civil Aviation", value: "280" },
  { label: "Civil Engineering", value: "281" },
  { label: "Civil Service", value: "29" },
  { label: "Cleaning Services", value: "282" },
  { label: "Clerical Services", value: "885" },
  { label: "Clock & Watch Manufacturer", value: "283" },
  { label: "Clock & Watch Repair", value: "284" },
  { label: "Clothing Manufacturer", value: "858" },
  { label: "Clothing Trade", value: "31" },
  { label: "Club", value: "32" },
  { label: "Coach Hirer", value: "285" },
  { label: "Coachbuilder", value: "286" },
  { label: "Coal Industry", value: "33" },
  { label: "Coal Merchant", value: "287" },
  { label: "Coffee Shop", value: "288" },
  { label: "Coin & Medal Dealer", value: "289" },
  { label: "Cold Store", value: "290" },
  { label: "Commissioners For Oaths", value: "291" },
  { label: "Commodities", value: "34" },
  { label: "Commodity Brokerage", value: "292" },
  { label: "Communications", value: "293" },
  { label: "Community Service", value: "294" },
  { label: "Computer Aided Design", value: "818" },
  { label: "Computer Distribution", value: "824" },
  { label: "Computer Sales", value: "812" },
  { label: "Computer Services", value: "295" },
  { label: "Computer Supplies", value: "886" },
  { label: "Computers", value: "181" },
  { label: "Computers - Hardware", value: "35" },
  { label: "Computers - Software", value: "36" },
  { label: "Concrete Supplier", value: "296" },
  { label: "Confectionery Manufacturer", value: "297" },
  { label: "Construction Industry", value: "160" },
  { label: "Consulting Engineering", value: "298" },
  { label: "Contact Lens Manufacturer", value: "299" },
  { label: "Container Hire", value: "300" },
  { label: "Conveyancers", value: "301" },
  { label: "Corporate Hospitality", value: "825" },
  { label: "Cosmetics", value: "811" },
  { label: "Costumiers", value: "302" },
  { label: "Cotton Mill", value: "303" },
  { label: "County Court", value: "953" },
  { label: "Courier Services", value: "304" },
  { label: "Crane Hire", value: "305" },
  { label: "Crane Manufacturer", value: "306" },
  { label: "Crop Spraying", value: "149" },
  { label: "Customs and Excise", value: "38" },
  { label: "Cutlery Craftsmen", value: "307" },
  { label: "Cycle Hire", value: "308" },
  { label: "Cycle Shop", value: "309" },
  { label: "Dairy", value: "39" },
  { label: "Dairy Farming", value: "900" },
  { label: "Dance Band", value: "954" },
  { label: "Data Processing", value: "310" },
  { label: "Data Protection", value: "311" },
  { label: "Dating Agency", value: "911" },
  { label: "Day Centre", value: "962" },
  { label: "Debt Collection", value: "40" },
  { label: "Decorating", value: "312" },
  { label: "Delicatessen", value: "866" },
  { label: "Delivery Service", value: "313" },
  { label: "Demolition", value: "41" },
  { label: "Dentistry", value: "42" },
  { label: "Department Store", value: "314" },
  { label: "Design Consultancy", value: "833" },
  { label: "Desktop Publishing Services", value: "887" },
  { label: "Despatch Services", value: "315" },
  { label: "Discotheque", value: "44" },
  { label: "Distillers", value: "316" },
  { label: "Distribution", value: "813" },
  { label: "DIY Store", value: "317" },
  { label: "Dock Authority", value: "318" },
  { label: "Domestic Appliance Maintenance", value: "157" },
  { label: "Domestic Service", value: "319" },
  { label: "Double Glazing", value: "172" },
  { label: "Drainage", value: "937" },
  { label: "Drapery", value: "45" },
  { label: "Driving Authority", value: "320" },
  { label: "Driving Instruction", value: "46" },
  { label: "Driving School", value: "321" },
  { label: "Dry Cleaning", value: "800" },
  { label: "Dry Lining", value: "938" },
  { label: "Earth Removers", value: "322" },
  { label: "Ecology", value: "897" },
  { label: "Education", value: "323" },
  { label: "Education - Private", value: "47" },
  { label: "Education - State", value: "138" },
  { label: "Effluent Disposal", value: "324" },
  { label: "Egg Merchants", value: "325" },
  { label: "Electrical Appliance Manufacturer", value: "823" },
  { label: "Electrical Contractors", value: "326" },
  { label: "Electrical Goods Consultancy", value: "834" },
  { label: "Electricity Industry", value: "48" },
  { label: "Electronics", value: "49" },
  { label: "Embossers", value: "327" },
  { label: "Emergency Services", value: "50" },
  { label: "Employment Agency", value: "328" },
  { label: "Energy Conservation", value: "939" },
  { label: "Energy Consultancy", value: "329" },
  { label: "Energy Supplier", value: "956" },
  { label: "Engineering", value: "139" },
  { label: "Engineering Consultants", value: "330" },
  { label: "Engraving", value: "182" },
  { label: "Entertainment", value: "51" },
  { label: "Environmental Health", value: "869" },
  { label: "Estate Agency", value: "52" },
  { label: "Excavation", value: "331" },
  { label: "Exhibition Centre", value: "332" },
  { label: "Exhibition Organisers", value: "333" },
  { label: "Export", value: "334" },
  { label: "Export Agency", value: "335" },
  { label: "Exporter", value: "336" },
  { label: "Fabric Manufacturer", value: "337" },
  { label: "Fabrications", value: "867" },
  { label: "Fairground", value: "53" },
  { label: "Falconry", value: "876" },
  { label: "Fancy Goods", value: "168" },
  { label: "Fancy Goods Importer", value: "338" },
  { label: "Farming", value: "54" },
  { label: "Fascia Board Installer", value: "920" },
  { label: "Fashion", value: "55" },
  { label: "Fashion Design", value: "339" },
  { label: "Fashion House", value: "340" },
  { label: "Fast Food", value: "841" },
  { label: "Fencing Manufacturer", value: "341" },
  { label: "Ferry Service", value: "342" },
  { label: "Fertilizer Manufacturer", value: "343" },
  { label: "Fibre Glass Manufacturer", value: "344" },
  { label: "Filling Station", value: "345" },
  { label: "Film Manufacturing", value: "346" },
  { label: "Film Processing", value: "347" },
  { label: "Film Production", value: "348" },
  { label: "Finance Company", value: "349" },
  { label: "Financial Advisors", value: "350" },
  { label: "Financial Services", value: "56" },
  { label: "Fire Protection", value: "351" },
  { label: "Fire Service", value: "963" },
  { label: "Fireplace Installer", value: "352" },
  { label: "Fireplace Manufacturer", value: "353" },
  { label: "Fireworks/Pyrotechnics", value: "982" },
  { label: "Fish & Chip Shop", value: "354" },
  { label: "Fish Farm", value: "355" },
  { label: "Fish Merchants", value: "150" },
  { label: "Fisheries", value: "943" },
  { label: "Fishing", value: "842" },
  { label: "Fishing Rights", value: "926" },
  { label: "Fishmonger", value: "356" },
  { label: "Fitted Bedroom Installer", value: "914" },
  { label: "Fitted Kitchen Installer", value: "915" },
  { label: "Flooring Construction", value: "916" },
  { label: "Flooring Installer", value: "918" },
  { label: "Flooring Services", value: "917" },
  { label: "Florists", value: "357" },
  { label: "Flying School", value: "358" },
  { label: "Food Exporter", value: "359" },
  { label: "Food Importer", value: "360" },
  { label: "Food Manufacturer", value: "361" },
  { label: "Food Production", value: "814" },
  { label: "Food Store", value: "362" },
  { label: "Football Pools", value: "363" },
  { label: "Forestry", value: "364" },
  { label: "Fostering/Adoption Agency", value: "843" },
  { label: "Foundry", value: "365" },
  { label: "Freezer Centre", value: "366" },
  { label: "Freight", value: "183" },
  { label: "Freight Agents", value: "367" },
  { label: "Freight Forwarders", value: "815" },
  { label: "French Polishing", value: "870" },
  { label: "Friendly Society", value: "368" },
  { label: "Frozen Food Distribution", value: "871" },
  { label: "Fuel Distribution", value: "369" },
  { label: "Fuel Merchant", value: "370" },
  { label: "Funeral Director", value: "173" },
  { label: "Funfair", value: "964" },
  { label: "Fur Trade", value: "371" },
  { label: "Furniture Installer", value: "925" },
  { label: "Furniture Manufacturer", value: "372" },
  { label: "Furniture Remover", value: "373" },
  { label: "Furniture Repair", value: "374" },
  { label: "Furniture Sales", value: "375" },
  { label: "Furniture Store", value: "376" },
  { label: "Furniture Storer", value: "377" },
  { label: "Furriers", value: "378" },
  { label: "Gambling", value: "57" },
  { label: "Game Breeders", value: "379" },
  { label: "Garage", value: "58" },
  { label: "Garden Centre", value: "826" },
  { label: "Gardening", value: "380" },
  { label: "Gas - Offshore", value: "844" },
  { label: "Gas Exploration", value: "381" },
  { label: "Gas Industry", value: "59" },
  { label: "Genealogy", value: "855" },
  { label: "General Dealer", value: "61" },
  { label: "General Store", value: "382" },
  { label: "Gift Shop", value: "383" },
  { label: "Glass Manufacturer", value: "384" },
  { label: "Glaziers", value: "385" },
  { label: "Golf Club", value: "386" },
  { label: "Government - Foreign", value: "151" },
  { label: "Government - Republic Of Ireland", value: "152" },
  { label: "Government - UK", value: "387" },
  { label: "Gown Trade", value: "388" },
  { label: "Grain Merchants", value: "389" },
  { label: "Grain Mill", value: "390" },
  { label: "Graphic Design", value: "895" },
  { label: "Graphology", value: "879" },
  { label: "Greengrocer", value: "391" },
  { label: "Greeting Card Manufacturer", value: "392" },
  { label: "Greeting Card Sales", value: "393" },
  { label: "Greyhound Racing", value: "394" },
  { label: "Greyhounds", value: "65" },
  { label: "Grit Blasters", value: "395" },
  { label: "Grocer", value: "396" },
  { label: "Ground Maintenance", value: "397" },
  { label: "Guest House - Licensed", value: "829" },
  { label: "Guest House - Unlicensed", value: "830" },
  { label: "Guttering Installer", value: "919" },
  { label: "Gymnasium", value: "965" },
  { label: "Hairdressing", value: "66" },
  { label: "Harbour Authority", value: "399" },
  { label: "Harbour Board", value: "67" },
  { label: "Hardware Manufacturer", value: "400" },
  { label: "Hardware Retailer", value: "401" },
  { label: "Haulage Contractors", value: "174" },
  { label: "Hazardous Chemical Transport", value: "979" },
  { label: "Health Authority", value: "402" },
  { label: "Health Care - NHS", value: "68" },
  { label: "Health Care - Private", value: "69" },
  { label: "Health Centre", value: "403" },
  { label: "Health Club", value: "404" },
  { label: "Health Products Distribution", value: "889" },
  { label: "Heating Consultant", value: "405" },
  { label: "Heating Services", value: "406" },
  { label: "Hire Purchase", value: "407" },
  { label: "HM Forces", value: "408" },
  { label: "Hobby Shop", value: "409" },
  { label: "Holiday Accommodation", value: "410" },
  { label: "Holiday Camp", value: "70" },
  { label: "Holiday Centre", value: "411" },
  { label: "Home Help Services", value: "412" },
  { label: "Honey Producer", value: "888" },
  { label: "Horses", value: "71" },
  { label: "Horticulture", value: "184" },
  { label: "Hospital", value: "413" },
  { label: "Hotel - Licensed", value: "72" },
  { label: "Hotel - Unlicensed", value: "73" },
  { label: "House Builders", value: "414" },
  { label: "Housing Association", value: "415" },
  { label: "Housing Developers", value: "416" },
  { label: "Hypermarket", value: "417" },
  { label: "Ice Cream", value: "74" },
  { label: "Ice Cream Manufacturer", value: "418" },
  { label: "Ice Cream Shop", value: "419" },
  { label: "Ice Merchant", value: "420" },
  { label: "Ice Rink", value: "421" },
  { label: "Import", value: "422" },
  { label: "Importers", value: "423" },
  { label: "Industrial Building Manufacturer", value: "424" },
  { label: "Industrial Relations", value: "425" },
  { label: "Information Technology", value: "904" },
  { label: "Inland Revenue", value: "76" },
  { label: "Inn", value: "426" },
  { label: "Inspection Services", value: "427" },
  { label: "Instant Print Services", value: "428" },
  { label: "Insulation Engineers", value: "429" },
  { label: "Insurance", value: "77" },
  { label: "Insurance Advisor", value: "430" },
  { label: "Insurance Broker", value: "431" },
  { label: "Insurance Company", value: "432" },
  { label: "Insurance Consultant", value: "433" },
  { label: "Interior Design", value: "801" },
  { label: "Investment", value: "78" },
  { label: "Iron Foundry", value: "434" },
  { label: "Ironing Services", value: "890" },
  { label: "Ironmonger", value: "435" },
  { label: "Jam & Preserve Manufacturer", value: "436" },
  { label: "Jewellers", value: "437" },
  { label: "Jewellery", value: "185" },
  { label: "Jewellery Manufacturer", value: "438" },
  { label: "Joinery", value: "439" },
  { label: "Keep Fit", value: "440" },
  { label: "Kennels", value: "441" },
  { label: "Kitchen Equipment Manufacturer", value: "442" },
  { label: "Kitchen Manufacturer", value: "835" },
  { label: "Kitchen Planners", value: "443" },
  { label: "Knitwear Manufacturer", value: "444" },
  { label: "Laboratory", value: "445" },
  { label: "Ladder Manufacturer", value: "446" },
  { label: "Land Clearance", value: "447" },
  { label: "Land Draining", value: "448" },
  { label: "Landscaping", value: "449" },
  { label: "Lathe Manufacturer", value: "450" },
  { label: "Launderette", value: "451" },
  { label: "Laundry", value: "153" },
  { label: "Law and Order", value: "79" },
  { label: "Lawnmowers & Garden Machinery", value: "452" },
  { label: "Lawyers", value: "453" },
  { label: "Leasing Company", value: "454" },
  { label: "Legal System", value: "455" },
  { label: "Leisure Centre", value: "175" },
  { label: "Leisure Goods", value: "930" },
  { label: "Lens Manufacturer", value: "456" },
  { label: "Library", value: "457" },
  { label: "Life Assurance", value: "966" },
  { label: "Lift Installers", value: "458" },
  { label: "Lift Maintenance", value: "459" },
  { label: "Lighting", value: "940" },
  { label: "Lighting Installers", value: "460" },
  { label: "Linen Hire", value: "461" },
  { label: "Livery Stables", value: "462" },
  { label: "Livestock Carriers", value: "463" },
  { label: "Livestock Farming", value: "902" },
  { label: "Local Government", value: "63" },
  { label: "Local Government Authority", value: "464" },
  { label: "Local Newspaper", value: "465" },
  { label: "Locksmiths", value: "466" },
  { label: "Loft Insulation", value: "467" },
  { label: "Log And Firewood", value: "82" },
  { label: "Loss Adjusting", value: "468" },
  { label: "LPG Suppliers", value: "469" },
  { label: "Machine Tool Supplier", value: "470" },
  { label: "Machinery Dealers", value: "471" },
  { label: "Mail Order", value: "83" },
  { label: "Mail Order Supplier", value: "472" },
  { label: "Maintenance Services", value: "473" },
  { label: "Management Consultancy", value: "474" },
  { label: "Management Training", value: "475" },
  { label: "Mantle Trade", value: "476" },
  { label: "Manufacturing", value: "84" },
  { label: "Manufacturing Chemist", value: "477" },
  { label: "Marina", value: "802" },
  { label: "Market Garden", value: "478" },
  { label: "Market Gardeners", value: "85" },
  { label: "Market Research", value: "868" },
  { label: "Market Trading", value: "479" },
  { label: "Marketing", value: "480" },
  { label: "Marriage Guidance", value: "481" },
  { label: "Material Manufacturer", value: "482" },
  { label: "Mattress Manufacturer", value: "483" },
  { label: "Meat Market", value: "484" },
  { label: "Meat Products", value: "485" },
  { label: "Mechanical Handling", value: "486" },
  { label: "Medical research", value: "803" },
  { label: "Medical Suppliers", value: "487" },
  { label: "Meditation", value: "972" },
  { label: "Merchant Navy", value: "86" },
  { label: "Metal Founders", value: "488" },
  { label: "Metal Treatment", value: "489" },
  { label: "Microfilm Services", value: "490" },
  { label: "Military Police", value: "967" },
  { label: "Milk Delivery", value: "491" },
  { label: "Mining", value: "87" },
  { label: "Mobile Food", value: "88" },
  { label: "Mobile Shop", value: "492" },
  { label: "Model Manufacturer", value: "875" },
  { label: "Moneylenders", value: "89" },
  { label: "Monumental Masons", value: "493" },
  { label: "Motor Factor/Parts", value: "845" },
  { label: "Motor Manufacturer", value: "142" },
  { label: "Motor Organisation", value: "143" },
  { label: "Motor Trade", value: "90" },
  { label: "Motorcar Racing", value: "91" },
  { label: "Motorcycle Racing", value: "92" },
  { label: "Motorcycle Trade", value: "903" },
  { label: "Motoring Organisation", value: "494" },
  { label: "Museums", value: "836" },
  { label: "Music Publisher", value: "495" },
  { label: "Music Retailer", value: "496" },
  { label: "Music School", value: "497" },
  { label: "Musical Instrument Manufacturer", value: "498" },
  { label: "National Newspaper", value: "499" },
  { label: "National Trust", value: "856" },
  { label: "National Trust For Scotland", value: "857" },
  { label: "Newsagents", value: "500" },
  { label: "Newspaper Wholesaler", value: "909" },
  { label: "Night Club", value: "93" },
  { label: "Noise Control", value: "819" },
  // { label: "None - Household Duties", value: "948" },
  // { label: "None - Not Employed due to Disability", value: "949" },
  // { label: "None - Retired", value: "947" },
  // { label: "None - Student", value: "950" },
  // { label: "None - Unemployed", value: "747" },
  // { label: "Not In Employment", value: "186" },
  { label: "Notaries", value: "501" },
  { label: "Nuclear Energy", value: "141" },
  { label: "Nursery", value: "95" },
  { label: "Nursery School", value: "502" },
  { label: "Nursing Home", value: "503" },
  { label: "Oceanographic Survey", value: "846" },
  { label: "Off Licence", value: "96" },
  { label: "Off Licence Store", value: "504" },
  { label: "Office Equipment Repairer", value: "921" },
  { label: "Office Equipment Supplier", value: "505" },
  { label: "Office Fitters", value: "506" },
  { label: "Office Services", value: "507" },
  { label: "Oil - Offshore", value: "847" },
  { label: "Oil Company", value: "508" },
  { label: "Oil Distribution", value: "980" },
  { label: "Oil Exploration", value: "509" },
  { label: "Oil Terminal Operator", value: "865" },
  { label: "Opinion Polls", value: "510" },
  { label: "Optical Services", value: "97" },
  { label: "Optician", value: "511" },
  { label: "Optometrist", value: "512" },
  { label: "Orchestra", value: "961" },
  { label: "Organ Building", value: "513" },
  { label: "Outdoor Pursuits", value: "905" },
  { label: "Overall Hire & Maintenance", value: "514" },
  { label: "Packers And Storers", value: "515" },
  { label: "Paint Manufacturer", value: "516" },
  { label: "Paint Spraying", value: "517" },
  { label: "Painting", value: "518" },
  { label: "Painting and Decorating", value: "98" },
  { label: "Painting Contractor", value: "859" },
  { label: "Panel Beating", value: "519" },
  { label: "Paper Manufacturer", value: "804" },
  { label: "Paperbag And Sack Manufacturer", value: "520" },
  { label: "Parcel Delivery", value: "521" },
  { label: "Partition Erector", value: "923" },
  { label: "Passenger Transport", value: "522" },
  { label: "Patent Office", value: "848" },
  { label: "Pawnbroker", value: "523" },
  { label: "Performing Arts", value: "12" },
  { label: "Personnel Consultancy", value: "524" },
  { label: "Pest And Vermin Control", value: "525" },
  { label: "Pet Feed", value: "927" },
  { label: "Pet Services", value: "908" },
  { label: "Pet Shop", value: "526" },
  { label: "Petrochemical Industry", value: "100" },
  { label: "Petrol Pump Maintenance", value: "527" },
  { label: "Petrol Station", value: "816" },
  { label: "Pharmaceutical Supplier", value: "528" },
  { label: "Pharmacy", value: "529" },
  { label: "Photo Engraving", value: "530" },
  { label: "Photo Processing And Printing", value: "531" },
  { label: "Photographic Equipment Repairs", value: "532" },
  { label: "Photography", value: "101" },
  { label: "Physiotherapy", value: "533" },
  { label: "Piano Sales And Repairs", value: "534" },
  { label: "Piano Tuning", value: "535" },
  { label: "Picture Framing", value: "872" },
  { label: "Pig Farming", value: "536" },
  { label: "Pipe Cleaning", value: "537" },
  { label: "Pizza Delivery", value: "538" },
  { label: "Planning Consultancy", value: "539" },
  { label: "Plant Hire", value: "102" },
  { label: "Plant Manufacturer", value: "540" },
  { label: "Plant Sales", value: "541" },
  { label: "Plastic Sheeting Manufacturer", value: "542" },
  { label: "Plastics Manufacturer", value: "805" },
  { label: "Playground Equipment Manufacturer", value: "543" },
  { label: "Plumbers Merchant", value: "544" },
  { label: "Plumbing", value: "103" },
  { label: "Plumbing & Heating", value: "896" },
  { label: "Political Consultancy", value: "545" },
  { label: "Political Party", value: "546" },
  { label: "Pollution Control", value: "547" },
  { label: "Polytechnic", value: "969" },
  { label: "Pony Trap", value: "931" },
  { label: "Pool Table Manufacturer", value: "548" },
  { label: "Pool Table Repairer", value: "549" },
  { label: "Pool Table Sales", value: "550" },
  { label: "Pop Group", value: "968" },
  { label: "Port Authority", value: "144" },
  { label: "Portable Building", value: "932" },
  { label: "Post Office", value: "104" },
  { label: "Potato Merchant", value: "552" },
  { label: "Pottery", value: "553" },
  { label: "Poultry Farm", value: "554" },
  { label: "Poultry Farming", value: "899" },
  { label: "Pre-Stressed Concrete Manufacturer", value: "555" },
  { label: "Presentation Materials Supplier", value: "556" },
  { label: "Press Cutting Agency", value: "557" },
  { label: "Pressure Cleaning", value: "558" },
  { label: "Print Type Services", value: "559" },
  { label: "Printer", value: "176" },
  { label: "Printing", value: "560" },
  { label: "Printing Engineering Services", value: "561" },
  { label: "Prison Service", value: "105" },
  { label: "Private Hire", value: "562" },
  { label: "Private Investigation", value: "563" },
  { label: "Private School", value: "564" },
  { label: "Process Engraving", value: "565" },
  { label: "Produce Importers", value: "566" },
  { label: "Project Management", value: "567" },
  { label: "Promotional Consultancy", value: "568" },
  { label: "Property Consultants", value: "820" },
  { label: "Property Developers", value: "106" },
  { label: "Property Letting", value: "821" },
  { label: "Property Owner", value: "569" },
  { label: "Property Services", value: "107" },
  { label: "Protective Clothing Manufacturer", value: "570" },
  { label: "Protective Clothing Supplier", value: "571" },
  { label: "Psychiatry", value: "572" },
  { label: "Psychology", value: "573" },
  { label: "Psychotherapy", value: "574" },
  { label: "Public Address System Supplier", value: "575" },
  { label: "Public Hire", value: "576" },
  { label: "Public Hirer", value: "577" },
  { label: "Public House", value: "578" },
  { label: "Public Relation Consultancy", value: "579" },
  { label: "Public School", value: "580" },
  { label: "Public Transport", value: "894" },
  { label: "Publishing", value: "108" },
  { label: "Publishing - Local Press", value: "109" },
  { label: "Publishing - National Press", value: "110" },
  { label: "Quality Assurance", value: "581" },
  { label: "Quantity Surveyors", value: "582" },
  { label: "Quarry", value: "583" },
  { label: "Quarrying", value: "111" },
  { label: "Race Course", value: "584" },
  { label: "Racehorses", value: "112" },
  { label: "Racing Or Rallies", value: "113" },
  { label: "Racing Stable", value: "585" },
  { label: "Radiator Repairs", value: "586" },
  { label: "Radiator Sales", value: "587" },
  { label: "Radio Production", value: "951" },
  { label: "Radiography", value: "588" },
  { label: "Rag Merchants", value: "589" },
  { label: "Railway", value: "114" },
  { label: "Rating And Valuation", value: "590" },
  { label: "Record Company", value: "591" },
  { label: "Record Shop", value: "592" },
  { label: "Recording Services", value: "593" },
  { label: "Recovery Services", value: "594" },
  { label: "Recruitment Agency", value: "595" },
  { label: "Recycling", value: "596" },
  { label: "Refrigeration", value: "597" },
  { label: "Refuse Collection", value: "598" },
  { label: "Refuse Treatment", value: "941" },
  { label: "Reinforced Concrete Manufacturer", value: "599" },
  { label: "Religion", value: "115" },
  { label: "Removal Contractors", value: "177" },
  { label: "Remover And Storer", value: "600" },
  { label: "Rescue Services", value: "601" },
  { label: "Residential Home", value: "602" },
  { label: "Restaurant - Licensed", value: "831" },
  { label: "Restaurant - Unlicensed", value: "832" },
  { label: "Retailer - Mobile", value: "154" },
  { label: "Retailing", value: "117" },
  { label: "Riding School", value: "603" },
  { label: "Ring Sports", value: "807" },
  { label: "River Authority", value: "604" },
  { label: "Road Haulage", value: "605" },
  { label: "Road Repair", value: "606" },
  { label: "Road Surfacing", value: "607" },
  { label: "Robotics Manufacturer", value: "849" },
  { label: "Roller Shutter Manufacturer", value: "608" },
  { label: "Roofing Services", value: "609" },
  { label: "Rope Merchant", value: "610" },
  { label: "Roughcasters", value: "611" },
  { label: "Royal Air Force", value: "935" },
  { label: "Royal Mint", value: "975" },
  { label: "Royal Navy", value: "934" },
  { label: "RSPCA", value: "850" },
  { label: "Rubbish Disposal", value: "612" },
  { label: "Rubble Removers", value: "613" },
  { label: "Rustproofing Services", value: "614" },
  { label: "Saddlers And Harness Makers", value: "615" },
  { label: "Safe Installation And Removal", value: "616" },
  { label: "Safe Manufacturer", value: "617" },
  { label: "Safety Consultancy", value: "618" },
  { label: "Safety Equipment Supplier", value: "619" },
  { label: "Sail Makers And Repairers", value: "620" },
  { label: "Sailing Equipment Supplier", value: "621" },
  { label: "Salvage", value: "622" },
  { label: "Sample Distribution", value: "891" },
  { label: "Sample Distributors", value: "623" },
  { label: "Sand And Gravel Merchants", value: "624" },
  { label: "Sand Blasters", value: "625" },
  { label: "Satellite TV And Equipment Suppliers", value: "626" },
  { label: "Satellite TV Installers", value: "627" },
  { label: "Saw Sales And Repairs", value: "628" },
  { label: "Sawmill", value: "629" },
  { label: "Scaffolding Erection", value: "630" },
  { label: "Scaffolding Hire", value: "631" },
  { label: "School", value: "632" },
  { label: "School For Performing Arts", value: "873" },
  { label: "Scientific Research", value: "851" },
  { label: "Scrap Disposal", value: "633" },
  { label: "Scrap Metal Merchants", value: "634" },
  { label: "Screen Printing", value: "635" },
  { label: "Screenwriting", value: "636" },
  { label: "Sculptors", value: "637" },
  { label: "Secondhand Dealers", value: "638" },
  { label: "Secretarial Services", value: "639" },
  { label: "Secretarial Training", value: "640" },
  { label: "Security Equipment", value: "860" },
  { label: "Security Services", value: "120" },
  { label: "Seedsmen", value: "641" },
  { label: "Self Catering Accommodation", value: "642" },
  { label: "Self Drive Hire", value: "643" },
  { label: "Sewage", value: "942" },
  { label: "Sharpening Services", value: "644" },
  { label: "Sheet Metal Work", value: "645" },
  { label: "Sheriff Officers", value: "646" },
  { label: "Ship Building And Repair", value: "647" },
  { label: "Ship Chandlery", value: "648" },
  { label: "Ship Painting", value: "649" },
  { label: "Shipbroking", value: "650" },
  { label: "Shipping And Forwarding Agency", value: "651" },
  { label: "Shipping Company", value: "652" },
  { label: "Shipyard", value: "122" },
  { label: "Shoe Manufacturers", value: "653" },
  { label: "Shoe Repair", value: "654" },
  { label: "Shoe Shop", value: "655" },
  { label: "Shooting Rights", value: "928" },
  { label: "Shop Fitting", value: "656" },
  { label: "Shopping Centre", value: "657" },
  { label: "Shot Blasters", value: "658" },
  { label: "Shutter Manufacturer", value: "659" },
  { label: "Sightseeing Tours Operator", value: "660" },
  { label: "Sign Making", value: "661" },
  { label: "Signwriting", value: "123" },
  { label: "Site Clearance", value: "662" },
  { label: "Site Investigation", value: "663" },
  { label: "Skating Rink", value: "664" },
  { label: "Ski Centre", value: "665" },
  { label: "Skip Hire", value: "666" },
  { label: "Skip Maintenance", value: "827" },
  { label: "Slaughterhouse", value: "667" },
  { label: "Slimming Distribution", value: "892" },
  { label: "Snack Bar", value: "668" },
  { label: "Snooker Club", value: "669" },
  { label: "Social Club", value: "670" },
  { label: "Social Services", value: "124" },
  { label: "Soft Drinks Manufacturer", value: "671" },
  { label: "Soft Drinks Supplier", value: "672" },
  { label: "Soil Engineers", value: "673" },
  { label: "Solar Panel Manufacturer", value: "674" },
  { label: "Solar Panel Supplier", value: "675" },
  { label: "Solicitors", value: "178" },
  { label: "Sound Proofing", value: "676" },
  { label: "Spinners", value: "677" },
  { label: "Sports - Amateur", value: "984" },
  { label: "Sports - Professional", value: "126" },
  { label: "Sports - Semi Professional", value: "983" },
  { label: "Sports Centre", value: "678" },
  { label: "Sports Club", value: "679" },
  { label: "Sports Club - Professional", value: "971" },
  { label: "Sports Goods Shop", value: "680" },
  { label: "Sports Ground", value: "681" },
  { label: "Sports Promotion", value: "682" },
  { label: "Sportswear Manufacturer", value: "683" },
  { label: "Sportswear Supplier", value: "684" },
  { label: "Stables", value: "685" },
  { label: "Stamp Dealers", value: "686" },
  { label: "Stamp Manufacturer", value: "687" },
  { label: "Stamp Supplier Stationers", value: "688" },
  { label: "State School", value: "957" },
  { label: "Stationers", value: "874" },
  { label: "Steel Industry", value: "127" },
  { label: "Steel Stockholders", value: "689" },
  { label: "Sterilizing Services", value: "690" },
  { label: "Stockbroking", value: "128" },
  { label: "Stonemasonry", value: "691" },
  { label: "Street Trading", value: "692" },
  { label: "Structural Engineering", value: "693" },
  { label: "Studio", value: "694" },
  { label: "Sunbed", value: "929" },
  { label: "Supermarket", value: "179" },
  { label: "Surveying", value: "695" },
  { label: "Surveyors", value: "180" },
  { label: "Suspended Ceiling Repairer", value: "922" },
  { label: "Swimming Pool", value: "696" },
  { label: "Tailor And Outfitter", value: "697" },
  { label: "Take Away Food Supplier", value: "698" },
  { label: "Tank Cleaning Services", value: "699" },
  { label: "Tarpaulin Manufacturer", value: "700" },
  { label: "Tarpaulin Suppliers", value: "701" },
  { label: "Tattoo Parlour/Clinic", value: "912" },
  { label: "Tax Consultancy", value: "702" },
  { label: "Taxi Service", value: "703" },
  { label: "Taxidermy", value: "704" },
  { label: "Tea Importer", value: "705" },
  { label: "Tea Merchant", value: "706" },
  { label: "Tea Room", value: "707" },
  { label: "Technical College", value: "708" },
  { label: "Telecommunication Equipment Suppliers", value: "709" },
  { label: "Telecommunications", value: "130" },
  { label: "Telemarketing", value: "710" },
  { label: "Telephone Answering Service", value: "711" },
  { label: "Telesales", value: "712" },
  { label: "Television", value: "166" },
  { label: "Television Hire", value: "806" },
  { label: "Television Production", value: "713" },
  { label: "Television Repairer", value: "714" },
  { label: "Television Sales", value: "715" },
  { label: "Tent And Marquee Hirer", value: "716" },
  { label: "Territorial Army", value: "837" },
  { label: "Textile Manufacturer", value: "717" },
  { label: "Thatching", value: "718" },
  { label: "Theatre", value: "167" },
  { label: "Theatrical Suppliers", value: "719" },
  { label: "Theme Park", value: "853" },
  { label: "Tilers", value: "720" },
  { label: "Timber Importers", value: "721" },
  { label: "Timber Merchants", value: "156" },
  { label: "Timber Preservation", value: "722" },
  { label: "Timeshare Operators", value: "723" },
  { label: "Tobacco Importer", value: "724" },
  { label: "Tobacco Manufacturer", value: "725" },
  { label: "Tobacconist", value: "726" },
  { label: "Tomato Grower", value: "727" },
  { label: "Tool Hire", value: "728" },
  { label: "Tour Operator", value: "729" },
  { label: "Tourist Board", value: "730" },
  { label: "Tourist Information", value: "731" },
  { label: "Towel Supplier", value: "732" },
  { label: "Toy And Game Manufacturer", value: "733" },
  { label: "Toy And Game Supplier", value: "734" },
  { label: "Trade Association", value: "159" },
  { label: "Trade Demonstration", value: "187" },
  { label: "Trade Union", value: "158" },
  { label: "Trading Estate", value: "735" },
  { label: "Trading Standards Enforcement", value: "736" },
  { label: "Trailer Manufacturer", value: "737" },
  { label: "Trailer Supplier", value: "738" },
  { label: "Training Company", value: "946" },
  { label: "Training Consultancy", value: "924" },
  { label: "Translators", value: "739" },
  { label: "Transport - PSV", value: "131" },
  { label: "Transport - Road", value: "132" },
  { label: "Travel And Tourism", value: "145" },
  { label: "Tree Surgery", value: "978" },
  { label: "Trinity House", value: "740" },
  { label: "Trust Company", value: "910" },
  { label: "Tupperware", value: "169" },
  { label: "Turf Accountants", value: "133" },
  { label: "TV And Radio", value: "741" },
  { label: "TV And Video Rental", value: "852" },
  { label: "Typewriter Services", value: "742" },
  { label: "Tyre Dealer", value: "863" },
  { label: "Tyre Manufacturer", value: "864" },
  { label: "Tyre Supplier And Fitting", value: "743" },
  { label: "Undertaker", value: "744" },
  { label: "Underwater Inspection", value: "745" },
  { label: "Underwriting Agency", value: "746" },
  { label: "Uniform Manufacturer", value: "748" },
  { label: "University", value: "749" },
  { label: "Upholstery", value: "750" },
  { label: "Vacuum Cleaner Repairs And Service", value: "751" },
  { label: "Valuation", value: "752" },
  { label: "Van And Lorry Hirer", value: "753" },
  { label: "Vehicle Hire - Self Drive", value: "155" },
  { label: "Vehicle Repairer", value: "754" },
  { label: "Vehicle Transport", value: "981" },
  { label: "Vending Machine Manufacturer", value: "755" },
  { label: "Vending Machine Supplier", value: "756" },
  { label: "Vending Services", value: "828" },
  { label: "Veneering", value: "757" },
  { label: "Venetian Blind Manufacturer", value: "758" },
  { label: "Venetian Blind Supplier", value: "759" },
  { label: "Ventilation", value: "760" },
  { label: "Vermin Control", value: "761" },
  { label: "Veterinary", value: "134" },
  { label: "Veterinary Supplies", value: "762" },
  { label: "Veterinary Surgeons", value: "763" },
  { label: "Video Hire", value: "135" },
  { label: "Video Services", value: "764" },
  { label: "Vineyard", value: "765" },
  { label: "Voluntary Organisation", value: "766" },
  { label: "Wallpaper Manufacturer/Supplier", value: "767" },
  { label: "Washing Machine Repairs And Servicing", value: "768" },
  { label: "Waste Disposal", value: "769" },
  { label: "Waste Paper", value: "976" },
  { label: "Watchmaker", value: "770" },
  { label: "Water Industry", value: "136" },
  { label: "Water Sports Centre", value: "771" },
  { label: "Waterproof Cover Manufacturer", value: "772" },
  { label: "Waterproof Cover Supplier", value: "773" },
  { label: "Weather Forecasting", value: "774" },
  { label: "Weather Services", value: "775" },
  { label: "Weights And Measures", value: "776" },
  { label: "Welding", value: "777" },
  { label: "Welfare Organisation", value: "778" },
  { label: "Whisky Blenders", value: "779" },
  { label: "Wholesaler", value: "137" },
  { label: "Window Cleaning", value: "780" },
  { label: "Wine And Spirit Merchants", value: "781" },
  { label: "Wine Bar", value: "782" },
  { label: "Wine Makers", value: "783" },
  { label: "Wire Rope Manufacturer", value: "784" },
  { label: "Wood Carving", value: "785" },
  { label: "Wood Preservation", value: "786" },
  { label: "Woodshavings Contractor", value: "880" },
  { label: "Woodworking", value: "787" },
  { label: "Woodworm And Dry Rot Control", value: "788" },
  { label: "Woollen Goods Manufacturer", value: "789" },
  { label: "Woollen Goods Shop", value: "790" },
  { label: "Woollen Mill", value: "791" },
  { label: "Wrought Iron Manufacturer", value: "792" },
  { label: "Yacht Building", value: "793" },
  { label: "Yacht Chandlery", value: "794" },
  { label: "Yacht Service And Management", value: "877" },
  { label: "Yarn Spinning", value: "795" },
  { label: "Youth Hostel Organisation", value: "796" },
  { label: "Youth Organisation", value: "878" },
  { label: "Zoo Operator", value: "797" },
];
