import React from 'react';

export default function QuoteListingErrorSvg({
  themeColor,
  fgColor1,
  fgColor2,
}: {
  fgColor1: string;
  fgColor2: string;
  themeColor: string;
}) {
  return (
    <svg width="240" height="240" viewBox="0 0 240 240" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Invoice_Error">
        <path
          id="Vector"
          d="M192.406 82.3502C173.221 82.3502 157.651 66.7952 157.651 47.5952C157.651 41.1602 159.406 35.1452 162.451 29.9852C149.566 23.8952 135.181 20.4902 120.001 20.4902C65.0406 20.4752 20.4756 65.0402 20.4756 120C20.4756 174.96 65.0406 219.525 120.001 219.525C174.961 219.525 219.526 174.96 219.526 120C219.526 104.805 216.106 90.4202 210.031 77.5502C204.871 80.5952 198.841 82.3502 192.421 82.3502H192.406Z"
          fill="#FAFAFF"
        />
        <g id="Group">
          <path
            id="Vector_2"
            d="M192.405 75.7658C207.963 75.7658 220.575 63.1536 220.575 47.5958C220.575 32.0379 207.963 19.4258 192.405 19.4258C176.847 19.4258 164.235 32.0379 164.235 47.5958C164.235 63.1536 176.847 75.7658 192.405 75.7658Z"
            fill={themeColor}
          />
          <g id="Group_2">
            <path
              id="Vector_3"
              d="M192.405 52.2157C191.025 52.2157 189.9 51.0907 189.9 49.7107V33.4357C189.9 32.0557 191.025 30.9307 192.405 30.9307C193.785 30.9307 194.91 32.0557 194.91 33.4357V49.7107C194.91 51.0907 193.785 52.2157 192.405 52.2157Z"
              fill="white"
            />
            <path
              id="Vector_4"
              d="M192.406 63.781C194.071 63.781 195.421 62.4311 195.421 60.766C195.421 59.1008 194.071 57.751 192.406 57.751C190.74 57.751 189.391 59.1008 189.391 60.766C189.391 62.4311 190.74 63.781 192.406 63.781Z"
              fill="white"
            />
          </g>
        </g>
        <g id="Group_3">
          <path
            id="Vector_5"
            d="M168.195 72.4648V174.21C168.195 178.2 164.955 181.425 160.98 181.425H84.3603C84.1503 181.425 83.9703 181.425 83.8053 181.395C78.7503 181.11 74.7453 176.925 74.7453 171.825V66.9148V66.8998V65.7748L74.6253 65.6698C74.6253 65.6698 74.5953 65.5348 74.5803 65.4748C74.5353 65.1898 74.4603 64.9198 74.3703 64.6498C74.2953 64.3798 74.2053 64.1248 74.1003 63.8848C73.9653 63.5698 73.8153 63.2698 73.6503 62.9848C73.5453 62.7898 73.4253 62.5948 73.3053 62.4148C73.3053 62.3998 73.3053 62.3848 73.2753 62.3698C73.1253 62.1448 72.9753 61.9348 72.7953 61.7398C72.6303 61.5298 72.4353 61.3198 72.2403 61.1248C70.7103 59.5948 68.5953 58.6348 66.1953 58.6048L159.495 58.5898C161.265 63.8998 164.28 68.6398 168.21 72.4648H168.195Z"
            fill={fgColor2}
          />
          <path
            id="Vector_6"
            d="M74.7611 67.2004V81.0304H74.7461V66.9004C74.7611 67.0054 74.7611 67.0954 74.7611 67.2004Z"
            fill="#75BBFF"
          />
          <path
            id="Vector_7"
            d="M66.1653 58.6045C70.9053 58.6045 74.7603 62.4595 74.7603 67.1995V83.9695H57.5703V67.1995C57.5703 62.4595 61.4253 58.6045 66.1653 58.6045Z"
            fill={fgColor1}
          />
          <path
            id="Vector_8"
            d="M182.431 162.884H91.936V172.814C91.936 177.419 88.321 181.184 83.791 181.379C83.956 181.409 84.151 181.409 84.346 181.409H175.966C179.536 181.409 182.446 178.514 182.446 174.929V162.869L182.431 162.884Z"
            fill={fgColor1}
          />
          <g id="Group_4">
            <path
              id="Vector_9"
              d="M103.756 79.3504H90.2109C89.4009 79.3504 88.7559 78.6904 88.7559 77.8954C88.7559 77.1004 89.4159 76.4404 90.2109 76.4404H103.756C104.566 76.4404 105.211 77.1004 105.211 77.8954C105.211 78.6904 104.551 79.3504 103.756 79.3504Z"
              fill={fgColor1}
            />
            <path
              id="Vector_10"
              d="M119.191 87.1795H90.2109C89.4009 87.1795 88.7559 86.5195 88.7559 85.7245C88.7559 84.9295 89.4159 84.2695 90.2109 84.2695H119.191C120.001 84.2695 120.646 84.9295 120.646 85.7245C120.646 86.5195 119.986 87.1795 119.191 87.1795Z"
              fill={fgColor1}
            />
          </g>
          <path
            id="Vector_11"
            d="M152.821 100.62H90.2109C89.4009 100.62 88.7559 99.96 88.7559 99.165C88.7559 98.37 89.4159 97.71 90.2109 97.71H152.821C153.631 97.71 154.276 98.37 154.276 99.165C154.276 99.96 153.616 100.62 152.821 100.62Z"
            fill="white"
          />
          <path
            id="Vector_12"
            d="M152.821 111.135H90.2109C89.4009 111.135 88.7559 110.475 88.7559 109.68C88.7559 108.885 89.4159 108.225 90.2109 108.225H152.821C153.631 108.225 154.276 108.885 154.276 109.68C154.276 110.475 153.616 111.135 152.821 111.135Z"
            fill="white"
          />
          <path
            id="Vector_13"
            d="M152.821 121.635H90.2109C89.4009 121.635 88.7559 120.975 88.7559 120.18C88.7559 119.385 89.4159 118.725 90.2109 118.725H152.821C153.631 118.725 154.276 119.385 154.276 120.18C154.276 120.975 153.616 121.635 152.821 121.635Z"
            fill="white"
          />
          <path
            id="Vector_14"
            d="M152.821 132.135H90.2109C89.4009 132.135 88.7559 131.475 88.7559 130.68C88.7559 129.885 89.4159 129.225 90.2109 129.225H152.821C153.631 129.225 154.276 129.885 154.276 130.68C154.276 131.475 153.616 132.135 152.821 132.135Z"
            fill="white"
          />
          <path
            id="Vector_15"
            d="M152.821 142.635H90.2109C89.4009 142.635 88.7559 141.975 88.7559 141.18C88.7559 140.385 89.4159 139.725 90.2109 139.725H152.821C153.631 139.725 154.276 140.385 154.276 141.18C154.276 141.975 153.616 142.635 152.821 142.635Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
}
