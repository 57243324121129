import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CookieIcon from '@mui/icons-material/Cookie';
import { useLayoutEffect, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { hexToRGBA, tagManagerArgs } from '../utils';
import { useTheme } from '@mui/material';
import TagManager from 'react-gtm-module';

const style = {
  position: 'fixed' as 'fixed',
  bottom: '10px',
  right: '10px',
  width: '480px',
  height: '320px',
  bgcolor: 'background.paper',
  border: '2px solid #AFB4F8',
  borderRadius: '10px',
  boxShadow: 24,
  p: '15px 24px',
  overflow: 'hidden',
  '&:focus-visible': {
    outline: 'none',
  },
  '@media (max-width: 500px)': {
    right: 'auto',
    left: '50%',
    bottom: '20px',
    transform: 'translateX(-50%)',
    width: '95%',
    minHeight: '380px',
    p: '20px 16px',
  },
};

export const CookieBanner = (uuid: any) => {
  const [cookies, setCookies] = useCookies(['cookieBanner']);
  const [open, setOpen] = useState(true);
  const theme = useTheme();
  const handleClose = () => setOpen(false);

  const brandColor = theme.palette.primary.main;

  console.log('tagmanner ---> ', TagManager);

  style.border = `2px solid ${hexToRGBA(brandColor, 0.5)}`;

  const handleCookieStorage = (acceptAll: boolean) => {
    if (acceptAll) {
      const expiryDate = new Date();
      expiryDate.setMonth(expiryDate.getMonth() + 3);
      setCookies('cookieBanner', 'true', {
        path: '/',
        expires: expiryDate,
      });
      TagManager.initialize(tagManagerArgs);
    } else {
      setCookies('cookieBanner', '', {
        path: '/',
        expires: new Date(0),
      });
      (window as any)['ga-disable-' + tagManagerArgs.gtmId] = true;
    }
    handleClose();
  };

  useLayoutEffect(() => {
    if (cookies.cookieBanner) {
      TagManager.initialize(tagManagerArgs);
    } else {
      (window as any)['ga-disable-' + tagManagerArgs.gtmId] = true;
    }
  }, [cookies.cookieBanner]);

  if (uuid?.uuid === 'com-ae1de7d5-fbec-401e-9fde-1995c8092b3c') {
    return     <div>
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock
      hideBackdrop
      disableAutoFocus
      disableEnforceFocus
      disablePortal
      style={{ position: 'absolute', zIndex: 9999, height: 0, width: 0 }}
      BackdropProps={{
        style: {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Box sx={{
        ...style,  
        '@media (max-width: 500px)': {
          height: '230px',
          width: '95%'
        },
        '@media (min-width: 501px)': {
          height: '230px'
        }
      }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CookieIcon sx={{ marginRight: '5px', color: hexToRGBA(brandColor, 8) }} />
          <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 500 }}>
            This website uses cookies
          </Typography>
        </Box>
        <Typography id="modal-modal-description" sx={{ mt: 1, flexGrow: 1, fontWeight: 100 }}>
          We use Strictly necessary Cookies that allow core website functionality only and they are not used for tracking purposes.
        </Typography>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-end',
            gap: '12px',
            mt: 2,
          }}
        >
          <Button
            variant="outlined"
            onClick={() => {
              handleCookieStorage(false);
            }}
            fullWidth
            sx={{
              height: '50px',
              borderRadius: '10px',
              border: `2px solid ${hexToRGBA(brandColor, 0.5)}`,
            }}
          >
            OK
          </Button>
        </Box>
      </Box>
    </Modal>
  </div>;
  }

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        disableScrollLock
        hideBackdrop
        disableAutoFocus
        disableEnforceFocus
        disablePortal
        style={{ position: 'absolute', zIndex: 9999, height: 0, width: 0 }}
        BackdropProps={{
          style: {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CookieIcon sx={{ marginRight: '5px', color: hexToRGBA(brandColor, 8) }} />
            <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ fontWeight: 500 }}>
              This website uses cookies
            </Typography>
          </Box>
          <Typography id="modal-modal-description" sx={{ mt: 1, flexGrow: 1, fontWeight: 100 }}>
            We use essential and necessary cookies to make our site function. We'd also like to set optional analytics
            cookies to help us improve. We won’t set optional cookies unless you enable them. Using this tool will set a
            cookie on your device to remember your preferences.
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-end',
              gap: '12px',
              mt: 2,
            }}
          >
            <Button
              variant="outlined"
              onClick={() => {
                handleCookieStorage(true);
              }}
              fullWidth
              color={'primary'}
              sx={{
                height: '50px',
                borderRadius: '10px',
                border: `2px solid ${hexToRGBA(brandColor, 0.5)}`,
              }}
            >
              Accept all cookies
            </Button>
            <Button
              variant="outlined"
              onClick={() => {
                handleCookieStorage(false);
              }}
              fullWidth
              sx={{
                height: '50px',
                borderRadius: '10px',
                border: `2px solid ${hexToRGBA(brandColor, 0.5)}`,
              }}
            >
              Essential cookies only
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
