export function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
}

export function formatToMoney(inputVal: any) {
  const number = parseFloat(inputVal);

  if (isNaN(number)) {
    console.error('Invalid input: Please provide a valid number string.');
    return inputVal;
  }

  const formatter = new Intl.NumberFormat('en-GB', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return formatter.format(number);
}
