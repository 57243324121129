import Button from '@mui/material/Button';
import {
  AppliedCoverInfo,
  AppliedOverviewText,
  IStringIndex,
  QuoteDetailResponse,
  QuoteRequestResponse,
  TAlert,
  TFormData,
  TJourneyConfig,
  TQuote,
} from '../../types';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Drawer,
  IconButton,
  Paper,
  Typography,
  useTheme,
  List,
  ListItemIcon,
  ListItem,
  ListItemText,
  Stack,
  Link,
  Alert,
  AlertTitle,
  Skeleton,
} from '@mui/material';

import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForward from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import CircleIcon from '@mui/icons-material/Circle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { DocumentItem } from '../FormFields/DocumentsField';
import { Spinner } from './QuoteListingLoader';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { AppliedTransactions, getQuote, patchQuote, updateQuoteStage } from '../../apiCalls';
import { JourneyContext } from '../../JourneyContext';
import { LoadingButton } from '@mui/lab';
import Cookies from 'js-cookie';
import SummaryListBox from '../FormFields/SummaryListBox';
import { AppliedContext } from '../../AppliedContext';
import { formatToMoney } from '../../utils/common';
import CustomAlert from '../FormFields/CustomAlert';
import { BorderAll } from '@mui/icons-material';

type LoadingState = 'Error fetching policy' | 'Finalising' | 'Loading Details' | 'Connecting to insurer...';

const cookieBranding = Cookies.get('branding');
const branding = cookieBranding && JSON.parse(decodeURIComponent(cookieBranding as string));

export const ProductInfoDrawer = ({
  config,
  open,
  onClose,
  formData,
  quote,
  handleSubmit,
  isSubmitting,
  submitError,
  setFormData,
}: {
  formData: TFormData | undefined;
  config: TJourneyConfig;
  open: boolean;
  onClose: () => void;
  quote: QuoteRequestResponse;
  handleSubmit?: (e: any, selectedQuote?: TQuote) => Promise<void>;
  isSubmitting?: boolean;
  submitError?: TAlert;
  setFormData?: Dispatch<SetStateAction<TFormData>>;
}) => {
  const theme = useTheme();
  const journeyContext = useContext(JourneyContext);
  const queryClient = useQueryClient();

  const { updateSelectedQuote } = useContext(AppliedContext);

  // const [isLoading, setIsLoading] = useState<boolean>(false);
  const [loadingStage, setLoadingStage] = useState<LoadingState>('Connecting to insurer...');

  const handleFetchQuoteDetails = async (appliedTransaction: AppliedTransactions, sequenceId?: string) => {
    if (!formData?.values || !open) return;

    const cleanValues: IStringIndex<any> = {};
    const apiConfig = config?.APIs?.patch;
    for (let [fieldName, fieldValue] of Object.entries(formData.values)) {
      if (typeof fieldValue === 'string') {
        cleanValues[fieldName] = fieldValue.replace(/\s+/g, ' ').trim();
      } else {
        cleanValues[fieldName] = fieldValue;
      }
    }

    cleanValues.intermedPolicyRefNo = formData?.values?.quote.policyNumber;
    cleanValues.schemeRef = quote.schemeRef;
    cleanValues.insurerId = quote.insurerId;

    if (sequenceId) {
      cleanValues.sequenceId = sequenceId;
    }

    const res = await patchQuote(config, apiConfig, cleanValues, appliedTransaction);
    setFormData?.({ ...formData, values: { ...cleanValues, quote: res?.[0] } });
    journeyContext?.setFormData?.({ ...formData, values: { ...cleanValues, quote: res?.[0] } });
    updateSelectedQuote({
      ...appliedQuoteDetails,
      schemeRef: quote?.schemeRef,
      frn: quote?.frn,
      insurerId: quote?.insurerId,
    });

    return res;
  };

  const { isLoading, error, data, isFetching, isRefetching } = useQuery({
    queryKey: [`QuoteDetails`, quote?.schemeRef, open],
    queryFn: () => handleFetchQuoteDetails('quoteDetails'),
    refetchOnMount: true,
    staleTime: 100,
  });

  const isPaymentMonthly = formData?.values?.paymentFrequency?.toLowerCase() === 'monthly';

  useEffect(() => {
    if (open && (isLoading || isFetching || isRefetching)) {
      const delay = (duration: number) => {
        return new Promise((resolve) => {
          setTimeout(resolve, duration);
        });
      };

      setLoadingStage('Connecting to insurer...');

      delay(Math.floor(Math.max(500, Math.random() * 2000)))
        .then(() => setLoadingStage('Loading Details'))
        .then(() => delay(Math.floor(Math.max(500, Math.random() * 2000))))
        .then(() => setLoadingStage('Finalising'))
        .then(() => delay(Math.floor(Math.max(500, Math.random() * 2000))));
    }
  }, [open, isLoading, isFetching, isRefetching]);

  const quoteDetails = data?.[0];
  const appliedQuoteDetails: QuoteDetailResponse | undefined = quoteDetails?.appliedQuoteDetails;

  const handleSelectQuote = async (): Promise<any> => {
    if (!formData?.values || !open) return;
    const cleanValues: IStringIndex<any> = {};
    for (let [fieldName, fieldValue] of Object.entries(formData.values)) {
      if (typeof fieldValue === 'string') {
        cleanValues[fieldName] = fieldValue.replace(/\s+/g, ' ').trim();
      } else {
        cleanValues[fieldName] = fieldValue;
      }
    }

    cleanValues.intermedPolicyRefNo = formData?.values?.quote.policyNumber;
    cleanValues.schemeRef = quote.schemeRef;
    cleanValues.insurerId = quote.insurerId;

    if (appliedQuoteDetails?.sequenceId) {
      cleanValues.sequenceId = appliedQuoteDetails.sequenceId;
    }
    updateSelectedQuote({
      ...appliedQuoteDetails,
      schemeRef: quote?.schemeRef,
      frn: quote?.frn,
      insurerId: quote?.insurerId,
    });
    journeyContext.setFormData({
      ...formData,
      values: cleanValues,
    });
    await handleSubmit?.(null, cleanValues as any);
  };

  const {
    isLoading: isFetchingDocs,
    isRefetching: isRefetchingDocs,
    error: docsError,
    data: docsResponse,
    refetch: refetchDocs,
  } = useQuery({
    queryKey: [`quoteDocuments`, appliedQuoteDetails?.sequenceId],
    queryFn: () => handleFetchQuoteDetails('quoteDocuments', appliedQuoteDetails?.sequenceId),
    retry: false,
    refetchOnMount: false,
    staleTime: 10000,
    enabled: !!appliedQuoteDetails ,
  });

  useEffect(() => {
    queryClient.removeQueries({ queryKey: [`QuoteDetails`, quote?.schemeRef, true] });
    queryClient.removeQueries({ queryKey: [`QuoteDetails`, quote?.schemeRef, false] });
  }, [quote?.schemeRef]);

  const fdDocs = docsResponse?.[0]?.fdDocs ?? []
  const documents = docsResponse?.[0]?.documents ?? [];
  const localStaticDocs = config?.info?.links;

  const numOfMonthlyPayments = Number(quote?.pcQuote?.numberOfCollections) - 1;

  return (
    <>
      <Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        PaperProps={{ sx: { maxWidth: 600, width: '100%', display: 'flex' } }}
      >
        {/* Titling */}
        <Paper
          variant="outlined"
          sx={{ display: 'flex', padding: '20px', alignItems: 'center', justifyContent: 'space-between' }}
        >
          <Box gap={'8px'}>
            <Typography
              textTransform="uppercase"
              color={theme?.palette?.primary?.main}
              fontWeight={700}
              fontSize={'16px'}
            >
              {quoteDetails?.productName}
            </Typography>
            <Typography fontWeight={600} fontSize={'30px'}>
              {quoteDetails?.appliedQuoteDetails?.schemeInsurerName}
            </Typography>
          </Box>
          <IconButton aria-label="close" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Paper>
        <Box
          padding={'24px'}
          flexGrow={1}
          display="flex"
          flexDirection={'column'}
          alignItems={'center'}
          justifyContent={'center'}
          overflow={'auto'}
        >
          {isLoading ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Spinner color={theme?.palette?.primary?.main} />
              <Typography fontSize="24px" fontWeight={'600'} color={theme.palette.primary.main} paddingTop={'16px'}>
                {loadingStage}
              </Typography>
            </Box>
          ) : error ? (
            <Box>
              <Alert severity="error" variant="outlined">
                Could not fetch quote details
              </Alert>
            </Box>
          ) : (
            <Box minHeight={'0px'}>
              {/* Overview */}
              <Box>
                <Box>
                  {/* <Typography fontWeight={600} fontSize={'18px'}>
                    Overview
                  </Typography>
                  <Typography paddingTop={'8px'} fontSize={'14px'}>
                    {''}
          </Typography> */}
                </Box>
                <Box paddingTop={'16px'}>
                  <Typography fontWeight={600} fontSize={'18px'}>
                    {appliedQuoteDetails?.coverLevelName}
                  </Typography>

                  <List dense>
                    {appliedQuoteDetails?.coverBreakdown?.map((perk) => (
                      <ListItem alignItems="flex-start" disablePadding>
                        <ListItemIcon sx={{ minWidth: '20px', marginTop: '8px' }}>
                          {perk.covered ? (
                            <CheckIcon sx={{ fontSize: '14px', color: theme.palette.primary.main }} />
                          ) : (
                            <CloseIcon sx={{ fontSize: '14px', color: theme.palette.grey[500] }} />
                          )}
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography fontWeight={600} fontSize={'14px'}>
                              {perk.title}
                            </Typography>
                          }
                          secondary={
                            <Typography fontSize={'14px'} color={theme.palette.grey[600]}>
                              {perk.subTitle}
                            </Typography>
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
                {/* Available to add removed for now will be added later */}
                {/* <Box paddingTop={'16px'}>
                  <Typography fontWeight={600} fontSize={'18px'}>
                    Available to Add
                  </Typography>

                  <List dense>
                    {mockedData.availableToAdd.map((item) => (
                      <ListItem disableGutters>
                        <ListItemIcon sx={{ minWidth: '30px' }}>
                          <CircleIcon sx={{ fontSize: '12px', color: theme.palette.grey[400] }} />
                        </ListItemIcon>
                        <ListItemText sx={{ fontSize: '12px' }} primary={item} />
                      </ListItem>
                    ))}
                  </List>
                </Box> */}
              </Box>

              {/* Payments */}
              <Box paddingTop={'16px'}>
                <Typography fontWeight={600} fontSize={'18px'} alignSelf={'baseline'}>
                  Payment
                </Typography>
                <Box
                  border={'2px solid'}
                  borderColor={theme.palette.grey[200]}
                  borderRadius={'8px'}
                  marginBottom={'24px'}
                  marginTop={'8px'}
                  sx={{ backgroundClip: 'padding-box' }}
                >
                  <Box padding={'16px'}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography fontSize={'18px'} fontWeight={'500'}>
                        {isPaymentMonthly ? `${numOfMonthlyPayments} monthly payments` : 'Annual Payment'}
                      </Typography>
                      <Typography color={theme?.palette?.primary?.main} fontSize={'18px'} fontWeight={'700'}>
                        £
                        {isPaymentMonthly
                          ? `${quote?.pcQuote?.recurringPayment}`
                          : `${formatToMoney(quote?.premiumIncIPT)}`}
                      </Typography>
                    </Box>
                    {isPaymentMonthly && (
                      <Box paddingTop={'8px'}>
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <Typography>Deposit</Typography>
                          <Typography fontWeight={'700'}>£{quote?.deposit}</Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} paddingTop={'4px'}>
                          <Typography>First month payment</Typography>
                          <Typography fontWeight={'700'}>£{quote?.pcQuote?.firstCollectionAmount} </Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} paddingTop={'4px'}>
                          <Typography>Total Payment</Typography>
                          <Typography fontWeight={'700'}>£{quote?.pcQuote?.totalAmountPayable} </Typography>
                        </Box>
                      </Box>
                    )}
                    <Typography  marginTop={2} >Insurers can change their prices at any time so this price might change if you return to buy later.</Typography>
                    {appliedQuoteDetails?.coverLevelCode != '03' && (
                      <>
                        <Box border={'1px solid'} borderColor={theme.palette.grey[200]} margin={'16px 0px'} />
                        <Box display={'flex'} justifyContent={'space-between'}>
                          <Typography fontSize={'18px'} fontWeight={'500'}>
                            Total Excess on this Policy
                          </Typography>
                          <Typography color={theme?.palette?.primary?.main} fontWeight={'700'}>
                            £{quote?.compulsoryExcess + (appliedQuoteDetails?.voluntaryExcessAmountApproved ?? 0)}
                          </Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} paddingTop={'8px'}>
                          <Typography>Compulsory</Typography>
                          <Typography fontWeight={'700'}>£{quote?.compulsoryExcess} </Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} paddingTop={'4px'}>
                          <Typography>Voluntary</Typography>
                          <Typography fontWeight={'700'}>£{appliedQuoteDetails?.voluntaryExcessAmountApproved} </Typography>
                        </Box>
                        {appliedQuoteDetails?.voluntaryExcessAmountOverriden && <Alert severity='warning' sx={{border: "2px solid #FFEFC6", borderRadius: "8px", marginTop:"8px"}} icon={<ErrorOutlineOutlinedIcon />}>
                        The voluntary excess you selected is not available with this insurer. It has been adjusted to the value shown above, please ensure this amount remains affordable.</Alert>}
                      </>
                    )}
                  </Box>

                  <Box
                    bgcolor={theme.palette.grey[200]}
                    borderRadius={'0px 0px 8px 8px'}
                    padding={'16px'}
                    display={'flex'}
                  >
                    <InfoOutlinedIcon sx={{ color: theme.palette.primary.main }} />
                    <Box>
                      <Typography paddingLeft={'32px'} fontSize={'14px'}>
                        <ul>
                          <li>Insurers may add an additional excess for young or inexperienced drivers.</li>
                          <li>
                            Please note that your compulsory excess may differ based on type of peril covered. You can
                            review your full compulsory excess by peril when you proceed to quote.
                          </li>
                        </ul>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>

              {/* Fees */}

              <Box paddingTop={'16px'}>
                <Typography fontWeight={600} fontSize={'18px'} alignSelf={'baseline'}>
                  Fees
                </Typography>
                <Box
                  border={'2px solid'}
                  borderColor={theme.palette.grey[200]}
                  borderRadius={'8px'}
                  marginBottom={'24px'}
                  marginTop={'8px'}
                  padding={'16px'}
                  sx={{ backgroundClip: 'padding-box' }}
                >
                  {' '}
                  {appliedQuoteDetails?.coverFees.map((fee, index) => (
                    <Box key={fee.title}>
                      <Box>
                        <Typography component={'span'} fontSize={'18px'} fontWeight={'500'}>
                          {fee.title}
                        </Typography>
                        {fee.subTitle && (
                          <Typography component="span" fontSize={'14px'} paddingLeft={'4px'}>
                            {fee.subTitle}
                          </Typography>
                        )}
                      </Box>
                      <Box display={'flex'} justifyContent={'space-between'} paddingTop={'8px'}>
                        <Typography>Our Fee</Typography>
                        <Typography fontWeight={'700'}>£{fee.brokerFee}</Typography>
                      </Box>
                      {/* Insurer fees removed for now might get added back later who knows */}
                      {/* <Box display={'flex'} justifyContent={'space-between'} paddingTop={'4px'}>
                        <Typography>Insurer</Typography>
                        <Typography fontWeight={'700'}>£{fee.insurerFee}</Typography>
                      </Box> */}
                      {/* {fee.disclaimerText && (
                        <Typography fontSize={'12px'} textAlign={'right'}>
                          {fee.disclaimerText}
                        </Typography>
                      )} */}
                      {index !== appliedQuoteDetails.coverFees.length - 1 && (
                        <Box border={'1px solid'} borderColor={theme.palette.grey[200]} margin={'16px 0px'} />
                      )}
                    </Box>
                  ))}
                </Box>
              </Box>

              {/* Documents */}
              <Typography fontWeight={600} fontSize={'18px'} alignSelf={'baseline'}>
                Documents
              </Typography>
              <Stack width={'100%'} gap={'8px'} padding={'16px 0px'}>
                {localStaticDocs?.map((doc) => (
                  <DocumentItem
                  doc={{
                    name: doc.title,
                    link: doc.url,
                  }}
                  />
                ))}
                {fdDocs?.map((doc: { fileName: string; filePath: string; category: string }, index: number) => (
                  <DocumentItem key={index} doc={{ link: doc.filePath, name: doc.fileName?.split('.')[0] }} />
                ))}
                {isFetchingDocs || isRefetchingDocs ? (
                  <>
                    <Skeleton sx={{ marginBottom: -3 }} width={'100%'} height={70} />
                    <Skeleton sx={{ marginBottom: -3 }} width={'100%'} height={70} />
                    <Skeleton sx={{ marginBottom: -3 }} width={'100%'} height={70} />
                  </>
                ) : docsError ? (
                  <Alert
                    action={
                      <Button
                        size="small"
                        color="inherit"
                        sx={{
                          textTransform: 'none',
                        }}
                        onClick={refetchDocs as any}
                      >
                        Retry
                      </Button>
                    }
                    sx={{ marginBottom: 2 }}
                    severity="error"
                  >
                    Failed to fetch documents
                  </Alert>
                ) : (
                  <>
                    {documents?.map((doc: { filename: string; description: string; url: string }) => (
                      <DocumentItem
                        doc={{
                          name: doc.description,
                          link: doc.url,
                        }}
                      />
                    ))}
                  </>
                )}
              </Stack>
            </Box>
          )}
        </Box>
        <Paper variant="outlined" sx={{ padding: 1 }}>
          <Stack alignItems={'center'} padding={2} direction={'row'} justifyContent={'flex-end'}>
            <Box>
              <LoadingButton
                loading={isSubmitting}
                onClick={handleSelectQuote}
                disabled={isLoading || isFetchingDocs}
                variant="contained"
                endIcon={<ArrowForward />}
                sx={{ padding: '12px', textTransform: 'none', fontWeight: 500 }}
              >
                Proceed to quote
              </LoadingButton>
            </Box>
          </Stack>
          {submitError && (
            <CustomAlert
              title={submitError.title}
              severity={submitError.severity}
              sx={{ marginTop: 2, marginBottom: 2, border: '1px solid rgba(0,0,0,0.5)' }}
            >
              {submitError.body}
            </CustomAlert>
          )}
        </Paper>
      </Drawer>
    </>
  );
};
