import { Button, Card, Divider, Stack, useTheme } from '@mui/material';
import { useState } from 'react';
import { IStringIndex, TFieldChangeHandler, TFormData, TFormField, TJourneyConfig, TJourneyMode } from '../../../types';
import { ArrayFieldDialog } from './ArrayFieldDialog';
import { ArrayFieldItem } from './ArrayFieldItem';
import AddIcon from '@mui/icons-material/Add';
import { PreviewArrayField } from './PreviewArrayField';
import { validateField } from '../../../fieldValidation';
import { checkDependents, initDependencies } from '../../../formHandling';
import { omit } from 'lodash';

export const ArrayField = (props: {
  field: TFormField;
  config: TJourneyConfig;
  formData: TFormData;
  value?: any[];
  changeHandler: TFieldChangeHandler;
  mode?:TJourneyMode
}) => {
  const { field, config, formData, changeHandler,mode } = props;
  const theme = useTheme();

  const [items, setItems] = useState<any[]>(formData.values[field.name] ?? []);
  const [activeIndex, setActiveIndex] = useState(field?.arrayParams?.autoOpen && items?.length === 0 ? 0 : -1);

  const addArrayItem = () => {
    setActiveIndex(items.length);
  };

  const editArrayItem = (index: number) => {
    setActiveIndex(index);
  };

  const deleteArrayItem = (index: number) => {
    const newItems = [...items];
    newItems.splice(index, 1);
    setItems(newItems);
    changeHandler(field, newItems);
    setActiveIndex(-1);
  };

  const getExtras = () => {
    const extras: IStringIndex<any> = {};
    for (let x of field.extras ?? []) {
      extras[x] = formData.values[x];
    }
    return extras;
  };

  const removeExtras = (data: IStringIndex<any>) => {
    return omit(data, field.extras ?? []);
  };

  const applyChanges = (newFormData: TFormData) => {
    const newItems = [...items];
    newItems[activeIndex] = removeExtras(newFormData.values);
    setItems(newItems);
    changeHandler(field, newItems);
    setActiveIndex(-1);
  };

  const discardChanges = () => {
    setActiveIndex(-1);
  };

  const validations: IStringIndex<any> = {};
  if (activeIndex >= 0 && items[activeIndex]) {
    initDependencies(field.fields ?? [], 'DEFAULT');
    for (let f of field.fields ?? []) {
      validations[f.name] = validateField(f, items[activeIndex][f.name], items[activeIndex]);
      checkDependents(f, field.fields ?? [], items[activeIndex], validations,config,mode);
    }
  }

  return (
    <>
      {activeIndex >= 0 ? (
        <ArrayFieldDialog
        mode={mode}
          fullScreen={field?.arrayParams?.fullScreen ?? false}
          onCancel={discardChanges}
          onApply={applyChanges}
          config={config}
          fields={field.fields ?? []}
          value={items[activeIndex]}
          extras={getExtras()}
          validations={validations}
          title={items.length === 0 ? field.arrayParams?.addFirst : field.arrayParams?.addMore}
        />
      ) : null}

      {config?.renderVersion === 'V2' ? (
        <Stack direction={'column'} spacing={2}>
          {items.map((value, index) =>
            field.variant === 'preview' ? (
              <div key={index}>
                <PreviewArrayField
                  config={config}
                  onChange={applyChanges}
                  onDelete={deleteArrayItem}
                  index={index}
                  fields={field.fields ?? []}
                  value={value}
                />
                <Divider />
              </div>
            ) : (
              <div key={value.lifetimeId ?? index}>
                <ArrayFieldItem
                  renderVersion="V2"
                  field={field}
                  index={index}
                  value={value}
                  formData={formData}
                  onEdit={editArrayItem}
                  onDelete={deleteArrayItem}
                />
                <Divider />
              </div>
            ),
          )}

          {items.length < field.arrayParams!.max && (
            <Button
              color="primary"
              variant="outlined"
              onClick={addArrayItem}
              sx={{ borderRadius: 2, textTransform: 'none', bgcolor: 'white', color: theme?.palette?.primary?.main }}
              startIcon={<AddIcon />}
              disabled={field.disabled}
              fullWidth
            >
              {items.length === 0 ? field.arrayParams?.addFirst : field.arrayParams?.addMore}
            </Button>
          )}
        </Stack>
      ) : (
        <Card sx={{ padding: 2, borderRadius: 2 }}>
          <Stack spacing={1}>
            {field.title && (
              <Stack spacing={2}>
                <b>{field.title}</b>
                <Divider></Divider>
              </Stack>
            )}

            {items.map((value, index) =>
              field.variant === 'preview' ? (
                <div key={index}>
                  <PreviewArrayField
                    config={config}
                    onChange={applyChanges}
                    onDelete={deleteArrayItem}
                    index={index}
                    fields={field.fields ?? []}
                    value={value}
                  />
                  <Divider />
                </div>
              ) : (
                <div key={value.lifetimeId ?? index}>
                  <ArrayFieldItem
                    field={field}
                    index={index}
                    value={value}
                    formData={formData}
                    onEdit={editArrayItem}
                    onDelete={deleteArrayItem}
                  />
                  <Divider />
                </div>
              ),
            )}
            <Stack spacing={2} sx={{ paddingTop: 1 }}>
              <p>
                {items.length === 0
                  ? `Please add up to ${field.arrayParams!.max} ${field.arrayParams!.noun.replaceAll(
                      /[()]/g,
                      '',
                    )} using the button below.`
                  : `You have added ${items.length} / ${field.arrayParams!.max} ${field.arrayParams!.noun}.`}
              </p>
              {items.length < field.arrayParams!.max && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={addArrayItem}
                  sx={{ borderRadius: 5, textTransform: 'none' }}
                  startIcon={<AddIcon />}
                  disabled={field.disabled}
                  fullWidth
                >
                  {items.length === 0 ? field.arrayParams?.addFirst : field.arrayParams?.addMore}
                </Button>
              )}
            </Stack>
          </Stack>
        </Card>
      )}
    </>
  );
};
