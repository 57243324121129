import React from 'react';

export default function SuccessPageTickSvg({fillColor}: {fillColor: string}) {
  return (
    <svg width="111" height="110" viewBox="0 0 111 110" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_832_53748)">
        <path
          d="M9.71094 55C9.71094 43.769 13.757 33.479 20.4686 25.5164"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M23.6465 87.8929C16.2981 80.7756 11.3278 71.2239 10.043 60.5338"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.4964 100.786C44.1582 100.786 33.7894 96.667 25.793 89.8427"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M89.2259 85.9657C81.9846 93.8438 72.0215 99.1803 60.8242 100.482"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M101.284 55.2254C101.233 66.0225 97.4408 75.9406 91.1406 83.7454"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M86.9883 21.7577C94.5508 28.9258 99.6676 38.6466 100.964 49.5395"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M55.4961 9.21362C66.6426 9.21362 76.8593 13.1977 84.7994 19.8192"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.3906 23.3694C29.5305 15.8971 39.178 10.8366 49.9808 9.5405"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.70703 27.7198C9.49033 26.3842 10.3243 25.0825 11.2147 23.8202"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.3832 57.04C1.36066 56.3637 1.34375 55.6819 1.34375 55C1.34375 46.1865 3.4457 37.8689 7.18186 30.5149"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M28.7018 102.071C27.1691 101.198 25.6814 100.251 24.25 99.231"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M77.4686 104.511C70.757 107.492 63.3185 109.155 55.4968 109.155C55.108 109.155 54.7248 109.155 54.3359 109.143"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M102.647 81.6603C101.784 83.1819 100.849 84.6583 99.8516 86.084"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M105.047 33.1183C108.005 39.8074 109.651 47.2121 109.651 55C109.651 55.4452 109.651 55.8903 109.634 56.3355"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M83.5 8.63882C84.9032 9.48975 86.2613 10.397 87.5799 11.3663"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M53.4453 0.884753C54.1272 0.856577 54.809 0.845306 55.4965 0.845306C64.3946 0.845306 72.7911 2.99234 80.1958 6.79613"
          stroke={fillColor}
          strokeWidth="1.79201"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.1226 21.2055C14.5895 21.2055 14.9679 20.827 14.9679 20.3602C14.9679 19.8933 14.5895 19.5149 14.1226 19.5149C13.6558 19.5149 13.2773 19.8933 13.2773 20.3602C13.2773 20.827 13.6558 21.2055 14.1226 21.2055Z"
          fill={fillColor}
        />
        <path
          d="M17.3375 18.1455C17.8043 18.1455 18.1828 17.7671 18.1828 17.3002C18.1828 16.8334 17.8043 16.455 17.3375 16.455C16.8706 16.455 16.4922 16.8334 16.4922 17.3002C16.4922 17.7671 16.8706 18.1455 17.3375 18.1455Z"
          fill={fillColor}
        />
        <path
          d="M31.2711 7.9457C31.7379 7.9457 32.1164 7.56725 32.1164 7.10041C32.1164 6.63357 31.7379 6.25513 31.2711 6.25513C30.8042 6.25513 30.4258 6.63357 30.4258 7.10041C30.4258 7.56725 30.8042 7.9457 31.2711 7.9457Z"
          fill={fillColor}
        />
        <path
          d="M90.9195 15.2546C91.3863 15.2546 91.7648 14.8762 91.7648 14.4093C91.7648 13.9425 91.3863 13.564 90.9195 13.564C90.4527 13.564 90.0742 13.9425 90.0742 14.4093C90.0742 14.8762 90.4527 15.2546 90.9195 15.2546Z"
          fill={fillColor}
        />
        <path
          d="M94.15 18.4104C94.6168 18.4104 94.9953 18.0319 94.9953 17.5651C94.9953 17.0982 94.6168 16.7198 94.15 16.7198C93.6831 16.7198 93.3047 17.0982 93.3047 17.5651C93.3047 18.0319 93.6831 18.4104 94.15 18.4104Z"
          fill={fillColor}
        />
        <path
          d="M103.173 30.0528C103.64 30.0528 104.019 29.6743 104.019 29.2075C104.019 28.7407 103.64 28.3622 103.173 28.3622C102.707 28.3622 102.328 28.7407 102.328 29.2075C102.328 29.6743 102.707 30.0528 103.173 30.0528Z"
          fill={fillColor}
        />
        <path
          d="M97.4859 90.7444C97.9528 90.7444 98.3312 90.3659 98.3312 89.8991C98.3312 89.4323 97.9528 89.0538 97.4859 89.0538C97.0191 89.0538 96.6406 89.4323 96.6406 89.8991C96.6406 90.3659 97.0191 90.7444 97.4859 90.7444Z"
          fill={fillColor}
        />
        <path
          d="M94.3687 94.0128C94.8356 94.0128 95.214 93.6344 95.214 93.1675C95.214 92.7007 94.8356 92.3222 94.3687 92.3222C93.9019 92.3222 93.5234 92.7007 93.5234 93.1675C93.5234 93.6344 93.9019 94.0128 94.3687 94.0128Z"
          fill={fillColor}
        />
        <path
          d="M81.5679 103.531C82.0348 103.531 82.4132 103.152 82.4132 102.685C82.4132 102.219 82.0348 101.84 81.5679 101.84C81.1011 101.84 80.7227 102.219 80.7227 102.685C80.7227 103.152 81.1011 103.531 81.5679 103.531Z"
          fill={fillColor}
        />
        <path
          d="M20.4586 97.7095C20.9254 97.7095 21.3039 97.3311 21.3039 96.8642C21.3039 96.3974 20.9254 96.019 20.4586 96.019C19.9917 96.019 19.6133 96.3974 19.6133 96.8642C19.6133 97.3311 19.9917 97.7095 20.4586 97.7095Z"
          fill={fillColor}
        />
        <path
          d="M16.8804 94.9933C17.3473 94.9933 17.7257 94.6149 17.7257 94.1481C17.7257 93.6812 17.3473 93.3028 16.8804 93.3028C16.4136 93.3028 16.0352 93.6812 16.0352 94.1481C16.0352 94.6149 16.4136 94.9933 16.8804 94.9933Z"
          fill={fillColor}
        />
        <path
          d="M6.54451 80.4601C7.01134 80.4601 7.38979 80.0816 7.38979 79.6148C7.38979 79.1479 7.01134 78.7695 6.54451 78.7695C6.07767 78.7695 5.69922 79.1479 5.69922 79.6148C5.69922 80.0816 6.07767 80.4601 6.54451 80.4601Z"
          fill={fillColor}
        />
        <path
          d="M54.9472 93.3535C76.1293 93.3535 93.3007 76.1821 93.3007 55C93.3007 33.818 76.1293 16.6465 54.9472 16.6465C33.7652 16.6465 16.5938 33.818 16.5938 55C16.5938 76.1821 33.7652 93.3535 54.9472 93.3535Z"
          fill={fillColor}
        />
        <path
          d="M37.4219 55L49.1094 66.6875L72.4787 43.3125"
          stroke="white"
          strokeWidth="4.77869"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_832_53748">
          <rect width="110" height="110" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
