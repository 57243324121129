import React, { useContext, useLayoutEffect, useState } from 'react';
import { MdOutlineCheckCircleOutline } from 'react-icons/md';
import { TBranding, TJourneyConfig, TQuote } from '../types';
import SuccessPageTickSvg from './SuccessPageTickSvg';
import { Alert, AlertTitle, Box, Container, Fade, Grow, Slide, Typography, Zoom } from '@mui/material';
import { getQuote } from '../apiCalls';
import { LoadingIndicator } from './LoadingIndicator';
import { CenteredError } from './CenteredError';
import { getEnvParams } from '../utils';
import { FiExternalLink } from 'react-icons/fi';
import { ProductsContext } from '../ProductsApiContext';
import { formatToMoney } from '../utils/common';
import DynamicFooter from './DynamicFooter';
import { ZoomIn } from '@mui/icons-material';
import Cookies from 'js-cookie';

const { userSessionToken } = getEnvParams();

const cookieBranding = Cookies.get('branding');
const branding = cookieBranding && JSON.parse(decodeURIComponent(cookieBranding as string));

export default function SuccessPageV2({
  quoteId,
  config,
}: {
  config: TJourneyConfig;
  quoteId: string | null;
  branding: TBranding;
}) {
  const [quote, setQuote] = useState<TQuote | null>(null);
  const [mode, setMode] = useState<'LOADING' | 'ERROR' | 'SUCCESS' | null>(null);
  const [error, setError] = useState<{ title: string; message: string } | null>(null);
  const { product } = useContext(ProductsContext);

  useLayoutEffect(() => {
    const _quoteId = quoteId || userSessionToken || '';
    if (_quoteId) {
      (async () => {
        try {
          setMode('LOADING');
          const data = await getQuote(_quoteId);
          if (!data)
            throw new Error('Quote not found', {
              cause: { message: 'QUOTE_NOT_FOUND', name: 'Quote not found' },
            });
          if (data?.state !== 'BOUND')
            throw new Error('Quote state is invalid', {
              cause: { message: 'INVALID_STATE', name: ' Invalid state' },
            });

          setQuote(data);
          setMode('SUCCESS');
        } catch (error: any) {
          console.log(error.message);
          setError({
            title: 'Error fetching quote',
            message: ['INVALID_STATE', 'QUOTE_NOT_FOUND'].includes(error?.cause)
              ? error.message
              : `An error occured while fetching quote details`,
          });
          setMode('ERROR');
        }
      })();
    }
  }, [quoteId]);

  if ((quoteId && window?.location?.host.includes('localhost')) || window?.location?.host?.includes('segdevelopment')) {
    console.log(`MTA Link -> ${window?.location?.origin}/?quoteId=${quoteId}`);
  }

  return (
    <div style={styles.root}>
      <header style={styles.header}>
        <img src={branding.full_logo} alt="branding logo" width={180} />
      </header>
      {mode === 'LOADING' && <LoadingIndicator />}
      {mode === 'ERROR' && <CenteredError title={error?.title} content={error?.message} />}
      {mode === 'SUCCESS' && quote && (
        <>
          <Container maxWidth="sm" sx={styles.contentContainer}>
            <Grow in={true} mountOnEnter unmountOnExit>
              <div style={styles.content}>
                <SuccessPageTickSvg fillColor={branding?.main_color} />
                <div>
                  <h3 style={styles.heading}>
                    Congratulations{' '}
                    <span
                      style={{
                        textTransform: 'capitalize',
                      }}
                    >
                      {quote?.proposerName}!
                    </span>
                  </h3>
                  <p style={styles.subheading}>You are covered for {quote?.productName}</p>
                </div>
              </div>
            </Grow>
            <Grow in={true} mountOnEnter unmountOnExit>
              <Typography textAlign={'center'} color={'#565E73'}>
                Thank you for purchasing your policy through{' '}
                <span style={{ textTransform: 'capitalize' }}>{quote?.mainBrokerName}</span>. We have emailed the policy
                documents to the address provided. This email will also confirm how to sign into our customer portal if
                you wish to manage your policy this way.
              </Typography>
            </Grow>
            <Grow in={true} mountOnEnter unmountOnExit timeout={500}>
              <Alert
                style={{
                  borderRadius: 10,
                }}
                iconMapping={{
                  success: <MdOutlineCheckCircleOutline />,
                }}
                severity="success"
              >
                <AlertTitle>Payment Successful</AlertTitle>
                {`You now have a ${quote?.productName} policy and your policy number is ${quote?.policyNumber}`}
              </Alert>
            </Grow>
            <Grow in={true} mountOnEnter unmountOnExit timeout={1000}>
              <Box>
                <PolicyDetails quote={quote} />
              </Box>
            </Grow>
            <Grow in={true} mountOnEnter unmountOnExit timeout={1500}>
              <Box>
                <PolicyDocuments quote={quote} />
              </Box>
            </Grow>
          </Container>
        </>
      )}
      <DynamicFooter branding={branding} />
      {/* <footer style={styles.footer}>
        {footer && <>{Array.isArray(footer) ? footer.map((txt, i) => <p key={i}>{txt}</p>) : <p>{footer}</p>}</>}
      </footer> */}
    </div>
  );
}

const PolicyDetails = ({ quote }: { quote: TQuote }) => {
  const premium = quote?.paymentFrequency === 'MONTHLY' ? quote?.monthlyPremium : quote?.premium;
  const data = [
    { label: 'Policy number', value: quote?.policyNumber },
    {
      label: quote?.paymentFrequency === 'MONTHLY' ? 'Monthly premium' : 'Annual premium',
      value: `£${formatToMoney(premium)}`,
    },
    { label: 'Cover duration', value: `${quote?.policyDurationAmountMonth} Months` },
    { label: 'Cover start date', value: quote?.inceptionDate },
  ];

  return (
    <div style={styles.policyContainer}>
      <p style={styles.policyTitle}>Policy Details</p>
      {data.map((item, index) => (
        <div key={index} style={styles.policyItem}>
          <span>{item.label}</span>
          <span style={{ fontWeight: 700 }}>{item.value}</span>
        </div>
      ))}
    </div>
  );
};
const PolicyDocuments = ({ quote }: { quote: TQuote }) => {
  let documents = [];

  documents =
    quote?.documents?.map((doc, index) => ({
      name: doc?.description ?? decodeURI(doc?.key)?.split(`/`)?.at(-1)?.replace('.pdf', '')?.replaceAll('-', ' '),
      url: doc?.url,
    })) ?? [];

  return (
    <div style={styles.policyContainer}>
      <p style={styles.policyTitle}>Policy Documents</p>
      {documents?.map((doc, index) => (
        <a href={doc.url} target="_blank" rel="noreferrer" key={index} style={styles.link}>
          <span>{doc.name}</span>
          <FiExternalLink size={20} />
        </a>
      ))}
    </div>
  );
};

const styles: { [key: string]: React.CSSProperties } = {
  root: {
    display: 'flex',
    minHeight: '100vh',
    flexDirection: 'column',
    overflow: 'hidden',
    background: 'white',
  },
  header: {
    padding: 20,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    margin: '10px 0 10px 0',
    // color: '#009BF5',
    textDecoration: 'none',
    textTransform: 'capitalize',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  contentContainer: {
    paddingTop: 2,
    marginTop: 10,
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  heading: {
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '32px',
    letterSpacing: '0em',
    textAlign: 'center',
  },
  subheading: {
    fontFamily: 'sans-serif',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '28px',
    letterSpacing: '0em',
    textAlign: 'center',
    color: '#565E73',
  },
  policyContainer: {
    border: '2px solid #E9EAFF',
    padding: '10px',
    borderRadius: '10px',
  },
  policyTitle: {
    color: '#464C5E',
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '20px',
    letterSpacing: '0em',
  },
  policyItem: {
    display: 'flex',
    marginTop: '10px',
    justifyContent: 'space-between',
    marginBottom: '5px',
    fontSize: '14px',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0em',
    color: '#464C5E',
  },
  footer: {
    fontSize: '12px',
    fontWeight: 400,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    lineHeight: '18px',
    padding: 10,
    letterSpacing: '0em',
    textAlign: 'left',
    position: 'fixed',
    color: '#667085',
    bottom: 0,
    width: '100%',
    backgroundColor: 'white',
    borderTop: '1px solid #E9EAFF',
  },
};
