import { renderObject, THtmlObject } from "../../renderHTML";
import { TFormField } from "../../types";

export const HTMLField = (props: { field: TFormField }) => {
  if (typeof props.field.html === "string") {
    return <div className={props.field?.props?.className ?? "htmlField"} dangerouslySetInnerHTML={{ __html: props.field.html }} />;
  }

  if (Array.isArray(props.field.html)) {
    const htmls = props.field.html as any[];
    return (
      <div className={props.field?.props?.className ?? "htmlField"} style={props.field.style}>
        {htmls.map((html) => renderObject(html.html as THtmlObject))}
      </div>
    );
  }

  if (typeof props.field.html === "object") {
    return (
      <div className={props.field?.props?.className ?? "htmlField"} style={props.field.style}>
        {renderObject(props.field.html as THtmlObject)}
      </div>
    );
  }

  return null;
};
