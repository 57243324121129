export const VehicleType = [
  {
    label: "Private Car",
    value: "Private Car",
  },
  {
    label: "Motorcycle",
    value: "Motorcycle",
  },
  {
    label: "Commercial Vehicle",
    value: "Commercial Vehicle",
  },
  {
    label: "Motor Caravan",
    value: "Motor caravan",
  },
  {
    label: "Minibus",
    value: "Minibus",
  },
  {
    label: "Quad Bike",
    value: "Quad bike",
  },
  {
    label: "Agricultural",
    value: "Agricultural",
  },
  {
    label: "Bus",
    value: "Bus",
  },
  {
    label: "Lorry",
    value: "Lorry",
  },
  {
    label: "Trailer",
    value: "Trailer",
  },
  {
    label: "Taxi",
    value: "Taxi",
  },
  {
    label: "Special Plant",
    value: "Special Plant",
  },
  {
    label: "Trade Plate",
    value: "Trade Plate",
  },
  {
    label: "Coach",
    value: "Coach",
  },
  {
    label: "Lcv",
    value: "LCV",
  },
  {
    label: "Hgv",
    value: "HGV",
  },
];
