/* eslint-disable no-template-curly-in-string */
// ============================================================================
// Provide all fields with the "fieldBase" className
// ============================================================================

import { Close, ErrorOutline, HelpOutline, Info } from '@mui/icons-material';
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  FormHelperText,
  IconButton,
  Modal,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { HelperModalParams, TFormData, TFormField, THelperTextParams, TJourneyConfig, TJourneyMode } from '../../types';
import { TouchDelay } from '../../utils';
import { useState } from 'react';
import CustomAlert from './CustomAlert';
import { conditionMet, replacePlaceholders } from '../../formHandling';

export const FieldBase = (props: {
  children: any;
  field: TFormField;
  mode?: TJourneyMode;
  formData?: TFormData;
  config?: TJourneyConfig;
}) => {
  const { children } = props;
  if (props.field.tooltip) {
    if (props.field.label) {
      return (
        <>
          <Stack direction="row" spacing={1} alignItems="top">
            <div style={{ flex: 1 }}>{props.field.label}</div>
            <Tooltip
              title={<div style={{ whiteSpace: 'pre-line' }}>{props.field.tooltip}</div>}
              disableFocusListener
              arrow
              enterTouchDelay={TouchDelay}
            >
              <HelpOutline color="primary" />
            </Tooltip>
          </Stack>
          <div className="fieldBase">{children}</div>
        </>
      );
    } else {
      return (
        <Stack direction="row" spacing={1} alignItems="center">
          <div className="fieldBase" style={{ flex: 1 }}>
            {children}
          </div>
          <Tooltip
            title={<div style={{ whiteSpace: 'pre-line' }}>{props.field.tooltip}</div>}
            disableFocusListener
            arrow
            enterTouchDelay={TouchDelay}
          >
            <HelpOutline color="primary" />
          </Tooltip>
        </Stack>
      );
    }
  } else {
    return (
      <div className="fieldBase">
        {props.field?.params?.hasDivider && (
          <div
            style={{
              margin: '20px 0 20px 0',
              height: '1px',
              width: '100%',
              backgroundColor: '#E9EAFF',
            }}
          />
        )}
        {props.field?.heading && (
          <Typography
            marginTop={2}
            fontFamily={'Poppins'}
            marginBottom={1}
            color={'primary'}
            fontSize={18}
            fontWeight={600}
          >
            {replacePlaceholders(props?.formData?.values, props?.field.heading)}
          </Typography>
        )}
        {props.field.label &&
          (props.field.label.includes('${productName}') ? (
            <p className="fieldLabel">
              {props.field.label.replace('${productName}', props.config?.product.title ?? '')}
            </p>
          ) : (
            <p className="fieldLabel">{props.field.label}</p>
          ))}
        {props.field?.subLabel && <p className="fieldLabel">{props.field?.subLabel}</p>}
        {children}
        {props.field?.helperText && (
          <CustomHelperText
            formData={props.formData}
            content={props.field.helperText as any}
            params={props?.field?.helperTextParams}
          />
        )}
        {props.field?.helperModal && <HelperModal {...props.field.helperModal} />}
      </div>
    );
  }
};

const CustomHelperText = ({
  content,
  params,
  formData,
}: {
  formData: TFormData | undefined;
  params?: THelperTextParams;
  content: string;
}) => {
  const hasConditions = params?.dependsOn;

  if (hasConditions && formData) {
    const conditionsMet = conditionMet(params.dependsOn, formData.values, formData.validations);
    if(!conditionsMet){
      return null
    }
  }

  if (params?.variant) {
    return (
      <CustomAlert
        sx={{
          marginTop: 1,
        }}
        variant={params?.variant as any}
        severity={params?.type}
        title={params?.title}
        hideIcon={params?.hideIcon}
      >
        {content}
      </CustomAlert>
    );
  }

  return (
    <Typography
      paddingLeft={0.2}
      marginTop={1}
      color={'#565E73'}
      fontSize={12}
      fontWeight={400}
      fontFamily={'Inter'}
      gutterBottom
    >
      {content}
    </Typography>
  );
};

const HelperModal = ({ content, heading, hyperlink }: HelperModalParams) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Box marginTop={0.2}>
        <Button
          onClick={() => setOpen(true)}
          sx={{
            textTransform: 'none',
            fontSize: 12,
            fontFamily: 'Inter',
            fontWeight: 500,
          }}
          variant="text"
          startIcon={<ErrorOutline />}
          color="primary"
          size="small"
        >
          {hyperlink}
        </Button>
      </Box>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            border: '2px solid #E9EAFF',
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography fontSize={30} fontFamily={'Poppins'} fontWeight={600} color={'#1C1D21'}>
              {heading}
            </Typography>
            <IconButton onClick={() => setOpen(false)}>
              <Close />
            </IconButton>
          </Stack>
          <div
            style={{
              margin: '20px 0 20px 0',
              height: '1px',
              width: '100%',
              backgroundColor: '#E9EAFF',
            }}
          />
          <Stack>
            {content.map((item, index) => (
              <>
                <Typography color={'#1A2133'} fontSize={12} fontWeight={600} fontFamily={'Inter'}>
                  {item.title}
                </Typography>
                <Typography marginBottom={1} color={'#565E73'} fontSize={12} fontWeight={400} fontFamily={'Inter'}>
                  {item.description}
                </Typography>
              </>
            ))}
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
