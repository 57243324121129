import { useContext, useEffect } from "react";
import { JourneyContext } from "../../JourneyContext";
import { TFormField } from "../../types";

export const Setter = (props: { field: TFormField }) => {
  const { field } = props;
  const journeyContext = useContext(JourneyContext);

  useEffect(() => {
    const keys = field.setterParams?.keys ?? [];
    const values = field.setterParams?.values ?? [];
    const newValues = { ...journeyContext.formData.values };
    for (let i = 0; i <= keys.length; i++) {
      newValues[keys[i]] = values[i];
    }

    journeyContext.setFormData({
      values: newValues,
      validations: { ...journeyContext.formData.validations },
    });
  }, []);

  return null;
};
