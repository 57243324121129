export const OccupationCode = [
  { label: "Abattoir Worker", value: "1" },
  { label: "Abbot or Abbess", value: "01A" },
  { label: "Abstractor", value: "02A" },
  { label: "Accommodation Officer", value: "391" },
  { label: "Accompanist", value: "03A" },
  { label: "Account Director", value: "53D" },
  { label: "Account Executive", value: "D77" },
  { label: "Account Manager", value: "D78" },
  { label: "Accountant", value: "A01" },
  { label: "Accounts Assistant", value: "2" },
  { label: "Accounts Clerk", value: "3" },
  { label: "Accounts Manager", value: "M07" },
  { label: "Accounts Staff", value: "A04" },
  { label: "Acoustic Engineer", value: "B76" },
  { label: "Acrobat", value: "04A" },
  { label: "Actor", value: "4" },
  { label: "Actuary", value: "A06" },
  { label: "Acupuncturist", value: "6" },
  { label: "Administration Assistant", value: "7" },
  { label: "Administration Clerk", value: "8" },
  { label: "Administration Manager", value: "M08" },
  { label: "Administration Officer", value: "05A" },
  { label: "Administration Staff", value: "A07" },
  { label: "Administrator", value: "9" },
  { label: "Advertising Agent", value: "A25" },
  { label: "Advertising Assistant", value: "10" },
  { label: "Advertising Buyer", value: "18C" },
  { label: "Advertising Clerk", value: "11" },
  { label: "Advertising Contractor", value: "680" },
  { label: "Advertising Controller", value: "77A" },
  { label: "Advertising Director", value: "81B" },
  { label: "Advertising Executive", value: "E27" },
  { label: "Advertising Manager", value: "12" },
  { label: "Advertising Staff", value: "A08" },
  { label: "Advocate", value: "06A" },
  { label: "Aerial Erector", value: "13" },
  { label: "Aerobic Instructor", value: "14" },
  { label: "Aeronautical Engineer", value: "E10" },
  { label: "After Dinner Speaker", value: "01J" },
  { label: "Agister", value: "D79" },
  { label: "Agricultural Consultant", value: "B36" },
  { label: "Agricultural Contractor", value: "B37" },
  { label: "Agricultural Engineer", value: "15" },
  { label: "Agricultural Merchant", value: "B38" },
  { label: "Agricultural Worker", value: "B70" },
  { label: "Agriculturalist", value: "07A" },
  { label: "Agronomist", value: "D80" },
  { label: "Air Force Crew", value: "18" },
  { label: "Air Traffic Controller", value: "A09" },
  { label: "Aircraft Buyer", value: "392" },
  { label: "Aircraft Cabin Crew", value: "A10" },
  { label: "Aircraft Designer", value: "16" },
  { label: "Aircraft Engineer", value: "559" },
  { label: "Aircraft Fitter", value: "08A" },
  { label: "Aircraft Hand", value: "09A" },
  { label: "Aircraft Maintenance Engineer", value: "B39" },
  { label: "Aircraft Surface Finisher", value: "B40" },
  { label: "Airline Broker", value: "10A" },
  { label: "Airline Check-in Staff", value: "17" },
  { label: "Airport Controller", value: "588" },
  { label: "Airport Manager", value: "M09" },
  { label: "Alarm Fitter", value: "40A" },
  { label: "Almoner", value: "19" },
  { label: "Ambulance Controller", value: "B41" },
  { label: "Ambulance Crew", value: "A12" },
  { label: "Ambulance Driver", value: "D41" },
  { label: "Amusement Arcade Worker", value: "A34" },
  { label: "Anaesthetist", value: "20" },
  { label: "Analytical Chemist", value: "B42" },
  { label: "Animal Breeder", value: "21" },
  { label: "Animator", value: "11A" },
  { label: "Announcer", value: "12A" },
  { label: "Anthropologist", value: "B86" },
  { label: "Antique Dealer", value: "A13" },
  { label: "Antique Renovator", value: "41C" },
  { label: "Appeal/Tribunal Chairperson", value: "53A" },
  { label: "Applications Engineer", value: "393" },
  { label: "Applications Programmer", value: "394" },
  { label: "Aquarist", value: "D81" },
  { label: "Arbitrator", value: "22" },
  { label: "Arborist", value: "B84" },
  { label: "Archaeologist", value: "A14" },
  { label: "Archbishop", value: "14A" },
  { label: "ArchDeacon", value: "15A" },
  { label: "Architect", value: "A15" },
  { label: "Architects Technician", value: "23" },
  { label: "Architectural Surveyor", value: "B43" },
  { label: "Archivist", value: "A16" },
  { label: "Area Manager", value: "M10" },
  { label: "Armourer", value: "B44" },
  { label: "Army - NCO/Commissioned Officer", value: "A28" },
  { label: "Army - Other Ranks", value: "A27" },
  { label: "Aromatherapist", value: "691" },
  { label: "Art Buyer", value: "16A" },
  { label: "Art Critic", value: "24" },
  { label: "Art Dealer", value: "A17" },
  { label: "Art Director", value: "17A" },
  { label: "Art Historian", value: "A74" },
  { label: "Art Restorer", value: "B45" },
  { label: "Artexer", value: "B46" },
  { label: "Articled Clerk", value: "25" },
  { label: "Artificial Inseminator", value: "18A" },
  { label: "Artificial Intelligence Engineer", value: "71S" },
  { label: "Artificial Limb Fitter", value: "B71" },
  { label: "Artist - Commercial", value: "20D" },
  { label: "Artist - Freelance", value: "21D" },
  { label: "Artist - Portrait", value: "19A" },
  { label: "Artist - Technical", value: "20A" },
  { label: "Asbestos Remover", value: "C93" },
  { label: "Asphalter", value: "26" },
  { label: "Assembly Worker", value: "A20" },
  { label: "Assessor", value: "A21" },
  { label: "Assistant Accounts Manager", value: "589" },
  { label: "Assistant Caretaker", value: "590" },
  { label: "Assistant Cook", value: "591" },
  { label: "Assistant Manager", value: "395" },
  { label: "Assistant Nurse", value: "592" },
  { label: "Assistant Teacher", value: "593" },
  { label: "Astrologer", value: "27" },
  { label: "Astronomer", value: "28" },
  { label: "Athlete", value: "21A" },
  { label: "ATM Fitter", value: "B55" },
  { label: "Au Pair", value: "A26" },
  { label: "Auction Worker", value: "692" },
  { label: "Auctioneer", value: "A22" },
  { label: "Audiologist", value: "396" },
  { label: "Audit Clerk", value: "397" },
  { label: "Audit Manager", value: "515" },
  { label: "Auditor", value: "A23" },
  { label: "Author", value: "A24" },
  { label: "Auto Electrician", value: "29" },
  { label: "Auxiliary Nurse", value: "30" },
  { label: "Bacon Curer", value: "31" },
  { label: "Bacteriologist", value: "22A" },
  { label: "Baggage Handler", value: "B01" },
  { label: "Bailiff", value: "B02" },
  { label: "Baker", value: "B03" },
  { label: "Bakery Assistant", value: "560" },
  { label: "Bakery Manager", value: "681" },
  { label: "Bakery Operator", value: "594" },
  { label: "Ballistics Expert", value: "23A" },
  { label: "Balloonist", value: "693" },
  { label: "Bank Clerk", value: "32" },
  { label: "Bank Manager", value: "33" },
  { label: "Bank Messenger", value: "B47" },
  { label: "Bank Note Checker", value: "398" },
  { label: "Bank Staff", value: "B04" },
  { label: "Banking Correspondent", value: "81A" },
  { label: "Baptist Minister", value: "595" },
  { label: "Bar Manager", value: "34" },
  { label: "Bar Staff", value: "B05" },
  { label: "Bar Steward", value: "35" },
  { label: "Barber", value: "36" },
  { label: "Barge Operator", value: "24A" },
  { label: "Barista", value: "01L" },
  { label: "Barrister", value: "B06" },
  { label: "Basket Worker", value: "25A" },
  { label: "Beautician", value: "B07" },
  { label: "Beauty Therapist", value: "596" },
  { label: "BeeKeeper", value: "26A" },
  { label: "Betting Shop Clerk", value: "682" },
  { label: "Bill Poster", value: "39" },
  { label: "Bingo Caller", value: "876" },
  { label: "Bingo Hall Staff", value: "694" },
  { label: "Biochemist", value: "40" },
  { label: "Biologist", value: "41" },
  { label: "Biometrician", value: "28A" },
  { label: "Biophysicist", value: "29A" },
  { label: "Bishop", value: "30A" },
  { label: "Blacksmith", value: "B08" },
  { label: "Blind Assembler", value: "597" },
  { label: "Blind Fitter", value: "516" },
  { label: "Blinds Installer", value: "42" },
  { label: "Blogger/Vlogger", value: "BL1" },
  { label: "Boat Builder", value: "B09" },
  { label: "Boatswain", value: "32A" },
  { label: "Body Fitter", value: "598" },
  { label: "Bodyguard", value: "D69" },
  { label: "Bodyshop Manager", value: "476" },
  { label: "Boiler Maker", value: "43" },
  { label: "Boiler Technician", value: "B10" },
  { label: "Book Binder", value: "44" },
  { label: "Book Seller", value: "45" },
  { label: "Book-Keeper", value: "399" },
  { label: "Bookfinisher", value: "33A" },
  { label: "Booking Agent", value: "599" },
  { label: "Booking Clerk", value: "C22" },
  { label: "Booking Office Clerk", value: "400" },
  { label: "Boom Operator", value: "34A" },
  { label: "Botanist", value: "46" },
  { label: "Bottler", value: "35A" },
  { label: "Box Maker", value: "695" },
  { label: "Box Office Clerk", value: "B67" },
  { label: "Boxer", value: "52F" },
  { label: "Branch Manager", value: "M11" },
  { label: "Brewer", value: "47" },
  { label: "Brewery Manager", value: "B48" },
  { label: "Brewery Worker", value: "B12" },
  { label: "Bricklayer", value: "B13" },
  { label: "Bridge Operator", value: "36A" },
  { label: "Bridgemaster", value: "37A" },
  { label: "Broadcaster", value: "48" },
  { label: "Broadcasting Engineer", value: "E11" },
  { label: "Brother or Sister (Church)", value: "38A" },
  { label: "Builder", value: "B15" },
  { label: "Builders Labourer", value: "49" },
  { label: "Builders Merchant", value: "696" },
  { label: "Building Advisor", value: "600" },
  { label: "Building Contractor", value: "601" },
  { label: "Building Control Officer", value: "D47" },
  { label: "Building Engineer", value: "B49" },
  { label: "Building Estimator", value: "B50" },
  { label: "Building Foreperson", value: "50" },
  { label: "Building Inspector", value: "401" },
  { label: "Building Maintenance", value: "39A" },
  { label: "Building Manager", value: "602" },
  { label: "Building Site Inspector", value: "697" },
  { label: "Building Society Clerk", value: "683" },
  { label: "Building Society Staff", value: "557" },
  { label: "Building Surveyor", value: "402" },
  { label: "Bursar", value: "B16" },
  { label: "Bus Driver", value: "51" },
  { label: "Bus Mechanic", value: "B51" },
  { label: "Bus Valeter", value: "603" },
  { label: "Business Analyst", value: "D93" },
  { label: "Business Consultant", value: "B18" },
  { label: "Butcher", value: "B21" },
  { label: "Butchery Manager", value: "684" },
  { label: "Butler", value: "52" },
  { label: "Buyer", value: "B22" },
  { label: "Cabinet Maker", value: "53" },
  { label: "Cable Contractor", value: "517" },
  { label: "Cable Jointer", value: "B52" },
  { label: "Cable TV Installer", value: "698" },
  { label: "Cafe Owner", value: "604" },
  { label: "Cafe Staff", value: "605" },
  { label: "Calibration Manager", value: "403" },
  { label: "Call Centre Manager", value: "CA1" },
  { label: "Call Centre Staff", value: "CA2" },
  { label: "Calligrapher", value: "43A" },
  { label: "Camera Operator/Technician", value: "54" },
  { label: "Camera Repairer", value: "B53" },
  { label: "Canal Boat Broker", value: "B54" },
  { label: "Canon or Canoness (Church)", value: "44A" },
  { label: "Canvasser", value: "45A" },
  { label: "Car Body Repairer", value: "606" },
  { label: "Car Builder", value: "55" },
  { label: "Car Delivery Driver", value: "D27" },
  { label: "Car Park Attendant", value: "C51" },
  { label: "Car Salesperson", value: "56" },
  { label: "Car Wash Attendant", value: "608" },
  { label: "Cardinal", value: "47A" },
  { label: "Cardiographer", value: "48A" },
  { label: "Cardiologist", value: "49A" },
  { label: "Care Assistant", value: "C49" },
  { label: "Care Manager", value: "404" },
  { label: "Careers Advisor", value: "518" },
  { label: "Careers Officer", value: "57" },
  { label: "Carer - Non Professional", value: "D75" },
  { label: "Carer - Professional", value: "D89" },
  { label: "Caretaker", value: "C02" },
  { label: "Cargo Handler", value: "50A" },
  { label: "Cargo Operator", value: "699" },
  { label: "Carpenter", value: "C03" },
  { label: "Carpenters Assistant", value: "609" },
  { label: "Carpet Cleaner", value: "700" },
  { label: "Carpet Retailer", value: "610" },
  { label: "Carpetfitter", value: "C04" },
  { label: "Cartographer", value: "C05" },
  { label: "Cartoonist", value: "B90" },
  { label: "Cashier", value: "C06" },
  { label: "Cataloguer", value: "51A" },
  { label: "Caterer", value: "C08" },
  { label: "Catering Consultant", value: "B91" },
  { label: "Catering Manager", value: "611" },
  { label: "Catering Staff", value: "C10" },
  { label: "Caulker", value: "59" },
  { label: "Ceiling Contractor", value: "612" },
  { label: "Ceiling Fitter", value: "52A" },
  { label: "Ceiling Fixer", value: "561" },
  { label: "Cellarperson", value: "613" },
  { label: "Certified Accountant", value: "60" },
  { label: "Chandler", value: "61" },
  { label: "Chaplain", value: "A91" },
  { label: "Charge Hand", value: "405" },
  { label: "Chartered Accountant", value: "63" },
  { label: "Chartered Engineer", value: "614" },
  { label: "Chartered Surveyor", value: "64" },
  { label: "Chartered Valuer", value: "D90" },
  { label: "Charterer", value: "C52" },
  { label: "Chauffeur", value: "C12" },
  { label: "Chef", value: "C13" },
  { label: "Chemical Engineer", value: "E12" },
  { label: "Chemist", value: "65" },
  { label: "Chicken Chaser", value: "D50" },
  { label: "Chicken Sexer", value: "66" },
  { label: "Chief Cashier", value: "615" },
  { label: "Chief Chemist", value: "616" },
  { label: "Chief Executive", value: "D62" },
  { label: "Child Care Officer", value: "54A" },
  { label: "Child Minder", value: "C14" },
  { label: "Childrens Entertainer", value: "C50" },
  { label: "Chimney Sweep", value: "C15" },
  { label: "China Restorer", value: "702" },
  { label: "Chiropodist", value: "C16" },
  { label: "Chiropractor", value: "68" },
  { label: "Choir Leader", value: "55A" },
  { label: "Choreographer", value: "69" },
  { label: "Chorister", value: "56A" },
  { label: "Church Army Worker", value: "57A" },
  { label: "Church Dean", value: "98A" },
  { label: "Church Officer", value: "70" },
  { label: "Church Official", value: "52L" },
  { label: "Church Warden", value: "71" },
  { label: "Cinema Assistant", value: "495" },
  { label: "Cinema Manager", value: "M12" },
  { label: "Circus Proprietor", value: "B56" },
  { label: "Circus Worker", value: "C17" },
  { label: "Civil Engineer", value: "E13" },
  { label: "Civil Servant", value: "C18" },
  { label: "Claims Adjuster", value: "72" },
  { label: "Claims Assessor", value: "73" },
  { label: "Claims Manager", value: "617" },
  { label: "Clairvoyant", value: "D51" },
  { label: "Clapper", value: "59A" },
  { label: "Classical Musician", value: "D48" },
  { label: "Clay Pigeon Instructor", value: "703" },
  { label: "Cleaner", value: "C20" },
  { label: "Cleaning Contractor", value: "562" },
  { label: "Cleaning Supervisor", value: "618" },
  { label: "Clergy", value: "74" },
  { label: "Cleric", value: "75" },
  { label: "Clerical Assistant", value: "619" },
  { label: "Clerical Officer", value: "496" },
  { label: "Clerk", value: "C21" },
  { label: "Clerk of Court", value: "61A" },
  { label: "Clerk Of Works", value: "C25" },
  { label: "Clinical Psychologist", value: "620" },
  { label: "Clock Maker", value: "76" },
  { label: "Clothing Design Cutter", value: "01B" },
  { label: "Coach Builder", value: "77" },
  { label: "Coach Driver", value: "78" },
  { label: "Coach Sprayer", value: "B57" },
  { label: "Coal Merchant", value: "79" },
  { label: "Coastguard", value: "C27" },
  { label: "Cobbler", value: "632" },
  { label: "Coffee Merchant", value: "B58" },
  { label: "Coin Dealer", value: "704" },
  { label: "College Dean", value: "705" },
  { label: "College Lecturer", value: "621" },
  { label: "College Principal", value: "B85" },
  { label: "College Scout", value: "65A" },
  { label: "Colourist (Maps/Photographs)", value: "66A" },
  { label: "Comedian or Comedienne", value: "67A" },
  { label: "Commercial Manager", value: "520" },
  { label: "Commission Agent", value: "C54" },
  { label: "Commissionaire", value: "C29" },
  { label: "Commissioner of Police", value: "69A" },
  { label: "Commissioning Engineer", value: "622" },
  { label: "Commodity Broker", value: "C30" },
  { label: "Commodity Dealer", value: "C31" },
  { label: "Communications Officer", value: "A92" },
  { label: "Communications Supervisor", value: "687" },
  { label: "Community Craft Instructor", value: "623" },
  { label: "Community Nurse", value: "B59" },
  { label: "Community Worker", value: "83" },
  { label: "Companion", value: "70A" },
  { label: "Companion Nurse", value: "71A" },
  { label: "Company Chairperson", value: "D61" },
  { label: "Company Director", value: "84" },
  { label: "Company Search Agent", value: "C71" },
  { label: "Company Secretary", value: "C32" },
  { label: "Compiler", value: "72A" },
  { label: "Complementary Therapist", value: "407" },
  { label: "Compliance Officer", value: "73A" },
  { label: "Composer", value: "85" },
  { label: "Compositor", value: "C33" },
  { label: "Computer Analyst", value: "86" },
  { label: "Computer Consultant", value: "C55" },
  { label: "Computer Editor", value: "75A" },
  { label: "Computer Engineer", value: "87" },
  { label: "Computer Manager", value: "688" },
  { label: "Computer Network Controller", value: "95B" },
  { label: "Computer Operator", value: "C56" },
  { label: "Computer Programmer", value: "C57" },
  { label: "Computer Technician", value: "C58" },
  { label: "Conductor", value: "76A" },
  { label: "Confectioner", value: "521" },
  { label: "Conference Manager", value: "B60" },
  { label: "Conference Organiser", value: "706" },
  { label: "Conservationist", value: "707" },
  { label: "Conservator", value: "624" },
  { label: "Construction Engineer", value: "689" },
  { label: "Construction Worker", value: "C35" },
  { label: "Consultant Engineer", value: "C59" },
  { label: "Consumer Scientist", value: "CA3" },
  { label: "Contract Cleaner", value: "477" },
  { label: "Contract Furnisher", value: "625" },
  { label: "Contract Manager", value: "497" },
  { label: "Contracts Supervisor", value: "626" },
  { label: "Conveyancer", value: "89" },
  { label: "Cook", value: "90" },
  { label: "Cooper", value: "91" },
  { label: "Copier", value: "78A" },
  { label: "Coppersmith", value: "375" },
  { label: "Copy Typist", value: "80A" },
  { label: "Copywriter", value: "384" },
  { label: "Coroner", value: "C38" },
  { label: "Corrosion Consultant", value: "C72" },
  { label: "Costume Designer", value: "B61" },
  { label: "Costume Jeweller", value: "C60" },
  { label: "Costumier", value: "A93" },
  { label: "Counter Staff", value: "27A" },
  { label: "Countryside Ranger", value: "708" },
  { label: "County Councillor", value: "498" },
  { label: "Courier", value: "C39" },
  { label: "Court Officer", value: "408" },
  { label: "Court Reporter", value: "01E" },
  { label: "Coxswain", value: "86A" },
  { label: "Craft Dealer", value: "627" },
  { label: "Craftsperson", value: "C43" },
  { label: "Crane Driver", value: "94" },
  { label: "Crane Erector", value: "D70" },
  { label: "Crane Operator", value: "C44" },
  { label: "Crash Assessor", value: "01F" },
  { label: "Creative Director", value: "88A" },
  { label: "Creche Worker", value: "522" },
  { label: "Credit Broker", value: "B92" },
  { label: "Credit Controller", value: "C53" },
  { label: "Credit Manager", value: "95" },
  { label: "Credit Researcher", value: "01G" },
  { label: "Crematorium Attendant", value: "A94" },
  { label: "Cricketer", value: "89A" },
  { label: "Crime Examiner", value: "629" },
  { label: "Criminologist", value: "90A" },
  { label: "Crofter", value: "96" },
  { label: "Croupier", value: "C46" },
  { label: "Crown Prosecutor", value: "B82" },
  { label: "Curate", value: "92A" },
  { label: "Curator", value: "C47" },
  { label: "Currency Trader", value: "709" },
  { label: "Curtain Hanger", value: "93A" },
  { label: "Curtain Maker", value: "690" },
  { label: "Customer Advisor", value: "409" },
  { label: "Customer Liaison Officer", value: "390" },
  { label: "Customer Service Controller", value: "94A" },
  { label: "Customer Services Clerk", value: "56F" },
  { label: "Customs & Excise Officer", value: "97" },
  { label: "Cutler", value: "98" },
  { label: "Cutter", value: "99" },
  { label: "Cycle Repairer", value: "630" },
  { label: "Cyclist", value: "23C" },
  { label: "Dairy Engineer", value: "633" },
  { label: "Dairy Worker", value: "D01" },
  { label: "Dance Teacher", value: "410" },
  { label: "Dancer", value: "100" },
  { label: "Dark Room Technician", value: "385" },
  { label: "Darts Player", value: "56E" },
  { label: "Data Administrator", value: "634" },
  { label: "Data Co-Ordinator", value: "635" },
  { label: "Data Processing Manager", value: "74A" },
  { label: "Data Processor", value: "563" },
  { label: "Data Scientist", value: "70S" },
  { label: "Day Care Officer", value: "523" },
  { label: "Day Centre Officer", value: "53C" },
  { label: "Dealer - General", value: "D03" },
  { label: "Debt Collector", value: "D44" },
  { label: "Debt Counsellor", value: "636" },
  { label: "Decorator", value: "101" },
  { label: "Delivery Courier", value: "102" },
  { label: "Delivery Driver", value: "637" },
  { label: "Delivery Rounds Person", value: "D05" },
  { label: "Demolition Contractor", value: "23D" },
  { label: "Demolition Worker", value: "D06" },
  { label: "Demonstrator", value: "103" },
  { label: "Dendrochronologist", value: "A75" },
  { label: "Dental Assistant", value: "104" },
  { label: "Dental Hygienist", value: "105" },
  { label: "Dental Mechanic", value: "99A" },
  { label: "Dental Nurse", value: "106" },
  { label: "Dental Surgeon", value: "499" },
  { label: "Dental Technician", value: "107" },
  { label: "Dental Therapist", value: "D92" },
  { label: "Dentist", value: "108" },
  { label: "Deputy Head Teacher", value: "638" },
  { label: "Deputy Manager", value: "639" },
  { label: "Deputy Principal", value: "640" },
  { label: "Dermatologist", value: "109" },
  { label: "Design Copier", value: "79A" },
  { label: "Design Director", value: "641" },
  { label: "Design Engineer", value: "411" },
  { label: "Design Manager", value: "642" },
  { label: "Designer", value: "D08" },
  { label: "Despatch Driver", value: "643" },
  { label: "Despatch Rider", value: "644" },
  { label: "Despatch Worker", value: "110" },
  { label: "Development Manager", value: "412" },
  { label: "Diamond Dealer", value: "710" },
  { label: "Diecaster", value: "524" },
  { label: "Dietician", value: "111" },
  { label: "Diplomat", value: "113" },
  { label: "Director of Environment", value: "08B" },
  { label: "Director of Housing", value: "09B" },
  { label: "Director of Planning", value: "10B" },
  { label: "Disc Jockey", value: "D17" },
  { label: "Distillery Worker", value: "711" },
  { label: "Distribution Manager", value: "645" },
  { label: "Distributor (Leaflets/Circ.)", value: "12B" },
  { label: "District Nurse", value: "N03" },
  { label: "District Valuer", value: "D19" },
  { label: "Diver", value: "D20" },
  { label: "Dock Pilot", value: "13B" },
  { label: "Docker", value: "D21" },
  { label: "Dockyard Worker", value: "114" },
  { label: "Doctor", value: "115" },
  { label: "Document Controller", value: "646" },
  { label: "Dog Beautician", value: "564" },
  { label: "Dog Breeder", value: "116" },
  { label: "Dog Groomer", value: "413" },
  { label: "Dog Handler", value: "14B" },
  { label: "Dog Trainer", value: "647" },
  { label: "Dog Walker", value: "D88" },
  { label: "Dog Warden", value: "A84" },
  { label: "Doll Maker", value: "B93" },
  { label: "Domestic Servant", value: "37B" },
  { label: "Domestic Staff", value: "648" },
  { label: "Door Fitter", value: "649" },
  { label: "Door To Door Collector", value: "C28" },
  { label: "Doorperson", value: "D24" },
  { label: "Double Glazing Fitter", value: "D39" },
  { label: "Double Glazing Salesperson", value: "117" },
  { label: "Draper", value: "15B" },
  { label: "Draughtsperson", value: "D25" },
  { label: "Drayperson", value: "118" },
  { label: "Dredger Master", value: "16B" },
  { label: "Dredging Operator", value: "17B" },
  { label: "Dresser Theatre/Films", value: "18B" },
  { label: "Dressmaker", value: "D26" },
  { label: "Driller (Civil Engineering)", value: "19B" },
  { label: "Drilling Technician", value: "712" },
  { label: "Driver's Mate", value: "DM1" },
  { label: "Driving Examiner", value: "D37" },
  { label: "Driving Instructor", value: "D38" },
  { label: "Driving Instructor (HGV)", value: "D91" },
  { label: "Driving Instructor - Advanced", value: "D94" },
  { label: "Drone Operator", value: "66S" },
  { label: "Drug Addiction Counsellor", value: "414" },
  { label: "Dry Cleaner", value: "651" },
  { label: "Dryliner", value: "525" },
  { label: "Dye Polisher", value: "652" },
  { label: "Dyer", value: "120" },
  { label: "Earth Moving Contractor", value: "E31" },
  { label: "Ecologist", value: "653" },
  { label: "Economist", value: "E01" },
  { label: "Editor", value: "E02" },
  { label: "Editorial Consultant", value: "C73" },
  { label: "Editorial Staff", value: "713" },
  { label: "Education Advisor", value: "654" },
  { label: "Education Officer", value: "A95" },
  { label: "Electrical Contractor", value: "415" },
  { label: "Electrical Contracts Manager", value: "21B" },
  { label: "Electrical Engineer", value: "E28" },
  { label: "Electrical Fitter", value: "655" },
  { label: "Electrical Process Worker", value: "22B" },
  { label: "Electrician", value: "E04" },
  { label: "Electrologist", value: "C74" },
  { label: "Electronic Engineer", value: "121" },
  { label: "Electronics Supervisor", value: "656" },
  { label: "Electronics Technician", value: "657" },
  { label: "Electroplater", value: "23B" },
  { label: "Electrotyper", value: "24B" },
  { label: "Embalmer", value: "122" },
  { label: "Embassy Staff", value: "123" },
  { label: "Embroiderer", value: "B94" },
  { label: "Embryologist", value: "25B" },
  { label: "Endocrinologist", value: "26B" },
  { label: "Energy Analyst", value: "500" },
  { label: "Engine Operator", value: "28B" },
  { label: "Engine Room Operator", value: "27B" },
  { label: "Engineer", value: "E09" },
  { label: "Engineer Stoker", value: "71C" },
  { label: "Engraver", value: "E22" },
  { label: "Enquiry Agent", value: "E23" },
  { label: "Entertainer", value: "E24" },
  { label: "Entomologist", value: "29B" },
  { label: "Environmental Chemist", value: "A76" },
  { label: "Environmental Consultant", value: "B95" },
  { label: "Environmental Health Officer", value: "124" },
  { label: "Equity Agent", value: "C75" },
  { label: "Erector", value: "30B" },
  { label: "Ergonomist", value: "E32" },
  { label: "Escort", value: "31B" },
  { label: "Estate Agent", value: "E25" },
  { label: "Estate Manager", value: "658" },
  { label: "Estimator", value: "E29" },
  { label: "Evangelist", value: "416" },
  { label: "Events Organiser", value: "659" },
  { label: "Examiner", value: "32B" },
  { label: "Excursion Manager", value: "417" },
  { label: "Executive Officer", value: "58A" },
  { label: "Exhaust Fitter", value: "125" },
  { label: "Exhibition Designer", value: "B96" },
  { label: "Exhibition Organiser", value: "126" },
  { label: "Exotic Dancer", value: "127" },
  { label: "Expedition Leader", value: "A85" },
  { label: "Explosives Worker", value: "33B" },
  { label: "Export Consultant", value: "C76" },
  { label: "Exporter", value: "714" },
  { label: "Extra", value: "35B" },
  { label: "Extrusion Operator", value: "660" },
  { label: "Fabricator", value: "128" },
  { label: "Factory Canteen Manager", value: "661" },
  { label: "Factory Inspector", value: "I07" },
  { label: "Factory Manager", value: "662" },
  { label: "Factory Worker", value: "F01" },
  { label: "Fairground Worker", value: "F02" },
  { label: "Falconer", value: "B78" },
  { label: "Farm Manager", value: "565" },
  { label: "Farm Worker", value: "F03" },
  { label: "Farmer", value: "F04" },
  { label: "Farrier", value: "386" },
  { label: "Fashion Designer", value: "D09" },
  { label: "Fashion Photographer", value: "663" },
  { label: "Fast Food Delivery Driver", value: "129" },
  { label: "Fast Food Proprietor", value: "715" },
  { label: "Fence Erector", value: "130" },
  { label: "Fettler (Foundry)", value: "34B" },
  { label: "Fibre Glass Moulder", value: "131" },
  { label: "Field Officer", value: "A96" },
  { label: "Figure Painter", value: "664" },
  { label: "Filing Clerk", value: "60A" },
  { label: "Film Director", value: "132" },
  { label: "Film Editor", value: "56P" },
  { label: "Film Editor (Cutter)", value: "56Q" },
  { label: "Film Gaffer", value: "41B" },
  { label: "Film Location Manager", value: "56N" },
  { label: "Film Producer", value: "133" },
  { label: "Film Technician", value: "134" },
  { label: "Finance Director", value: "418" },
  { label: "Finance Manager", value: "665" },
  { label: "Finance Officer", value: "666" },
  { label: "Financial Advisor", value: "F05" },
  { label: "Financial Analyst", value: "419" },
  { label: "Financial Consultant", value: "478" },
  { label: "Financier", value: "F06" },
  { label: "Finisher", value: "36B" },
  { label: "Fire Officer", value: "B73" },
  { label: "Fire Prevention Officer", value: "716" },
  { label: "Fire Protection Consultant", value: "D60" },
  { label: "Fire Service Inspector", value: "56M" },
  { label: "Firefighter", value: "135" },
  { label: "Fireplace Fitter", value: "667" },
  { label: "Firewood Merchant", value: "668" },
  { label: "First Aid Instructor", value: "D97" },
  { label: "First Aid Worker", value: "669" },
  { label: "Fish Buyer", value: "670" },
  { label: "Fish Filleter", value: "501" },
  { label: "Fish Fryer", value: "136" },
  { label: "Fish Merchant", value: "671" },
  { label: "Fish Worker", value: "672" },
  { label: "Fisher", value: "F08" },
  { label: "Fisheries Inspector", value: "673" },
  { label: "Fishery Manager", value: "566" },
  { label: "Fishmonger", value: "F09" },
  { label: "Fitness Instructor", value: "137" },
  { label: "Fitter", value: "F10" },
  { label: "Flagger", value: "526" },
  { label: "Fleet Manager", value: "86B" },
  { label: "Flight Deck Crew", value: "138" },
  { label: "Flight Dispatcher", value: "02B" },
  { label: "Floor Layer", value: "420" },
  { label: "Floor Manager", value: "421" },
  { label: "Florist", value: "F12" },
  { label: "Flour Miller", value: "674" },
  { label: "Flower Arranger", value: "B97" },
  { label: "Flying Instructor", value: "387" },
  { label: "Foam Convertor", value: "527" },
  { label: "Food Processor", value: "422" },
  { label: "Footballer", value: "675" },
  { label: "Forensic Scientist", value: "C77" },
  { label: "Foreperson", value: "502" },
  { label: "Forest Ranger", value: "B75" },
  { label: "Forester", value: "F16" },
  { label: "Fork Lift Truck Driver", value: "D28" },
  { label: "Fortune Teller", value: "38B" },
  { label: "Forwarding Agent", value: "F17" },
  { label: "Foster Parent", value: "A97" },
  { label: "Foundry Moulder", value: "88B" },
  { label: "Foundry Worker", value: "139" },
  { label: "Fraud Investigator", value: "A86" },
  { label: "Freelance Photographer", value: "30D" },
  { label: "French Polisher", value: "140" },
  { label: "Fruiterer", value: "676" },
  { label: "Fuel Merchant", value: "141" },
  { label: "Fund Manager", value: "39B" },
  { label: "Fund Raiser", value: "F18" },
  { label: "Funeral Director", value: "142" },
  { label: "Funeral Furnisher", value: "143" },
  { label: "Funfair Employee", value: "40B" },
  { label: "Furnace Operator", value: "F19" },
  { label: "Furniture Dealer", value: "677" },
  { label: "Furniture Remover", value: "F20" },
  { label: "Furniture Restorer", value: "F21" },
  { label: "Furrier", value: "F24" },
  { label: "Gallery Owner", value: "717" },
  { label: "Gambler", value: "718" },
  { label: "Gambling Commission Inspector", value: "C96" },
  { label: "Gamekeeper", value: "G10" },
  { label: "Gaming Club Manager", value: "719" },
  { label: "Gaming Club Proprietor", value: "720" },
  { label: "Garage Attendant", value: "721" },
  { label: "Garage Foreperson", value: "423" },
  { label: "Garage Manager", value: "722" },
  { label: "Garda", value: "B66" },
  { label: "Garden Designer", value: "723" },
  { label: "Gardener", value: "G01" },
  { label: "Gas Fitter", value: "144" },
  { label: "Gas Mechanic", value: "724" },
  { label: "Gas Technician", value: "145" },
  { label: "Gate Keeper", value: "D52" },
  { label: "Genealogist", value: "784" },
  { label: "General Manager", value: "146" },
  { label: "General Practitioner", value: "D22" },
  { label: "Geneticist", value: "42B" },
  { label: "Geographer", value: "43B" },
  { label: "Geologist", value: "G09" },
  { label: "Geophysicist", value: "424" },
  { label: "Geriatrician", value: "44B" },
  { label: "Gilder", value: "B79" },
  { label: "Glass Worker", value: "G03" },
  { label: "Glazier", value: "147" },
  { label: "Goldsmith", value: "G04" },
  { label: "Golf Caddy", value: "D68" },
  { label: "Golf Club Professional", value: "A77" },
  { label: "Golf Coach", value: "45B" },
  { label: "Golfer", value: "725" },
  { label: "Goods Despatcher", value: "03B" },
  { label: "Goods Handler", value: "528" },
  { label: "Governor", value: "D57" },
  { label: "Granite Technician", value: "726" },
  { label: "Graphic Designer", value: "D10" },
  { label: "Graphologist", value: "B74" },
  { label: "Grave Digger", value: "G05" },
  { label: "Gravel Merchant", value: "727" },
  { label: "Green Keeper", value: "148" },
  { label: "Greengrocer", value: "G06" },
  { label: "Grocer", value: "728" },
  { label: "Groom", value: "G07" },
  { label: "Ground Worker", value: "503" },
  { label: "Grounds Person", value: "G08" },
  { label: "Guard", value: "149" },
  { label: "Guest House Proprietor", value: "150" },
  { label: "Guide", value: "729" },
  { label: "Gun Smith", value: "151" },
  { label: "Gynaecologist", value: "152" },
  { label: "Haematologist", value: "46B" },
  { label: "Hair Stylist", value: "75C" },
  { label: "Hairdresser", value: "H01" },
  { label: "Handyperson", value: "529" },
  { label: "Harbour Master", value: "D82" },
  { label: "Hardware Dealer", value: "730" },
  { label: "Haulage Contractor", value: "731" },
  { label: "Hawker", value: "H03" },
  { label: "Head Greenkeeper", value: "732" },
  { label: "Head Groom", value: "A78" },
  { label: "Head of Arts", value: "47B" },
  { label: "Head of Trade", value: "48B" },
  { label: "Head of Traffic", value: "49B" },
  { label: "Headteacher", value: "H11" },
  { label: "Health Advisor", value: "567" },
  { label: "Health And Safety Consultant", value: "733" },
  { label: "Health And Safety Officer", value: "376" },
  { label: "Health Care Assistant", value: "734" },
  { label: "Health Planner", value: "735" },
  { label: "Health Therapist", value: "479" },
  { label: "Health Visitor", value: "H05" },
  { label: "Hearing Aid Technician", value: "50B" },
  { label: "Hearing Therapist", value: "425" },
  { label: "Heating & Ventilation Engineer", value: "153" },
  { label: "Heating Engineer", value: "154" },
  { label: "Heating/Ventilation Engineer", value: "E14" },
  { label: "Hedger/Ditcher", value: "51B" },
  { label: "Helmsperson", value: "52B" },
  { label: "Herbalist", value: "155" },
  { label: "Herdsperson", value: "53B" },
  { label: "HGV Driver", value: "D29" },
  { label: "HGV Mechanic", value: "736" },
  { label: "Highway Inspector", value: "D53" },
  { label: "Hire Car Driver", value: "D30" },
  { label: "Hirer", value: "H17" },
  { label: "Histologist", value: "54B" },
  { label: "Historian", value: "737" },
  { label: "Hod Carrier", value: "156" },
  { label: "Home Economist", value: "D76" },
  { label: "Home Help", value: "H06" },
  { label: "Homecare Manager", value: "738" },
  { label: "Homeopath", value: "157" },
  { label: "Hop Merchant", value: "739" },
  { label: "Horse Breeder", value: "158" },
  { label: "Horse Dealer (Non Sport)", value: "14D" },
  { label: "Horse Dealer (Sport)", value: "15D" },
  { label: "Horse Riding Instructor", value: "H12" },
  { label: "Horse Trader", value: "161" },
  { label: "Horse Trainer", value: "740" },
  { label: "Horticultural Consultant", value: "C78" },
  { label: "Horticulturalist", value: "H07" },
  { label: "Hosiery Mechanic", value: "741" },
  { label: "Hosiery Worker", value: "742" },
  { label: "Hospital Consultant", value: "743" },
  { label: "Hospital Doctor", value: "678" },
  { label: "Hospital Manager", value: "744" },
  { label: "Hospital Orderly", value: "745" },
  { label: "Hospital Photographer", value: "55B" },
  { label: "Hospital Technician", value: "426" },
  { label: "Hospital Warden", value: "A87" },
  { label: "Hospital Worker", value: "746" },
  { label: "Hostess", value: "H08" },
  { label: "Hot Foil Printer", value: "747" },
  { label: "Hotel Consultant", value: "B98" },
  { label: "Hotel Worker", value: "748" },
  { label: "Hotelier", value: "162" },
  { label: "House Parent", value: "427" },
  { label: "House Sitter", value: "D83" },
  { label: "Housekeeper", value: "H10" },
  // { label: "Houseperson (Housewife or Househusband)", value: "H09" },
  { label: "Housing Assistant", value: "749" },
  { label: "Housing Officer", value: "164" },
  { label: "Housing Supervisor", value: "750" },
  { label: "Human Resources Manager", value: "H15" },
  { label: "Human Resources Staff", value: "H16" },
  { label: "Hunt Master or Mistress", value: "H18" },
  { label: "Huntsperson", value: "H19" },
  { label: "Hydro Geologist", value: "751" },
  { label: "Hygienist", value: "568" },
  { label: "Hypnotherapist", value: "752" },
  { label: "Hypnotist", value: "A98" },
  { label: "Ice Cream Vendor", value: "I01" },
  { label: "Illustrator", value: "378" },
  { label: "Immigration Officer", value: "428" },
  { label: "Immunologist", value: "57B" },
  { label: "Import Consultant", value: "C79" },
  { label: "Import/Export Dealer", value: "58B" },
  { label: "Importer", value: "753" },
  // { label: "Independent Means", value: "I02" },
  { label: "Induction Moulder", value: "785" },
  { label: "Industrial Chemist", value: "I03" },
  { label: "Industrial Consultant", value: "B99" },
  { label: "Industrial Designer", value: "D11" },
  { label: "Injection Moulder", value: "786" },
  { label: "Inland Revenue Officer", value: "I04" },
  { label: "Insolvency Practitioner", value: "D95" },
  { label: "Inspector", value: "I05" },
  { label: "Inspector - Insurance", value: "I09" },
  { label: "Installation Worker", value: "59B" },
  { label: "Instructor", value: "60B" },
  { label: "Instrument Engineer", value: "429" },
  { label: "Instrument Maker", value: "I10" },
  { label: "Instrument Supervisor", value: "531" },
  { label: "Instrument Technician", value: "782" },
  { label: "Insurance Assessor", value: "165" },
  { label: "Insurance Collector", value: "61B" },
  { label: "Insurance Consultant", value: "787" },
  { label: "Insurance Inspector", value: "166" },
  { label: "Insurance Representative", value: "167" },
  { label: "Interior Decorator", value: "168" },
  { label: "Interior Designer", value: "D12" },
  { label: "Interpreter", value: "I13" },
  { label: "Interviewer", value: "169" },
  { label: "Inventor", value: "D63" },
  { label: "Investigator", value: "975" },
  { label: "Investment Advisor", value: "D64" },
  { label: "Investment Banker", value: "788" },
  { label: "Investment Manager", value: "M13" },
  { label: "Invigilator", value: "62B" },
  { label: "Ironer", value: "63B" },
  { label: "Ironer Finisher", value: "64B" },
  { label: "Ironer Presser", value: "65B" },
  { label: "Ironmonger", value: "I14" },
  { label: "IT Consultant", value: "870" },
  { label: "IT Manager", value: "871" },
  { label: "IT Trainer", value: "872" },
  { label: "IT/Systems Architect", value: "72S" },
  { label: "Janitor", value: "170" },
  { label: "Jazz Composer", value: "789" },
  { label: "Jeweller", value: "J01" },
  { label: "Jewellery Consultant", value: "480" },
  { label: "Jockey", value: "J02" },
  { label: "Joiner", value: "J03" },
  { label: "Joinery Consultant", value: "790" },
  { label: "Jointer", value: "68B" },
  { label: "Journalist", value: "J04" },
  { label: "Journalist - Freelance", value: "26D" },
  { label: "Journalistic Agent", value: "C80" },
  { label: "Judge", value: "J05" },
  { label: "Juggler", value: "69B" },
  { label: "Junk Shop Proprietor", value: "791" },
  { label: "Justice Of The Peace", value: "171" },
  { label: "Keep Fit Instructor", value: "172" },
  { label: "Kennel Hand", value: "C94" },
  { label: "Kiln Setter", value: "70B" },
  { label: "Kilnperson (Glass/Ceramics)", value: "71B" },
  { label: "Kissagram Person", value: "174" },
  { label: "Kitchen Worker", value: "175" },
  { label: "Knitter", value: "K01" },
  { label: "Labelling Operator", value: "795" },
  { label: "Laboratory Analyst", value: "792" },
  { label: "Laboratory Assistant", value: "430" },
  { label: "Laboratory Attendant", value: "797" },
  { label: "Laboratory Manager", value: "793" },
  { label: "Laboratory Operative", value: "796" },
  { label: "Laboratory Supervisor", value: "794" },
  { label: "Laboratory Technician", value: "L01" },
  { label: "Labourer", value: "L02" },
  { label: "Laminator", value: "379" },
  { label: "Lampshade Maker", value: "C61" },
  { label: "Land Agent", value: "L12" },
  { label: "Land Surveyor", value: "C81" },
  { label: "Landowner", value: "A79" },
  { label: "Landscape Architect", value: "532" },
  { label: "Landscape Gardener", value: "L03" },
  { label: "Landworker", value: "874" },
  { label: "Lathe Operator", value: "431" },
  { label: "Laundry Worker", value: "177" },
  { label: "Lavatory Attendant", value: "178" },
  { label: "Law Clerk", value: "799" },
  { label: "Lawn Mower Repairer", value: "800" },
  { label: "Lawyer", value: "L05" },
  { label: "Leaflet Distributor", value: "801" },
  { label: "Leather Worker", value: "179" },
  { label: "Lecturer", value: "L06" },
  { label: "Ledger Clerk", value: "802" },
  { label: "Legal Advisor", value: "803" },
  { label: "Legal Assistant", value: "504" },
  { label: "Legal Executive", value: "432" },
  { label: "Legal Secretary", value: "L07" },
  { label: "Leisure Centre Attendant", value: "180" },
  { label: "Leisure Centre Manager", value: "804" },
  { label: "Lengthsperson", value: "35C" },
  { label: "Lens Grinder & Polisher", value: "181" },
  { label: "Letting Agent", value: "805" },
  { label: "Lexicographer", value: "72B" },
  { label: "Liaison Officer", value: "C99" },
  { label: "Librarian", value: "L08" },
  { label: "Library Manager", value: "817" },
  { label: "Licensee", value: "182" },
  { label: "Licensing Consultant", value: "C62" },
  { label: "Life Assurance Salesperson", value: "54D" },
  { label: "Lifeguard", value: "L11" },
  { label: "Lift Attendant", value: "183" },
  { label: "Lift Engineer", value: "184" },
  { label: "Lighterperson", value: "818" },
  { label: "Lighthouse Keeper", value: "D49" },
  { label: "Lighting Designer", value: "819" },
  { label: "Lighting Director", value: "73B" },
  { label: "Lighting Technician", value: "185" },
  { label: "Lime Kiln Attendant", value: "820" },
  { label: "Line Manager", value: "875" },
  { label: "Line Worker", value: "186" },
  { label: "Linesperson", value: "74B" },
  { label: "Linguist", value: "187" },
  { label: "Linotype Operator", value: "75B" },
  { label: "Literary Agent", value: "188" },
  { label: "Literary Editor", value: "821" },
  { label: "Lithographer", value: "L09" },
  { label: "Litigation Manager", value: "569" },
  { label: "Loader", value: "493" },
  { label: "Loans Manager", value: "822" },
  { label: "Local Government Officer", value: "383" },
  { label: "Lock Keeper", value: "D58" },
  { label: "Locksmith", value: "L10" },
  { label: "Locum Doctor", value: "24D" },
  { label: "Locum Pharmacist", value: "823" },
  { label: "Log Merchant", value: "824" },
  { label: "Lorry Driver", value: "189" },
  { label: "Loss Adjustor", value: "190" },
  { label: "Loss Assessor", value: "191" },
  { label: "Lumberjack", value: "192" },
  { label: "Machine Fitters Mate", value: "825" },
  { label: "Machine Minder", value: "826" },
  { label: "Machine Operator", value: "M01" },
  { label: "Machine Setter", value: "481" },
  { label: "Machine Technician", value: "827" },
  { label: "Machine Tool Engineer", value: "828" },
  { label: "Machine Tool Fitter", value: "829" },
  { label: "Machinist", value: "M02" },
  { label: "Magician", value: "830" },
  { label: "Magistrate", value: "M03" },
  { label: "Magistrates Clerk", value: "193" },
  { label: "Maid", value: "831" },
  { label: "Mail Order Worker", value: "76B" },
  { label: "Maintenance Engineer", value: "832" },
  { label: "Maintenance Fitter", value: "482" },
  { label: "Maintenance Manager", value: "833" },
  { label: "Maintenance Staff", value: "M04" },
  { label: "Maitre D Hotel", value: "77B" },
  { label: "Make Up Artist", value: "505" },
  { label: "Make Up Supervisor", value: "834" },
  { label: "Management Consultant", value: "M05" },
  { label: "Manager", value: "M06" },
  { label: "Manager - Sports", value: "M19" },
  { label: "Managing Clerk", value: "78B" },
  { label: "Managing Director", value: "194" },
  { label: "Manicurist", value: "195" },
  { label: "Manufacturing Agent", value: "M23" },
  { label: "Manufacturing Engineer", value: "79B" },
  { label: "Manufacturing Technician", value: "836" },
  { label: "Map Maker", value: "27D" },
  { label: "Map Mounter", value: "837" },
  { label: "Marble Finisher", value: "838" },
  { label: "Marble Mason", value: "839" },
  { label: "Marine Broker", value: "C85" },
  { label: "Marine Consultant", value: "C63" },
  { label: "Marine Electrician", value: "840" },
  { label: "Marine Engineer", value: "E15" },
  { label: "Marine Geologist", value: "841" },
  { label: "Marine Pilot", value: "196" },
  { label: "Marine Surveyor", value: "570" },
  { label: "Market Gardener", value: "M24" },
  { label: "Market Research Assistant", value: "843" },
  { label: "Market Researcher", value: "M25" },
  { label: "Market Trader", value: "M26" },
  { label: "Marketing Agent", value: "844" },
  { label: "Marketing Assistant", value: "197" },
  { label: "Marketing Co-ordinator", value: "845" },
  { label: "Marketing Consultant", value: "846" },
  { label: "Marketing Director", value: "847" },
  { label: "Marketing Executive", value: "198" },
  { label: "Marketing Manager", value: "199" },
  { label: "Marquee Erector", value: "848" },
  { label: "Massage Therapist", value: "849" },
  { label: "Masseur or Masseuse", value: "200" },
  { label: "Master Mariner", value: "850" },
  { label: "Master of Ceremonies", value: "D84" },
  { label: "Master Of Foxhounds", value: "M53" },
  { label: "Mate (Ship's)", value: "56K" },
  { label: "Materials Controller", value: "851" },
  { label: "Materials Manager", value: "852" },
  { label: "Mathematician", value: "853" },
  { label: "Matron", value: "434" },
  { label: "Mattress Maker", value: "854" },
  // { label: "Mature Student - Living At Home", value: "S50" },
  // { label: "Mature Student - Living Away", value: "S51" },
  { label: "Meat Inspector", value: "855" },
  { label: "Meat Wholesaler", value: "856" },
  { label: "Mechanic", value: "M29" },
  { label: "Mechanical Designer", value: "80B" },
  { label: "Mechanical Engineer", value: "E16" },
  { label: "Mechanical Technician", value: "783" },
  { label: "Medal Dealer", value: "857" },
  { label: "Media Critic", value: "91A" },
  { label: "Media Planner", value: "82B" },
  { label: "Medical Advisor", value: "858" },
  { label: "Medical Assistant", value: "859" },
  { label: "Medical Consultant", value: "C36" },
  { label: "Medical Diagnostician", value: "06B" },
  { label: "Medical Officer", value: "B35" },
  { label: "Medical Physicist", value: "860" },
  { label: "Medical Practitioner", value: "B34" },
  { label: "Medical Researcher", value: "861" },
  { label: "Medical Secretary", value: "435" },
  // { label: "Medical Student - Living At Home", value: "S48" },
  // { label: "Medical Student - Living Away", value: "S49" },
  { label: "Medical Supplier", value: "862" },
  { label: "Medical Technician", value: "863" },
  { label: "Member Of Parliament", value: "M45" },
  { label: "Member of Religious Order", value: "52H" },
  { label: "Merchandiser", value: "864" },
  { label: "Merchant Banker", value: "865" },
  { label: "Merchant Ship Captain", value: "46A" },
  { label: "Merchant Ship Crew", value: "202" },
  { label: "Messenger", value: "M34" },
  { label: "Metal Dealer", value: "203" },
  { label: "Metal Engineer", value: "571" },
  { label: "Metal Polisher", value: "506" },
  { label: "Metal Worker", value: "M50" },
  { label: "Metallurgist", value: "M35" },
  { label: "Meteorologist", value: "M36" },
  { label: "Meter Reader", value: "M37" },
  { label: "Microbiologist", value: "204" },
  { label: "Microfilm Operator", value: "507" },
  { label: "Microscopist", value: "83B" },
  { label: "Midwife", value: "M38" },
  { label: "Milk Delivery Person", value: "205" },
  { label: "Mill Operator", value: "866" },
  { label: "Mill Worker", value: "206" },
  { label: "Miller", value: "207" },
  { label: "Milliner", value: "M39" },
  { label: "Millwright", value: "B80" },
  { label: "Miner", value: "M40" },
  { label: "Mineralologist", value: "208" },
  { label: "Minibus Driver", value: "A88" },
  { label: "Minicab Driver", value: "209" },
  { label: "Mining Consultant", value: "867" },
  { label: "Mining Engineer", value: "E17" },
  { label: "Minister Of Religion", value: "M41" },
  { label: "Minister Of The Crown", value: "84B" },
  { label: "Missionary", value: "B81" },
  { label: "Mobile Caterer", value: "C09" },
  { label: "Mobile Disc Jockey", value: "D18" },
  { label: "Mobile Disco Owner", value: "868" },
  { label: "Mobile Hairdresser", value: "210" },
  { label: "Mobile Motor Mechanic", value: "A80" },
  { label: "Mobile Service Engineer", value: "211" },
  { label: "Model", value: "M42" },
  { label: "Model Maker", value: "869" },
  { label: "Moderator", value: "85B" },
  { label: "Money Broker", value: "754" },
  { label: "Money Dealer", value: "806" },
  { label: "Moneylender", value: "M51" },
  { label: "Monk or Nun", value: "D85" },
  { label: "Monumental Sculptor", value: "873" },
  { label: "Mooring Contractor", value: "807" },
  { label: "Mortgage Broker", value: "212" },
  { label: "Mortgage Consultant", value: "808" },
  { label: "Mortician", value: "213" },
  { label: "Motor Dealer", value: "809" },
  { label: "Motor Engineer", value: "214" },
  { label: "Motor Fitter", value: "810" },
  { label: "Motor Mechanic", value: "215" },
  { label: "Motor Racing Driver", value: "28D" },
  { label: "Motor Racing Organiser", value: "811" },
  { label: "Motor Trader", value: "M44" },
  { label: "Moulding Process Technician", value: "89B" },
  { label: "Museum Assistant", value: "812" },
  { label: "Museum Attendant", value: "813" },
  { label: "Museum Consultant", value: "814" },
  { label: "Museum Technician", value: "436" },
  { label: "Music Producer", value: "91B" },
  { label: "Music Teacher", value: "508" },
  { label: "Music Therapist", value: "815" },
  { label: "Music Wholesaler", value: "816" },
  { label: "Musical Arranger", value: "92B" },
  { label: "Musician", value: "M46" },
  { label: "Musician - Amateur", value: "29D" },
  { label: "Nanny", value: "N08" },
  { label: "Naturalist", value: "94B" },
  { label: "Naturopath", value: "C82" },
  { label: "Navigator", value: "216" },
  { label: "Negotiator", value: "N01" },
  { label: "Neurologist", value: "217" },
  { label: "Newsagent", value: "N11" },
  { label: "Newsreader", value: "96B" },
  { label: "Night Porter", value: "878" },
  { label: "Night Watchperson", value: "218" },
  { label: "Non Commissioned Officer", value: "219" },
  { label: "Non Religious Celebrant", value: "N13" },
  // { label: "Not Employed Due to Disability", value: "43D" },
  // { label: "Not In Employment", value: "220" },
  { label: "Notary Public", value: "221" },
  { label: "Nuclear Scientist", value: "222" },
  { label: "Nurse", value: "N02" },
  { label: "Nursemaid", value: "97B" },
  { label: "Nursery Nurse", value: "N10" },
  { label: "Nursery Worker (Childcare)", value: "879" },
  { label: "Nursery Worker (Gardening)", value: "N05" },
  { label: "Nursing Assistant", value: "533" },
  { label: "Nursing Auxiliary", value: "880" },
  { label: "Nursing Manager", value: "572" },
  { label: "Nursing Officer", value: "54C" },
  { label: "Nursing Sister", value: "D66" },
  { label: "Nutritionist", value: "755" },
  { label: "Obstetrician", value: "98B" },
  { label: "Occupational Health Consultant", value: "C64" },
  { label: "Occupational Health Nurse", value: "D54" },
  { label: "Occupational Therapist", value: "223" },
  { label: "Oceanographer", value: "56J" },
  { label: "Oculist", value: "224" },
  { label: "Off Shore Surveyor", value: "881" },
  { label: "Office Administrator", value: "882" },
  { label: "Office Manager", value: "225" },
  { label: "Oil Broker", value: "883" },
  { label: "Oil Rig Crew", value: "534" },
  { label: "Online/eTrade Seller (e.g. Ebay)", value: "ON1" },
  { label: "Opera Singer", value: "884" },
  { label: "Operating Department Practitioner", value: "A36" },
  { label: "Operations Director", value: "885" },
  { label: "Operations Engineer", value: "99B" },
  { label: "Operations Manager", value: "226" },
  { label: "Operations Supervisor", value: "438" },
  { label: "Opthalmic Surgeon", value: "01C" },
  { label: "Opthalmic Technician", value: "573" },
  { label: "Optical Advisor", value: "886" },
  { label: "Optical Assistant", value: "380" },
  { label: "Optical Technician", value: "535" },
  { label: "Optician", value: "O02" },
  { label: "Optometrist", value: "A89" },
  { label: "Orchestra Leader", value: "02C" },
  { label: "Orchestral Violinist", value: "887" },
  { label: "Order Clerk", value: "888" },
  { label: "Orderly", value: "03C" },
  { label: "Organist", value: "227" },
  { label: "Ornamental Blacksmith", value: "889" },
  { label: "Ornithologist", value: "C91" },
  { label: "Orthopaedic Technician", value: "890" },
  { label: "Orthoptist", value: "756" },
  { label: "Osteopath", value: "O03" },
  { label: "Outdoor Pursuits Instructor", value: "891" },
  { label: "Outfitter", value: "439" },
  { label: "Outreach Worker", value: "892" },
  { label: "Oven Cleaner", value: "52M" },
  { label: "Overhead Line Instructor", value: "893" },
  { label: "Overhead Lineperson", value: "894" },
  { label: "Overlocker", value: "895" },
  { label: "Overseas Mailer", value: "896" },
  { label: "Overwriter", value: "897" },
  { label: "Packaging Consultant", value: "898" },
  { label: "Packer", value: "P01" },
  { label: "Paediatrician", value: "899" },
  { label: "Paint Consultant", value: "C83" },
  { label: "Paint Sprayer", value: "229" },
  { label: "Paint Technologist", value: "01H" },
  { label: "Painter", value: "P02" },
  { label: "Painter And Decorator", value: "P03" },
  { label: "Palaeobotanist", value: "B89" },
  { label: "Palaeontologist", value: "D56" },
  { label: "Pallet Maker", value: "381" },
  { label: "Panel Beater", value: "230" },
  { label: "Paper Mill Worker", value: "900" },
  { label: "Parachute Packer", value: "D59" },
  { label: "Paramedic", value: "231" },
  { label: "Park Attendant", value: "232" },
  { label: "Park Keeper", value: "233" },
  { label: "Park Ranger", value: "901" },
  { label: "Partition Erector", value: "902" },
  { label: "Parts Manager", value: "483" },
  { label: "Parts Person", value: "903" },
  { label: "Parts Supervisor", value: "536" },
  { label: "Party Planner", value: "P05" },
  { label: "Pasteuriser", value: "537" },
  { label: "Pastry Chef", value: "904" },
  { label: "Patent Agent", value: "757" },
  { label: "Patent Attorney", value: "538" },
  { label: "Pathologist", value: "440" },
  { label: "Patrol Person", value: "C92" },
  { label: "Pattern Cutter", value: "905" },
  { label: "Pattern Maker", value: "P06" },
  { label: "Pattern Weaver", value: "906" },
  { label: "Paviour", value: "907" },
  { label: "Pawnbroker", value: "P07" },
  { label: "Payment Officer", value: "908" },
  { label: "Payroll Assistant", value: "574" },
  { label: "Payroll Clerk", value: "910" },
  { label: "Payroll Manager", value: "911" },
  { label: "Payroll Supervisor", value: "912" },
  { label: "Pearl Stringer", value: "913" },
  { label: "Pedicurist", value: "P52" },
  { label: "Pensions Consultant", value: "914" },
  { label: "Pensions Manager", value: "915" },
  { label: "Personal Assistant", value: "P08" },
  { label: "Personnel Administrator", value: "P09" },
  { label: "Personnel Manager", value: "234" },
  { label: "Personnel Officer", value: "235" },
  { label: "Pest Control", value: "P10" },
  { label: "Pest Controller", value: "236" },
  { label: "Pet Minder", value: "916" },
  { label: "Petrol Station Attendant", value: "P11" },
  { label: "Petroleum Engineer", value: "441" },
  { label: "Petty Officer", value: "917" },
  { label: "Pharmaceutical Assistant", value: "918" },
  { label: "Pharmacist", value: "P12" },
  { label: "Pharmacy Manager", value: "919" },
  { label: "Pharmacy Technician", value: "920" },
  { label: "Philatelist", value: "B87" },
  { label: "Phlebotomist", value: "P50" },
  { label: "Photo Engraver", value: "237" },
  { label: "Photo Laboratory Processor", value: "921" },
  { label: "Photo Technician", value: "922" },
  { label: "Photocopy Machine Technician", value: "A99" },
  { label: "Photographer", value: "P13" },
  { label: "Photographer - Location", value: "P47" },
  { label: "Photographer - Press", value: "05C" },
  { label: "Photographer - Press (Local)", value: "33D" },
  { label: "Photographer - Shop Owner", value: "06C" },
  { label: "Photographer - Street", value: "34D" },
  { label: "Photographer - Studio", value: "P15" },
  { label: "Photographic Agent", value: "C84" },
  { label: "Physician", value: "923" },
  { label: "Physicist", value: "238" },
  { label: "Physiologist", value: "388" },
  { label: "Physiotherapist", value: "P16" },
  { label: "Piano Teacher", value: "924" },
  { label: "Piano Tuner", value: "P17" },
  { label: "Picker", value: "239" },
  { label: "Picture Editor", value: "925" },
  { label: "Picture Framer", value: "240" },
  { label: "Picture Renovator", value: "42C" },
  { label: "Picture Researcher", value: "926" },
  { label: "Pig Manager", value: "928" },
  { label: "Pilot", value: "241" },
  { label: "Pilot Helicopter", value: "08C" },
  { label: "Pilot Test", value: "09C" },
  { label: "Pipe Fitter", value: "509" },
  { label: "Pipe Inspector", value: "758" },
  { label: "Pipe Insulator", value: "929" },
  { label: "Pipe Layer", value: "P48" },
  { label: "Planning Engineer", value: "575" },
  { label: "Planning Manager", value: "930" },
  { label: "Planning Officer", value: "539" },
  { label: "Planning Technician", value: "931" },
  { label: "Plant Attendant", value: "932" },
  { label: "Plant Breeder", value: "10C" },
  { label: "Plant Driver", value: "D33" },
  { label: "Plant Engineer", value: "933" },
  { label: "Plant Fitter", value: "934" },
  { label: "Plant Manager", value: "442" },
  { label: "Plant Operator", value: "935" },
  { label: "Plasterer", value: "P18" },
  { label: "Plastics Consultant", value: "484" },
  { label: "Plastics Engineer", value: "540" },
  { label: "Plate Layer", value: "936" },
  { label: "Plater", value: "443" },
  { label: "Playgroup Assistant", value: "541" },
  { label: "Playgroup Leader", value: "444" },
  { label: "Playwright", value: "11C" },
  { label: "Plumber", value: "P19" },
  { label: "Plumbing & Heating Engineer", value: "C98" },
  { label: "Podiatrist", value: "C95" },
  { label: "Points Person", value: "36C" },
  { label: "Police - Civilian", value: "12C" },
  { label: "Police Community Support Officer", value: "P53" },
  { label: "Police Inspector", value: "56L" },
  { label: "Police Officer", value: "P20" },
  { label: "Polisher", value: "937" },
  { label: "Pool Attendant", value: "242" },
  { label: "Port Officer", value: "31D" },
  { label: "Porter", value: "P21" },
  { label: "Portfolio Manager", value: "938" },
  { label: "Post Card Seller", value: "939" },
  // { label: "Post Graduate Student Living at Home", value: "18D" },
  // { label: "Post Graduate Student Living Away from Home", value: "19D" },
  { label: "Post Office Counter Clerk", value: "244" },
  { label: "Post Sorter", value: "940" },
  { label: "Postmaster or Mistress", value: "B27" },
  { label: "Postperson", value: "245" },
  { label: "Potato Merchant", value: "759" },
  { label: "Potter", value: "P24" },
  { label: "Poultry Worker", value: "15C" },
  { label: "Practice Manager", value: "445" },
  { label: "Preacher", value: "941" },
  { label: "Precious Metal Merchant", value: "16C" },
  { label: "Precision Engineer", value: "446" },
  { label: "Premises Security Installers", value: "B65" },
  { label: "Press Correspondent", value: "83A" },
  { label: "Press Officer", value: "576" },
  { label: "Press Operator", value: "510" },
  { label: "Press Photographer - Freelance", value: "35D" },
  { label: "Press Photographer - National", value: "36D" },
  { label: "Press Producer", value: "17C" },
  { label: "Press Setter", value: "942" },
  { label: "Presser", value: "943" },
  { label: "Priest or Reverend", value: "247" },
  { label: "Principal", value: "19C" },
  { label: "Print Finisher", value: "248" },
  { label: "Printer", value: "P25" },
  { label: "Printing Press Operator", value: "20C" },
  { label: "Prison Chaplain", value: "944" },
  { label: "Prison Officer", value: "P26" },
  { label: "Private Detective", value: "17D" },
  { label: "Private Investigator", value: "P44" },
  { label: "Privy Councillor", value: "21C" },
  { label: "Probation Officer", value: "P27" },
  { label: "Probation Worker", value: "945" },
  { label: "Process Engineer", value: "485" },
  { label: "Process Operator", value: "946" },
  { label: "Process Worker", value: "P28" },
  { label: "Procurator Fiscal", value: "250" },
  { label: "Produce Supervisor", value: "447" },
  { label: "Producer", value: "P29" },
  { label: "Product Designer", value: "22C" },
  { label: "Product Installer", value: "948" },
  { label: "Product Manager", value: "448" },
  { label: "Production Engineer", value: "449" },
  { label: "Production Hand", value: "949" },
  { label: "Production Manager", value: "M52" },
  { label: "Production Planner", value: "542" },
  { label: "Professional Gamer", value: "68S" },
  { label: "Professor", value: "251" },
  { label: "Progress Chaser", value: "950" },
  { label: "Progress Clerk", value: "252" },
  { label: "Project Co-ordinator", value: "951" },
  { label: "Project Engineer", value: "253" },
  { label: "Project Leader", value: "577" },
  { label: "Project Manager", value: "254" },
  { label: "Project Worker", value: "952" },
  { label: "Projectionist", value: "P35" },
  { label: "Promoter", value: "P36" },
  { label: "Promoter - Entertainments", value: "40D" },
  { label: "Promoter - Racing", value: "P37" },
  { label: "Promoter - Ring Sports", value: "P38" },
  { label: "Promoter - Sports", value: "41D" },
  { label: "Proof Reader", value: "511" },
  { label: "Property Buyer", value: "255" },
  { label: "Property Dealer", value: "256" },
  { label: "Property Developer", value: "P49" },
  { label: "Property Manager", value: "257" },
  { label: "Property Valuer", value: "258" },
  { label: "Proprietor", value: "259" },
  { label: "Prosthetist", value: "P51" },
  { label: "Psychiatrist", value: "260" },
  { label: "Psychoanalyst", value: "A90" },
  { label: "Psychodynamic Counsellor", value: "953" },
  { label: "Psychologist", value: "261" },
  { label: "Psychotherapist", value: "262" },
  { label: "Public House Manager", value: "486" },
  { label: "Public Relations Officer", value: "P39" },
  { label: "Publican", value: "P42" },
  { label: "Publicity Manager", value: "450" },
  { label: "Publisher", value: "P40" },
  { label: "Publishing Manager", value: "760" },
  { label: "Purchase Clerk", value: "954" },
  { label: "Purchase Ledger Clerk", value: "955" },
  { label: "Purchasing Assistant", value: "578" },
  { label: "Purchasing Manager", value: "956" },
  { label: "Purser", value: "263" },
  { label: "Quality Controller", value: "Q01" },
  { label: "Quality Engineer", value: "543" },
  { label: "Quality Inspector", value: "957" },
  { label: "Quality Manager", value: "544" },
  { label: "Quality Technician", value: "545" },
  { label: "Quantity Surveyor", value: "Q02" },
  { label: "Quarry Worker", value: "264" },
  { label: "Queens Counsel", value: "265" },
  { label: "Rabbi", value: "266" },
  { label: "Racehorse Groom", value: "761" },
  { label: "Racing Motorcyclist", value: "29C" },
  { label: "Racing Organiser", value: "R01" },
  { label: "Radar Mechanic", value: "30C" },
  { label: "Radio Controller", value: "762" },
  { label: "Radio Director", value: "267" },
  { label: "Radio Engineer", value: "958" },
  { label: "Radio Helpline Coordinator", value: "31C" },
  { label: "Radio Mechanic", value: "32C" },
  { label: "Radio Operator", value: "579" },
  { label: "Radio Presenter", value: "268" },
  { label: "Radio Producer", value: "269" },
  { label: "Radiographer", value: "R02" },
  { label: "Radiologist", value: "451" },
  { label: "Radiotherapist", value: "33C" },
  { label: "Rail Person", value: "34C" },
  { label: "Rally Driver", value: "959" },
  { label: "Ramp Agent", value: "960" },
  { label: "Re-Settlement Officer", value: "R14" },
  { label: "Reactor Attendant", value: "38C" },
  { label: "Reader", value: "40C" },
  { label: "Reader Compositor", value: "39C" },
  { label: "Receptionist", value: "R04" },
  { label: "Records Supervisor", value: "961" },
  { label: "Recovery Vehicle Co-ordinator", value: "962" },
  { label: "Recruitment Consultant", value: "R13" },
  { label: "Rector", value: "270" },
  { label: "Referee", value: "R15" },
  { label: "Refit Merchandiser", value: "494" },
  { label: "Reflexologist", value: "764" },
  { label: "Refractory Engineer", value: "963" },
  { label: "Refrigeration Engineer", value: "763" },
  { label: "Refuse Collector", value: "R05" },
  { label: "Registered Disabled", value: "42D" },
  { label: "Registrar", value: "R06" },
  { label: "Regulator", value: "964" },
  { label: "Religious Dignitary", value: "52K" },
  { label: "Religious Leader", value: "52J" },
  { label: "Relocation Agent", value: "965" },
  { label: "Remedial Therapist", value: "966" },
  { label: "Rent Collector", value: "765" },
  { label: "Rent Officer", value: "D67" },
  { label: "Repairer", value: "967" },
  { label: "Reporter", value: "R07" },
  { label: "Reporter - Freelance", value: "44D" },
  { label: "Reprographic Assistant", value: "968" },
  { label: "Rescue Worker", value: "90B" },
  { label: "Research Analyst", value: "969" },
  { label: "Research Assistant", value: "43C" },
  { label: "Research Consultant", value: "C65" },
  { label: "Research Director", value: "452" },
  { label: "Research Scientist", value: "487" },
  { label: "Research Technician", value: "453" },
  { label: "Researcher", value: "R08" },
  { label: "Resin Caster", value: "A81" },
  { label: "Restaurant Manager", value: "271" },
  { label: "Restaurateur", value: "272" },
  { label: "Restorer", value: "273" },
  // { label: "Retired", value: "R09" },
  { label: "Revenue Clerk", value: "970" },
  { label: "Revenue Officer", value: "454" },
  { label: "Rheumatologist", value: "44C" },
  { label: "Rig Worker", value: "R10" },
  { label: "Rigger", value: "275" },
  { label: "Riverperson", value: "45C" },
  { label: "Riveter", value: "276" },
  { label: "Road Safety Officer", value: "B28" },
  { label: "Road Sweeper", value: "389" },
  { label: "Road Worker", value: "277" },
  { label: "Roof Tiler", value: "580" },
  { label: "Roofer", value: "R12" },
  { label: "Rose Grower", value: "971" },
  { label: "Royal Air Force - NCO/Commissioned Officer", value: "A30" },
  { label: "Royal Air Force - Other Ranks", value: "A29" },
  { label: "Royal Marine", value: "278" },
  { label: "Royal Navy - NCO/Commissioned Officer", value: "N07" },
  { label: "Royal Navy - Other Ranks", value: "N06" },
  { label: "Rubber Worker", value: "46C" },
  { label: "Rug Maker", value: "C66" },
  { label: "Rugby Player", value: "27C" },
  { label: "Saddler", value: "279" },
  { label: "Safety Officer", value: "512" },
  { label: "Sail Maker", value: "280" },
  { label: "Sales Administrator", value: "455" },
  { label: "Sales Assistant", value: "S04" },
  { label: "Sales Director", value: "456" },
  { label: "Sales Engineer", value: "E18" },
  { label: "Sales Executive", value: "281" },
  { label: "Sales Manager", value: "282" },
  { label: "Sales Representative", value: "283" },
  { label: "Sales Support", value: "457" },
  { label: "Salesperson", value: "284" },
  { label: "Salvage Dealer", value: "47C" },
  { label: "Salvage Hand", value: "48C" },
  { label: "Salvation Army Officer", value: "56H" },
  { label: "Sample Hand", value: "49C" },
  { label: "Sand Blaster", value: "285" },
  { label: "Sand Merchant", value: "50C" },
  { label: "Saw Miller", value: "286" },
  { label: "Sawyer", value: "51C" },
  { label: "Scaffolder", value: "S05" },
  { label: "Scenehand", value: "52C" },
  { label: "School Catering Assistant", value: "112" },
  { label: "School Counsellor", value: "84A" },
  { label: "School Crossing Warden", value: "458" },
  { label: "School Inspector", value: "B68" },
  // { label: "School Student", value: "74C" },
  { label: "Scientific Officer", value: "766" },
  { label: "Scientist", value: "S06" },
  { label: "Scientist - Atomic Energy", value: "22D" },
  { label: "Scrap Dealer", value: "287" },
  { label: "Screen Printer", value: "288" },
  { label: "Screen Writer", value: "289" },
  { label: "Script Writer", value: "S07" },
  { label: "Sculptor", value: "S08" },
  { label: "Sea Person", value: "290" },
  { label: "Search Engine Optimization Analyst/Consultant/Executive", value: "67S" },
  { label: "Search Engine Optimization Manager", value: "55S" },
  { label: "Second Hand Dealer", value: "292" },
  { label: "Secretary", value: "S09" },
  { label: "Secretary And PA", value: "459" },
  { label: "Security Consultant", value: "C67" },
  { label: "Security Controller", value: "546" },
  { label: "Security Guard", value: "S10" },
  { label: "Security Officer", value: "S11" },
  { label: "Seedsperson", value: "293" },
  { label: "Servant", value: "294" },
  { label: "Service Engineer", value: "E19" },
  { label: "Service Engineer (Non-Mobile)", value: "E20" },
  { label: "Service Manager", value: "547" },
  { label: "Setter", value: "55C" },
  { label: "Sewage Worker", value: "56C" },
  { label: "Share Dealer", value: "460" },
  { label: "Sheet Metal Worker", value: "295" },
  { label: "Shelf Filler", value: "296" },
  { label: "Shelter Warden", value: "767" },
  { label: "Shepherd or Shepherdess", value: "297" },
  { label: "Sheriff", value: "298" },
  { label: "Sheriff Clerk", value: "299" },
  { label: "Sheriff Principal", value: "300" },
  { label: "Sheriffs Officer", value: "301" },
  { label: "Shift Controller", value: "581" },
  { label: "Ship Broker", value: "B83" },
  { label: "Ship Builder", value: "302" },
  { label: "Shipping Clerk", value: "C23" },
  { label: "Shipping Officer", value: "488" },
  { label: "Shipwright", value: "303" },
  { label: "Shipyard Worker", value: "S13" },
  { label: "Shoe Maker", value: "B69" },
  { label: "Shoe Repairer", value: "304" },
  { label: "Shooting Instructor", value: "768" },
  { label: "Shop Assistant", value: "S14" },
  { label: "Shop Fitter", value: "S17" },
  { label: "Shop Keeper", value: "305" },
  { label: "Shop Manager", value: "306" },
  { label: "Shorthand Writer", value: "58C" },
  { label: "Shot Blaster", value: "307" },
  { label: "Show Jumper", value: "B72" },
  { label: "Showperson", value: "S39" },
  { label: "Shunter", value: "308" },
  { label: "Sign Maker", value: "A82" },
  { label: "Signalperson", value: "309" },
  { label: "Signwriter", value: "S18" },
  { label: "Silversmith", value: "59C" },
  { label: "Singer", value: "60C" },
  { label: "Site Agent", value: "548" },
  { label: "Site Engineer", value: "461" },
  { label: "Skipper", value: "549" },
  { label: "Slater", value: "310" },
  { label: "Slaughterperson", value: "311" },
  { label: "Smallholder", value: "312" },
  { label: "Smelter", value: "61C" },
  { label: "Snooker Player", value: "28C" },
  { label: "Social Media Influencer", value: "S56" },
  { label: "Social Worker", value: "S19" },
  { label: "Sociologist", value: "63C" },
  { label: "Software Consultant", value: "462" },
  { label: "Software Engineer", value: "463" },
  { label: "Solar Panel Installer And Repairer", value: "D96" },
  { label: "Soldier", value: "313" },
  { label: "Solicitor", value: "S20" },
  { label: "Song Writer", value: "314" },
  { label: "Sorter", value: "S21" },
  { label: "Sound Editor", value: "64C" },
  { label: "Sound Engineer", value: "315" },
  { label: "Sound Mixer", value: "65C" },
  { label: "Sound Technician", value: "550" },
  { label: "Special Constable", value: "769" },
  { label: "Special Needs Assistant", value: "B32" },
  { label: "Speech Therapist", value: "316" },
  { label: "Sports Administrator", value: "66C" },
  { label: "Sports Agent", value: "S54" },
  { label: "Sports Centre Attendant", value: "S40" },
  { label: "Sports Coach", value: "317" },
  { label: "Sports Commentator", value: "770" },
  { label: "Sports Equipment Repairer", value: "67C" },
  { label: "Sports Scout", value: "S55" },
  { label: "Spring Maker", value: "582" },
  { label: "Stable Hand", value: "S24" },
  { label: "Staff Nurse", value: "489" },
  { label: "Stage Director", value: "320" },
  { label: "Stage Manager", value: "322" },
  { label: "Stage Mover", value: "323" },
  { label: "Stamp Dealer", value: "68C" },
  { label: "State Enrolled Nurse", value: "69C" },
  { label: "State Registered Nurse", value: "70C" },
  { label: "Station Manager", value: "M20" },
  { label: "Stationer", value: "S43" },
  { label: "Statistician", value: "S25" },
  { label: "Steel Erector", value: "324" },
  { label: "Steel Worker", value: "S41" },
  { label: "Steeplejack", value: "S26" },
  { label: "Stenographer", value: "513" },
  { label: "Stevedore", value: "S27" },
  { label: "Steward or Stewardess", value: "325" },
  { label: "Stock Controller", value: "S29" },
  { label: "Stock Exchange Dealer", value: "97A" },
  { label: "Stock Manager", value: "551" },
  { label: "Stockbroker", value: "S30" },
  { label: "Stockperson", value: "327" },
  { label: "Stocktaker", value: "771" },
  { label: "Stone Cutter", value: "552" },
  { label: "Stone Sawyer", value: "B77" },
  { label: "Stonemason", value: "S31" },
  { label: "Store Detective", value: "587" },
  { label: "Store Keeper", value: "72C" },
  { label: "Storeperson", value: "328" },
  { label: "Stores Controller", value: "73C" },
  { label: "Street Entertainer", value: "D71" },
  { label: "Street Trader", value: "S33" },
  { label: "Stud Hand", value: "330" },
  // { label: "Student - Living at Home", value: "S44" },
  // { label: "Student - Living Away", value: "S45" },
  { label: "Student Counseller", value: "85A" },
  // { label: "Student Nurse - Living At Home", value: "S52" },
  // { label: "Student Nurse - Living Away", value: "S53" },
  // { label: "Student Teacher - Living At Home", value: "S46" },
  // { label: "Student Teacher - Living Away", value: "S47" },
  { label: "Studio Manager", value: "553" },
  { label: "Sub-Postmaster or Mistress", value: "B33" },
  { label: "Supervisor", value: "S42" },
  { label: "Supply Teacher", value: "514" },
  { label: "Support Worker", value: "77C" },
  { label: "Surgeon", value: "S36" },
  { label: "Surgeon - N.H.S.", value: "45D" },
  { label: "Surgeon - Non  N.H.S.", value: "46D" },
  { label: "Surveyor", value: "S37" },
  { label: "Surveyor - Chartered", value: "S38" },
  { label: "Sustainability Specialist", value: "69S" },
  { label: "Systems Analyst", value: "332" },
  { label: "Systems Engineer", value: "584" },
  { label: "Systems Manager", value: "464" },
  { label: "T-Shirt Printer", value: "C68" },
  { label: "Tachograph Analyst", value: "C86" },
  { label: "Tacker", value: "554" },
  { label: "Tailor", value: "T01" },
  { label: "Tank Farm Operative", value: "B88" },
  { label: "Tanker Driver", value: "976" },
  { label: "Tanner", value: "333" },
  { label: "Tape Librarian", value: "78C" },
  { label: "Tape Operator", value: "79C" },
  { label: "Tarmacer", value: "977" },
  { label: "Tarot Reader/Palmistry Expert", value: "D74" },
  { label: "Taster", value: "31A" },
  { label: "Tattooist", value: "772" },
  { label: "Tax Advisor", value: "978" },
  { label: "Tax Analyst", value: "490" },
  { label: "Tax Assistant", value: "979" },
  { label: "Tax Consultant", value: "334" },
  { label: "Tax Inspector", value: "I08" },
  { label: "Tax Manager", value: "980" },
  { label: "Tax Officer", value: "981" },
  { label: "Taxi Controller", value: "982" },
  { label: "Taxi Driver", value: "D34" },
  { label: "Taxidermist", value: "T02" },
  { label: "Tea Blender", value: "335" },
  { label: "Tea Taster", value: "336" },
  { label: "Teacher", value: "T03" },
  { label: "Teachers Assistant", value: "465" },
  { label: "Technical Advisor", value: "983" },
  { label: "Technical Analyst", value: "984" },
  { label: "Technical Assistant", value: "985" },
  { label: "Technical Author", value: "337" },
  { label: "Technical Clerk", value: "986" },
  { label: "Technical Co-ordinator", value: "987" },
  { label: "Technical Director", value: "988" },
  { label: "Technical Editor", value: "989" },
  { label: "Technical Engineer", value: "990" },
  { label: "Technical Illustrator", value: "773" },
  { label: "Technical Instructor", value: "774" },
  { label: "Technical Liaison Engineer", value: "991" },
  { label: "Technical Manager", value: "992" },
  { label: "Technician", value: "14C" },
  { label: "Technician - Performing Arts", value: "T04" },
  { label: "Technologist", value: "80C" },
  { label: "Telecommunication Consultant", value: "D65" },
  { label: "Telecommunications Engineer", value: "338" },
  { label: "Telecommunications Manager", value: "993" },
  { label: "Telemarketeer", value: "995" },
  { label: "Telephone Engineer", value: "339" },
  { label: "Telephonist", value: "T05" },
  { label: "Telesales Person", value: "S02" },
  { label: "Television Director", value: "340" },
  { label: "Television Engineer", value: "341" },
  { label: "Television Presenter", value: "342" },
  { label: "Television Producer", value: "343" },
  { label: "Television Set Builder", value: "92C" },
  { label: "Temperature Time Recorder", value: "996" },
  { label: "Tennis Coach", value: "997" },
  { label: "Tennis Player", value: "52G" },
  { label: "Terrier", value: "D86" },
  { label: "Territorial Army Officer", value: "56G" },
  { label: "Test Driver", value: "81C" },
  { label: "Test Engineer", value: "82C" },
  { label: "Textile Consultant", value: "998" },
  { label: "Textile Engineer", value: "999" },
  { label: "Textile Technician", value: "A35" },
  { label: "Textile Worker", value: "T06" },
  { label: "Thatcher", value: "344" },
  { label: "Theatre Director", value: "07B" },
  { label: "Theatre Manager", value: "345" },
  { label: "Theatrical Agent", value: "346" },
  { label: "Theme Park Worker", value: "83C" },
  { label: "Therapist", value: "466" },
  { label: "Thermal Engineer", value: "491" },
  { label: "Thermal Insulator", value: "A37" },
  { label: "Ticket Agent", value: "C88" },
  { label: "Ticket Collector", value: "37C" },
  { label: "Ticket Inspector", value: "T07" },
  { label: "Tiler", value: "T24" },
  { label: "Timber Inspector", value: "A38" },
  { label: "Timber Worker", value: "A39" },
  { label: "Time and Motion Analyst", value: "85C" },
  { label: "Tobacconist", value: "T08" },
  { label: "Toll Collector", value: "972" },
  { label: "Tool Maker", value: "T09" },
  { label: "Tool Setter", value: "382" },
  { label: "Tour Agent", value: "A40" },
  { label: "Tour Guide", value: "347" },
  { label: "Town Clerk", value: "A41" },
  { label: "Town Planner", value: "467" },
  { label: "Toy Maker", value: "T10" },
  { label: "Toy Trader", value: "A83" },
  { label: "Track Worker", value: "A42" },
  { label: "Tractor Driver", value: "A43" },
  { label: "Tractor Mechanic", value: "A44" },
  { label: "Trade Mark Agent", value: "A45" },
  { label: "Trade Union Official", value: "T23" },
  { label: "Trading Standards Officer", value: "348" },
  { label: "Traffic Clerk", value: "A46" },
  { label: "Traffic Despatcher", value: "04B" },
  { label: "Traffic Engineer", value: "A47" },
  { label: "Traffic Officer", value: "A48" },
  { label: "Traffic Planner", value: "A49" },
  { label: "Traffic Supervisor", value: "A50" },
  { label: "Traffic Warden", value: "T11" },
  { label: "Train Despatcher", value: "05B" },
  { label: "Train Driver", value: "D35" },
  { label: "Train Motor Operative", value: "87B" },
  { label: "Trainee Manager", value: "555" },
  { label: "Trainer", value: "T12" },
  { label: "Training Advisor", value: "A51" },
  { label: "Training Assistant", value: "A52" },
  { label: "Training Co-ordinator", value: "A54" },
  { label: "Training Consultant", value: "A53" },
  { label: "Training Instructor", value: "A55" },
  { label: "Training Manager", value: "468" },
  { label: "Training Officer", value: "T16" },
  { label: "Transcriber", value: "A56" },
  { label: "Translator", value: "T17" },
  { label: "Transmission Controller", value: "86C" },
  { label: "Transport Clerk", value: "A57" },
  { label: "Transport Consultant", value: "A58" },
  { label: "Transport Controller", value: "469" },
  { label: "Transport Engineer", value: "470" },
  { label: "Transport Manager", value: "M21" },
  { label: "Transport Officer", value: "349" },
  { label: "Transport Planner", value: "A59" },
  { label: "Transport Police Officer", value: "87C" },
  { label: "Travel Agent", value: "T18" },
  { label: "Travel Clerk", value: "A60" },
  { label: "Travel Consultant", value: "350" },
  { label: "Travel Courier", value: "351" },
  { label: "Travel Guide", value: "A61" },
  { label: "Travel Guide Writer", value: "A62" },
  { label: "Travel Representative", value: "A63" },
  { label: "Travelling Salesman/Woman", value: "88C" },
  { label: "Trawler Hand", value: "89C" },
  { label: "Treasurer", value: "A64" },
  { label: "Tree Feller", value: "352" },
  { label: "Tree Surgeon", value: "T20" },
  { label: "Trichologist", value: "C87" },
  { label: "Trinity House Pilot", value: "T21" },
  { label: "Trout Farmer", value: "A65" },
  { label: "Truck Driver", value: "90C" },
  { label: "Tug Skipper", value: "776" },
  { label: "Tunneller", value: "353" },
  { label: "Turf Accountant", value: "A03" },
  { label: "Turkey Farmer", value: "A66" },
  { label: "Turner", value: "354" },
  { label: "Tutor", value: "355" },
  { label: "TV And Video Installer", value: "A67" },
  { label: "TV And Video Repairer", value: "A68" },
  { label: "TV Announcer", value: "91C" },
  { label: "TV Broadcasting Technician", value: "47D" },
  { label: "TV Editor", value: "A69" },
  { label: "Typesetter", value: "356" },
  { label: "Typewriter Engineer", value: "A70" },
  { label: "Typist", value: "T22" },
  { label: "Typographer", value: "93C" },
  { label: "Tyre Builder", value: "A71" },
  { label: "Tyre Fitter", value: "357" },
  { label: "Tyre Inspector", value: "A72" },
  { label: "Tyre Technician", value: "A73" },
  { label: "Tyre/Exhaust Fitter", value: "25D" },
  { label: "Umpire", value: "U06" },
  // { label: "Undergraduate Student - Living At Home", value: "48D" },
  // { label: "Undergraduate Student - Living Away from Home", value: "49D" },
  { label: "Undertaker", value: "U01" },
  { label: "Underwriter", value: "U02" },
  // { label: "Unemployed", value: "U03" },
  { label: "University Dean", value: "16D" },
  { label: "University Reader", value: "94C" },
  { label: "Upholsterer", value: "U04" },
  { label: "Usher", value: "U05" },
  { label: "Valet", value: "95C" },
  { label: "Valuer", value: "V01" },
  { label: "Valve Technician", value: "777" },
  { label: "Van Driver", value: "D46" },
  { label: "Van Salesperson", value: "96C" },
  { label: "VDU Operator", value: "V02" },
  { label: "Vehicle Assessor", value: "358" },
  { label: "Vehicle Bodybuilder", value: "68A" },
  { label: "Vehicle Delivery Agent", value: "97C" },
  { label: "Vehicle Engineer", value: "E21" },
  { label: "Vehicle Technician", value: "471" },
  { label: "Vehicle Valet", value: "V05" },
  { label: "Vending Machine Filler", value: "778" },
  { label: "Vending Machine Technician", value: "779" },
  { label: "Ventriloquist", value: "D72" },
  { label: "Verger", value: "472" },
  { label: "Veterinary Assistant", value: "V07" },
  { label: "Veterinary Nurse", value: "98C" },
  { label: "Veterinary Surgeon", value: "V04" },
  { label: "Vicar", value: "359" },
  { label: "Violin Maker", value: "492" },
  { label: "Vision Control Manager", value: "02D" },
  { label: "Vision Mixer", value: "03D" },
  { label: "Voluntary Worker", value: "V06" },
  { label: "Vulcanologist", value: "04D" },
  { label: "Wages Clerk", value: "C24" },
  { label: "Waiter or Waitress", value: "360" },
  { label: "Warden", value: "05D" },
  { label: "Wardrobe Mistress", value: "06D" },
  { label: "Warehouse Manager", value: "473" },
  { label: "Warehouse Operative", value: "362" },
  { label: "Waste Dealer", value: "364" },
  { label: "Waste Disposal Worker", value: "07D" },
  { label: "Waste Paper Merchant", value: "55D" },
  { label: "Watchmaker", value: "W02" },
  { label: "Water Diviner", value: "D87" },
  { label: "Weaver", value: "365" },
  { label: "Web Designer", value: "W09" },
  { label: "Web Developer", value: "50D" },
  { label: "Web Programmer", value: "51D" },
  { label: "Weighbridge Clerk", value: "780" },
  { label: "Weighbridge Operator", value: "781" },
  { label: "Welder", value: "W04" },
  { label: "Welfare Assistant", value: "585" },
  { label: "Welfare Officer", value: "366" },
  { label: "Welfare Rights Officer", value: "474" },
  { label: "Wheel Clamper", value: "367" },
  { label: "Wheelwright", value: "08D" },
  { label: "Whisky Blender", value: "09D" },
  { label: "Wholesale Newspaper Delivery Driver", value: "D36" },
  { label: "Will Writer", value: "10D" },
  { label: "Window Cleaner", value: "W05" },
  { label: "Window Dresser", value: "368" },
  { label: "Windscreen Fitter", value: "F22" },
  { label: "Wine Merchant", value: "369" },
  { label: "Wood Carver", value: "370" },
  { label: "Wood Cutter", value: "371" },
  { label: "Wood Machinist", value: "11D" },
  { label: "Wood Worker", value: "372" },
  { label: "Word Processing Operator", value: "C69" },
  { label: "Work Study Analyst", value: "12D" },
  { label: "Works Manager", value: "586" },
  { label: "Wrestler", value: "52E" },
  { label: "Writer", value: "W06" },
  { label: "Yacht Master", value: "B63" },
  { label: "Yard Manager", value: "556" },
  { label: "Yard Person", value: "13D" },
  { label: "Yoga Teacher", value: "Y01" },
  { label: "Youth Hostel Warden", value: "475" },
  { label: "Youth Worker", value: "373" },
  { label: "Zoo Keeper", value: "Z01" },
  { label: "Zoo Manager", value: "B64" },
  { label: "Zoologist", value: "374" },
  { label: "Zoology Consultant", value: "C89" },
];
