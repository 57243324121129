export const RelationshipToProposer = [
  {
    "label": "Brother or Sister",
    "value": "A"
  },
  {
    "label": "Business Partner",
    "value": "C"
  },
  {
    "label": "Daughter in Law or Son in Law",
    "value": "G"
  },
  {
    "label": "Daughter Or Son",
    "value": "O"
  },
  {
    "label": "Director",
    "value": "D"
  },
  {
    "label": "Employee Of Proposer",
    "value": "E"
  },
  {
    "label": "Employer Of Proposer",
    "value": "B"
  },
  {
    "label": "Family",
    "value": "F"
  },
  {
    "label": "Grandchild",
    "value": "K"
  },
  {
    "label": "Grandparent",
    "value": "R"
  },
  {
    "label": "Guardian",
    "value": "1"
  },
  {
    "label": "Lodger",
    "value": "L"
  },
  {
    "label": "Not Applicable",
    "value": "Z"
  },
  {
    "label": "Partner - Civil",
    "value": "J"
  },
  {
    "label": "Partner - Common Law",
    "value": "W"
  },
  {
    "label": "Partner Of Family",
    "value": "V"
  },
  {
    "label": "Proposer",
    "value": "P"
  },
  {
    "label": "Sister In Law Or Brother In Law",
    "value": "H"
  },
  {
    "label": "Spouse",
    "value": "S"
  },
  {
    "label": "Tenant",
    "value": "T"
  },
  {
    "label": "Unrelated",
    "value": "U"
  },
  {
    "label": "Volunteer Of Proposer",
    "value": "X"
  }
]