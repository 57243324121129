export const MaritalStatus = [
  {
    "label": "Civil Partnership",
    "value": "B"
  },
  {
    "label": "Divorced",
    "value": "D"
  },
  {
    "label": "Living with Partner/Common Law",
    "value": "C"
  },
  {
    "label": "Married",
    "value": "M"
  },
  {
    "label": "Not Applicable",
    "value": "N"
  },
  {
    "label": "Single",
    "value": "S"
  },
  {
    "label": "Widowed",
    "value": "W"
  }
]