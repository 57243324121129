import React, { createContext, useContext, useLayoutEffect, useState } from 'react';

export interface TGroupsFieldContext {
  openAccordion: (id: string) => void;
  closeAccordion: () => void;
  isOpen: (id: string) => boolean;
  groupIds?: string[];
  completed?: boolean[];
  markCompleted?: (index: number) => void;
  markUnCompleted?: (index: number) => void;
}

export const GroupsFieldContext = createContext<TGroupsFieldContext | undefined>(undefined);

export const GroupsFieldProvider = ({ children, groupIds }: { groupIds?: string[]; children: any }) => {
  const [openAccordionId, setOpenAccordionId] = useState<string | null>(groupIds?.[0] || null);
  const [completed, setCompleted] = useState(Array(groupIds?.length).fill(false));

  useLayoutEffect(() => {
    setOpenAccordionId(groupIds?.[0] || null);
  }, [groupIds]);

  const openAccordion = (id: string): void => {
    setOpenAccordionId(id);
  };

  const closeAccordion = (): void => {
    setOpenAccordionId(null);
  };

  const markCompleted = (index: number) => {
    const updated = completed;
    completed[index] = true;
    setCompleted(updated);
  };
  const markUnCompleted = (index: number) => {
    const updated = completed;
    completed[index] = false;
    setCompleted(updated);
  };

  const isOpen = (id: string): boolean => {
    return openAccordionId === id;
  };

  return (
    <GroupsFieldContext.Provider value={{ openAccordion, closeAccordion, isOpen, groupIds, markCompleted, completed,markUnCompleted }}>
      {children}
    </GroupsFieldContext.Provider>
  );
};

export const useGroupsField = (): TGroupsFieldContext => {
  const context = useContext(GroupsFieldContext);
  if (!context) {
    throw new Error('useGroupsField must be used within an GroupsFieldProvider');
  }
  return context;
};
