import {
  // Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
  TextField,
} from '@mui/material';
import { TFieldChangeHandler, TFormData, TFormField, TJourneyConfig } from '../../types';
import { useContext, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { toTitleCase } from '../../utils';
import SearchIcon from '@mui/icons-material/Search';
import axios from 'axios';
import { isBlacklistedPostcode, isValidPostcode, validateField } from '../../fieldValidation';
import { getCedarPostcode, postcodeSearch } from '../../apiCalls';
import { JourneyContext } from '../../JourneyContext';
import { renderFields } from './fields';

// ----------------------------------------------------------------------------
// AddressField types
// ----------------------------------------------------------------------------

type LookupResult = {
  error?: ErrorResult;
  address?: AddressResult;
};

type ErrorResult = {
  error_code: string;
  error_msg: string;
};

// ----------------------------------------------------------------------------
// Fetchify types
// ----------------------------------------------------------------------------

type AddressResult = {
  delivery_points: DeliveryPoint[];
  delivery_point_count: number;
  postal_county: string;
  traditional_county: string;
  town: string;
  postcode: string;
};

type DeliveryPoint = {
  organisation_name: string;
  department_name: string;
  line_1: string;
  line_2: string;
  udprn: string;
  dps: string;
};

// ----------------------------------------------------------------------------
// Create an address string
// ----------------------------------------------------------------------------

function buildAddressString(dp: DeliveryPoint, town: string): string {
  let addressString = '';
  if (dp.organisation_name.length) {
    addressString.length && (addressString += ', ');
    addressString += dp.organisation_name;
  }
  if (dp.line_1.length) {
    addressString.length && (addressString += ', ');
    addressString += dp.line_1;
  }
  if (dp.line_2.length) {
    addressString.length && (addressString += ', ');
    addressString += dp.line_2;
  }
  if (town.length) {
    addressString.length && (addressString += ', ');
    addressString += town;
  }
  return toTitleCase(addressString);
}

// ============================================================================
// Main component
// ============================================================================

export const AddressField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
  config: TJourneyConfig;
  formData: TFormData;
  validations?: any;
}) => {
  const journeyContext = useContext(JourneyContext);
  // --------------------------------------------------------------------------
  // Component state and variables
  // --------------------------------------------------------------------------

  const { field, value, changeHandler, config, validations, formData } = props;
  const [busy, setBusy] = useState(false);
  const [postCode, setPostCode] = useState(value?.postcode || '');
  const [lookupResult, setLookupResult] = useState<LookupResult>({});
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [line1Error, setLine1Error] = useState('');
  const [townError, setTownError] = useState('');
  const [countyError, setCountyError] = useState('');
  const [cedarError, setCedarError] = useState('');
  const [localFormData, setLocalFormData] = useState<TFormData>({
    values: { ...value },
    validations: { ...props.validations },
  });

  const postCodeBlacklist = field.addressParams?.postCodeBlacklist ?? [];

  const closeDialog = () => {
    setDialogOpen(false);
  };

  const openDialog = () => {
    setDialogOpen(true);
  };

  const options =
    lookupResult.address?.delivery_points.map((dp, index) => {
      return {
        id: index,
        label: buildAddressString(dp, lookupResult.address?.town || ''),
      };
    }) || [];

  // --------------------------------------------------------------------------
  // Call to Fetchify API
  // --------------------------------------------------------------------------
  const postCodeCheckConditions = {
    condition: field?.cedarPostCodeCheckCondition,
    attribute: field?.cedarPostCodeCheckAttribute,
    value: field?.cedarPostCodeCheckValue,
  };
  async function lookup(): Promise<any> {
    setBusy(true);
    setLookupResult({ error: undefined, address: undefined });
    try {
      const { data } = await postcodeSearch(postCode);
      if (data.error_code || data.error_msg) {
        setLookupResult({ error: data as ErrorResult, address: undefined });
      } else {
        if (field.variant === 'checkCedarPostCode') {
          // checking cedar postcode
          const postcodeData = await getCedarPostcode(postCode, postCodeCheckConditions);
          if (!postcodeData && config?.referrals) {
            config.referrals['postcode'] = postCode;
          }
          if (postcodeData?.data?.decline) {
            setLookupResult({
              error: { error_code: '400', error_msg: postcodeData.data.message } as ErrorResult,
              address: undefined,
            });
          } else {
            setLookupResult({ address: data as AddressResult, error: undefined });
          }
        } else {
          setLookupResult({ address: data as AddressResult, error: undefined });
        }
      }
    } catch (e) {
      setLookupResult({
        error: { error_code: '', error_msg: 'There was a problem looking up your address.' },
        address: undefined,
      });
      console.log(e, '<=== Fetchify error');
    }
    setBusy(false);
  }

  const handlePostcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (lookupResult.error) {
      setLookupResult({ ...lookupResult, error: undefined });
    }
    setPostCode(event.target.value.toUpperCase());
  };

  // --------------------------------------------------------------------------
  // Rendering helpers
  // --------------------------------------------------------------------------

  const renderPostcodeLookup = () => {
    const validPostCode = isValidPostcode(postCode);

    return (
      <>
        <TextField
          autoComplete="autocomplete_off_randString"
          autoFocus
          disabled={field.disabled || busy}
          label="Postcode"
          variant="outlined"
          onChange={handlePostcodeChange}
          error={!validPostCode || Boolean(lookupResult.error?.error_msg?.length)}
          helperText={!validPostCode ? 'Postcode is invalid.' : lookupResult.error?.error_msg || ' '}
          value={postCode}
        />
        <LoadingButton
          loading={busy}
          variant="contained"
          onClick={lookup}
          disabled={!validPostCode || postCode.trim().length === 0}
          startIcon={<SearchIcon />}
        >
          Find address
        </LoadingButton>
      </>
    );
  };

  const selectAddress = (id: number) => {
    const deliveryPoint = lookupResult.address?.delivery_points[id];
    changeHandler(field, {
      line1: toTitleCase(deliveryPoint?.line_1 || ''),
      line2: toTitleCase(deliveryPoint?.line_2 || ''),
      town: toTitleCase(lookupResult.address?.town || ''),
      county: field.variant === 'includeCounty' ? toTitleCase(lookupResult.address?.postal_county || '') : '',
      postcode: lookupResult.address?.postcode?.toUpperCase() || '',
    });
    setLine1Error('');
    setCountyError('');
    setTownError('');
    closeDialog();
  };

  const renderAddressAutocomplete = () => {
    return (
      <>
        <p>
          Found {options.length} addresses at <b>{postCode}</b>.
        </p>
        <List
          sx={{
            border: '1px solid rgba(0,0,0,0.25)',
            width: '100%',
            position: 'relative',
            overflow: 'auto',
            maxHeight: 300,
            padding: 0,
            '& ul': { padding: 0, margin: 0 },
          }}
        >
          <ul>
            {options
              .sort((a, b) => a.label.localeCompare(b.label, undefined, { numeric: true }))
              .map((option) => (
                <ListItem key={option.id} sx={{ padding: 0 }}>
                  <ListItemButton onClick={() => selectAddress(option.id)}>
                    <ListItemText primary={option.label} />
                  </ListItemButton>
                </ListItem>
              ))}
          </ul>
        </List>
      </>
    );
  };

  const handlePostcodeOnBlur = async (e: any) => {
    setCedarError('');
    const value = e.target.value;
    if (field.variant === 'checkCedarPostCode' && value) {
      const response = await getCedarPostcode(value, postCodeCheckConditions);
      if (response?.data?.decline) {
        setCedarError(response?.data?.message);
      }
    }
  };
  const handlePostcodeOnChange = async (e: any) => {
    setCedarError('');
    const value = e.target.value;
    const joinedValue = value.replace(' ', '');
    if (field.variant === 'checkCedarPostCode' && joinedValue.length > 5) {
      const response = await getCedarPostcode(value, postCodeCheckConditions);
      if (response?.data?.decline) {
        setCedarError(response?.data?.message);
      }
    }
  };

  const localChangeHandler: TFieldChangeHandler = (extraField: TFormField, value: any) => {
    const fieldData = formData.values[field.name];
    fieldData[extraField.name] = value;
    changeHandler(field, fieldData);
  };
  const renderAddressFields = () => {
    const valid = isValidPostcode(value?.postcode || '');
    const blacklisted = !valid || !value?.postcode ? false : isBlacklistedPostcode(value.postcode, postCodeBlacklist);

    return (
      <>
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <p>
            {field.title}
            {field.required && ' *'}
          </p>
          <Button
            onClick={openDialog}
            variant="contained"
            size="medium"
            startIcon={<SearchIcon />}
            disabled={field.disabled || busy}
          >
            Find address
          </Button>
        </Stack>
        {field?.extraFields && (
          <JourneyContext.Provider
            value={{ ...journeyContext, formData: localFormData, setFormData: setLocalFormData }}
          >
            {renderFields(config, field?.extraFields, localFormData, localChangeHandler, false, 'DEFAULT')}
          </JourneyContext.Provider>
        )}
        <TextField
          autoComplete="autocomplete_off_randString"
          disabled={field.disabled || busy}
          label={'Address line 1'}
          value={value?.line1 || ''}
          error={Boolean(line1Error)}
          helperText={line1Error}
          required
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (value?.line1 !== undefined && event.target.value === '') {
              setLine1Error('This field is required.');
            } else {
              if (line1Error) {
                setLine1Error('');
              }
            }
            changeHandler(field, {
              ...value,
              line1: event.target.value,
              line2: value?.line2 || '',
            });
          }}
        />
        <TextField
          autoComplete="autocomplete_off_randString"
          disabled={field.disabled || busy}
          label={'Address line 2'}
          value={value?.line2 || ''}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            changeHandler(field, { ...value, line2: event.target.value })
          }
        />
        <TextField
          autoComplete="autocomplete_off_randString"
          disabled={field.disabled || busy}
          label={'Town'}
          value={value?.town || ''}
          error={Boolean(townError)}
          helperText={townError}
          required
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            if (value?.town !== undefined && event.target.value === '') {
              setTownError('This field is required.');
            } else {
              if (townError) {
                setTownError('');
              }
            }
            changeHandler(field, { ...value, town: event.target.value, line2: value?.line2 || '' });
          }}
        />
        {field.variant === 'includeCounty' && (
          <TextField
            autoComplete="autocomplete_off_randString"
            disabled={field.disabled || busy}
            label={'County'}
            value={value?.county || ''}
            error={Boolean(countyError)}
            helperText={countyError}
            required
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              if (value?.county !== undefined && event.target.value === '') {
                setCountyError('This field is required.');
              } else {
                if (countyError) {
                  setCountyError('');
                }
              }
              changeHandler(field, {
                ...value,
                county: event.target.value,
                line2: value?.line2 || '',
              });
            }}
          />
        )}
        <TextField
          autoComplete="autocomplete_off_randString"
          disabled={field.disabled || busy}
          label={'Postcode'}
          onBlur={handlePostcodeOnBlur}
          value={value?.postcode || ''}
          error={!valid || blacklisted || value?.postcode === '' || cedarError.length > 0}
          helperText={
            blacklisted
              ? 'Based on your selected postcode we are unable to provide you cover that meets your requirements at this time.'
              : !valid
                ? 'Postcode is invalid.'
                : cedarError
                  ? cedarError
                  : value?.postcode === ''
                    ? 'This field is required.'
                    : ''
          }
          required
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            handlePostcodeOnChange(event);
            changeHandler(field, {
              ...value,
              postcode: event.target.value,
              line2: value?.line2 || '',
            });
          }}
        />
      </>
    );
  };

  // --------------------------------------------------------------------------
  // Main render
  // --------------------------------------------------------------------------

  return (
    <Stack spacing={3}>
      {renderAddressFields()}
      <Dialog open={isDialogOpen} fullWidth PaperProps={{ sx: { width: '100%', margin: '0px' } }}>
        {isDialogOpen && (
          <DialogContent className="dialogContent">
            <Stack spacing={3}>{lookupResult.address ? renderAddressAutocomplete() : renderPostcodeLookup()}</Stack>
          </DialogContent>
        )}

        <DialogActions>
          {lookupResult.address && (
            <Button
              onClick={() => {
                setLookupResult({ address: undefined, error: undefined });
              }}
            >
              Change Postcode
            </Button>
          )}
          <Button onClick={closeDialog} disabled={busy}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};
