import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useContext, useState } from 'react';
import { IStringIndex, TFieldChangeHandler, TFormData, TFormField, TJourneyConfig } from '../../types';
import { OptionField } from './OptionField';
import { renderFields } from './fields';
import { IoMdClose } from 'react-icons/io';
import { JourneyContext } from '../../JourneyContext';
import { getFieldOptions, replacePlaceholders } from '../../formHandling';
import { FiInfo } from 'react-icons/fi';
import _ from 'lodash';
import { patchQuote } from '../../apiCalls';
import { formatToMoney } from '../../utils/common';
import { AppliedContext } from '../../AppliedContext';

function getValueAtPath<T>(data: T, pathString: string): any {
  const path = pathString.match(/\${(.*?)}/)?.[1];
  if (!path) {
    return pathString;
  }
  return _.get(data, path);
}

export default function ExcessField(props: {
  field: TFormField;
  value: any;
  config: TJourneyConfig;
  formData: TFormData;
  changeHandler: TFieldChangeHandler;
  setFormData?: Dispatch<SetStateAction<TFormData | null>>;
  setBusy?: Dispatch<SetStateAction<boolean>>;
  busy: boolean;
}) {
  const { field, value, changeHandler, formData, config, setBusy, setFormData } = props;
  const [updating, setUpdating] = useState(false);
  const theme = useTheme();
  const params = field?.excessParams;
  const journeyContext = useContext(JourneyContext);
  const {selectedAppliedQuote,updateSelectedQuote} = useContext(AppliedContext);
  const localOptions = getFieldOptions(field, journeyContext.formData);
  const compulsoryExcess = Number(getValueAtPath(formData.values, String(params?.compulsoryExcess ?? 0)));

  console.log(formData?.values,params?.breakdown)

  const excessBreakdown = getValueAtPath(formData?.values, params?.breakdown ?? '');

  const localChangeHandler = async (event: any) => {
    const newVal = event.target.value;

    changeHandler(field, newVal);

    if (params?.updateQuoteOnChange) {
      const cleanValues: IStringIndex<any> = {};
      for (let [fieldName, fieldValue] of Object.entries(formData.values)) {
        if (typeof fieldValue === 'string') {
          cleanValues[fieldName] = fieldValue.replace(/\s+/g, ' ').trim();
        } else {
          cleanValues[fieldName] = fieldValue;
        }
      }
      cleanValues[field.name] = newVal;

      // await handleSubmit?.(null, cleanValues,true);

      // If API is not configured, or we are editing, just update journeyContext and return
      if (!config?.APIs?.patch || journeyContext.mode === 'EDIT') {
        setFormData?.({
          values: cleanValues,
          validations: { ...formData.validations },
        });
        return;
      }

      setBusy?.(true);
      setUpdating(true);

      // MTA preview
      try {
        if (journeyContext.mode === 'MTA') {
          const apiCopy = JSON.parse(JSON.stringify(config.APIs?.mta));
          apiCopy.payload.quote.journey = 'mta_preview';
          const result = await patchQuote(config, apiCopy, cleanValues);
          if (result === null || result?.errorMessage || !Array.isArray(result) || result.length !== 1) {
            console.error({
              body: 'There was a problem previewing the MTA, please wait a moment and try again.',
              severity: 'error',
            });
          } else {
            journeyContext.setFormData({
              values: {
                ...cleanValues,
                MTA: result[0],
              },
              validations: { ...formData.validations },
            });
          }
        } else {
          const appliedTransaction = params?.isApplied ? 'quoteDetails' : undefined;
          config.APIs.patch.payload.quote.isQuote = true
          // Actual quote update
          const result = await patchQuote(config, config!.APIs!.patch, cleanValues, appliedTransaction);
          if (result === null || result?.errorMessage || !Array.isArray(result) || result.length !== 1) {
            console.error({
              body: 'There was a problem updating your quote, please wait a moment and try again.',
              severity: 'error',
            });
          } else {
            setFormData?.({
              values: {
                ...cleanValues,
                quote: result[0],
              },
              validations: { ...formData.validations },
            });
            if(params?.isApplied){
              updateSelectedQuote({...selectedAppliedQuote,...result?.at(0)?.appliedQuoteDetails})
            }
          }
        }
      } catch (error: any) {
        console.error(error);
      } finally {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setBusy?.(false);
        setUpdating(false);
      }
    }
  };

  const totalExcess = Number(value) + Number(compulsoryExcess);
  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={updating}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Stack
        spacing={2}
        style={{
          border: '2px solid #E9EAFF',
          backgroundColor: '#ffff',
          padding: 24,
          borderRadius: 8,
        }}
      >
        <div>
          <p className="fieldLabel">{`Choose your voluntary excess`}</p>
          <FormControl fullWidth required={field.required}>
            <InputLabel id={field.label + '-label'}>{'Amount'}</InputLabel>
            <Select
              disabled={field.disabled}
              labelId={field.label + '-label'}
              label={'amount'}
              value={value ?? ''}
              onChange={localChangeHandler}
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
            >
              {localOptions.map((option, index) => {
                return (
                  <MenuItem
                    sx={{ whiteSpace: 'pre-wrap', maxWidth: '600px' }}
                    key={index}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        <div>
          <p className="fieldLabel">{`Compulsory Excess`}</p>
          <Stack
            padding={1}
            paddingLeft={2}
            borderRadius={1}
            sx={{
              backgroundColor: '#EAECF0',
            }}
          >
            <p
              style={{
                color: '#464C5E',
                fontSize: 12,
                fontWeight: '400',
              }}
            >
              Amount
            </p>
            <p>£{compulsoryExcess}</p>
          </Stack>
        </div>

        <Stack paddingTop={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Stack>
            <Typography fontSize={14} fontWeight={500} color={'#565E73'}>
              Total excess
            </Typography>
            <Typography fontSize={16} fontWeight={700} color={'#1A2133'}>
              £{isNaN(totalExcess) ? '??' : totalExcess}
            </Typography>
          </Stack>
          <ExcessModal
            breakdown={excessBreakdown}
            modalSummaryTitle={field?.dialog?.title ?? ''}
            brandingColor={theme.palette.primary.main}
            compulsoryExcess={compulsoryExcess}
            excess={value}
            totalExcess={totalExcess}
          />
        </Stack>
      </Stack>
    </>
  );
}

const ExcessModal = ({
  brandingColor,
  excess,
  compulsoryExcess,
  totalExcess,
  modalSummaryTitle,
  breakdown,
}: {
  modalSummaryTitle: string;
  brandingColor: string;
  excess: number;
  compulsoryExcess: number;
  totalExcess: number;
  breakdown?: { amount: number; description: string }[];
}) => {
  const [open, setOpen] = useState(false);

  const lineItems = [
    {
      label: 'Compulsory excess',
      value: `£${compulsoryExcess}`,
    },
    {
      label: 'Voluntary excess',
      value: `£${excess}`,
    },
    {
      label: 'Total Excess',
      value: `£${totalExcess}`,
      isBold: true,
    },
  ];

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        style={{
          textTransform: 'none',
        }}
        variant="outlined"
      >
        {' '}
        View detail
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            border: '2px solid #E9EAFF',
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography fontSize={30} fontFamily={'Poppins'} fontWeight={600} color={'#1C1D21'}>
              Excess
            </Typography>
            <IoMdClose size={24} onClick={() => setOpen(false)} />
          </Stack>
          <div
            style={{
              margin: '20px 0 20px 0',
              height: '1px',
              width: '100%',
              backgroundColor: '#E9EAFF',
            }}
          />
          <Stack spacing={4}>
            <Typography fontSize={16} fontWeight={400} fontFamily={'sans-serif'}>
              Excess is only paid when you make a claim – it’s the amount you pay before your claim is resolved.
            </Typography>

            <Stack
              padding={1}
              spacing={1}
              direction={'row'}
              alignItems={'start'}
              border={'2px solid #E9EAFF'}
              borderRadius={2}
            >
              <FiInfo color={brandingColor} size={25} />
              <Typography fontFamily={'sans-serif'} color={'#464C5E'} fontSize={14} fontWeight={400}>
                Choosing a higher excess will lower the price of your cover but you will need to pay more if you make a
                claim.
              </Typography>
            </Stack>

            <Stack padding={0} spacing={1} border={'2px solid #E9EAFF'} borderRadius={2}>
              <Typography padding={1} color={brandingColor} fontSize={14} fontFamily={'sans-serif'} fontWeight={700}>
                {modalSummaryTitle}
              </Typography>
              {breakdown && (
                <>
                  {breakdown?.map((lineItem, index) => (
                    <Stack
                      padding={0.8}
                      key={`line-item-${index}`}
                      marginBottom={1}
                      direction={'row'}
                      justifyContent={'space-between'}
                      fontSize={14}
                      fontFamily={'sans-serif'}
                      color={'#464C5E'}
                    >
                      <span>{lineItem.description}</span>
                      <span>£{formatToMoney(lineItem.amount)}</span>
                    </Stack>
                  ))}
                  <div
                    style={{
                      height: '1px',
                      width: '100%',
                      backgroundColor: '#E9EAFF',
                    }}
                  />
                </>
              )}
              {lineItems.map((lineItem, index) => (
                <Stack
                  padding={0.8}
                  bgcolor={index === lineItems.length - 1 ? '#FAFAFF' : 'white'}
                  key={`line-item-${index}`}
                  marginBottom={1}
                  direction={'row'}
                  justifyContent={'space-between'}
                  fontSize={14}
                  fontFamily={'sans-serif'}
                  fontWeight={lineItem?.isBold ? 600 : 400}
                  color={'#464C5E'}
                >
                  <span>{lineItem.label}</span>
                  <span>{lineItem.value}</span>
                </Stack>
              ))}
            </Stack>
          </Stack>
        </Box>
      </Modal>
    </>
  );
};
