import { uniqueId } from "lodash";
import { createElement } from "react";

export type THtmlObject = {
  tag: "string";
  props?: object;
  children: THtmlObject[];
};

const renderChildren = (children: THtmlObject | string | string[] | THtmlObject[]): any => {
  if (typeof children === "string") {
    return children;
  }
  if (typeof children === "object") {
    if (Array.isArray(children)) {
      return children.map((c) => renderChildren(c));
    }
    return renderObject(children);
  }
};

export const renderObject = (obj: THtmlObject): any => {
  // Contents should be static, so using uniqueId shouldn't be an issue
  return createElement(obj.tag, { ...obj.props, key: uniqueId() }, renderChildren(obj.children));
};
