export const Occupations = [
  {
    label: "Abattoir Inspector",
    value: "33b4618a-141a-434c-876d-991925b2181a",
  },
  {
    label: "Abattoir Worker",
    value: "9406f632-31b2-432c-9b7a-0a8ea2cd3fb8",
  },
  {
    label: "Abrasive Wheels Worker",
    value: "26789998-1e08-46d8-9fc7-ad163abe3bb2",
  },
  {
    label: "Account Executive",
    value: "cf4985a0-641a-4934-8340-67abd61d79d3",
  },
  {
    label: "Accountant",
    value: "4a70aedb-f2f5-4d1a-83f2-8ce45ba76768",
  },
  {
    label: "Accounts Administrator/Assistant",
    value: "d9c921c4-3fb1-4dad-801c-22b1be3426d1",
  },
  {
    label: "Acidifier Operator",
    value: "b45ffc9d-d674-44f2-82e8-519cef33d564",
  },
  {
    label: "Acrobat",
    value: "e5a429c6-fa46-43ef-b9c5-dd2d9c9a002f",
  },
  {
    label: "Actor/Actress (no stunt work)",
    value: "65a4268e-abe3-4f7d-886e-af6b1fc40401",
  },
  {
    label: "Actuary",
    value: "e9a2063b-f335-43e5-a7fa-e6cb9a19411a",
  },
  {
    label: "Acupuncturist",
    value: "7ee4eaf2-5c5b-4aab-acf3-41b4f33511b8",
  },
  {
    label: "Administration Manager",
    value: "eb6e8ffa-1f83-4b60-98fe-71883fb40435",
  },
  {
    label: "Administrator - office",
    value: "98644eba-65fb-458a-afad-1ab621aa2e2c",
  },
  {
    label: "Advertising Manager",
    value: "272308e0-649e-4885-9b81-ad6ac4940655",
  },
  {
    label: "Advertising executive",
    value: "470e1005-4aba-417a-a10b-bf62141d4b91",
  },
  {
    label: "Aerial Erector (40' up)",
    value: "9140e87d-a651-4136-8277-5ffdcabed2f6",
  },
  {
    label: "Aerial Erector (up to 40')",
    value: "fb911520-60dc-47b4-9bd7-f62df9258feb",
  },
  {
    label: "Aerial Photography",
    value: "a3a80501-fdec-476a-991f-4d05ce6c19c9",
  },
  {
    label: "Aerobics Instructor",
    value: "e48e3f76-df57-4b16-8ef8-099e751e07ee",
  },
  {
    label: "Agent",
    value: "023a440e-d57f-4d4d-9720-da231213f44d",
  },
  {
    label: "Agricultural Engineer",
    value: "1f455c17-7294-4d9c-93ee-e4773cfc4882",
  },
  {
    label: "Agricultural Worker",
    value: "0e5c26f9-4d7e-4ba0-8fb6-a913ff678ee1",
  },
  {
    label: "Agronomist",
    value: "2bd8d292-e134-401d-9f42-1315886ddf41",
  },
  {
    label: "Air Compressor Operator",
    value: "fc652e2f-10a6-40c3-8440-71a337a35eab",
  },
  {
    label: "Air Frame Service Fitter",
    value: "63b6e0f3-2469-498b-9bcb-bd5cdc62cf7b",
  },
  {
    label: "Air Pump Attendant -Coastal etc",
    value: "ee0ee3a0-c54d-4299-ba7e-1aa2cb7c4402",
  },
  {
    label: "Air Pump Attendant -Deep Sea",
    value: "3b3b6b92-c295-42d5-a1be-9112c8abc1b6",
  },
  {
    label: "Air Traffic Control Assistant",
    value: "4a975dad-8afc-4c36-bade-2630b5fa6f75",
  },
  {
    label: "Air Traffic Controller",
    value: "787072af-287f-4291-a5a8-ab664740b1cc",
  },
  {
    label: "Air Traffic Planner",
    value: "8297ce9e-10ad-474f-a088-e54dc1fb9ece",
  },
  {
    label: "Aircraft Electronics Service Fitter",
    value: "1c35c683-536e-4b1c-8288-0e9ba1213253",
  },
  {
    label: "Aircraft Engine Service Fitter",
    value: "6561ba8b-22dd-47d9-bde1-ef29051125ee",
  },
  {
    label: "Aircraft Finisher",
    value: "17ae68c7-a045-4b42-9c5e-8aea16b85e70",
  },
  {
    label: "Aircraft Inspector",
    value: "40943d02-028a-4491-85ca-f2ba6693848f",
  },
  {
    label: "Aircraft Instrument Mechanic",
    value: "b6ba897a-2ccc-40e7-8c2a-00de3be98f44",
  },
  {
    label: "Aircraft Joiner",
    value: "23fd0e88-5c3c-46f0-a1bc-69e8dde09f5e",
  },
  {
    label: "Aircraft Maintenance Technician",
    value: "7b43aac4-dfcb-44d3-a4e4-a1b985b4d0c4",
  },
  {
    label: "Aircraft Marshaller",
    value: "ad421958-ccbb-404e-a383-531aab0f4e4c",
  },
  {
    label: "Aircraft Refueller",
    value: "53dae384-f237-45b0-8640-9fdaef19c21e",
  },
  {
    label: "Aircrew (including Flight Engineer)",
    value: "74ddd5cb-4be3-4648-aa4f-e2636d402d1f",
  },
  {
    label: "Airline Cabin Staff",
    value: "97d7edc5-f57d-4cd4-9a90-c6b688bd9fb3",
  },
  {
    label: "Airline Pilots",
    value: "f5dbedf2-c11d-4dbc-9779-ad19a49eafc5",
  },
  {
    label: "Airport Manager",
    value: "bbc699c0-93ee-4999-a98d-3a6d8d83e7b8",
  },
  {
    label: "Airport Superintendent",
    value: "b0d75a03-11a8-45b4-8eab-145e9b1002e3",
  },
  {
    label: "Alternative Therapist/Complimentary Therapist",
    value: "bdc7a014-262a-42db-8091-64ad331ff35e",
  },
  {
    label: "Ambassador",
    value: "b024430c-c28f-4957-8c32-83328eeac6b7",
  },
  {
    label: "Ambulance Driver",
    value: "445e083d-7a25-4b06-875b-74bf8c98d4c4",
  },
  {
    label: "Ambulanceman (No driving)",
    value: "49eae977-3e2c-489c-b8c2-41b797563278",
  },
  {
    label: "Amusement Arcade Worker",
    value: "6b5a9fe0-10a4-4cc4-840b-98cdd68549a6",
  },
  {
    label: "Amusement Park Worker",
    value: "cfaf4694-7b42-4ce8-81dc-71b79cf1fce4",
  },
  {
    label: "Anaesthetist",
    value: "0e0724a7-a1c8-426e-8f26-07ed7918a284",
  },
  {
    label: "Analyst - Business",
    value: "4c2ff213-5c80-4d2c-b9d3-b9d38ceee1b5",
  },
  {
    label: "Analyst - City",
    value: "648d2fe8-f06c-4aa0-a960-eb047771a4ff",
  },
  {
    label: "Analyst - Investment",
    value: "46296691-6911-48d1-8f2a-73f4d2ddd13f",
  },
  {
    label: "Analyst - Systems",
    value: "e3bb2a94-79ec-4c63-96e9-e99e50ec82a8",
  },
  {
    label: "Analytical Chemist",
    value: "e1718bba-0b53-4e1d-b603-c74db74e3604",
  },
  {
    label: "Ancient Monuments Inspector",
    value: "7ce0cb61-4463-46fa-88d3-b641dbdc760b",
  },
  {
    label: "Animal Nursing Auxiliary",
    value: "a582b0d9-ab7d-4d53-acd3-d8f097874c18",
  },
  {
    label: "Animal Trainer/Keeper",
    value: "d66110f8-c124-4412-b98f-dea726757347",
  },
  {
    label: "Animator",
    value: "868fc8b3-b223-430d-b7fd-22bbb348021f",
  },
  {
    label: "Annealer",
    value: "cebe4bff-964b-4348-b6f3-d9e339cd6bb0",
  },
  {
    label: "Announcer - Radio & TV - Entertainment",
    value: "086d7942-a5c4-4fea-8718-56a9b8245921",
  },
  {
    label: "Announcer - Station Personnel - Railways",
    value: "f06747eb-283f-4fe5-a5ec-eb28427ced5d",
  },
  {
    label: "Anodiser",
    value: "3596c220-0c26-47e6-a5b8-1374d9889a95",
  },
  {
    label: "Antique Dealer",
    value: "cc054c4a-727f-4a89-ad89-be9fd3810375",
  },
  {
    label: "Antique Restorer",
    value: "e854766d-0386-4672-b1ad-57b9ba55a10f",
  },
  {
    label: "Arc Welder",
    value: "41c9c0dc-2fc7-48a9-8bf7-d9182313a304",
  },
  {
    label: "Archaeologist",
    value: "b6a064d8-cafa-4663-aa00-8127a6a34cee",
  },
  {
    label: "Archaeologist (other countries)",
    value: "38a2568e-a471-4741-97dd-6b1790f69a6a",
  },
  {
    label: "Architect",
    value: "b94f9178-6b64-4b46-9892-543c8c0e6eed",
  },
  {
    label: "Architect (office)",
    value: "6c4a34ea-fea8-4845-ae45-23bf435790ac",
  },
  {
    label: "Archivist",
    value: "1b2c50ed-626e-427d-99b5-d39db5076fc5",
  },
  {
    label: "Armed Forces - Army - SAS",
    value: "71bcdf09-60dd-4ac8-8671-70ec26a38e7e",
  },
  {
    label: "Armed Forces - Army - aircrew",
    value: "cd1a141b-178b-4964-8709-d1d87535ac96",
  },
  {
    label: "Armed Forces - Army - bomb disposal",
    value: "98f0ea0a-f8e5-4f26-94a7-eb752d0089e2",
  },
  {
    label: "Armed Forces - Army - no bomb disposal",
    value: "57ebcc56-a353-408f-ad5f-f3c7581b8dc0",
  },
  {
    label: "Armed Forces - Full time reservist - no special duties",
    value: "aabdebda-dde0-4e3e-a45c-bbbd9eccefad",
  },
  {
    label: "Armed Forces - Full time reservist - special duties",
    value: "e444fd2c-c4cc-4ca4-b03c-094d8e0250c3",
  },
  {
    label: "Armed Forces - Navy - SBS",
    value: "d1f90e4f-bd37-47f0-b4a0-f44d76c73807",
  },
  {
    label: "Armed Forces - Navy - aircrew",
    value: "0cb7d0cf-21a1-490a-97f1-5563a4a1595b",
  },
  {
    label: "Armed Forces - Navy - diving",
    value: "0d4b9ef3-ffc2-4f9d-afcb-3937a1c5ff10",
  },
  {
    label: "Armed Forces - Navy - no diving",
    value: "f8fac167-ee9d-4e28-a26e-598fc526de33",
  },
  {
    label: "Armed Forces - RAF - aircrew",
    value: "c4b2974d-7a1e-4097-bd60-250dad44f936",
  },
  {
    label: "Armed Forces - RAF - no flying",
    value: "b31f0975-9300-4455-87ab-145acbe2e9ea",
  },
  {
    label: "Aromatherapist",
    value: "e7e4819f-95a1-4c01-b0d6-be3c7453f9e6",
  },
  {
    label: "Arranger",
    value: "b6ffda1c-a830-49ff-9423-0145b3feefa8",
  },
  {
    label: "Art Director",
    value: "ae87a39a-e182-4190-bf55-6256b172a1d9",
  },
  {
    label: "Art Gallery Attendant",
    value: "e03bab49-e1a0-4738-82ab-8e90b23c5678",
  },
  {
    label: "Art Gallery Curator",
    value: "b4a8ae79-a03e-4a8b-91ed-fb71339ff466",
  },
  {
    label: "Art Gallery Guide",
    value: "ea4004b7-9769-4368-b6f9-79f37b73b9d1",
  },
  {
    label: "Art Gallery Manager - Commercial",
    value: "7622efb6-5d29-4f80-9639-2ab736d74054",
  },
  {
    label: "Artexer",
    value: "454815b7-03cb-46d1-b44b-73f3ba9dfee1",
  },
  {
    label: "Artist - Freelance Painter",
    value: "52be9049-4342-4ae2-b5e8-81e189f38980",
  },
  {
    label: "Artist Commercial",
    value: "d50ab02f-c396-4b17-a235-b81fb405753c",
  },
  {
    label: "Artist's Model",
    value: "d80bb90e-2d94-4558-bf37-3f76be95f877",
  },
  {
    label: "Asbestos Inspector",
    value: "aa8951dd-44f6-4975-b6d9-0175ba7a04fb",
  },
  {
    label: "Asbestos Worker",
    value: "b6b11b3c-55cc-449f-8d35-87058b7af147",
  },
  {
    label: "Asphalter",
    value: "0ed2d76f-6935-4ab5-95a1-90af50033b14",
  },
  {
    label: "Assembler - Woodworking Industry",
    value: "c495e1df-ed50-490a-a4bc-769050ce4962",
  },
  {
    label: "Assembly Inspector",
    value: "0d3afba5-2cd6-4e04-b582-a8b830ee5f6c",
  },
  {
    label: "Assessor (claims/insurance)",
    value: "ab2e89c3-f0ed-4c02-ab12-52fd4d19c4f9",
  },
  {
    label: "Assistant Cameraman",
    value: "2be5b46c-9c12-4a62-880b-494fd62c3e67",
  },
  {
    label: "Assistant Director",
    value: "3be15183-99fd-41bf-a34e-759338f3dc69",
  },
  {
    label: "Assistant Editor",
    value: "73eda335-0588-4be6-8b7b-459adc18b5c2",
  },
  {
    label: "Assistant Superintendent",
    value: "941d5a88-aa1a-4a09-9f9b-fc30a94f2cf4",
  },
  {
    label: "Assistant Tool Pusher",
    value: "3a7ca011-1b99-49f3-8c38-8f8401f19e93",
  },
  {
    label: "Associate Producer",
    value: "64439ae5-7806-4604-83ed-2a0b5407ca0e",
  },
  {
    label: "Assumed Non-Hazardous (for quotation only)",
    value: "d92be116-fa83-42fa-83f7-7b5344035332",
  },
  {
    label: "Astrologer",
    value: "ca86558b-cc8f-4425-a733-176e318276e5",
  },
  {
    label: "Astronomer",
    value: "1c80fa15-3329-4484-9d30-c6b5bb856dbb",
  },
  {
    label: "Atomic Energy Worker",
    value: "3955cf9e-1743-4b70-8a23-8bb7cc2334d6",
  },
  {
    label: "Attendant - Bingo - Entertainment",
    value: "74e98dbf-1134-48e0-9bbb-1339dcff9671",
  },
  {
    label: "Attendant - Fairground etc - Entertainment",
    value: "efc063a4-fa14-424b-8bbc-ba90dc774073",
  },
  {
    label: "Au Pair",
    value: "58233cd0-dd7a-4d64-8e9b-86c48d4ac845",
  },
  {
    label: "Auctioneer",
    value: "a2eb8e39-dfc1-4b17-800e-45e01d64d7ed",
  },
  {
    label: "Audiometrician",
    value: "4c1293e4-1ece-49d9-8ced-ac38ad9ee824",
  },
  {
    label: "Auditor",
    value: "ede647b3-00ee-4ae8-a85e-dd5ac24b313b",
  },
  {
    label: "Author",
    value: "8aeb7920-50b1-4906-86ae-575a8dfe080b",
  },
  {
    label: "Autoclave Operator",
    value: "72b7f9f4-bf39-4b1a-a1a6-be55c1484ce7",
  },
  {
    label: "Autolysis Man",
    value: "eb544674-36e6-4d1f-b4cd-0cf2a2e415fe",
  },
  {
    label: "Automatic Train Attendant",
    value: "551e3f61-af4c-4834-8831-9a6c21c88882",
  },
  {
    label: "Auxiliary Nurse",
    value: "0e8474a2-672a-4fc3-9c6e-0a489ee965dc",
  },
  {
    label: "Baggage Handler",
    value: "68eb899d-8e49-486b-b13e-174c6c6346be",
  },
  {
    label: "Baggage Manager",
    value: "d8f0c838-f5ba-4016-b896-3aeac8334347",
  },
  {
    label: "Baggage Master",
    value: "26d1ad19-4c9d-4104-8817-ca9990b73986",
  },
  {
    label: "Baggage Porter",
    value: "cfaa32ef-2cea-45c0-8d7c-04de39bfbecb",
  },
  {
    label: "Bailiff",
    value: "2aaf4b18-09d0-4648-826d-797749b4cdce",
  },
  {
    label: "Baker",
    value: "b9c41d88-15d5-4f0e-881b-2e1e48cb04e0",
  },
  {
    label: "Bakery Equipment Operator",
    value: "94b18c73-d3f0-48aa-b8a6-b0cafaacd14f",
  },
  {
    label: "Bakery Shop Manager",
    value: "2e1f6412-754f-45e2-a1f5-2e51c43b10e4",
  },
  {
    label: "Baler",
    value: "a56702c2-31d1-483f-aa09-0d999be96bed",
  },
  {
    label: "Band Leader",
    value: "d6b3b92d-5599-498b-987f-402932526de5",
  },
  {
    label: "Band Mill Sawyer",
    value: "cfbed85a-40f4-4ae1-bc46-9c858dd558db",
  },
  {
    label: "Bank Staff",
    value: "f990a334-16a8-4208-ae31-6a20d662ed49",
  },
  {
    label: "Banksman",
    value: "b673a9af-5f0b-4e58-8d01-095158bbe5de",
  },
  {
    label: "Banksman's Assistant",
    value: "4492a8fa-e6e0-4b93-a9dd-f64cc7f77d75",
  },
  {
    label: "Bar Manager/Proprietor",
    value: "f5c2a751-66e1-4dc2-94d2-c8ab55bf80dd",
  },
  {
    label: "Bar Steward",
    value: "11c40f69-e61e-48ee-8d12-20213a7c96ce",
  },
  {
    label: "Barber",
    value: "6edfa52d-047c-4ad9-acd3-f8429f760a48",
  },
  {
    label: "Barber - Shop Manager/Proprietor",
    value: "c1408e54-6491-4d4c-93c1-d7d79831f69c",
  },
  {
    label: "Bargeman - Merchant Marine",
    value: "a6ef9c54-0dc1-4153-bf64-112c03fe7672",
  },
  {
    label: "Bargeman - Quarrying",
    value: "4fd1118e-4e66-40a2-ad34-74891997b417",
  },
  {
    label: "Bargemaster",
    value: "6e0fcf96-8c05-4dc3-be9f-a8629642a6fc",
  },
  {
    label: "Barley Roaster",
    value: "1d07cf96-6bfb-4b36-8b6c-09be5071e42a",
  },
  {
    label: "Barmaid",
    value: "9a44bfcd-03df-413e-af1f-de4663f503cf",
  },
  {
    label: "Barman",
    value: "eb1c30b9-a1fc-459c-b7ff-e3c4c42e118e",
  },
  {
    label: "Barrelman",
    value: "7abf2047-eb21-44fb-9fe3-41904ae6f6b3",
  },
  {
    label: "Barrister",
    value: "49162f2f-f60d-4bda-9387-085967632c68",
  },
  {
    label: "Barrister, Advocate",
    value: "57f6a20c-f2a6-4a7c-a66e-7a548ea85a2f",
  },
  {
    label: "Batman",
    value: "c5208855-06a0-46d1-94e5-5d3df70a823a",
  },
  {
    label: "Battery Assembler",
    value: "95cc3846-73af-4a14-9316-7e9551082cc9",
  },
  {
    label: "Battery Repairer",
    value: "811b41bf-1954-49fe-a8af-213225046538",
  },
  {
    label: "Beautician",
    value: "33fcc9f6-ce5f-4c3a-bdbc-796259478397",
  },
  {
    label: "Beautician Shop Manager/Proprietor",
    value: "56bd60c8-9386-48a7-bafe-3c9b15dc7a90",
  },
  {
    label: "Bed & Breakfast Proprietor",
    value: "c34f14a8-2d8f-48a5-b78c-b783cb13b89a",
  },
  {
    label: "Beekeeper, Apiarist",
    value: "4f2b7eb6-13af-4439-8027-9c267a3a4f46",
  },
  {
    label: "Belt Maker",
    value: "ff5506a8-dd45-4a3a-8d70-b1bb4a56ca9f",
  },
  {
    label: "Belt Patrol Man",
    value: "d0511188-c1bc-4d28-ae6f-be6f19c8bd49",
  },
  {
    label: "Bench Hand - Production Fitting - Metal Manufacture",
    value: "f10babbc-8d72-4b72-90b8-ce2544e24776",
  },
  {
    label: "Bench Hand - Rubber Industry - Natural",
    value: "0c9f65f3-2fdf-4e22-90b0-e8244bf4102c",
  },
  {
    label: "Berthing Superintendent",
    value: "dc1cb6c4-7c80-4e73-acb1-e79ee56e0b24",
  },
  {
    label: "Betting shop manager (on course)",
    value: "0ceb5479-395d-420c-9330-be328bd12c00",
  },
  {
    label: "Betting shop manager (shop based)",
    value: "14e40d95-9dc4-41be-8550-8691f81e9801",
  },
  {
    label: "Bill Poster/Sticker",
    value: "2ddb77cd-f225-493e-80ad-fd92bc4b8e2d",
  },
  {
    label: "Bin man",
    value: "18449d3e-efb1-4fc3-8228-3d0e96bbd339",
  },
  {
    label: "Bindery Assistant",
    value: "d916616f-dde1-4af9-8b00-d572f807eb41",
  },
  {
    label: "Binding Machine Attendant",
    value: "3990ac4a-f323-4721-85eb-221244a7f3a6",
  },
  {
    label: "Bingo Hall Manager",
    value: "7eb917c3-66e6-42f0-954f-aa6f34184aa3",
  },
  {
    label: "Biochemist",
    value: "fa280630-1b13-4723-a79f-c24fca91afc1",
  },
  {
    label: "Biochemist - Lecturing and research",
    value: "5aeb4d96-6704-4c95-a20e-12150c6cbe1e",
  },
  {
    label: "Biological scientist",
    value: "d80d5201-5a23-4884-a1b4-17656f3ca555",
  },
  {
    label: "Biologist (No travel/ underwater)",
    value: "94609c37-7fa5-4f29-9294-fd98089cefae",
  },
  {
    label: "Biologist (Overseas travel)",
    value: "a506d8a1-882b-4d63-8746-16295abc1dd8",
  },
  {
    label: "Biologist (Underwater work)",
    value: "566c7331-f0da-4ce4-b79c-8e5ce0aaa401",
  },
  {
    label: "Biscuit Baker",
    value: "44fb2730-5f66-4854-abf9-f90b873d07c3",
  },
  {
    label: "Blacksmith",
    value: "451a6cc6-b67c-4a4c-9e17-44d85864fdb0",
  },
  {
    label: "Blancher",
    value: "8e3996bb-7d78-4eb3-b152-620f639d8c4d",
  },
  {
    label: "Blaster - quarry",
    value: "8a422faa-7ed3-4450-ae79-f25894992f0a",
  },
  {
    label: "Bleacher - Paper & Board Manufacture",
    value: "6e5dc905-5ed7-4daa-81ef-c5fad612d124",
  },
  {
    label: "Bleacher - Textile & Clothing Industry",
    value: "f4d66d09-c8bf-48fb-8783-e0449a6db4b2",
  },
  {
    label: "Blender",
    value: "c6b36059-ad82-423e-9100-1f5ac44bdfb4",
  },
  {
    label: "Block Cutter",
    value: "d52863c2-98a7-419e-abf3-57ae169423b7",
  },
  {
    label: "Boarding School Matron",
    value: "60a6c050-80f0-4d82-9aaa-fe5e4ba1f5df",
  },
  {
    label: "Boat Builder",
    value: "5a34f96c-197c-42f8-88aa-1e42d89f7598",
  },
  {
    label: "Boatswain - Fishing Industry",
    value: "69ca4387-f0ac-4bb5-b8fa-babaec0d32d3",
  },
  {
    label: "Boatswain - Merchant Marine",
    value: "e3afa548-9582-48e4-9874-77463f50579a",
  },
  {
    label: "Boatswain's Mate",
    value: "5518906d-8d27-4f52-8bc8-e696d9497871",
  },
  {
    label: "Bodyguard",
    value: "2f237c99-fd07-434f-9ef6-739bef557be2",
  },
  {
    label: "Boiler - Confectionery etc - Food & Drink",
    value: "6a1c33ae-35fe-47d0-8c75-d03e2842d01c",
  },
  {
    label: "Boiler - Fruit & Veg. - Food & Drink",
    value: "3ea37744-9140-45ef-8466-0b7794fa0d49",
  },
  {
    label: "Boiler - Meat, Fish etc - Food & Drink",
    value: "1207e94b-da63-40b1-b100-d22d723ca665",
  },
  {
    label: "Boiler Cleaner",
    value: "a985dcd6-0c28-43aa-8b60-bc9baa7e05d9",
  },
  {
    label: "Boiler Operator - Electrical Supply",
    value: "c63aec3f-323f-4a79-b91f-13de4d70d357",
  },
  {
    label: "Boiler Operator - Water Supply Industry",
    value: "62fde4dd-e5a0-493c-9e38-8466f9f265e0",
  },
  {
    label: "Boiler Operator/Fireman",
    value: "70956d3c-be70-473e-b229-a660a9f3295c",
  },
  {
    label: "Bomb Disposal - Elsewhere",
    value: "1b40b4d5-dca5-4556-a49c-4285fba5436f",
  },
  {
    label: "Bomb Disposal - Mainland Britain",
    value: "4dfb88fa-2bcb-43d4-9425-d0743483707f",
  },
  {
    label: "Book Illustrator",
    value: "975135c2-6063-4e8f-8537-3500c1fda645",
  },
  {
    label: "Book Seller",
    value: "26e88a8a-f30e-4947-b9c4-f94a27a76141",
  },
  {
    label: "Book-Keeper",
    value: "147ed876-5ae6-484a-b71e-ee6a27e3fe30",
  },
  {
    label: "Bookbinder",
    value: "cc2a92a4-70f1-4000-b5dd-e5fdc0d5515e",
  },
  {
    label: "Bookmaker - On course",
    value: "b6038d1a-8417-4845-8aba-e886356ee470",
  },
  {
    label: "Bookmaker - Shop Manager",
    value: "b4521950-702a-412a-851b-76247d20dc02",
  },
  {
    label: "Bookmaker - Shop Owner",
    value: "b3a3918f-6048-4f55-be99-f1971dce54cb",
  },
  {
    label: "Bookmaker - Shop Staff",
    value: "f63b2e7f-eacc-4024-9f2d-39bb257e07e1",
  },
  {
    label: "Boom Operator",
    value: "e06059db-2414-46fe-87d2-0c8de190107d",
  },
  {
    label: "Borer - Mining",
    value: "efa15242-8e06-4233-b51e-c374df174502",
  },
  {
    label: "Borer - Tunnelling",
    value: "cfdd2e42-fc9b-44bd-9f38-7f80f276cebd",
  },
  {
    label: "Borstal Matron",
    value: "498f6953-6ce0-462b-af20-1fa147789296",
  },
  {
    label: "Bosun (Third Hand)",
    value: "4d0906fa-c60a-4dc9-a5fa-45244acb106f",
  },
  {
    label: "Botanist (No overseas field work)",
    value: "1560072c-5a0a-4e69-9fe6-35fdfe2da799",
  },
  {
    label: "Bottle Washer (hand or machine)",
    value: "706aa9ab-ea87-4ced-a1dc-da2449887d66",
  },
  {
    label: "Bottling Machine Attendant",
    value: "6edf78bc-8f23-4511-8939-c72797006b7f",
  },
  {
    label: "Box Maker",
    value: "9daec91f-6a52-46e7-a554-a606c78fa0eb",
  },
  {
    label: "Box Office Cashier",
    value: "67130add-709f-4ab2-ae6a-fefa18cac005",
  },
  {
    label: "Box Office Clerk",
    value: "71cf7685-6712-40a2-891e-fbdcaf26e6a8",
  },
  {
    label: "Box Office Manager",
    value: "51906a11-dcf2-4d38-999f-8351b1fe4d31",
  },
  {
    label: "Brakesman",
    value: "330de162-6669-4ec2-8c1f-d859359318b6",
  },
  {
    label: "Brazer",
    value: "e5f44543-5dbe-425d-8220-b4dbb6f99cc5",
  },
  {
    label: "Bread Baker",
    value: "545c5a05-029e-4577-b6c1-b9a54d3fd62b",
  },
  {
    label: "Bread Roundsman",
    value: "cda1f15e-44f0-42b0-b7fe-88127c6cd95d",
  },
  {
    label: "Breakdown Recovery Man",
    value: "33d67618-8e7f-41da-b7e9-b3e619edfe3a",
  },
  {
    label: "Brewer",
    value: "acded85a-637e-45f4-acc2-bc4ba77efa52",
  },
  {
    label: "Brewery Manager",
    value: "bb3d6d0e-a38c-4d63-ac65-2426176d62bc",
  },
  {
    label: "Bricklayer",
    value: "e9f27c2c-973e-4ac1-9848-d15a78ca0a20",
  },
  {
    label: "Bridge Man",
    value: "6a1c95d1-c66a-421f-98bb-2c6774ee1031",
  },
  {
    label: "Briner",
    value: "ad5ff707-cc78-422b-9d44-1b44c205b9ed",
  },
  {
    label: "Broker - Insurance IFA",
    value: "825937ae-673d-43a4-b3b4-5926e347784e",
  },
  {
    label: "Broker - Insurance Non IFA",
    value: "b0ce086f-f3f3-4819-a667-e396c68adcdc",
  },
  {
    label: "Broker - Money/investments",
    value: "0cde53bb-dec1-473d-9dc3-dd2e6c3230d5",
  },
  {
    label: "Broker - Oil",
    value: "59cb486c-2fe9-45f1-a98f-c3edaa9d22ce",
  },
  {
    label: "Bronzer",
    value: "73c83bdf-1d1f-41e5-a0d8-d0dc9c059cba",
  },
  {
    label: "Broom/Brush Maker",
    value: "24610242-93c4-461f-baf8-232e29f0a4d3",
  },
  {
    label: "Buffet Car Attendant",
    value: "527e9102-7dc6-4fb1-a537-03c999852e9d",
  },
  {
    label: "Builder",
    value: "65c5e562-c5df-41e0-a4a1-2a01cebcb312",
  },
  {
    label: "Building Inspector",
    value: "eae293bc-bde2-4258-9103-cf26885591fa",
  },
  {
    label: "Building Site Agent - Building and construction",
    value: "cf07e099-cc96-4af0-a9da-35aa072868b7",
  },
  {
    label: "Building Society worker",
    value: "b801257d-57ea-468c-bfc4-4ccd1759957e",
  },
  {
    label: "Building Surveyor",
    value: "62700361-7489-4892-a1e3-cb530b8bc7e9",
  },
  {
    label: "Bulldozer Driver",
    value: "5147d600-8252-4523-996f-b4d8150a69cf",
  },
  {
    label: "Bunker Control man",
    value: "0111d1d1-e661-4aeb-83a1-bd90fb888716",
  },
  {
    label: "Burglar Alarm Fitter",
    value: "0797edd5-c312-4023-9d0e-9d511b7ae855",
  },
  {
    label: "Bus Conductor (No driving)",
    value: "334e96c2-2965-4a9c-a5cd-0b2d972e5b1b",
  },
  {
    label: "Bus Driver",
    value: "f5b70b27-4695-4088-83f8-7986e9ebc195",
  },
  {
    label: "Bus Inspector",
    value: "6f819182-f986-4d42-b854-8b1cd02feee5",
  },
  {
    label: "Business Consultant",
    value: "9c1197a1-b0e4-4cca-8569-5e537ff859a2",
  },
  {
    label: "Butcher",
    value: "a619d89f-ebc8-429e-a96a-dc49f2409cba",
  },
  {
    label: "Butcher Shop Proprietor",
    value: "863fc3aa-6bef-48b6-b4e0-82d2f5b05637",
  },
  {
    label: "Butler",
    value: "2c9900dc-8891-47cb-ac9a-b638aac6bc55",
  },
  {
    label: "Butter Blender",
    value: "fd3f0488-8bdf-4173-b5f0-bab018df2816",
  },
  {
    label: "Butter Maker",
    value: "2f2466fd-edc0-4fbc-8edf-5c9c2afbe4e6",
  },
  {
    label: "Buyer - retail",
    value: "0671bf36-e03e-40bc-b89f-55d88f6d0abf",
  },
  {
    label: "Buyer - stocks and shares",
    value: "ab22d9e5-79c1-45e5-b806-b368f22ed7ec",
  },
  {
    label: "CC TV Installer/Maintenance - 40 ft and over",
    value: "86fd6881-191f-44ef-a244-7f262baa35fb",
  },
  {
    label: "CC TV Installer/Maintenance - under 40 ft",
    value: "586c4a06-7d28-4f2a-b5ab-351870fed00d",
  },
  {
    label: "Cabin Boy",
    value: "dfe33811-aefe-4322-aa69-c412e0f8e94f",
  },
  {
    label: "Cabinet Maker",
    value: "e0ccb172-4683-4e21-a86b-23b458e91368",
  },
  {
    label: "Cable Former",
    value: "b862ac50-7fdf-47ba-a539-b4a4d12df366",
  },
  {
    label: "Cable Hand",
    value: "ea0ce02a-a42c-42b5-bb19-2f87e999de41",
  },
  {
    label: "Cable Jointer",
    value: "889c6584-ec5a-4ee3-9757-88011b69192a",
  },
  {
    label: "Cable Laying Diver",
    value: "3100c37a-db65-4f9d-81ef-5d7c56946b20",
  },
  {
    label: "Cable Tester",
    value: "a4bb4956-ef1f-454a-a991-70c98ea75f32",
  },
  {
    label: "Cafe Cashier",
    value: "92367c63-a8b6-4735-97e1-95617ecf5201",
  },
  {
    label: "Cafe Manager",
    value: "0ed9935d-26aa-4a48-826f-a541fb9d4d84",
  },
  {
    label: "Cafe Proprietor (Licensed)",
    value: "b0b6da32-97e7-4bad-a8f6-a7c86aa1a802",
  },
  {
    label: "Cafe Proprietor (Unlicensed)",
    value: "43013c7d-a046-45a2-9949-d00ce78653ea",
  },
  {
    label: "Cafe Worker",
    value: "79164ea6-f95e-4ab0-84be-8076db595d0b",
  },
  {
    label: "Calibrator",
    value: "9fde5557-3f92-4eac-adfc-b7c66bd9cc26",
  },
  {
    label: "Call Centre Manager",
    value: "82bda654-bd9f-41f4-b409-3618b12d2ba7",
  },
  {
    label: "Call Centre Worker",
    value: "06818367-7a23-45b9-b35a-1036703872a1",
  },
  {
    label: "Caller",
    value: "5df201ff-56bf-4ddf-a07c-8b2fa3f95fb6",
  },
  {
    label: "Calligrapher",
    value: "c020d15c-3d52-4ee1-8308-6cff7c8e03cc",
  },
  {
    label: "Camera Repair Technician",
    value: "6c2c07ef-8225-4c9a-9b65-91a1bafed81b",
  },
  {
    label: "Cameraman Outside Work",
    value: "cb3a922d-964a-46d8-86ae-8658c8f1e8d9",
  },
  {
    label: "Cameraman Studio",
    value: "561bacc7-f252-44da-82ed-ce41c6f8630a",
  },
  {
    label: "Cameraman War or Disaster reporting",
    value: "bec57077-4b75-440a-9bc4-4811aa331852",
  },
  {
    label: "Candle Maker",
    value: "d204684c-e9a9-4acc-a1cf-0ccc419870d0",
  },
  {
    label: "Canine Beautician",
    value: "c161dd64-8c81-4df8-9be9-a246ff652ce3",
  },
  {
    label: "Canine Behaviourist",
    value: "2c8d64f2-26bb-4bb2-963f-3d245a7c8bd9",
  },
  {
    label: "Canning Machine Attendant",
    value: "1addd070-2c69-48c0-bce8-890fe739a524",
  },
  {
    label: "Canteen Assistant",
    value: "ae100f82-316d-405a-86d1-0f53ab832177",
  },
  {
    label: "Canteen Manager",
    value: "d2704342-8014-42f6-a995-7f9b389408a3",
  },
  {
    label: "Canvasser",
    value: "5d6d9051-221f-46c5-a02e-4005ce15981a",
  },
  {
    label: "Captain - Merchant Marine",
    value: "38273f77-2fcb-4241-82f1-1d8b75b2ca6e",
  },
  {
    label: "Captain - Oil & Natural Gas Industries",
    value: "57b67db5-5695-492c-8b50-81de066e74f5",
  },
  {
    label: "Car Delivery Driver",
    value: "90d22950-81da-48db-91ed-b7a75a4b53be",
  },
  {
    label: "Car Hire Company Proprietor (admin. and driving)",
    value: "021b9d9d-5969-474f-9e79-109b8c4f22f6",
  },
  {
    label: "Car Lasher",
    value: "e420d288-1d03-435e-bebe-e7a66a801157",
  },
  {
    label: "Car Park Attendant",
    value: "c625aeb8-d1b6-41af-a903-bb37547f6d82",
  },
  {
    label: "Car Rental Company Manager",
    value: "0c716e10-dfb0-4f44-8f66-ad79adaa8903",
  },
  {
    label: "Car Rental Company Worker",
    value: "eed6ef85-47bb-417e-b590-24b52aebe7e7",
  },
  {
    label: "Car Salesman (S/E or commission)",
    value: "69fb8c47-1734-4c57-a08f-5af929aa60d7",
  },
  {
    label: "Car Salesman (Salaried)",
    value: "b729e9dd-1502-4a0a-b9d2-d0b723e43a59",
  },
  {
    label: "Car Valeter",
    value: "81c3da9e-15cb-4392-97b1-8a994b5d78ea",
  },
  {
    label: "Car Wash Attendant",
    value: "4f7ec7a5-a9f8-4dc3-9b19-c4e12a66d5ad",
  },
  {
    label: "Caravan Site Manager",
    value: "6bcd95ca-3fbf-4716-ab65-2884470a24d7",
  },
  {
    label: "Caravan Site Staff",
    value: "4445f932-ed58-4d35-8eb0-373f64413b64",
  },
  {
    label: "Carbon Printer",
    value: "f54ee04d-1dcf-4af7-a6ba-2b83e365e892",
  },
  {
    label: "Carbonation Man",
    value: "15aec344-9901-4b92-8c2a-ffbe2c2a1646",
  },
  {
    label: "Carboniser",
    value: "96fef09b-bd5d-4fee-bcb4-0096f5ce9bb4",
  },
  {
    label: "Care Assistant",
    value: "99226247-9f90-42f9-9275-289ca497d91d",
  },
  {
    label: "Care Worker - Residential",
    value: "2d077acd-d6dc-417d-9094-3e7c09f3b153",
  },
  {
    label: "Careers Advisor",
    value: "390e7851-3a05-48e5-bb51-d509db82ac6b",
  },
  {
    label: "Caretaker, Janitor",
    value: "30dfceb5-d90b-47a4-961c-10eba8c86dcb",
  },
  {
    label: "Cargo Clerk",
    value: "0c28380e-c6ac-42a0-9d99-1672d83269be",
  },
  {
    label: "Cargo Superintendent",
    value: "7e1ae14b-c280-4eaf-9229-cff1457398fa",
  },
  {
    label: "Carpenter & Joiner",
    value: "0de0f75c-f5b2-42bb-9214-2b31de5a7c24",
  },
  {
    label: "Carpenter - Construction Industry",
    value: "ab2f5f2b-d9c1-41a5-9759-e252b83cd293",
  },
  {
    label: "Carpenter - Film Industry - Entertainment",
    value: "714b6cf3-7736-4d61-a4c8-5ea67962d6d5",
  },
  {
    label: "Carpet Cleaner",
    value: "3c6c72f5-0b2c-4124-808a-7b254a679360",
  },
  {
    label: "Carpet Company director (office based admin. only)",
    value: "fccbc8a7-909f-467d-a5fa-f65c6dbf812d",
  },
  {
    label: "Carpet Designer",
    value: "fbc214e9-180a-4ca1-b47a-2c49214a393e",
  },
  {
    label: "Carpet Fitter",
    value: "a71d5233-cd85-441a-82a3-56e2ce7af31c",
  },
  {
    label: "Carpet Salesman",
    value: "04376d5d-29c9-471d-85e9-13376794334c",
  },
  {
    label: "Carpet Shop Assistant",
    value: "b7304261-a188-4ce9-ac78-856b16b1f6e4",
  },
  {
    label: "Carpet Shop Manager (admin.)",
    value: "3288830f-ccec-4d94-8c60-3e55fa6fe623",
  },
  {
    label: "Carpet Shop Owner (no manual duties)",
    value: "a9122c1a-7a77-4c84-b6d3-9e0278e406cd",
  },
  {
    label: "Carriage Cleaner",
    value: "bc3611d2-402a-4ff8-b557-e071804782ec",
  },
  {
    label: "Carriage Examiner",
    value: "d7f1aa96-cce6-4f3d-848d-3e4a42cbe469",
  },
  {
    label: "Cartographer",
    value: "6b0ecbd1-2e65-4b6b-bb23-fcfaa523b589",
  },
  {
    label: "Cartoonist",
    value: "e50f2d6c-9ae6-432a-918d-c48865751019",
  },
  {
    label: "Cartridge Filler",
    value: "6d73bd7c-3b41-49a1-9f73-0ca0a4e0b96d",
  },
  {
    label: "Cashier - Bank, building society",
    value: "24da272a-f693-4306-8f4e-20b805c9fe79",
  },
  {
    label: "Cashier - Shop, cafe, supermarket, bingo",
    value: "eae0471b-f077-4abd-9983-3fee11e3b3f6",
  },
  {
    label: "Casino Cashier",
    value: "1984b4b7-4734-45d6-8e0a-ad2f971c44b3",
  },
  {
    label: "Caster",
    value: "92f222df-cbf8-4220-9059-5e392189908b",
  },
  {
    label: "Casting Director",
    value: "42f69f5f-7606-45b3-934e-d721b770fa35",
  },
  {
    label: "Casting Machine Operator",
    value: "3481130a-afbf-4326-96c8-e00cb769b2a0",
  },
  {
    label: "Caterer - offshore/at sea",
    value: "4830a533-5cce-4daa-987b-60c8674a9a24",
  },
  {
    label: "Catering Assistant",
    value: "04b765c5-035e-4cb1-9db7-74e15220715d",
  },
  {
    label: "Catering Manager",
    value: "44d1b238-9599-4208-a288-e35401d9bb26",
  },
  {
    label: "Cathead Man",
    value: "80cec36f-0d93-40ac-a2a5-313f936b15c6",
  },
  {
    label: "Caulker",
    value: "2444e612-83ba-4d6a-ab5e-911e46cfcdee",
  },
  {
    label: "Ceiling Fixer",
    value: "fd64abaf-31f7-42dd-8449-22fbd5031d69",
  },
  {
    label: "Cell Tester",
    value: "1a223455-b226-4837-8188-5390a4ce97f6",
  },
  {
    label: "Cementer",
    value: "1b1b003b-9ced-42af-afb0-dc8b6fcd60cb",
  },
  {
    label: "Cemetery Worker",
    value: "cd69540e-797f-4e88-aa90-2b9ab0347c19",
  },
  {
    label: "Ceramicist",
    value: "8bbbf544-db26-4af9-a13e-99cd56daf249",
  },
  {
    label: "Chain Maker",
    value: "d26a5b48-1658-4649-aaaf-39a384a90728",
  },
  {
    label: "Chair Maker",
    value: "075af61e-029e-48c9-8682-ebed0bd88f29",
  },
  {
    label: "Chambermaid - Housekeeper",
    value: "ed222284-c20b-49dc-9034-eccae6bd7ce9",
  },
  {
    label: "Charge Nurse",
    value: "ef1a871e-24fc-4852-9810-9b854335c7bc",
  },
  {
    label: "Charity Worker - Admin Only",
    value: "1216c50e-6f57-4b86-826f-d7814aa293b8",
  },
  {
    label: "Charity Worker - Overseas Work",
    value: "8dbabb68-1ca1-4f9b-97a7-7817f8820e34",
  },
  {
    label: "Charity Worker - UK Work",
    value: "93a391bf-c7b3-4c65-8d0e-11495be7978e",
  },
  {
    label: "Chartered Engineer (some site duties)",
    value: "7bd50a4f-5b10-40c6-97f9-7f0189423632",
  },
  {
    label: "Chartered Engineered (admin. only)",
    value: "2392fb5f-f4f1-4692-b23e-aec8de61b246",
  },
  {
    label: "Chartered Surveyor (admin only)",
    value: "b397cb74-1411-4378-9388-9edee7b84ae0",
  },
  {
    label: "Chartered Surveyor (some site duties)",
    value: "f853bd9b-f791-4563-91b4-526d84a59e68",
  },
  {
    label: "Chassis Builder",
    value: "463cb40a-d3c2-4f67-b710-94f6b45d0249",
  },
  {
    label: "Chauffeur",
    value: "5a026a79-947e-425f-ae60-8dddb1c19b5d",
  },
  {
    label: "Chef",
    value: "2f90f73c-4386-4e79-b607-d041f98cdcc2",
  },
  {
    label: "Chemical Plumber",
    value: "60878c85-3f13-41f5-8c81-98c0735e0152",
  },
  {
    label: "Chemical Plumber's Mate",
    value: "328a5cf6-ab11-4d4c-b331-8a51be9bd6bd",
  },
  {
    label: "Chemical engineer - UK",
    value: "ce37aace-c8c8-40e8-9c26-98a10a46c609",
  },
  {
    label: "Chemical engineer - offshore",
    value: "6dad774b-e37d-4561-a173-6e97042cb3c1",
  },
  {
    label: "Chemist - industrial",
    value: "eb3f1f00-9d66-47f1-8381-ff127902b54c",
  },
  {
    label: "Chemist - retail",
    value: "58cbe165-56cd-4efb-8531-c0aa95d5b35d",
  },
  {
    label: "Child Protection Co-ordinator",
    value: "1f1e4549-e761-46a3-85eb-24bf8fde8100",
  },
  {
    label: "Child Support Agency (CSA) worker",
    value: "193ec31b-2187-444e-b210-de7addcf6663",
  },
  {
    label: "Child Welfare Officer",
    value: "9492489b-bedc-42e0-8d81-7caf4224218e",
  },
  {
    label: "Childminder",
    value: "e60428e3-ca98-4a28-8c73-e4551c2ad5dc",
  },
  {
    label: "Children's Inspector",
    value: "473c337e-17c6-43db-9e8e-a36a39e70af3",
  },
  {
    label: "Children's Matron",
    value: "e644d695-85e5-47b6-af54-95bbc95419f6",
  },
  {
    label: "Children's Nursery Proprietor",
    value: "bafc35c1-1dac-4979-a25d-846b20bbe9d0",
  },
  {
    label: "Children's Play-group Leader",
    value: "5469be44-e37f-47c8-8640-12ce4523e491",
  },
  {
    label: "Chimney Sweep",
    value: "b122c77f-493c-4c2c-9219-21c9d8936840",
  },
  {
    label: "Chip Shop Owner",
    value: "af32ab1e-530b-4582-a109-b4e89683ff92",
  },
  {
    label: "Chip Shop Worker",
    value: "d13d4932-8780-46ae-8d50-32ab5670256e",
  },
  {
    label: "Chip/Money Changer",
    value: "ed50bacf-e922-4f79-8537-b204edcf0e5d",
  },
  {
    label: "Chipper & Painter",
    value: "3625dd9f-6d79-45a0-9b77-e73bac2ae819",
  },
  {
    label: "Chipper (hand)",
    value: "5568f706-7a40-46f9-bed8-5351c4cbcccb",
  },
  {
    label: "Chipping Driver",
    value: "6cea9a8d-562e-4ff9-b99b-9a17a1421af2",
  },
  {
    label: "Chiropodist",
    value: "4e35db57-7113-495e-8cbe-6b071a40a0fc",
  },
  {
    label: "Chiropracter",
    value: "6a6469f4-3675-4f54-9b24-ffd2bea329fd",
  },
  {
    label: "Church Organist",
    value: "8bcab5e9-cd64-4206-998e-3828fb204467",
  },
  {
    label: "Cinema Projectionist",
    value: "2e59335b-3972-4f03-8588-c99986f2aa7e",
  },
  {
    label: "Circus Hand",
    value: "9b8a4a1c-ee80-4192-8537-aef7eeaa54ba",
  },
  {
    label: "Circus Manager",
    value: "698ee770-01a8-49c3-b882-5c563807081e",
  },
  {
    label: "Civil Engineer",
    value: "e905039f-dbf8-47e5-878e-c7b339a266a5",
  },
  {
    label: "Civil Servant",
    value: "3e0fd114-f598-42cd-99c7-69a553b71b11",
  },
  {
    label: "Claims Adjuster",
    value: "a41f7fc6-d67f-41da-9cf9-6bab07e1cba1",
  },
  {
    label: "Claims Assessor",
    value: "aa93537f-d0a1-42af-abc6-c826228879c7",
  },
  {
    label: "Cleaner - commercial premises",
    value: "3379aa88-eaae-444c-b7b5-7f0d8f0d74ed",
  },
  {
    label: "Cleaner - domestic premises",
    value: "389016e7-95bd-4c4d-88be-6a3557f20412",
  },
  {
    label: "Cleaner - industrial",
    value: "fdf615da-5481-4c5a-a1af-a004b073788b",
  },
  {
    label: "Clergy",
    value: "9e2ed9c3-d455-4b56-8c4b-b00e45913027",
  },
  {
    label: "Clerical Staff",
    value: "1d5b30c6-1279-471f-b9f6-c64878193add",
  },
  {
    label: "Clerical Worker",
    value: "c656b03d-87d9-4901-8105-dda7212b803a",
  },
  {
    label: "Clerk of Works",
    value: "f33dbb55-4d6b-4e26-9c5c-52cb58c535bd",
  },
  {
    label: "Cloakroom Attendant - Club/Nightclub - Entertainment",
    value: "b82b067f-7e32-4028-bfd2-9f97e9de9548",
  },
  {
    label: "Cloakroom Attendant - Theatre, Ballet etc - Entertainment",
    value: "96e8a086-e04f-4b38-837e-ac4edff48cef",
  },
  {
    label: "Clock & Watch Assembler",
    value: "366e45c5-ee88-42d2-9233-997086e32e69",
  },
  {
    label: "Clock/Watch Maker",
    value: "84990cc9-cc8b-4e18-9be2-6296d409ed3b",
  },
  {
    label: "Clock/Watch Repairer",
    value: "801fa0d9-e673-4207-961d-0ef857b1cbcd",
  },
  {
    label: "Cloth Cutter",
    value: "71a5f8f0-a84a-4b78-b9ea-821676b4fd40",
  },
  {
    label: "Clothing Designer",
    value: "4f9fd5ca-26c7-4457-ad4e-716ee19aac06",
  },
  {
    label: "Clown",
    value: "94833bb5-5a74-4f43-9409-d9ea3e632557",
  },
  {
    label: "Club Manager",
    value: "a9257a47-7497-43ed-8b85-9d43e5b946f2",
  },
  {
    label: "Club Proprietor",
    value: "560666db-460d-4bb9-8393-a35931f206e4",
  },
  {
    label: "Club Steward",
    value: "2feefc07-aaf0-420e-83da-18b3976d4b36",
  },
  {
    label: "Coach - Sports",
    value: "f6b50709-ace1-40b2-a66f-f5a3a07ea510",
  },
  {
    label: "Coach Driver",
    value: "ea5e8c52-5e08-403a-a0ac-d289a6018374",
  },
  {
    label: "Coach Painter",
    value: "6ca55a7e-bd6e-4171-932b-170ca051fae8",
  },
  {
    label: "Coal Cutter Mover",
    value: "62cedfa9-06e7-4684-9bf3-b9c55537f64a",
  },
  {
    label: "Coal Cutterman",
    value: "dc752ca4-b558-4a90-b22f-d96b71f99ece",
  },
  {
    label: "Coal Dry Cleaning Plant Operator",
    value: "edf8ae58-1838-467e-a895-072e946a611b",
  },
  {
    label: "Coal Face Workers",
    value: "4fb82935-b4c1-44fc-b94f-1fcfc2a8add7",
  },
  {
    label: "Coal Melter",
    value: "a5f0676b-dd24-49ad-83a8-f92c57949313",
  },
  {
    label: "Coal Merchant - admin only",
    value: "fea69401-52f4-47a1-9232-d9f0097d0e17",
  },
  {
    label: "Coal Merchant - some delivery",
    value: "0b62340e-7c30-416e-bf61-b9dcb59ac135",
  },
  {
    label: "Coal Trimmer",
    value: "43817b89-67a4-4fa5-935a-e37ab4ecc484",
  },
  {
    label: "Coal Washery Operator",
    value: "307c6298-f4aa-4f79-99d2-2ac4ec02e955",
  },
  {
    label: "Coal Yard Foreman",
    value: "a2d6f3cc-a28e-4a25-816a-2674761e05a9",
  },
  {
    label: "Coal Yard Man",
    value: "ea837410-3d51-49ca-9d93-29593a03af10",
  },
  {
    label: "Coastguard (Office based)",
    value: "485a46a1-4443-4a5d-901b-8bb17687fb54",
  },
  {
    label: "Coastguard (Otherwise)",
    value: "15dcd3d2-d6a5-4ec0-9682-6bd91cd9db12",
  },
  {
    label: "Coffin Maker",
    value: "15a7700e-9459-4832-8eb3-ae99c0852336",
  },
  {
    label: "Coil Former",
    value: "d9ad5efe-bce7-4eb4-8d42-6cd6416db014",
  },
  {
    label: "Coil Winder",
    value: "b584bc85-8d20-40e6-82b8-2f1bd6fbfa16",
  },
  {
    label: "College Lecturer",
    value: "e03d9465-27cf-4c24-a1b8-e63bd61f71ff",
  },
  {
    label: "Colour Calculator",
    value: "d68cd208-43cc-4243-b0b5-b5a34206ccd0",
  },
  {
    label: "Colour Matcher",
    value: "b703479b-9722-4dbd-878a-43e365da773e",
  },
  {
    label: "Colour Mixer",
    value: "a7f72fa3-c78a-42af-88c1-89234c2edceb",
  },
  {
    label: "Columnist",
    value: "e9285bbd-2281-4282-8932-916a84ea9e53",
  },
  {
    label: "Comedian",
    value: "1203a6e3-b439-4525-af74-0873adaef13d",
  },
  {
    label: "Commentator - no overseas travel etc",
    value: "10bbdd18-c7f3-4317-8f70-c290e22ef191",
  },
  {
    label: "Commentator - otherwise",
    value: "2dbd38a1-f399-450e-a192-13d673797ce5",
  },
  {
    label: "Commercial Diving",
    value: "0eda8d63-4f32-4697-aa68-ce971b99aaeb",
  },
  {
    label: "Commercial Manager (office sales)",
    value: "57e54afe-d6b8-4175-9808-4f728209263f",
  },
  {
    label: "Commercial Pilots",
    value: "24ece2a6-71fb-477c-93a6-f84010c48e16",
  },
  {
    label: "Commercial Traveller",
    value: "7a436588-208e-452e-b62f-99268026ec6d",
  },
  {
    label: "Commissionaire",
    value: "01950a48-10e3-49c1-8507-9119340981fb",
  },
  {
    label: "Community Development Worker",
    value: "0c29c086-b29e-4dce-a2f2-a7154aa82202",
  },
  {
    label: "Community Nurse",
    value: "b3848ea1-43ea-4202-8c29-3ee974e405b0",
  },
  {
    label: "Company Director (admin. duties only)",
    value: "aaacfba5-3096-4696-9a07-93d0c2cc1833",
  },
  {
    label: "Company Secretary",
    value: "b4c8eb7f-76a8-4fce-814c-df4eafa93613",
  },
  {
    label: "Compass Adjuster",
    value: "0113e1fb-ab34-440a-9e80-cdaf5922b531",
  },
  {
    label: "Compliance Manager",
    value: "82883d5e-3fa5-4b1c-a95f-dd7ef00833a7",
  },
  {
    label: "Composer",
    value: "4c080adc-9fa9-455c-9d11-f8792fc33f7c",
  },
  {
    label: "Compositor",
    value: "ce5457ad-8086-4433-879f-066a40fcdfa2",
  },
  {
    label: "Compounder",
    value: "3195da18-f313-4709-add7-4e5d974670c5",
  },
  {
    label: "Computer Analyst",
    value: "c1186c4c-0a97-4aa1-b14a-27eaa4616a18",
  },
  {
    label: "Computer Company Technical Support Manager",
    value: "a5fee854-d110-48f9-a450-b36d83b9e306",
  },
  {
    label: "Computer Operator",
    value: "918621e8-e3e2-4e15-9d70-33664dbe8437",
  },
  {
    label: "Computer Programmer",
    value: "3eaf9f45-94d9-49c3-944e-71f569f85113",
  },
  {
    label: "Computer Programmer/Analyst",
    value: "be82d292-a4be-48ab-acd0-8da1eda9a432",
  },
  {
    label: "Computer Salesman (office based)",
    value: "82d7b205-b4be-4356-bab2-10caabcef91b",
  },
  {
    label: "Computer Software Manager",
    value: "482901bc-c6bd-4051-bf31-0a88c222bc40",
  },
  {
    label: "Computer Software Salesman (includes travelling)",
    value: "bce4d485-e799-46b3-b407-468c5cc62195",
  },
  {
    label: "Computer Systems Installer",
    value: "da546976-0c26-4bb4-b1c1-30595c18f8ac",
  },
  {
    label: "Computer Wirer",
    value: "c779dce2-f679-4917-8809-f06b90db0cb3",
  },
  {
    label: "Computer Workshop Technical Engineer",
    value: "e14fd2f0-6341-41f3-9bcf-27774cfb76ea",
  },
  {
    label: "Concert Promoter",
    value: "0815430a-083f-4c2d-a526-38e7431fd532",
  },
  {
    label: "Concrete Erector - 40' up",
    value: "dbb9e206-a2bb-4291-a53e-df5a7a355260",
  },
  {
    label: "Concrete Erector - up to 40'",
    value: "bf5e0f45-8d34-4c4c-a1d5-7dad0a4f31fd",
  },
  {
    label: "Concrete Finisher",
    value: "1aabbb9e-7c19-4baa-aae7-dad3a64175ff",
  },
  {
    label: "Concrete Paving Driver",
    value: "de5fbec4-ed05-4372-a33d-94f1e41d2450",
  },
  {
    label: "Concrete Shutterer",
    value: "82e2d248-8c7d-456d-b6ba-2d19a4b9f0b1",
  },
  {
    label: "Concreter",
    value: "2a348977-af71-4479-a1a7-5dc11bcfd5b2",
  },
  {
    label: "Conductor - Music Industry - Entertainment",
    value: "db1e52be-a0b7-4e40-b994-7c664f2eb346",
  },
  {
    label: "Conductor - Train Crew - Railways",
    value: "ba712f83-6337-4cd1-aa1d-f89dae1be838",
  },
  {
    label: "Confectioner",
    value: "a1636972-b7af-433b-bcc2-6d2f19d3ba52",
  },
  {
    label: "Conference Organising Assistant",
    value: "a6e1f2d0-14b0-412f-aefd-f01da3951cd4",
  },
  {
    label: "Conference Organising Manager",
    value: "4de1537f-1f95-4b0f-8d1e-7c7fa1a54d9f",
  },
  {
    label: "Conjurer",
    value: "44e3bcc9-4725-46a1-8f5b-5ed5c1c50fc0",
  },
  {
    label: "Construction Work",
    value: "7d61da5e-9d7c-4aff-90ce-25fa7f76cec6",
  },
  {
    label: "Contact Lens Technician",
    value: "e0298af3-e4c7-49a6-8e7f-0f2bf1519c4e",
  },
  {
    label: "Continuity Clerk",
    value: "be21c2bd-11d3-4fed-9906-72087b5b9719",
  },
  {
    label: "Control Engineer",
    value: "13ba8c11-bcd8-4a14-909e-58747cc073a3",
  },
  {
    label: "Control Room Operator",
    value: "7b1e06da-8168-494b-84cd-33a26d14f4d8",
  },
  {
    label: "Conveyancer",
    value: "0b2ab84d-c8f0-4b7d-8a76-2bd846da41a5",
  },
  {
    label: "Conveyer Operator",
    value: "558e1554-c93f-4456-9020-64ef76b528f2",
  },
  {
    label: "Cook",
    value: "0d869706-b686-4371-affb-c41137c87bd0",
  },
  {
    label: "Cooper",
    value: "d5d8defb-6769-4bcb-8153-50d2ae855db0",
  },
  {
    label: "Coppersmith",
    value: "f8f333f7-6ded-4e49-a778-6fc95a22f3a8",
  },
  {
    label: "Copyholder",
    value: "e0084bdd-7dad-4612-ae36-1c26f7551a58",
  },
  {
    label: "Copyholder (Newspapers etc)",
    value: "0bf3a0e6-c432-42ed-9506-3417b60bb81d",
  },
  {
    label: "Copytaster",
    value: "0d3d9ed8-aea3-477a-a7a7-b85b27bbfe08",
  },
  {
    label: "Copywriter",
    value: "6c96dea4-533f-4ba1-8024-b591db5cf240",
  },
  {
    label: "Core Borer",
    value: "5e5d06ff-2833-455a-8222-3c10f28e85b9",
  },
  {
    label: "Core Builder",
    value: "e51072fb-72d8-4ee1-b73b-05433d9831c4",
  },
  {
    label: "Coremaker",
    value: "fd15f73a-3589-403e-b9e6-d5193cbb772b",
  },
  {
    label: "Coroner",
    value: "83ab2f88-49f6-41d3-b1b2-eb8dddd9eb03",
  },
  {
    label: "Correspondent - no overseas travel etc - Journalism",
    value: "12365d69-f132-4873-8ff7-ea70edbba836",
  },
  {
    label: "Correspondent - no overseas travel etc - Radio & TV - Entertainment",
    value: "99a58043-e050-4770-8e3d-eda4b6f6484c",
  },
  {
    label: "Correspondent - otherwise - Journalism",
    value: "e8b14395-1a1d-4bd5-b618-abc7ccbd1b2a",
  },
  {
    label: "Correspondent - otherwise - Radio & TV - Entertainment",
    value: "e34d9929-8cd9-45ff-b829-524b6d147d78",
  },
  {
    label: "Costermonger",
    value: "f64ad4ab-429e-4df2-8793-9d159cc9ea13",
  },
  {
    label: "Costume Designer",
    value: "53e8edf4-4481-49b0-a7d3-2e7b664cbb62",
  },
  {
    label: "Costumier",
    value: "abc87ab5-7ae0-4cec-9d95-875d779d7207",
  },
  {
    label: "Counsellor",
    value: "b6750eb0-6eea-4dbb-8e78-1c6ff9dcbde4",
  },
  {
    label: "Counter Staff - Dry Cleaning",
    value: "44ce5a3f-4ff2-401e-a549-ab86c2cd567d",
  },
  {
    label: "Counter Staff - Laundry",
    value: "22778833-ea35-4736-954d-8c0e8ed23415",
  },
  {
    label: "Counter Staff - Post Office/Telecommunications",
    value: "46b1f857-613c-4e49-855b-7f9c4f5a7498",
  },
  {
    label: "Couriers",
    value: "5e5bc33b-3431-4ef7-9608-e84749d3826e",
  },
  {
    label: "Court Bailiff",
    value: "2fa28baf-4085-4b50-b134-31f34d64bd99",
  },
  {
    label: "Court Usher",
    value: "f4be9a34-294c-4b0d-8860-8d4771da169d",
  },
  {
    label: "Crab Fisherman",
    value: "39e9b893-f55d-4816-8634-05f1f073f8fc",
  },
  {
    label: "Crane Erector",
    value: "f9af9eef-f677-4288-9eee-5976e287cbd4",
  },
  {
    label: "Crane Operator",
    value: "3955235b-53f5-436e-bdd0-8107a2cf22e9",
  },
  {
    label: "Crane Slinger",
    value: "e59a0ab5-b772-4053-9dd1-59888c21fcd6",
  },
  {
    label: "Credit Agent",
    value: "b10ab92a-e9f1-438f-9bcc-113e90740621",
  },
  {
    label: "Credit Controller",
    value: "079b5432-4542-4f9e-bc70-40733282cbb6",
  },
  {
    label: "Critic",
    value: "3609d47c-f83a-458a-aa10-8b7e7d35b517",
  },
  {
    label: "Crop Sprayer - on ground",
    value: "295d9a77-a5dc-4549-825c-6c4630e1f8b5",
  },
  {
    label: "Crop Sprayer - pilot",
    value: "ce1d1726-4100-49b1-9b58-b06cc2131e36",
  },
  {
    label: "Crossing Keeper",
    value: "bf69342f-2bb6-4997-9a07-23641a8f9024",
  },
  {
    label: "Croupier",
    value: "98baf2a9-8f10-43da-8718-1e247d2f3a37",
  },
  {
    label: "Crushing Worker",
    value: "8f1e1314-e1e1-4485-9797-6ef99415e6cc",
  },
  {
    label: "Curator (museum)",
    value: "27312fe5-974b-448b-affb-d36999c1d3d8",
  },
  {
    label: "Curator - Zoo",
    value: "ccdb8b37-eff1-4781-8270-8e451c828a66",
  },
  {
    label: "Curer",
    value: "9dda665b-6ada-45f5-95f5-49eab6f2727f",
  },
  {
    label: "Customer Care Officer",
    value: "33e885d5-d749-4836-b3ab-9c712ece2086",
  },
  {
    label: "Customer Service Staff",
    value: "94a5229b-6304-4f81-90cf-349db6bbe67d",
  },
  {
    label: "Customs and Excise",
    value: "9f327cff-40b3-46bf-9926-603ebbe64086",
  },
  {
    label: "Cutter",
    value: "656dbcf4-f992-4605-97d6-943f41116abb",
  },
  {
    label: "Cutting Machine Operator",
    value: "b3856f95-cff0-4dca-96fd-bcd8ec18760e",
  },
  {
    label: "Dairyman",
    value: "905eb5f2-2036-4fba-8807-7f2bd33cf3f5",
  },
  {
    label: "Dancer",
    value: "bd111ee6-ebb5-43a7-8bbb-ae476c90eeac",
  },
  {
    label: "Dancing Teacher",
    value: "8026c0ff-807c-4ec8-8003-91b33905646f",
  },
  {
    label: "Data Controller",
    value: "c27ae819-0d17-430b-bfab-847e7b846da4",
  },
  {
    label: "Data Inputter",
    value: "e2a98987-384a-43e9-b6cb-2fcac8dbd970",
  },
  {
    label: "Dealer - money/shares/investment",
    value: "d77bf8dc-31db-4a10-9ccc-60ad030df2fb",
  },
  {
    label: "Debt Collector",
    value: "5af0810e-7b50-4467-b2dc-a37d57acc005",
  },
  {
    label: "Deck Chair Attendant",
    value: "97c6fda7-319a-4525-a2d1-1efd54d81b61",
  },
  {
    label: "Deck Hand",
    value: "a12e1b9c-48bb-4536-bab1-7da02c39b471",
  },
  {
    label: "Deck Officer",
    value: "36d373f2-5ffd-40fe-b90d-d5da67af4fa1",
  },
  {
    label: "Decorator",
    value: "9b5bdc1f-3057-4e3e-9176-167cfc8c39d7",
  },
  {
    label: "Delivery Driver",
    value: "32bef565-fc58-4dd8-a4ec-94c5211463d3",
  },
  {
    label: "Demolition Worker-no explosives",
    value: "3db350b5-9df4-4b28-821d-bb5547d7356c",
  },
  {
    label: "Demolition Worker-using explosives",
    value: "84e23564-2e9d-4d87-aff4-5a525f730c14",
  },
  {
    label: "Demonstrator",
    value: "2a6999f9-fba7-4c20-a521-37bd8d10d162",
  },
  {
    label: "Dental Assistant",
    value: "bba327e9-3385-4c17-bea5-6bed503538f5",
  },
  {
    label: "Dental Auxiliary",
    value: "daec1d53-6d30-4bb1-9a7e-57f0bc2c9e23",
  },
  {
    label: "Dental Consultant",
    value: "c520345f-fbf4-40fe-9c3f-5e08276624d8",
  },
  {
    label: "Dental Hygienist",
    value: "c7d18832-7488-411d-95c7-39868a0ef27e",
  },
  {
    label: "Dental Nurse",
    value: "7f0093d5-19d1-4322-a27d-5b4acf3e779c",
  },
  {
    label: "Dental Practitioner",
    value: "6c411351-99ca-4c34-8bb0-8a6c1828e209",
  },
  {
    label: "Dental Receptionist",
    value: "b4eb6292-cb1c-4fae-a1c7-fd83e7feee5d",
  },
  {
    label: "Dental Technician",
    value: "78e78660-3cbe-4939-bfde-e3f52b368f73",
  },
  {
    label: "Dental Therapist",
    value: "f63d8b46-fe74-404c-86ab-92046d4e2ee4",
  },
  {
    label: "Dentist",
    value: "28ca8b61-4927-4ad8-8c46-e36ec0e4aeac",
  },
  {
    label: "Department Store Manager",
    value: "1d54b31c-ad10-4643-9d09-4220bb65f4ec",
  },
  {
    label: "Department Store worker",
    value: "89824c6f-2517-475a-bf2d-b9ce29b2a92f",
  },
  {
    label: "Dermatologist",
    value: "fa119b5d-d549-4dcf-a992-e05d1e40b4f5",
  },
  {
    label: "Design cutter",
    value: "a9fcf823-d415-45ad-b0cf-1e5792635ed8",
  },
  {
    label: "Designer",
    value: "14dea6c7-8508-4007-b6fb-44b6b1cc0e7d",
  },
  {
    label: "Detention Centre Warden",
    value: "7a48b195-bbd6-4fcb-bc7a-c0a1073dfe66",
  },
  {
    label: "Diamond workers",
    value: "8ba8d475-500e-4e55-aea4-d4b08d7e6e58",
  },
  {
    label: "Die Cutter",
    value: "c4a5de48-4ce7-4ba1-8781-7ba85cb0c1f7",
  },
  {
    label: "Die Setter",
    value: "36a46dc6-2631-4b15-9720-03ce97ef854a",
  },
  {
    label: "Diesel Locomotive Fitter",
    value: "bddfb58c-b836-4719-93f0-54a110b39c2f",
  },
  {
    label: "Dietician",
    value: "aece94d5-2f2c-4707-abe4-90563ec6f35d",
  },
  {
    label: "Dining Car Attendant",
    value: "d4ca258c-b309-434d-94c1-2f2760dc6b39",
  },
  {
    label: "Dinner Lady",
    value: "34be0e42-a185-492e-9d58-5356242e6b3e",
  },
  {
    label: "Director & Medical Consultant",
    value: "0b863f3c-8684-4bc1-b2b2-c024846b9b66",
  },
  {
    label: "Director - Company - admin only",
    value: "dfae295b-7434-4ae3-ac2b-14bc5159a3ac",
  },
  {
    label: "Director - Managing - admin only",
    value: "a1a1179e-32f8-4171-9acf-0c8e21f2eed9",
  },
  {
    label: "Director - Musical",
    value: "b6070c72-28c1-430c-95e2-529655c9cc30",
  },
  {
    label: "Director - Sales - management only",
    value: "dc9de322-aaad-4d41-9676-8ef185b29ca9",
  },
  {
    label: "Director - Sales - some selling",
    value: "223c9d28-5aec-4b75-8f31-6d17e64f1df5",
  },
  {
    label: "Director - TV and film",
    value: "25379126-b9d7-4538-952e-4a21e602570b",
  },
  {
    label: "Disc Jockey",
    value: "a689c76e-623a-4155-9005-8bc8e863f421",
  },
  {
    label: "Disinfecting Officer",
    value: "abf35f93-1aae-4b46-a31f-aceae6c3f07e",
  },
  {
    label: "Disinfestor",
    value: "5b9bf0c0-fc37-4823-8546-8a760750b415",
  },
  {
    label: "Dispatch Rider",
    value: "b2810c2a-e95d-4bb3-bd60-d870b6824d9b",
  },
  {
    label: "Dispatcher",
    value: "cce1b7a4-c0d7-4728-b83e-b451b9a70f86",
  },
  {
    label: "Distiller",
    value: "0aee8253-2d31-4dc4-80f1-80a3e90d6024",
  },
  {
    label: "Distillery Manager",
    value: "be9332e6-ed49-4fdc-ba3d-1375ab8f43ff",
  },
  {
    label: "District Nurse",
    value: "891510e9-fdf5-425d-81a9-cfb33f800b65",
  },
  {
    label: "Diver",
    value: "a6b82a27-6855-41ca-a105-99ad73e30aa5",
  },
  {
    label: "Diver (North Sea)",
    value: "87d2ddd8-91ff-4bd0-ad1f-dde677c648ad",
  },
  {
    label: "Diver's Linesman -Coastal etc",
    value: "ee7f97c9-d73f-4331-92a6-4c7c9d3b43b0",
  },
  {
    label: "Diver's Linesman -Deep Sea",
    value: "aa6bd706-ada4-40ab-b329-bb558130ae63",
  },
  {
    label: "Dock Foreman",
    value: "39e703bc-08b9-46f9-98e2-59135ca56a12",
  },
  {
    label: "Dock Master",
    value: "44f0bcea-92e6-4bf3-8cf8-1b8eb3c1409d",
  },
  {
    label: "Dock Superintendent",
    value: "62920e4d-da4f-483a-a23c-c631eb3e1f86",
  },
  {
    label: "Docker",
    value: "4d8f3a4d-d4aa-40c9-bf67-4388fbb6c554",
  },
  {
    label: "Doctor - Health",
    value: "ddae97ba-fee0-41be-8dd9-b73c3ac237b6",
  },
  {
    label: "Doctor - Merchant Marine",
    value: "ac0f185e-761c-4391-b3b4-c651cc14dda9",
  },
  {
    label: "Dog Catcher",
    value: "e4e0da9d-9b7e-49dc-bbc7-538133b2e646",
  },
  {
    label: "Dogger",
    value: "02fc236c-c3f0-443e-9b9f-09dda2805a5b",
  },
  {
    label: "Domestic Electrician",
    value: "1522a24b-2ce5-41ee-bc21-f6d014178d62",
  },
  {
    label: "Domestic Premises Cleaner",
    value: "311f466a-312c-4d2d-994e-a269b1d4fc0e",
  },
  {
    label: "Domestic Supervisor (Hospital)",
    value: "76c6b98f-5816-4b55-b667-3df00aaa9585",
  },
  {
    label: "Domestic Tiler",
    value: "dffee7a7-da03-4c03-9370-7865e4f988a0",
  },
  {
    label: "Donkeyman",
    value: "2b8a2934-4a70-48b1-ba87-a279468ae127",
  },
  {
    label: "Door to Door Salesman",
    value: "65dc6680-bda1-4efa-afd6-0287cb89956a",
  },
  {
    label: "Doorman",
    value: "3f258cdf-8b12-40ed-9733-6a763e5f09f5",
  },
  {
    label: "Double Glazing - Installer/fitter",
    value: "10928568-794b-4c9d-a8e8-65ca65b14231",
  },
  {
    label: "Double Glazing Surveyor",
    value: "fa66b48d-4449-43bc-b2f6-0aabe5fcfabc",
  },
  {
    label: "Drainage Layer/Clearer",
    value: "acff6551-525f-432b-8796-80085c919103",
  },
  {
    label: "Draper",
    value: "0cd7abc2-3509-4d90-b373-a01a3564b036",
  },
  {
    label: "Draughtsman",
    value: "956735ae-6fc6-4197-9753-577e362001b9",
  },
  {
    label: "Drawer (Bar, Plate, Rod, etc)",
    value: "5ef90887-d1d6-4034-85cb-fbb4921644d8",
  },
  {
    label: "Drayman",
    value: "24454907-ad44-4fa8-b591-43f16dc0340a",
  },
  {
    label: "Dredger Driver",
    value: "10d3f650-c967-42bf-aa06-53ef24dbc26a",
  },
  {
    label: "Dredgerman",
    value: "b29615d7-e475-46a2-9e7e-f74f92f76c7a",
  },
  {
    label: "Dresser",
    value: "4e2a7839-0c55-4c60-aac6-67ed0b0071b5",
  },
  {
    label: "Dressmaker",
    value: "a109596b-b495-4e05-afa9-0d477973b5d6",
  },
  {
    label: "Drier",
    value: "0006cb83-731e-4742-be8f-097b583bacab",
  },
  {
    label: "Driller - offshore",
    value: "b1551847-4df3-456c-b66a-cf7e11544633",
  },
  {
    label: "Driller - onshore",
    value: "0c2abc7f-c1fe-4bf4-a5f6-a139e1611b4e",
  },
  {
    label: "Driver - HGV",
    value: "8a1ccc54-cc76-49ef-8064-638d7cea7899",
  },
  {
    label: "Driver - PSV",
    value: "b5a8149c-37ec-4616-abaa-47c8a7aedecb",
  },
  {
    label: "Driver - construction",
    value: "d0aecea9-7ae8-4432-a674-4f4b5ad80937",
  },
  {
    label: "Driver - delivery (car or van)",
    value: "6a0f2a02-2eb6-4f66-b201-9c6e9ef7bd45",
  },
  {
    label: "Driver - industrial plant",
    value: "10b119f8-b932-4be8-a0b7-75c889b12627",
  },
  {
    label: "Driver - refuse",
    value: "f02a1663-d11c-47c1-b031-e63803ccfa6f",
  },
  {
    label: "Driver - tractor",
    value: "049e38fa-857f-4bfd-b1be-02073d4db70e",
  },
  {
    label: "Driving Examiner",
    value: "74bf135b-ef79-4ed4-80a3-60daa3a43ee4",
  },
  {
    label: "Driving Instructor",
    value: "44652b92-838c-4e5f-92ec-b9a0cd2da7b5",
  },
  {
    label: "Drop Ball Operator",
    value: "ad759660-5e72-4b0e-bff8-1622f048a172",
  },
  {
    label: "Dry Cleaning Machine Operator",
    value: "d5ec3ebc-371c-445f-b4b0-af4142dab237",
  },
  {
    label: "Dry Salter",
    value: "bc956be5-7ca3-4ef4-ac31-69f94b95735c",
  },
  {
    label: "Dustman/Refuse Collector",
    value: "22f6ce5d-b951-44ef-bffd-2093f947638f",
  },
  {
    label: "Dyer",
    value: "5d9eda1e-8704-4ac4-a30b-cd1df09fa21b",
  },
  {
    label: "Ecological Consultant Outside UK",
    value: "cd47122a-f576-4bca-98c1-8e83ae6a6c33",
  },
  {
    label: "Ecological Consultant UK",
    value: "94ec62a8-25da-4083-8e95-eff8a1304bbe",
  },
  {
    label: "Economist",
    value: "287f8a42-90bf-436c-a531-4b2a096af36e",
  },
  {
    label: "Editor",
    value: "a5425f66-e2ae-4f78-a2d4-7c5f1abbe266",
  },
  {
    label: "Editorial Assistant",
    value: "52bd6d3f-d5df-457c-8ae3-545dc9709b8b",
  },
  {
    label: "Education Assistant",
    value: "fd0aa713-d2bc-44c5-9509-135bf0695aaa",
  },
  {
    label: "Educational Advisor",
    value: "5490e57f-d977-4bfb-b43e-1fe8a83b5167",
  },
  {
    label: "Effluent Inspector",
    value: "68d705d9-2a7e-49fa-beb3-fe88be163e30",
  },
  {
    label: "Electric Logger",
    value: "30785409-c1dd-44d1-b1dc-f4221f13e4d9",
  },
  {
    label: "Electrical Contractor",
    value: "da9d86e4-a1f9-48a6-a687-4bf9a1b22fa7",
  },
  {
    label: "Electrical Engineer",
    value: "8f775a25-50c1-4933-a7cf-c8ecab44551a",
  },
  {
    label: "Electrical Fitter",
    value: "dd152f1a-8d7f-4762-bf93-9e445a44f1a1",
  },
  {
    label: "Electrical Wireman",
    value: "abea5117-af96-4769-8375-93daa87722af",
  },
  {
    label: "Electrician - offshore",
    value: "220f04e9-4a04-4f60-9ba7-aeddbcf0f87f",
  },
  {
    label: "Electrician UK based - domestic",
    value: "63275a3b-1f96-4245-95b0-177917f4db5e",
  },
  {
    label: "Electrician UK based - industrial",
    value: "76e92c15-cc26-451d-833a-e02ccbbcbdf6",
  },
  {
    label: "Electronics Fitter",
    value: "86a72ddb-09bb-44e6-839a-22fc0649c631",
  },
  {
    label: "Electronics Installer",
    value: "53e01d67-2a21-4ec9-9e29-57ca151fed95",
  },
  {
    label: "Electronics Mechanic",
    value: "0fde0422-d44d-46d7-bb38-dbd17f8237a7",
  },
  {
    label: "Electronics Repairer",
    value: "a5b79459-c9e4-4c03-b958-f5cbe6ae1eed",
  },
  {
    label: "Electronics Service Mechanic",
    value: "e66f7da8-0251-4ea7-bb46-126c6677d45f",
  },
  {
    label: "Electronics Wireman",
    value: "0c4c8d0d-27b2-4fb6-8bb1-5cd7e568fa90",
  },
  {
    label: "Electroplater",
    value: "574f9287-fb89-4aca-b834-b30f3224e511",
  },
  {
    label: "Electrotyper",
    value: "1fd66783-3040-4301-908e-86cc40acd997",
  },
  {
    label: "Embalmer",
    value: "3fc77ab0-4310-42b7-be20-3f072b69bce1",
  },
  {
    label: "Embassy Employee",
    value: "6a4e4463-9ac6-4a9f-a4ca-abc971abc9d6",
  },
  {
    label: "Embroiderer",
    value: "8b8f589e-83f9-4b09-beef-d6391282d40c",
  },
  {
    label: "Employment Agency Owner (admin. only)",
    value: "63179abe-f1c8-40c6-853b-87b4df1b1b62",
  },
  {
    label: "Employment Agency worker",
    value: "326d1a03-e7eb-443a-abc1-e86b95bc7d4a",
  },
  {
    label: "Enameller",
    value: "675cc491-6333-469a-9858-4fbc0424355b",
  },
  {
    label: "Engine Driver",
    value: "b4bdb485-3d89-4a91-bacb-2cfd1ab20e1f",
  },
  {
    label: "Engine Tester",
    value: "af669192-9ffa-46f2-8445-919579e36907",
  },
  {
    label: "Engineer - admin and site visits only",
    value: "cb94abe0-55a5-4395-8947-9e8542a2151f",
  },
  {
    label: "Engineer - admin only",
    value: "eee7f6cc-ba1c-462a-8d3e-07d2c3ea5834",
  },
  {
    label: "Engineer - heavy manual",
    value: "2d6476b4-4409-446a-a169-fec7a89dbb9b",
  },
  {
    label: "Engineer - light manual",
    value: "d72a1afd-9851-45f7-9d15-702a4c7f5c80",
  },
  {
    label: "Engineer - offshore",
    value: "be771627-0fa7-4374-b655-a544b1982077",
  },
  {
    label: "Engineer - sales",
    value: "daeb118b-3a20-494d-85f9-960774b97f29",
  },
  {
    label: "Engineer - works at heights over 40 ft",
    value: "f7c9c93b-19d2-4675-99c6-951a0c44df9f",
  },
  {
    label: "Engineering Fitter",
    value: "ad3863dd-89ed-47f7-9bf9-d9589761b6f0",
  },
  {
    label: "Engineering Technician",
    value: "3856f22c-0fe9-4d2f-8e62-7bcfd8b08f79",
  },
  {
    label: "Engraver",
    value: "ef6bac1d-352e-48a6-96aa-c43ae4c3f6f0",
  },
  {
    label: "Enrolled Nurse",
    value: "a0acda0e-4328-47a4-b007-93a6e9181735",
  },
  {
    label: "Entertainer - Entertainment industry",
    value: "b7004d80-5807-4b34-9489-4c65a4c729bc",
  },
  {
    label: "Entertainment Agent - Entertainment industry",
    value: "99c547ec-133f-4a06-8f6c-d9e33425f15f",
  },
  {
    label: "Entertainment Manager - Entertainment industry",
    value: "93448cf1-e003-4e71-ab6f-2aa2fac4f391",
  },
  {
    label: "Entertainments Officer",
    value: "76cf2670-11d0-4561-b5d3-7e3309edfadc",
  },
  {
    label: "Environmental Health Officer",
    value: "5a282314-d472-4eec-a486-07b23c5012d2",
  },
  {
    label: "Equestrian Artiste",
    value: "f4a4b4cf-a0d8-4644-a7db-5febc8681cd2",
  },
  {
    label: "Equestrianism - Riding Instructor",
    value: "89ca8568-175e-4c58-b0d7-6921108122a3",
  },
  {
    label: "Equestrianism - Show Jumping",
    value: "7fa408be-cfdd-4dd0-8f91-ee421844adfc",
  },
  {
    label: "Equipment Cleaner",
    value: "6d7a4780-8591-419e-81d4-0e7d0e738dd4",
  },
  {
    label: "Erector - Aircraft/Aerospace",
    value: "22f44fb3-132c-4d09-8d14-0fcda7c29c89",
  },
  {
    label: "Erector - Production Fitting - Metal Manufacture",
    value: "75183b0a-5278-48ca-9e4c-0908ba6e70f6",
  },
  {
    label: "Escapologist",
    value: "9f094d2a-597b-4d73-a0ce-9814c7f05c05",
  },
  {
    label: "Estate Agent",
    value: "11d1787a-7670-452c-9dcd-975d457c5a4b",
  },
  {
    label: "Estate Manager - all aspects (no manual work)",
    value: "de566c01-be54-4bbb-bbdd-5b7a41d8d7cd",
  },
  {
    label: "Estate Ranger",
    value: "6ee2c54a-79ac-43a5-98a6-3215a60609e3",
  },
  {
    label: "Estimator",
    value: "a2342a90-29c1-4c2c-8d44-1bd27aebc51c",
  },
  {
    label: "Estimator (mainly office duties)",
    value: "7ea6adab-8c31-4b52-b3bf-04b2bb08f2b2",
  },
  {
    label: "Etcher (creative)",
    value: "5e02ced6-8460-4d0d-8d18-eba8ec859ffa",
  },
  {
    label: "Etcher - Pottery Industry",
    value: "10893070-8316-4945-b0c3-625a3fccf0a3",
  },
  {
    label: "Etcher - Precious Metals, Engraving etc - Metal Manufacture",
    value: "c3ecf3f2-1a67-4ebe-a9b5-6279e1639720",
  },
  {
    label: "Etcher - Printing Industry",
    value: "a6f6abd7-7ee8-45a2-ae81-8c177492d4d4",
  },
  {
    label: "Examiner - process",
    value: "dcc6571f-b088-441f-ba55-54f409d2bcd5",
  },
  {
    label: "Excavator Driver",
    value: "01d39b76-29fa-461c-8090-93f01de2716a",
  },
  {
    label: "Exhaust Fitter",
    value: "dc110484-9f03-46c9-a877-90e66c3de221",
  },
  {
    label: "Exhausterman",
    value: "62aa69d5-d047-4d07-b6ee-a5d750ed40e3",
  },
  {
    label: "Exhibition Foreman",
    value: "243f4e5a-0ec5-496e-be07-1dd794f364bd",
  },
  {
    label: "Exhibition Space Sales Manager",
    value: "21cec05c-1262-49e3-93f8-159e2bd8d65c",
  },
  {
    label: "Exhibition Stand Fitter",
    value: "523fb187-285b-4462-9a41-3f4e7a21b4ac",
  },
  {
    label: "Explosives Inspector",
    value: "2c55ace4-3e11-4286-bf5e-6fb0138b4ae0",
  },
  {
    label: "Export Agent",
    value: "5bd9b41d-64b7-4920-94ad-4a082b79223f",
  },
  {
    label: "Extruder",
    value: "3bde5cb4-f8da-490d-bc86-40e76b4f7c80",
  },
  {
    label: "Fabric Designer",
    value: "b5a2805b-6419-40d6-bad0-ea902805686f",
  },
  {
    label: "Fabricator - welder/fitter",
    value: "20732a96-67e2-4771-9a23-f29da54c0824",
  },
  {
    label: "Facilities Assistant",
    value: "02142b21-625d-4cdf-9dbc-0b2e4a3aecbf",
  },
  {
    label: "Facilities Procurement Officer",
    value: "7e1d767f-9f1f-492c-92e0-ff096eaee3d0",
  },
  {
    label: "Factory (worker)",
    value: "18f1c46b-d89f-4d26-bcfc-8b1716023be2",
  },
  {
    label: "Factory Inspector",
    value: "5cf66f06-cc3b-4f49-b28f-4d8b2d8fcd62",
  },
  {
    label: "Factory Manager (mainly admin.)",
    value: "b1a8feec-1c39-4439-80c8-8ec0a3c7551b",
  },
  {
    label: "Fairground Worker",
    value: "d803086e-6011-4af2-9ab8-72c9aa905ef5",
  },
  {
    label: "Farm Manager (manual duties)",
    value: "fd011439-6ac1-41bd-851b-5f739171d77f",
  },
  {
    label: "Farm Manager (no manual duties)",
    value: "2e046a1f-75ef-4a72-a86a-93cb985c8bb1",
  },
  {
    label: "Farm Owner (manual duties)",
    value: "d6879ed2-77f2-4659-a4ed-60dd37bd0c3c",
  },
  {
    label: "Farm Owner (no manual duties)",
    value: "dbda1470-c8dd-4e7b-9269-8b434f747464",
  },
  {
    label: "Farm Worker/Labourer",
    value: "47a2d07a-2b5a-4819-a8d5-1eb05ed8b397",
  },
  {
    label: "Farrier",
    value: "3360e6b0-6622-4efa-a782-bed4e227cab4",
  },
  {
    label: "Fashion Model",
    value: "c096aea6-7ad7-4c0e-ae93-94416ba069bf",
  },
  {
    label: "Fashion Photographer",
    value: "7062a3f6-329e-4151-bcd6-80404c060fb9",
  },
  {
    label: "Fashion Stylist",
    value: "1b0090bf-04ca-45cb-9dcd-ce5a04f19e0e",
  },
  {
    label: "Fast Food Restaurant Assistant",
    value: "207314bb-cd45-4682-9601-05ef2370127f",
  },
  {
    label: "Fast Food Restaurant Manager (admin. only)",
    value: "9734ca63-1135-45ec-ad23-17254ca815b2",
  },
  {
    label: "Fat Extractor Man",
    value: "34b1707e-65e1-4b95-bc0f-70b596548163",
  },
  {
    label: "Fencing Contractor",
    value: "ff55f467-eb7e-4945-9e6d-692f8c78df61",
  },
  {
    label: "Ferryman",
    value: "86fba526-d266-48c4-8767-6a72ea1d3155",
  },
  {
    label: "Fight Arranger",
    value: "0b8a2f49-ffc7-4946-b2ce-1e20db46bb91",
  },
  {
    label: "Film Developer",
    value: "83f22b3e-da4e-4fde-a57a-30934a5eec8e",
  },
  {
    label: "Film Joiner",
    value: "c587c521-5b7e-49d9-80d7-4c87be7c2438",
  },
  {
    label: "Film Processor",
    value: "8c0aa7b1-738b-4547-b900-74c7e3db76e8",
  },
  {
    label: "Filmsetting Machine Operator",
    value: "7edf4906-b869-44a7-8b06-e2658484c910",
  },
  {
    label: "Financial Adviser",
    value: "48cb8d44-e95f-4faa-a3b0-2d853ff1e519",
  },
  {
    label: "Financial Planner/Paraplanner",
    value: "61cfcc01-7a5a-48bf-b7b6-5da5e019b3ed",
  },
  {
    label: "Finisher - toys and textiles",
    value: "ab9f5363-1fba-41c5-8d12-b61ed4ea94f3",
  },
  {
    label: "Fire Eater",
    value: "538b8755-9ce6-4bb1-bc28-913dac25d439",
  },
  {
    label: "Fire Prevention Officer",
    value: "e20344fe-48b3-4640-a88f-6c78d056a094",
  },
  {
    label: "Firefighter - Fire Service",
    value: "ca769aec-227d-4070-86be-ef3869f93abc",
  },
  {
    label: "Fish Farmer",
    value: "bef98c70-0566-4544-bb28-38da0dc445ed",
  },
  {
    label: "Fish Preparer",
    value: "bd9e475b-a601-4254-bd36-52131a166d71",
  },
  {
    label: "Fish and chip shop owner",
    value: "44aeae37-2d7b-4f17-ae2c-240130c2a952",
  },
  {
    label: "Fish and chip shop worker",
    value: "de3f941d-a6c1-48bb-9bb9-5ee5fd976349",
  },
  {
    label: "Fisherman",
    value: "76b4ba6e-5762-43a0-b240-77675eb5644f",
  },
  {
    label: "Fishery Officer/Warden",
    value: "04f0eb43-7009-4606-bb3a-2a852c4423a3",
  },
  {
    label: "Fishmonger",
    value: "6ee4ef0f-5a05-492a-8626-329eec0e2c53",
  },
  {
    label: "Fitness instructor",
    value: "ccd3632d-4f55-49fc-bc1f-86fc02344baa",
  },
  {
    label: "Fitter - Motor Vehicle & Cycle Industry",
    value: "178cabce-4d3e-4716-bcb0-7b349a6534dd",
  },
  {
    label: "Fitter-Assembler",
    value: "62b76244-b69f-40f8-8aa1-61eab66021c3",
  },
  {
    label: "Fixer Mason",
    value: "42433a22-b999-4857-8a66-6b0ba3618511",
  },
  {
    label: "Flame cutter - 40 ft +",
    value: "91fb46ef-14ef-460a-b414-7809921ed1f1",
  },
  {
    label: "Flame cutter - under 40 ft",
    value: "0489acc5-31ba-4823-8321-0ed7efc54dcb",
  },
  {
    label: "Flight Attendant",
    value: "456c9f7b-376c-4d1e-8e4a-123e01c28d7c",
  },
  {
    label: "Flight Dispatcher",
    value: "80a99de6-b707-449d-a70f-fcc58f08bf1f",
  },
  {
    label: "Flight Operations Manager",
    value: "7689ae24-b8be-4843-848b-3fc4369d6610",
  },
  {
    label: "Flight Planner",
    value: "f3297600-a9d4-49cb-90f2-ad2ad28cfe36",
  },
  {
    label: "Floor Layer",
    value: "e963a7f1-6b6c-4347-9cf9-7f0ea2977fde",
  },
  {
    label: "Floor Manager",
    value: "a7cb4c82-99ef-48b2-a1a3-2420e9416148",
  },
  {
    label: "Floor Tiler",
    value: "5486940f-afab-488c-bca5-169748a1c5ae",
  },
  {
    label: "Floorman",
    value: "daabf8af-8c7f-4b30-9a3f-536c24dead98",
  },
  {
    label: "Floorman - Oil Rig Industry",
    value: "6aaf296f-2a4b-4849-a5d4-0ceccdac47bf",
  },
  {
    label: "Florist",
    value: "9ebe9939-a4c7-4b99-9478-193cb44cbbb5",
  },
  {
    label: "Flour Confectioner",
    value: "f20ba7fa-50fc-45f8-8eb1-ddaa26e2c866",
  },
  {
    label: "Food Technologist",
    value: "29d66c67-e26d-4b4a-8826-a78788d1e984",
  },
  {
    label: "Football Manager - Professional players",
    value: "f85c99c4-6ccb-4b82-8267-3f4e10e6bde8",
  },
  {
    label: "Forecourt Attendant",
    value: "9c6ed638-0202-4bc4-b4f4-02cc536c1d28",
  },
  {
    label: "Foreman - heavy manual",
    value: "b2698bca-2120-49a0-977b-ed97d0439ea3",
  },
  {
    label: "Foreman - light manual",
    value: "8dc66d4d-e9c8-4790-85d8-bb0887c85bbf",
  },
  {
    label: "Foreman - no manual",
    value: "7db19c9d-17a0-4122-a717-63eb2fce093f",
  },
  {
    label: "Foreman - offshore",
    value: "55469a26-202b-4d07-ad1b-9666a46e3a26",
  },
  {
    label: "Forest Ranger",
    value: "5697433b-d93b-4131-8ba7-375a98001ea6",
  },
  {
    label: "Forest Worker",
    value: "c00268a1-e955-4bf7-8e79-99d2ee1ba6a3",
  },
  {
    label: "Forestry Officer",
    value: "553ac0be-bfd2-4e86-a067-84d10033c98b",
  },
  {
    label: "Forge Hammerman",
    value: "42d1bf96-57f3-4d11-9abf-f0c8ad6f2a29",
  },
  {
    label: "Forge Pressman",
    value: "0c5e4ec2-3245-4d06-b52e-09e25ea16c9e",
  },
  {
    label: "Forger",
    value: "f21629b1-1c00-41f7-898d-edd13a8f03fd",
  },
  {
    label: "Fork Lift Truck Driver",
    value: "f1b51299-c9ca-451e-a8fe-cdbca339c089",
  },
  {
    label: "Fortune Teller",
    value: "ee4a45d9-d2e7-4df6-b437-a20d7646ae65",
  },
  {
    label: "Foster Parent",
    value: "9f9b45e9-b8b6-4c18-810a-c9a49f60b81e",
  },
  {
    label: "Frame Finisher",
    value: "65a11f17-02ac-4d19-943b-0960c69a285c",
  },
  {
    label: "Freezer Operator",
    value: "88ad1f20-20c7-4990-86e8-12b2fdaf9a3f",
  },
  {
    label: "Freight Clerk",
    value: "e5fb6097-58eb-4edf-9fbf-0242f13ad143",
  },
  {
    label: "Freight Manager - Airport",
    value: "9321e072-0c7a-4b0b-a75f-86456bd0979f",
  },
  {
    label: "Freight Manager - Docks",
    value: "7b088d24-4b9b-44b5-b7e1-28585435ee7e",
  },
  {
    label: "French Polisher",
    value: "1001e960-dc4b-4d29-97d4-ae1bf1af6014",
  },
  {
    label: "Fruitier",
    value: "75a38491-1152-446b-abec-5677531cb2dc",
  },
  {
    label: "Fuel Technologist",
    value: "29f208ee-5408-453c-9c65-b3ad85ca27b3",
  },
  {
    label: "Funeral Director",
    value: "90e1e458-ca85-4fdb-b70b-0dbfe8ed56be",
  },
  {
    label: "Funeral Director's Assistant",
    value: "c8657ae6-a060-4d73-bc37-17fdca22ac11",
  },
  {
    label: "Furnace Control Room Operator",
    value: "9aa34630-f722-46a9-b96f-c913ad110518",
  },
  {
    label: "Furnace Operator - Cemetery, Crematorium",
    value: "7cf83a94-1022-48d1-ad97-8e4f756594e2",
  },
  {
    label: "Furnace Operator - Other",
    value: "e80befd1-3265-4b24-a771-cb939b482edd",
  },
  {
    label: "Furniture Designer",
    value: "102d4caa-9ac8-43e6-ae81-07ae6951d779",
  },
  {
    label: "Furniture Maker",
    value: "91d10a75-3d9e-4b92-b0f8-4a24fcfa211c",
  },
  {
    label: "Furniture Remover",
    value: "e110a864-167b-4c92-9ce0-229dfa9af811",
  },
  {
    label: "Furniture Restorer",
    value: "1c3e4f50-fd50-4ecf-9904-e13264e0e177",
  },
  {
    label: "Furniture Retailer",
    value: "793ee86a-4130-4ade-a42c-7f88dd99266c",
  },
  {
    label: "GP - general practitioner - Doctor",
    value: "70169e61-46bc-4c28-950c-51ee612b03d2",
  },
  {
    label: "Gallery Assistant",
    value: "47b97fdd-9632-4785-969b-e5ac5701a85e",
  },
  {
    label: "Gallery Owner (admin. only)",
    value: "0295d62d-4891-4441-b3c9-6bac40c3fb1f",
  },
  {
    label: "Gallery Owner (including manual work)",
    value: "c6798c94-a866-4767-b5bf-9bffcd93d469",
  },
  {
    label: "Galley Hand",
    value: "e76f598b-f1a9-4377-913e-24281cf5e88e",
  },
  {
    label: "Galvaniser",
    value: "e052dedf-0e57-4463-afd5-c645a8f44b44",
  },
  {
    label: "Gamekeeper",
    value: "f6557aee-bc13-4c0b-9357-41d32ea57148",
  },
  {
    label: "Ganger",
    value: "7d3f413d-b436-42b1-ad7a-eb63c6e054fc",
  },
  {
    label: "Gantry Crane Driver",
    value: "bfa475d1-03b4-494f-87b5-f836e7b0fa21",
  },
  {
    label: "Garage - Mechanic",
    value: "c8f596fb-3920-4822-ae7d-aa6f0642c5da",
  },
  {
    label: "Garage - Petrol Pump Attendant",
    value: "d3eff46a-0638-4632-88ce-5dfc88cab517",
  },
  {
    label: "Garage Proprietor - admin only",
    value: "07d0e189-e3bc-42ae-98ca-7a1359dbbba3",
  },
  {
    label: "Garage Repair Shop Supervisor (including manual duties)",
    value: "c64f0d02-194b-4225-8344-3052978cc6f2",
  },
  {
    label: "Gardener",
    value: "3438fe1c-84e9-4896-aa11-5551bb97315c",
  },
  {
    label: "Gas Appliance Mechanic",
    value: "c2cfe434-52f9-4104-809b-122b510321ab",
  },
  {
    label: "Gas Compressor Operator",
    value: "2a703f28-e3f5-46eb-8253-35247db8f1db",
  },
  {
    label: "Gas Fitter",
    value: "2fedaa85-a4c4-4515-97cb-dddb00db0341",
  },
  {
    label: "Gas Meter Tester",
    value: "69a8c033-bc47-48ed-a6e2-3f73145d6221",
  },
  {
    label: "Gem Cutter",
    value: "0b8651b6-45ca-4cf5-a1e5-f704f27c8438",
  },
  {
    label: "Gem Polisher",
    value: "7aa20c94-6ace-4f90-b00e-2321d16c64ae",
  },
  {
    label: "Gem Setter",
    value: "ccd2cda1-9dad-41e2-8a9f-22b4b22d2e9d",
  },
  {
    label: "Geologist - Mining",
    value: "2b1c63eb-f682-4319-886e-7b02460f601b",
  },
  {
    label: "Geologist - Oil & Natural Gas Industries",
    value: "44710807-468e-4d09-a834-80e5135b3294",
  },
  {
    label: "Geologist - no aerial/offshore etc",
    value: "6c6f9a1c-acec-44a3-86bb-525a87e6e96c",
  },
  {
    label: "Geophysicist - Mining",
    value: "4da16056-be2f-4890-ab38-78f4a12f28d9",
  },
  {
    label: "Geophysicist - Oil & Natural Gas Industries",
    value: "958f74f5-5a8c-4a96-af03-d87d0d6c4a12",
  },
  {
    label: "Glamour Model",
    value: "0f577952-74a9-4428-8f28-677e7a39eaa1",
  },
  {
    label: "Glass Blower",
    value: "afa69333-7fc0-4345-9e02-c21bb95e02e3",
  },
  {
    label: "Glass Worker",
    value: "0e56156e-7b75-4327-9f23-722b7823bb39",
  },
  {
    label: "Glazer",
    value: "2ae55dd2-fb14-46a8-8166-b1417549226b",
  },
  {
    label: "Glazier",
    value: "93835db9-c31b-4492-a751-fbddc532ddb0",
  },
  {
    label: "Gold Beater",
    value: "4c9f41fb-3b22-4288-a13d-28fc26fff6db",
  },
  {
    label: "Goldsmith",
    value: "48f88613-51c6-49e6-9dde-8e229915402e",
  },
  {
    label: "Golf - Caddie",
    value: "d3335559-86e9-443c-aa28-c989833128a0",
  },
  {
    label: "Governor",
    value: "bf168bb2-7c52-4b8e-a9b9-2935226d088c",
  },
  {
    label: "Grader",
    value: "70458f8d-3b69-4f00-aeba-8ba179511752",
  },
  {
    label: "Grain Merchant (office based)",
    value: "ceda19c3-628a-4df9-a17a-0783e5fb2848",
  },
  {
    label: "Graphic Designer",
    value: "bc646724-6e50-42a8-b4f8-3e30b61f5224",
  },
  {
    label: "Grave Digger",
    value: "6bc159a6-7b93-4475-9aad-fbe4f7e874b3",
  },
  {
    label: "Greaser",
    value: "2dba859a-edbf-4df0-94c8-c34e075f00f9",
  },
  {
    label: "Greenkeeper",
    value: "ad0d599a-f818-47e5-83f9-a47d811b3c3c",
  },
  {
    label: "Grinder",
    value: "27b9e4c8-3830-47c9-9dd3-e5a6c7c58b6b",
  },
  {
    label: "Grocer",
    value: "532b80bb-d88a-4b92-bec7-4ad59b7bc18e",
  },
  {
    label: "Groom",
    value: "39532aed-bc78-40e6-97d7-7f279ba84936",
  },
  {
    label: "Ground Equipment Service Mechanic",
    value: "c494f5f8-93b5-49cf-9a0b-5bb73a152982",
  },
  {
    label: "Ground Hostess/Steward",
    value: "844c04fd-8c6b-4a1c-9d8c-3e607a59113e",
  },
  {
    label: "Ground Movement Controller",
    value: "ba484566-1ebd-4209-ae0c-b6fe366fc54f",
  },
  {
    label: "Groundsman",
    value: "d2199ceb-3cf8-4c5e-b9fa-d5f9bd0fb712",
  },
  {
    label: "Groundworker",
    value: "41b9bd7c-6d61-416e-86d8-5ef9026aeaed",
  },
  {
    label: "Guard - railway",
    value: "f8f9316e-e4cb-4cab-a0d1-a9edf577e636",
  },
  {
    label: "Guard - security",
    value: "0f5943a6-acb1-44c2-b3db-fcbf4aa52159",
  },
  {
    label: "Guest House Proprietor",
    value: "1290fa13-4b63-4214-8e5d-ed4ab5e00bdc",
  },
  {
    label: "Guest House Proprietor (admin. only)",
    value: "7f5dcb0c-f775-4e9a-9785-353ebdf3c4f2",
  },
  {
    label: "Guillotine Operator",
    value: "3a43d548-436d-4fa2-8d55-0adb7aee2c28",
  },
  {
    label: "Gummer",
    value: "91d9d3b2-0c7f-4259-b6bd-6dd0e32485ba",
  },
  {
    label: "Gunsmith",
    value: "c01acec6-d330-4f98-8e98-466e6a617e7f",
  },
  {
    label: "Haberdasher",
    value: "ee53af9e-3479-4362-ae81-e9eb38915f95",
  },
  {
    label: "Hairdresser - Mobile",
    value: "633a77e3-6284-4042-9ddf-eff99bdec08d",
  },
  {
    label: "Hairdresser - Salon",
    value: "edc1cc74-f44e-4011-9cf4-203442d5f65b",
  },
  {
    label: "Hairdresser Shop Manager - admin only",
    value: "23ebd891-ea5d-460d-9f06-84df3ae0c02b",
  },
  {
    label: "Hairdresser Shop Proprietor",
    value: "c7750ad5-bed3-485e-9425-2175a26549f8",
  },
  {
    label: "Hammerman",
    value: "016a33b1-c58f-456d-a6d6-3a593f88a6d5",
  },
  {
    label: "Handyman",
    value: "638cae82-c73a-4e7d-b51b-cb0614f64c14",
  },
  {
    label: "Harbour Master",
    value: "d4d4d9b9-0959-40f1-9739-bb48f42c974f",
  },
  {
    label: "Harbour Pilot",
    value: "341bc0b8-6d55-49ed-ba8c-c48cc10074d7",
  },
  {
    label: "Hardware Shop Retailer",
    value: "7c12e36c-baa6-43f5-a554-71ff2b664ea0",
  },
  {
    label: "Harness Maker",
    value: "0cf5a1ee-a48d-4dde-90de-6673c064c788",
  },
  {
    label: "Hat Maker",
    value: "a53100b2-ff5a-440b-9b78-aa4fc6d4d9b1",
  },
  {
    label: "Hatchery Worker",
    value: "7ea3001b-ac5a-4745-a8ca-3268594079df",
  },
  {
    label: "Haulage Contractor",
    value: "f6607725-f848-46ad-b923-cb4fb7e73942",
  },
  {
    label: "Haulier (no driving)",
    value: "e68d90f0-34e9-4b2b-a407-0754efa97c47",
  },
  {
    label: "Head Gardener",
    value: "cdac442c-502c-4d80-b4d8-64981d8bc657",
  },
  {
    label: "Head Groundsman",
    value: "c5501a66-25bf-4728-97a9-7955d1ac64c5",
  },
  {
    label: "Head Keeper - Zoo",
    value: "9f746e24-5ef1-4e61-9c66-dd2b35bcd68f",
  },
  {
    label: "Head Roustabout",
    value: "77d95af5-e2aa-41b6-bb87-3645d5130897",
  },
  {
    label: "Headteacher",
    value: "c13eee1a-8eac-4a02-b355-a1fb5603aa33",
  },
  {
    label: "Health & Safety Officer",
    value: "6491b8a1-346d-410b-b04c-83a2677aac7c",
  },
  {
    label: "Health Counsellor",
    value: "5d7df4e6-0e1a-4068-86fc-8d2d72e1dacc",
  },
  {
    label: "Health Radiation Monitor",
    value: "5f0b0c87-84ea-43d5-b12f-20b71f1298c1",
  },
  {
    label: "Health Visitor",
    value: "b54add69-b3d5-4b59-a5d9-ea56dca66ec9",
  },
  {
    label: "Health and Fitness Club Manager (admin. only)",
    value: "a7c8d033-15a5-4dc6-84a8-93faa71aa0cb",
  },
  {
    label: "Health and Fitness Club Trainer",
    value: "132d57e4-5d70-436a-93bf-312b4f424f86",
  },
  {
    label: "Heating and Ventilating Fitter",
    value: "55b03d72-c96d-4030-908c-7ba146c05e9f",
  },
  {
    label: "Heavy Goods Driver (no loading) UK only",
    value: "01004df0-a569-41e2-89ca-3a8f02317d84",
  },
  {
    label: "Heavy Goods Vehicle Driver",
    value: "98b23f43-4ca2-4855-b9bd-90cb14787e39",
  },
  {
    label: "Helicopter Engineer",
    value: "41db11c6-fb70-4117-a6a7-6abadfe61c50",
  },
  {
    label: "Helicopter Pilot - Oil Rig Industry",
    value: "f31ad771-7545-4d54-9bb4-e82abfb146bb",
  },
  {
    label: "Helicopter Pilot - Onshore",
    value: "f618b7df-6ddf-4c2f-a3ee-ecba44471a3b",
  },
  {
    label: "Historic Building Guide",
    value: "8a3bd61b-bf78-4072-8359-c6384bf3eafe",
  },
  {
    label: "Hod carrier - construction",
    value: "7ce3878d-57ce-4925-b444-cecd049573a8",
  },
  {
    label: "Hoist Driver",
    value: "906d5a40-3fb1-4131-b496-9e8cd6a91e7c",
  },
  {
    label: "Hoist Operator",
    value: "54f437c1-49c5-4b6a-80f9-320c5fb6fe01",
  },
  {
    label: "Holiday Representative",
    value: "1606c366-89b2-4491-929f-f0a4333d14ab",
  },
  {
    label: "Home Help",
    value: "20e3005b-ea73-4a47-a33a-cc14e190da1a",
  },
  {
    label: "Homeless Centre Manager (admin. only)",
    value: "6f9bb4fe-3964-4086-a24d-26effcc1f015",
  },
  {
    label: "Homeopath",
    value: "98b3f7d6-0e3f-4396-84a4-a04eee5fccfe",
  },
  {
    label: "Horse Breeder",
    value: "dc41fe2b-dfc5-4759-8f57-de1e5f95c201",
  },
  {
    label: "Horse Racing - Flat Jockey",
    value: "a5f17349-4160-41b8-973f-d3db48628200",
  },
  {
    label: "Horse racing - National Hunt",
    value: "2aa7fded-c359-4be4-bb6a-71411fe059a0",
  },
  {
    label: "Horticulturist",
    value: "ebbc34ea-3216-4eb7-b173-c5ab3005dbae",
  },
  {
    label: "Hospital Matron",
    value: "73325cc5-376d-4134-a5e5-d68753773e5a",
  },
  {
    label: "Hospital Porter - Health",
    value: "1c75d1cb-5420-40e5-8dfe-bc0a72ded446",
  },
  {
    label: "Hospital Storeman",
    value: "d33d9bfb-46bb-49fe-9186-262e27880046",
  },
  {
    label: "Hospital Ward Orderly",
    value: "8a7103ab-5b0d-4aa9-9bc6-92509269112f",
  },
  {
    label: "Hostel Matron",
    value: "956afa23-5f59-482a-9a42-b3b0f4974d10",
  },
  {
    label: "Hostel Warden",
    value: "3d63fa19-12e6-404b-b3de-b1a00f30f068",
  },
  {
    label: "Hostess",
    value: "253b3832-8208-46f5-b3aa-f60061c028e6",
  },
  {
    label: "Hotel Concierge",
    value: "06a131be-33df-4665-b2f2-e9bc417a526b",
  },
  {
    label: "Hotel Detective",
    value: "cea86da7-77e2-4c6e-b7ab-5f533cba2429",
  },
  {
    label: "Hotel Doorman",
    value: "628b09a3-8219-4e2a-8f14-c4d6b6da2f4b",
  },
  {
    label: "Hotel Maid",
    value: "7c88a5ca-49d7-43a7-b426-2c2503560dd9",
  },
  {
    label: "Hotel Manager (office based)",
    value: "2a8a470d-ae5f-4289-81e7-95104373a055",
  },
  {
    label: "Hotel Porter",
    value: "ae5ef336-935f-4467-9543-4ab0657d7fd8",
  },
  {
    label: "Hotel Proprietor",
    value: "5397b5de-b3cb-4813-94d0-910cce5b05e6",
  },
  {
    label: "Hotel Receptionist",
    value: "37abf3be-12b8-435a-a231-658f26d6f5ee",
  },
  {
    label: "House Maid",
    value: "f64bef66-e26f-453b-a156-b7da6cdfe17a",
  },
  {
    label: "Housekeeper",
    value: "c2a4ba91-f309-495d-8edc-cb688819a6b5",
  },
  {
    label: "Housewife/House-Husband",
    value: "3fddd7ca-bb99-41b3-9178-31bbe1067456",
  },
  {
    label: "Housing Association Development Manager (inc. site visits)",
    value: "21e158ce-8271-4d3e-89e4-bf9bf4cfc3a5",
  },
  {
    label: "Housing Inspector",
    value: "f06ece19-37a7-4240-9699-732cc070253c",
  },
  {
    label: "Housing Manager",
    value: "a4c923d3-6667-4d1c-a06f-55a9b1bff293",
  },
  {
    label: "Human Resources Advisor",
    value: "37464543-53e2-4884-b5ca-5bab8a4dafeb",
  },
  {
    label: "Human Resources Analyst",
    value: "557b5096-23a8-4921-b619-e8e83621709d",
  },
  {
    label: "Human Resources Assistant",
    value: "7588c65c-d51b-4606-8037-9bf16f1bc91a",
  },
  {
    label: "Human Resources Consultant",
    value: "3201ee79-440b-44a9-8158-cbcb35c60b3b",
  },
  {
    label: "Human Resources Officer",
    value: "3ae2bd14-102f-4f83-be64-9a4e49fd4b8d",
  },
  {
    label: "Hydro-Extractor Operator",
    value: "8965b39d-6f66-4ffd-92b2-4c23b0dee724",
  },
  {
    label: "Hydrographic Engineer/Surveyor",
    value: "c9c0037a-ef33-4603-94b6-6f504c08ad9d",
  },
  {
    label: "Hygienist",
    value: "58ca9ac3-c23f-41b4-9514-c61f0cc118fd",
  },
  {
    label: "Hypnotherapist",
    value: "4bdb0b92-3b38-4e51-adcd-d40cc6752cd7",
  },
  {
    label: "Hypnotist",
    value: "cdc76eda-45fc-4c7f-8ae4-182f62cc1e55",
  },
  {
    label: "IT Analyst",
    value: "d83f1a5b-a01c-4910-94b4-ebe806934002",
  },
  {
    label: "IT Manager - admin only",
    value: "cdc0f31f-1350-4f2e-924c-de5e49b8b166",
  },
  {
    label: "IT Programmer",
    value: "3a185806-b80b-4df8-aa8e-cdb062c5779b",
  },
  {
    label: "IT Technician",
    value: "5131f376-bf93-4ba1-996a-c35899e8022e",
  },
  {
    label: "Ice Cream Van Driver",
    value: "f7594c7e-1721-43b4-ab67-bce5a72f63a5",
  },
  {
    label: "Illusionist",
    value: "6de1bef9-2abd-4f70-afa2-6c9ced9894ef",
  },
  {
    label: "Illustrator",
    value: "86f4bda5-b72b-42d1-8cd8-8811786915d5",
  },
  {
    label: "Immigration Officer - admin only",
    value: "1426f5db-1aaf-4da6-a158-d62880dd7c62",
  },
  {
    label: "Immigration Officer - otherwise",
    value: "69d6cd4f-2b0e-461c-98b0-b88b3c396cb2",
  },
  {
    label: "Impersonator",
    value: "3294a539-5e21-4507-a75d-94507c94c269",
  },
  {
    label: "Importer",
    value: "6b340b45-2623-4f25-83df-5f92457eea08",
  },
  {
    label: "Incinerator Operator",
    value: "c92e0d98-2605-4ff1-a636-d48b826cc39f",
  },
  {
    label: "Independent Financial Adviser - IFA",
    value: "56892058-3e88-4b94-b1c9-7a575e17d5de",
  },
  {
    label: "Industrial Chemist",
    value: "483ebe61-dbd8-4f55-96fa-840e0c94293f",
  },
  {
    label: "Industrial Designer",
    value: "58a0127c-7ba1-4ec6-b898-15a9bb3ffe51",
  },
  {
    label: "Industrial Relations Officer",
    value: "765040ae-8e13-49c5-932e-308a5ffacbeb",
  },
  {
    label: "Industrial Trainer",
    value: "0fd18abd-4d02-41b9-ac55-361021a99386",
  },
  {
    label: "Inseminator",
    value: "b9bcb185-5bb6-4329-bc88-f28dc2a9f23e",
  },
  {
    label: "Inspector (not police)",
    value: "5a1d95ef-9f07-42ec-97e0-5d7f49b266f9",
  },
  {
    label: "Instructor - aviation, diving, etc",
    value: "1c9b113a-de7a-4ce5-820e-99c094267e41",
  },
  {
    label: "Instructor - no special risks",
    value: "5a7c8270-1a8e-40cf-89b5-b0bedb248515",
  },
  {
    label: "Instrument Maker",
    value: "c2d16151-dbb6-4ce8-b39b-2f58d1bd24ec",
  },
  {
    label: "Instrument Repairer",
    value: "69eb5df2-e09f-4295-add8-286ba1bc33e0",
  },
  {
    label: "Insulator - asbestos work inc",
    value: "e16070b4-d2f6-40e3-a9e4-2e5a541acc91",
  },
  {
    label: "Insulator - no asbestos work",
    value: "c56727c9-c8c2-4f93-8438-2aa5b7d0dd54",
  },
  {
    label: "Insurance Agent",
    value: "72d6b29b-2e11-42f7-a2fb-a3ebf2bec70e",
  },
  {
    label: "Insurance Assessor",
    value: "f49de59b-6941-4ea6-9188-468a9a8ed1e2",
  },
  {
    label: "Insurance Broker",
    value: "faa0c708-9aa0-4ce0-ba95-8220e5e145b1",
  },
  {
    label: "Insurance Inspector",
    value: "2169daf6-f4dd-4e2f-8d1b-934230559380",
  },
  {
    label: "Interior Designer",
    value: "4349395e-3815-4f9a-adff-11ed34e75749",
  },
  {
    label: "Internal Auditor",
    value: "51f7f271-8e0c-4505-ad0d-bfd051e676a7",
  },
  {
    label: "Interpreter",
    value: "de48585b-84ef-4d7d-998d-f42606a705df",
  },
  {
    label: "Investment Analyst",
    value: "08ce713d-d36d-479b-b2ef-ec580f0f357f",
  },
  {
    label: "Ironer",
    value: "2b4f84e6-5160-4611-8e10-2229e1b1397e",
  },
  {
    label: "Janitor",
    value: "c1f760cb-4c3a-4bef-8071-d66c930e6783",
  },
  {
    label: "Jetty Hand",
    value: "d831e52e-8989-4eea-a6cc-e7f66d17f8aa",
  },
  {
    label: "Jeweller",
    value: "7bda3403-fa52-4fce-bc4d-0bf559d857f3",
  },
  {
    label: "Jewellery Enameller",
    value: "71a6b9d2-a7d7-4ed6-b98a-92fbcef88b01",
  },
  {
    label: "Jewellery Making & Repair",
    value: "7e5e12a5-6f9a-433f-8942-37470fbd0010",
  },
  {
    label: "Jewellery Mounter",
    value: "568e9d5b-e16d-4d85-b4f2-31d5d7b77ece",
  },
  {
    label: "Joiner - Construction Industry",
    value: "6c13aa59-f7d4-4878-8483-bd3fafa54cb2",
  },
  {
    label: "Joiner - Ship Building, Ship Repair & Marine Engineering",
    value: "912b8b14-b449-4978-8035-c7532464eaa2",
  },
  {
    label: "Jointer",
    value: "9405067f-0940-4755-9ce9-89487529b610",
  },
  {
    label: "Journalist - no overseas travel etc",
    value: "d3315bce-3426-45fb-acbc-9a051900611c",
  },
  {
    label: "Journalist - otherwise",
    value: "4d1b88d9-1dc8-4d4d-b858-8656d4915dbe",
  },
  {
    label: "Judge",
    value: "baa997da-630a-44f0-becb-e2d02f9d3bf0",
  },
  {
    label: "Judge's Clerk",
    value: "237cc664-9e09-4e79-9b5f-e8977553e997",
  },
  {
    label: "Juggler",
    value: "7378e0e6-3251-4215-86ad-6ebd0c9fc7a5",
  },
  {
    label: "Justice of the Peace",
    value: "c1ef788b-0fd4-4158-ba43-e27103982b92",
  },
  {
    label: "Kebab van vendor",
    value: "2326958e-55eb-4ea4-96e9-58d06c26b8d2",
  },
  {
    label: "Keeper - Zoo",
    value: "92bd208a-0b64-444b-9919-3c9e5853344e",
  },
  {
    label: "Kennel Hand",
    value: "49c16811-5864-4dc7-ba4b-eb4d7eafa522",
  },
  {
    label: "Kerb Layer",
    value: "aa8ac80e-d7fb-4679-abf6-1b6d7e028d81",
  },
  {
    label: "Key Cutter",
    value: "ca3ddabf-2241-495c-b939-aca199775689",
  },
  {
    label: "Keyboard Operator (type setting)",
    value: "8a24998d-e83d-4b8d-bb01-6a28041006ac",
  },
  {
    label: "Kiln Attendant",
    value: "e59af078-0121-4ece-bbdf-a74b13b07305",
  },
  {
    label: "Kiln Operator",
    value: "92245da1-8f6d-48c2-9d1b-698ff90a1d24",
  },
  {
    label: "Kitchen Porter",
    value: "86857451-7608-40bc-8d69-d085a9bffc81",
  },
  {
    label: "Kitchen Staff",
    value: "0456b08b-2aef-4cf7-9e96-6ed902bc1fe3",
  },
  {
    label: "Knife Thrower",
    value: "bc28d85b-ed24-4ef4-b045-e2b759cac7ba",
  },
  {
    label: "Knitter",
    value: "022d68d9-1be1-49c4-b832-93246b631b4a",
  },
  {
    label: "Labeller",
    value: "c6290273-4898-4df1-a254-45efb5070a1c",
  },
  {
    label: "Laboratory Manager (supervisory and some testing)",
    value: "935b5921-e411-4c86-b281-c838803d31f0",
  },
  {
    label: "Laboratory Technician",
    value: "1a8dc952-f513-4095-9b65-7534f968309e",
  },
  {
    label: "Labourer",
    value: "9ddaf6f3-57fa-4596-b922-ba783494facf",
  },
  {
    label: "Lagger",
    value: "bdf192f5-b8f5-479f-83b1-5e598693d4c6",
  },
  {
    label: "Laminator",
    value: "d3f22651-586d-465b-ab05-bc1b58e2180b",
  },
  {
    label: "Land Agent",
    value: "4ebeed57-da91-4130-a686-a4a34f598997",
  },
  {
    label: "Land Drainage Worker",
    value: "36b27d6f-ad81-4c28-a651-d76e623ae344",
  },
  {
    label: "Land Surveyor",
    value: "d017e925-a0b5-4313-9283-1606f3581b46",
  },
  {
    label: "Landlord (Property -inc manual work)",
    value: "ad462a3c-87cd-44b9-bc2e-c15d302c42e5",
  },
  {
    label: "Landlord (Property -no manual work )",
    value: "4b7d2d27-108c-4d66-acab-1cb4cf4fdedf",
  },
  {
    label: "Landscape Gardener",
    value: "8b38f649-f9ec-473c-b771-1ec44964a5b4",
  },
  {
    label: "Landscape Painter",
    value: "643ef26d-b537-4a16-8982-e1a6c68e5e7d",
  },
  {
    label: "Lathe Operator",
    value: "2d70b8f3-ef19-4cc9-8803-782e628dbc4b",
  },
  {
    label: "Launderette Assistant",
    value: "54b608a3-0ba2-40fd-b37c-971eb98c4921",
  },
  {
    label: "Laundryman",
    value: "24c8326e-0ff4-4d6a-89fd-15562ceaaed6",
  },
  {
    label: "Lavatory Attendant",
    value: "34696b87-b56c-4d68-a809-cc28a6f6bd6f",
  },
  {
    label: "Lawyer",
    value: "f5bd3350-76eb-41f0-afb6-56167abc4177",
  },
  {
    label: "Leading Fireman",
    value: "eb4cdaa6-ce24-4a75-8124-5869b5db08b7",
  },
  {
    label: "Leather Technologist",
    value: "c6caa77b-66f1-4da0-bdf1-1367776b9eed",
  },
  {
    label: "Leather worker",
    value: "d4dcca90-880d-4701-b10f-4a42069ddea1",
  },
  {
    label: "Lecturer",
    value: "05eb161d-3a02-4df9-b247-f29167cecab1",
  },
  {
    label: "Left Luggage Attendant",
    value: "88e04f4c-b8c9-4c31-8241-2c08977348c5",
  },
  {
    label: "Legal Adviser",
    value: "57362871-fc85-4098-b6ac-e7b5552147a5",
  },
  {
    label: "Legal Executive",
    value: "f4f52204-0acf-4fe6-abae-214bb577ccfa",
  },
  {
    label: "Legal Practice Manager",
    value: "fb637280-ccbb-4689-80f8-b16556a80f14",
  },
  {
    label: "Legal Secretary",
    value: "d36e4c36-ace9-4d13-839d-6f23d0869e41",
  },
  {
    label: "Letting Agent - Holiday homes",
    value: "fb0c9c50-6278-4ee3-8d61-e27ceb0b7c1d",
  },
  {
    label: "Librarian",
    value: "c896e3f9-58ff-4014-833e-2d02049fe0ca",
  },
  {
    label: "Library Assistant",
    value: "3da6c419-20ee-4ae5-bd31-2a2e96fd9e4f",
  },
  {
    label: "Life Coach",
    value: "b74ea54f-a537-4772-b480-2d7547ee5b53",
  },
  {
    label: "Lifeboatman (enrolled crew)",
    value: "9034269d-c670-43d7-a08d-f7dc1f014773",
  },
  {
    label: "Lifeboatman - Crew",
    value: "d6a03135-e8dd-47d3-a152-59bcca7ad3c9",
  },
  {
    label: "Lifeguard",
    value: "1cad9c49-36bd-4df7-9206-389393fa3a79",
  },
  {
    label: "Lift Attendant",
    value: "c86d9730-eb78-4bb4-9a8d-7882008e1665",
  },
  {
    label: "Lift Engineer",
    value: "bcdb681a-6c9e-4faa-83d2-6b672d478632",
  },
  {
    label: "Lift Erector",
    value: "2d04bee2-a369-48b4-96cf-59f5338e8a1f",
  },
  {
    label: "Light Goods Vehicle Driver",
    value: "ad7943c6-1289-4e10-959f-682ba1e2797d",
  },
  {
    label: "Lighterman",
    value: "ff13d3c6-3e39-49d0-8dd2-ad6600337687",
  },
  {
    label: "Lighting Manager",
    value: "c3a6fdb7-b26e-4eb2-8418-792eb9ba9680",
  },
  {
    label: "Lighting Technician",
    value: "920c126a-5d19-4fc5-8d38-2c00120d9f12",
  },
  {
    label: "Linesman",
    value: "d807a9fc-1c1f-44c0-9fd5-e25bc571d73f",
  },
  {
    label: "Linesman's Mate",
    value: "4812d412-91e4-4e4e-8517-ef7531ddd8f3",
  },
  {
    label: "Liquidator",
    value: "210fe0ba-434f-496e-b222-458f2d856f02",
  },
  {
    label: "Literary Agent",
    value: "3e5e3bb5-b1ae-42d4-92b7-cf51cb7fc43b",
  },
  {
    label: "Lithographer",
    value: "a7160a71-90b5-4be8-b838-37af941b8e57",
  },
  {
    label: "Lithographic Assistant",
    value: "470b151f-55a5-4b21-8c39-d779ee18762b",
  },
  {
    label: "Lithographic Plate Grainer",
    value: "2b486836-7483-4b76-ad5d-7d237ffc91ac",
  },
  {
    label: "Lithographic Plate Preparer",
    value: "a13f8a8a-7eed-4b14-91b5-40e2aa042576",
  },
  {
    label: "Loader",
    value: "24cf9253-b3fa-4e4b-840b-13d42e28ae84",
  },
  {
    label: "Loader Operator",
    value: "88af1878-a017-4d44-88c3-0174e9a013a1",
  },
  {
    label: "Lobster Fisherman",
    value: "40f21e3b-c882-485e-bb54-c24db4690d77",
  },
  {
    label: "Local Government Officer",
    value: "98d9c7ff-539d-4109-94bc-9b0ed4c6e7b1",
  },
  {
    label: "Local Newspaper Editor",
    value: "899bed7f-8a5d-40a7-a097-1f1a9c17e0ad",
  },
  {
    label: "Lock Keeper",
    value: "2a99f395-48bc-471f-becf-1e6e52f6c7d4",
  },
  {
    label: "Locksmith",
    value: "d4cb0e75-1f8f-43ce-aec8-cbb968d25434",
  },
  {
    label: "Locomotive Driver",
    value: "dfa39777-cd32-4aa2-9da2-87f4c4e08de3",
  },
  {
    label: "Locomotive Guard",
    value: "d3403b9d-4f31-427b-a150-abc80facf13b",
  },
  {
    label: "Lollipop Man/Lady",
    value: "0d8ea979-9207-474c-a8c3-a057a4546417",
  },
  {
    label: "Loss Adjuster",
    value: "fe7db070-a788-483b-a6d7-0ba4525c996e",
  },
  {
    label: "Lumberjack",
    value: "5174aa89-4479-4e54-bd0e-2f2f5568a96f",
  },
  {
    label: "Machine Attendant",
    value: "7db8c9c9-e0c1-4c06-b3ab-eba1f5875f1e",
  },
  {
    label: "Machine Maintenance Worker",
    value: "e43ad0e8-35f5-47c9-941a-520be88fb8d4",
  },
  {
    label: "Machine Operator - processing",
    value: "5b3d144e-f664-41b3-b8a2-9916d5198e4f",
  },
  {
    label: "Machine Tool Setter-Operator",
    value: "a44753d3-864e-4161-bab4-d41ccb4ceb76",
  },
  {
    label: "Machinery Electrician",
    value: "f631d188-5bc2-4c1c-aa88-9d5c81ef7de2",
  },
  {
    label: "Machinist",
    value: "88989b7d-46e4-4054-b5c6-dd58755e323b",
  },
  {
    label: "Magazine Editor",
    value: "77ccf7d3-461f-4b1b-8b4f-b07a98fc26ac",
  },
  {
    label: "Magazine Illustrator",
    value: "6480e47b-1d86-4b70-b6e2-35a02ab44c0f",
  },
  {
    label: "Magazine Writer",
    value: "6e59355e-a25a-4619-870a-f6c8d9959acb",
  },
  {
    label: "Magician",
    value: "bcbffe5b-5e2b-421a-adb9-29632bdb2031",
  },
  {
    label: "Magistrate - Stipendiary",
    value: "a7b42ecd-914a-4e98-ab41-c8c57f77c5a6",
  },
  {
    label: "Mail Sorter",
    value: "20cba4a5-fe90-4df2-bcb0-2e963ed7fe36",
  },
  {
    label: "Maintenance Fitter",
    value: "1aefcf7c-006a-4ae4-a79e-c27b1945f2d4",
  },
  {
    label: "Maintenance Manager",
    value: "68e471e7-38e7-4145-afcf-7ace0fb3ba94",
  },
  {
    label: "Maintenance Repairer",
    value: "f867fb0f-5354-406a-b1cc-57b7da322185",
  },
  {
    label: "Maintenance Technician",
    value: "3d6265b2-42d0-486b-a90b-d7aac9619310",
  },
  {
    label: "Maitre d'Hotel",
    value: "d32ffd67-1b89-4579-98de-b0c93c024a47",
  },
  {
    label: "Make-up Artist",
    value: "8f207348-dc7c-495e-84c4-05a06d087cf7",
  },
  {
    label: "Malt Roaster",
    value: "eed8e7ee-f74b-4eb2-8cb1-a87cdedb4979",
  },
  {
    label: "Maltster",
    value: "4290d5a8-92b5-4283-9395-781a4082e46c",
  },
  {
    label: "Management Consultant - Usually",
    value: "c7bb2706-ddce-4f03-bdec-083ee7a56167",
  },
  {
    label: "Manager (off site)",
    value: "3703055b-5b3a-4b61-9181-b227ace23703",
  },
  {
    label: "Manager - Offshore",
    value: "01890192-06e1-4464-a711-7f69f3738855",
  },
  {
    label: "Manager - Retail",
    value: "b0b3ed08-6f8f-4b98-a6ca-d14a0f8658cb",
  },
  {
    label: "Manager - Sales",
    value: "1f691348-a566-4a9a-a8e9-d952824f3068",
  },
  {
    label: "Manager - admin only",
    value: "ecaf365d-dbe0-4a80-8966-626d2dc56e9e",
  },
  {
    label: "Manager - heavy manual work",
    value: "34f99018-4d0c-4d15-b644-0f472ca97f90",
  },
  {
    label: "Manager - light manual work",
    value: "30ec1690-78ee-4928-b5a6-3131a7404c1e",
  },
  {
    label: "Managing Director - Retail",
    value: "cba3ace3-a7ee-4d9d-bd83-e276bafb62b3",
  },
  {
    label: "Managing Director - Sales Management",
    value: "4afa67a8-0c03-47e6-bde5-8fba4361a74d",
  },
  {
    label: "Managing Director - Selling",
    value: "3e16e30a-91b2-4447-8ae0-453069ea2f86",
  },
  {
    label: "Managing Director - admin/office based only",
    value: "ccde75c9-2353-459e-b064-88ae85327e16",
  },
  {
    label: "Managing Director - heavy manual duties",
    value: "5ddbf5ba-7144-4291-81e1-b0b0bf72bed3",
  },
  {
    label: "Managing Director - light manual duties",
    value: "f9755af3-03cd-4cd8-ac95-37b20ec67684",
  },
  {
    label: "Manhole Maker",
    value: "120a4d52-fdba-457f-81df-0eb40d473c69",
  },
  {
    label: "Manicurist",
    value: "09e78cd8-6619-4b2f-bb8a-5ccdb220cc2f",
  },
  {
    label: "Marine Biologist",
    value: "b301154c-a807-49dc-9973-3288632426e4",
  },
  {
    label: "Marine Engineer",
    value: "282fcede-9938-4553-8126-42af1fea1322",
  },
  {
    label: "Marine Installation Fitter",
    value: "dc52f464-04da-4048-b1a2-f18ef85c6a2f",
  },
  {
    label: "Marine Surveyor",
    value: "4874852a-afcd-4246-8fc4-d331d3f854e8",
  },
  {
    label: "Market Gardener",
    value: "286ca125-cf89-457d-a4aa-f6e62c42b548",
  },
  {
    label: "Market Porter - Usually",
    value: "c706290c-e235-4519-8373-e97bddfee25d",
  },
  {
    label: "Market Research Analyst",
    value: "4fec7627-12ba-451a-b036-e2b279c7efc6",
  },
  {
    label: "Market Research Interviewer",
    value: "02d1bf3e-2d8f-4270-b9fc-e805b9a0a0a0",
  },
  {
    label: "Market Researcher (office based)",
    value: "c8158308-11c8-454f-8efb-26ca9f8753bf",
  },
  {
    label: "Market Researcher - Street research",
    value: "6651c83f-93e7-413c-ab0d-33224b64338c",
  },
  {
    label: "Market or Street Trader",
    value: "e0e58426-0b71-4f4c-ba09-1c51b5b4a1f3",
  },
  {
    label: "Market or Street Traders Assistant",
    value: "056b2069-cc25-4539-9dc6-4af35a6b7933",
  },
  {
    label: "Marketing Consultant - International",
    value: "5e1fa18c-15b8-4ef6-a21a-fb4b20d21cb3",
  },
  {
    label: "Marketing Manager",
    value: "736fca8a-ad1e-4759-a5ad-0a15d13cf063",
  },
  {
    label: "Marketing Research Manager (office based)",
    value: "4a1a71ee-28e8-4645-91f9-43983d40a905",
  },
  {
    label: "Marriage Guidance Counsellor",
    value: "ed560d5e-6954-4f5b-b482-da92c4318bb1",
  },
  {
    label: "Martial Arts Instructor",
    value: "5bb0b9d3-d7e0-4a2c-bf27-c297721919e9",
  },
  {
    label: "Mason",
    value: "3e0c4d20-4575-4e26-ba4b-35d2be5b5ae7",
  },
  {
    label: "Mason Bricklayer",
    value: "cbe64729-bf40-48da-96e0-db262e8a9433",
  },
  {
    label: "Mason's Labourer",
    value: "f3161264-5f81-42ac-8c23-de933224fbe8",
  },
  {
    label: "Masseur",
    value: "db0c6261-73f6-4d9e-95a4-5fa2703819a0",
  },
  {
    label: "Masseuse",
    value: "4acb24db-fde6-4745-a7b1-76c2dc03be0e",
  },
  {
    label: "Mate, Second Hand",
    value: "07be8fe1-6217-4be8-b645-e420ed6d0066",
  },
  {
    label: "Mathematician",
    value: "8dbb4715-651b-4ccb-a706-ba54c1748bea",
  },
  {
    label: "Mattress Maker",
    value: "719bd2c3-98f7-4517-94f3-765bf9d6047f",
  },
  {
    label: "Meat Cutter",
    value: "3cd53688-5bf8-4e4b-b29b-7866d9af281a",
  },
  {
    label: "Meat Inspector",
    value: "319e34c6-8820-405e-aa77-b67ce48d172b",
  },
  {
    label: "Meat Trimmer",
    value: "da4cdec8-be7b-4f3f-9f7d-5d961a5acfc7",
  },
  {
    label: "Mechanic",
    value: "c50b7dbf-d8f0-439b-8adf-7fc2ee821c00",
  },
  {
    label: "Mechanic - Oil Rig",
    value: "927d32a5-1c7d-4c32-849f-f0900cb5b1c2",
  },
  {
    label: "Mechanical Engineer",
    value: "b10f7ca1-5c8d-49f5-afdc-3f41001e5dcf",
  },
  {
    label: "Medical Practitioner",
    value: "b77251f0-c266-4fd5-9d81-be8f412732db",
  },
  {
    label: "Medical Receptionist",
    value: "69dbbfad-81ac-4b32-9b7c-76d2b09135e6",
  },
  {
    label: "Medical Secretary",
    value: "2b8b5dbf-c5ba-47be-ab1a-5009324a3756",
  },
  {
    label: "Medium",
    value: "537496a7-8be3-49b5-b50e-405059ae702c",
  },
  {
    label: "Member of Parliament, Politician",
    value: "251131ae-9c12-49dd-b75b-b6443907de19",
  },
  {
    label: "Messenger - Motorcycle",
    value: "058421e8-3746-4b2d-9e7a-ed9309089cb9",
  },
  {
    label: "Messenger - Not motorcycle",
    value: "c798e36c-acf7-458f-a8f2-ce636cbe560a",
  },
  {
    label: "Metallographer",
    value: "1b60565c-59b4-462d-af1b-64fe9134c9e5",
  },
  {
    label: "Metallurgist",
    value: "fc1f4d46-b006-4ea6-a0fd-87fc9bb3598d",
  },
  {
    label: "Meteorologist",
    value: "03995aa1-1a45-43bc-b87f-f05d6c2ac091",
  },
  {
    label: "Meter Collector",
    value: "1de0f3cd-dfda-4f31-bf06-dfb78e9b95da",
  },
  {
    label: "Meter Fixer/Tester",
    value: "c88b4668-c62d-46df-9d44-33d7e5a9a6c0",
  },
  {
    label: "Meter Reader",
    value: "cb7bdba1-6b08-4f6d-afa9-fcaa4294af23",
  },
  {
    label: "Microfilm Operator",
    value: "a39ae905-38f0-4b6f-9d90-90998cb103eb",
  },
  {
    label: "Midwife",
    value: "b658f149-f1a9-41df-9203-c8e5235a7657",
  },
  {
    label: "Milk Roundsman",
    value: "aa70643e-cedc-43e8-b582-a77700237cd4",
  },
  {
    label: "Milker",
    value: "0324b50e-2580-47c4-9ea8-870d87e0b651",
  },
  {
    label: "Miller",
    value: "fba40d45-f5af-43bb-b6a7-ec48007e7a92",
  },
  {
    label: "Milliner",
    value: "dc0372f6-ff96-49bf-907a-24207bfbf694",
  },
  {
    label: "Miner",
    value: "8cc98276-662b-4646-9a50-ab9732661b66",
  },
  {
    label: "Mineralogist",
    value: "06661903-5383-47d4-a565-5b9a495e45c0",
  },
  {
    label: "Mini Cab Driver",
    value: "255903aa-de81-4f7d-994d-c46a77876a77",
  },
  {
    label: "Mining Engineer",
    value: "ca8b97a8-4247-4860-9c7b-d9d09815b4c6",
  },
  {
    label: "Mining Officer",
    value: "75872cd0-e8f5-4d2a-8ed6-c6847101fe2b",
  },
  {
    label: "Mining Surveyor",
    value: "3c111cec-7265-473c-b12d-72e04ca296a0",
  },
  {
    label: "Minister of Religion",
    value: "d20d052d-d75b-471e-84da-f9bd643c44fc",
  },
  {
    label: "Missionary",
    value: "47034a52-976a-462e-86f1-a03c1c561b6e",
  },
  {
    label: "Mixer - processing",
    value: "49e71e9e-cb0f-4e35-8937-0256b1e699f1",
  },
  {
    label: "Mobile Crane Driver",
    value: "d7504c75-bfeb-4f7f-b27f-2210e7ef82f2",
  },
  {
    label: "Model Maker",
    value: "28027059-1705-4ad4-a327-f8074900193c",
  },
  {
    label: "Money Broker",
    value: "25ad8f07-a0e5-4fdc-92ef-19ffc9c0a999",
  },
  {
    label: "Mortuary Attendant",
    value: "9a7bff97-0e1a-474f-9586-fdd880bfff3b",
  },
  {
    label: "Motor Bike Instructor",
    value: "03add053-7e73-4f4f-bd62-86e67baaa9b4",
  },
  {
    label: "Motor Cycle Courier",
    value: "b523f69c-38f7-482c-a93a-6567922a4131",
  },
  {
    label: "Motor Cycle Messenger",
    value: "52c064c4-ba7a-45d0-b808-61a33b35b7b5",
  },
  {
    label: "Motor Fleet Manager",
    value: "432aa200-fb32-48ad-ba95-e1b0822b9f4c",
  },
  {
    label: "Motor Service Manager - admin only",
    value: "076cb594-f7b4-46b4-ae30-5084e31bca92",
  },
  {
    label: "Motorman",
    value: "887dfde9-d16b-49e5-b74d-aaa1a7990568",
  },
  {
    label: "Mould Maker",
    value: "0f5c3fa7-39d5-4aba-9f37-75b40f3afd7b",
  },
  {
    label: "Moulder",
    value: "198ddda7-5813-4744-af26-89504b23ea3d",
  },
  {
    label: "Mud Engineer",
    value: "9cff2f84-2e88-43f3-be2a-aa81a8e7e766",
  },
  {
    label: "Mud Logger",
    value: "0a9f9c46-fa9b-4023-abb0-2dcab96edc4b",
  },
  {
    label: "Mud Man",
    value: "f488cf8e-62c5-4d5f-ae1d-0b853404d634",
  },
  {
    label: "Museum Attendant",
    value: "ad333574-7086-4b36-8ae6-04bd493e4d2d",
  },
  {
    label: "Museum Curator",
    value: "0be2f5b6-e2fd-44b2-a952-7ea003885cb8",
  },
  {
    label: "Museum Guide",
    value: "4006e0bb-ad25-448f-b5e4-376533e611bd",
  },
  {
    label: "Music Teacher (Private)",
    value: "a8b89db2-3ecf-407e-b41b-d2165cfee29d",
  },
  {
    label: "Musical Instrument Maker",
    value: "80b35962-5da1-42a2-94a2-4305d0c2cce5",
  },
  {
    label: "Musical Instrument Repairer",
    value: "da666e9c-9a81-4fed-a71b-fbd2a3bc3879",
  },
  {
    label: "Musician - Professional",
    value: "f99e79f2-9fd4-40de-b7f0-3980520c822d",
  },
  {
    label: "NHS Manager",
    value: "16b3c2a0-e02b-4b20-80f2-a16e27627f08",
  },
  {
    label: "Nail Technician/Beautician",
    value: "03cd8a76-38d4-4b08-ab09-510ea38e9346",
  },
  {
    label: "Nanny",
    value: "8df2df48-113f-4fc6-af12-477732c62a46",
  },
  {
    label: "Nature Reserve Worker/Warden",
    value: "c58482d8-2be4-44e4-8486-b8b749cf54ab",
  },
  {
    label: "Navigator",
    value: "dc8d17ae-ea6a-4316-881e-91687fbfe77f",
  },
  {
    label: "News Photographer - no overseas travel etc",
    value: "e24432bb-090b-4260-9884-25f033546e73",
  },
  {
    label: "News Photographer - otherwise",
    value: "d49986d8-7da3-4e69-83a4-483e20c54126",
  },
  {
    label: "Newsagent (not delivering papers)",
    value: "c284cfca-fc6a-45ef-9ef6-88490684c77f",
  },
  {
    label: "Newspaper Reporter - Freelance",
    value: "d38e412d-6009-49cf-ba0d-9a5cae7f924e",
  },
  {
    label: "Newsreader",
    value: "e495d855-0efb-4ee5-b43d-46cbd30b0f1f",
  },
  {
    label: "Newsvendor",
    value: "9aae0054-e35f-4962-a657-3ce7d704575b",
  },
  {
    label: "Night Watchman",
    value: "e6f7a0d6-a056-413b-91ff-22fde391e526",
  },
  {
    label: "Nuclear Engineer",
    value: "6911cb18-61d7-49f7-a943-fa73c9b63353",
  },
  {
    label: "Nuclear Plant Attendant",
    value: "408bb8c6-40c7-476c-9b01-b6bf1192e9ad",
  },
  {
    label: "Nuclear Scientist",
    value: "97d2730b-e00f-4370-987b-1ff99b1b5ef9",
  },
  {
    label: "Nurse",
    value: "dd0fa5d0-fc88-43d0-b964-9fa4c4745592",
  },
  {
    label: "Nurse - Midwife",
    value: "333f5d19-2313-45d5-9b75-90f8e6d8bba5",
  },
  {
    label: "Nurse - Sister",
    value: "0a719395-ea87-4f57-8c31-70f8fb190e20",
  },
  {
    label: "Nurse - Teaching duties only",
    value: "14ecb428-76f1-40f8-a50b-e9fa4aaeff68",
  },
  {
    label: "Nursery School Assistant",
    value: "d6e4b58f-228e-49a6-98eb-c9ce9beacf75",
  },
  {
    label: "Nurseryman",
    value: "2202b1c8-bf53-49a7-9c7d-3786408ff5e4",
  },
  {
    label: "Nursing Auxiliary",
    value: "602816b6-690c-4677-a176-4c6fa8b7ac81",
  },
  {
    label: "Nursing Home Proprietor (admin. only)",
    value: "fab9f0c2-c110-4470-af28-0c9819c7be71",
  },
  {
    label: "Obstetrician",
    value: "f3f8c786-4f6d-4990-b529-a5f58c73ac52",
  },
  {
    label: "Occupational Therapist",
    value: "af5e1982-a57c-4b2c-b0da-9d5f9d77e294",
  },
  {
    label: "Oceanographer",
    value: "06cd73de-85f9-4a5e-a282-6cfce933b38a",
  },
  {
    label: "Off-Licence Employee",
    value: "6aff7162-b96f-4013-a7b8-48f46a0b2e13",
  },
  {
    label: "Off-Licence Manager",
    value: "a04760e2-c5bd-496f-95a3-ad5e095160db",
  },
  {
    label: "Office Clerk",
    value: "2513ba1d-4ece-44fe-9e53-6e0bda5f00f1",
  },
  {
    label: "Office Fitter",
    value: "120e3f98-d036-43b9-b4ac-74469aef948a",
  },
  {
    label: "Office Messenger",
    value: "af09cae1-41ae-43bf-96c7-bcc5b869d8f5",
  },
  {
    label: "Office Receptionist",
    value: "f1a8d574-731d-4196-b1ff-70e8c15bf8f9",
  },
  {
    label: "Oiler and Greaser",
    value: "9e8da023-219b-4af8-b1ee-4bc3a71d3a13",
  },
  {
    label: "Old People's Home Matron",
    value: "4d96dee3-caec-4987-a7e3-385dc3586310",
  },
  {
    label: "Old People's Home Warden",
    value: "0e96f703-8c8b-448e-b859-5fc2c2adb158",
  },
  {
    label: "Operations Manager - Light engineering company",
    value: "27519234-72c2-4dea-8b78-9b79bed90f16",
  },
  {
    label: "Operations Officer",
    value: "4c0a0e1b-d947-46c1-8fa3-7249ee9656f7",
  },
  {
    label: "Optical Instrument Fitter",
    value: "0a7ac5c1-406f-4f9c-abfe-cb7bd244f03c",
  },
  {
    label: "Optical Instrument Maker",
    value: "3baaffb2-59da-4ced-a7aa-b81ca6167fca",
  },
  {
    label: "Optical Instrument Mechanic",
    value: "b7851ecc-c80e-486e-9fbd-bcd2eae62d70",
  },
  {
    label: "Optical Instrument Repairer",
    value: "06dfe24d-7a64-4b43-9bfb-05e135e253a2",
  },
  {
    label: "Optical Printer",
    value: "3bf77f09-10f8-43a2-9e64-ecfab12ebf70",
  },
  {
    label: "Optician",
    value: "097ec44c-f36f-4938-b039-941afdbd4a76",
  },
  {
    label: "Opticians Assistant",
    value: "0bd1be79-7a5c-4de2-92c4-a04c80814da6",
  },
  {
    label: "Orchestrator",
    value: "e7638d65-dc26-41ba-85cf-4f252f89aea0",
  },
  {
    label: "Orthodontic Technician",
    value: "dba16fd2-602d-4223-a29a-d632842659aa",
  },
  {
    label: "Orthodontist",
    value: "ca620f0e-49af-4ff3-8223-a699531471b3",
  },
  {
    label: "Orthopaedic Surgeon",
    value: "19bb524a-c84c-48c9-899d-f8114f6bd1b3",
  },
  {
    label: "Orthoptist",
    value: "34f63ab1-1722-4eb2-a6bf-35b5bc278eb3",
  },
  {
    label: "Osteopath",
    value: "dc21a542-7af4-409c-9458-b79039ebde6d",
  },
  {
    label: "Other - Occupation not listed",
    value: "b59bca33-6c1d-4033-992d-de900ce7b205",
  },
  {
    label: "Outdoor Pursuits Centre Instructor",
    value: "6d837bc1-71d5-4ff7-b85a-f1a4eb7612ed",
  },
  {
    label: "Ovensman",
    value: "50cc134a-ee24-4719-8423-d646ef2109d4",
  },
  {
    label: "Overhead Crane Driver",
    value: "7098749b-3820-480e-a853-5d544aa4557b",
  },
  {
    label: "Overhead Linesman",
    value: "5abc470c-fd93-48e2-93f9-f03f69d4c014",
  },
  {
    label: "Overhead Linesman's Mate",
    value: "b74670f6-96ee-425d-9fe3-5289904d3def",
  },
  {
    label: "Oyster Fisherman",
    value: "fa92757b-990a-4222-9cd2-9041dfec58ae",
  },
  {
    label: "P E Teacher",
    value: "f78c35fc-7f32-4c79-9a5d-4200147f286c",
  },
  {
    label: "PR executive",
    value: "ac833f57-0e53-43ae-a5a2-cda093bb6037",
  },
  {
    label: "Packaging Machine Attendant",
    value: "dd6a4fec-693a-4aad-80a6-a849397f6676",
  },
  {
    label: "Packer",
    value: "affc0708-6f2a-4c28-993d-f405f1bd9bc3",
  },
  {
    label: "Painter",
    value: "7f6e81c5-21ec-485f-96f5-7233e94a6d13",
  },
  {
    label: "Painter & Declineorator (Interior)",
    value: "5ab9cb38-9be7-40fe-abad-d35d3098759d",
  },
  {
    label: "Painter (Exterior) - 40' up",
    value: "2d411fd8-f43b-4418-8392-1826bb0038e1",
  },
  {
    label: "Painter (Exterior) - up to 40'",
    value: "75174ca3-17ff-4ac0-bb60-f8749eaf4616",
  },
  {
    label: "Painter - Woodworking Industry",
    value: "cc228b9b-ef4c-4d6b-9b23-99fb0a50ad0d",
  },
  {
    label: "Panel Beater",
    value: "7ca7e0e4-4d50-4aeb-881f-794cef68ccf8",
  },
  {
    label: "Paper Maker (hand)",
    value: "09328535-bac7-4a96-94e8-6cf531649e23",
  },
  {
    label: "Paper Merchant",
    value: "0caee824-ca80-4482-90c0-ba2a50431e1c",
  },
  {
    label: "Paramedic (Driver)",
    value: "3b9b22ed-1d60-4583-90fa-0aa05981ea5c",
  },
  {
    label: "Paramedic (No driving)",
    value: "ce62b447-761a-4aca-bb7b-2bca8fb95f80",
  },
  {
    label: "Park Keeper",
    value: "e62a770f-f108-40b3-a2d6-19f648c101bc",
  },
  {
    label: "Park Ranger",
    value: "6f786702-8999-4953-ab00-b302e6b51e41",
  },
  {
    label: "Parks Superintendent",
    value: "97959fa6-7cb5-44c9-9db5-71c4dc4e86ea",
  },
  {
    label: "Parliamentary Agent",
    value: "b1594b4e-2c2d-47ff-8b50-ff8d91c45b81",
  },
  {
    label: "Party Organiser",
    value: "5902c537-b735-43e2-bfb0-3ddbee61e8bc",
  },
  {
    label: "Passenger Officer",
    value: "8ea1dd45-3e22-45d3-b7b7-0ac5454e963d",
  },
  {
    label: "Pasteuriser",
    value: "c1d1b601-99b0-4d24-8743-1d4d1819aef0",
  },
  {
    label: "Patent Agent",
    value: "2636b95b-1ab5-4681-b34c-6dc96d6d269b",
  },
  {
    label: "Pathologist",
    value: "abf3db34-173f-4ea2-a276-d907b39dd432",
  },
  {
    label: "Pattern Maker - Metal, Plastic etc",
    value: "136988e2-aca6-4041-a2bc-ad8411e5cf3c",
  },
  {
    label: "Pavior",
    value: "b315074f-2d6e-4fa9-a547-6411d5709733",
  },
  {
    label: "Pawnbroker",
    value: "386b2992-f5c8-471d-aef3-ce013384a934",
  },
  {
    label: "Pedicurist",
    value: "ebcfd53b-ed3f-4adc-8930-ac400aa78d3f",
  },
  {
    label: "Personal Assistant (PA)",
    value: "f640972d-6eba-424e-bd8b-3917d02d1482",
  },
  {
    label: "Pest Control Manager",
    value: "e3b3dd09-f160-4b5a-a163-217cd284e957",
  },
  {
    label: "Pest Control Operator",
    value: "5e94d0b6-a584-42d8-b857-17870821a556",
  },
  {
    label: "Petrol Pump Attendant",
    value: "0fe13840-20cb-4d0c-aa3f-faf780a79a7d",
  },
  {
    label: "Pharmacist",
    value: "f9c23744-f0ef-4994-a189-3fef52dfc9ef",
  },
  {
    label: "Pharmacologist",
    value: "c2102a65-ac18-4d67-920a-233ac59bb37d",
  },
  {
    label: "Pharmacy Assistant",
    value: "54b31e7c-bff6-4423-84dc-4dcebf9b4d89",
  },
  {
    label: "Phlebotomist",
    value: "aa34ecb9-ea88-4dce-b23e-f9a1213aa178",
  },
  {
    label: "Photocopier Engineer",
    value: "191da16b-50bf-479b-b0ac-8f31527812b7",
  },
  {
    label: "Photocopying Machine Operator",
    value: "fa41ba7a-c7b7-4f15-89ab-ff787812f7d1",
  },
  {
    label: "Photographer",
    value: "eb380deb-68a2-4aa4-9ae8-43179e3f4d10",
  },
  {
    label: "Photographer - Aerial",
    value: "11bcd6ce-2812-441a-8fe0-15b1b4adaaf1",
  },
  {
    label: "Photographic Finisher",
    value: "988cf5fe-a096-4b63-a170-823627091684",
  },
  {
    label: "Photographic Model",
    value: "66a498a6-61e0-444b-b0ce-a61b24e2d08b",
  },
  {
    label: "Photographic Mounter",
    value: "1aef9d10-019d-4e0f-bf0b-e62b4ce57448",
  },
  {
    label: "Physician",
    value: "75890884-9ae7-4ef0-a5a2-facbe6634cf6",
  },
  {
    label: "Physicist",
    value: "e14d0a99-5ca3-45fe-b9e7-8144df3e5de8",
  },
  {
    label: "Physiotherapist",
    value: "13b81c73-4a04-40d5-9546-443909dfa712",
  },
  {
    label: "Piano/Organ Tuner",
    value: "93aedeaa-bb2f-42f8-83a3-be063bdc894e",
  },
  {
    label: "Pickler",
    value: "14027b85-cc41-43a6-b061-9b62cb5109c8",
  },
  {
    label: "Picture Framer",
    value: "6db960ed-43f8-48da-96c9-c7b7ccfc2074",
  },
  {
    label: "Pier Master",
    value: "6179324a-e743-4312-ab63-53262689fe11",
  },
  {
    label: "Pile Driver",
    value: "a31e6947-dfa2-4e80-a2ea-722c7239450c",
  },
  {
    label: "Pilot",
    value: "7e72b682-b888-4232-9589-70ffc7319f5a",
  },
  {
    label: "Pipe Fitter",
    value: "dfe1b261-1db9-482f-a13f-582be59e8919",
  },
  {
    label: "Pipe Jointer",
    value: "2652539d-3149-4ec0-b44c-9206832a4382",
  },
  {
    label: "Pipe Layer",
    value: "7b0d6439-b39b-4bc2-bcce-12f95dde2596",
  },
  {
    label: "Pipe/Powerline Survey Work",
    value: "e65929d3-2337-49ca-86f2-1ec86ce6642b",
  },
  {
    label: "Pitch Melter",
    value: "4ff8471b-bc87-4af6-b1ac-4bee00be24d0",
  },
  {
    label: "Planning Engineer",
    value: "2da65b0c-1a73-4d72-8380-03d20b201fc2",
  },
  {
    label: "Planning Inspector",
    value: "a1d2cdd0-87c8-465b-be27-26d57c33482e",
  },
  {
    label: "Plant Attendant",
    value: "d4358912-c62e-420d-881b-9866071ea60e",
  },
  {
    label: "Plant Hire Manager (some manual work)",
    value: "d201ef30-ea6e-427e-abd2-5ab2cdf8fa07",
  },
  {
    label: "Plant Hire Owner (some manual work)",
    value: "76995365-9eeb-4b91-bb9f-d5d45de80a08",
  },
  {
    label: "Plant Hire Proprietor (admin. only)",
    value: "ed152747-7f9b-4ade-90aa-b733d8f1a61d",
  },
  {
    label: "Plant Operator",
    value: "147e079f-8204-42da-a550-1bf036be982f",
  },
  {
    label: "Plasterer",
    value: "7baeee17-1256-4eae-b900-82d771bbde6a",
  },
  {
    label: "Plastic Coating Operator",
    value: "43a12708-07f7-4477-aa1b-47d7300a2e7d",
  },
  {
    label: "Plate Cutter",
    value: "6fe2afb1-bb58-48c6-9930-244e9cfeaea4",
  },
  {
    label: "Plate Moulder",
    value: "b7bf1d40-004d-43ee-b3cc-925c4259ed20",
  },
  {
    label: "Plate Separator",
    value: "fefe61be-161f-49b1-947f-3d3a67f058b5",
  },
  {
    label: "Platelayer",
    value: "0599af1b-a06d-4f47-80e7-5c2f22c6ca19",
  },
  {
    label: "Plateman",
    value: "e85cdc90-afae-4f85-b47d-c9c50ae6f6ae",
  },
  {
    label: "Plater (including Boiler)",
    value: "56bca25b-4719-47b3-9d49-ace836ceeb9b",
  },
  {
    label: "Plater - Aircraft/Aerospace",
    value: "4cf3e36d-b82c-4f6d-b03b-62694f5c99a0",
  },
  {
    label: "Plater - Motor Vehicle & Cycle Industry",
    value: "e35a3773-25fd-4d06-8f3b-1faaf72ae08f",
  },
  {
    label: "Plater - Ship Building, Ship Repair & Marine Engineering",
    value: "4ebd733d-9548-4aa2-85a5-e123d9e287cd",
  },
  {
    label: "Playschool/Group Worker/Leader",
    value: "fbb7f28e-e9dd-4a66-943e-21cf5149a366",
  },
  {
    label: "Playwright",
    value: "2ea2be69-4fd0-42a4-827f-868ee4d62073",
  },
  {
    label: "Plumber - Construction/Industrial",
    value: "15e39bf9-ff3c-4c28-ae80-a5ea7eccf0d2",
  },
  {
    label: "Plumber - Domestic",
    value: "1070c34b-3c04-4974-b4e6-ef0887922726",
  },
  {
    label: "Pneumatic Drill Operator",
    value: "4bbe309f-350d-44b2-a2da-e8b00c0cad85",
  },
  {
    label: "Poet",
    value: "d37f492e-74e3-4ee4-8610-32504993bc17",
  },
  {
    label: "Pointsman",
    value: "79ad4e53-949b-42a1-910a-1da5ffba83c3",
  },
  {
    label: "Police",
    value: "5eea9c25-e4a0-4204-b2cc-cadf5079a7a3",
  },
  {
    label: "Police Frogman",
    value: "39b821ce-f89d-4f28-a0c4-2512b03d4571",
  },
  {
    label: "Politician",
    value: "32c23389-789f-4cba-a6db-057ecf2226c1",
  },
  {
    label: "Pollution Inspector",
    value: "871ae00e-e3de-42b9-b390-f2b2dfab8b62",
  },
  {
    label: "Pop Musician",
    value: "ea56fb85-fa51-4abc-ac77-1f5fb24614d4",
  },
  {
    label: "Pop Singer",
    value: "8d28b36a-2be9-4316-90fd-874497e90e8f",
  },
  {
    label: "Port Control Signalman",
    value: "630d7988-abeb-4080-b40f-f7e9b89c5312",
  },
  {
    label: "Port Health Inspector",
    value: "5f364a4b-b3ca-4b5d-8019-ebf912515f5a",
  },
  {
    label: "Porter - Meat, Fish etc - Food & Drink",
    value: "26fe9973-46e8-4a27-b897-bdcc4c18f854",
  },
  {
    label: "Porter - Station Personnel - Railways",
    value: "4614eeda-f1be-424c-bc8b-db47196f64fd",
  },
  {
    label: "Portrait Painter",
    value: "347f8d1d-2ac8-48e2-b944-82ca4768e4c7",
  },
  {
    label: "Portrait Photographer",
    value: "9e3253f7-3845-42e3-9957-fa41b63571c6",
  },
  {
    label: "Postal Courier Driver (car or van)",
    value: "1ff6b3f4-7991-424a-ba0d-18bbe3889ee1",
  },
  {
    label: "Postal Courier Manager",
    value: "8c42a56a-4a91-436c-bb98-60a7d9149c04",
  },
  {
    label: "Postman",
    value: "9a69b9d6-acff-4334-b98d-d1b9a8ec9556",
  },
  {
    label: "Postman (no driving)",
    value: "516a0cf9-e7a9-4ccb-bb00-725b442eec1c",
  },
  {
    label: "Pot Fisherman",
    value: "0b7ed348-b1fe-43fa-a865-d970c06bb4db",
  },
  {
    label: "Potman",
    value: "f01b8ec1-b128-45d2-857c-f0ec669ed565",
  },
  {
    label: "Potter",
    value: "3ee9e8ba-522d-4359-bfe7-3ce023bcb3b7",
  },
  {
    label: "Poultry Dresser",
    value: "5f78048e-fe93-48f5-92b3-abb3b625d723",
  },
  {
    label: "Poultry Plucker",
    value: "b7c84d4d-ffe7-4e70-8a16-ce19b7ec9ed8",
  },
  {
    label: "Poultryman",
    value: "dcc3c0ff-d72f-4de8-9dbe-f0659088b3c9",
  },
  {
    label: "Power Loader Man",
    value: "70b999e3-28fd-428e-bbab-beb1a88e7803",
  },
  {
    label: "Power Loader Operator",
    value: "dadc96b9-5874-41cd-8b8d-80de34319f58",
  },
  {
    label: "Power Station Charge Engineer",
    value: "e59cec07-bdfc-48ff-acea-463c09306654",
  },
  {
    label: "Power Station Manager",
    value: "eaab39fe-55e6-40fe-b48a-7f854d44976e",
  },
  {
    label: "Power Station Superintendent",
    value: "07cc11b2-4254-4d39-bc97-ecda3a1b0008",
  },
  {
    label: "Practice Manager",
    value: "58ac5fb3-9298-4056-857c-29909ffc4d8b",
  },
  {
    label: "Precision Instrument Fitter",
    value: "0926b069-e7fe-41d7-b072-04ae9707de5d",
  },
  {
    label: "Precision Instrument Maker",
    value: "7014cb9c-cc6d-482f-bb88-b0f31f0b1a12",
  },
  {
    label: "Precision Instrument Repairer",
    value: "c7cc296d-493e-469d-876c-4babf074571b",
  },
  {
    label: "Preparer",
    value: "0f7fa67c-a3d6-4d9e-9248-7b79f0ecdb4d",
  },
  {
    label: "Press Cutter",
    value: "bcee75f3-d347-4dbc-b38b-be1c3d40d303",
  },
  {
    label: "Press Officer",
    value: "755f2639-6a0b-4dbc-af45-fb51f7cd472b",
  },
  {
    label: "Press Operator - processing",
    value: "571ff81f-006f-4993-acd5-6e9e5559bf81",
  },
  {
    label: "Press Tool Setter",
    value: "4ec7afdb-6733-4593-ab48-5243ec89c486",
  },
  {
    label: "Presser - Fruit & Veg. - Food & Drink",
    value: "33352ba6-a959-4cbd-a2e0-008e36eab631",
  },
  {
    label: "Presser - Laundry",
    value: "ef5fd4da-c8eb-40c0-af4d-fe41efdc7c6e",
  },
  {
    label: "Priest",
    value: "2c9b35b4-a34c-494d-8d59-a41b3ab16921",
  },
  {
    label: "Printer",
    value: "bdab55b5-1406-4e98-8372-8f6a227d0526",
  },
  {
    label: "Printing Director (purely admin.)",
    value: "e6725833-db65-403e-9fa5-ef27d3fe717d",
  },
  {
    label: "Prison Officer",
    value: "5154158a-31d8-4229-8477-aed91414f28c",
  },
  {
    label: "Private Detective",
    value: "e4741de4-6f86-4468-965a-42e42d72237e",
  },
  {
    label: "Probation Officer",
    value: "bfc355a8-7b10-4123-82f2-adbfb325489d",
  },
  {
    label: "Process worker",
    value: "6f7b8f9d-ac53-437e-af24-f3b10c81d72d",
  },
  {
    label: "Producer - TV/film/theatre",
    value: "85106336-f47a-4769-984d-dfddfc8cda31",
  },
  {
    label: "Production Manager",
    value: "94ac1ba3-c7a5-490d-b917-b9fc316d6e7d",
  },
  {
    label: "Professional Sportsperson",
    value: "9aecd3fa-ec4a-45b9-9d5c-973e6e84e6e7",
  },
  {
    label: "Project Co-ordinator",
    value: "3da5a8c9-ccfb-4386-94be-c7811eefad7a",
  },
  {
    label: "Project Manager/Programme Manager",
    value: "7cc82eca-5899-492e-9eb1-469e3f22812c",
  },
  {
    label: "Projectionist",
    value: "9038703a-a849-4bd5-9585-41c124f31888",
  },
  {
    label: "Prompter",
    value: "b1a60713-2270-4b27-a67d-dac6e18b1c03",
  },
  {
    label: "Proofer",
    value: "e64e8641-a85b-4eef-b8cc-4d124c8e57a4",
  },
  {
    label: "Property & Estate Manager",
    value: "ecf2db21-5af7-42c0-a4d4-4c1514b9e83f",
  },
  {
    label: "Property Developer(no manual work)",
    value: "37f17edf-f602-4ec6-b856-a60ad9cb85e3",
  },
  {
    label: "Property Manager",
    value: "6509d83b-e8f0-4074-859f-6b5245cfb72c",
  },
  {
    label: "Proprietor",
    value: "cf285276-a161-4d05-9064-e0979d82cdf6",
  },
  {
    label: "Psychiatrist",
    value: "1787029b-40a9-494a-a567-b5595a972f61",
  },
  {
    label: "Psychologist",
    value: "b6b85fa6-e327-4a56-8f96-e795f9311adb",
  },
  {
    label: "Psychotherapist",
    value: "4031c817-3369-4d58-8a0e-c62ac61ee297",
  },
  {
    label: "Public Hall Bookings Office Manager",
    value: "6fcfecac-a4b6-4c49-9e38-1f686db99963",
  },
  {
    label: "Public Health Inspector",
    value: "179fa659-e21b-4c44-a65d-5e74e2fd884b",
  },
  {
    label: "Public House Manager (salaried)",
    value: "bb11d281-6f84-49bb-b803-d69123a29643",
  },
  {
    label: "Public Lighting Fitter-Erector",
    value: "d96ceaa1-8c72-4d23-adb7-b5a5b9122f56",
  },
  {
    label: "Public Relations Officer",
    value: "2eb4421a-7c5b-410c-93c3-fea2f9761285",
  },
  {
    label: "Publican",
    value: "f0445174-d98c-4930-a1e4-8c7f49181ea3",
  },
  {
    label: "Publisher",
    value: "c3fe02f3-7f80-4254-a0c4-2ab0d7458f87",
  },
  {
    label: "Pumpman",
    value: "9fa92870-1116-49ea-81fa-e4ac88e2c35a",
  },
  {
    label: "Puppeteer",
    value: "2bfefb5d-b115-49f1-b238-3e09a94df1e9",
  },
  {
    label: "Purchasing Officer/Manager (not retail)",
    value: "83109bd3-652e-4711-8ad4-2ceb29d79d7b",
  },
  {
    label: "Purifier Man",
    value: "b2b45c4d-b940-4dab-8e3e-96d1882f489e",
  },
  {
    label: "Purser",
    value: "a5effcf9-1564-43f4-bec3-09af7d3052e1",
  },
  {
    label: "Pusherman",
    value: "a9d941a3-7601-481e-b0ac-ed904d26a821",
  },
  {
    label: "Quality Control Engineer",
    value: "d20c0c50-3413-47d2-948c-18ec3bde5433",
  },
  {
    label: "Quality Control Inspector",
    value: "1be46347-011c-416e-a36e-9c78c880c977",
  },
  {
    label: "Quantity Surveyor",
    value: "8244878b-7d3b-4650-b5f4-55339bbcd1a3",
  },
  {
    label: "Quarry Manager",
    value: "3d2bc9ae-8091-44ef-b39f-36a1e0665698",
  },
  {
    label: "Quarryman",
    value: "66596981-0401-40ae-8230-3dc1b875e276",
  },
  {
    label: "Quartermaster",
    value: "d3a352c4-f804-43ba-a597-60eb76a75736",
  },
  {
    label: "Queen's Counsel",
    value: "d3c00f13-67a7-4436-8ec1-714295814705",
  },
  {
    label: "RSPCA Inspector",
    value: "232d80a8-dee0-4c60-a8fb-ba8a4ed71e98",
  },
  {
    label: "Rabbi",
    value: "3e02f15b-6a4c-47d4-bde2-15686129b7c4",
  },
  {
    label: "Racetrack Steward",
    value: "dfdfef39-66ce-4213-aa4d-aaaf42c2809e",
  },
  {
    label: "Radar Controller/Operator",
    value: "4e815042-c32a-4dd7-bcdb-d35744142fe6",
  },
  {
    label: "Radar Observer",
    value: "abba1c34-a527-4afa-a45f-2c6712ce87fa",
  },
  {
    label: "Radio Station Manager",
    value: "c913ad60-a9a8-4c47-912c-860325013109",
  },
  {
    label: "Radio and TV Repairer",
    value: "dacf4338-c588-4ef2-bd6a-0e7dab34da3a",
  },
  {
    label: "Radio/Radar Operator",
    value: "8275cb11-f97e-4891-84db-e394c0749668",
  },
  {
    label: "Radio/TV Announcer/Presenter",
    value: "73494326-89fe-4361-bd81-f4c775f8adda",
  },
  {
    label: "Radio/TV Director/Producer",
    value: "c7102bef-debf-49b5-adac-bc3bfe2cb206",
  },
  {
    label: "Radiographer - Health",
    value: "ecd29355-6132-44c4-a94d-9087aca0d0a2",
  },
  {
    label: "Radiologist",
    value: "176835a4-7b63-4c6d-8f3f-cc00dc905d85",
  },
  {
    label: "Radiotherapist",
    value: "8baa7ad7-ef17-4613-8043-0af09b8b48b9",
  },
  {
    label: "Rag & Bone Dealer",
    value: "7f802a6d-c022-4ee0-9cad-2256d39deaae",
  },
  {
    label: "Receptionist",
    value: "201dc587-56ff-4600-9f63-5c415e35d083",
  },
  {
    label: "Record Producer - Entertainment Industry",
    value: "30099cf8-c1ad-47ff-825a-7af4df4075f7",
  },
  {
    label: "Recording Engineer",
    value: "bf474e09-84cc-4045-bb83-77a45d5d650a",
  },
  {
    label: "Recruitment Consultant",
    value: "55fb858e-090e-479e-b720-30a8809208bb",
  },
  {
    label: "Rector",
    value: "f3127854-8d6c-4991-b2aa-3f4c315ff6ad",
  },
  {
    label: "Reflexologist",
    value: "a430407e-5b72-44c1-95ff-2f4c96867ae1",
  },
  {
    label: "Refuse Collector",
    value: "3767a2ae-ed13-426a-ab1f-f2c8eb663858",
  },
  {
    label: "Registrar",
    value: "e03af62e-28f6-4f88-ae6c-f19e8fa52e8a",
  },
  {
    label: "Reinsurance Broker",
    value: "ebc42890-f1ac-43ff-8d0d-c32b99caaf38",
  },
  {
    label: "Relationship Manager",
    value: "cd051dd2-9477-4d57-9464-d6d8150cffc1",
  },
  {
    label: "Rent Collector",
    value: "82c5444f-61a2-4c19-b762-bf4f0b8008a3",
  },
  {
    label: "Reporter/Writer - no overseas travel etc",
    value: "906c6558-b0d9-47e3-8acd-24f12e646865",
  },
  {
    label: "Reporter/Writer - otherwise",
    value: "fd016e9c-bc0f-4a76-aba8-7b502c04de65",
  },
  {
    label: "Rescue Diver",
    value: "0c839797-b88c-452a-b2d0-48de4528ab19",
  },
  {
    label: "Research Chemist (Managerial)",
    value: "35177a92-a3b1-40eb-b596-2b618b653dba",
  },
  {
    label: "Research Information Officer (Office based)",
    value: "d61cd445-8a32-48f6-9f34-73abe9c069ef",
  },
  {
    label: "Research Projects Manager (Deals with hazardous substances)",
    value: "c83b594e-3c0a-46d0-bf66-08aeee58a3a1",
  },
  {
    label: "Research Survey Clerk",
    value: "d8c1ebe7-7b53-462a-b274-3fbd7dab9569",
  },
  {
    label: "Researcher - Journalism",
    value: "c025c17e-14b0-44b0-bd3e-57af6ad4b4bc",
  },
  {
    label: "Researcher - Radio & TV - Entertainment",
    value: "cde05ce8-6100-4eb5-8656-544fd583f3f5",
  },
  {
    label: "Reservations Clerk",
    value: "a336d7c5-5daf-495f-9475-58f3c32f49d3",
  },
  {
    label: "Reservoir Attendant",
    value: "38a4c1ae-e53d-47b6-a2b5-d04bebb7b044",
  },
  {
    label: "Residential Care Worker",
    value: "afe3e0bb-5978-4e96-9aa4-2fbf221e3127",
  },
  {
    label: "Residential Home Proprietor (Admin. only)",
    value: "c88b8989-7d03-4401-9f69-3c1c74ea286b",
  },
  {
    label: "Residential Home Proprietor (full involvement in caring)",
    value: "f90ddfff-5371-424e-aa29-65ebb62548d0",
  },
  {
    label: "Restaurant Proprietor - no cooking",
    value: "f84406ec-7729-449d-aac0-a2e9929149b4",
  },
  {
    label: "Restorer (Paintings)",
    value: "77f047f4-fccc-4038-ac18-f58284a161f9",
  },
  {
    label: "Restorer (Stone, Brickwork)",
    value: "ffaf741e-36f7-4aa6-b472-8335cd593b83",
  },
  {
    label: "Retail Shop Manager",
    value: "79b3be20-25da-4aa9-bd2b-880ea40fb042",
  },
  {
    label: "Retail Shop Manager - admin only",
    value: "3c8836b7-bbf4-4a03-89fa-7c2274bc4011",
  },
  {
    label: "Retired",
    value: "2e5d7430-180c-4725-b838-caeb304b1b4c",
  },
  {
    label: "Riding Instructor",
    value: "eab95096-0be6-4909-9a28-a70de44101ca",
  },
  {
    label: "Rig Electrician",
    value: "7de8f7c9-4c7c-403a-97fd-a06b05bbfb94",
  },
  {
    label: "Rig Maintenance Diver",
    value: "e1f410fb-ca5e-4e69-8642-386bb57bd3dd",
  },
  {
    label: "Rig Mechanic",
    value: "f0f8e2b5-9245-4be2-8ce5-06bed0842824",
  },
  {
    label: "Rig Medic",
    value: "21a90435-8f2a-4a42-b20d-6a34d79be4e0",
  },
  {
    label: "Rigger - Docks",
    value: "64e12d8a-c51d-4957-a9ea-2b583c3222c9",
  },
  {
    label: "Rigger - Film Industry - Entertainment",
    value: "041f05fd-b342-4f6c-8b84-6cf24cb42d40",
  },
  {
    label: "Rigger - Gas Supply Industry",
    value: "6fe9966c-58da-4bee-a83a-db4e1c9163e2",
  },
  {
    label: "Rigger - Industrial/Plant Machinery",
    value: "62901672-195f-40f4-a842-8d0bbf4b67ea",
  },
  {
    label: "Rigger - Oil & Natural Gas Industries",
    value: "8a877949-4c9f-43dc-9afd-cd0098e5c13f",
  },
  {
    label: "Rigger - Ship Building, Ship Repair & Marine Engineering",
    value: "b7db3498-5af0-4b88-8923-eeee6e8fdd1e",
  },
  {
    label: "Ripper",
    value: "b4cb7ae7-5988-4c52-9cd5-856272ed316c",
  },
  {
    label: "River Inspector",
    value: "19957aa6-64c2-4f71-8ade-68b4c60cea5d",
  },
  {
    label: "Riveter",
    value: "9fc98800-2367-4233-886b-ea6b4497a75a",
  },
  {
    label: "Road Crew Member - 'Roadie'",
    value: "96637968-e228-4c9e-825a-76fe2005d185",
  },
  {
    label: "Road Manager - Rock band",
    value: "3c97b942-f5fb-4033-bc7e-29f41750a3d3",
  },
  {
    label: "Road Marker",
    value: "d179bc87-dd1d-4852-bfd3-18359bc138db",
  },
  {
    label: "Road Patrolman",
    value: "49a2cc29-98bd-4da4-acd4-ad6c69995969",
  },
  {
    label: "Road Safety Officer",
    value: "543b30cc-59d7-40a3-bef1-679bc3b68cfa",
  },
  {
    label: "Road Sweeper (hand)",
    value: "05869a71-764c-4d0a-923f-513b17eb6df5",
  },
  {
    label: "Road Sweeper - Mechanical",
    value: "b7bf07a5-3297-441e-a271-d02b5a37f19a",
  },
  {
    label: "Road Tester - Garage Trade",
    value: "f78f6e4a-365e-444e-a1b0-bc647845ef9d",
  },
  {
    label: "Road Tester - Motor Vehicle & Cycle Industry",
    value: "d0e4c60c-ebaf-43bd-85c1-f0a3c484faa2",
  },
  {
    label: "Road Worker/Labourer",
    value: "601deeda-b734-4d6c-a5da-c51c1354882c",
  },
  {
    label: "Roaster",
    value: "a1522458-5017-4fa1-b4dc-20f07a6dadd0",
  },
  {
    label: "Rodent Destroyer",
    value: "a1c350b3-454a-44d1-92bf-c924028cc8cf",
  },
  {
    label: "Roller Blind Maker",
    value: "ee59279a-37c7-4cd5-8ae5-8d689a8a913b",
  },
  {
    label: "Rollerman",
    value: "512f4c19-31d3-43f6-a5d6-f2043bda0965",
  },
  {
    label: "Roofer - 40' up",
    value: "a0bb3b5c-1271-42d9-9802-c4b7bbafd401",
  },
  {
    label: "Roofer - up to 40'",
    value: "b230c87a-a1d5-44e1-9075-efe0acb1331e",
  },
  {
    label: "Roofing Inspector (Mostly office based - some estimating)",
    value: "a8161337-5f1b-48c2-8577-e17073f23c20",
  },
  {
    label: "Rope Maker",
    value: "161d1b27-0b0b-4486-bafc-48397a9ad88b",
  },
  {
    label: "Roughneck",
    value: "b485902c-256d-48db-9525-1daa1f48e5c8",
  },
  {
    label: "Roustabout",
    value: "50099baa-f988-47f5-a505-d4bf57090cb9",
  },
  {
    label: "Roustabout Pusher - Oil Rig Industry",
    value: "a0dfab73-d53b-4651-8dee-c593606a2c7e",
  },
  {
    label: "Rubber & Plastics Worker",
    value: "e65a6d8f-65fe-432f-92a5-1d27ba8c0631",
  },
  {
    label: "Rubber Technologist",
    value: "9cda121d-04c0-44dc-a65f-ec7e9019cdee",
  },
  {
    label: "Saddler",
    value: "7b63ff7c-282c-47ec-95ee-3013ddff3f20",
  },
  {
    label: "Safety Inspector",
    value: "0fbc74d9-a3c2-4d01-a148-0b0fcb443a39",
  },
  {
    label: "Safety Officer",
    value: "56488b2f-92ab-4015-8a0c-ff8a8b456ab8",
  },
  {
    label: "Safety Officer - Oil Rig Industry",
    value: "6fc14fe0-8d83-4ec7-a41b-2b94c6cbe55d",
  },
  {
    label: "Sailing Instructor",
    value: "e5a44afa-5034-4837-886c-d4646819baa0",
  },
  {
    label: "Salary Administrator",
    value: "489a4245-955f-4f88-a63d-556d46f101ba",
  },
  {
    label: "Sales & Marketing Manager",
    value: "ea05042c-fb51-4018-a9bf-ed7c41c58f61",
  },
  {
    label: "Sales Assistant - retail",
    value: "8c3377a4-6b3c-43e3-b17a-18accae7001c",
  },
  {
    label: "Sales Executive",
    value: "68a4b476-ac0b-4489-95d5-341a3d8f31a7",
  },
  {
    label: "Sales Manager",
    value: "89503194-44ee-42dd-9a58-76574068d00b",
  },
  {
    label: "Sales Support Administrator",
    value: "b4a4a027-c450-468f-b0ef-7c508a9e4a2a",
  },
  {
    label: "Salter",
    value: "874ad648-ec09-47a1-b41d-e9ac6b0c21f9",
  },
  {
    label: "Salvage Diver",
    value: "840b5105-48cd-40dd-8f35-419c555ff8cf",
  },
  {
    label: "Salvage Man",
    value: "b46b2942-9637-4f0a-b78f-df7c917d3f90",
  },
  {
    label: "Sandblaster",
    value: "183ead40-a7c0-4e45-a8fa-de86251f8f03",
  },
  {
    label: "Satellite Aerial Fixer (domestic only)",
    value: "1e842e7b-8f9e-4179-b24b-a168bd53ea75",
  },
  {
    label: "Saturation Tank Attendant",
    value: "d834b017-8b79-4c9a-898d-4c27ad29c660",
  },
  {
    label: "Saw Doctor or Repairer or Sharpener",
    value: "02a935e5-c06f-49d7-9ae0-96d65ce753e9",
  },
  {
    label: "Scaffolder",
    value: "b18a0dec-365f-4270-8049-20945d6f5cf5",
  },
  {
    label: "Scaffolder Offshore Oil or Gas",
    value: "a2e212b5-f594-49cf-8a8a-b3fbcf03d50d",
  },
  {
    label: "Scene Shifter",
    value: "a978d30e-3b04-4d05-b0bc-387f7a9f0faf",
  },
  {
    label: "Scenery Painter",
    value: "6c8294ce-105e-4a7e-b810-d9a2ab09f239",
  },
  {
    label: "School Assistant",
    value: "ae047509-c240-4c99-b495-a464715e1306",
  },
  {
    label: "School Bursar",
    value: "22ecd8b6-5fa0-4899-97a2-7517d428739c",
  },
  {
    label: "School Inspector",
    value: "d63969a7-2dc3-4c33-b732-332b6779f62a",
  },
  {
    label: "Scrap Breaker",
    value: "04f86b2d-6a2b-4668-9b55-55a09a6592a6",
  },
  {
    label: "Scrap Dealer",
    value: "4be0b821-fc93-445e-bb98-6acab4db1693",
  },
  {
    label: "Screen Printer",
    value: "527e1cc2-adbd-4913-abe2-039903e5b766",
  },
  {
    label: "Screener - Quarrying",
    value: "f26dc050-b8a3-43de-8c85-087fc4d2bc65",
  },
  {
    label: "Screenmaker",
    value: "59738819-b23d-4ce7-a74d-dd99c66e7c08",
  },
  {
    label: "Screwman",
    value: "0857b3dc-f4d2-490d-a489-b1f3709881b5",
  },
  {
    label: "Script Writer",
    value: "29c28ec6-d213-4d13-9853-22d0ee048e3b",
  },
  {
    label: "Sculptor",
    value: "ab0655b8-cc88-4f36-91a3-be2524574796",
  },
  {
    label: "Seaman",
    value: "bfc92a0b-6363-447b-b3d4-6e8c7409a541",
  },
  {
    label: "Secretary",
    value: "5395c0a3-688e-4593-881a-350f7318e93b",
  },
  {
    label: "Security Consultant",
    value: "a12a28ee-af29-42cb-bfd4-59662f7f329f",
  },
  {
    label: "Security Guard",
    value: "9275ed14-4c40-45c0-9dc3-795b53f51417",
  },
  {
    label: "Security Manager",
    value: "72e369cd-d283-417f-8fee-e6c43da78b01",
  },
  {
    label: "Seismologist",
    value: "823181f9-076a-46ad-98ec-11eee420c923",
  },
  {
    label: "Set Designer",
    value: "a43756cf-5f60-462f-afa5-1a30ff75dfb1",
  },
  {
    label: "Sewage Works Attendant",
    value: "0ac34ddd-a528-4c35-ad1b-8bcc28ca1511",
  },
  {
    label: "Sewage Works Manager",
    value: "0cf15026-2d75-4a3e-a618-8e3307ddada7",
  },
  {
    label: "Sewerman",
    value: "d61b6dea-7f5d-45e5-af21-476c01cb5ea9",
  },
  {
    label: "Sewing Machine Mechanic",
    value: "7184249b-97d4-4b6f-ab5c-299b4aef5854",
  },
  {
    label: "Sewing Machinist",
    value: "b6155a23-93fd-45ce-ac7d-bb1b045cabf7",
  },
  {
    label: "Shaftsman",
    value: "8e4394c6-6ae5-4947-ac03-a51139cc6e43",
  },
  {
    label: "Sheep Shearer",
    value: "90e2a229-cdfa-49a9-b137-290ed6e5326a",
  },
  {
    label: "Sheet Fixer",
    value: "b8f23e5d-12c6-4160-ad89-7afc64a4713b",
  },
  {
    label: "Sheet Metal Worker",
    value: "4b0e783e-d06e-4764-9e5b-ac7f41c4d3ce",
  },
  {
    label: "Shelf Filler",
    value: "057e68fe-a11d-46a8-971b-4cdd5ee9d421",
  },
  {
    label: "Shepherd",
    value: "224e8e6e-ebae-401c-bcb1-d98c5691aa1f",
  },
  {
    label: "Ship's Broker",
    value: "491a8b29-a208-4ef0-aa0c-a256bbdc991c",
  },
  {
    label: "Shipping Clerk",
    value: "dafd397b-c0fc-427d-aec4-38d0a0678d8f",
  },
  {
    label: "Shoe Maker",
    value: "464eb9c4-21c5-47ff-824f-6d4c476f5be0",
  },
  {
    label: "Shoe Repairer",
    value: "5c727b41-cf14-4b06-854e-aa1e8219f117",
  },
  {
    label: "Shop Assistant",
    value: "30327946-92df-4776-9bfb-a6f392a491a4",
  },
  {
    label: "Shop Fitter",
    value: "5cf90053-9131-4096-bd36-fcaf9a2995e8",
  },
  {
    label: "Shotblaster",
    value: "bd613809-07ce-4c95-8d03-4d92721e79f7",
  },
  {
    label: "Shotfirer",
    value: "046b330a-5a65-41f9-9844-2df0083ba9af",
  },
  {
    label: "Shredding Machine Operator",
    value: "d9ee5aec-5549-48a3-ae1c-ffebeafa6c66",
  },
  {
    label: "Shunter - Marshalling/Goods Yard - Railways",
    value: "dd15eeea-fa0b-49bb-8846-a632aeede723",
  },
  {
    label: "Shunter - Mining",
    value: "656e05f2-8e86-4cf7-b6d8-e04388ec7654",
  },
  {
    label: "Sieve Operator - food",
    value: "2936513b-1998-47ac-9829-3da642eb69b1",
  },
  {
    label: "Sieve Operator - quarry",
    value: "6202e548-fdca-4ae2-b156-903bc50c6d70",
  },
  {
    label: "Sifter - food",
    value: "7d6f66df-1c8b-41eb-a88c-f3b22c309b24",
  },
  {
    label: "Sifter - quarry",
    value: "da0904e3-2f4e-49f8-ae23-d93033489381",
  },
  {
    label: "Sign Writer (40' up)",
    value: "b8320115-3aec-4101-ad4f-aca36e57ac62",
  },
  {
    label: "Sign Writer (no work at heights)",
    value: "fba029f3-ed80-4820-8fe7-82771d3eea55",
  },
  {
    label: "Signalman",
    value: "613e6286-c7ec-4e82-95d8-19621d8901ce",
  },
  {
    label: "Siloman",
    value: "035a9297-f38a-40bd-b12c-b18341f26a7f",
  },
  {
    label: "Siloman - Docks",
    value: "e18fea86-f23c-42cf-bcfb-60181bcd09ca",
  },
  {
    label: "Siloman - quarry",
    value: "6fd61148-2d07-4980-bb65-c2c76e474edd",
  },
  {
    label: "Silversmith",
    value: "cc4f674b-6182-4ebc-8314-f71a018c3e76",
  },
  {
    label: "Sister (Hospital)",
    value: "ecc72597-4797-4e3f-9972-cbf649d92afc",
  },
  {
    label: "Site Agent",
    value: "a059ca44-87da-45db-82ef-192c9dfd2fc2",
  },
  {
    label: "Skiing - Snow - Prof Instructor",
    value: "009a04e4-4ff4-4fb5-8a3b-8b1bce8b7db5",
  },
  {
    label: "Skipper",
    value: "b5549051-e04d-4a7b-bcce-6825f3467ef0",
  },
  {
    label: "Slate Cutter",
    value: "542bcf99-45d0-4ba9-b2c2-3fdf7abae2ba",
  },
  {
    label: "Slate Dresser",
    value: "d3951048-27b2-459a-a3c9-cd9b22c06cc1",
  },
  {
    label: "Slate Splitter",
    value: "b7e80254-d22c-498b-a65c-00cb6c9f9824",
  },
  {
    label: "Slater - 40' up",
    value: "c148dbee-7dd7-4f21-be47-bb79654be685",
  },
  {
    label: "Slater - up to 40'",
    value: "a0cd7def-111d-4671-864b-397413779154",
  },
  {
    label: "Slaughterer",
    value: "ef3866ec-40e7-46d7-afff-5a2d28a21e1b",
  },
  {
    label: "Slaughterhouse Manager",
    value: "86a024e9-77b0-4a4a-b67f-446a6c45a755",
  },
  {
    label: "Sleeping Car Attendant",
    value: "843364b2-8097-469d-82d2-81ff969949df",
  },
  {
    label: "Sleeve Designer",
    value: "4e7eb5a9-448c-485c-991d-9228a9c4432b",
  },
  {
    label: "Smeller",
    value: "11cb5f83-d930-4a45-aa4e-eaf0e4016f3c",
  },
  {
    label: "Smith - Gold, Silver etc",
    value: "a54c74b3-386c-45d7-be0f-36586908218e",
  },
  {
    label: "Smoker",
    value: "8aba967e-9393-4bfd-aee3-9926949a1641",
  },
  {
    label: "Social Worker",
    value: "34d75279-1efa-43b8-a05d-510a188e0eca",
  },
  {
    label: "Sociologist",
    value: "2c035953-25bf-4100-8aea-2ae70051a111",
  },
  {
    label: "Solderer",
    value: "081e25c5-1409-4223-9284-cbb79b5bf36d",
  },
  {
    label: "Solicitor",
    value: "221231a4-3fa6-4969-a360-82ec924ba20f",
  },
  {
    label: "Song Writer",
    value: "712062a5-4d28-4474-9a61-b1413bab604a",
  },
  {
    label: "Sorter (scrap metal)",
    value: "46e86261-1b81-4a4b-908d-ba128b958737",
  },
  {
    label: "Sorter - Dry Cleaning",
    value: "19298649-a339-40b5-b3cc-d25406e3fac7",
  },
  {
    label: "Sorter - Laundry",
    value: "f17ef0aa-e4fd-4fd6-acfe-cfa250f5ed9e",
  },
  {
    label: "Sorter - Post Office/Telecommunications",
    value: "dbdd2a61-47c0-47fb-b9cc-05fbedf957ad",
  },
  {
    label: "Sound Balancer",
    value: "1c292ee2-5334-417b-8489-82f7586a6013",
  },
  {
    label: "Sound Mixer",
    value: "28575712-86fd-49f6-b95a-ac3733021140",
  },
  {
    label: "Sound Recordist",
    value: "6ff65bfe-fb0e-431d-8882-d7ffff6d82de",
  },
  {
    label: "Sound Technician",
    value: "5fec48da-b75f-4491-899a-078b76ad67b4",
  },
  {
    label: "Special Air Service (SAS)",
    value: "0c6b877d-0768-4402-937c-3bc15babc44b",
  },
  {
    label: "Special Boat Service (SBS)",
    value: "b038d0c1-346a-413a-827a-44e844fff7ba",
  },
  {
    label: "Special Effects Technician",
    value: "e35808d0-0aef-46d7-9d70-cf6cc34da929",
  },
  {
    label: "Speech Therapist",
    value: "8b662639-17ab-45ed-ad07-e7b351d40554",
  },
  {
    label: "Spiderman",
    value: "c61a5e60-a890-480b-9421-afdf921f5b6b",
  },
  {
    label: "Spinner",
    value: "88aecde0-59dc-4563-b047-68116a29672e",
  },
  {
    label: "Sports Equipment Maker",
    value: "723f364e-6c0e-4ac9-94f3-ba99ccd67451",
  },
  {
    label: "Sports Equipment Repairer",
    value: "ef90e16d-41e0-4329-988a-bde07028e1b3",
  },
  {
    label: "Spot-Welder",
    value: "31452d28-8871-4927-a53a-983b20a86975",
  },
  {
    label: "Spray Painter",
    value: "97520ccc-0f8a-43e8-8112-54ee9b7adfa6",
  },
  {
    label: "Stablehand",
    value: "5e35fd3a-20f8-4dcd-9538-309819649530",
  },
  {
    label: "Staff Nurse",
    value: "12e44938-d27f-4682-923f-b698ab7134b0",
  },
  {
    label: "Stage Doorkeeper",
    value: "bcddcd39-c81c-4519-852b-b34bd971a8d5",
  },
  {
    label: "Stage Hand",
    value: "16f72ac4-7595-402c-83e9-c6053e89770d",
  },
  {
    label: "Stage Manager",
    value: "1baf1be6-9b6f-401e-8284-d6e48c0f499b",
  },
  {
    label: "Stage Technician",
    value: "7e77d0aa-6fbd-4d3f-844d-cf858842a32f",
  },
  {
    label: "Stamper (identification markings)",
    value: "69647519-1fac-4164-9f00-708af9a9e4b6",
  },
  {
    label: "Station Manager",
    value: "9ba8c01b-98b6-411d-8c9b-934752419b83",
  },
  {
    label: "Station Officer - Fire",
    value: "013877aa-23c0-4738-9b3b-01e5e9e83b1a",
  },
  {
    label: "Stationer",
    value: "41f5aaf2-0782-4b42-85ce-8aced7522755",
  },
  {
    label: "Statistician",
    value: "a61b4bd0-de50-4312-8a6b-88b4fe158e8f",
  },
  {
    label: "Steel Erector - 40' up",
    value: "cb8828f2-67e3-4f4d-8691-89440bf2204d",
  },
  {
    label: "Steel Erector - up to 40'",
    value: "9889a8fa-6018-435b-9cf1-cb684b5960f3",
  },
  {
    label: "Steeplejack",
    value: "97a57704-44c9-4077-afcc-7eca40a48c17",
  },
  {
    label: "Stenographer",
    value: "359feb1f-5444-42cb-8761-04a8861243a6",
  },
  {
    label: "Steriliser",
    value: "3fb1c54a-2358-4cfe-98d3-940f06cd87c3",
  },
  {
    label: "Stevedore",
    value: "20c5a6fb-597f-4a55-9a81-4bdc92d1277a",
  },
  {
    label: "Stitcher",
    value: "939e8e1c-30fb-46b6-82dc-b79dcb3eb1b9",
  },
  {
    label: "Stockbroker",
    value: "1e94c885-e04c-4876-90c6-d842e417b2fe",
  },
  {
    label: "Stockroom Storeman",
    value: "3bfe8e7d-03c0-4136-9ebf-a4cd61340485",
  },
  {
    label: "Stocktaker",
    value: "4a5fa826-e759-42f7-a4fd-b3692c59bcf6",
  },
  {
    label: "Stone Breaker",
    value: "47b89e2d-b3fb-4537-a313-02035b2c053b",
  },
  {
    label: "Stone/Slate Polisher",
    value: "62bbe79a-7dd7-499e-97b5-64af465017bc",
  },
  {
    label: "Stonemason",
    value: "37b00a35-2d43-4a53-af02-4df53d6e76c2",
  },
  {
    label: "Store Detective",
    value: "5abeaaf3-9e3f-47d0-85f4-0af5a64e44fa",
  },
  {
    label: "Storekeeper",
    value: "ee271e68-766b-44f3-beb0-60ea44211245",
  },
  {
    label: "Storeman",
    value: "c1bb52ca-9265-4fce-8b4e-9b6d2735315b",
  },
  {
    label: "Structural Engineer",
    value: "da2dcd12-e511-4134-a89a-2922068988c8",
  },
  {
    label: "Student",
    value: "d0a4e7fd-06a0-4672-abf6-ca06854baa74",
  },
  {
    label: "Stunt Man",
    value: "e0e3b912-eb60-493e-972e-220d6141d2a6",
  },
  {
    label: "Submariner",
    value: "2b2bfc64-5e3d-428b-a564-8d79ed4a53f3",
  },
  {
    label: "Sugar Beet Cutter/Slicer",
    value: "eca47aa5-8631-43e0-9e65-86ea4840b7d3",
  },
  {
    label: "Sugar Boiler",
    value: "a08d0dad-c206-45e2-b252-16f598cab21f",
  },
  {
    label: "Supermarket Cashier",
    value: "25df795d-5cbc-4a88-a661-d7ada7990d82",
  },
  {
    label: "Supermarket Manager",
    value: "2e9138a2-5c16-4d77-b068-0a17d9d38f1b",
  },
  {
    label: "Surgeon",
    value: "0de81c46-0a41-4a58-8a25-3569ccc186c3",
  },
  {
    label: "Surgery Nurse",
    value: "9b656e7f-19fb-40e6-ae8d-8ba22a2a7d52",
  },
  {
    label: "Surgery Receptionist",
    value: "f0743b2d-1c69-4be5-9d81-45f356e93727",
  },
  {
    label: "Surgical Appliance Maker",
    value: "1b14ccab-b207-4280-a953-2c01f2463257",
  },
  {
    label: "Surgical Shoe Maker",
    value: "883e6bd3-0397-4862-a581-7f6ae9ec2147",
  },
  {
    label: "Surveyor - Oil & Natural Gas Industries (Exploration & Production)",
    value: "25f9372c-da7c-4ee3-974a-792c05f3cd14",
  },
  {
    label: "Surveyor - Ship Building, Ship Repair & Marine Engineering",
    value: "b61b2a49-e927-4f2a-bc40-c160b7f0b969",
  },
  {
    label: "Swimming Instructor",
    value: "d2d8d31d-66fa-45dc-a4c5-2d4fa39c115b",
  },
  {
    label: "Swimming Pool Attendant",
    value: "737716fd-297d-4dbc-9595-a0a3b7d4f943",
  },
  {
    label: "Switchboard Operator",
    value: "7ce43eb5-d792-42a0-911c-d2bb5e08695d",
  },
  {
    label: "Sword Swallower",
    value: "5a0a3fae-3549-4eea-b2d3-c16fbabbd521",
  },
  {
    label: "Systems Programmer",
    value: "485ac655-0a19-4fc9-bc61-b15de78ec966",
  },
  {
    label: "Tailor",
    value: "dc0171ae-7651-4082-be9e-5e02513fa0b3",
  },
  {
    label: "Takeaway Food Shop Assistant",
    value: "bbce5de4-b5b9-4d94-99a3-45d9b033f4c1",
  },
  {
    label: "Takeaway Food Shop Manager - no serving",
    value: "716dd7fe-134e-42a5-9986-1f6c0731a1d3",
  },
  {
    label: "Tamperman",
    value: "61bb4527-189a-4baf-af04-881e99a7e934",
  },
  {
    label: "Tank Room Attendant",
    value: "55f33a35-4a0e-4e64-a1dd-36625efe7137",
  },
  {
    label: "Tanker Driver",
    value: "55eeae22-f2d0-46e9-b94d-571d844812c4",
  },
  {
    label: "Tanker Filler",
    value: "fdde052d-567c-4000-b3b5-8ecabb441044",
  },
  {
    label: "Tanner",
    value: "4bcf0805-a1e8-4937-979d-050a180a8e1f",
  },
  {
    label: "Tarmac Layer - Construction Industry",
    value: "b7cc1a2b-639b-42a9-80a6-d6f38e04e467",
  },
  {
    label: "Tarmac Layer - Road Maintenance & Construction",
    value: "c6e5a480-677f-4103-ace2-35b767cbd847",
  },
  {
    label: "Tattoo Artist",
    value: "d00b64c8-25b7-4a29-9f24-27ef82303418",
  },
  {
    label: "Tax Consultant",
    value: "faaac734-e250-40af-8235-ead4ab3fc587",
  },
  {
    label: "Tax Inspector",
    value: "e52192d1-a72e-4cc4-9c86-728ed02da31a",
  },
  {
    label: "Taxi Business Administrator",
    value: "2c649af9-b5b5-4743-ae03-a9be50856bd2",
  },
  {
    label: "Taxi Business Proprietor (no driving)",
    value: "986cd5b1-7290-4596-8b5f-caf011b18d02",
  },
  {
    label: "Taxi Driver",
    value: "3a744585-27e7-421e-9493-6cab77589a71",
  },
  {
    label: "Taxidermist",
    value: "ea5df39d-cd33-4f9c-b644-51b0b93ea574",
  },
  {
    label: "Teacher",
    value: "906588ab-f67a-4a27-ae1d-7604e604d3e3",
  },
  {
    label: "Teaching Assistant",
    value: "06cbe7c1-98ef-44bd-95b4-c3ebec471b85",
  },
  {
    label: "Technician - admin and site visits",
    value: "cba67ac5-f218-4115-8e69-c25c269a8931",
  },
  {
    label: "Technician - admin only",
    value: "ba73373e-8dd7-43c2-b267-5f5fda9c0335",
  },
  {
    label: "Technician - heavy manual",
    value: "de5381e4-36c4-43c0-b257-cf7acf53cbcf",
  },
  {
    label: "Technician - light manual",
    value: "49af0075-946c-49ff-b602-0124622867ba",
  },
  {
    label: "Telecommunication Technical Officer",
    value: "712bb6d8-7396-43e3-9d9f-291c2bddc8d1",
  },
  {
    label: "Telegraphist",
    value: "707f5b91-0595-4442-96a8-7f9e006f030a",
  },
  {
    label: "Telephone Customer Advisor",
    value: "2a3ffb09-3f8e-437c-837f-e1c1fd3422f2",
  },
  {
    label: "Telephone Exchange Superintendent",
    value: "b61c3af6-d1da-4671-8ed6-a838a701a16d",
  },
  {
    label: "Telephone Fitter",
    value: "1e27c828-c3fe-4eec-b3a2-84869fc35223",
  },
  {
    label: "Telephone Operator",
    value: "a0ce2e7f-e24c-4826-90de-40d4644720b8",
  },
  {
    label: "Telephone Repairer",
    value: "07a72da8-8d4e-4eca-81b1-ee48dc05c27d",
  },
  {
    label: "Telephone Supervisor",
    value: "32c73f9c-5660-4b7d-a241-d330dbd57c2c",
  },
  {
    label: "Telephone Systems Sales Director",
    value: "810f2349-21f8-4f9a-927d-8091ae51a6a4",
  },
  {
    label: "Telephonist",
    value: "86c92116-df5c-4ceb-b401-5465129618c3",
  },
  {
    label: "Telesales Caller",
    value: "b90edab7-d750-49ec-9d0e-f7eda8f7bcb0",
  },
  {
    label: "Telesales Manager",
    value: "ff04351a-d7f5-46d1-87de-2c4e410cf010",
  },
  {
    label: "Television Engineer",
    value: "5108e291-1b6c-4b57-9b0d-b1fe54dc5db8",
  },
  {
    label: "Test Engineer",
    value: "d200dde7-16f3-4ee2-a13a-9f2bb04e7863",
  },
  {
    label: "Test Pilots",
    value: "d3bc8c30-9c5f-4c93-8215-9f7710e3766b",
  },
  {
    label: "Tester",
    value: "ff384afd-625e-49fc-a09b-6a0de81c531c",
  },
  {
    label: "Textile Worker",
    value: "8c527d92-44cb-4862-8886-5cd0dacd6b42",
  },
  {
    label: "Thatcher",
    value: "370b4e64-a68e-4266-bab6-d229c84cc56e",
  },
  {
    label: "Theatre Sound Engineer",
    value: "d7b6d3e9-0d0b-4616-95c5-05ad36c2ab9c",
  },
  {
    label: "Ticket Inspector",
    value: "502360ca-767b-4f4a-900a-d7329bb643e9",
  },
  {
    label: "Tiler - 40' up",
    value: "5490e9b9-a5d3-4b3a-b13b-cefd89915b8b",
  },
  {
    label: "Tiler - up to 40'",
    value: "c673ca92-7887-449f-998c-ead479bde07c",
  },
  {
    label: "Timber Merchant (no manual work)",
    value: "9c63cd55-5638-46bb-bbff-9a3e21a0be50",
  },
  {
    label: "Timberman",
    value: "c3dad7a9-699d-4a5d-a7de-951c17c860b1",
  },
  {
    label: "Tobacconist",
    value: "3cc11522-21eb-42c3-ac42-cbdfc4c766cd",
  },
  {
    label: "Tool Fitter",
    value: "19396fa8-0479-4afc-b817-fcfae1ad17c9",
  },
  {
    label: "Tool Maker",
    value: "1bcb6e89-8819-4e8c-8df7-14779e8f7bbc",
  },
  {
    label: "Tool Pusher",
    value: "1f68205d-d0a3-4f76-b5df-af085ee1aaaa",
  },
  {
    label: "Tour Guide",
    value: "c3384fdf-443e-48c8-97ff-92f09d915841",
  },
  {
    label: "Tour Manager",
    value: "6a464b2b-dc0c-4b61-8496-d70f2398c765",
  },
  {
    label: "Tower Crane Driver",
    value: "b2fc5f76-1c6b-40e5-8afa-1fb75bec06ad",
  },
  {
    label: "Town Planner",
    value: "db62507c-7cab-4ef7-931b-8b540f9811b2",
  },
  {
    label: "Toxicologist",
    value: "c6f3ad63-c6f6-4a41-9b70-604953eee178",
  },
  {
    label: "Trackman",
    value: "677fbcc1-a94c-4ec7-9f32-024ff9bdca20",
  },
  {
    label: "Trade Union Official (full time)",
    value: "1007296d-2c1f-4fe6-b972-c0c5eba975a3",
  },
  {
    label: "Traffic Warden",
    value: "1b1e142f-7260-42ed-81b3-5b65d5595eb0",
  },
  {
    label: "Train Crew Inspector",
    value: "40b5535d-0f9a-47bb-9a5e-ca5a389ad1a3",
  },
  {
    label: "Train Crew Supervisor",
    value: "9ffd4a6c-6839-4413-b3fb-547dd63f0ad6",
  },
  {
    label: "Train Driver",
    value: "d6b545f5-7d9a-42f2-9114-6578dade658a",
  },
  {
    label: "Trainer - education/office based",
    value: "0bc80959-ae3b-4cda-b261-89953c321f59",
  },
  {
    label: "Tram Driver/Conductor",
    value: "75959deb-1eb7-4fa2-8196-be50329026d0",
  },
  {
    label: "Translator",
    value: "2ef6e943-853d-4377-8e76-e1f6c87aebc6",
  },
  {
    label: "Transport Company Operations Manager (Office based)",
    value: "fad9ed71-cd72-4fdb-a8cf-34487b8ce6ae",
  },
  {
    label: "Transport Manager",
    value: "bf9e86b5-73a8-43d0-8bdd-f17e2fb555fb",
  },
  {
    label: "Travel Agent (office based)",
    value: "49d8e8f3-cb6b-430b-bf03-64b72c3eb43a",
  },
  {
    label: "Travel Courier",
    value: "b8f78d39-9a4e-4e28-aa99-75dd11c2b503",
  },
  {
    label: "Trawlerman",
    value: "ca0eb05b-a0f9-4e62-83eb-a651be86e669",
  },
  {
    label: "Tree Feller",
    value: "d66cf5d9-c00b-4024-8c97-068f2751daf6",
  },
  {
    label: "Tree Surgeon",
    value: "cceba4e7-9e1f-4b5a-ac8c-ea2e1b5878d9",
  },
  {
    label: "Trenchman",
    value: "70118b4e-b554-4d2e-9d74-20b83dff4ec4",
  },
  {
    label: "Tugman",
    value: "9e061cf8-c082-4100-95e6-e74aa635cb20",
  },
  {
    label: "Tunneller - no explosives etc",
    value: "ebc6fd97-1f89-458a-8d8d-9a28d21aa24c",
  },
  {
    label: "Tunneller - using explosives etc",
    value: "9c82d33a-59df-4fe7-904a-e864bfd7cd9b",
  },
  {
    label: "Turf Accountant (on course)",
    value: "76b119d7-8bbc-4179-b540-f47b7a4f6d77",
  },
  {
    label: "Turf Accountant (shop)",
    value: "d2b17914-197f-4240-b821-4016c8131ce1",
  },
  {
    label: "Turf Cutter/Layer",
    value: "28a07c03-cd46-40f7-96a1-8c3bc45395a9",
  },
  {
    label: "Turner - Machining, Shaping etc - Metal Manufacture",
    value: "af6f4f1e-5b2e-4485-b105-a30fa3493aa7",
  },
  {
    label: "Turner - Pottery Industry",
    value: "1b16de7e-501f-451e-b49f-4c73943cd234",
  },
  {
    label: "Turnstile Operator",
    value: "679d7cc7-4aee-4eb2-bddf-615c56e281f0",
  },
  {
    label: "Tutor (salaried)",
    value: "37075e0d-63aa-4e2a-8555-7a9ad1cea816",
  },
  {
    label: "Tutor (self-employed)",
    value: "112ee0fe-4e81-4216-a66c-20eb8f3bc9c6",
  },
  {
    label: "Type Caster",
    value: "91cd5e54-971f-44c4-848f-ac8aaf759839",
  },
  {
    label: "Typesetter",
    value: "20e193cb-692d-4445-8036-c63c5ca69833",
  },
  {
    label: "Typograph Operator",
    value: "fcc86cf1-65c9-4f57-8825-e16d09c67534",
  },
  {
    label: "Typographical Designer",
    value: "35aac31a-1d50-4b5f-b8a7-343ab1612b86",
  },
  {
    label: "Tyre/Exhaust Fitter",
    value: "6ae20306-5cba-48be-b749-a30b607ac0f3",
  },
  {
    label: "Umpire",
    value: "1ead03e2-5664-44a0-80dd-18c37bba5666",
  },
  {
    label: "Under Secretary",
    value: "408213c0-aaa8-4376-b840-e746e6b98f24",
  },
  {
    label: "Undertaker",
    value: "a300db62-fc9f-4dd5-ba48-404b9b51c8b3",
  },
  {
    label: "Undertaker's Director's Assistant",
    value: "848863ed-b4f3-40cd-b392-f47e20fafefb",
  },
  {
    label: "Underwriter",
    value: "1f7c1376-d3f4-4a0d-af4a-2d007a7f5d08",
  },
  {
    label: "Unemployed",
    value: "716fbfd6-9a56-49a6-9081-873e55317aff",
  },
  {
    label: "University lecturer",
    value: "3e5e357d-be26-42cc-8576-7bce5757be90",
  },
  {
    label: "Unknown",
    value: "a690106e-1cf3-49ab-b1b3-61f37d27ed1f",
  },
  {
    label: "Upholsterer",
    value: "e41921d0-1f5f-4c54-ac0f-0d4dee8a6ef2",
  },
  {
    label: "Usher/Usherette",
    value: "4a134df1-447d-4c98-b93e-8e7975e6eb18",
  },
  {
    label: "Vacuum Plant Operator",
    value: "f085d5c0-0ff1-4740-8502-61a6423f9932",
  },
  {
    label: "Valet/Valeter",
    value: "11ffc214-5e43-4384-9329-0c9291c9c089",
  },
  {
    label: "Valuer",
    value: "b6005ad2-0a53-4f38-b9f6-756505a8d0e3",
  },
  {
    label: "Valveman",
    value: "916b996b-824e-4720-9fe4-b82beb5f6e81",
  },
  {
    label: "Van Driver",
    value: "66d81fc9-b105-45ed-b05d-46e6a2e6dd4c",
  },
  {
    label: "Varnisher",
    value: "e16bf571-9acf-490f-8255-9fd466f2293d",
  },
  {
    label: "Vatman",
    value: "a401c834-cddf-4a4d-aff1-5ae5689c89f4",
  },
  {
    label: "Vehicle Body Builder",
    value: "a3deeff3-458a-4dae-8821-298a55983aee",
  },
  {
    label: "Vehicle Body Fitter",
    value: "74a1a381-ffba-4816-80bb-04fc3aeaf4d0",
  },
  {
    label: "Vending Machine Engineer",
    value: "357fa24f-769f-412f-8e24-cf7d51454023",
  },
  {
    label: "Venetian Blind Maker",
    value: "a380a1e0-e90e-4ea6-bbf3-88bd047bea54",
  },
  {
    label: "Ventriloquist",
    value: "57d1aeb9-c32e-4f78-937c-7be71f56fc09",
  },
  {
    label: "Venture Capitalist",
    value: "c1f567f5-c97f-4226-a21d-9506db6b2f80",
  },
  {
    label: "Verger",
    value: "d5c43993-b583-4cfe-ac14-ee8b698bfbc6",
  },
  {
    label: "Veterinarian",
    value: "efc44609-8cc1-4483-9345-30a92b4664ac",
  },
  {
    label: "Veterinary Assistant",
    value: "929f62ca-f585-41eb-bafd-cb26cf9a27e6",
  },
  {
    label: "Vicar",
    value: "c8673984-fc87-4bf7-add0-ccae2f92e71d",
  },
  {
    label: "Video Conference Engineer",
    value: "8b8cc92a-b987-4914-af58-3b909b16569f",
  },
  {
    label: "Video Recorder Operator",
    value: "79b7a4ec-9c62-4ef8-8a66-a064ae5afc77",
  },
  {
    label: "Vintner",
    value: "0923802a-b996-4952-85a4-ec27ff445e9c",
  },
  {
    label: "Vision Mixer",
    value: "475ad4dd-7e57-4d08-b728-b971ca2110fe",
  },
  {
    label: "Vocational Training Instructor",
    value: "7bc12550-2a13-446d-a8e8-031513b3500e",
  },
  {
    label: "Wages Clerk",
    value: "2937fa6d-1c2c-4d42-bb6a-70dc167b97ff",
  },
  {
    label: "Wages Inspector",
    value: "9fffb3ee-7f39-414b-9b9a-12e9a0ec10d0",
  },
  {
    label: "Waiter, Waitress",
    value: "df8f7264-e163-4c1b-afed-749703c45f8a",
  },
  {
    label: "Wallpaper Printer",
    value: "1e6a84d8-0f13-4d8f-9486-30e3a5c33fee",
  },
  {
    label: "Warden",
    value: "cf6794c8-428e-4f5e-a36b-859cdd508472",
  },
  {
    label: "Wardrobe Mistress",
    value: "5813d444-c0fc-44b4-a838-28adac4b2fc5",
  },
  {
    label: "Warehouse Manager",
    value: "8e11628a-7bbe-448b-b31e-49817f9ce618",
  },
  {
    label: "Warehouseman",
    value: "514f0009-cd8b-4e25-a947-eea0e246aa4d",
  },
  {
    label: "Washer",
    value: "49af22ec-12c1-463a-87e8-f6b8ce0b3ebd",
  },
  {
    label: "Waste Disposal/Recycling Operative",
    value: "f8461adf-2276-4d68-82e5-005dde25d5fd",
  },
  {
    label: "Wasteman, Salvage Man",
    value: "0ed62435-957a-45f9-a22a-056eeec6b011",
  },
  {
    label: "Watch & Clock Maker",
    value: "58d5b91f-eff6-4631-8cbd-d23325d11505",
  },
  {
    label: "Watch & Clock Repairer",
    value: "39c112b0-95d1-4640-9eb5-c5e171afe8ad",
  },
  {
    label: "Watchman (inland waterways)",
    value: "2ce262ff-199c-4bc7-86d4-64720dd91c39",
  },
  {
    label: "Watchstander",
    value: "5ffb2f0c-c8dc-4a92-81fb-af9b910a030c",
  },
  {
    label: "Water Bailiff",
    value: "15b29e3d-5a9d-439e-b79d-526191ceae90",
  },
  {
    label: "Water Infusion Man",
    value: "fd5ff26a-e0bf-47dc-babc-955b522f5d07",
  },
  {
    label: "Water Meter Reader/Fitter/Tester",
    value: "baab352c-5a24-4873-8497-5e625b91c08e",
  },
  {
    label: "Water Skiing - Prof. Instructor",
    value: "32242145-2b77-438b-ac4f-8217fc06acfc",
  },
  {
    label: "Water Treatment Plant Operator",
    value: "72cc2b0f-66bf-455b-9e73-bbc2e76afb4d",
  },
  {
    label: "Waterworks Manager",
    value: "4508cd84-f0e5-446f-a53e-6655b5c8e30b",
  },
  {
    label: "Weaver",
    value: "5cf807ab-6599-4dc3-8a82-bd66162f5df7",
  },
  {
    label: "Website/Webpage Designer",
    value: "da2b9843-cf2e-4f7b-b112-325f6d4d277a",
  },
  {
    label: "Wedding Photographer",
    value: "c24bfa2a-4b24-40eb-9979-935433c6c09a",
  },
  {
    label: "Weighbridge Clerk",
    value: "18a9d665-e6d7-4ec9-a5a3-dff548288a12",
  },
  {
    label: "Weighbridgeman",
    value: "5e3e9034-2f32-4d18-b20c-7fb5a7e25d57",
  },
  {
    label: "Weights & Measures Inspector",
    value: "77e385bb-60e6-4ecc-acc0-85dc113dcd9e",
  },
  {
    label: "Welder - 40' up",
    value: "2af4edfd-4297-480e-885b-2fa0de0eef76",
  },
  {
    label: "Welder - up to 40'",
    value: "b724f464-1697-49b4-ad79-6f05bc20d914",
  },
  {
    label: "Welfare Officer",
    value: "1071c17c-1cb7-4fab-a84e-8105f9e360ef",
  },
  {
    label: "Welfare Worker",
    value: "b960078a-0f49-4197-8a84-8a5e7a76c7d8",
  },
  {
    label: "Wicker Worker",
    value: "d4eff0f3-2ef6-4571-9b6f-5a7884ae0035",
  },
  {
    label: "Wig Maker",
    value: "17826d3d-1e4d-4f26-a7ef-ce5897a12384",
  },
  {
    label: "Winch Operator",
    value: "5a224f3d-5646-4e33-b86d-2536146a855f",
  },
  {
    label: "Winchman",
    value: "fd7a9ac5-723e-4129-9642-a895e87d0229",
  },
  {
    label: "Window Cleaner (40' up)",
    value: "a7ad2e1c-0ece-4ab1-91b8-e21533d45699",
  },
  {
    label: "Window Cleaner (up to 40')",
    value: "e9e24161-dbf7-4b61-9c2d-78b9531b2d5e",
  },
  {
    label: "Window Dresser",
    value: "d887105e-8f87-4e86-8642-a1cc7f332b69",
  },
  {
    label: "Window/Conservatory Fitter",
    value: "64297300-e4d2-4f91-8a87-d7a817094542",
  },
  {
    label: "Windscreen Fitter/Repairer",
    value: "2f587f93-6fe9-4e20-acf5-6598bc8d180e",
  },
  {
    label: "Wire Winder",
    value: "e707b36a-5baa-43f7-9d77-3fc537e0f5f0",
  },
  {
    label: "Wood Carver",
    value: "0db57357-4511-4158-a871-36b2245f70d8",
  },
  {
    label: "Wood Worker",
    value: "b75b612c-9391-4976-9961-030bb8f4872f",
  },
  {
    label: "Woodcutter",
    value: "d7407be6-0279-47bf-b3c5-52220eb4ef20",
  },
  {
    label: "Woodman",
    value: "7c9099c7-3852-4e80-a703-0c2b5e5b9bac",
  },
  {
    label: "Working Partner",
    value: "cbeae9b3-f1cf-49ac-a905-98025979fbf3",
  },
  {
    label: "Wrapping Machine Attendant",
    value: "bc2ac968-300b-4bd2-8203-d20c8af1b3e8",
  },
  {
    label: "Writer",
    value: "953ad546-dbb9-470e-a345-0b3098e0e8b0",
  },
  {
    label: "X-ray Technician - Radiologist",
    value: "ccd8344b-38ee-4b71-9cfe-a571cb8c7f51",
  },
  {
    label: "Yacht & Boat Builder",
    value: "51a14047-37ce-4f7d-8d05-e93e35c6c4e8",
  },
  {
    label: "Yard Assistant",
    value: "e5cc11eb-975b-49b3-9f36-6b14427b7973",
  },
  {
    label: "Yard Cleaner",
    value: "869f71c1-9bc3-49a0-8dab-6fef1728dec0",
  },
  {
    label: "Yarn Worker",
    value: "0818fde0-0314-43e1-ab81-20fe52da1591",
  },
  {
    label: "Yoga Teacher",
    value: "060615da-f903-4520-ba2f-05224c2cdde0",
  },
  {
    label: "Youth Hostel Assistant and Cook",
    value: "488062b3-184f-462e-b877-abbdd6c0efef",
  },
  {
    label: "Youth Hostel Manager (some manual work)",
    value: "bcfb3b74-52ff-4a6b-8741-7a8d96d28122",
  },
  {
    label: "Youth Leader (Full time)",
    value: "ca8440dc-838e-4d6d-a463-b13ac7c5751f",
  },
  {
    label: "Youth Worker (full time)",
    value: "35500548-7df3-4f1e-8c8d-7316620fb4b1",
  },
  {
    label: "Zoo Curator",
    value: "5785ec3b-355b-41e7-a0f9-7873af690351",
  },
  {
    label: "Zoo Director",
    value: "00e36fa1-04cd-41a6-aac9-3d210775e4a1",
  },
  {
    label: "Zoo Keeper",
    value: "a82c92be-749e-4e67-a2b4-f8109086446c",
  },
  {
    label: "Zoo Keeper - large zoos",
    value: "46b40d05-ac63-4d00-a382-3869cebecb0a",
  },
  {
    label: "Zoo Keeper - small zoos",
    value: "863b9e2d-96b6-455d-a02f-2bd39cf4f0ef",
  },
  {
    label: "Zoological Research Associate",
    value: "d4005642-8c75-4e5f-ab06-0dd59c331a17",
  },
  {
    label: "Zoological Researcher",
    value: "fa0a3610-6b20-40c3-b2e0-aae58b4b168a",
  },
  {
    label: "Zoologist",
    value: "ed9e3dfe-ae8d-415b-ac35-2856a542173d",
  },
  {
    label: "Zoologist (no overseas field work)",
    value: "8c0c1995-78b8-4f70-ad45-e6217307ccb1",
  },
];
