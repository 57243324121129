import { Alert, Button, Stack } from '@mui/material';
import { renderFields } from '../FormFields/fields';
import { QuoteListingProps } from './QuoteListingPage';
import { QueryObserverResult, RefetchOptions, useMutation, useQueryClient } from '@tanstack/react-query';
import { JourneyContext } from '../../JourneyContext';
import { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import { IStringIndex, QuoteDetailResponse, QuoteRequestResponse, TFormData } from '../../types';
import { createQuote, patchQuote } from '../../apiCalls';
import QuoteListingLoader from './QuoteListingLoader';
import CustomAlert from '../FormFields/CustomAlert';
import { AppliedContext } from '../../AppliedContext';
import { checkDependents, checkRules, initDependencies } from '../../formHandling';
import { validateField } from '../../fieldValidation';
import { getEnvParams } from '../../utils';

interface QuoteListingSideOptionProps extends QuoteListingProps {
  setIsError: Dispatch<SetStateAction<boolean>>;
  setcomparativeQuotes: Dispatch<SetStateAction<any>>;
  refetchMonthly?: (options?: RefetchOptions) => Promise<QueryObserverResult<any, Error>>;
}
const { mode: journeyMode } = getEnvParams();
export default function QuoteListingSideOptions({
  changeHandler,
  isSubmitting,
  config,
  formData,
  backHandler,
  handleSubmit,
  activeIndex = 0,
  branding,
  setIsError,
  setcomparativeQuotes,
  refetchMonthly,
  setFormData,
}: QuoteListingSideOptionProps) {
  const [changeDetected, setChangeDetected] = useState(false);
  const { mode } = useContext(JourneyContext);
  const queryClient = useQueryClient();
  const { selectedAppliedQuote, updateSelectedQuote } = useContext(AppliedContext);

  const quoteListingFields = config?.sections?.find((s) => s.name === 'quoteListing')?.fields ?? [];

  useEffect(() => {
    (async () => {
      initDependencies(quoteListingFields, journeyMode ?? mode);
      const validations: IStringIndex<boolean> = {};
      for (let f of quoteListingFields) {
        const valid = validateField(f, formData.values[f.name], formData);
        validations[f.name] = valid;
        checkDependents(f, quoteListingFields, formData.values, formData.validations,config,journeyMode ?? mode);
        await checkRules(f, quoteListingFields, formData.values, formData.validations, config);
      }
    })();

  }, []);

  const handleUpdateQuote = async () => {
    queryClient.invalidateQueries();
    const cleanValues: IStringIndex<any> = {};
    const apiConfig = config?.APIs?.patch;
    for (let [fieldName, fieldValue] of Object.entries(formData.values)) {
      if (typeof fieldValue === 'string') {
        cleanValues[fieldName] = fieldValue.replace(/\s+/g, ' ').trim();
      } else {
        cleanValues[fieldName] = fieldValue;
      }
    }
    apiConfig.payload.quote.isQuote = true;

    // Actual quote update
    const result = await patchQuote(config, apiConfig, cleanValues, 'comparativeQuotes');
    console.log(result);
    const comparativeQuotes = result?.[0]?.comparativeQuotes;
    console.log(comparativeQuotes);
    if (!comparativeQuotes || comparativeQuotes?.length === 0) {
      setIsError(true);
      throw new Error('No quotes found');
    } else {
      refetchMonthly?.();
      setFormData?.({
        values: {
          ...cleanValues,
          quote: result?.data?.[0],
        },
        validations: { ...formData.validations },
      });
    }
    return comparativeQuotes;
  };

  const { error, isPending, mutate } = useMutation({
    mutationKey: [`UpdateQuote`],
    mutationFn: handleUpdateQuote,
    onError: (err) => {
      console.error(err);
    },
    onSuccess: (data) => {
      setcomparativeQuotes(data);
      setChangeDetected(false);
      updateSelectedQuote(null);
    },
  });

  useEffect(() => {
    const selectedQuoteDetails: QuoteDetailResponse = selectedAppliedQuote;
    if (selectedQuoteDetails && selectedQuoteDetails.premiumIncIPT) {
      const matchingComparativeQuote = formData?.values?.quote?.comparativeQuotes?.find(
        (q: QuoteRequestResponse) => q.schemeRef === selectedQuoteDetails?.schemeRef,
      );
      const premiumChanged = selectedQuoteDetails?.premiumIncIPT !== matchingComparativeQuote?.premiumIncIPT;
      setChangeDetected(premiumChanged);
    }
  }, [selectedAppliedQuote, formData?.values?.quote?.comparativeQuotes]);


  useEffect(() => {
    if(changeDetected){
      mutate()
    }
  }, [changeDetected])
  

  return (
    <>
      {isPending && <QuoteListingLoader open={isPending} />}
      <QuoteListingLoader open={isPending} />
      <Stack
        fontFamily={'Inter'}
        direction={'column'}
        padding={3}
        marginTop={5}
        border={{ md: '2px solid #E9EAFF' }}
        borderRadius={2}
        width={{ md: 350 }}
        sx={{
          bgcolor: 'white',
        }}
      >
        <Stack direction={'column'} spacing={4}>
          {renderFields(config, quoteListingFields, formData, changeHandler, false, 'DEFAULT')}

          <Button
            onClick={() => mutate()}
            variant="outlined"
            sx={{
              textTransform: 'capitalize',
            }}
          >
            Update Quotes
          </Button>
          {changeDetected && (
            <CustomAlert title="Change detected" severity="warning">
              Please click <b>Update Quotes</b>
            </CustomAlert>
          )}
          {error && <CustomAlert severity="error">Failed to update quotes</CustomAlert>}
          <Button
            onClick={backHandler}
            variant="text"
            sx={{
              textTransform: 'none',
            }}
          >
            Edit all quote details
          </Button>
        </Stack>
      </Stack>
    </>
  );
}
