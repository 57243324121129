import { FormControl, Slider } from "@mui/material";
import { TFieldChangeHandler, TFormField } from "../../types";

export const SliderField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
}) => {
  const { field, value, changeHandler } = props;

  const localChangeHandler = (event: any) => {
    changeHandler(field, event.target.value);
  };
  return (
    <div
      style={{
        padding: "5px 10px 5px 10px",
        marginTop: "10px",
      }}
    >
      <FormControl fullWidth required={field.required}>
        <Slider
          size="medium"
          valueLabelDisplay="on"
          valueLabelFormat={`${value ?? 0}${field?.adornment}`}
          onChange={localChangeHandler}
          value={value ?? 0}
          min={field.params?.min as any}
          max={field.params?.max as any}
          step={1}
        />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>{field.params?.min as any}</div>
          <div>{field.params?.max as any}</div>
        </div>

        {/* <Typography alignSelf={"center"} fontSize={14} fontWeight={500}>
          {value}
          {field?.adornment}
        </Typography> */}
      </FormControl>
    </div>
  );
};
