export const EmploymentType = [
  {
    "label": "Company",
    "value": "C"
  },
  {
    "label": "Contractor",
    "value": "D"
  },
  {
    "label": "Employed",
    "value": "E"
  },
  {
    "label": "Employed (Temporary)",
    "value": "T"
  },
  {
    "label": "Financially Assisted",
    "value": "A"
  },
  {
    "label": "Household Duties",
    "value": "H"
  },
  {
    "label": "In Full Or Part Time Education",
    "value": "F"
  },
  {
    "label": "Independent Means",
    "value": "I"
  },
  {
    "label": "Not Employed Due To Disability",
    "value": "N"
  },
  {
    "label": "Retired",
    "value": "R"
  },
  {
    "label": "Self Employed",
    "value": "S"
  },
  {
    "label": "Unemployed",
    "value": "U"
  },
  {
    "label": "Voluntary Work",
    "value": "V"
  }
]