import { Backdrop, Card, CardContent, hexToRgb, Stack, Typography, useTheme } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import '../../index.css';

export default function QuoteListingLoader({ open, onClose }: { open: boolean; onClose?: () => void }) {
  const theme = useTheme();
  const themeColor = theme.palette.primary.main;
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open} onClick={onClose}>
      <Card
        sx={{
          width: 520,
          borderRadius: 5,
        }}
      >
        <CardContent
          sx={{
            alignContent: 'center',
            alignItems: 'center',
            paddingX: 5,
          }}
        >
          <Stack direction={'column'} spacing={2} alignItems={'center'}>
            <Spinner color={themeColor} />
            <Typography
              width={340}
              fontSize={24}
              fontWeight={600}
              fontFamily={'Poppins'}
              color={'#1A2133'}
              textAlign={'center'}
            >
              We are searching insurance brands for you to save you more money...
            </Typography>
            <Typography textAlign={"center"} fontSize={16} fontWeight={400} fontFamily={'Inter'} color={'#464C5E'}>
              We'll save your quote details and send you a follow-up email.
            </Typography>

            <Stack
              direction={'row'}
              alignItems={'start'}
              spacing={1}
              border={'2px solid #E9EAFF'}
              padding={1}
              borderRadius={2}
              sx={{
                bgcolor:hexToRgb(themeColor)?.replace(')', `, ${0.05})`)
              }}
            >
              <ErrorOutlineIcon color="primary" sx={{ marginRight: 1 }} />
              <Typography textAlign={"left"} fontSize={14} fontWeight={400} fontFamily={'Inter'} color={'#464C5E'}>
                We source products but do not offer advice or make recommendations. We have asked you some questions to
                produce a list of quotations; this will help you to make your own choice about how to proceed.
              </Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Backdrop>
  );
}

export const Spinner = ({ color }: { color: string }) => (
  <div
    className="lds-default"
    style={{
      color: color,
    }}
  >
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
);
