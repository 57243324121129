export const AlcoholicReadingType = [
  {
    "label": "Blood",
    "value": "2"
  },
  {
    "label": "Breath",
    "value": "1"
  },
  {
    "label": "Urine",
    "value": "3"
  }
]