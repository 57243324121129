import React from 'react';
import { TFormData, TFormField, TJourneyConfig, TJourneyMode } from '../../types';
import { Container, Stack, Typography, hexToRgb, useTheme } from '@mui/material';
import { IoDocumentOutline } from 'react-icons/io5';
import { LuExternalLink } from 'react-icons/lu';
import Cookies from 'js-cookie';

const cookieBranding = Cookies.get('branding');
const branding = cookieBranding && JSON.parse(decodeURIComponent(cookieBranding as string));

export default function DocumentsField(props: {
  config: TJourneyConfig;
  field: TFormField;
  formData: TFormData;
  mode?: TJourneyMode;
}) {
  const { config, field, formData, mode } = props;

  const isMTA = formData?.values?.MTA || mode === 'MTA';
  const includeFDDocs = !isMTA && field?.params?.includeFDDocs;
  const includeQuoteDocs = field?.params?.includeQuoteDocs ?? false;

  const documents = isMTA ? formData?.values?.MTA?.documents : formData?.values?.quote?.documents;

  const fdDocs = formData?.values?.quote?.fdDocs ?? [];

  return (
    <Stack direction={'column'} spacing={1}>
      {includeFDDocs &&
        fdDocs &&
        fdDocs?.map((doc: { fileName: string; filePath: string; category: string }, index: number) => (
          <DocumentItem key={index} doc={{ link: doc.filePath, name: doc.fileName?.split('.')[0] }} />
        ))}
      {includeQuoteDocs &&
        documents?.map((doc: { filename: string; description: string; url: string }) => (
          <DocumentItem
            doc={{
              name: doc.description,
              link: doc.url,
            }}
          />
        ))}
      {field?.documents?.map((doc, index) => <DocumentItem key={index} doc={doc} />)}
    </Stack>
  );
}

export const DocumentItem = ({ doc }: { doc: { name: string; link: string } }) => {
  const theme = useTheme();
  const brandingColor = theme.palette.primary.main;
  const fgBranding = hexToRgb(brandingColor)?.replace(')', `, ${0.05})`);

  const handleClick = () => {
    window.open(doc.link, '_blank');
  };

  return (
    <Stack
      sx={{
        width: '100%',
        cursor: 'pointer',
        ':hover': {
          color: brandingColor,
        },
      }}
      onClick={handleClick}
      direction={'row'}
      border={'1px solid #EAECF0'}
      alignItems={'center'}
      justifyContent={'space-between'}
      style={{ backgroundColor: 'white' }}
      padding={1}
      borderRadius={2}
    >
      <Stack direction={'row'} gap={2} alignItems={'center'}>
        <span
          style={{
            backgroundColor: fgBranding,
            padding: 8,
            borderRadius: 10,
          }}
        >
          <IoDocumentOutline color={brandingColor} size={20} />
        </span>
        <Typography fontWeight={700} fontSize={14}>
          {cleanFilename(doc?.name)}
        </Typography>
      </Stack>
      <LuExternalLink color={brandingColor} size={24} />
    </Stack>
  );
};

export const cleanFilename = (str: string) => {
  return decodeURI(str?.replaceAll('-', ' ')?.split('.')?.at(0) ?? '');
};
