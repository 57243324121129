// =================================================================================================
// For entering dates or selecting from a calendar
// =================================================================================================

import { FormControlLabel, TextField, TextFieldProps } from '@mui/material';
import { IStringIndex, TFieldChangeHandler, TFormData, TFormField, TValidationResult } from '../../types';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import { useEffect, useContext, useState } from 'react';
import moment, { Moment } from 'moment';
import lodash from 'lodash';
import { JourneyContext } from '../../JourneyContext';
import Checkbox from '@mui/material/Checkbox';
import { replacePlaceholders } from '../../formHandling';
// =================================================================================================
// Validation
// =================================================================================================

const getDateForMaxAge = (maxAge: number) => {
  return moment()
    .subtract(maxAge + 1, 'years')
    .add(1, 'days');
};

const getDateForMinAge = (minAge: number) => {
  return moment().subtract(minAge, 'years');
};

const getMinDate = (field: TFormField, formValues: IStringIndex<any>) => {
  // Max age corresponds to minimum date
  const maxAge = field.dateParams?.maxAge;
  if (typeof maxAge === 'number') {
    return getDateForMaxAge(maxAge);
  }
  // Rest use the "normal" min values
  let min = field.dateParams?.min;
  min = setMinimuDate(field, formValues);
  if (min === undefined) {
    return field.dateParams?.disablePast ? moment() : undefined;
  }
  const unit = field.dateParams?.unit ?? 'years';
  let minDate: Moment | undefined;
  if (typeof min === 'number') {
    minDate = moment().add(min, unit);
  } else if (min.field) {
    minDate = moment(lodash.get(formValues, min.field), min.format);
    if (min?.age) {
      minDate = minDate.add(min.age, 'years');
    }
  } else {
    minDate = moment().add(min.value ?? 0, min.unit ?? 'years');
  }
  if (field.dateParams?.views && !field.dateParams.views.includes('day')) {
    minDate.startOf('month');
  }
  return minDate;
};

const getMaxDate = (field: TFormField, formValues: IStringIndex<any>) => {
  // Min age corresponds to maximum date
  const minAge = field.dateParams?.minAge;
  if (typeof minAge === 'number') {
    return getDateForMinAge(minAge);
  }
  // Special case for AOIP, with hardcoded values until behaviour can be confirmed to be correct
  if (field.name === 'coverStartDate' && field.variant === 'AOIP') {
    const DoB = formValues['dateOfBirth'];
    const daysUntilMaxAge = Math.ceil(
      moment(DoB, 'DD-MM-YYYY').add(60, 'years').subtract(1, 'days').diff(moment(), 'days', true),
    );
    const days = Math.min(60, daysUntilMaxAge);
    return moment().add(days, 'days');
  }
  // Rest use the "normal" max values
  const max = field.dateParams?.max;
  if (max === undefined) {
    return field.dateParams?.disableFuture ? moment() : undefined;
  }
  const unit = field.dateParams?.unit ?? 'years';
  let maxDate: Moment | undefined;
  if (typeof max === 'number') {
    maxDate = moment().add(max, unit);
  } else if (max.field) {
    maxDate = moment(lodash.get(formValues, max.field), max.format);
    if (max?.age) {
      maxDate = maxDate.add(max.age, 'years');
    }
  } else {
    maxDate = moment().add(max.value ?? 0, max.unit ?? 'years');
  }
  if (field.dateParams?.views && !field.dateParams.views.includes('day')) {
    maxDate.endOf('month');
  }
  return maxDate;
};

export const doDateFieldValidation = (
  field: TFormField,
  value: any,
  formValues: IStringIndex<any>,
): TValidationResult => {
  const dateFormat = field.dateParams?.dateFormat || 'DD-MM-YYYY';
  const newValue = moment(value, dateFormat) as any;
  const minDate = getMinDate(field, formValues);
  const maxDate = getMaxDate(field, formValues);
  const disableFuture = field.dateParams?.disableFuture ?? false;
  const disablePast = field.dateParams?.disablePast ?? false;

  if (field.required && !value) {
    return { valid: false, errorMsg: 'This field is required' };
  }

  if (field.dateParams?.hasCheckbox) {
    if (value === field.dateParams.checkboxProperties?.valueWhenChecked) {
      return { valid: true };
    }
  }

  if (!newValue._isValid) {
    return { valid: false, errorMsg: 'Please enter a valid date.' };
  }

  const now = moment();
  // Date cannot be in the future
  if (disableFuture && newValue.isAfter(now, 'day')) {
    return { valid: false, errorMsg: 'Date cannot be in the future.' };
  }
  // Date cannot be in the past
  if (disablePast && newValue.isBefore(now, 'day')) {
    return { valid: false, errorMsg: 'Date cannot be in the past.' };
  }
  // Validation for max and min unit
  if (minDate !== undefined && maxDate !== undefined) {
    if (!newValue.isBetween(minDate, maxDate, 'day', '[]')) {
      return {
        valid: false,
        errorMsg: `Date must be between ${minDate.format(dateFormat)} and ${maxDate.format(dateFormat)}.`,
      };
    }
  }
  // Validation for max
  if (maxDate !== undefined) {
    if (newValue.isAfter(maxDate, 'day')) {
      return { valid: false, errorMsg: `Date cannot be after ${maxDate.format(dateFormat)}.` };
    }
  }
  // Validation for min
  if (minDate !== undefined) {
    if (newValue.isBefore(minDate, 'day')) {
      return { valid: false, errorMsg: `Date cannot be before ${minDate.format(dateFormat)}.` };
    }
  }
  return { valid: true };
};

// =================================================================================================
// Main component
// =================================================================================================

export const DateField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
  formData: TFormData;
}) => {
  // -----------------------------------------------------------------------------------------------
  // Component state and date parameters
  // -----------------------------------------------------------------------------------------------
  const { field, changeHandler, formData } = props;
  // Date parameters
  const dateFormat = field.dateParams?.dateFormat || 'DD-MM-YYYY';
  const dateMask = field.dateParams?.dateMask || '__-__-____';
  const disableFuture = field.dateParams?.disableFuture ?? false;
  const disablePast = field.dateParams?.disablePast ?? false;
  const minDate = getMinDate(field, formData.values);
  const fullMaxDate = field.dateParams?.fullMaxDate;
  const maxDate = getMaxDate(field, formData.values);
  const fullMinDate = field.dateParams?.fullMinDate;
  const [dateError, setDateError] = useState<string | undefined>();

  const isCoverDateField = field.name === 'coverStartDate';
  const isLockedFields = isCoverDateField && formData.values?.quote?.lockFields;
  const isPaymentConfirmed =
    isCoverDateField &&
    formData.values?.paymentIntent?.status === 'succeeded' &&
    formData?.values?.quote.state === 'QUOTE';
  const isPaymentPending =
    isCoverDateField &&
    formData.values?.paymentIntent?.status === 'requires_payment_method' &&
    formData?.values?.quote.state === 'QUOTE';
  const journeyContext = useContext(JourneyContext);
  const ignoreDisablePrePayment = isLockedFields || isPaymentConfirmed || isPaymentPending;
  const fieldChecked = formData?.values?.[`${field?.name}Checked`];
  const [fieldDisabled, setfieldDisabled] = useState(ignoreDisablePrePayment ? false : fieldChecked ?? field.disabled);
  const [checked, setChecked] = useState(fieldChecked);
  const [value, setValue] = useState(fieldChecked ? null : props.value ? moment(props.value, dateFormat) : null);
  // -----------------------------------------------------------------------------------------------
  // Change handler
  // -----------------------------------------------------------------------------------------------

  useEffect(() => {
    const validation = doDateFieldValidation(field, value, formData.values);

    // new => if date field depends on other date fields
    if (formData.values['isSideBySide'] === true && field.dependents && value !== null) {
      // only being used in SBS for now could be used for other products
      const pastExpirationDate = validateExpirationDate(field.dependents, formData, value);
      if (pastExpirationDate) {
        if (dateError !== field.fieldErrors) {
          setDateError(field.fieldErrors);
        }
        if (formData.values[field.name] !== undefined) {
          changeHandler(field, undefined);
        }
        return;
      }
    }

    if (validation.valid) {
      if (dateError) {
        setDateError(undefined);
      }
      if (formData.values[field.name] !== value!.format(dateFormat)) {
        let formattedValue = value!.format(dateFormat);
        if (field.dateParams?.valueType === 'number') {
          changeHandler(field, Number(formattedValue));
        } else {
          changeHandler(field, formattedValue);
        }
      }
    } else {
      if (value && dateError !== validation.errorMsg) {
        setDateError(validation.errorMsg);
        changeHandler(field, undefined);
      }
    }
  }, [
    value,
    formData?.values?.yearOfManufacture,
    formData?.values?.yearsOfNoClaimInsurance,
    journeyContext?.formData?.values?.yearsOfNoClaimInsurance,
  ]);

  const localChangeHandler = (newValue: any) => {
    setValue(newValue);
  };

  const handleCheckboxChange = (e: any) => {
    const value = e.target.checked;
    setChecked(value);
    if (value) {
      const valueWhenChecked = field?.dateParams?.checkboxProperties?.valueWhenChecked?.startsWith('${')
        ? replacePlaceholders(props.formData.values, field?.dateParams?.checkboxProperties?.valueWhenChecked)
        : field?.dateParams?.checkboxProperties?.valueWhenChecked;

      changeHandler(field, valueWhenChecked ?? 'N/A');
      changeHandler({ name: `${field.name}Checked` } as any, value);
      setValue(null);
      setfieldDisabled(true);
    } else {
      setfieldDisabled(ignoreDisablePrePayment ? false : field.disabled);
      changeHandler(field, undefined);
      changeHandler({ name: `${field.name}Checked` } as any, value);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------
  let defaultDate = undefined;
  if (field.dateParams?.defaultDate === 'min') {
    defaultDate = minDate;
  } else if (field.dateParams?.defaultDate === 'max') {
    defaultDate = maxDate;
  }

  return (
    <>
      {field?.dateParams?.hasCheckbox && (
        <FormControlLabel
          sx={{
            color: '#565E73',
            fontSize: 14,
            fontWeight: 500,
          }}
          control={
            <Checkbox
              disabled={field.disabled}
              size="small"
              color="primary"
              onChange={handleCheckboxChange}
              checked={checked}
              value={checked}
            />
          }
          label={field?.dateParams?.checkboxProperties?.label}
        />
      )}
      <LocalizationProvider dateAdapter={DateAdapter}>
        <DatePicker
          views={field.dateParams?.views}
          disabled={fieldDisabled}
          label={field.title}
          disablePast={disablePast}
          disableFuture={disableFuture}
          minDate={fullMinDate ? moment(new Date(fullMinDate)) : minDate}
          maxDate={fullMaxDate ? moment(new Date(fullMaxDate)) : maxDate}
          mask={dateMask}
          inputFormat={dateFormat}
          defaultCalendarMonth={defaultDate}
          value={value}
          allowSameDateSelection
          disableMaskedInput={false}
          onChange={localChangeHandler}
          renderInput={(params: TextFieldProps) => (
            <TextField
              autoComplete="autocomplete_off_randString"
              fullWidth
              variant="outlined"
              {...params}
              helperText={dateError || ''}
              error={Boolean(dateError)}
              required={field.required}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

// checks if cover date is past fire arm expiration date
function validateExpirationDate(dependents: string[] | undefined, formData: TFormData, newValue: any) {
  if (!dependents) return true;
  let dates = [] as any;
  for (let fieldName of dependents) {
    const matchingValue = formData.values[fieldName];
    if (matchingValue) {
      const [day, month, year] = matchingValue.split('-');
      dates.push(`${year}-${month}-${day}`);
    }
  }
  // getting min date between dates array
  const minDate = new Date(Math.min(...dates.map((d: any) => new Date(d).getTime())));
  const coverDate = new Date(newValue._d);

  const formattedDates = {
    cover: moment(coverDate).format('YYYY-MM-DD'),
    expiration: moment(minDate).format('YYYY-MM-DD'),
  };
  if (formattedDates.cover === formattedDates.expiration) return false;
  // if cover date past expiration date
  return new Date(coverDate).getTime() >= new Date(minDate).getTime();
}

const setMinimuDate = (field: TFormField, formValues: IStringIndex<any>) => {
  const currentDate = moment();
  let min = field.dateParams?.min;
  if (field.name === 'purchasedDate' && formValues?.yearOfManufacture !== undefined) {
    const year = moment(`${formValues?.yearOfManufacture}, 01, 01`);
    const difference = currentDate.diff(year, 'days');
    console.log(difference);
    min = { unit: 'days', value: -difference };
  }
  return min;
};
