// =================================================================================================
// This is our wrapper for the MUI component of the same name
// =================================================================================================

import { Checkbox, FormControlLabel, InputAdornment, TextField as TextFieldMUI } from '@mui/material';
import { TFieldChangeHandler, TFormData, TFormField, TValidationResult } from '../../types';
import EmailIcon from '@mui/icons-material/EmailOutlined';
import PhoneIcon from '@mui/icons-material/PhoneOutlined';
import validator from 'validator';
import { useState } from 'react';
import { isValidPostcode } from '../../fieldValidation';
import { CurrencyPound } from '@mui/icons-material';
import { replacePlaceholders } from '../../formHandling';

// =================================================================================================
// Helper functions
// =================================================================================================

const getInputProps = (field: TFormField) => {
  switch (field.variant) {
    case 'phone':
      return { maxLength: 11 };
    case 'signature':
      return { style: { fontFamily: "'Dancing Script', cursive", fontSize: 20 } };
    default:
      return undefined;
  }
};

const getAdornments = (field: TFormField) => {
  if (field?.params?.adornment) {
    const { start, end } = field.params.adornment;
    if (end) {
      return {
        endAdornment: <InputAdornment position="end">{end}</InputAdornment>,
      };
    } else
      return {
        startAdornment: <InputAdornment position="start">{start}</InputAdornment>,
      };
  }

  switch (field.variant) {
    case 'email':
      return {
        endAdornment: (
          <InputAdornment position="end">
            <EmailIcon />
          </InputAdornment>
        ),
      };
    case 'phone': {
      return {
        endAdornment: (
          <InputAdornment position="end">
            <PhoneIcon />
          </InputAdornment>
        ),
      };
    }
    default:
      return undefined;
  }
};

// =================================================================================================
// Validation
// =================================================================================================

export const doTextFieldValidation = (field: TFormField, value: string): TValidationResult => {
  if (!value || value.trim().length === 0) {
    // Required fields cannot be blank
    if (field.required) {
      return { valid: false, errorMsg: 'This field is required.' };
    } else {
      return { valid: true };
    }
  } else {
    const allowSpaces = field?.params?.allowSpaces ?? true;

    if (!allowSpaces && /\s/.test(value)) {
      return {
        valid: false,
        errorMsg: 'Spaces are not allowed',
      };
    }

    if (
      (field.variant === 'alphabet' || field.variant === 'signature') &&
      !validator.isAlpha(value, 'en-GB', { ignore: " -'" })
    ) {
      return {
        valid: false,
        errorMsg: `Please enter only alphabet characters.`,
      };
    }

    if (field.variant === 'numeric' && !validator.isNumeric(value.trim())) {
      return {
        valid: false,
        errorMsg: 'Please enter only numeric values.',
      };
    }

    if (field.variant === 'sort') {
      if (value.trim().length !== 6) {
        return {
          valid: false,
          errorMsg: 'Please enter the 6-digit sort code without hyphens, e.g. 123456.',
        };
      } else {
        return { valid: true };
      }
    }

    if (field.variant === 'postcode') {
      if (isValidPostcode(value.trim())) {
        return { valid: true };
      } else {
        return { valid: false, errorMsg: 'Postcode is invalid.' };
      }
    }

    const min = field.params?.min as number | undefined;
    const max = field.params?.max as number | undefined;
    // Enforce range (length has both minimum and maximum)
    if (min !== undefined && max !== undefined && (value.length > max || value.length < min)) {
      return {
        valid: false,
        errorMsg: `Please enter ${min} to ${max} characters.`,
      };
    }
    // Enforce maximum length
    if (max !== undefined && value.length > max) {
      return {
        valid: false,
        errorMsg: `Please enter ${max} characters or fewer.`,
      };
    }
    // Enforce minimum length
    if (min !== undefined && value.length < min) {
      return {
        valid: false,
        errorMsg: `Please enter ${min} characters or more.`,
      };
    }
    // Email address validation
    if (field.variant === 'email') {
      if (!validator.isEmail(value)) {
        return { valid: false, errorMsg: 'Please enter a valid email address.' };
      }
    }
    // Phone number validation
    if (field.variant === 'phone') {
      // couldn't find a good regex for this
      if (
        !validator.isNumeric(value) ||
        value.startsWith('00') ||
        !value.startsWith('0') ||
        value.length < 11 ||
        /\s/.test(value)
      ) {
        return {
          valid: false,
          errorMsg: 'Please enter a valid phone number 0xxxxxxxxxx.',
        };
      }
    }
    // This string is perfectly fine. Probably.
    return { valid: true };
  }
};

// =================================================================================================
// Main component
// =================================================================================================

export const TextField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
  formData: TFormData;
}) => {
  // -----------------------------------------------------------------------------------------------
  // Component state
  // -----------------------------------------------------------------------------------------------

  const { field, changeHandler, formData } = props;
  const isPaymentConfirmed =
    formData.values?.paymentIntent?.status === 'suceeded' && formData?.values?.quote.state === 'QUOTE';

  const ignoreDisablePrePayment = isPaymentConfirmed;
  const fieldChecked = formData?.values?.[`${field?.name}Checked`];
  const [value, setValue] = useState(fieldChecked ? null : props.value);
  const [validationError, setValidationError] = useState<string | undefined>();
  const [fieldDisabled, setfieldDisabled] = useState(ignoreDisablePrePayment ? false : fieldChecked || field.disabled);
  const [checked, setChecked] = useState(fieldChecked);

  // -----------------------------------------------------------------------------------------------
  // Component change handler
  // -----------------------------------------------------------------------------------------------

  const localChangeHandler = (newValue: string) => {
    setValue(newValue);
    const validation = doTextFieldValidation(field, newValue);
    if (validation.valid) {
      changeHandler(field, newValue);
      if (validationError?.length) {
        setValidationError(undefined);
      }
    } else {
      setValidationError(validation.errorMsg);
      changeHandler(field, undefined);
    }
  };

  const handleCheckboxChange = (e: any) => {
    const value = e.target.checked;
    setChecked(value);
    if (value) {
      const valueWhenChecked = field?.params?.checkboxProperties?.valueWhenChecked?.startsWith('${')
        ? replacePlaceholders(props.formData.values, field?.params?.checkboxProperties?.valueWhenChecked)
        : field?.params?.checkboxProperties?.valueWhenChecked;

      changeHandler(field, valueWhenChecked ?? 'N/A');
      changeHandler({ name: `${field.name}Checked` } as any, value);
      setValue(null);
      setfieldDisabled(true);
    } else {
      setfieldDisabled(ignoreDisablePrePayment ? false : field.disabled);
      changeHandler(field, undefined);
      changeHandler({ name: `${field.name}Checked` } as any, value);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------

  return (
    <>
      {field?.params?.hasCheckbox && (
        <FormControlLabel
          sx={{
            color: '#565E73',
            fontSize: 14,
            fontWeight: 500,
          }}
          control={
            <Checkbox
              disabled={field.disabled}
              size="small"
              color="primary"
              onChange={handleCheckboxChange}
              checked={checked}
              value={checked}
            />
          }
          label={field?.params?.checkboxProperties?.label}
        />
      )}
      <TextFieldMUI
        autoComplete="autocomplete_off_randString"
        disabled={fieldDisabled}
        fullWidth
        label={field.title}
        variant="outlined"
        InputProps={getAdornments(field)}
        multiline={field.variant === 'multiline'}
        rows={field.variant === 'multiline' ? 4 : 1}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => localChangeHandler(event.target.value)}
        inputProps={getInputProps(field)}
        required={field.required}
        error={Boolean(validationError)}
        helperText={validationError || ''}
        value={value || ''}
      />
    </>
  );
};

const AdornmentIcons = {
  pounds: <CurrencyPound />,
};
