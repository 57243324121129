export const RiskClass = [
  {
    label: "Motor",
    value: "MOTOR",
  },
  {
    label: "Breakdown",
    value: "MBI",
  },
];
