import { createContext, useState } from "react";
import React from "react";

export const ProductsContext = createContext<any>({});

function ProductsContextProvider({ children }: any) {
  const [product, setProduct] = useState<any>(null);

  const updateProductData = (data: any) => setProduct(data);
  return (
    <ProductsContext.Provider value={{ product, updateProductData }}>
      {children}
    </ProductsContext.Provider>
  );
}

export default ProductsContextProvider;
