export const VehicleUse = [
  {
    "label": "Airside",
    "value": "08"
  },
  {
    "label": "Carriage Of Goods For Hire And Reward",
    "value": "09"
  },
  {
    "label": "Carriage Of Own Goods",
    "value": "10"
  },
  {
    "label": "Carriage Of School Children For Hire And Reward",
    "value": "11"
  },
  {
    "label": "Chauffeured Hire",
    "value": "21"
  },
  {
    "label": "Civil Partners Business",
    "value": "23"
  },
  {
    "label": "Commercial Travelling",
    "value": "06"
  },
  {
    "label": "Common Law Partners Business",
    "value": "24"
  },
  {
    "label": "Commuting",
    "value": "01"
  },
  {
    "label": "Competitions, Pacemaking, Racing, Rallies, Trials, Speed Testing",
    "value": "12"
  },
  {
    "label": "Driving Tuition",
    "value": "13"
  },
  {
    "label": "Motor Trade",
    "value": "14"
  },
  {
    "label": "Named Drivers Business",
    "value": "20"
  },
  {
    "label": "Non Standard Use",
    "value": "02"
  },
  {
    "label": "Personal Business Use",
    "value": "03"
  },
  {
    "label": "Private Hire",
    "value": "15"
  },
  {
    "label": "Proposers Business",
    "value": "16"
  },
  {
    "label": "Proposers, Employers Or  Business Partners Business",
    "value": "19"
  },
  {
    "label": "Public Hire",
    "value": "17"
  },
  {
    "label": "Social, Domestic And Pleasure",
    "value": "04"
  },
  {
    "label": "Spouses Business",
    "value": "18"
  },
  {
    "label": "Teachers Use",
    "value": "07"
  },
  {
    "label": "Use On Anothers Business",
    "value": "05"
  },
  {
    "label": "Wedding Chauffeured Hire",
    "value": "22"
  }
]