// =================================================================================================
// Component specific to the Multiple Appliances journey.
// =================================================================================================
// I experienced mild depression developing this one. If you are looking at this with the intention
// of editing it, good luck sucker.
// =================================================================================================

import {
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import validator from "validator";
import { TFormField, TFieldChangeHandler } from "../../types";

import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";

const appliancesTypeA = [
  "Dishwasher",
  "Free Standing Cooker",
  "Freezer",
  "Fridge/Freezer",
  "Cooker",
  "Hob",
  "Oven",
  "Microwave Oven",
  "Refrigerator",
  "Tumble Dryer",
  "Washer/Dryer",
  "Washing Machine",
  "Wine Cooler",
];

const appliancesTypeB = [
  "Satellite/Freeview Boxes",
  "Digital Radios",
  "Home Entertainment Equipment",
  "Vacuum Cleaner",
  "De-Humidifiers",
  "Halogen Oven",
  "Slow Cooker",
  "Food Processor",
  "DVD Player/Blue Ray Players",
  "Televisions (incl. OLED/LCD/LED/Plasma)",
  "Smart Speakers/Wireless Speakers/Sound Bars",
];

type TAppliance = {
  type: string;
  make: string;
  model: string;
  serialNumber: string;
  proof: boolean;
  age: number;
  value: number;
};

// =================================================================================================
// Purchase Details Input
// =================================================================================================

const PurchaseDetailsInput = (props: any) => {
  // -----------------------------------------------------------------------------------------------
  // Component state
  // -----------------------------------------------------------------------------------------------
  const [purchase, setPurchase] = useState(props.purchase ?? {});
  const [valueError, setValueError] = useState("");
  const { onChange, limit } = props;
  const [total, setTotal] = useState(props.total);

  // -----------------------------------------------------------------------------------------------
  // Change handlers and helpers
  // -----------------------------------------------------------------------------------------------

  const purchaseValueChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const newPurchase = { ...purchase, value: newValue };
    setPurchase(newPurchase);
    if (newValue.trim() === "") {
      setValueError("This field is required.");
      onChange({ ...purchase, value: undefined });
    } else if (!validator.isInt(newValue)) {
      setValueError("Please enter an integer value.");
      onChange({ ...purchase, value: undefined });
    } else if (Number(newValue) < 0) {
      setValueError("Value cannot be negative.");
      onChange({ ...purchase, value: undefined });
    } else if (Number(newValue) + total > limit) {
      setValueError(
        `Total value of all appliances cannot exceed £${limit}. Your current total is £${
          Number(newValue) + total
        }.`
      );
      onChange({ ...purchase, value: undefined });
    } else {
      setValueError("");
      onChange(newPurchase);
    }
  };

  const roundPurchaseValue = () => {
    if (!valueError) {
      const newValue = Math.ceil(purchase.value / 100) * 100;
      const newPurchase = { ...purchase, value: newValue };
      setPurchase(newPurchase);
      onChange(newPurchase);
    }
  };

  const purchaseAgeChanged = (event: any) => {
    const newAge = event.target.value;
    let newPurchase = {};
    if (newAge >= 8) {
      newPurchase = { proof: true, age: newAge };
    } else {
      newPurchase = { ...purchase, age: newAge };
    }
    setPurchase(newPurchase);
    onChange(newPurchase);
  };

  // -----------------------------------------------------------------------------------------------
  // Render
  // -----------------------------------------------------------------------------------------------

  return (
    <Stack spacing={3}>
      <p>Do you have the proof of purchase?</p>
      <Stack direction="row" alignItems="center" spacing={2}>
        <Button
          color="primary"
          size="large"
          variant={purchase.proof === true ? "contained" : "outlined"}
          sx={{
            textTransform: "none",
          }}
          onClick={() => {
            const newPurchase = { ...purchase, proof: true };
            setPurchase(newPurchase);
            setValueError("");
            onChange(newPurchase);
          }}
        >
          Yes
        </Button>
        <Button
          color="primary"
          size="large"
          variant={purchase.proof === false ? "contained" : "outlined"}
          sx={{
            textTransform: "none",
          }}
          onClick={() => {
            const newPurchase = { proof: false };
            setPurchase(newPurchase);
            setValueError("");
            onChange(newPurchase);
          }}
        >
          No
        </Button>
      </Stack>
      {purchase.proof === false && (
        <b>Proof of purchase is required for each appliance to be insured.</b>
      )}
      {purchase.proof === true && (
        <Stack spacing={3}>
          <p>What is the age of appliance?</p>
          <FormControl fullWidth required>
            <InputLabel id={"appliance-age-label"}>Age of Appliance</InputLabel>
            <Select
              labelId={"appliance-age-label"}
              label={"Type of Appliance"}
              onChange={purchaseAgeChanged}
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
              value={purchase.age ?? ""}
            >
              <MenuItem key={1} value={1}>
                Up to 1 year
              </MenuItem>
              {[2, 3, 4, 5, 6, 7, 8].map((age) => {
                return (
                  <MenuItem key={age} value={age}>
                    Up to {age} years
                  </MenuItem>
                );
              })}
              <MenuItem key={9} value={9}>
                Older than 8 years
              </MenuItem>
            </Select>
          </FormControl>
          {purchase.age === 9 && <b>Maximum age is 8 years.</b>}
          {purchase.age > 0 && purchase.age < 9 && (
            <Stack spacing={3}>
              <p>What is the value of the appliance?</p>
              <TextField
                required
                fullWidth
                label={"Appliance Value"}
                variant="outlined"
                value={purchase.value ? purchase.value + "" : ""}
                onChange={purchaseValueChanged}
                error={Boolean(valueError)}
                onBlur={roundPurchaseValue}
                inputProps={{ maxLength: 4 }}
                helperText={
                  valueError ||
                  "Value will automatically be rounded up to the next increment of £100"
                }
                InputProps={{
                  startAdornment: <InputAdornment position="start">£</InputAdornment>,
                }}
              />
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

// =================================================================================================
// Appliance Details Input
// =================================================================================================

const ApplianceDetailsInput = (props: any) => {
  // -----------------------------------------------------------------------------------------------
  // Component state
  // -----------------------------------------------------------------------------------------------
  const [appliance, setAppliance] = useState(props.value || {});

  const [makeError, setMakeError] = useState("");
  const [modelError, setModelError] = useState("");
  const [serialNumberError, setSerialNumberError] = useState("");

  // -----------------------------------------------------------------------------------------------
  // Change handlers and helpers
  // -----------------------------------------------------------------------------------------------

  const onChange = props.onChange;
  //const applianceOptions = [...appliancesTypeA, ...appliancesTypeB];

  const applianceTypeChanged = (event: any) => {
    const newAppliance = { ...appliance, type: event.target.value };
    setAppliance(newAppliance);
    onChange(newAppliance);
    // if (appliancesTypeB.includes(event.target.value)) {
    //   console.log("Consumer electronics!");
    // }
  };

  const applianceMakeChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAppliance = { ...appliance, make: event.target.value };
    setAppliance(newAppliance);
    if (event.target.value.trim() === "") {
      setMakeError("This field is required.");
      onChange({ ...appliance, make: undefined });
    } else {
      onChange(newAppliance);
      setMakeError("");
    }
  };

  const applianceModelChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAppliance = { ...appliance, model: event.target.value };
    setAppliance(newAppliance);
    if (event.target.value.trim() === "") {
      setModelError("This field is required.");
      onChange({ ...appliance, model: undefined });
    } else {
      onChange(newAppliance);
      setModelError("");
    }
  };

  const applianceSerialNumberChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newAppliance = { ...appliance, serialNumber: event.target.value };
    setAppliance(newAppliance);
    if (event.target.value.trim() === "") {
      setSerialNumberError("This field is required.");
      onChange({ ...appliance, serialNumber: undefined });
    } else {
      onChange(newAppliance);
      setSerialNumberError("");
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Render
  // -----------------------------------------------------------------------------------------------

  return (
    <Stack spacing={3}>
      <p>Please fill in the details for this appliance.</p>
      <FormControl fullWidth required>
        <InputLabel id={"appliance-type-label"}>Type of Appliance</InputLabel>
        <Select
          labelId={"appliance-type-label"}
          label={"Type of Appliance"}
          onChange={applianceTypeChanged}
          MenuProps={{
            style: {
              maxHeight: 400,
            },
          }}
          value={appliance.type ?? ""}
        >
          <ListSubheader>Domestic Appliances</ListSubheader>
          {appliancesTypeA.map((a) => {
            return (
              <MenuItem key={a} value={a}>
                {a}
              </MenuItem>
            );
          })}
          <ListSubheader>Consumer Electronics</ListSubheader>
          {appliancesTypeB.map((b) => {
            return (
              <MenuItem key={b} value={b}>
                {b}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <TextField
        required
        fullWidth
        label={"Appliance Make"}
        variant="outlined"
        value={appliance.make ?? ""}
        onChange={applianceMakeChanged}
        error={Boolean(makeError)}
        helperText={makeError}
      />
      <TextField
        required
        fullWidth
        label={"Appliance Model"}
        variant="outlined"
        value={appliance.model ?? ""}
        onChange={applianceModelChanged}
        error={Boolean(modelError)}
        helperText={modelError}
      />
      <TextField
        required
        fullWidth
        label={"Appliance Serial Number"}
        variant="outlined"
        value={appliance.serialNumber ?? ""}
        onChange={applianceSerialNumberChanged}
        error={Boolean(serialNumberError)}
        helperText={serialNumberError}
      />
    </Stack>
  );
};

// =================================================================================================
// Appliance card component
// =================================================================================================

const ApplianceCard = (props: {
  appliance: TAppliance;
  onSave: (newAppliance: TAppliance | null) => void;
  onEdit: (editAppliance: TAppliance) => void;
  disabled: boolean;
}) => {
  const { appliance, onEdit, onSave, disabled } = props;
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Card sx={{ padding: 2, borderRadius: 2 }}>
      {/* renderEditDialog() */}
      <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
        <h4 style={{ flex: 1 }}>
          {appliance.make} {appliance.model}
        </h4>
        <b>£{(appliance.value ?? 0).toLocaleString("en-GB")}</b>
        <IconButton color="primary" onClick={toggleExpanded} disabled={disabled}>
          {expanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
        </IconButton>
      </Stack>
      <small>{appliance.type}</small>
      {expanded && (
        <Stack direction="row" alignItems="center" justifyContent="space-around">
          <Stack justifyContent="center" alignItems="center" spacing={1}>
            <IconButton
              color="primary"
              component="span"
              onClick={() => onEdit(appliance)}
              disabled={disabled}
            >
              <EditOutlinedIcon fontSize="large" />
            </IconButton>
            <small>Edit Details</small>
          </Stack>
          <Stack justifyContent="center" alignItems="center" spacing={1}>
            <IconButton
              color="primary"
              component="span"
              onClick={() => onSave(null)}
              disabled={disabled}
            >
              <DeleteForeverOutlinedIcon fontSize="large" />
            </IconButton>
            <small>Remove</small>
          </Stack>
        </Stack>
      )}
    </Card>
  );
};

// =================================================================================================
// Main component
// =================================================================================================

export const AppliancesField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
}) => {
  // -----------------------------------------------------------------------------------------------
  // Component state
  // -----------------------------------------------------------------------------------------------

  const value = props.value;

  const { field, changeHandler } = props;
  const [step, setStep] = useState(0);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [purchaseDetails, setPurchaseDetails] = useState<any>({});
  const [applianceDetails, setApplianceDetails] = useState<any>({});
  const [appliances, setAppliances] = useState<TAppliance[]>(value?.list ?? []);

  const coverLimit = 7500;
  const maxAppliances = 10;

  let total = 0;
  for (let a of appliances) {
    total += Number(a.value);
  }

  const showPurchaseDialog = (purchaseDetails: any, applianceDetails: any, index: number) => {
    setPurchaseDetails(purchaseDetails);
    setApplianceDetails(applianceDetails);
    setActiveIndex(index);
    setStep(1);
  };

  const hideDialog = () => {
    setActiveIndex(-1);
    setStep(0);
  };

  const showApplianceDialog = () => {
    setStep(2);
  };

  const purchaseDetailsChanged = (what: any) => {
    setPurchaseDetails(what);
  };

  const applianceDetailsChanged = (what: any) => {
    setApplianceDetails(what);
  };

  const addAppliance = () => {
    const newAppliances = [...appliances, { ...applianceDetails, ...purchaseDetails }];
    let newTotal = total + purchaseDetails.value;
    setAppliances(newAppliances);
    changeHandler(field, {
      list: [...newAppliances],
      total: newTotal + "",
      type: newAppliances.find((appliance) => appliancesTypeB.includes(appliance.type)) ? "B" : "A",
      number: newAppliances.length,
    });
    setApplianceDetails({});
    setPurchaseDetails({});
    hideDialog();
  };

  const renderDialog = () => {
    return (
      <Dialog
        open={step > 0}
        scroll="paper"
        fullWidth
        PaperProps={{ sx: { width: "100%", margin: "0px", minHeight: "550px" } }}
      >
        <DialogTitle>{step === 1 ? "Purchase Details" : "Appliance Details"}</DialogTitle>
        <DialogContent>
          {step === 1 ? (
            <PurchaseDetailsInput
              purchase={purchaseDetails}
              onChange={purchaseDetailsChanged}
              limit={coverLimit}
              total={activeIndex === -1 ? total : total - purchaseDetails.value}
            />
          ) : (
            <ApplianceDetailsInput value={applianceDetails} onChange={applianceDetailsChanged} />
          )}
        </DialogContent>
        <div style={{ padding: 16 }}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <Button onClick={hideDialog}>Cancel</Button>
            <Button
              disabled={
                step === 1
                  ? purchaseDetails.proof !== true || !purchaseDetails.age || !purchaseDetails.value
                  : !applianceDetails.type ||
                    !applianceDetails.make ||
                    !applianceDetails.model ||
                    !applianceDetails.serialNumber
              }
              fullWidth
              variant="contained"
              size="large"
              sx={{
                borderRadius: 15,
                textTransform: "none",
                fontSize: 18,
              }}
              onClick={
                step === 1
                  ? showApplianceDialog
                  : activeIndex >= 0
                  ? () => {
                      updateAppliance(activeIndex, { ...applianceDetails, ...purchaseDetails });
                      hideDialog();
                    }
                  : addAppliance
              }
            >
              {step === 1 ? "Next" : activeIndex >= 0 ? "Apply Changes" : "Add Appliance"}
            </Button>
          </Stack>
        </div>
      </Dialog>
    );
  };

  const updateAppliance = (index: number, newAppliance: TAppliance | null) => {
    const newAppliances = [...appliances];
    if (newAppliance === null) {
      newAppliances.splice(index, 1);
    } else {
      newAppliances[index] = newAppliance;
    }
    let total = 0;
    for (let a of newAppliances) {
      total += a.value;
    }
    setAppliances(newAppliances);
    changeHandler(field, {
      list: newAppliances,
      total: total + "",
      type: newAppliances.find((appliance) => appliancesTypeB.includes(appliance.type)) ? "B" : "A",
      number: newAppliances.length,
    });
  };

  return (
    <Stack spacing={3}>
      {step > 0 && renderDialog()}
      <p>
        Add up to {maxAppliances} appliances that have a maximum combined value of{" "}
        <b>£{coverLimit.toLocaleString("en-GB")}</b>.
      </p>
      {appliances.length > 0 && (
        <Stack direction="row" alignItems="center" justifyContent={"space-between"}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <span>Appliances</span>
            <h3>
              {appliances.length}/{maxAppliances}
            </h3>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={2}>
            <span>Combined value</span>
            <h3>£{total.toLocaleString("en-GB")}</h3>
          </Stack>
        </Stack>
      )}
      {appliances.map((app, index) => (
        <ApplianceCard
          disabled={field.disabled ?? false}
          key={index}
          appliance={app}
          onSave={(newAppliance) => updateAppliance(index, newAppliance)}
          onEdit={() => {
            showPurchaseDialog(
              { proof: app.proof, age: app.age, value: app.value },
              { type: app.type, make: app.make, model: app.model, serialNumber: app.serialNumber },
              index
            );
          }}
        />
      ))}
      {appliances.length < maxAppliances && total < coverLimit && (
        <Button
          color="primary"
          variant="contained"
          onClick={() => showPurchaseDialog({}, {}, -1)}
          fullWidth
          sx={{ borderRadius: 5 }}
          startIcon={<AddIcon />}
          disabled={field.disabled}
        >
          Add Appliance
        </Button>
      )}
      {total > coverLimit && (
        <Stack spacing={1}>
          <b>
            The total value of your appliances exceeds your selected cover limit by £
            {(total - coverLimit).toLocaleString("en-GB")}.
          </b>
          <b>Please edit or remove one or more appliances.</b>
        </Stack>
      )}
      {appliances.length === 0 && <b>Please make sure you have added at least one appliance.</b>}
    </Stack>
  );
};
