export const PublicLiabilityLevel = [
  {
    label: "One Million",
    value: "1000000",
  },
  {
    label: "Five Million",
    value: "5000000",
  },
  {
    label: "Ten Million",
    value: "10000000",
  },
];
