export const SecurityDeviceManufacturerAndModel = [
  { label: "A.T.M. Door Lock Guard", value: "1081" },
  { label: "AA Electronic Car Immobiliser", value: "410" },
  { label: "AA Electronic Immobiliser", value: "223" },
  { label: "AA Foxguard Alarm/Immobiliser", value: "369" },
  { label: "AA High Security Steering Wheel Lock", value: "3" },
  { label: "AA Immobiliser", value: "409" },
  { label: "AA Meta Max Alarm/Immobiliser", value: "528" },
  { label: "AA Protector", value: "371" },
  { label: "AA SG 30", value: "296" },
  { label: "AA Sigma Car Immobiliser", value: "372" },
  { label: "AA Sigma SG30", value: "370" },
  { label: "AA Sparkrite Alarm And Immobiliser", value: "2" },
  { label: "AA Sparkrite Car Alarm", value: "1" },
  { label: "AA Vecta Car Immobiliser", value: "373" },
  { label: "ABC - SFF SFF3", value: "1722" },
  { label: "ABC - SFF SFF4", value: "1724" },
  { label: "ABC - SFF SFF6", value: "2178" },
  { label: "ABC Umformtechnik AutoBloc", value: "1214" },
  { label: "ABC Umformtechnik AUTOBLOC - RDS Locking Wheel Device", value: "2229" },
  { label: "ABC Umformtechnik Safe Bolt", value: "1212" },
  { label: "ABC Umformtechnik Simbolz", value: "1213" },
  { label: "Abletronics A100", value: "355" },
  { label: "ABUS Carblock Granit", value: "4" },
  { label: "Acos-Tesa 6937 Embossed Label", value: "1422" },
  { label: "Acos-Tesa 6947 Label", value: "1420" },
  { label: "Active 8 Electrical Immobiliser CS12", value: "5" },
  { label: "Active 8 Electrical Immobiliser CS24", value: "6" },
  { label: "Active 8 Electrical Immobiliser JAP12", value: "7" },
  { label: "Active 8 Electrical Immobiliser JAP24", value: "8" },
  { label: "ADL AutoBloc BH", value: "1408" },
  { label: "ADL AutoBloc SH", value: "1409" },
  { label: "ADL Nogolock", value: "1008" },
  { label: "ADL sa Auto bloc SHC", value: "1723" },
  { label: "AEB Technologies S.p.A MED 7450 UK Alarm", value: "1478" },
  { label: "Aegis Engineering Services RoadEye", value: "1087" },
  { label: "Aeromark Ltd Aerotrack", value: "1334" },
  { label: "AGC AN780", value: "678" },
  { label: "Alfa Romeo Code 2 Immobiliser", value: "834" },
  { label: "Alfa Romeo Code Transponder Immobiliser", value: "374" },
  { label: "Alfa Romeo FGA Metasystem", value: "1376" },
  { label: "Alfa Romeo M36TFA", value: "584" },
  { label: "Alfa Romeo M99 T2FA", value: "754" },
  { label: "Alfa Romeo M99 TFA", value: "583" },
  { label: "Alfa Romeo TRW Siemens", value: "753" },
  { label: "Alliance (Tritec) Sleeper 5000", value: "457" },
  { label: "Alpha Micro Trac Automotive", value: "1414" },
  { label: "Alpina Alarm System", value: "2083" },
  { label: "Alpine Alarm System", value: "2386" },
  { label: "Apache 150", value: "107" },
  { label: "Apache 300", value: "108" },
  { label: "Apollo 2002", value: "489" },
  { label: "Aragon Immobiliser", value: "329" },
  { label: "Armour Automotive Ltd Armour Security MAX", value: "2052" },
  { label: "ASP Carback", value: "398" },
  { label: "Asset Assured Phantom - Cars and Vans", value: "906" },
  { label: "Aston Martin Immobiliser", value: "330" },
  { label: "Aston Martin Tracking", value: "1355" },
  { label: "Aston Martin Tracking by Cobra", value: "1944" },
  { label: "Aston Martin Tracking II", value: "1374" },
  { label: "Audi 5021", value: "654" },
  { label: "Audi Alarm System", value: "777" },
  { label: "Audi Cobra Trak 5", value: "1447" },
  { label: "Audi Cobra Trak First/Audi Cobra Trak First Mobile", value: "1474" },
  { label: "Audi Immobiliser", value: "585" },
  { label: "Audi Security System I", value: "879" },
  { label: "Audi Security System V AU-FI", value: "1278" },
  { label: "Audi Security System VI AU-FI", value: "1337" },
  { label: "Audi Tracking Assistance Plus Gen II", value: "1934" },
  { label: "Audi Tracking Assistant", value: "1453" },
  { label: "Audi Tracking Assistant Plus", value: "1454" },
  { label: "Audi Tracking Assistant Plus Inhibition System", value: "1510" },
  { label: "Audi UK Security System 6 Audi-FI", value: "2185" },
  { label: "Audioline MS741", value: "547" },
  { label: "Auto Security Direct Parksafe Immobilock", value: "550" },
  { label: "Auto Turned Products Wheel Safe", value: "1728" },
  { label: "Auto Turned Products Wheelsafe", value: "1563" },
  { label: "Auto Turned Products Wheelsafe", value: "1265" },
  { label: "Auto Watch 59JI Digital", value: "297" },
  { label: "Auto-txt Discover", value: "1273" },
  { label: "Auto-txt Ltd Aston Martin Tracking II", value: "1364" },
  { label: "Auto-txt Pursue", value: "1248" },
  { label: "Autoblock (Centurion) Immobiliser", value: "548" },
  { label: "Autoblock AB0387", value: "109" },
  { label: "Autoblock AB0387/S", value: "110" },
  { label: "Autoblock AB0389", value: "112" },
  { label: "Autoblock AB0399", value: "113" },
  { label: "Autoblock Immobiliser", value: "473" },
  { label: "Autoblock ST1", value: "111" },
  { label: "AUTOCONNEX Ltd", value: "1476" },
  { label: "Autogenius Easylock Immobiliser", value: "474" },
  { label: "Autoguard 803", value: "298" },
  { label: "Autojack 090S", value: "375" },
  { label: "Autojack 101", value: "9" },
  { label: "Autojack 101E", value: "285" },
  { label: "Autojack 124", value: "10" },
  { label: "Autojack 2000", value: "11" },
  { label: "Autojack 202", value: "12" },
  { label: "Autojack 224", value: "13" },
  { label: "Autojack 303", value: "14" },
  { label: "Autojack 404", value: "15" },
  { label: "Autojack 404E", value: "286" },
  { label: "Autojack 505E", value: "509" },
  { label: "Autoleck Meridian Touch Pad", value: "16" },
  { label: "Autoleck Smart Key", value: "17" },
  { label: "Autolok 2000 Plus", value: "549" },
  { label: "Automatrics Limited Automatrics Mtrack", value: "2023" },
  { label: "Automatrics Limited Automatrics MTrack Active", value: "2071" },
  { label: "Automatrics Limited Automatrics MTrack Proactive", value: "2072" },
  { label: "Automotive Electronics Codelock", value: "623" },
  { label: "Automotive Electronics Smartlock", value: "624" },
  { label: "Autostop Immobiliser", value: "299" },
  { label: "Autotxt Locate", value: "1416" },
  { label: "Autotxt RVCS6", value: "1522" },
  { label: "Autowatch 139Ti", value: "490" },
  { label: "Autowatch 160 RLi", value: "637" },
  { label: "Autowatch 161 RL", value: "645" },
  { label: "Autowatch 171 PPi", value: "641" },
  { label: "Autowatch 172 Ri", value: "643" },
  { label: "Autowatch 173 PPI", value: "551" },
  { label: "Autowatch 239 Ti", value: "642" },
  { label: "Autowatch 375", value: "1225" },
  { label: "Autowatch 457 Rli", value: "1326" },
  { label: "Autowatch 458 RL", value: "1329" },
  { label: "Autowatch 52Ji", value: "327" },
  { label: "Autowatch 695 RLC", value: "1397" },
  { label: "Autowatch 695 RLC CAN", value: "1398" },
  { label: "AutoWatch 69JI", value: "349" },
  { label: "AutoWatch 69JIP", value: "350" },
  { label: "AutoWatch API", value: "348" },
  { label: "Autowatch Ghost-II CANbus Immobiliser", value: "2430" },
  { label: "Autowatch Ltd 375 Clam", value: "1596" },
  { label: "Autowatch Ltd Autowatch Track & Trace 1b", value: "2188" },
  { label: "Autowatch Ltd Autowatch Track & Trace 5", value: "1556" },
  { label: "Autowatch Track & Trace 1", value: "1519" },
  { label: "Autowatch Track & Trace 2", value: "1520" },
  { label: "Autowatch Track & Trace 3", value: "1521" },
  { label: "Autronic Alcatraz 2001 SX", value: "114" },
  { label: "Autronic Alcatraz 2002 SX", value: "115" },
  { label: "Autronic Alcatraz 2003 SX", value: "116" },
  { label: "Avonwood Development Ltd Eureka Immobiliser 24/2007", value: "1020" },
  { label: "Avonwood Development Ltd Securicar Immobiliser 24/2007", value: "955" },
  { label: "Barrier Systems Limited Barrier Deadlock", value: "18" },
  { label: "Bentley GPS Tracking System Advanced", value: "1505" },
  { label: "Bentley Motors Ltd Bentley Secure VTS", value: "2384" },
  { label: "Bentley Motors Security System", value: "1012" },
  { label: "Bentley Motors Security System II", value: "1013" },
  { label: "Bentley Motors Security System III", value: "1014" },
  { label: "Bentley Motors Security System IV", value: "1015" },
  { label: "Bentley Motors Security System V", value: "1098" },
  { label: "Bentley Motors Security System VI", value: "1499" },
  { label: "Bentley Motors Security System VII", value: "2272" },
  { label: "Bike Trac Ltd Fleet Asset", value: "1491" },
  { label: "Bike Trac Ltd Trac-It", value: "1497" },
  { label: "BMW 3G.EWS", value: "414" },
  { label: "BMW 3G.EWS II", value: "717" },
  { label: "BMW 3T-E30", value: "411" },
  { label: "BMW 3T-EWS", value: "413" },
  { label: "BMW BM36D", value: "376" },
  { label: "BMW DWA MY99", value: "690" },
  { label: "BMW DWA/CAS", value: "1935" },
  { label: "BMW EWS 11 Transponder", value: "377" },
  { label: "BMW EWS II/DWA", value: "412" },
  { label: "BMW EWS III Transponder", value: "633" },
  { label: "BMW EWS III/DWA", value: "1303" },
  { label: "BMW Immobiliser Passive Setting", value: "106" },
  { label: "BMW MB Immobiliser", value: "273" },
  { label: "BMW Stolen Vehicle Tracking System", value: "1489" },
  { label: "BMW System 2T", value: "300" },
  { label: "BMW System 3G", value: "301" },
  { label: "BMW System 3T", value: "302" },
  { label: "BMW UK 3G", value: "1613" },
  { label: "BMW UK EWS IV/DWA", value: "1544" },
  { label: "BMW Vehicle Tracking Telematic System", value: "1455" },
  { label: "Bosch Bloctronic KWS", value: "255" },
  { label: "Bosch RF2000", value: "284" },
  { label: "BOX ctg5", value: "1277" },
  { label: "British Tracking Systems Predator", value: "1518" },
  { label: "BT Redcare auto-txt", value: "1137" },
  { label: "BT Redcare BT Trackit", value: "1221" },
  { label: "Bugs Universal Carbug", value: "1047" },
  { label: "Bullock T", value: "475" },
  { label: "CanTrack Global Ltd CanTrack Asset", value: "2351" },
  { label: "Carback-Kwik", value: "444" },
  { label: "Carbug Ltd Carbug", value: "1085" },
  { label: "Carflow Barrier Deadlock", value: "295" },
  { label: "Carflow Longarm", value: "19" },
  { label: "Carflow Shorty", value: "688" },
  { label: "Cartec Guardsman 2000", value: "20" },
  { label: "CEL P8T", value: "534" },
  { label: "CEL Protector 10", value: "21" },
  { label: "CEL Protector 10T", value: "415" },
  { label: "CEL Protector 15", value: "22" },
  { label: "CEL Protector 15T", value: "416" },
  { label: "CEL Protector 20", value: "23" },
  { label: "CEL TL6011", value: "552" },
  { label: "CEL Topline 2000", value: "118" },
  { label: "CEL Topline 2011", value: "256" },
  { label: "CEL Topline 3000", value: "119" },
  { label: "CEL Topline 3011", value: "250" },
  { label: "CEL Topline 3016", value: "120" },
  { label: "CEL Topline 4001", value: "468" },
  { label: "CEL Topline 4011", value: "303" },
  { label: "CEL Topline 5001", value: "467" },
  { label: "CEL Topline 5011", value: "304" },
  { label: "CEL Topline 5016", value: "305" },
  { label: "CEL Topline 6011", value: "942" },
  { label: "Centurion 3000", value: "672" },
  { label: "Centurion 3001", value: "718" },
  { label: "Centurion Carguard", value: "553" },
  { label: "Centurion Carsafe", value: "679" },
  { label: "Centurion Carsafe Plus", value: "743" },
  { label: "Centurion Carshield", value: "610" },
  { label: "Centurion Carshield Plus", value: "611" },
  { label: "Cesar Satelite", value: "1475" },
  { label: "CESAR System", value: "1421" },
  { label: "Chevrolet GM Sentinel", value: "1279" },
  { label: "Chevrolet Immobiliser", value: "1163" },
  { label: "Chevrolet Security System 1", value: "1160" },
  { label: "Chrysler CJ Genuine Approval Alarm System", value: "778" },
  { label: "Chrysler CJ Original Equipment Immobiliser", value: "813" },
  { label: "Chrysler CJ3", value: "1166" },
  { label: "Chrysler CJCOB 01", value: "1144" },
  { label: "Chrysler CJFBS2B", value: "1088" },
  { label: "Chrysler FGA Delphi 1", value: "2123" },
  { label: "Chrysler FGA Immobiliser", value: "2127" },
  { label: "Chrysler FGA Metasystem", value: "1936" },
  { label: "Chrysler Grand Cherokee (WJ) Security System", value: "739" },
  { label: "Chrysler Grand Cherokee Security System", value: "569" },
  { label: "Chrysler Immobiliser Security System", value: "704" },
  { label: "Chrysler Jeep Security System", value: "967" },
  { label: "Chrysler Jeep UK Alarm Security System", value: "691" },
  { label: "Chrysler Neon Alarm", value: "155" },
  { label: "Chrysler Neon Immobiliser", value: "288" },
  { label: "Chrysler Security System P.I", value: "855" },
  { label: "Chrysler Voyager Security System", value: "968" },
  { label: "Citroen 9471 AW", value: "976" },
  { label: "Citroen 9471 AY", value: "818" },
  { label: "Citroen 9471 C1", value: "819" },
  { label: "Citroen 9471 H5", value: "996" },
  { label: "Citroen 9471.K3", value: "1067" },
  { label: "Citroen Keypad Immobiliser", value: "417" },
  { label: "Citroen Keypad Immobiliser (Diesel)", value: "586" },
  { label: "Citroen OE Alarm MUX", value: "908" },
  { label: "Citroen OE Alarm MUX 2", value: "1338" },
  { label: "Citroen PSA OE Alarm MUX 3", value: "1425" },
  { label: "Citroen PSA sub-B Immobiliser", value: "1174" },
  { label: "Citroen Relay Transponder Immobiliser", value: "823" },
  { label: "Citroen S4 Immobiliser", value: "1928" },
  { label: "Citroen Transponder", value: "748" },
  { label: "Citroen Transponder Immobiliser", value: "625" },
  { label: "Citroen Transponder Immobiliser MUX", value: "835" },
  { label: "Citroen Vehicle CAT 2", value: "2122" },
  { label: "Clifford", value: "24" },
  { label: "Clifford 3 Point Autoimmobiliser", value: "626" },
  { label: "Clifford 330 X1", value: "1340" },
  { label: "Clifford 330 X1/ X2/ X3", value: "1598" },
  { label: "Clifford 330 X2", value: "1341" },
  { label: "Clifford 330 X3", value: "1342" },
  { label: "Clifford 3c Immobiliser", value: "418" },
  { label: "Clifford Advantage", value: "121" },
  { label: "Clifford Advantage ACG", value: "122" },
  { label: "Clifford AIM", value: "123" },
  { label: "Clifford Avant Guard 5.5", value: "1284" },
  { label: "Clifford Avant Guard II", value: "124" },
  { label: "Clifford Avital Maxx 1", value: "1327" },
  { label: "Clifford Avital Maxx 2", value: "997" },
  { label: "Clifford Avital Maxx 3", value: "779" },
  { label: "Clifford Concept 300", value: "736" },
  { label: "Clifford Concept 50", value: "274" },
  { label: "Clifford Concept 500", value: "774" },
  { label: "Clifford Concept 50x", value: "598" },
  { label: "Clifford Concept 600", value: "719" },
  { label: "Clifford Concept 650", value: "1101" },
  { label: "Clifford Concept 650 MKII", value: "1285" },
  { label: "Clifford Eagle", value: "125" },
  { label: "Clifford Eagle ACG", value: "126" },
  { label: "Clifford Intelliguard 950", value: "1102" },
  { label: "Clifford Matrix", value: "127" },
  { label: "Clifford Novell", value: "128" },
  { label: "Clifford Sabre", value: "129" },
  { label: "Clifford Virtual Key", value: "333" },
  { label: "Clifford Virtual Key II", value: "727" },
  { label: "Clifford Virtual Key III", value: "728" },
  { label: "CMT Linelock", value: "1005" },
  { label: "Cobra 0802T", value: "257" },
  { label: "Cobra 0803T", value: "258" },
  { label: "Cobra 0832", value: "458" },
  { label: "Cobra 0832 HF", value: "420" },
  { label: "Cobra 0832 SU", value: "419" },
  { label: "Cobra 0833", value: "459" },
  { label: "Cobra 0833 HF", value: "422" },
  { label: "Cobra 0833 SU", value: "421" },
  { label: "Cobra 2111", value: "1134" },
  { label: "Cobra 2112", value: "1187" },
  { label: "Cobra 213X (Cobra Trak 5)", value: "1346" },
  { label: "Cobra 3198", value: "998" },
  { label: "Cobra 3868", value: "2118" },
  { label: "Cobra 3868 (3865)", value: "1021" },
  { label: "Cobra 3998", value: "1039" },
  { label: "Cobra 4138", value: "1235" },
  { label: "Cobra 4198", value: "1237" },
  { label: "Cobra 4405", value: "1387" },
  { label: "Cobra 4425", value: "1384" },
  { label: "Cobra 4485", value: "1385" },
  { label: "Cobra 4615", value: "1565" },
  { label: "Cobra 4625", value: "2116" },
  { label: "Cobra 4908", value: "1239" },
  { label: "Cobra 5901", value: "25" },
  { label: "Cobra 6009", value: "130" },
  { label: "Cobra 6019", value: "26" },
  { label: "Cobra 6019 HF", value: "491" },
  { label: "Cobra 6019 mk2", value: "275" },
  { label: "Cobra 6402", value: "606" },
  { label: "Cobra 6421", value: "599" },
  { label: "Cobra 6422", value: "600" },
  { label: "Cobra 7518/15", value: "978" },
  { label: "Cobra 7908", value: "820" },
  { label: "Cobra 7908/18", value: "959" },
  { label: "Cobra 7915", value: "686" },
  { label: "Cobra 7928", value: "737" },
  { label: "Cobra 8165", value: "793" },
  { label: "Cobra 8166/8", value: "960" },
  { label: "Cobra 8186/8", value: "953" },
  { label: "Cobra 8510", value: "994" },
  { label: "Cobra 8808", value: "1153" },
  { label: "Cobra 886", value: "476" },
  { label: "Cobra 887", value: "469" },
  { label: "Cobra Automotive Technologies 4600", value: "1609" },
  { label: "Cobra Automotive Technologies Audi Cobra Trak Plus", value: "1808" },
  { label: "Cobra Automotive Technologies Cobra ADR", value: "1872" },
  { label: "Cobra Automotive Technologies Cobra Trak Plus", value: "1827" },
  { label: "Cobra Bentley GPS Tracking System - Secure", value: "1516" },
  { label: "Cobra Connex ICP10", value: "1084" },
  { label: "Cobra Diesel Guard", value: "554" },
  { label: "Cobra Diesel Guard - ECV", value: "555" },
  { label: "Cobra G198", value: "1383" },
  { label: "Cobra Nissan Tracking System Standard", value: "1517" },
  { label: "Cobra NU1", value: "27" },
  { label: "Cobra Trak ADR", value: "1272" },
  { label: "Cobra Trak First/First Mobile", value: "1465" },
  { label: "Cobra Trak Q/Cobra Trak Attivo", value: "1231" },
  { label: "Cobra UK Ltd 4625", value: "2036" },
  { label: "Cobra UK Ltd 4685", value: "1949" },
  { label: "Cobra UK Ltd 4698", value: "1961" },
  { label: "Cobra UK Ltd A4405 OES", value: "2294" },
  { label: "Cobra UK Ltd Aston Martin Tracking by VAUK Ltd", value: "2302" },
  { label: "Cobra UK Ltd Bentley GPS Tracking System Advanced", value: "2163" },
  { label: "Cobra UK Ltd CobraTrak 5", value: "1986" },
  { label: "Cobra UK Ltd CobraTrak 5 V2", value: "2197" },
  { label: "Cobra UK Ltd CobraTrak 5SC", value: "2200" },
  { label: "Cobra UK Ltd CobraTrak 6SC", value: "2228" },
  { label: "Cobra UK Ltd CobraTrak ADR", value: "2070" },
  { label: "Cobra UK Ltd NavTrak ADR10", value: "2220" },
  { label: "Cobra UK Ltd NavTrak ADR10 Secure", value: "2225" },
  { label: "Cobra UK Ltd SCD2231", value: "2298" },
  { label: "Cobra UK Navtrack ADR5", value: "1335" },
  { label: "CobraConnex", value: "1271" },
  { label: "CobraTrak First", value: "1433" },
  { label: "CobraTrak First Mobile", value: "1434" },
  { label: "Code Alarm ANES 75", value: "131" },
  { label: "Code Alarm Atlanta", value: "344" },
  { label: "Code Alarm Chicago", value: "423" },
  { label: "Code Alarm Detroit", value: "477" },
  { label: "Code Alarm Los Angeles", value: "478" },
  { label: "Code Alarm Miami", value: "615" },
  { label: "Code Alarm New York", value: "608" },
  { label: "Code Alarm Pro 1000", value: "132" },
  { label: "Code Alarm Pro 3000", value: "133" },
  { label: "Code Alarm Pro 5000", value: "134" },
  { label: "Code Alarm Pro 7000", value: "135" },
  { label: "Code Alarm Talisman 3", value: "556" },
  { label: "Commercial Electronics HA-101", value: "1171" },
  { label: "Commercial Electronics Hawk HA-203", value: "1176" },
  { label: "Conlog 100", value: "479" },
  { label: "Conlog 300", value: "505" },
  { label: "Conlog Eclipse", value: "378" },
  { label: "Conlog Elite", value: "356" },
  { label: "CP Films Optically Clear Llumar Film 175 Thick Single Ply", value: "1773" },
  { label: "CQR Security Ltd Longarm", value: "917" },
  { label: "CQR Security Ltd Shorty", value: "918" },
  { label: "Dacia Dacia Alarm", value: "2377" },
  { label: "Dacia Immobiliser", value: "2084" },
  { label: "Daihatsu DA 029", value: "379" },
  { label: "Daihatsu DA 030", value: "445" },
  { label: "Daihatsu Immobiliser", value: "1429" },
  { label: "Daimler Security System", value: "1232" },
  { label: "DanTrack DT100", value: "1026" },
  { label: "Data Block", value: "306" },
  { label: "Datatag ATV System", value: "1472" },
  { label: "Datatag Car System", value: "1469" },
  { label: "Datatag Caravan System", value: "1471" },
  { label: "Datatag ID Limited CESAR System", value: "2082" },
  { label: "Datatag Marine & Watercraft System", value: "1468" },
  { label: "Datatag Motorcycle System", value: "1466" },
  { label: "Datatag Scooter System", value: "1470" },
  { label: "Datatag Trailer System", value: "1467" },
  { label: "Defa Patriot 300X", value: "136" },
  { label: "Defa Patriot 500X", value: "137" },
  { label: "Defa Patriot 700X", value: "138" },
  { label: "Delta DA180A", value: "307" },
  { label: "Demon ES150", value: "28" },
  { label: "Demon ES154", value: "29" },
  { label: "Demon ES180", value: "102" },
  { label: "Demon WS190", value: "30" },
  { label: "Designtronic Ltd Apollo 2002", value: "957" },
  { label: "Designtronic Ltd Apollo 780", value: "956" },
  { label: "Digital", value: "31" },
  { label: "Digital CB697039", value: "143" },
  { label: "Digital CB69F7139/2", value: "142" },
  { label: "Digital CB797039", value: "144" },
  { label: "Digital CB797041", value: "145" },
  { label: "Digital CT 82", value: "139" },
  { label: "Digital CT 86", value: "140" },
  { label: "Digital CT 88", value: "141" },
  { label: "Digital V.S.S. BM 140T Vigilante", value: "308" },
  { label: "Directed Clifford Connect 5", value: "2358" },
  { label: "Directed Clifford Connect 6", value: "2361" },
  { label: "Disklok (UK) Disklok Small", value: "607" },
  { label: "Disklok UK Disklok", value: "2150" },
  { label: "Dodge CJ3", value: "1304" },
  { label: "Dodge CJCOB 01", value: "1161" },
  { label: "Doppler", value: "32" },
  { label: "Dynamco Cyclops Para plus", value: "146" },
  { label: "Dynamco Paralyser 604A/3", value: "147" },
  { label: "Eagle Eye Tracking Systems", value: "949" },
  { label: "EEC 24/2004", value: "839" },
  { label: "Enigma Skyline 999", value: "1492" },
  { label: "Enigma Skyline CAT 5", value: "1445" },
  { label: "Enigma Skyline CAT 5 Plus", value: "1446" },
  { label: "Enigma Skyline E-Lock 3", value: "1441" },
  { label: "Enterprise Interceptor", value: "309" },
  { label: "ETS Ltd M-Track", value: "1196" },
  { label: "Eureka 24/2004", value: "815" },
  { label: "EVO Automotive Solutions Ltd Microlocks", value: "1315" },
  { label: "Evo Automotive Solutions Ltd MK 10 Locking Wheel Device", value: "2162" },
  { label: "EVO Automotive Solutions Ltd Mk V Range", value: "1401" },
  { label: "EVO Automotive Solutions Ltd Mk V Range", value: "1215" },
  { label: "EVO Automotive Solutions Ltd Mk VII (A) Range", value: "1402" },
  { label: "EVO Automotive Solutions Ltd Mk VII (B) Range", value: "2066" },
  { label: "EVO Automotive Solutions Ltd Truck Locks", value: "1316" },
  { label: "Evo Automotive Solutions Ltd. MK 7 (C) Locking Wheel Device", value: "2407" },
  { label: "Evo Automotive Solutions Ltd. MK 9 Locking Wheel Device", value: "1717" },
  { label: "Eye Track GSM - GPS Tracking System", value: "1048" },
  { label: "Failproof (Mapleman) UK Ltd", value: "33" },
  { label: "Falcon Predator MK1", value: "1172" },
  { label: "Falcon Predator MK2", value: "1177" },
  { label: "Falcon Protector", value: "1086" },
  { label: "FBS Engineering Safeguard", value: "1097" },
  { label: "Ferrari Immobiliser", value: "224" },
  { label: "Fiat 500 50901734", value: "1350" },
  { label: "Fiat 918U", value: "480" },
  { label: "Fiat BCTS", value: "986" },
  { label: "Fiat Chrysler Automobiles Alarm/Immobiliser Security System", value: "2232" },
  { label: "Fiat Code 2 Immobiliser", value: "837" },
  { label: "Fiat Code Immobiliser", value: "922" },
  { label: "Fiat Code Transponder Immobiliser", value: "380" },
  { label: "Fiat M36TFA", value: "589" },
  { label: "Fiat M7 - M8", value: "1426" },
  { label: "Fiat M99 T2FA", value: "755" },
  { label: "Fiat M99TFA", value: "588" },
  { label: "Fiat/Alfa Alarm (OE) TRW SEP", value: "638" },
  { label: "Finning Caterpillar Machine Security System (MSS)", value: "1506" },
  { label: "Flashpoint FP775", value: "535" },
  { label: "Flashpoint FP776", value: "529" },
  { label: "Flashpoint FP776 V2", value: "692" },
  { label: "Fleet Director TM100", value: "1411" },
  { label: "Ford 100-200-300", value: "558" },
  { label: "Ford 100-300", value: "557" },
  { label: "Ford 10T & 20T", value: "472" },
  { label: "Ford 30", value: "331" },
  { label: "Ford 40", value: "310" },
  { label: "Ford 40T", value: "446" },
  { label: "Ford 951 Immobiliser", value: "34" },
  { label: "Ford 951 Vehicle Immobiliser (FD.1.2)", value: "424" },
  { label: "Ford Cat 1", value: "1030" },
  { label: "Ford Focus RS Security System", value: "1016" },
  { label: "Ford FoMoCo-B", value: "1382" },
  { label: "Ford FV1000", value: "1131" },
  { label: "Ford GT Cobra Security System", value: "1168" },
  { label: "Ford High Security Steering Wheel Lock", value: "287" },
  { label: "Ford Ka Immobiliser", value: "1377" },
  { label: "Ford Maverick Safeguard", value: "332" },
  { label: "Ford MFD1 by Metasystem", value: "1290" },
  { label: "Ford Safeguard (BS.II.1)", value: "628" },
  { label: "Ford Safeguard (FD.11.6)", value: "1287" },
  { label: "Ford Safeguard (FD.I.1)", value: "35" },
  { label: "Ford Safeguard (FD.I.3)", value: "536" },
  { label: "Ford Safeguard (FD.II.1)", value: "590" },
  { label: "Ford Safeguard (FD.II.2)", value: "591" },
  { label: "Ford Safeguard (FD.II.3)", value: "537" },
  { label: "Ford Safeguard (FD.II.4)", value: "538" },
  { label: "Ford Safeguard (FD.II.5)", value: "627" },
  { label: "Ford Safeguard (SM.II.2)", value: "604" },
  { label: "Ford Safeguard (TM.II.1)", value: "353" },
  { label: "Ford Security System", value: "1242" },
  { label: "Ford ST 200 Security System", value: "756" },
  { label: "Ford UK Ford Security System", value: "1610" },
  { label: "Ford UK Security System", value: "1661" },
  { label: "Ford Xvision Alarm Upgrade", value: "2143" },
  { label: "Foxguard F-4", value: "381" },
  { label: "Foxguard F1-11", value: "37" },
  { label: "Foxguard F18E", value: "148" },
  { label: "Foxguard F4 - M", value: "358" },
  { label: "Foxguard FG1", value: "357" },
  { label: "Foxguard FP - OP", value: "522" },
  { label: "Foxguard H2-M", value: "38" },
  { label: "Foxguard Jack Rabbit JR40", value: "149" },
  { label: "Foxguard Jack Rabbit JR50", value: "39" },
  { label: "Foxguard Jack Rabbit JR60", value: "40" },
  { label: "Foxguard JP2", value: "510" },
  { label: "Foxguard JP3", value: "511" },
  { label: "Foxguard JR45", value: "311" },
  { label: "Foxguard KT - 2", value: "359" },
  { label: "Foxguard T32", value: "150" },
  { label: "Foxguard T38 S2EP", value: "151" },
  { label: "Foxguard T44", value: "152" },
  { label: "Foxguard T44 S2E", value: "154" },
  { label: "Foxguard T44B", value: "153" },
  { label: "Foxguard V-MAX-1", value: "400" },
  { label: "Foxguard V-MAX-2", value: "399" },
  { label: "Gamma CH5", value: "512" },
  { label: "Gamma RC 51", value: "513" },
  { label: "Gamma SBR M", value: "41" },
  { label: "Gemel E 733", value: "792" },
  { label: "Gemel E247 T", value: "1205" },
  { label: "Gemel E251T", value: "1182" },
  { label: "Gemel E252T", value: "1296" },
  { label: "Gemel E541", value: "1093" },
  { label: "Gemel GR 470", value: "646" },
  { label: "Gemel GR 480", value: "639" },
  { label: "Gemel GR 99 T", value: "832" },
  { label: "Gemel GR420", value: "312" },
  { label: "Gemel Serpi Star Mk125", value: "225" },
  { label: "Gemini 1002", value: "226" },
  { label: "Gemini 1151", value: "227" },
  { label: "Gemini 1160", value: "228" },
  { label: "Gemini 3024", value: "229" },
  { label: "Gemini 4001", value: "156" },
  { label: "Gemini 4002", value: "157" },
  { label: "Gemini 4003", value: "158" },
  { label: "Gemini 4004", value: "230" },
  { label: "Gemini 4060", value: "231" },
  { label: "Gemini 5001", value: "159" },
  { label: "Gemini 5002", value: "160" },
  { label: "Gemini 5003", value: "161" },
  { label: "Gemini 5019", value: "232" },
  { label: "Gemini 5060", value: "233" },
  { label: "Gemini 5079T", value: "816" },
  { label: "Gemini 7350 C1", value: "812" },
  { label: "Gemini 7350 U", value: "821" },
  { label: "Gemini 7359 K1", value: "1103" },
  { label: "Gemini 7590", value: "1149" },
  { label: "Gemini 7590T", value: "1206" },
  { label: "Gemini 7621T", value: "1183" },
  { label: "Gemini 931T", value: "1554" },
  { label: "Gemini Aquila 2", value: "360" },
  { label: "Gemini Aquila 5160T", value: "42" },
  { label: "Gemini Falco 5049", value: "234" },
  { label: "Gemini GAT", value: "235" },
  { label: "Gemini GAT (GEANTI)", value: "43" },
  { label: "Gemini Meta M32", value: "328" },
  { label: "Gemini P20/1", value: "236" },
  { label: "Gemini RAP100", value: "237" },
  { label: "Gemini RAP200", value: "238" },
  { label: "Gemini RAP300", value: "239" },
  { label: "Gemini Security Direct UK Ltd GTrack", value: "2321" },
  { label: "General Motors Cadillac Seville Security System", value: "721" },
  { label: "General Motors Chevrolet Blazer Security System", value: "720" },
  { label: "General Motors Chevrolet Camaro Security System", value: "805" },
  { label: "General Motors Chevrolet Corvette Security System", value: "804" },
  { label: "General Motors Chevrolet Epica Immobiliser", value: "1339" },
  { label: "General Motors Chevrolet Immobiliser", value: "1150" },
  { label: "General Motors Chevrolet Security System 1", value: "1143" },
  { label: "General Motors GM passlock system Cadillac", value: "1310" },
  { label: "General Motors GM passlock system Vauxhall", value: "1328" },
  { label: "General Motors GM Security System", value: "1427" },
  { label: "General Motors Keyless Access with push button start system Cadillac", value: "1305" },
  { label: "General Motors Security System 05", value: "1200" },
  { label: "General Motors Security System BLS", value: "1233" },
  { label: "Global Asset MTrack", value: "1895" },
  { label: "Global Telematics - Orchid", value: "950" },
  { label: "Global Telemetrics Ltd Autowatch Track & Trace 5 DRS", value: "2280" },
  { label: "Global Telemetrics Ltd Autowatch Track & Trace 5 DRST", value: "2284" },
  { label: "Global Telemetrics Ltd Group 999 CAT 5", value: "1557" },
  { label: "Global Telemetrics Ltd Group 999 CAT6", value: "1831" },
  { label: "Global Telemetrics Ltd SmarTrack CAT5 DRST", value: "2233" },
  { label: "Global Telemetrics Ltd SmarTrack CAT5 PRO DRS", value: "2237" },
  { label: "Glopos Data Unit", value: "803" },
  { label: "Glopos Voice Unit", value: "802" },
  { label: "GM Daewoo UK DCL 2000", value: "836" },
  { label: "GM Daewoo UK Security System", value: "738" },
  { label: "GM Daewoo UK Security System 1", value: "1089" },
  { label: "GT Auto", value: "44" },
  { label: "GT Auto Alarm (UK) Ltd GT 108/142", value: "354" },
  { label: "GT Auto Alarm (UK) Ltd GT 136/142", value: "542" },
  { label: "GT Auto Alarm GT 908.2-1", value: "1292" },
  { label: "GT Auto Alarm GT 908.2-1A", value: "1297" },
  { label: "GT Auto Alarms GT 636/444", value: "1035" },
  { label: "GT Auto Alarms GT 69.2T", value: "334" },
  { label: "GT Auto Alarms GT 69.3T", value: "335" },
  { label: "GT Auto Alarms GT 908.1", value: "1286" },
  { label: "GT Auto Alarms GT16T", value: "276" },
  { label: "GT Auto Alarms GT16T-2", value: "447" },
  { label: "GT Auto Alarms GT869", value: "1092" },
  { label: "Guardian 2000", value: "45" },
  { label: "HAL-Locate HAL1000", value: "1365" },
  { label: "Halfords High Security Shield", value: "364" },
  { label: "Halfords High Security Steering Lock", value: "526" },
  { label: "Halfords High Security Wheel Bar", value: "582" },
  { label: "Hamilton & Palmer Black Box 2", value: "757" },
  { label: "Hamilton & Palmer Black Box 2 ->1", value: "1018" },
  { label: "Hamilton & Palmer Vantage Black Box", value: "382" },
  { label: "Hamilton And Palmer Matrix 3", value: "241" },
  { label: "Hamilton And Palmer Matrix II", value: "240" },
  { label: "Hamilton And Palmer Vantage ATS", value: "242" },
  { label: "Hannah HA420", value: "530" },
  { label: "Hannah HAE2T", value: "492" },
  { label: "Hawk Car Alarms HA-101", value: "1260" },
  { label: "Hawk Car Alarms Hawk HA-203", value: "1262" },
  { label: "Hella Protec 7000 Luxus", value: "163" },
  { label: "Hidden Technology Armada XC", value: "876" },
  { label: "Honda ACU 7898 Alarm", value: "1031" },
  { label: "Honda Cat 1 T2", value: "758" },
  { label: "Honda Cat1", value: "568" },
  { label: "Honda EI Accord Alarm", value: "673" },
  { label: "Honda HAS2-U Alarm", value: "1351" },
  { label: "Honda HIS 5", value: "864" },
  { label: "Honda HIS-6 Immobiliser", value: "1368" },
  { label: "Honda HIS4", value: "660" },
  { label: "Honda HLI 502", value: "493" },
  { label: "Honda Pro-line 2", value: "259" },
  { label: "Honda Pro-line 4", value: "260" },
  { label: "Hornet Maxx 1", value: "1309" },
  { label: "Hornet Maxx 221", value: "1293" },
  { label: "HRH Carguard", value: "494" },
  { label: "Hyundai Approved 2-1 Alarm", value: "1204" },
  { label: "Hyundai OE Alarm/Immobiliser", value: "1058" },
  { label: "Hyundai OE Immobiliser", value: "1064" },
  { label: "Hyundai Smartra 3 Immobiliser System", value: "1311" },
  { label: "Hyundai Smatra 3 Immobiliser System", value: "1389" },
  { label: "Hyundai Steering Wheel Lock", value: "481" },
  { label: "i-mob Tracking Device", value: "1124" },
  { label: "i-mob TT1", value: "1266" },
  { label: "i-mob TT2", value: "1267" },
  { label: "Immobiliser Compact Plus 2", value: "427" },
  { label: "Immobiliser Compact Plus 3", value: "426" },
  { label: "Immobiliser Group Compac", value: "104" },
  { label: "Immobiliser Group Immobiliser", value: "46" },
  { label: "Immobiliser Group Immobiliser Executive", value: "47" },
  { label: "Immobiliser Group Powerpulse", value: "105" },
  { label: "Infiniti NATS Alarm", value: "1437" },
  { label: "Infiniti Tracking System", value: "1435" },
  { label: "Ins Traning & Marketing Ultimate MK4", value: "48" },
  { label: "Ins Traning & Marketing Ultimate MK5", value: "49" },
  { label: "Insure my box Insure my box One", value: "2194" },
  { label: "Insure my Box One", value: "2326" },
  { label: "Iris Automotive Ltd IRIS 600 SVT", value: "1222" },
  { label: "Isuzu", value: "1094" },
  { label: "Isuzu DMAXIM2012 - 0001", value: "1930" },
  { label: "ITM EP10", value: "729" },
  { label: "Iveco Immobiliser", value: "1041" },
  { label: "J R Alarms MA-01", value: "164" },
  { label: "J R Alarms MA-03", value: "165" },
  { label: "J R Alarms MA-04", value: "166" },
  { label: "J R Alarms MB-01", value: "167" },
  { label: "J R Alarms MB-03", value: "168" },
  { label: "J R Alarms MB-04", value: "169" },
  { label: "J R Alarms MC-01", value: "170" },
  { label: "J R Alarms MC-03", value: "171" },
  { label: "J R Alarms MC-04", value: "172" },
  { label: "J R Alarms MD-03", value: "173" },
  { label: "J R Alarms MD-04", value: "174" },
  { label: "Jaguar ERC", value: "50" },
  { label: "Jaguar High Security Steering Wheel Lock", value: "425" },
  { label: "Jaguar InControl Secure", value: "2231" },
  { label: "Jaguar JLR Secure Tracker Pro", value: "2410" },
  { label: "Jaguar Land Rover JLR OE Security System", value: "2159" },
  { label: "Jaguar S-Type Security System", value: "722" },
  { label: "Jaguar Security System", value: "336" },
  { label: "Jaguar Security System II", value: "531" },
  { label: "Jaguar Security System III", value: "618" },
  { label: "Jaguar Watch", value: "1251" },
  { label: "Jaguar X Type Security System", value: "880" },
  { label: "JaguarWatch TQA", value: "1268" },
  { label: "Jeep CJ Original Equipment Immobiliser", value: "814" },
  { label: "Jeep CJ3", value: "1306" },
  { label: "Jeep TC1", value: "495" },
  { label: "Karkraft Sonar 150", value: "661" },
  { label: "Karma Innovations Invisible Immobiliser", value: "930" },
  { label: "Karma Innovations Ltd Invisishield", value: "1178" },
  { label: "Karma Innovations Ltd RAC Invisible Immobiliser K2002D", value: "1107" },
  { label: "KFT S.p.A Mk 01 Family", value: "1083" },
  { label: "Kia Alarm System", value: "1253" },
  { label: "Kia Immobiliser System", value: "1254" },
  { label: "Kia Motors Advanced Anti Theft System", value: "1547" },
  { label: "Kia Smartra 3 & Alarm", value: "1280" },
  { label: "Kia Smartra 3 Immobiliser System", value: "1288" },
  { label: "Kia Sportage RKE Immobiliser", value: "482" },
  { label: "Killjoy Gold", value: "496" },
  { label: "Kiltech Ford Transit Immobiliser", value: "617" },
  { label: "Knightwatch 56 RLi", value: "175" },
  { label: "Kosran Diesel Guard ECV", value: "559" },
  { label: "Kosran Diesel Immobiliser ECV", value: "560" },
  { label: "Kwik-Sure Cat 1 Basic", value: "441" },
  { label: "Kwik-Sure Cat 1 Deluxe", value: "442" },
  { label: "Kwik-Sure Cat 2", value: "443" },
  { label: "Lamborghini GPS/GSM Tracking System", value: "1356" },
  { label: "Lamborghini Tracking by Cobra", value: "1945" },
  { label: "Lamborghini VTS", value: "2385" },
  { label: "Land Rover Defender Immobiliser", value: "1580" },
  { label: "Land Rover Discovery Series 2", value: "865" },
  { label: "Land Rover Freelander Security System", value: "629" },
  { label: "Land Rover InControl Secure", value: "2230" },
  { label: "Land Rover LRSS", value: "337" },
  { label: "Land Rover LRSS O.E", value: "561" },
  { label: "Land Rover LRSS Plus", value: "384" },
  { label: "Land Rover New Range Rover Security System", value: "383" },
  { label: "Land Rover Security System", value: "969" },
  { label: "Land Rover T5 Security System", value: "1145" },
  { label: "Land Rover Watch", value: "1252" },
  { label: "Land Rover Watch TQA", value: "1269" },
  { label: "Laserline (S.p.A) 213", value: "1236" },
  { label: "Laserline (S.p.A) 290 i2", value: "870" },
  { label: "Laserline (S.p.A) 291 I", value: "971" },
  { label: "Laserline (S.p.A) 291 i2", value: "886" },
  { label: "Laserline (S.p.A) 921 K", value: "868" },
  { label: "Laserline (S.p.A) 921 T", value: "867" },
  { label: "Laserline (S.p.A) 996 V3", value: "860" },
  { label: "Laserline (S.p.A.) 212", value: "1238" },
  { label: "Laserline (S.p.A.) 270 UK", value: "999" },
  { label: "Laserline (S.p.A.) 278", value: "1240" },
  { label: "Laserline (S.p.A.) 670", value: "1352" },
  { label: "Laserline 101 Autojack", value: "51" },
  { label: "Laserline 290i", value: "745" },
  { label: "Laserline 905", value: "176" },
  { label: "Laserline 921", value: "338" },
  { label: "Laserline 921 V2", value: "705" },
  { label: "Laserline 931", value: "339" },
  { label: "Laserline 944 Immobiliser", value: "52" },
  { label: "Laserline 992T", value: "53" },
  { label: "Laserline 993", value: "523" },
  { label: "Laserline 993 V2", value: "712" },
  { label: "Laserline 994", value: "261" },
  { label: "Laserline 995", value: "262" },
  { label: "Laserline 995T", value: "313" },
  { label: "Laserline 996", value: "251" },
  { label: "Laserline 996 V2", value: "694" },
  { label: "Laserline S.p.A 691T", value: "1988" },
  { label: "Laserline Stop Diesel", value: "612" },
  { label: "Laserline Stopdiesel", value: "905" },
  { label: "Laserline TecTus", value: "361" },
  { label: "Lasertrax/NCG", value: "1136" },
  { label: "LDV Ltd Maxus Immobiliser", value: "1227" },
  { label: "LDV Ltd Van Secure", value: "1095" },
  { label: "LDV Ltd Vanshield BD 111", value: "897" },
  { label: "LDV Ltd Vanshield BD 112", value: "899" },
  { label: "LDV Ltd Vanshield BD121", value: "902" },
  { label: "Linwood Electronic Tornada TRFX", value: "54" },
  { label: "Llumar Window Film 175u", value: "1400" },
  { label: "Lock 4 Vans Defender", value: "1417" },
  { label: "Lock 4 Vans T Series Deadlock", value: "1361" },
  { label: "Lock 4 Vans T Series Slamlock", value: "1360" },
  { label: "Lotus Evora Security System", value: "1553" },
  { label: "Lotus Security System I", value: "1162" },
  { label: "Lotus Security System II", value: "1320" },
  { label: "Lotus Security System III", value: "1321" },
  { label: "Mahindra Blue Signature By Mahindra", value: "1940" },
  { label: "Mahindra Immobiliser System", value: "1938" },
  { label: "Masternaut UK Ltd Masternaut MCU2", value: "1697" },
  { label: "Matrix Telematics Ltd Matrix 5", value: "1135" },
  { label: "Maxpower MP4", value: "871" },
  { label: "Maxpower MP5", value: "861" },
  { label: "Maystar S20-20", value: "177" },
  { label: "Maystar S20-60", value: "178" },
  { label: "Maystar S400", value: "277" },
  { label: "Maystar SC1", value: "723" },
  { label: "Maystar SC2", value: "730" },
  { label: "Maystar Svi4", value: "278" },
  { label: "Mazda Motors UK EWS II Transponder", value: "1577" },
  { label: "Mazda MVSS 030", value: "401" },
  { label: "Mazda MVSS 1000", value: "662" },
  { label: "Mazda MVSS 115", value: "55" },
  { label: "Mazda MVSS 2000", value: "848" },
  { label: "Mazda MVSS 2000 A", value: "847" },
  { label: "Mazda MVSS 3000", value: "1036" },
  { label: "Mazda MVSS 3000A", value: "987" },
  { label: "Mazda MVSS 4000", value: "1307" },
  { label: "Mazda MVSS 4000A", value: "1432" },
  { label: "Mazda MVSS001", value: "363" },
  { label: "Mazda MVSS020", value: "365" },
  { label: "Mazda MVSS300", value: "362" },
  { label: "Mazda MVSS500", value: "451" },
  { label: "McGard Aftermarket USH - 1 2", value: "1317" },
  { label: "McGard UHS-1", value: "1217" },
  { label: "McGard UHS-1 Locking Wheel Device", value: "1719" },
  { label: "McGard UHS-10", value: "1430" },
  { label: "McGard UHS-11", value: "1431" },
  { label: "McGard UHS-12 Locking Wheel Device", value: "1727" },
  { label: "McGard UHS-13 Locking Wheel Device", value: "1726" },
  { label: "McGard UHS-14", value: "1564" },
  { label: "McGard UHS-17 Locking Wheel Device", value: "2310" },
  { label: "McGard UHS-2", value: "1218" },
  { label: "McGard UHS-2 Locking Wheel Device", value: "1718" },
  { label: "McGard UHS-3", value: "1216" },
  { label: "McGard UHS-3 Locking Wheel Device", value: "1721" },
  { label: "McGard UHS-7", value: "1219" },
  { label: "McGard UHS-7 Locking Wheel Device", value: "1720" },
  { label: "McLaren Vehicle Tracking", value: "1512" },
  { label: "Mendix The 'Big-un' 2", value: "1078" },
  { label: "Mendix The 'Big-un' 3", value: "1079" },
  { label: "Mendix The 'Big-un' 4", value: "1080" },
  { label: "Mendix The Big-Un", value: "826" },
  { label: "Mercedes Benz 5000", value: "263" },
  { label: "Mercedes Benz MBAAS", value: "759" },
  { label: "Mercedes Benz MBRSS", value: "788" },
  { label: "Mercedes Benz MBSS", value: "428" },
  { label: "Mercedes Benz MBSS 2a", value: "570" },
  { label: "Mercedes Benz MBSS 2b", value: "674" },
  { label: "Mercedes Benz MBSS 3", value: "619" },
  { label: "Mercedes Benz MBSS 3.1", value: "1381" },
  { label: "Mercedes Benz MBSS 4", value: "1941" },
  { label: "Mercedes Benz MBV5", value: "1942" },
  { label: "Mercedes Benz MBVT 1", value: "951" },
  { label: "Mercedes Immobiliser MBV1", value: "776" },
  { label: "Mercedes MBV04 (L1)", value: "1165" },
  { label: "Mercedes MBV04 (L2)", value: "1114" },
  { label: "Meta Aquila M99/36T", value: "543" },
  { label: "Meta Aquilla (Microwave)", value: "676" },
  { label: "Meta Aquilla M99/36T2", value: "675" },
  { label: "Meta GPA800", value: "1096" },
  { label: "Meta M36 VV1", value: "840" },
  { label: "Meta M36T", value: "430" },
  { label: "Meta M750T", value: "933" },
  { label: "Meta M99 T2", value: "822" },
  { label: "Meta M99-36T", value: "429" },
  { label: "Meta M999T", value: "914" },
  { label: "Meta M99T (Microwave)", value: "687" },
  { label: "Meta M99T (Private Car Only)", value: "943" },
  { label: "Meta Meta Max", value: "497" },
  { label: "Meta Recovery System (Mrs)", value: "1486" },
  { label: "Meta System S.p.A Defcom Trak", value: "2285" },
  { label: "Meta System S.p.A HPAT 5.0", value: "2243" },
  { label: "Meta System S.p.A Meta Trak 5", value: "2245" },
  { label: "Meta System S.p.A Meta Trak 6", value: "2248" },
  { label: "Meta System S.p.A TVM110", value: "2252" },
  { label: "Metasystem GPA801", value: "1274" },
  { label: "MetaSystem UK Ltd HPA 1", value: "1546" },
  { label: "MetaSystem UK Ltd HPBT/HPBT CAN", value: "1671" },
  { label: "MetaSystem UK Ltd Subaru Sat", value: "1357" },
  { label: "Metasystems HPBT CAN", value: "1298" },
  { label: "Metasystems HPBT/HPBT CAN", value: "1155" },
  { label: "Metasystems TVM", value: "1424" },
  { label: "Metasystems TVM CAT 5", value: "1256" },
  { label: "Metasystems TVM FS", value: "1410" },
  { label: "Metro Stoplock Pro", value: "498" },
  { label: "MG Motors UK MG 6 Security System", value: "1548" },
  { label: "MG Motors UK MG Immobiliser", value: "2085" },
  { label: "Micro Car Alarms M020", value: "180" },
  { label: "Micro Car Alarms M0311", value: "181" },
  { label: "Micro Car Alarms M0312", value: "182" },
  { label: "Micro Car Alarms M0501", value: "183" },
  { label: "Micro Car Alarms M0511", value: "184" },
  { label: "Micro Car Alarms M0521", value: "185" },
  { label: "Microscan AN 550", value: "887" },
  { label: "Microscan MI 500", value: "630" },
  { label: "Microscan MI 600", value: "681" },
  { label: "Mighty Dot", value: "1029" },
  { label: "Mileage Management Asset", value: "1412" },
  { label: "Mileage Management Live", value: "1413" },
  { label: "Mini DWA MY 99", value: "909" },
  { label: "Mini DWA/CAS", value: "2387" },
  { label: "Mini DWA/EWS 2-1", value: "1255" },
  { label: "Mini EWS 11 Transponder", value: "915" },
  { label: "Mini EWS 111/DWA", value: "1308" },
  { label: "MINI Trackstar", value: "1373" },
  { label: "Mitsubishi Bosch Encrypted Immobiliser", value: "731" },
  { label: "Mitsubishi Classic", value: "460" },
  { label: "Mitsubishi Diamond Collection Elite 4", value: "952" },
  { label: "Mitsubishi Diamond Collection Optimum HF3", value: "856" },
  { label: "Mitsubishi Diamond Collection Paramount", value: "973" },
  { label: "Mitsubishi Electric Encrypted Immobiliser", value: "732" },
  { label: "Mitsubishi Electric Immobiliser", value: "545" },
  { label: "Mitsubishi Elite 2", value: "366" },
  { label: "Mitsubishi Elite 3", value: "655" },
  { label: "Mitsubishi Elite TRAK 5", value: "1347" },
  { label: "Mitsubishi MG Security System", value: "499" },
  { label: "Mitsubishi Mitsubishi/Bosch Immobiliser", value: "562" },
  { label: "Mitsubishi MMC Immobiliser System", value: "461" },
  { label: "Mitsubishi Motors UK Diamond Collection Optimum HF4", value: "2408" },
  { label: "Mitsubishi Optimum Alarm", value: "453" },
  { label: "Mitsubishi Optimum HF2/Dual HF", value: "656" },
  { label: "Mitsubishi Paramount", value: "340" },
  { label: "Mitsubishi Prestige", value: "452" },
  { label: "Moss Security MS605", value: "56" },
  { label: "Moss Security MS708", value: "57" },
  { label: "Moss Security MS725", value: "58" },
  { label: "Moss Security MS730", value: "186" },
  { label: "Moss Security MS740A", value: "187" },
  { label: "Moss Security MS775", value: "188" },
  { label: "Moss Security MS785", value: "189" },
  { label: "Moving Intelligence Ltd i Track Secure 5", value: "2412" },
  { label: "Moving Intelligence Ltd iTrack", value: "2413" },
  { label: "Moving Intelligence Ltd iTrack Ultimate", value: "2414" },
  { label: "Moving Intelligence Ltd Mi01 Sentry", value: "2415" },
  { label: "Moving Intelligence Ltd Mi50", value: "2416" },
  { label: "Moving Intelligence Ltd Mi50 S5", value: "2417" },
  { label: "Moving Intelligence Ltd Mi50 Ultimate", value: "2418" },
  { label: "Moving Intelligence Ltd MiTrack", value: "2419" },
  { label: "Moving Intelligence Ltd Phantom Pro iii", value: "2420" },
  { label: "Moving Intelligence Ltd Sentinel", value: "2421" },
  { label: "Moving Intelligence Ltd Sentinel Five", value: "2424" },
  { label: "Moving Intelligence Ltd Sentinel Ultimate", value: "2427" },
  { label: "MSI 7007", value: "514" },
  { label: "Mtrack", value: "1358" },
  { label: "Mtrack Active", value: "1461" },
  { label: "Mtrack Boatwatch", value: "1463" },
  { label: "Mtrack Pro", value: "1359" },
  { label: "MtrackPro Active", value: "1462" },
  { label: "MtrackPro Boatwatch", value: "1464" },
  { label: "Mul-T-Lock 22", value: "243" },
  { label: "Mul-T-Lock 45", value: "500" },
  { label: "Navman Wireless Qube 3", value: "1523" },
  { label: "Navman Wireless Qube 3", value: "1820" },
  { label: "Navtrack ADR 3", value: "1257" },
  { label: "NavTrack Ltd NavTrack ADR", value: "1559" },
  { label: "NavTrak 4000", value: "1027" },
  { label: "NavTrak 4000 Proactive", value: "1009" },
  { label: "NavTrak Proactive", value: "877" },
  { label: "NavTrak Proactive ADR", value: "1028" },
  { label: "NavTrak Standard", value: "796" },
  { label: "Neo-Trac One", value: "1354" },
  { label: "Nissan GB NATS V2.0 Plus B", value: "2067" },
  { label: "Nissan NATS", value: "1929" },
  { label: "Nissan NATS 2000 Alarm", value: "761" },
  { label: "Nissan NATS 2000 Alarm 02 Model Year", value: "970" },
  { label: "Nissan NATS Alarm", value: "2140" },
  { label: "Nissan NATS II V Spec", value: "780" },
  { label: "Nissan NATS V1.0", value: "244" },
  { label: "Nissan NATS V1.0 Plus", value: "252" },
  { label: "Nissan NATS V2.0", value: "314" },
  { label: "Nissan NATS V2.0 (Diesel)", value: "483" },
  { label: "Nissan NATS V2.0 Plus A", value: "315" },
  { label: "Nissan NATS V3.0", value: "515" },
  { label: "Nissan NATS V3.0 Plus", value: "532" },
  { label: "Nissan NATS V4.0", value: "663" },
  { label: "Nissan NATS V4.0 Plus", value: "806" },
  { label: "Nissan NATS V5.0", value: "765" },
  { label: "Nissan NATS V5.0 Plus", value: "760" },
  { label: "Nissan NATS V6.0", value: "974" },
  { label: "Nissan NATS v7.0", value: "1202" },
  { label: "Nissan NATS V8.0", value: "1185" },
  { label: "Nissan Navara Alarm", value: "1184" },
  { label: "Nissan Premier Alarm 7887", value: "1169" },
  { label: "Nissan Tracking System", value: "1348" },
  { label: "Nissan V2/P/T/S", value: "981" },
  { label: "Omitec Ltd Omi Quest", value: "1249" },
  { label: "Orange PCS Ltd Orange Fleet Link", value: "2065" },
  { label: "Panasonic Electric Works Alarm System", value: "1263" },
  { label: "Panther Defender", value: "501" },
  { label: "Parksafe Immobilock Immobiliser", value: "462" },
  { label: "PBM TVI IMM5", value: "289" },
  { label: "Perodua Immobiliser", value: "1312" },
  { label: "Peugeot 200 Series", value: "351" },
  { label: "Peugeot 9671.6X", value: "1059" },
  { label: "Peugeot 9671.ZK", value: "931" },
  { label: "Peugeot 9671.ZK & 9671.8H", value: "881" },
  { label: "Peugeot 9671.ZL", value: "932" },
  { label: "Peugeot 9671.ZL & 9671.8H", value: "882" },
  { label: "Peugeot 96721T", value: "279" },
  { label: "Peugeot 96731 V", value: "766" },
  { label: "Peugeot 96731C", value: "601" },
  { label: "Peugeot 96731E", value: "602" },
  { label: "Peugeot 96731Q", value: "695" },
  { label: "Peugeot 96731R", value: "713" },
  { label: "Peugeot Boxer Transponder Immobiliser", value: "1071" },
  { label: "Peugeot Coverlock", value: "649" },
  { label: "Peugeot Immobiliser EV", value: "2115" },
  { label: "Peugeot Keypad Diesel Immobiliser", value: "605" },
  { label: "Peugeot Keypad Immobiliser", value: "431" },
  { label: "Peugeot MUX 2 Immobiliser", value: "1369" },
  { label: "Peugeot MUX Transponder", value: "1073" },
  { label: "Peugeot MUX Transponder Immobiliser", value: "1065" },
  { label: "Peugeot OE Alarm MUX", value: "1060" },
  { label: "Peugeot OE Alarm MUX 2", value: "1366" },
  { label: "Peugeot OEM Alarm MUX", value: "1264" },
  { label: "Peugeot PSA OE Alarm MUX 3", value: "1428" },
  { label: "Peugeot PSA sub-B Alarm", value: "1181" },
  { label: "Peugeot PSA sub-B Immobiliser", value: "1175" },
  { label: "Peugeot PSA Transponder Immobiliser", value: "954" },
  { label: "Peugeot S4 Immobiliser", value: "1931" },
  { label: "Peugeot Transponder", value: "1072" },
  { label: "Peugeot Transponder Immobiliser", value: "664" },
  { label: "Peugeot Transponder Immobiliser (C/R)", value: "682" },
  { label: "Phantom Ltd Insight", value: "2096" },
  { label: "Phantom Ltd iTrack", value: "2101" },
  { label: "Phantom Ltd ITRACK2 OBD", value: "2207" },
  { label: "Phantom Ltd iTrack2 OBD", value: "2336" },
  { label: "Phantom Ltd Phantom", value: "2111" },
  { label: "Phantom Ltd Phantom  - Cars and Vans", value: "1082" },
  { label: "Phantom Ltd Pro iii", value: "2091" },
  { label: "Phantom Ltd VanGuard", value: "2289" },
  { label: "Philips PH200", value: "59" },
  { label: "Philips PH550T", value: "316" },
  { label: "Piranha A101", value: "317" },
  { label: "Piranha A101FE", value: "403" },
  { label: "Piranha A102", value: "341" },
  { label: "Piranha A103", value: "539" },
  { label: "Piranha A201E", value: "402" },
  { label: "Piranha M14/55P", value: "404" },
  { label: "Piranha M94/55 RND", value: "190" },
  { label: "Piranha SC15", value: "60" },
  { label: "Piranha SC16", value: "61" },
  { label: "Piranha SC31", value: "408" },
  { label: "Piranha SC55", value: "62" },
  { label: "Piranha SC85", value: "63" },
  { label: "Piranha SCM18 RND", value: "191" },
  { label: "Piranha SCO5", value: "407" },
  { label: "Piranha SCO5P", value: "406" },
  { label: "Piranha SCO7P", value: "405" },
  { label: "Piranha THM 95/55", value: "342" },
  { label: "Piranha THM 96/66", value: "563" },
  { label: "Piranha THM 97/77", value: "620" },
  { label: "Piranha THM A400", value: "706" },
  { label: "Piranha THM A800", value: "724" },
  { label: "Porsche 9X1 MY12 Security System", value: "2128" },
  { label: "Porsche Anti Theft System", value: "64" },
  { label: "Porsche Boxster 05 Security System", value: "1146" },
  { label: "Porsche Boxster Security System", value: "571" },
  { label: "Porsche Carrera 05 Security System", value: "1142" },
  { label: "Porsche Carrera 98 Security System", value: "621" },
  { label: "Porsche Carrera GT Security System", value: "1090" },
  { label: "Porsche Cayenne Security System", value: "1061" },
  { label: "Porsche Cayenne Security System MY11", value: "1508" },
  { label: "Porsche Cayman Security System", value: "1201" },
  { label: "Porsche GB 9X1 MY12 Security System", value: "2292" },
  { label: "Porsche Immobiliser", value: "254" },
  { label: "Porsche Immoboliser P2", value: "1477" },
  { label: "Porsche PA 1000", value: "385" },
  { label: "Porsche PA 2000", value: "762" },
  { label: "Porsche PA 2000 Upgrade", value: "767" },
  { label: "Porsche Panamera Security System", value: "1507" },
  { label: "Porsche Vehicle Tracking System", value: "1230" },
  { label: "Porsche VTS", value: "1349" },
  { label: "Porsche VTS 2", value: "1511" },
  { label: "Portman AM 125M", value: "1179" },
  { label: "Portman AM 209M", value: "1164" },
  { label: "Portman AM-139", value: "1173" },
  { label: "Precision Electronics Remote Usound MK2 Mphonic Noise Discriminator", value: "318" },
  { label: "Predator 620", value: "454" },
  { label: "Proton GX1 Security System", value: "993" },
  { label: "Proton SV1", value: "1203" },
  { label: "PSA Locking Wheel Bolts", value: "1220" },
  { label: "Quinton Hazell Krooklok", value: "845" },
  { label: "Quorum Immobiliser", value: "319" },
  { label: "R J L Enterprises Knightlock", value: "829" },
  { label: "RAC Trackstar", value: "785" },
  { label: "RAC Trackstar Guardian", value: "786" },
  { label: "RAC Trackstar II - Cars", value: "1055" },
  { label: "RAC Trackstar Plus", value: "1197" },
  { label: "RAC Trackstar Protector", value: "1198" },
  { label: "RAC Trackstar Protector Plus", value: "1199" },
  { label: "Radiomobile RMN 196", value: "484" },
  { label: "RDM AT101-15", value: "1191" },
  { label: "RDM AT101-1R", value: "1192" },
  { label: "RDM AT101-25", value: "1193" },
  { label: "RDM AT101-2R", value: "1194" },
  { label: "RDM Auto-TXT", value: "1190" },
  { label: "Recall Remote (after theft) Immobilisation - 202D", value: "1046" },
  { label: "RedWeb Technologies Ltd RX10", value: "1708" },
  { label: "Renault 6019", value: "280" },
  { label: "Renault Compact 7895/C", value: "883" },
  { label: "Renault Compact Alarm", value: "697" },
  { label: "Renault Compact Alarm Cat 1", value: "789" },
  { label: "Renault Gold Shield", value: "386" },
  { label: "Renault Goldshield II", value: "689" },
  { label: "Renault Immobiliser System", value: "2129" },
  { label: "Renault New Premier Alarm 7877", value: "1322" },
  { label: "Renault Premier 7897 New Espace", value: "1032" },
  { label: "Renault Premier Alarm (Espace)", value: "572" },
  { label: "Renault Premier Alarm (Private Car Only)", value: "945" },
  { label: "Renault Premier Alarm 144 (Espace)", value: "807" },
  { label: "Renault Premier Alarm 7887", value: "1170" },
  { label: "Renault Premier Alarm 7897", value: "1062" },
  { label: "Renault Premier Alarm 8268", value: "808" },
  { label: "Renault Premier Alarm 8268/B", value: "857" },
  { label: "Renault Silver Shield (V2Pi)", value: "564" },
  { label: "Renault Silver Shield (X64)", value: "486" },
  { label: "Renault Silver Shield V1P", value: "387" },
  { label: "Renault Silver Shield V2P", value: "485" },
  { label: "Renault Silver Shield V2P/R/T", value: "707" },
  { label: "Renault Silver Shield V2P/T/S", value: "665" },
  { label: "Renault Trafic", value: "1040" },
  { label: "Renault UK Premier Alarm 16", value: "2362" },
  { label: "Renault UK Silver Shield (V2Pi)", value: "1663" },
  { label: "Renault UK VIP", value: "1664" },
  { label: "Renault V2/P/T/S", value: "650" },
  { label: "Renault V3", value: "866" },
  { label: "Renault V3/S", value: "1066" },
  { label: "Renault V3/V", value: "975" },
  { label: "Repeller", value: "1270" },
  { label: "Retainagroup Ltd Chemical Etching", value: "1394" },
  { label: "Retainagroup Ltd International Security Register", value: "1759" },
  { label: "Retainagroup Ltd ISR Marking System for Cars", value: "1766" },
  { label: "Retainagroup Ltd ISR Marking System for Cars", value: "1542" },
  { label: "Retainagroup Ltd ISR Marking System for Commercial Vehicles", value: "1543" },
  { label: "Retainagroup Ltd Laser Etching", value: "1393" },
  { label: "Retainagroup Ltd Secure Database", value: "1396" },
  { label: "Retainagroup Ltd Security Labels", value: "1395" },
  { label: "Retainagroup Ltd Self Etch Label", value: "1752" },
  { label: "Retainagroup Ltd Ultra Destruct Label with UV Fluid", value: "1419" },
  { label: "Richard Lloyd Enterprises Knightlock", value: "671" },
  { label: "Rolls Royce Security System", value: "573" },
  { label: "Rolls Royce Security System II", value: "657" },
  { label: "Rolls Royce Security System III", value: "698" },
  { label: "Rolls Royce Stolen Vehicle Tracking", value: "1436" },
  { label: "Rolls-Royce Motor Cars Limited Phantom RR11 VTS", value: "2409" },
  {
    label: "Rolls-Royce Motor Cars Limited Rolls-Royce Stolen Vehicle Tracking System",
    value: "2374",
  },
  { label: "Rolls-Royce Phantom CAT 5 Security by Cobra", value: "1570" },
  { label: "Roma Components Ltd Steelheart", value: "846" },
  { label: "Ross Autotronics Ltd LEO", value: "770" },
  { label: "Ross Autotronics Ltd Strikeback LEO", value: "771" },
  { label: "Ross Meta M36T", value: "388" },
  { label: "Rover EWS 3", value: "733" },
  { label: "Rover Immobiliser (small/med car)", value: "613" },
  { label: "Rover MG - R Security System 1", value: "1033" },
  { label: "Rover MG - R Security System 2", value: "1037" },
  { label: "Rover MG ZT/ZT-T 260 Security System", value: "1099" },
  { label: "Rover SCU", value: "1100" },
  { label: "RVJM AS2T", value: "432" },
  { label: "RVJM AS3/T", value: "264" },
  { label: "Saab Anti Theft Alarm/ATA3", value: "609" },
  { label: "Saab Anti Theft Alarm/ATA4", value: "988" },
  { label: "Saab Anti-Theft Alarm", value: "320" },
  { label: "Saab ATA/VSS", value: "516" },
  { label: "Saab SC6019", value: "281" },
  { label: "Saab Security System", value: "1487" },
  { label: "SAIC Motors MG Immobiliser", value: "2393" },
  { label: "Sakura 4Tress", value: "1130" },
  { label: "Sanji Talisman 3", value: "290" },
  { label: "Saxon Industries Ltd Stoplock Pro", value: "2287" },
  { label: "Saxon Industries Ltd Stoplock Professional Elite", value: "2276" },
  { label: "Saxon Industries Stoplock Ultima", value: "896" },
  { label: "Saxon Industries Stoplock X-Cel", value: "1156" },
  { label: "SCC Hornet 707T", value: "192" },
  { label: "SCC Marketing Ltd XVTA21", value: "2041" },
  { label: "SCC Marketing Xvision XVTA21", value: "2120" },
  { label: "SCC Viper 400", value: "193" },
  { label: "SCC Viper 800", value: "194" },
  { label: "Scorpio SA40", value: "1330" },
  { label: "Scorpion 1000", value: "65" },
  { label: "Scorpion 1002 T2", value: "634" },
  { label: "Scorpion 1002T", value: "517" },
  { label: "Scorpion 1003 T2", value: "635" },
  { label: "Scorpion 1003T", value: "518" },
  { label: "Scorpion 1012 T2", value: "746" },
  { label: "Scorpion 1014 T2", value: "740" },
  { label: "Scorpion 1015 T2", value: "843" },
  { label: "Scorpion 1015T", value: "524" },
  { label: "Scorpion 1018 T2", value: "506" },
  { label: "Scorpion 1023", value: "652" },
  { label: "Scorpion 1215 T2", value: "735" },
  { label: "Scorpion 1218 T2", value: "725" },
  { label: "Scorpion 1254", value: "900" },
  { label: "Scorpion 1254 T", value: "995" },
  { label: "Scorpion 2000", value: "245" },
  { label: "Scorpion 3025T", value: "616" },
  { label: "Scorpion 5", value: "1138" },
  { label: "Scorpion 5000", value: "246" },
  { label: "Scorpion 858", value: "66" },
  { label: "Scorpion 902", value: "321" },
  { label: "Scorpion 902T", value: "389" },
  { label: "Scorpion 918", value: "67" },
  { label: "Scorpion 918U", value: "502" },
  { label: "Scorpion 980", value: "68" },
  { label: "Scorpion Automotive Ltd SA40", value: "1672" },
  { label: "Scorpion Automotive Ltd Scorpion Track", value: "1834" },
  { label: "Scorpion Automotive Ltd Scorpion Track 5", value: "2124" },
  { label: "Scorpion Automotive Ltd Scorpion Track Fleet Secure", value: "2027" },
  { label: "Scorpion Automotive Ltd Scorpion Track Fleet Secure HD", value: "2341" },
  { label: "Scorpion Automotive Ltd Sterling Touch 2", value: "2303" },
  { label: "Scorpion Bosch", value: "850" },
  { label: "Scorpion Discover", value: "1418" },
  { label: "Scorpion Locate", value: "1415" },
  { label: "Scorpion Lucas TCM", value: "849" },
  { label: "Scorpion PURSUE", value: "1371" },
  { label: "Scorpion SA70", value: "1294" },
  { label: "Scorpion SA80", value: "1295" },
  { label: "Scorpion Track HD", value: "1515" },
  { label: "Seat 1215 T2", value: "910" },
  { label: "Seat 1318 T2", value: "763" },
  { label: "Seat Compact Alarm", value: "772" },
  { label: "Seat Ibiza 1400 T2", value: "1038" },
  { label: "Seat Immobiliser", value: "773" },
  { label: "Seat Security System 1", value: "989" },
  { label: "Seat Security System II", value: "889" },
  { label: "Seat Security System IV SE-FI", value: "1281" },
  { label: "Seat UK Security System V SE-FI", value: "2068" },
  { label: "Secure Microsystems M 10", value: "684" },
  { label: "Secure Microsystems Sigma S34", value: "1154" },
  { label: "Secure Microsystems Sigma SG10", value: "322" },
  { label: "Secure Microsystems Sigma SG20", value: "323" },
  { label: "Secure Microsystems Sigma SG30", value: "265" },
  { label: "Secure Microsystems Sigma SG40", value: "324" },
  { label: "Secure Microsystems Sigma SG80", value: "614" },
  { label: "SecureMark", value: "1319" },
  { label: "Securicor TrakBak", value: "272" },
  { label: "Securiloc Immobiliser", value: "919" },
  { label: "Seecuricar 24/2007", value: "685" },
  { label: "Selca FC 6.71", value: "872" },
  { label: "Selca FM7-11", value: "862" },
  { label: "Selca IM 3.22", value: "734" },
  { label: "Selectamark Security Systems plc Car Security System", value: "2056" },
  { label: "Selectamark Security Systems plc Catalytic Converter Marking System", value: "1996" },
  { label: "Selectamark Security Systems plc Glass Etch", value: "1980" },
  { label: "Selectamark Security Systems plc Metal Etch", value: "1984" },
  {
    label: "Selectamark Security Systems plc Off Road & 4x4 Vehicle Security System",
    value: "2370",
  },
  { label: "Selectamark Security Systems plc Plastic Etch", value: "1976" },
  { label: "Selectamark Security Systems plc Plastic Marking Compound (Bottle)", value: "2368" },
  { label: "Selectamark Security Systems plc QR Code Security Label", value: "2013" },
  { label: "Selectamark Security Systems plc SecureAssetRegister.com", value: "2018" },
  {
    label: "Selectamark Security Systems plc SelectaDNA/Dot Forensic Marking Adhesive",
    value: "2382",
  },
  { label: "Selectamark Security Systems plc Tesa Security Label", value: "2000" },
  { label: "Selectamark Security Systems plc Universal Security System", value: "2063" },
  { label: "Selmar PL350", value: "69" },
  { label: "Selmar PL351", value: "70" },
  { label: "Selmar SBA 598", value: "71" },
  { label: "Serpi Star Digit Line 400", value: "195" },
  { label: "Serpi Star Digit Line 440", value: "196" },
  { label: "Serpi Star GP11", value: "197" },
  { label: "Serpi Star GP12", value: "198" },
  { label: "Serpi Star GP13", value: "199" },
  { label: "Serpi Star GR24", value: "200" },
  { label: "Serpi Star GR42", value: "201" },
  { label: "Serpi Star GR44", value: "202" },
  { label: "Serpi Star GR500", value: "205" },
  { label: "Serpi Star GR54", value: "203" },
  { label: "Serpi Star GR600", value: "206" },
  { label: "Serpi Star GR64", value: "204" },
  { label: "Shurlock EP10", value: "636" },
  { label: "Shurlok EP100", value: "666" },
  { label: "Shurlok FP 150", value: "708" },
  { label: "Shurlok SK 20", value: "841" },
  { label: "Shurlok SK 30", value: "833" },
  { label: "Shurlok SK 34", value: "844" },
  { label: "Siemens Datatrak Location & Information Systems Ltd Trakback", value: "1010" },
  { label: "Sigma Alarm/Immobiliser SG0", value: "207" },
  { label: "Sigma Alarm/Immobiliser SG1", value: "72" },
  { label: "Sigma Alarm/Immobiliser SG2", value: "73" },
  { label: "Sigma Alarm/Immobiliser SG3", value: "208" },
  { label: "Sigma M 800", value: "817" },
  { label: "Sigma M20", value: "644" },
  { label: "Sigma M30", value: "640" },
  { label: "Sigma M32", value: "648" },
  { label: "Sigma M34", value: "647" },
  { label: "Sigma S30", value: "1105" },
  { label: "Sigma S32", value: "1126" },
  { label: "Sigma S35", value: "1917" },
  { label: "Sigma SG 34", value: "471" },
  { label: "Sigma SG 800", value: "683" },
  { label: "Sikura A10", value: "209" },
  { label: "Sikura A11", value: "210" },
  { label: "Sikura A12", value: "211" },
  { label: "Sikura A13", value: "212" },
  { label: "Sikura A15", value: "213" },
  { label: "Sikura A16", value: "214" },
  { label: "Sikura K11", value: "215" },
  { label: "Simba Jackplug Immobiliser", value: "103" },
  { label: "Skoda 1400 T2", value: "1017" },
  { label: "Skoda Immobiliser", value: "775" },
  { label: "Skoda Security System 1", value: "990" },
  { label: "Skoda Security System II", value: "890" },
  { label: "Skoda Security System V", value: "2141" },
  { label: "Skoda UK Security System IV SK-FI", value: "1549" },
  { label: "Skoda VAG 1400 T2", value: "894" },
  { label: "SKYNET 2000", value: "527" },
  { label: "Smart", value: "1106" },
  { label: "Smart Immobiliser 451 with Mercedes Alarm", value: "1323" },
  { label: "Smart Immobiliser System", value: "2147" },
  { label: "Smart OE System Cat 1", value: "1148" },
  { label: "Smartrack Alert 24", value: "1276" },
  { label: "SmarTrack Alert24 Keyguard", value: "1457" },
  { label: "SmarTrack Caravan", value: "1514" },
  { label: "SmarTrack Caravan Protector", value: "1473" },
  { label: "SmarTrack Ltd  Protector Pro Global", value: "2345" },
  { label: "SmarTrack Ltd Protector Pro Global", value: "2183" },
  { label: "SmarTrack Ltd SmarTrack CAT 5", value: "1558" },
  { label: "SmarTrack Ltd SmarTrack Protector Plus", value: "1533" },
  { label: "SmarTrack Maxi", value: "1458" },
  { label: "Smartrack Protector Plus", value: "1275" },
  { label: "Smartrack Protector Pro", value: "1498" },
  { label: "SmarTrack TOAD TT1000", value: "1460" },
  { label: "SmarTrack Trident", value: "1459" },
  { label: "Solartrack Block System 4", value: "291" },
  { label: "Solutia Performance Films LLumar Window Film", value: "1959" },
  { label: "Sparkrite AT125", value: "74" },
  { label: "Sparkrite SR100", value: "218" },
  { label: "Sparkrite SR70", value: "75" },
  { label: "Sparkrite ST1000", value: "219" },
  { label: "Sparkrite ST2000", value: "220" },
  { label: "Speed Ahead Talisman 3", value: "282" },
  { label: "Spyball RF53", value: "76" },
  { label: "Spyball SPK 395", value: "463" },
  { label: "Spyball SPK 660", value: "77" },
  { label: "Spyball SPK 695", value: "343" },
  { label: "Spyball ZRF88", value: "216" },
  { label: "Spyball ZRF89", value: "217" },
  { label: "Spyball/Electro Stop 190", value: "78" },
  { label: "Spyball/Electro Stop RF88", value: "79" },
  { label: "SsangYong Security Immobiliser 1", value: "2112" },
  { label: "Ssangyong Security System", value: "1234" },
  { label: "Ssangyong UK Rexton 7928 Security System", value: "1550" },
  { label: "Sterling Excel", value: "1289" },
  { label: "Sterling One", value: "1180" },
  { label: "Sterling Touch", value: "869" },
  { label: "Stinger Smart Key Immobiliser", value: "667" },
  { label: "Stirling Excel", value: "782" },
  { label: "Strikeback T", value: "1042" },
  { label: "Stud-It-Ltd Easylock Immobiliser", value: "961" },
  { label: "Subaru Forester Cat1", value: "911" },
  { label: "Subaru IMBRZ-001", value: "1937" },
  { label: "Subaru Immobiliser", value: "1500" },
  { label: "Subaru Impreza Cat1", value: "912" },
  { label: "Subaru Legacy Cat1", value: "913" },
  { label: "Subaru SUB S32", value: "1324" },
  { label: "Subaru SUB S34", value: "1325" },
  { label: "Subaru SUB/AT 100", value: "1091" },
  { label: "Subaru SUB/AT 100", value: "2114" },
  { label: "Subaru Uk Subaru Sat", value: "1881" },
  { label: "SupaGlass 350", value: "1399" },
  { label: "Sure-Track Europe Ltd Sure-Track MT3", value: "1526" },
  { label: "Suzuki GB Secure Alarm/ Immobiliser", value: "1639" },
  { label: "Suzuki Immobiliser", value: "1370" },
  { label: "Suzuki Secure Alarm/Immobiliser", value: "433" },
  { label: "Swiftrak Swiftrak", value: "1813" },
  { label: "Swiftrak Swiftrak 2", value: "2167" },
  { label: "T Trac", value: "1159" },
  { label: "Tag Guard Ltd Locator", value: "1139" },
  { label: "Talisman 1 Touch", value: "352" },
  { label: "Taurus T1000", value: "519" },
  { label: "Taurus T2000", value: "541" },
  { label: "Taurus T20003M", value: "540" },
  { label: "Tech-link RVJM AS2T", value: "566" },
  { label: "Tech-link RVJM AS3T", value: "565" },
  { label: "Technology For Today Meridian 250", value: "80" },
  { label: "Technology For Today Meridian 250AA", value: "179" },
  { label: "Technology For Today Meridian Minder", value: "81" },
  { label: "Technology For Today Meridian Minder Jack Plug", value: "82" },
  { label: "Technology For Today Meridian Minder Techno 2000", value: "83" },
  { label: "Tecnik Immobiliser Plus2", value: "434" },
  { label: "Telematix e-Guard", value: "1051" },
  { label: "Telematrix Exec Euro", value: "1482" },
  { label: "Telematrix Exec UK", value: "1483" },
  { label: "Telematrix Premium", value: "1484" },
  { label: "Texalarm 2960", value: "714" },
  { label: "Texalarm 2962", value: "699" },
  { label: "Texalarm 2970", value: "715" },
  { label: "Texalarm 2972", value: "700" },
  { label: "Texalarm 2980", value: "895" },
  { label: "Texalarm 2980 & AD 200", value: "888" },
  { label: "Texalarm AV2950 GBT", value: "576" },
  { label: "Texton AV2955GB", value: "222" },
  { label: "Texton AV810GB", value: "221" },
  { label: "Texton Tex Alarm AV2955 GBT", value: "567" },
  { label: "Texton TFK 165 GBT", value: "577" },
  { label: "Thales CAT 5", value: "1158" },
  { label: "Thales Telematics DX-50 Cars & Vans", value: "1052" },
  { label: "Thales Telematics DX-50 Tractors &Trucks", value: "1057" },
  { label: "Thales Telematics Orchid VX 100", value: "1054" },
  { label: "The Minder", value: "247" },
  { label: "The Ultimate", value: "248" },
  { label: "Thiefbeaters Ltd", value: "928" },
  { label: "Tiger Telematics Ltd TT105 Standard System - Track & Trace", value: "1011" },
  { label: "Titan API", value: "396" },
  { label: "Titan SSA", value: "397" },
  { label: "Titan SSJ", value: "394" },
  { label: "Titan SST", value: "395" },
  { label: "Titan THA Bsc", value: "390" },
  { label: "Titan THA DL", value: "391" },
  { label: "Titan THT", value: "392" },
  { label: "TM100 Trackstar", value: "1258" },
  { label: "TM400 Trackstar", value: "1259" },
  { label: "Toad 850T", value: "768" },
  { label: "Toad A120", value: "464" },
  { label: "Toad A220", value: "465" },
  { label: "Toad A221", value: "592" },
  { label: "Toad A520", value: "455" },
  { label: "Toad Ai 600", value: "578" },
  { label: "Toad Ai 606", value: "781" },
  { label: "Toad Compact C4", value: "794" },
  { label: "Toad Compact C5", value: "790" },
  { label: "Toad Laserkey", value: "487" },
  { label: "Toad Powerjack", value: "466" },
  { label: "Toad Powerkey", value: "709" },
  { label: "Toad Powerkey 1", value: "520" },
  { label: "Toad Tracking", value: "1485" },
  { label: "Toyota (Inc. Lexus) 5000", value: "450" },
  { label: "Toyota (inc. Lexus) EIS Immobiliser", value: "435" },
  { label: "Toyota (inc. Lexus) EIS2 Immobiliser", value: "668" },
  { label: "Toyota (Inc. Lexus) Talm 001", value: "809" },
  { label: "Toyota (Inc. Lexus) TVSS IIIA", value: "448" },
  { label: "Toyota (Inc. Lexus) TVSS IIIB", value: "449" },
  { label: "Toyota (inc. Lexus) TVSS IV-MPX", value: "701" },
  { label: "Toyota EIS 2", value: "851" },
  { label: "Toyota EIS 3", value: "1378" },
  { label: "Toyota Lexus IS 200", value: "741" },
  { label: "Toyota PZ464-00130-00", value: "1291" },
  { label: "Toyota TSES 001", value: "929" },
  { label: "Toyota TVSS 11Plus", value: "292" },
  { label: "Toyota TVSS IVS", value: "658" },
  { label: "Toyota TVSS V", value: "1034" },
  { label: "Tracker 24 Hour Monitor", value: "595" },
  { label: "Tracker 24 Hour Monitor including Guard", value: "596" },
  { label: "Tracker 24 Hour Monitor including Guard and Recover", value: "597" },
  { label: "Tracker Echo", value: "1140" },
  { label: "Tracker Echo II", value: "1195" },
  { label: "TRACKER Horizon", value: "878" },
  { label: "Tracker including Guard", value: "594" },
  { label: "TRACKER Locate", value: "1481" },
  { label: "TRACKER Monitor", value: "801" },
  { label: "TRACKER Network (UK) Ltd Battery Powered TRACKER Retrieve", value: "1859" },
  { label: "Tracker Network (UK) Ltd Battery Powered Tracker Retrieve", value: "1538" },
  { label: "Tracker Network (UK) Ltd Tracker CAT 5 Plus", value: "1922" },
  { label: "TRACKER Network (UK) Ltd TRACKER Fleet Plus", value: "1969" },
  { label: "TRACKER Network (UK) Ltd Tracker for Mercedes-Benz", value: "2030" },
  { label: "TRACKER Network (UK) Ltd TRACKER Plus", value: "2104" },
  { label: "TRACKER Network (UK) Ltd Tracker Plus for Mercedes-Benz", value: "2033" },
  { label: "TRACKER Network (UK) Ltd TRACKER Secure", value: "2108" },
  { label: "Tracker Network Caravan Monitor", value: "1493" },
  { label: "Tracker Network Tracker Reporter", value: "1318" },
  { label: "Tracker Response", value: "1226" },
  { label: "TRACKER Retrieve", value: "800" },
  { label: "TRACKER Stolen Vehicle Recovery System", value: "1045" },
  { label: "Tracks360 Ltd Tracks360 - The Guardian", value: "1909" },
  { label: "Trackstar Ltd Trackstar 5", value: "1189" },
  { label: "Trade Vehicle Locks (UK) Ltd Pedalbox", value: "2145" },
  { label: "Trafficmaster BMW Trackstar", value: "1362" },
  { label: "Trafficmaster Fleet Director", value: "1479" },
  { label: "Trafficmaster Fleet Director Trackstar", value: "1490" },
  { label: "Trafficmaster Jaguar Trackstar", value: "1496" },
  { label: "Trafficmaster Land Rover Trackstar", value: "1513" },
  { label: "Trafficmaster Ltd BMW Trackstar Advance", value: "2138" },
  { label: "Trafficmaster Ltd Jaguar Trackstar Advance", value: "2132" },
  { label: "Trafficmaster Ltd Land Rover Trackstar Advance", value: "2133" },
  { label: "Trafficmaster Ltd Mini Trackstar Advance", value: "2139" },
  { label: "Trafficmaster Ltd Trackstar Advance", value: "2135" },
  { label: "Trafficmaster Ltd. BMW Trackstar CAT 5", value: "1950" },
  { label: "Trafficmaster Ltd. BMW Trackstar CAT5 TM470", value: "2038" },
  { label: "Trafficmaster Ltd. BMW Trackstar TM470", value: "2047" },
  { label: "Trafficmaster Ltd. Fleet Director TM460", value: "2255" },
  { label: "Trafficmaster Ltd. Fleet Director Trackstar TM470", value: "1972" },
  { label: "Trafficmaster Ltd. Jaguar Trackstar CAT 5", value: "1952" },
  { label: "Trafficmaster Ltd. Jaguar Trackstar CAT5 TM470", value: "2037" },
  { label: "Trafficmaster Ltd. Jaguar Trackstar TM470", value: "1991" },
  { label: "Trafficmaster Ltd. Land Rover Trackstar CAT 5", value: "1954" },
  { label: "Trafficmaster Ltd. Land Rover Trackstar CAT5 TM470", value: "1990" },
  { label: "Trafficmaster Ltd. Land Rover Trackstar TM470", value: "2049" },
  { label: "Trafficmaster Ltd. Mercedes Benz Trackstar CAT5", value: "1963" },
  { label: "Trafficmaster Ltd. MINI Trackstar CAT 5", value: "1951" },
  { label: "Trafficmaster Ltd. Mini Trackstar CAT5 TM470", value: "2042" },
  { label: "Trafficmaster Ltd. Mini Trackstar TM470", value: "2050" },
  { label: "Trafficmaster Ltd. Range Rover Trackstar CAT 5", value: "1955" },
  { label: "Trafficmaster Ltd. Range Rover Trackstar CAT5 TM470", value: "2043" },
  { label: "Trafficmaster Ltd. Range Rover Trackstar TM470", value: "2034" },
  { label: "Trafficmaster Ltd. Smartnav TM460", value: "2257" },
  { label: "Trafficmaster Ltd. Teletrac TM460", value: "2259" },
  { label: "Trafficmaster Ltd. Trackstar CAT 5", value: "1966" },
  { label: "Trafficmaster Ltd. Trackstar CAT5 TM470", value: "2046" },
  { label: "Trafficmaster Ltd. Trackstar Protector TM470", value: "2055" },
  { label: "Trafficmaster Ltd. Trackstar TM460", value: "2262" },
  { label: "Trafficmaster MINI Trackstar", value: "1363" },
  { label: "Trafficmaster PLC BMW TM100 Trackstar", value: "1867" },
  { label: "Trafficmaster PLC MINI TM100 Trackstar", value: "1882" },
  { label: "Trafficmaster Range Rover Trackstar", value: "1569" },
  { label: "Trafficmaster TM50", value: "1423" },
  { label: "Trafficmaster Trackstar Protector 2", value: "1495" },
  { label: "Trak (Global Solutions Ltd) Swiftrak", value: "1535" },
  { label: "Trakbak Immobiliser", value: "436" },
  { label: "TRAKM8 Ltd T2002", value: "1141" },
  { label: "TrakM8 Track and Trace - Cars", value: "1049" },
  { label: "TUK Excalibur 7000", value: "293" },
  { label: "TVS TK3", value: "283" },
  { label: "Ultimate Security Systems Powerlock 1", value: "1151" },
  { label: "Uncoded Alarm", value: "86" },
  { label: "Uncoded Combined Alarm/Immobiliser", value: "87" },
  { label: "Uncoded Electromechanical or Electronic Immobiliser", value: "88" },
  { label: "Uncoded Mechanical Immobiliser", value: "89" },
  { label: "Uncoded Tracker", value: "90" },
  { label: "Unknown Alarm", value: "91" },
  { label: "Unknown Combined Alarm/Immobiliser", value: "92" },
  { label: "Unknown Electromechanical or Electronic Immobiliser", value: "93" },
  { label: "Unknown Mechanical Immobiliser", value: "94" },
  { label: "Unknown Tracker", value: "95" },
  { label: "VAG (UK) Logic 950", value: "579" },
  { label: "VAG (UK) Logic 951", value: "580" },
  { label: "VAG 1315 T2", value: "842" },
  { label: "VAG 5021 T", value: "810" },
  { label: "VAG U.K. 5000", value: "266" },
  { label: "VAG UK Logic 900", value: "368" },
  { label: "Van Bitz Strikeback", value: "2148" },
  { label: "Van Bitz STRIKEBACK", value: "2161" },
  { label: "Van Bitz Strikeback CAN Alarm", value: "1313" },
  { label: "Van Bitz Strikeback T", value: "507" },
  { label: "Vari-Trac Ltd Vari-Trac One", value: "1250" },
  { label: "Vauxhall 3000 Security System", value: "991" },
  { label: "Vauxhall Astra Security System", value: "702" },
  { label: "Vauxhall Astra/Zafira Security System", value: "831" },
  { label: "Vauxhall Brava Immobiliser", value: "923" },
  { label: "Vauxhall Combo Van Security System", value: "980" },
  { label: "Vauxhall Corsa Van Security System", value: "898" },
  { label: "Vauxhall Corsa/Meriva Security System", value: "858" },
  { label: "Vauxhall D.S.A.", value: "325" },
  { label: "Vauxhall DSA Issue 2", value: "726" },
  { label: "Vauxhall Engine Deadlock II Immobiliser", value: "669" },
  { label: "Vauxhall Engine Deadlock Immobiliser", value: "503" },
  { label: "Vauxhall Engine Deadlock V3 Immobiliser", value: "924" },
  { label: "Vauxhall Frontera Security System", value: "677" },
  { label: "Vauxhall Gearlock", value: "249" },
  { label: "Vauxhall GM Sentinel", value: "1282" },
  { label: "Vauxhall Motors DSA", value: "1641" },
  { label: "Vauxhall Motors Security System MVX2/3", value: "1603" },
  { label: "Vauxhall Movano Security System", value: "1113" },
  { label: "Vauxhall Omega Security System", value: "345" },
  { label: "Vauxhall Security System MVX1", value: "1108" },
  { label: "Vauxhall Security System MVX2/3", value: "1128" },
  { label: "Vauxhall Security System MVX3", value: "1129" },
  { label: "Vauxhall Sintra Alarm", value: "622" },
  { label: "Vauxhall Steering Wheel Bar", value: "96" },
  { label: "Vauxhall Steering Wheel Security Bar", value: "439" },
  { label: "Vauxhall V2/P/T/S", value: "852" },
  { label: "Vauxhall Vectra/Omega Security System", value: "504" },
  { label: "Vauxhall VIM 129", value: "97" },
  { label: "Vauxhall Vivaro Security System", value: "921" },
  { label: "Vauxhall VX220 Security System", value: "859" },
  { label: "Vecta Anti Theft System", value: "98" },
  { label: "Vecta Cat 1", value: "544" },
  { label: "Vecta Managusta", value: "267" },
  { label: "Vecta Shadow", value: "268" },
  { label: "Vecta Silhouette", value: "294" },
  { label: "Vecta The New Cat 1", value: "742" },
  { label: "Vecta The New Cat 2", value: "744" },
  { label: "Vecta The New Cat 2 -> 1 Upgrade", value: "747" },
  { label: "Vecta Vecta 2-1 Alarm", value: "525" },
  { label: "Vehicle Manufacturers Optional Equipment", value: "99" },
  { label: "Vehicle Manufacturers Standard Equipment", value: "100" },
  { label: "Vestatec Distribution Ltd Defcom Trak", value: "2273" },
  { label: "Vestatec Distribution Ltd Easycan C/A Alarm", value: "1438" },
  { label: "Vestatec Distribution Ltd HPAT 1", value: "1109" },
  { label: "Vestatec Distribution Ltd HPAT 5.0", value: "2155" },
  { label: "Vestatec Distribution Ltd M36T2", value: "680" },
  { label: "Vestatec Distribution Ltd Meta Trak 5", value: "2264" },
  { label: "Vestatec Distribution Ltd Meta Trak 6", value: "2267" },
  { label: "Vestatec Distribution Ltd Meta Trak 6 V2", value: "2399" },
  { label: "Vestatec Distribution Ltd Meta Trak M 6", value: "2274" },
  { label: "Vestatec Distribution Ltd TVM110", value: "2171" },
  { label: "Viking 604", value: "326" },
  { label: "Viking Stopman", value: "346" },
  { label: "Viper 106V", value: "1152" },
  { label: "Viper 460XV", value: "1223" },
  { label: "Viper 480XV", value: "1224" },
  { label: "Viper 500XV", value: "1261" },
  { label: "Virgin 2 -> 1 Upgrade", value: "873" },
  { label: "Virgin 2 -> 1 Upgrade Remote", value: "874" },
  { label: "Virgin Cat 1", value: "863" },
  { label: "Vision UK Aegis VAE 318-1820", value: "795" },
  { label: "Vision UK Aegis VAE 318-1830", value: "791" },
  { label: "Vodafone Automotive UK Ltd A4405 OES", value: "2291" },
  { label: "Vodafone Automotive UK Ltd Bentley GPS Tracking System Advanced", value: "2177" },
  { label: "Vodafone Automotive UK Ltd CobraTrak 5 V2", value: "2218" },
  { label: "Vodafone Automotive UK Ltd CobraTrak 5SC", value: "2215" },
  { label: "Vodafone Automotive UK Ltd CobraTrak 6SC", value: "2176" },
  { label: "Vodafone Automotive UK Ltd CobraTrak ADR", value: "2212" },
  { label: "Vodafone Automotive UK Ltd NavTrak ADR10", value: "2173" },
  { label: "Vodafone Automotive UK Ltd NavTrak ADR10 Secure", value: "2209" },
  { label: "Vodafone Automotive UK Ltd. Aston Martin Tracking by VAUK Ltd", value: "2301" },
  { label: "Vodafone Automotive UK Ltd. MCD30", value: "2394" },
  { label: "Vodafone Automotive UK Ltd. Nissan Tracking System 5", value: "2373" },
  { label: "Vodafone Automotive UK Ltd. SCD2231", value: "2296" },
  { label: "Vodafone Automotive UK Ltd. Vodafone Protect & Connect 5", value: "2311" },
  { label: "Volkswagen Beetle Alarm Upgrade", value: "958" },
  { label: "Volkswagen Compact Alarm", value: "811" },
  { label: "Volkswagen CV System 1", value: "1074" },
  { label: "Volkswagen Engine Immobiliser", value: "670" },
  { label: "Volkswagen Group UK Security System 6 VW-FI", value: "2184" },
  { label: "Volkswagen Logic 900", value: "938" },
  { label: "Volkswagen Logic 950", value: "939" },
  { label: "Volkswagen New Golf Alarm", value: "659" },
  { label: "Volkswagen Polo 1400 T2", value: "977" },
  { label: "Volkswagen Security System 1", value: "884" },
  { label: "Volkswagen Security System II", value: "891" },
  { label: "Volkswagen Security System IV VW-FI", value: "1283" },
  { label: "Volkswagen Security System V VW-FI", value: "1551" },
  { label: "Volkswagen Security System VI VW-FI", value: "2142" },
  { label: "Volvo 400 Series", value: "347" },
  { label: "Volvo 400 Series Alarm/Immobiliser", value: "393" },
  { label: "Volvo 400 Series Immobiliser", value: "440" },
  { label: "Volvo 5000", value: "269" },
  { label: "Volvo Guard 40", value: "885" },
  { label: "Volvo Guard Alarm II", value: "581" },
  { label: "Volvo V40 Immobiliser", value: "488" },
  { label: "Volvo VGLA 40 Security System", value: "764" },
  { label: "Volvo VGSS", value: "703" },
  { label: "Volvo VGSSi", value: "1367" },
  { label: "Volvo Volvoguard", value: "546" },
  { label: "Volvo Volvoguard Lock & Alarm", value: "593" },
  { label: "Volvo VSS", value: "603" },
  { label: "Wasco GR200", value: "101" },
  { label: "Waso Untouchable 1T", value: "438" },
  { label: "Waso UT2", value: "631" },
  { label: "Waso XPiT", value: "437" },
  { label: "Waso XpiT 2", value: "710" },
  { label: "Wasp Wasp 2", value: "521" },
  { label: "Wipac EP10", value: "270" },
  { label: "Wipac FP15", value: "271" },
];
