export const VehicleType = [
  { label: "Cabriolet Coupe", value: "D2" },
  { label: "Convertible Saloon", value: "B5" },
  { label: "Convertible Sport", value: "B6" },
  { label: "Convertible/Cabriolet", value: "8" },
  { label: "Coupe", value: "4" },
  { label: "Estate Car/Station Wagon", value: "3" },
  { label: "Hatchback", value: "2" },
  { label: "Light 4x4 Utility", value: "BL" },
  { label: "People Carrier/MPV", value: "62" },
  { label: "Saloon", value: "1" },
  { label: "Sports Car", value: "B7" },
  { label: "SUV", value: "D3" },
  { label: "SUV Cabriolet", value: "D5" },
  { label: "SUV Coupe", value: "D4" },
];
