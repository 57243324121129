import { TFormField } from '../../types';
import Alert from '@mui/material/Alert';
import InfoIcon from '@mui/icons-material/Info';

const variants = {
  info: '#565E73',
  warning: '#FF0000',
};
export const InfoWithHyperLink = (props: { field: TFormField }) => {
  const variant = props?.field?.variant ?? 'info';
  const variantColor = variants[variant as keyof typeof variants];

  const InfoWithHyperLink = props?.field?.params?.hyperLink;

  return (
    <Alert
      style={{
        backgroundColor: '#fafaff',
        fontSize: '3rem',
        color: variantColor,
        padding: 0,
        display: 'flex',
      }}
      icon={
        <InfoIcon
          fontSize="medium"
          style={{
            color: '#98A2B3',
          }}
        />
      }
    >
      {Array.isArray(InfoWithHyperLink) ? (
        InfoWithHyperLink.map((item) => {
          return createText(item);
        })
      ) : (
        <p style={{ color: 'red' }}>
          {
            "Missing params to create this component InfoWithHyperLink. It should look like: ['here', {link: 'www.google.com', text: 'dsds'}]"
          }
        </p>
      )}
    </Alert>
  );
};

const createText = (item: string | any) => {
  if (typeof item == 'string') {
    return item;
  } else if (typeof item == 'object') {
    return (
      <a
        style={{
          color: '#0000EE',
        }}
        href={item.link}
        target="_blank"
        rel="noreferrer"
      >
        {item.text}
      </a>
    );
  } else {
    return '';
  }
};
