import React, { useContext, useState } from 'react';
import { TBranding, TFieldChangeHandler, TFormData, TFormField, TJourneyMode } from '../../types';
import {
  Box,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Modal,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Circle, Close, ErrorOutline } from '@mui/icons-material';
import { replacePlaceholders } from '../../formHandling';
import { JourneyContext } from '../../JourneyContext';

export default function PopupModal({
  field,
  value,
  changeHandler,
  formData,
  mode,
}: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
  formData: TFormData;
  mode: TJourneyMode;
  branding: TBranding | undefined;
}) {
  const isMobile = useMediaQuery('(max-width:600px)');
  const [open, setOpen] = useState(formData?.values?.[field?.name] ? false : true);

  const { branding } = useContext(JourneyContext);

  const listItems: { text: string; subText?: string }[] = field?.dialog?.listItems ?? [];
  const title = field?.dialog?.title ?? '';
  const title2 = field?.dialog?.title2 ?? '';
  const subTitle = field?.dialog?.subTitle ?? '';
  const includeContactInfo = field?.dialog?.includeContactInfo ?? false;
  const TitleIcon = TitleIcons[field?.dialog?.titleIcon as keyof typeof TitleIcons];

  const contactEmail = branding?.servicing_email;
  const contactNumber = branding?.servicing_phone;

  const localChangeHandler = () => {
    changeHandler(field, open);
    setOpen(false);
  };

  return (
    <>
      <DynamicContainer open={open} onClose={() => setOpen(open)} isMobile={isMobile}>
        {' '}
        <Box
          sx={{
            position: isMobile ? 'initial' : ('absolute' as 'absolute'),
            top: '50%',
            left: '50%',
            transform: isMobile ? 'none' : 'translate(-50%, -50%)',
            width: { xs: 'fit', sm: 600 },
            bgcolor: 'background.paper',
            border: '2px solid #E9EAFF',
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Stack direction={'row'} alignItems={'center'}>
              {TitleIcon && <TitleIcon color="primary" sx={{ marginRight: 1 }} fontSize="large" />}
              <div>
                <Typography
                  fontSize={{
                    xs: 12,
                    md: 16,
                  }}
                  textTransform={'uppercase'}
                  fontFamily={'Poppins'}
                  fontWeight={600}
                  color={'primary'}
                >
                  {title}
                </Typography>
                {title2 && (
                  <Typography
                    fontSize={{
                      xs: 25,
                      md: 30,
                    }}
                    fontFamily={'Poppins'}
                    fontWeight={600}
                    color={'#1C1D21'}
                  >
                    {title2}
                  </Typography>
                )}
                {subTitle && (
                  <Typography fontSize={20} fontFamily={'Poppins'} fontWeight={600} color={'#565E73'}>
                    {subTitle}
                  </Typography>
                )}
              </div>
            </Stack>
          </Stack>
          <div
            style={{
              margin: '20px 0 20px 0',
              height: '1px',
              width: '100%',
              backgroundColor: '#E9EAFF',
            }}
          />
          <Stack spacing={4}>
            {listItems && (
              <List dense>
                {listItems?.map((item, index) => (
                  <ListItem sx={{ marginBottom: -1 }} disableGutters alignItems="flex-start">
                    <ListItemIcon sx={{ minWidth: '30px', paddingTop: 0.2 }}>
                      <Circle sx={{ fontSize: 12 }} color="primary" />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography fontSize={16} color={'#1A2133'} fontWeight={500}>
                          {replacePlaceholders(formData.values, item.text)}
                        </Typography>
                      }
                      secondary={
                        !item?.subText ? null : (
                          <p
                            style={{
                              fontSize: 14,
                              color: '#677185',
                              fontStyle: 'normal',
                              fontWeight: 400,
                            }}
                          >
                            {replacePlaceholders(formData.values, item?.subText ?? '')}
                          </p>
                        )
                      }
                    />
                  </ListItem>
                ))}
              </List>
            )}

            {includeContactInfo && (
              <Stack fontSize={12} fontFamily={'Inter'} width={'100%'}>
                <Typography color={branding?.main_color ?? 'primary'}>Need help?</Typography>
                <Typography variant="body1" color={'#565E73'} fontSize={{ xs: 13, sm: 14 }}>
                  Call <b>{contactNumber}</b> or email <b>{contactEmail}</b>
                </Typography>
              </Stack>
            )}
          </Stack>
          <div
            style={{
              margin: '20px 0 20px 0',
              height: '1px',
              width: '100%',
              backgroundColor: '#E9EAFF',
            }}
          />
          <Stack alignItems={'center'} justifyContent={'center'}>
            <Button onClick={localChangeHandler} color="primary" fullWidth variant="contained">
              Got it
            </Button>
          </Stack>
        </Box>
      </DynamicContainer>
    </>
  );
}

const TitleIcons = {
  info: ErrorOutline,
};

const DynamicContainer = ({
  isMobile,
  open,
  onClose,
  children,
}: {
  children: any;
  isMobile: boolean;
  open: boolean;
  onClose: () => void;
}) => {
  if (isMobile) {
    return (
      <Drawer
        PaperProps={{
          sx: {
            background: 'transparent',
          },
        }}
        open={open}
        onClose={onClose}
        anchor="bottom"
      >
        {children}
      </Drawer>
    );
  }

  return (
    <Modal open={open} onClose={onClose}>
      <>{children}</>
    </Modal>
  );
};
