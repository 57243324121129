import { FormHelperText, Stack, TextField as TextFieldMUI } from "@mui/material";
import validator from "validator";
import { useState } from "react";
import { TFormField } from "../../../types";
import { MAX_MEASUREMENTS } from "./MeasurementsField";

// =================================================================================================
// Imperial Measurements
// =================================================================================================
export const ImperialHeight = (props: {
  field: TFormField;
  inches: number;
  errors: { feet: string; inches: string };
  onChange: (feet: string, inches: string) => void;
}) => {
  const { field, errors } = props;

  const [feet, setFeet] = useState(props.inches ? Math.trunc(props.inches / 12) + "" : "");
  const [inches, setInches] = useState(props.inches ? (props.inches % 12).toFixed(1) : "");

  // used to show a single error message if feet and inches are invalid
  const feetAndInchError = () => {
    const validFeet = validator.isInt(feet || "0") && Number(feet) >= 0;
    const validInches = validator.isDecimal(inches || "0") && Number(inches) >= 0;
    if (errors.feet.length > 0 && errors.inches.length > 0) {
      if (validFeet || validInches) return true;
    }

    return false;
  };

  const errorHandler = (error: string, value: string, field: "FEET" | "INCHES") => {
    if (error.length > 0) return true;
    if (Number(value) > MAX_MEASUREMENTS.Imperial[field]) return true;
    // if (!feetAndInchError) return false;
    return false;
  };

  return (
    <>
      <p style={{ marginTop: 24 }}>What is your height?</p>
      <Stack direction="row" spacing={2}>
        <TextFieldMUI
          disabled={field.disabled}
          fullWidth
          variant="outlined"
          label="Feet"
          value={feet}
          onChange={(event) => {
            setFeet(event.target.value);
            props.onChange(event.target.value, inches);
          }}
          required={field.required}
          error={errorHandler(errors.feet, feet, "FEET")}
          helperText={errors.feet || ""}
        />
        <TextFieldMUI
          disabled={field.disabled}
          fullWidth
          variant="outlined"
          label="Inches"
          value={inches}
          onChange={(event) => {
            setInches(event.target.value);
            props.onChange(feet, event.target.value);
          }}
          required={field.required}
          error={errorHandler(errors.inches, inches, "INCHES")}
          helperText={errors.inches || ""}
        />
      </Stack>

      <FormHelperText
        hidden={!feetAndInchError()}
        style={{ margin: "-20px auto 0 auto" }}
        error={true}
      >
        {"Height must not exceed " +
          MAX_MEASUREMENTS.Imperial.FEET +
          "ft, " +
          MAX_MEASUREMENTS.Imperial.INCHES +
          "in."}
      </FormHelperText>
    </>
  );
};
