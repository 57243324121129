import { Container, Stack, Typography, hexToRgb, useTheme } from '@mui/material';
import { TFormData, TFormField, TJourneyConfig } from '../../types';
import { replacePlaceholders } from '../../formHandling';
import { formatToMoney } from '../../utils/common';

export type QuoteBoxVariants = 'DEFAULT' | 'MonthlyWordingMinimal' | 'MonthlyWordingFull';

export interface PCQuoteData {
  firstCollectionAmount: number;
  recurringPayment: number;
  creditCharge: number;
  totalRepayable: number;
  facilityFeeAmount: number;
  numberOfCollections: number;
  annualPercentageRate: number;
  totalAmountPayable: string;
  subsequentNumOfCollections: number;
}

export default function QuoteBox(props: {
  field: TFormField;
  config: TJourneyConfig;
  formData: TFormData;
  showDeposit?: boolean;
}) {
  const { variant = 'DEFAULT', lineItems } = props?.field;

  const isMonthly = props?.formData?.values?.paymentFrequency === 'MONTHLY';

  const quoteData = props?.formData?.values?.MTA ?? props?.formData?.values?.quote;

  const premium = isMonthly
    ? props?.showDeposit
      ? quoteData?.deposit
      : quoteData?.monthlyPremium
    : quoteData?.premium;

  const pclQuoteData: PCQuoteData = quoteData?.pcQuote;

  const premiumText = props?.field?.title?.length
    ? props.field.title
    : isMonthly
      ? 'Monthly payment'
      : 'Annual payment';

  return (
    <Container
      style={{
        border: '2px solid #E9EAFF',
        backgroundColor: '#ffff',
        padding: 24,
        borderRadius: 8,
      }}
    >
      <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
        <Stack>
          <Typography fontSize={18} fontWeight={700} color={'#464C5E'}>
            {premiumText}
          </Typography>
          <p
            style={{
              fontSize: 14,
              color: '#464C5E',
            }}
          >
            {props?.field?.subtitle}
          </p>
        </Stack>
        <Typography color={'primary'} fontSize={24} fontWeight={700}>
          £{formatToMoney(premium)}
        </Typography>
      </Stack>

      {['MonthlyWordingFull', 'MonthlyWordingMinimal'].includes(variant) && (
        <>
          <div
            style={{
              margin: '20px 0 20px 0',
              height: '1px',
              width: '100%',
              backgroundColor: '#E9EAFF',
            }}
          />
          <MonthlyWordingVariant
            variant={variant as QuoteBoxVariants}
            deposit={quoteData?.deposit}
            firstPayment={pclQuoteData?.firstCollectionAmount}
            numOfRecurringPayments={pclQuoteData?.numberOfCollections}
            recurringPaymentAmount={pclQuoteData?.recurringPayment}
            facilityFee={pclQuoteData?.facilityFeeAmount}
            APR={pclQuoteData?.annualPercentageRate}
            totalAmountPayable={pclQuoteData?.totalAmountPayable}
          />
        </>
      )}

      {lineItems && variant === 'DEFAULT' && lineItems.length > 0 && (
        <Stack>
          <div
            style={{
              margin: '20px 0 20px 0',
              height: '1px',
              width: '100%',
              backgroundColor: '#E9EAFF',
            }}
          />
          {lineItems.map((lineItem, index) => (
            <Stack
              key={`line-item-${index}`}
              marginBottom={1}
              direction={'row'}
              justifyContent={'space-between'}
              fontSize={16}
              fontWeight={lineItem?.isBold ? 600 : 400}
              color={'#464C5E'}
            >
              <span>{replacePlaceholders(props.formData.values, lineItem.label)}</span>
              <span>{replacePlaceholders(props.formData.values, lineItem.value)}</span>
            </Stack>
          ))}
        </Stack>
      )}
    </Container>
  );
}

interface MonthlyVariantProps {
  variant: QuoteBoxVariants;
  deposit: number;
  firstPayment: number;
  numOfRecurringPayments: number;
  recurringPaymentAmount: number;
  facilityFee: number;
  APR: number;
  totalAmountPayable: string;
}
const MonthlyWordingVariant = ({
  variant,
  deposit,
  firstPayment,
  numOfRecurringPayments,
  recurringPaymentAmount,
  facilityFee,
  APR,
  totalAmountPayable,
}: MonthlyVariantProps) => {
  return (
    <Typography variant="body1" color={'#464C5E'} fontFamily={'Inter'} fontSize={16} fontWeight={400}>
      {variant === 'MonthlyWordingMinimal' && (
        <>
          Deposit of <b>£{deposit}</b> today followed by 1 instalment of <b>£{firstPayment}</b> and{' '}
          <b>{numOfRecurringPayments - 1}</b> monthly instalments of <b>£{recurringPaymentAmount}</b>
        </>
      )}
      {variant === 'MonthlyWordingFull' && (
        <>
          Deposit of <b>£{deposit}</b> today followed by {numOfRecurringPayments} monthly payments. <br />1 instalment of{' '}
          <b>£{firstPayment}</b> (including facility fee of <b>£{facilityFee}</b>)<br />
          {numOfRecurringPayments - 1} instalments of <b>£{recurringPaymentAmount}</b>
          <br />
          <br />
          Total amount payable = <b>£{totalAmountPayable}</b> (including a deposit of £{deposit}, {APR}% interest and
          facility fee of £{facilityFee})
        </>
      )}
    </Typography>
  );
};

