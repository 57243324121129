// =================================================================================================
// Very simple toggle switch for e.g. confirming details
// =================================================================================================

import { Stack, Switch } from "@mui/material";
import { TFormField, TFieldChangeHandler, TJourneyConfig } from "../../types";
import { DialogBox } from "../DialogBox";
import { useState } from "react";

// =================================================================================================
// Main component
// =================================================================================================

export const SwitchField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
  config: TJourneyConfig;
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  const toggleDialog = () => {
    setDialogOpen(!dialogOpen);
  };

  const { field, value, changeHandler } = props;
  const onValue = field.switchParams?.onValue ?? true;
  const offValue = field.switchParams?.offValue ?? false;

  return (
    <div>
      <Stack direction="row" alignItems="flex-start">
        <Switch
          id={field.name}
          disabled={field.disabled}
          checked={value === onValue}
          onChange={(_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            changeHandler(field, checked ? onValue : offValue);
          }}
        />
        <label
          style={{
            marginTop: 7,
            cursor: field.disabled ? "default" : "pointer",
          }}
          htmlFor={field.name}
        >
          {field.variant === "termsOfUse" ? (
            <span style={{ fontSize: 14 }}>I have read and agree to the statement above.</span>
          ) : (
            field.title
          )}
        </label>
      </Stack>
    </div>
  );
};
