import { Accordion, AccordionDetails, AccordionSummary, Button, Stack, useTheme } from '@mui/material';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useGroupsField } from '../../CollapsableSectionsContext';
import { validateField } from '../../fieldValidation';
import { checkDependents, initDependencies } from '../../formHandling';
import {
  IStringIndex,
  TBranding,
  TFieldChangeHandler,
  TFormData,
  TFormField,
  TJourneyConfig,
  TJourneyMode,
} from '../../types';
import { renderFields } from './fields';
import { Menu } from '@mui/icons-material';
import { allRequiredFilled, getEnvParams, getPreviouseGroupField, hexToRGBA, unAnsweredFields } from '../../utils';
import { capitalizeFirstLetter } from '../../utils/common';
import CustomAlert from './CustomAlert';

interface AccordionSectionProps {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
  config: TJourneyConfig;
  formData: TFormData;
  validations?: any;
  disabled?: boolean;
  mode: TJourneyMode;
  branding: TBranding;
}

const { mode: journeyMode } = getEnvParams();

export default function AccordionSectionField({
  changeHandler,
  config,
  field,
  value,
  formData,
  validations,
  disabled,
  mode,
  branding,
}: AccordionSectionProps) {
  const [unAnswered, setUnAnswered] = useState<null | { question: string }[]>(null);
  const [sectionUnlocked, setsectionUnlocked] = useState(false);
  const [hasBlockersVisible, setHasBlockersVisible] = useState<boolean>(false);

  const fields = field?.fields ?? [];
  const theme = useTheme();
  const groupRef = useRef<HTMLDivElement>(null);
  const { closeAccordion, groupIds, isOpen, openAccordion, markCompleted, completed, markUnCompleted } =
    useGroupsField();
  const brandingColor = theme?.palette?.primary?.main;
  const brandingForegroundColor = hexToRGBA(brandingColor, 0.1);

  const currentIdx = groupIds?.indexOf(field.name);

  const localChangeHandler: TFieldChangeHandler = (field: TFormField, value: any) => {
    const localFormData = { ...formData };

    localFormData.values[field.name] = value;
    localFormData.validations[field.name] = validateField(field, value, localFormData.values);
    checkDependents(field, fields, localFormData.values, formData.validations,config,mode);
    changeHandler(field, value);
    // checkDependents(field, fields, localFormData.values, formData.validations);

    const hasBlockers = fields.some(field => field.type === "blocker" && field.hidden === false);
    setHasBlockersVisible(hasBlockers);
  };

  useEffect(() => {
    setUnAnswered(null);
    const isTestMode = ['TEST'].includes(journeyMode ?? mode ?? '');
    const previousFields = groupIds?.slice(0, currentIdx);

    const previousFieldsComplete = previousFields?.every((prevField) => {
      const fieldConfig = getPreviouseGroupField(config, prevField);
      return allRequiredFilled(formData, fieldConfig?.fields);
    });
    const markedAsComplete = isTestMode ? true : completed?.[currentIdx!];
    const isUnlocked =
      isTestMode || (previousFieldsComplete && ((isFinal && isOpen(groupIds?.at(-1) ?? '')) || markedAsComplete));
    setsectionUnlocked(isUnlocked ?? false);
  }, [formData.values]);

  useLayoutEffect(() => {
    if (fields) {
      initDependencies(fields, journeyMode ?? mode);
      const validations: IStringIndex<boolean> = {};
      for (let f of fields) {
        const valid = validateField(f, formData.values[f.name], formData);
        validations[f.name] = valid;
        checkDependents(f, fields, formData.values, formData.validations,config,mode);
      }
      if (['MTA','RESUME'].includes(mode)) {
        const completed = allRequiredFilled(formData, fields);
        if (completed && currentIdx !== undefined) {
          markCompleted?.(currentIdx);
        }
      }
    }

    return () => {
      closeAccordion();
    };
  }, [currentIdx, fields]);

  const handleNext = () => {
    const hasUnAnsweredFields = unAnsweredFields(formData, fields);
    if (hasUnAnsweredFields && hasUnAnsweredFields?.length > 0) {
      setUnAnswered(hasUnAnsweredFields);
      return;
    } else {
      setUnAnswered(null);
    }
    closeAccordion();
    if (currentIdx !== undefined && currentIdx !== -1 && groupIds && currentIdx + 1 <= groupIds.length) {
      markCompleted?.(currentIdx);
      setsectionUnlocked(true);
      groupRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      openAccordion(groupIds[currentIdx + 1]);
    }
  };

  const isFinal = groupIds?.at(-1) === field?.name;

  const lastField = field?.fields?.at(-1)

  // because of UX guys, we need to do stupid stuff like this
  const btnHidden = (lastField?.type === "vehicle" && !formData?.values[lastField.name]) || isFinal

  const isFieldsLocked = formData.values?.quote?.lockFields;
  const isPaymentSucceeded = formData.values?.paymentIntent?.status === 'succeeded';
  const isPaymentPending = formData.values?.paymentIntent?.status === "requires_payment_method"
  const isQuoteStateQuote = formData.values?.quote?.state === 'QUOTE';
  const disableFields = isQuoteStateQuote && (isFieldsLocked || isPaymentSucceeded || isPaymentPending) //mode !== 'MTA' && formData?.values?.paymentIntent?.status === 'succeeded';

  const handleToggle = () => {
    if (isOpen(field.name)) {
      if (!allRequiredFilled(formData, field?.fields)) {
        return;
      }
      closeAccordion();
      if (currentIdx !== undefined && currentIdx !== -1 && groupIds && groupIds.length <= currentIdx + 1) {
        groupRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        openAccordion(groupIds[currentIdx + 1]);
      }
    } else {
      groupRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
      openAccordion(field.name);
    }
  };
  const getAccordionSummaryColors = () => {
    const colors = {
      text: '',
      bg: '',
    };
    const opened = isOpen(field.name);
    if (opened) {
      colors.bg = brandingColor;
      colors.text = '#ffffff';
    } else if (!opened && !sectionUnlocked) {
      colors.bg = '#98A2B3';
      colors.text = 'white';
    } else {
      colors.bg = brandingForegroundColor;
      colors.text = brandingColor;
    }

    return colors;
  };

  return (
    <Accordion
      id={field.name}
      ref={groupRef}
      disabled={!sectionUnlocked}
      expanded={isOpen(field.name)}
      onChange={handleToggle}
      disableGutters
      style={{
        cursor: 'pointer',
        backgroundColor: 'transparent',
        boxShadow: 'none',
        marginBottom: isOpen(field.name) ? 16 : 0,
      }}
    >
      <AccordionSummary
        expandIcon={
          !isOpen(field.name) && sectionUnlocked ? (
            <Menu sx={{ color: getAccordionSummaryColors().text as any }} />
          ) : (
            <></>
          )
        }
        style={{
          textTransform: 'none',
          opacity: 100,
          fontWeight: 600,
          borderRadius: 10,
          padding: '5px 20px 5px 20px',
          color: getAccordionSummaryColors().text,
          backgroundColor: getAccordionSummaryColors().bg,
        }}
        // expandIcon={<GiHamburgerMenu size={20} color={getAccordionSummaryColors().text} />}
      >
        {capitalizeFirstLetter(field.title ?? '')}
      </AccordionSummary>

      <AccordionDetails style={{ marginTop: 30, padding: 0, display: 'flex', flexDirection: 'column', gap: 20 }}>
        {field.fields &&
          (renderFields(
            config,
            field.fields,
            formData,
            localChangeHandler,
            disableFields,
            journeyMode ?? mode,
          ) as any)}
      </AccordionDetails>
      {/*  */}
      {unAnswered && unAnswered?.length && (
        <CustomAlert
          sx={{
            marginTop: 2,
          }}
          severity="error"
          title="The following questions are not answered"
        >
          <ul
            style={{
              paddingLeft: 20,
            }}
          >
            {unAnswered?.map((item, index) => <li>{item.question}</li>)}
          </ul>
        </CustomAlert>
      )}
      {!btnHidden && !hasBlockersVisible && (
        <Stack paddingTop={2} alignItems={'end'} alignContent={'end'}>
          <Button sx={{ textTransform: 'capitalize' }} onClick={handleNext} variant="contained" color="primary">
            {field?.btnText ?? 'Continue'}
          </Button>
        </Stack>
      )}
    </Accordion>
  );
}

