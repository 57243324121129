import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';
import React, { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import { driverExcess, IStringIndex, TFieldChangeHandler, TFormData, TFormField, TJourneyConfig } from '../../types';
import { OptionField } from './OptionField';
import { renderFields } from './fields';
import { IoMdClose } from 'react-icons/io';
import { JourneyContext } from '../../JourneyContext';
import { getFieldOptions, replacePlaceholders } from '../../formHandling';
import { FiInfo } from 'react-icons/fi';
import _ from 'lodash';
import { patchQuote } from '../../apiCalls';
import { formatToMoney } from '../../utils/common';
import { AppliedContext } from '../../AppliedContext';

function getValueAtPath<T>(data: T, pathString: string): any {
  const path = pathString.match(/\${(.*?)}/)?.[1];
  if (!path) {
    return pathString;
  }
  return _.get(data, path);
}

export default function ExcessBreakdownField(props: {
  field: TFormField;
  value: any;
  config: TJourneyConfig;
  formData: TFormData;
  changeHandler: TFieldChangeHandler;
  setFormData?: Dispatch<SetStateAction<TFormData | null>>;
  setBusy?: Dispatch<SetStateAction<boolean>>;
  busy: boolean;
}) {
  const { field, value, changeHandler, formData, config, setBusy, setFormData } = props;
  const [updating, setUpdating] = useState(false);
  const theme = useTheme();
  const params = field?.excessParams;
  const journeyContext = useContext(JourneyContext);
  const { selectedAppliedQuote, updateSelectedQuote } = useContext(AppliedContext);
  const localOptions = getFieldOptions(field, journeyContext.formData);
  const excessBreakdown: driverExcess[] = getValueAtPath(formData?.values, params?.driverBreakdown ?? '');
  const primaryVoluntaryExcess =
    excessBreakdown?.find((excess) => excess.prn === '1')?.excesses.find((type) => type.name === 'Voluntary')?.value ??
    0;

  useEffect(() => {
    if (selectedAppliedQuote?.voluntaryExcessAmountOverriden) {
      changeHandler(field, selectedAppliedQuote?.voluntaryExcessAmountApproved);
    }
  }, [selectedAppliedQuote]);

  const localChangeHandler = async (event: any) => {
    const newVal = event.target.value;

    changeHandler(field, newVal);

    if (params?.updateQuoteOnChange) {
      const cleanValues: IStringIndex<any> = {};
      for (let [fieldName, fieldValue] of Object.entries(formData.values)) {
        if (typeof fieldValue === 'string') {
          cleanValues[fieldName] = fieldValue.replace(/\s+/g, ' ').trim();
        } else {
          cleanValues[fieldName] = fieldValue;
        }
      }
      cleanValues[field.name] = newVal;

      // await handleSubmit?.(null, cleanValues,true);

      // If API is not configured, or we are editing, just update journeyContext and return
      if (!config?.APIs?.patch || journeyContext.mode === 'EDIT') {
        setFormData?.({
          values: cleanValues,
          validations: { ...formData.validations },
        });
        return;
      }

      setBusy?.(true);
      setUpdating(true);

      // MTA preview
      try {
        if (journeyContext.mode === 'MTA') {
          const apiCopy = JSON.parse(JSON.stringify(config.APIs?.mta));
          apiCopy.payload.quote.journey = 'mta_preview';
          const result = await patchQuote(config, apiCopy, cleanValues);
          if (result === null || result?.errorMessage || !Array.isArray(result) || result.length !== 1) {
            console.error({
              body: 'There was a problem previewing the MTA, please wait a moment and try again.',
              severity: 'error',
            });
          } else {
            journeyContext.setFormData({
              values: {
                ...cleanValues,
                MTA: result[0],
              },
              validations: { ...formData.validations },
            });
          }
        } else {
          const appliedTransaction = params?.isApplied ? 'quoteDetails' : undefined;
          config.APIs.patch.payload.quote.isQuote = true;
          // Actual quote update
          const result = await patchQuote(config, config!.APIs!.patch, cleanValues, appliedTransaction);
          if (result === null || result?.errorMessage || !Array.isArray(result) || result.length !== 1) {
            console.error({
              body: 'There was a problem updating your quote, please wait a moment and try again.',
              severity: 'error',
            });
          } else {
            setFormData?.({
              values: {
                ...cleanValues,
                quote: result[0],
              },
              validations: { ...formData.validations },
            });
            if (params?.isApplied) {
              updateSelectedQuote({ ...selectedAppliedQuote, ...result?.at(0)?.appliedQuoteDetails });
            }
          }
        }
      } catch (error: any) {
        console.error(error);
      } finally {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setBusy?.(false);
        setUpdating(false);
      }
    }
  };

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={updating}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Stack
        spacing={2}
        style={{
          border: '2px solid #E9EAFF',
          backgroundColor: '#ffff',
          padding: 24,
          borderRadius: 8,
        }}
      >
        <div>
          <p className="fieldLabel">{`Voluntary excess`}</p>
          <FormControl fullWidth required={field.required}>
            <InputLabel id={field.label + '-label'}>{'Amount'}</InputLabel>
            <Select
              disabled={field.disabled}
              labelId={field.label + '-label'}
              label={'amount'}
              value={value ?? ''}
              onChange={localChangeHandler}
              MenuProps={{
                style: {
                  maxHeight: 400,
                },
              }}
            >
              {localOptions.map((option, index) => {
                return (
                  <MenuItem
                    sx={{ whiteSpace: 'pre-wrap', maxWidth: '600px' }}
                    key={index}
                    value={option.value}
                    disabled={option.disabled}
                  >
                    {option.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </div>
        {selectedAppliedQuote?.voluntaryExcessAmountOverriden && (
          <Alert
            severity="warning"
            sx={{ border: '2px solid #FFEFC6', borderRadius: '8px', marginTop: '8px' }}
            icon={<ErrorOutlineOutlinedIcon />}
          >
            The voluntary excess you selected is not available with this insurer. It has been adjusted to the value
            shown above, please ensure this amount remains affordable.
          </Alert>
        )}
        <Box>
          {excessBreakdown
            ?.find((driver) => driver?.prn === '1')
            ?.excesses.map(
              (excess, index, parent) =>
                excess.value !== 0 &&
                excess.name !== 'Voluntary' && (
                  <Box key={excess.name}>
                    <Box display={'flex'} justifyContent={'space-between'}>
                      <Typography component={'span'} fontSize={'18px'} fontWeight={'600'}>
                        {excess.name}
                      </Typography>
                      {excess.name === 'Windscreen Replacement' && (
                        <Typography fontWeight={'700'} color={theme.palette.primary.main}>
                          £{excess.value}
                        </Typography>
                      )}
                    </Box>
                    {excess.name !== 'Windscreen Replacement' && (
                      <>
                        <Box display={'flex'} justifyContent={'space-between'} paddingTop={'8px'}>
                          <Typography>Compulsory</Typography>
                          <Typography fontWeight={'700'}>£{excess.value}</Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} paddingTop={'4px'}>
                          <Typography>Voluntary</Typography>
                          <Typography fontWeight={'700'}>£{primaryVoluntaryExcess}</Typography>
                        </Box>
                        <Box display={'flex'} justifyContent={'space-between'} paddingTop={'4px'} fontWeight={'600'}>
                          <Typography>Total excess</Typography>
                          <Typography fontWeight={'700'} color={theme.palette.primary.main}>
                            £{excess.value + primaryVoluntaryExcess}
                          </Typography>
                        </Box>
                        {index !== parent?.length - 2 && (
                          <Box border={'1px solid'} borderColor={theme.palette.grey[200]} margin={'16px 0px'} />
                        )}
                      </>
                    )}
                  </Box>
                ),
            )}
        </Box>
        {excessBreakdown?.length > 1 && (
          <Stack paddingTop={2} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <ExcessModal breakdown={excessBreakdown} theme={theme} currentQuote={selectedAppliedQuote} />
          </Stack>
        )}
      </Stack>
    </>
  );
}

const ExcessModal = ({
  theme,
  breakdown,
  currentQuote,
}: {
  theme: Theme;
  breakdown?: driverExcess[];
  currentQuote: any;
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Button onClick={() => setOpen(true)} sx={{ textTransform: 'none', fontWeight: 600 }}>
        View additional driver excess
      </Button>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'background.paper',
            border: '2px solid #E9EAFF',
            borderRadius: 4,
            boxShadow: 24,
            p: 4,
            maxHeight: '94%',
            overflowY: 'auto',
          }}
        >
          <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Typography fontSize={30} fontFamily={'Poppins'} fontWeight={600} color={'#1C1D21'}>
              Additional Driver Excess
            </Typography>
            <IconButton aria-label="close" onClick={() => setOpen(false)}>
              <IoMdClose size={24} />
            </IconButton>
          </Stack>
          <div
            style={{
              marginTop: '20px',
              height: '1px',
              width: '100%',
              backgroundColor: '#E9EAFF',
            }}
          />

          {breakdown
            ?.filter((driver) => driver.prn != '1')
            .map((driver) => (
              <>
                <Typography
                  color={theme.palette.primary.main}
                  fontSize={18}
                  fontFamily={'sans-serif'}
                  fontWeight={700}
                  padding={'20px 0'}
                >
                  {`${currentQuote.drivers.at(+driver.prn - 1).firstName} ${currentQuote.drivers.at(+driver.prn - 1).lastName}`}
                </Typography>
                <Box>
                  {driver.excesses.map(
                    (excess, index, parent) =>
                      excess.value != 0 &&
                      excess.name != 'Voluntary' && (
                        <Box key={excess.name}>
                          <Box display={'flex'} justifyContent={'space-between'}>
                            <Typography component={'span'} fontSize={'18px'} fontWeight={'600'}>
                              {excess.name}
                            </Typography>
                            {excess.name === 'Windscreen Replacement' && (
                              <Typography fontWeight={'700'} color={theme.palette.primary.main}>
                                £{excess.value}
                              </Typography>
                            )}
                          </Box>
                          {excess.name != 'Windscreen Replacement' && (
                            <>
                              <Box display={'flex'} justifyContent={'space-between'} paddingTop={'8px'}>
                                <Typography>Compulsory</Typography>
                                <Typography fontWeight={'700'}>£{excess.value}</Typography>
                              </Box>
                              <Box display={'flex'} justifyContent={'space-between'} paddingTop={'4px'}>
                                <Typography>Voluntary</Typography>
                                <Typography fontWeight={'700'}>
                                  £{parent.find((excess) => excess.name === 'Voluntary')?.value}
                                </Typography>
                              </Box>
                              <Box
                                display={'flex'}
                                justifyContent={'space-between'}
                                paddingTop={'4px'}
                                fontWeight={'600'}
                              >
                                <Typography>Total excess</Typography>
                                <Typography fontWeight={'700'} color={theme.palette.primary.main}>
                                  £{excess.value + (parent.find((excess) => excess.name === 'Voluntary')?.value ?? 0)}
                                </Typography>
                              </Box>
                              {index != parent.length - 2 && (
                                <Box border={'1px solid'} borderColor={theme.palette.grey[200]} margin={'16px 0px'} />
                              )}
                            </>
                          )}
                        </Box>
                      ),
                  )}
                </Box>
              </>
            ))}
        </Box>
      </Modal>
    </>
  );
};
