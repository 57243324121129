export const VehicleLocationWhenNotInUse = [
  {
    "label": "Car Park",
    "value": "5"
  },
  {
    "label": "Carport",
    "value": "8"
  },
  {
    "label": "Garage",
    "value": "1"
  },
  {
    "label": "Kept On Private Property - Unsecure",
    "value": "R"
  },
  {
    "label": "Kept On Public Road",
    "value": "3"
  },
  {
    "label": "Resident Parking - Gated/Secure",
    "value": "N"
  },
  {
    "label": "Resident Parking - Not Secure",
    "value": "P"
  }
]