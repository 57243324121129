export const AffinityGroup = [
  {
    label: "No Membership",
    value: "701",
  },
  {
    label: "Caravan Club",
    value: "702",
  },
  {
    label: "Camping And Caravan Club",
    value: "703",
  },
  {
    label: "Yes",
    value: "700",
  },
  {
    label: "Austin 7",
    value: "773",
  },
  {
    label: "Austin 10",
    value: "704",
  },
  {
    label: "Austin A 30 A 35",
    value: "705",
  },
  {
    label: "Rolls Royce Enthusiasts Club",
    value: "706",
  },
  {
    label: "Bentley Drivers Club",
    value: "707",
  },
  {
    label: "Stag Owners Club",
    value: "708",
  },
  {
    label: "Austin Counties",
    value: "709",
  },
  {
    label: "Alvis",
    value: "710",
  },
  {
    label: "Morris Register",
    value: "711",
  },
  {
    label: "Ac Owners Club",
    value: "712",
  },
  {
    label: "Ajs And Matchless Club",
    value: "713",
  },
  {
    label: "Ariel Owners Club",
    value: "714",
  },
  {
    label: "Aston Martin Owners Club",
    value: "715",
  },
  {
    label: "Austin A 40 Farina Owner Club",
    value: "716",
  },
  {
    label: "Beauford Owners Club",
    value: "717",
  },
  {
    label: "Bmw Car Club",
    value: "718",
  },
  {
    label: "Bsa Goldstar Owners Club",
    value: "719",
  },
  {
    label: "Citroen 2 Cv Club",
    value: "720",
  },
  {
    label: "Classic Corvette Club",
    value: "721",
  },
  {
    label: "Classic And Sports Car Club",
    value: "722",
  },
  {
    label: "Club Peugeot",
    value: "723",
  },
  {
    label: "Club Triumph",
    value: "724",
  },
  {
    label: "Daimler And Lanchester Club",
    value: "725",
  },
  {
    label: "Delorean Owners Club",
    value: "726",
  },
  {
    label: "Farm Machinery Preservation Society",
    value: "727",
  },
  {
    label: "Ferrari Owners Club",
    value: "728",
  },
  {
    label: "Francis Barnett Owners Club",
    value: "729",
  },
  {
    label: "Gay Classic Car Club",
    value: "730",
  },
  {
    label: "Jaguar Etype Club",
    value: "731",
  },
  {
    label: "Jaguar Enthusiasts Club",
    value: "732",
  },
  {
    label: "Jawa Cz Owners Club",
    value: "733",
  },
  {
    label: "Marco Owners Club",
    value: "734",
  },
  {
    label: "Mercedes Owners Club",
    value: "735",
  },
  {
    label: "Ford Model A Club",
    value: "736",
  },
  {
    label: "Mg Midget And Sprite Club",
    value: "737",
  },
  {
    label: "Mg Octogan Car Club",
    value: "738",
  },
  {
    label: "Military Vehicle Trust",
    value: "739",
  },
  {
    label: "Mopar Muscle Association",
    value: "740",
  },
  {
    label: "Morgan Sports Car Club",
    value: "741",
  },
  {
    label: "Morris Minor Owners Club",
    value: "742",
  },
  {
    label: "Moto Guzzi Club",
    value: "743",
  },
  {
    label: "Mv Agusta Owners Club",
    value: "744",
  },
  {
    label: "Mx 5 Owners Club",
    value: "745",
  },
  {
    label: "National Association Of Street Clubs",
    value: "746",
  },
  {
    label: "Norton Owners Club",
    value: "747",
  },
  {
    label: "Porsche Club Gb",
    value: "748",
  },
  {
    label: "Pre 50 American Club",
    value: "749",
  },
  {
    label: "Reliant Sabre Scimitar Owners Club",
    value: "750",
  },
  {
    label: "Rover P 4 Drivers Guild",
    value: "751",
  },
  {
    label: "Rover P 6 Club",
    value: "752",
  },
  {
    label: "Rs Owners Club",
    value: "753",
  },
  {
    label: "South Eastern Vintage And Classic Vehicle Club",
    value: "754",
  },
  {
    label: "Split Screen Van Club",
    value: "755",
  },
  {
    label: "Standard Motor Club",
    value: "756",
  },
  {
    label: "Steam Car Club",
    value: "757",
  },
  {
    label: "Sunbeam Motorcycle Club",
    value: "758",
  },
  {
    label: "Talbot Owners Club",
    value: "759",
  },
  {
    label: "Trident And Rocket Owners Club",
    value: "760",
  },
  {
    label: "Triumph Dolomite Club",
    value: "761",
  },
  {
    label: "Triumph Owners Motorcycle Club",
    value: "762",
  },
  {
    label: "Triumph Pre 1940 S Owners Club",
    value: "763",
  },
  {
    label: "Uk Street Machines",
    value: "764",
  },
  {
    label: "Vauxhall Viva Owners Club",
    value: "765",
  },
  {
    label: "Velocette Owners Club",
    value: "766",
  },
  {
    label: "Veteran Car Club",
    value: "767",
  },
  {
    label: "Vincent Owners Club",
    value: "768",
  },
  {
    label: "Vintage Japanese Motorcycle Club",
    value: "769",
  },
  {
    label: "Vintage Sports Car Club",
    value: "770",
  },
  {
    label: "Lamborghini Owners Club",
    value: "771",
  },
  {
    label: "Other",
    value: "772",
  },
  {
    label: "Stafford Oct",
    value: "774",
  },
  {
    label: "Copdock Mc",
    value: "775",
  },
  {
    label: "Nec Nov",
    value: "776",
  },
  {
    label: "Steve S Roller Club",
    value: "1234",
  },
  {
    label: "Ad Flux New",
    value: "12345",
  },
];
