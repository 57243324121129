export const SecurityDeviceFittedBy = [
  {
    "label": "Device Manufacturer Approved Fitter",
    "value": "1"
  },
  {
    "label": "DIY",
    "value": "2"
  },
  {
    "label": "Insurer Approved Fitter",
    "value": "3"
  },
  {
    "label": "Other",
    "value": "5"
  },
  {
    "label": "Unknown",
    "value": "6"
  },
  {
    "label": "Vehicle Manufacturer",
    "value": "4"
  },
  {
    "label": "Vehicle Manufacturer Franchised Dealer",
    "value": "7"
  },
  {
    "label": "VSIB Accredited Installer",
    "value": "8"
  }
]