export const ClaimType = [
  {
    "label": "Accident",
    "value": "A"
  },
  {
    "label": "Accident Damage",
    "value": "B"
  },
  {
    "label": "Chemical",
    "value": "C"
  },
  {
    "label": "Collision",
    "value": "L"
  },
  {
    "label": "Collision with Pedestrian",
    "value": "J"
  },
  {
    "label": "Fire",
    "value": "F"
  },
  {
    "label": "Flood",
    "value": "D"
  },
  {
    "label": "Hit by Third Party",
    "value": "G"
  },
  {
    "label": "Hit Third Party",
    "value": "E"
  },
  {
    "label": "Loss Of Keys",
    "value": "K"
  },
  {
    "label": "Lost Control",
    "value": "P"
  },
  {
    "label": "Malicious Damage As A Result Of Theft",
    "value": "M"
  },
  {
    "label": "Malicious Damage Claim",
    "value": "Y"
  },
  {
    "label": "Multi-Vehicle Collision",
    "value": "N"
  },
  {
    "label": "Parked",
    "value": "H"
  },
  {
    "label": "Riot",
    "value": "R"
  },
  {
    "label": "Storm",
    "value": "S"
  },
  {
    "label": "Theft of Vehicle",
    "value": "Z"
  },
  {
    "label": "Unknown",
    "value": "U"
  },
  {
    "label": "Vandalism",
    "value": "V"
  },
  {
    "label": "Windscreen/Glass",
    "value": "W"
  }
]