import { Container, Stack, ThemeProvider } from '@mui/material';
import { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { TFormField, TJourneyConfig } from './types';
import { defaultBrand, defaultTheme } from './defaultBranding';
import { activeJourneyContext } from './activeJourneyContext';
import { Journey } from './components/Journey';
import { renderFields } from './components/FormFields/fields';
import { GroupsFieldProvider } from './CollapsableSectionsContext';

type TMessage = {
  source: string;
  type: string;
  data?: any;
};

const _SELF = 'WLJ Editor';
const _OTHER = 'PB UI';

function debug(...args: any[]): void {
  const colorStyle = 'color: cyan';
  console.log(`%c${_SELF} ${args.join(' ')}`, colorStyle);
}

const sendMessage = (message: Omit<TMessage, 'source'>) => {
  window.parent.postMessage({ source: _SELF, type: message.type, data: message.data }, '*');
};

const SizeChangeObserver: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const observer = useRef<ResizeObserver | null>(null);
  const elementRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    observer.current = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const { height } = entry.contentRect;
        sendMessage({ type: 'fieldResize', data: Math.ceil(height) });
      });
    });

    if (elementRef.current) {
      observer.current.observe(elementRef.current);
    }

    return () => {
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);

  return (
    <div ref={elementRef} style={{ width: '100%', height: '100%' }}>
      {children}
    </div>
  );
};

export default SizeChangeObserver;

export const JourneyEditApp = () => {
  const [isEditing, setIsEditing] = useState(true);
  const [branding, setBranding] = useState(defaultBrand);
  const [activeJourney, setActiveJourney] = useState<TJourneyConfig | null>(null);
  const [activeField, setActiveField] = useState<TFormField | null>(null);
  const [theme, setTheme] = useState(defaultTheme);

  useEffect(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.source !== _OTHER) {
        return;
      }

      const received: TMessage = {
        source: event.data.source,
        type: String(event.data.type ?? 'unknown'),
        data: event.data.data ?? null,
      };
      // debug(`received from ${_OTHER}:`, JSON.stringify(received, null, 2));

      switch (received.type) {
        case 'setJourney':
          setActiveJourney(received.data);
          break;
        case 'setField':
          setActiveField(received.data);
          break;
        case 'isEditing':
          setIsEditing(received.data);
          break;
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  if (activeField) {
    return (
      <ThemeProvider theme={theme}>
        <GroupsFieldProvider groupIds={[activeField.name]}>
          <SizeChangeObserver>
            <Stack spacing={3} sx={{ padding: 3 }}>
              {renderFields(
                {
                  product: { title: 'test' },
                  sections: [],
                  info: {
                    benefits: ['Test benefit 1', 'Test benefit 2'],
                    antiBenefits: ['Test anti-benefit 1', 'Test anti-benefit 2'],
                  },
                },
                [activeField],
                { values: {}, validations: {} },
                () => {},
                false,
                'EDIT',
                )}
            </Stack>
          </SizeChangeObserver>
        </GroupsFieldProvider>
      </ThemeProvider>
    );
  }
  
  const groupFields = activeJourney?.sections?.flatMap(s => s.fields.filter(f => f.type === "group"));

  return activeJourney ? (
    <activeJourneyContext.Provider value={activeJourney}>
      <ThemeProvider theme={theme}>
        <GroupsFieldProvider groupIds={groupFields?.map(f => f?.name) ?? []}>
          <Journey
          productsLoading={false}
            branding={branding}
            config={activeJourney}
            setSuccessToken={() => {}}
            setActiveJourney={setActiveJourney}
            mode={'TEST'}
          />
        </GroupsFieldProvider>
      </ThemeProvider>
    </activeJourneyContext.Provider>
  ) : (
    <Container
      maxWidth="sm"
      sx={{
        backgroundColor: 'white',
        borderRadius: 4,
        padding: 2,
      }}
    >
      No active journey
    </Container>
  );
};

export const Editable = (props: {
  isEditing: boolean;
  children: any;
  item: { type: string; data: any };
  sendMessage: (message: Omit<TMessage, 'source'>) => void;
}) => {
  return (
    <div
      style={props.isEditing ? { border: '1px dashed black', boxSizing: 'border-box', padding: '1px' } : {}}
      onMouseDownCapture={(event) => {
        if (props.isEditing) {
          event.stopPropagation();
          event.preventDefault();
          props.sendMessage({ type: 'itemSelected', data: { ...props.item } });
        }
      }}
    >
      <div style={{ pointerEvents: props.isEditing ? 'none' : 'auto' }}>{props.children}</div>
    </div>
  );
};
