import { InputAdornment, TextField as TextFieldMUI } from "@mui/material";
import { useState } from "react";
import { TFormField } from "../../../types";
import { MAX_MEASUREMENTS } from "./MeasurementsField";

// =================================================================================================
// Metric Measurements
// =================================================================================================
export const MetricHeight = (props: {
  field: TFormField;
  cm: number;
  onChange: (cm: string) => void;
  error: string;
}) => {
  const { field, error } = props;
  const [cm, setCm] = useState(props.cm ? props.cm.toFixed(1) : "");
  return (
    <>
      <p style={{ marginTop: 24 }}>What is your height?</p>
      <TextFieldMUI
        disabled={field.disabled}
        fullWidth
        variant="outlined"
        label="Centimeters"
        InputProps={{
          endAdornment: <InputAdornment position="end">cm</InputAdornment>,
        }}
        value={cm}
        onChange={(event) => {
          setCm(event.target.value);
          props.onChange(event.target.value);
        }}
        required={field.required}
        helperText={error || ""}
        error={error.length > 0 || Number(cm) > MAX_MEASUREMENTS.Metric.CM}
      />
    </>
  );
};
