// =================================================================================================
// Default brand and theme
// =================================================================================================

import { createTheme, ThemeOptions } from '@mui/material/styles';
import { TBranding } from './types';
import { hexToRGBA } from './utils';

export const defaultBrand: TBranding = {
  name: 'Cool Default Brand',
  website: 'https://www.google.com',
  main_color: '#2D40DD',
  full_logo: 'img/branding/cooldefaultbrand.png',
  servicing_email: 'support@email.com',
  servicing_phone: '01234567890',
};

export const V2RenderVersionThemeExtras: ThemeOptions = {
  components: {
    MuiButton: {
      styleOverrides: {
        outlined: {
          borderRadius: 8,
          border: '1px solid #D0D5DD',
          color: '#464C5E',
        },
        contained: {
          borderRadius: 8,
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        sx: {
          bgcolor: 'white',
          borderRadius: '8px',
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        sx: {
          bgcolor: 'white',
          borderRadius: '8px',
        },
      },
    },
    MuiInput: {
      defaultProps: {
        sx: {
          bgcolor: 'white',
          borderRadius: '8px',
        },
      },
    },
  },
};

export const defaultTheme = createTheme({
  ...V2RenderVersionThemeExtras,
  palette: {
    primary: {
      main: defaultBrand.main_color,
    },
    secondary: {
      main: hexToRGBA(defaultBrand.main_color, 0.08),
    },
  },
});

export const tempBranding: TBranding = {
  name: '',
  website: '#',
  main_color: '#3B90CF',
  full_logo: '',
  servicing_email: '',
  servicing_phone: '',
};
