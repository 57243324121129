export const VehicleValueIndicator = [
  {
    "label": "Agreed Value",
    "value": "A"
  },
  {
    "label": "Market Value",
    "value": "M"
  },
  {
    "label": "New For Old",
    "value": "N"
  }
]