import Tick from '@mui/icons-material/Check';
import Close from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Card,
  Chip,
  Grid,
  hexToRgb,
  Stack,
  Typography,
  useTheme
} from '@mui/material';
import { useContext, useState } from 'react';
import { AppliedContext } from '../../AppliedContext';
import { QuoteRequestResponse } from '../../types';
import { formatToMoney } from '../../utils/common';
import { ProductInfoDrawer } from './productInfoDrawer';
import { QuoteListingProps } from './QuoteListingPage';

interface QuoteListingCardProps extends Partial<QuoteListingProps> {
  quote: QuoteRequestResponse;
}

export default function QuoteListingCard({
  formData,
  quote,
  config,
  handleSubmit,
  isSubmitting,
  submitError,
  setFormData,
}: QuoteListingCardProps) {
  const { selectedAppliedQuote } = useContext(AppliedContext);
  const [openDetails, setOpenDetails] = useState(false);
  const theme = useTheme();
  const fgColor = hexToRgb(theme.palette.primary.main)?.replace(')', `, ${0.05})`);

  const paymentFrequency = formData?.values?.paymentFrequency;
  const voluntaryExcess = quote?.voluntaryExcess ?? 0;
  const compulsoryExcess = quote?.compulsoryExcess ?? 0;
  const totalExcess = quote?.totalExcess ?? 0;
  const covers = [
    {
      name: 'Protected no claims',
      covered: quote?.protectedNoClaims,
    },
    {
      name: 'Windscreen',
      covered: quote?.windscreenCover,
    },
  ];

  const handleViewDetails = () => {
    setOpenDetails(true);
  };

  const fallbackImg = `https://placehold.co/128x56/FAFAFA/${theme.palette.primary.main?.replace('#', '')}?text=${quote?.schemeInsurerName}`;

  return (
    <Card
      sx={{
        borderRadius: 2,
        bgcolor: 'white',
        height: 'fit',
        width: '100%',
        alignItems: 'center',
        border:
          quote?.schemeRef === selectedAppliedQuote?.schemeRef
            ? `2px solid ${theme?.palette?.primary?.main}`
            : '2px solid #E9EAFF',
        boxShadow: 'none',
      }}
    >
      <Stack direction={{ xs: 'column', lg: 'row' }}>
        <Grid container spacing={4} alignItems={'center'} justifyContent={'space-around'}>
          {/* Image */}
          <Grid item display={'flex'} direction={'column'} gap={1}>
            {quote?.summaryLogoText && (
              <Chip
                label={quote?.summaryLogoText}
                sx={{ maxWidth: 'fit-content', color: '#464C5E', fontSize: 12, fontWeight: 500, fontFamily: 'Inter' }}
                size="small"
              />
            )}
            <img
              onError={(e: any) => (e.target.src = fallbackImg)}
              src={quote?.logo}
              alt="insurer logo"
              style={{
                objectFit: 'contain',
              }}
              width={120}
            />
          </Grid>
          {/* Benefits & AntiBenefits */}
          <Grid item>
            <Stack direction={'row'} spacing={{ xs: 1, md: 3 }}>
              {covers.map((cover, index) => (
                <Stack direction={'column'} alignItems={'center'}>
                  {cover.covered ? <Tick color="primary" fontSize="small" /> : <Close fontSize="small" />}
                  <Typography
                    width={96}
                    textAlign={'center'}
                    fontSize={14}
                    fontWeight={600}
                    fontFamily={'Inter'}
                    color={'#464C5E'}
                  >
                    {cover.name}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Grid>
          {/* Excess */}
          <Grid item>
          {formData?.values?.coverCode != "03" && (
            <Stack textAlign={'center'} spacing={1}>
              <Typography color={'#464C5E'} fontSize={14} fontWeight={400} fontFamily={'Inter'}>
                Voluntary excess: <b> £{voluntaryExcess}</b>
              </Typography>
              <Typography color={'#464C5E'} fontSize={14} fontWeight={400} fontFamily={'Inter'}>
                Compulsory excess: <b> £{compulsoryExcess}</b>
              </Typography>
              <Typography
                fontSize={14}
                fontWeight={400}
                fontFamily={'Inter'}
                sx={{ paddingY: 0.5, bgcolor: fgColor, borderRadius: 5 }}
                color={'primary'}
              >
                Total excess: <b>£{totalExcess}</b>
              </Typography>
            </Stack>
          )}
          </Grid>
        </Grid>
        {/* Payment Info */}
        <Box
          sx={{ bgcolor: '#FAFAFF' }}
          padding={4}
          marginLeft={'auto'}
          height={'100%'}
          width={{ xs: '100%', lg: 300 }}
        >
          <Stack spacing={0.5} textAlign={'center'} alignItems={'center'}>
            {paymentFrequency?.toLowerCase() === 'monthly' ? (
              <MonthlyPaymentBox quote={quote} />
            ) : (
              <AnnualPaymentBox quote={quote} />
            )}
            <Button
              onClick={handleViewDetails}
              size="small"
              variant="contained"
              color="primary"
              sx={{
                width: 'fit-content',
                textTransform: 'none',
              }}
            >
              View details
            </Button>
          </Stack>
        </Box>

        {config && (
          <ProductInfoDrawer
            setFormData={setFormData}
            submitError={submitError}
            isSubmitting={isSubmitting}
            handleSubmit={handleSubmit}
            quote={quote}
            formData={formData}
            config={config}
            open={openDetails}
            onClose={() => setOpenDetails(false)}
          />
        )}
      </Stack>
    </Card>
  );
}
const MonthlyPaymentBox = ({ quote }: { quote: QuoteRequestResponse }) => {
  return (
    <>
      <Typography fontSize={14} color={'#464C5E'} fontWeight={400} fontFamily={'Inter'}>
        {quote?.pcQuote?.subsequentNumOfCollections} monthly payments
      </Typography>
      <Typography fontSize={24} fontWeight={600} fontFamily={'Poppins'}>
        £{formatToMoney(quote?.pcQuote?.recurringPayment)}
      </Typography>
      <Typography fontSize={14} color={'#464C5E'} fontWeight={400} fontFamily={'Inter'}>
        Deposit: <b>£{formatToMoney(quote?.deposit)}</b>
      </Typography>
      <Typography fontSize={14} color={'#464C5E'} fontWeight={400} fontFamily={'Inter'}>
        First payment: <b>£{formatToMoney(quote?.pcQuote?.firstCollectionAmount)}</b>
      </Typography>
      <Typography fontSize={14} color={'#464C5E'} fontWeight={400} fontFamily={'Inter'}>
        Total payment: <b>£{formatToMoney(quote?.pcQuote?.totalAmountPayable)}</b>
      </Typography>
    </>
  );
};
const AnnualPaymentBox = ({ quote }: { quote: QuoteRequestResponse }) => {
  return (
    <>
      <Typography fontSize={14} color={'#464C5E'} fontWeight={400} fontFamily={'Inter'}>
        Annual Payment
      </Typography>
      <Typography fontSize={24} fontWeight={600} fontFamily={'Poppins'}>
        £<>{formatToMoney(quote?.premiumIncIPT)}</>
      </Typography>
    </>
  );
};
