import { Button, InputAdornment, Stack, TextField } from "@mui/material";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import axios from "axios";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import validator from "validator";
import { JourneyContext } from "../../JourneyContext";
import { TClientPremium, TFieldChangeHandler, TFormField } from "../../types";

const GBP = "£";
const InputProps = {
  startAdornment: <InputAdornment position="start">{GBP}</InputAdornment>,
};

const getPremium = async (
  age: number,
  isSmoker: boolean,
  premium: number,
  sumAssured: number,
  medical: boolean,
  diagnosed12: boolean,
  diagnosed5: boolean
) => {
  try {
    let url = "";
    url = `https://nationalfriendly-api.azurewebsites.net/api/gla?age=${age}&medical=${medical}&diagnosed12=${diagnosed12}&diagnosed5=${diagnosed5}&smokerStatus=${
      isSmoker ? 1 : 0
    }&premium=${premium}&sumAssured=${sumAssured}`;
    const results = await axios.get(url);
    if (results.status === 200) {
      return results.data;
    }
  } catch (e) {
    console.log(e, "<=== NF get premium");
  }
  return null;
};

export const PremiumSumAssuredField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
}) => {
  function usePreviousValue(value: any) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const { field, value, changeHandler } = props;
  const journeyContext = useContext(JourneyContext);
  // Hard code all the things, component only used for one journey
  const coverStartDate = journeyContext.formData.values["coverStartDate"];
  const dateOfBirth = journeyContext.formData.values["dateOfBirth"];
  const isSmoker = journeyContext.formData.values["smokerStatus"] === "smoker";
  const medical = journeyContext.formData.values["answerOptional"] === "Yes";
  const diagnosed12 = journeyContext.formData.values["question1"] === true;
  const diagnosed5 = journeyContext.formData.values["question2"] === true;
  const discount = medical && !diagnosed5 && !diagnosed12;
  // Age calculated from cover start date
  const age = moment(coverStartDate, "DD-MM-YYYY").diff(moment(dateOfBirth, "DD-MM-YYYY"), "years");
  console.log("Age =", age);

  // Messages and errors for input fields
  const [premiumError, setPremiumError] = useState("");
  const [sumError, setSumError] = useState("");
  const [premiumMsg, setPremiumMsg] = useState("");
  const [sumMsg, setSumMsg] = useState("");

  const premiumMinMax =  { min: 20, max: 100 };

  const [chosenPremium, setChosenPremium] = useState(value?.premium || "");
  const [chosenSumAssured, setChosenSumAssured] = useState(value?.sumAssured || "");
  const [isDirty, setIsDirty] = useState(false);

  const prevAge = usePreviousValue(age);
  const prevDiscount = usePreviousValue(discount);
  const prevIsSmoker = usePreviousValue(isSmoker);

  useEffect(() => {
    if (
      (age !== prevAge && prevAge !== undefined) ||
      (discount !== prevDiscount && prevDiscount !== undefined) ||
      (isSmoker !== prevIsSmoker && prevIsSmoker !== undefined)
    ) {
      setChosenPremium("");
      setPremiumError("");
      setPremiumMsg("");
      setChosenSumAssured("");
      setSumError("");
      setSumMsg("");
      setIsDirty(false);
      changeHandler(field, undefined);
    }
  }, [age, prevAge, discount, prevDiscount, isSmoker, prevIsSmoker, field, changeHandler]);

  const [busy, setBusy] = useState(false);

  if ((isSmoker && age > 75) || isNaN(age)) {
    return null;
  }

  const handlePremiumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChosenSumAssured("");
    setSumMsg("");
    setSumError("");
    const newValue = event.target.value;
    setChosenPremium(newValue);
    if (!newValue) {
      if (premiumError) {
        setPremiumError("");
      }
      return;
    }
    if (!validator.isFloat(newValue)) {
      setPremiumError("Please enter a valid decimal number.");
      return;
    }
    const value = Number(newValue);
    if (value < premiumMinMax.min) {
      setPremiumError(`Your minimum premium is ${GBP}${premiumMinMax.min}.`);
      return;
    }
    if (value > premiumMinMax.max) {
      setPremiumError(`Your maximum premium is ${GBP}${premiumMinMax.max}.`);
      return;
    }
    setPremiumMsg("");
    setPremiumError("");
    setIsDirty(true);
    changeHandler(field, undefined);
  };

  const handleSumChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChosenPremium("");
    setPremiumMsg("");
    setPremiumError("");
    const newValue = event.target.value;
    setChosenSumAssured(newValue);
    if (!newValue) {
      if (sumError) {
        setSumError("");
      }
      return;
    }
    if (!validator.isFloat(newValue)) {
      setSumError("Please enter a valid decimal number.");
      return;
    }
    if (Number(newValue) <= 0) {
      setSumError("Please enter a value larger than 0.");
      return;
    }
    setSumMsg("");
    setSumError("");
    setIsDirty(true);
    changeHandler(field, undefined);
  };

  const updateQuote = async () => {
    setBusy(true);
    const clientPremium: TClientPremium | null = await getPremium(
      age,
      isSmoker,
      Number(chosenPremium),
      Number(chosenSumAssured),
      medical,
      diagnosed12,
      diagnosed5
    );
    const newPremium = clientPremium?.Premium?.replace(",", "") || "0.00";
    const newSumAssured = clientPremium?.SumAssured?.replace(",", "") || "0.00";
    setBusy(false);
    setPremiumMsg(clientPremium?.PremiumMessage || "");
    setSumMsg(clientPremium?.SumAssuredMessage || "");
    setChosenPremium(newPremium);
    setChosenSumAssured(newSumAssured);
    if (clientPremium !== null) {
      changeHandler(field, {
        premium: newPremium,
        sumAssured: newSumAssured,
        chosen: Number(chosenPremium) > 0 ? "premium" : "sum",
      });
    }
    setIsDirty(false);
  };

  return (
    <Stack spacing={2}>
      <b>
        You have a choice of entering either the monthly premium you would like to pay, or the sum
        assured you would like to be covered for.
      </b>
      <p>Please enter one of the values in the fields below and click on the Update button.</p>
      <Stack direction="row" spacing={2}>
        <Stack sx={{ flex: 1 }}>
          <p style={{ margin: "0 auto 0 auto" }}>Monthly premium:</p>
          <TextField
            InputProps={InputProps}
            fullWidth
            helperText={
              premiumError ||
              premiumMsg ||
              `${GBP}${premiumMinMax.min} - ${GBP}${premiumMinMax.max}`
            }
            error={Boolean(premiumError)}
            onChange={handlePremiumChange}
            value={chosenPremium}
            disabled={busy || field.disabled}
          />
        </Stack>
        <Stack sx={{ flex: 1 }}>
          <p style={{ margin: "0 auto 0 auto" }}>Cash lump sum:</p>
          <TextField
            InputProps={InputProps}
            fullWidth
            helperText={sumError || sumMsg || ""}
            error={Boolean(sumError)}
            onChange={handleSumChange}
            value={chosenSumAssured}
            disabled={busy || field.disabled}
          />
        </Stack>
      </Stack>
      <Button
        variant="contained"
        style={{ width: "50%", margin: "16px auto 0 auto" }}
        disabled={field.disabled || !isDirty || busy || Boolean(premiumError) || Boolean(sumError)}
        onClick={updateQuote}
        endIcon={<PlayArrowIcon />}
      >
        Update
      </Button>
    </Stack>
  );
};
