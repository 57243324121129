import { useContext, useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stack,
  DialogContentText,
  DialogActions,
  Button,
  IconButton,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { renderFields } from '../fields';

import { IStringIndex, TFieldChangeHandler, TFormData, TFormField, TJourneyConfig, TJourneyMode } from '../../../types';
import { validateField } from '../../../fieldValidation';
import { checkDependents, initDependencies } from '../../../formHandling';
import { JourneyContext } from '../../../JourneyContext';
import { Add, ArrowBack } from '@mui/icons-material';

export const ArrayFieldDialog = (props: {
  config: TJourneyConfig;
  mode?:TJourneyMode
  fields: TFormField[];
  value: any;
  title?: string;
  label?: string;
  onApply: (value: any) => void;
  onCancel: () => void;
  extras?: any;
  validations?: any;
  fullScreen?: boolean;
}) => {
  const journeyContext = useContext(JourneyContext);
  const { title, label, value, config, fields, onCancel, onApply, extras,mode } = props;

  const [localFormData, setLocalFormData] = useState<TFormData>({
    values: { ...value, ...extras },
    validations: { ...props.validations },
  });

  useEffect(() => {
    initDependencies(fields, 'DEFAULT');
    const validations: IStringIndex<boolean> = {};
    for (let f of fields) {
      const hasCheckbox = f?.dateParams?.hasCheckbox ?? false;
      const checkboxName = `${f.name}Checked`;
      const isChecked = localFormData.values?.[checkboxName];

      const isValid = isChecked && hasCheckbox ? true :  validateField(f, localFormData.values[f.name], localFormData);
      validations[f.name] = isValid;
      checkDependents(f, fields, localFormData.values, localFormData.validations,config,mode);
    }
    setLocalFormData({ ...localFormData, validations });
  }, [fields]);

  const localChangeHandler: TFieldChangeHandler = (field: TFormField, value: any) => {
    const newFormData: TFormData = { ...localFormData };
    newFormData.values[field.name] = value;
    console.log(`Change: ${field.name} >> ${value}`, { newFormData });
    newFormData.validations[field.name] = validateField(field, value, newFormData.values);
    checkDependents(field, fields, newFormData.values, newFormData.validations,config,mode);
    setLocalFormData(newFormData);
  };

  const handleApply = () => {
    onApply(localFormData);
  };

  const handleCancel = () => {
    onCancel();
  };

  let allValid = true;
  for (let f of fields) {
    if (f.required && localFormData.validations[f.name] !== true) {
      console.log(f.name);
      allValid = false;
      break;
    }
  }

  return (
    <Dialog
      fullScreen={props.fullScreen || false}
      open={true}
      scroll="paper"
      fullWidth
      PaperProps={{ sx: { width: '100%', margin: '0px', minHeight: '550px' } }}
    >
      {title && (
        <DialogTitle borderBottom={'1px solid #EAECF0'} fontSize={30} fontWeight={600} fontFamily={'Poppins'}>
          {props?.fullScreen ? (
            <IconButton color="primary" onClick={handleCancel}>
              <ArrowBack />
            </IconButton>
          ) : (
            <Add color="primary" />
          )}
          {title}
        </DialogTitle>
      )}
      <DialogContent
        sx={
          props?.fullScreen
            ? {
                bgcolor: '#FAFAFF',
              }
            : {}
        }
        className="dialogContent"
      >
        <Stack
          marginTop={props?.fullScreen ? 5 : 0}
          spacing={3}
          paddingTop={2}
          maxWidth={props?.fullScreen ? 550 : '100%'}
          mx={'auto'}
        >
          {label && <DialogContentText>{label}</DialogContentText>}
          {/* This right here illustrates how badly this whole show needs a rewrite */}
          <JourneyContext.Provider
            value={{
              ...journeyContext,
              formData: localFormData,
              setFormData: setLocalFormData,
            }}
          >
            {renderFields(config, fields, localFormData, localChangeHandler, false, 'DEFAULT')}
          </JourneyContext.Provider>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          bgcolor: '#F9FAFB',
          padding: 2,
          borderTop: '1px solid #EAECF0',
        }}
      >
        <Button disabled={!allValid} onClick={handleApply} color="primary" variant="contained" className="dialogButton">
          Save
        </Button>
        <Button onClick={handleCancel} variant="outlined" className="dialogButton">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};
