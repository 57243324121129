export const Product = [
  {
    label: "Private Car",
    value: {
      schemeCode: "Ventura_Car_7320",
      productCode: "Private_Car",
    },
  },
  {
    label: "Motorhome",
    value: {
      schemeCode: "ERS_Motorhome_IHP_7410",
      productCode: "Motorhome",
    },
  },
  {
    label: "Van",
    value: {
      schemeCode: "ERS_Standard_Van_5656",
      productCode: "Van",
    },
  },
  {
    label: "Classic Car",
    value: {
      schemeCode: "ERS_Classic_Car_5621",
      productCode: "Classic_Car",
    },
  },
  {
    label: "Taxi",
    value: {
      schemeCode: "ERS_Taxi_5901",
      productCode: "Taxi",
    },
  },
  {
    label: "Courier",
    value: {
      schemeCode: "Courier_7368",
      productCode: "Courier",
    },
  },
];
