import { Alert, Button, Card, Stack } from "@mui/material";
import { useState } from "react";
import { getUKVehicleData } from "../../../apiCalls";
import { TVehicleDataItems } from "../../../types/UKVD/TVehicleDataItems";
import { TVehicleDataResponse } from "../../../types/UKVD/TVehicleDataResponse";
import { RegistrationInput } from "./RegistrationInput";
import { FuelType } from "../../../codeLists/Polaris/109-FuelType";
import { TransmissionType } from "../../../codeLists/Polaris/110-TransmissionType";
import { VehicleType } from "../../../codeLists/Polaris/10-VehicleType";
import { TFieldChangeHandler, TFormField } from "../../../types";
import { TSpecAndOptionsDataItems } from "../../../types/UKVD/TSpecAndOptionsDataItems";

export const VehicleERS = (props: {
  field: TFormField;
  changeHandler: TFieldChangeHandler;
  value: any;
}) => {
  const { field, value, changeHandler } = props;
  const [busy, setBusy] = useState(false);
  const [lookupError, setLookupError] = useState("");
  const [vehicleData, setVehicleData] = useState(value);

  const handleLookupError = (results: TVehicleDataResponse | null) => {
    if (!results) {
      setLookupError(
        "There was a problem finding your vehicle's details, please wait a moment and try again."
      );
      return;
    }
    setLookupError(
      "Your vehicle could not be found. Please check your vehicle registration number and try again."
    );
  };

  const tryToMatch = (item: string | null, list: { label: string; value: string }[]) => {
    if (item === null) {
      return null;
    }
    const match = list.find((listItem) => listItem.label.toUpperCase() === item.toUpperCase());
    return match ?? null;
  };

  const mapFuelType = (fuelType: string | null) => {
    switch (fuelType?.toUpperCase()) {
      case "ELECTRICITY":
        return "Electric Only";
    }
    return fuelType;
  };

  const isHybridVehicle = (fuelType: string | null) => {
    if (fuelType === null) {
      return false;
    }
    return fuelType.toUpperCase() === "HYBRID ELECTRIC";
  };

  const mapVehicleDataItems = (dataItems: TVehicleDataItems) => {
    return {
      VIN: dataItems.VehicleRegistration.Vin,
      make: dataItems.VehicleRegistration.Make,
      model: dataItems.VehicleRegistration.Model,
      year: dataItems.VehicleRegistration.YearOfManufacture,
      fuel: tryToMatch(mapFuelType(dataItems.VehicleRegistration.FuelType), FuelType),
      engine: dataItems.VehicleRegistration.EngineCapacity,
      transmission: tryToMatch(dataItems.VehicleRegistration.TransmissionCode, [
        { label: "A", value: "001" },
        { label: "M", value: "002" },
      ]),
      numberOfDoors: dataItems.SmmtDetails.NumberOfDoors,
      bodyStyle: tryToMatch(dataItems.SmmtDetails.BodyStyle, VehicleType),
      vehicleClass: dataItems.VehicleRegistration.VehicleClass,
      isImported: dataItems.VehicleRegistration.Imported ? "Yes" : "No",
      isLeftHandDrive: dataItems.TechnicalDetails.General.DriverPosition !== "R" ? "Yes" : "No",
      hasAlarmOrImmobiliser: "No",
      isHybrid: isHybridVehicle(dataItems.VehicleRegistration.FuelType),
      abiCode: dataItems.VehicleRegistration.AbiBrokerNetCode,
    };
  };

  const hasAlarmOrImmobiliser = (dataItems: TSpecAndOptionsDataItems) => {
    const hasAlarm = dataItems.FactoryEquipmentList.find(
      (item) => item.Name?.toUpperCase() === "ALARM"
    );
    const hasImmobiliser = dataItems.FactoryEquipmentList.find(
      (item) => item.Name?.toUpperCase() === "IMMOBILISER"
    );
    return hasAlarm || hasImmobiliser ? "Yes" : "No";
  };

  const vehicleSearch = async (VRM: string) => {
    changeHandler(field, undefined);
    setLookupError("");
    setBusy(true);
    // Get standard vehicle data first
    const vehicleDataResults = await getUKVehicleData(VRM, "VehicleData");
    let vehicleData = { VRM };
    if (vehicleDataResults && vehicleDataResults.Response.StatusCode === "Success") {
      const vehicleDataItems = vehicleDataResults.Response.DataItems as TVehicleDataItems;
      vehicleData = {
        VRM,
        ...mapVehicleDataItems(vehicleDataItems),
      };
    } else {
      handleLookupError(vehicleDataResults);
      setBusy(false);
      return;
    }
    // Now try to get spec and options
    const specAndOptionsResults = await getUKVehicleData(VRM, "SpecAndOptionsData");
    if (specAndOptionsResults && specAndOptionsResults.Response.StatusCode === "Success") {
      const specAndOptionsDataItems = specAndOptionsResults.Response
        .DataItems as TSpecAndOptionsDataItems;
      vehicleData = {
        ...vehicleData,
        ...{ hasAlarmOrImmobiliser: hasAlarmOrImmobiliser(specAndOptionsDataItems) },
      };
    }
    changeHandler(field, vehicleData);
    setVehicleData(vehicleData);
    setBusy(false);
  };

  const renderLookupStep = () => {
    return (
      <Stack spacing={3}>
        <p>What is your vehicle's registration number?</p>
        <RegistrationInput VRM="" onSubmit={vehicleSearch} busy={busy} />
        {lookupError && (
          <Alert
            severity="error"
            sx={{ marginTop: 2, border: "1px solid rgba(0,0,0,0.5)" }}
            onClose={() => setLookupError("")}
          >
            {lookupError}
          </Alert>
        )}
      </Stack>
    );
  };

  const clearVehicleData = () => {
    setVehicleData(undefined);
    changeHandler(field, undefined);
  };

  const renderVehicleInformation = () => {
    const renderRow = (label: string, value: string) => {
      return (
        <Stack
          justifyContent="space-between"
          alignItems="start"
          sx={{ margin: "5px 0 5px 0" }}
          direction="row"
        >
          <p style={{ flex: 1 }}>{label}</p>
          <b style={{ flex: 1, textAlign: "end" }}>{value}</b>
        </Stack>
      );
    };
    return (
      <Card sx={{ padding: "16px 10px", borderRadius: 2 }}>
        <Stack spacing={3}>
          <div>
            <b>Vehicle Information</b>
            {renderRow("VRM", vehicleData.VRM)}
            {renderRow("Make", vehicleData.make)}
            {renderRow("Model", vehicleData.model)}
            {renderRow("Year", vehicleData.year)}
            {vehicleData.fuel?.label && renderRow("Fuel", vehicleData.fuel.label)}
            {vehicleData.engine && renderRow("Engine Size", vehicleData.engine)}
            {vehicleData.transmission?.label &&
              renderRow(
                "Transmission",
                vehicleData.transmission.label === "A"
                  ? "Automatic"
                  : vehicleData.transmission.label === "M"
                  ? "Manual"
                  : vehicleData.transmission.label
              )}
            {vehicleData.bodyStyle?.label && renderRow("Body Style", vehicleData.bodyStyle?.label)}
          </div>
          <Button variant="contained" fullWidth onClick={clearVehicleData}>
            Search for a different vehicle
          </Button>
        </Stack>
      </Card>
    );
  };

  if (!vehicleData) {
    // No vehicle data, render lookup
    return renderLookupStep();
  } else {
    // Show the information looked up
    return renderVehicleInformation();
  }
};
