import { createContext, useState } from 'react';
import React from 'react';

export const AppliedContext = createContext<any>({});

function AppliedContextProvider({ children,initialSelectedQuote }: {children:any,initialSelectedQuote?:any}) {
  const [selectedQuote, setSelectedQuote] = useState(initialSelectedQuote);

  const updateSelectedQuote = (data: any) => setSelectedQuote(data);
  return <AppliedContext.Provider value={{ selectedAppliedQuote:selectedQuote, updateSelectedQuote }}>{children}</AppliedContext.Provider>;
}

export default AppliedContextProvider;
