import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { BiChevronDown } from 'react-icons/bi';
import { BiChevronUp } from 'react-icons/bi';
import EditIcon from '@mui/icons-material/Edit';
import { TFormField, TJourneyConfig, TFieldChangeHandler, TFormData, IStringIndex, TJourneyMode } from '../../types';
import { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { EmbeddedJourneySection } from '../EmbeddedJourneySection';
import { getFieldOptions, replacePlaceholders } from '../../formHandling';
import { get } from 'lodash';
import { createNumberofClaimsText, calculateTotalIncurredValue } from '../../formHandling';
import { applyRule } from '../../utils/rulesEngine';
import { JourneyContext } from '../../JourneyContext';
import { GoPencil } from 'react-icons/go';
import { checkDependents } from '../../formHandling';
import { validateField } from '../../fieldValidation';
import { hexToRGBA } from '../../utils';
import { TVehicle } from './Vehicle/VehicleField';
import { GroupsFieldContext, useGroupsField } from '../../CollapsableSectionsContext';

export const SummaryField = (props: {
  field: TFormField;
  config: TJourneyConfig;
  formData: TFormData;
  changeHandler: TFieldChangeHandler;
  mode?:TJourneyMode
}) => {
  const { field, formData, config, changeHandler,mode } = props;
  console.log(field);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const theme = useTheme();
  const { activeIndex, setActiveIndex } = useContext(JourneyContext);
  const journeyContext = useContext(JourneyContext);
  const { openAccordion, groupIds } = useGroupsField();

  // The section that can get edited from this summary field
  const { summaryParams } = field;
  const editAction = summaryParams?.editAction;
  const editSection = config.sections.find((section) => section.name === summaryParams?.sectionName);

  const hidePagination = !summaryParams?.pagination;

  const maxPerView = !hidePagination ? summaryParams?.maxItems : field?.options?.length;

  const fields = field.fields ?? [];
  const localChangeHandler: TFieldChangeHandler = (field: TFormField, value: any) => {
    const localFormData = { ...formData };
    localFormData.values[field.name] = value;
    localFormData.validations[field.name] = validateField(field, value, localFormData.values);
    changeHandler(field, value);
    checkDependents(field, fields, localFormData.values, formData.validations,config,mode);
  };

  useEffect(() => {
    const currentReserve1 = formData?.values?.currentReserve1;
    const amountPaidSoFar1 = formData?.values?.AmountPaidSoFar1;
    const currentReserve2 = formData?.values?.currentReserve2;
    const amountPaidSoFar2 = formData?.values?.AmountPaidSoFar2;
    if (currentReserve1 && amountPaidSoFar1 && field.name === 'totalIncurred1') {
      const totalIncurred1 = currentReserve1 + amountPaidSoFar1;
      localChangeHandler(field, totalIncurred1);
    } else if (currentReserve2 && amountPaidSoFar2 && field.name === 'totalIncurred2') {
      const totalIncurred2 = currentReserve2 + amountPaidSoFar2;
      localChangeHandler(field, totalIncurred2);
    }
  }, [
    journeyContext?.formData?.values?.currentReserve1,
    journeyContext?.formData?.values?.currentReserve2,
    journeyContext?.formData?.values?.AmountPaidSoFar1,
    journeyContext?.formData?.values?.AmountPaidSoFar2,
  ]);

  // Ugly mess courtesy of ERS.
  // One day, when time allows, I will rewrite this.
  const parents = summaryParams?.parent;
  const renderSummaryArray = (parent?: string | string[]) => {
    const multipleParents = Array.isArray(parent);
    const combinedItems: any = [];
    if (multipleParents) {
      parent?.forEach((p) => {
        const items = get(formData.values, p);
        combinedItems.push(items);
      });
    }

    const items = multipleParents ? combineParentArrayItems(combinedItems) : get(formData.values, parent!);
    if (!items) {
      return null;
    }

    const elements = [];
    if (Array.isArray(items)) {
      if (items.length > 1) {
        elements.push(<Divider key="divider" sx={{ paddingBottom: 2 }} />);
      }
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        elements.push(
          <div key={`item-${i}`}>
            <SummaryItem
              itemIndex={i}
              globalFormData={props.formData}
              itemKey={`item-${i}`}
              formData={{
                values: item,
                validations: formData.validations,
              }}
              field={field}
              getFormattedValue={getFormattedValue}
            />
            {i < items.length - 1 && <Divider sx={{ paddingBottom: 2 }} />}
          </div>,
        );
      }
    }
    return elements;
  };

  const getFormattedValue = (fieldValue: string, values = formData.values) => {
    const matched = fieldValue.match(/\$\{[^}]+}/g);
    if (!matched) {
      return fieldValue;
    }
    let resultString = fieldValue;

    matches: for (let m of matched) {
      const fieldName = m.substring(2, m.length - 1);
      const matchingValue = fieldName.includes('||') ? replacePlaceholders(values, m) : values[fieldName];
      let matchingField = null;
      for (let s of config.sections) {
        for (let f of s.fields) {
          if (f.fields) {
            for (let xyzzy of f.fields) {
              if (xyzzy.name === fieldName) {
                matchingField = xyzzy;
                break;
              }
            }
          } else {
            if (f.name === fieldName) {
              matchingField = f;
              break;
            }
          }
        }
      }
      // This will have to do for now
      if (matchingField && typeof matchingValue === 'boolean') {
        resultString = resultString.replace(m, matchingValue === true ? 'Yes' : 'No');
        continue matches;
      }

      if (matchingField && typeof matchingValue === 'object' && Array.isArray(matchingValue)) {
        resultString = resultString.replace(m, matchingValue.length + '');
        continue matches;
      }

      // Is this backwards compatible with all journeys?
      if (matchingField && (matchingField.options || matchingField.dataSource)) {
        const matchingOptions = getFieldOptions(matchingField, formData);
        for (let o of matchingOptions) {
          if (o.value === matchingValue) {
            resultString = resultString.replace(m, o.label);
            continue matches;
          }
        }
      }

      if (
        matchingValue !== undefined &&
        matchingField &&
        (matchingField.variant === 'money' ||
          (matchingField.type === 'currency' &&
            (matchingField?.params?.adornment?.start === '£' || matchingField?.params?.adornment?.end === '£')))
      ) {
        if (isNaN(Number(matchingValue))) {
          resultString = resultString.replace(m, matchingValue);
        } else {
          resultString = resultString.replace(m, '£' + Number(matchingValue).toLocaleString('en-GB'));
        }
        continue matches;
      }

      if (matchingValue !== undefined) {
        resultString = resultString.replace(m, matchingValue);
      } else {
        const v = get(values, fieldName) ?? m;
        resultString = resultString.replace(m, v);
      }
    }
    return resultString.match(/\$\{[^}]+}/g) ? 'N/A' : resultString;
  };

  const getFormattedFieldValue = (fieldValue: string, values = formData.values) => {
    const matched = fieldValue.match(/\$\{[^}]+}/g);
    if (!matched) {
      return fieldValue;
    }
    let resultString = fieldValue;

    matches: for (let m of matched) {
      const fieldName = m.substring(2, m.length - 1);
      const matchingValue = fieldName.includes('||') ? replacePlaceholders(values, m) : values[fieldName];
      let matchingField = null;
      for (let s of config.sections) {
        for (let f of s.fields) {
          if (f.fields) {
            for (let xyzzy of f.fields) {
              if (xyzzy.name === fieldName) {
                matchingField = xyzzy;
                break;
              }
            }
          } else {
            if (f.name === fieldName) {
              matchingField = f;
              break;
            }
          }
        }
      }

      return matchingValue;
    }
  };

  const renderSummaryItems = (formData: any, key: any = Math.random()) => {
    const isVehicleField = summaryParams?.type === 'vehicle';

    const options = createNumberofClaimsText(formData?.values?.period || '12', getFieldOptions(field, formData));
    const newOptions = calculateTotalIncurredValue(formData?.values, options);
    const visibleItems = showAll ? newOptions : newOptions?.slice(0, maxPerView);
    return visibleItems?.map((option: any, idx: number) => {
      return option.disabled ? null : config?.renderVersion === 'V2' ? (
        isVehicleField ? (
          <Card sx={{ borderRadius: 2, boxShadow: 'none', bgcolor: 'white', marginTop: 2 }}>
            <Box height="56px" display="flex" marginBottom="16px">
              <Box bgcolor={theme.palette.primary.main} width="16px" height="100%" borderRadius={'8px 0px 0px 8px'} />
              <Box
                display={'flex'}
                width="100%"
                alignItems={'center'}
                bgcolor={hexToRGBA(theme.palette.primary.main, 0.05)}
                borderLeft="0px"
                borderRadius={'0px 8px 8px 0px'}
                height="100%"
              >
                <Typography fontFamily={'Roboto Condensed'} fontSize={'20px'} fontWeight="700" paddingLeft="24px">
                  {getFormattedFieldValue(option.value)?.registration_number}
                </Typography>
              </Box>
            </Box>
            <Stack spacing={1} marginBottom="16px">
              <Typography fontSize={14} fontWeight={700} fontFamily={'Inter'} color={'#464C5E'}>
                {getFormattedFieldValue(option.value)?.vehicle_make_model}
              </Typography>
              {v2VehicleData(getFormattedFieldValue(option.value)).map((item) => (
                <Stack
                  direction={'row'}
                  justifyContent={'space-between'}
                  alignItems={'start'}
                  fontSize={14}
                  fontFamily={'Inter'}
                  marginTop={1}
                  color={'#464C5E'}
                >
                  <Typography>{item.label}</Typography>
                  <Typography textTransform={'uppercase'} fontWeight={600}>
                    {item.value}
                  </Typography>
                </Stack>
              ))}
            </Stack>
          </Card>
        ) : (
          <Stack
            direction={'row'}
            justifyContent={'space-between'}
            alignItems={'start'}
            fontSize={14}
            fontFamily={'Inter'}
            marginTop={1}
            color={'#464C5E'}
          >
            <Typography maxWidth={'50%'}>
              {replacePlaceholders(formData.values, option.label, true, idx + 1)}
            </Typography>
            <Typography noWrap fontWeight={700}>
              {getFormattedValue(option.value, formData.values)}
            </Typography>
          </Stack>
        )
      ) : (
        <Stack
          direction={'row'}
          justifyContent="space-between"
          alignItems="start"
          className="SummaryField"
          sx={{ margin: '5px 0 5px 0' }}
          key={key + 'option' + idx + '-' + option.label + option.value}
        >
          <p style={{ flex: 1 }}>{replacePlaceholders(formData.values, option.label, true, idx + 1)}</p>
          {option?.value && (
            <b style={{ flex: 1, textAlign: 'end' }}>{getFormattedValue(option.value, formData.values)}</b>
          )}
        </Stack>
      );
    });
  };

  const openEditDialog = () => {
    if (editAction && editAction?.goToSection) {
      const sectionIndex = config.sections.findIndex((s) => s.name === editAction.goToSection);
      const targetField = editAction?.targetField;
      if (targetField && groupIds) {
        const index = groupIds?.indexOf(targetField);
        console.log({targetField,index})
        if (index !== undefined && index > -1) {
          openAccordion(groupIds[index]);
        }
      }
      setActiveIndex(sectionIndex);
    } else {
      setEditDialogOpen(true);
    }
  };

  const closeEditDialog = () => {
    setEditDialogOpen(false);
  };

  const renderEditDialog = () => {
    if (!editSection) {
      return null;
    }

    const handleSuccess = () => {
      closeEditDialog();
    };

    return (
      <Dialog open={editDialogOpen} scroll="paper" fullWidth PaperProps={{ sx: { width: '100%', margin: '0px' } }}>
        <DialogContent className="dialogContent">
          <EmbeddedJourneySection
            section={{ ...editSection, heading: 'Adjust your cover' }}
            config={config}
            onCancel={closeEditDialog}
            onSuccess={handleSuccess}
          />
        </DialogContent>
      </Dialog>
    );
  };

  if (config?.renderVersion === 'V2') {
    return (
      <Stack>
        <Box
          className="summaryField"
          style={{
            backgroundColor: field?.variant === 'branded' ? hexToRGBA(theme.palette.primary.main, 0.03) : 'white',
            padding: 12,
            borderRadius: 12,
            marginTop: 16,
            border: `2px solid ${field?.variant === 'branded' ? hexToRGBA(theme.palette.primary.main, 0.1) : hexToRGBA(theme.palette.primary.main, 0.2)}`,
          }}
        >
          <Stack direction="row" justifyContent="space-between" alignItems="center" className="SummaryField">
            <Typography fontSize={14} fontWeight={700} fontFamily={'Inter'} color={'#464C5E'}>
              {field.title}
            </Typography>
            {(editSection || editAction) && (
              <IconButton color="primary" component="span" onClick={openEditDialog} disabled={field.disabled}>
                <GoPencil size={20} />
              </IconButton>
            )}
          </Stack>
          {parents ? <Stack spacing={2}>{renderSummaryArray(parents)}</Stack> : renderSummaryItems(formData)}
          {renderEditDialog()}
          {!hidePagination && maxPerView && field?.options && field?.options?.length > maxPerView && (
            <Button
              variant="text"
              style={{
                textTransform: 'capitalize',
              }}
              startIcon={showAll ? <BiChevronUp /> : <BiChevronDown />}
              onClick={() => setShowAll(!showAll)}
              color="primary"
            >
              {showAll ? 'Show Less' : 'Show More'}
            </Button>
          )}
        </Box>
      </Stack>
    );
  }

  return (
    <Stack>
      <Box
        className="summaryField"
        style={{
          backgroundColor: 'white',
          padding: 12,
          borderRadius: 16,
          marginTop: 16,
          border: '1px solid rgba(0,0,0,0.25)',
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" className="SummaryField">
          <Typography variant="h4" className="summaryFieldTitle">
            {field.title}
          </Typography>
          {(editSection || editAction) && (
            <IconButton color="primary" component="span" onClick={openEditDialog} disabled={field.disabled}>
              <GoPencil size={20} />
            </IconButton>
          )}
        </Stack>
        {parents ? <Stack spacing={2}>{renderSummaryArray(parents)}</Stack> : renderSummaryItems(formData)}
        {renderEditDialog()}
        {!hidePagination && maxPerView && field?.options && field?.options?.length > maxPerView && (
          <Button
            variant="text"
            style={{
              textTransform: 'capitalize',
            }}
            startIcon={showAll ? <BiChevronUp /> : <BiChevronDown />}
            onClick={() => setShowAll(!showAll)}
            color="primary"
          >
            {showAll ? 'Show Less' : 'Show More'}
          </Button>
        )}
      </Box>
    </Stack>
  );
};

/**
 * Combines multiple parent items represented as an array of arrays, where each inner array
 * represents objects with key-value pairs. The function merges key-value pairs with the same
 * index in each inner array, effectively combining the parent items into a single array of objects.
 *
 * @param {any[]} items - An array of arrays containing parent items to combine.
 * @returns {any[]} An array of combined parent items.
 */
function combineParentArrayItems(items: any): any[] {
  if (!items || !items.length) {
    return [];
  }

  try {
    return items[0]?.map((_: any, index: number) =>
      items.reduce((combinedObject: any, array: any[]) => Object.assign(combinedObject, array[index]), {}),
    );
  } catch (error) {
    return [];
  }
}

const SummaryItem = ({
  field,
  getFormattedValue,
  formData,
  globalFormData,
  itemKey = Math.random(),
  itemIndex,
}: {
  field: TFormField;
  formData: TFormData;
  globalFormData: TFormData;
  itemKey: any;
  itemIndex: number;
  getFormattedValue: (fieldValue: string, values?: IStringIndex<any>) => string;
}) => {
  const [localOptions, setLocalOptions] = useState<any>(
    createNumberofClaimsText(formData?.values?.period || '12', getFieldOptions(field, formData)),
  );

  useLayoutEffect(() => {
    (async () => {
      const updatedOptions = await Promise.all(
        localOptions?.map(async (option: any) => {
          if (option?.rules) {
            const res = await applyRule(option.rules, globalFormData.values);
            if (res && res.conditionMet) {
              option.disabled = false;
            } else {
              option.disabled = true;
            }
          }
          return option;
        }),
      );

      setLocalOptions(updatedOptions);
    })();
  }, [globalFormData.values]);

  return (
    <>
      {localOptions?.map((option: any, idx: number) => {
        return option.disabled ? null : (
          <Stack
            direction={'row'}
            justifyContent="space-between"
            alignItems="start"
            className="SummaryField"
            sx={{ margin: '5px 0 5px 0' }}
            key={itemKey + 'option' + idx + '-' + option.label + option.value}
          >
            <p style={{ flex: 1 }}>{replacePlaceholders(formData.values, option.label, true, itemIndex + 1)}</p>
            {option?.value && (
              <b style={{ flex: 1, textAlign: 'end' }}>{getFormattedValue(option.value, formData.values)}</b>
            )}
          </Stack>
        );
      })}
    </>
  );
};

const v2VehicleData = (vehicle: TVehicle) => [
  { label: 'Body Style', value: vehicle?.num_of_doors },
  { label: 'Year', value: vehicle?.vehicle_year },
  { label: 'Transmission', value: vehicle?.transmission },
  { label: 'Fuel type', value: vehicle?.fuel_type },
  { label: 'Engine Size', value: vehicle?.engineCC },
];
