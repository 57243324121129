export const ConvictionType = [
  {
    "label": "Absconding",
    "value": "22"
  },
  {
    "label": "Abusive Words Or Behaviour",
    "value": "23"
  },
  {
    "label": "Actual Bodily Harm (ABH)",
    "value": "24"
  },
  {
    "label": "Administering Noxious Substances To Commit Sexual Act",
    "value": "25"
  },
  {
    "label": "Affray",
    "value": "26"
  },
  {
    "label": "Aggravated Vehicle Taking",
    "value": "27"
  },
  {
    "label": "Air Gun In A Public Place",
    "value": "28"
  },
  {
    "label": "Animals (Cruelty To)",
    "value": "29"
  },
  {
    "label": "Anti Social Behaviour Order - Breach",
    "value": "30"
  },
  {
    "label": "Any Criminal Injury Related",
    "value": "06"
  },
  {
    "label": "Arson",
    "value": "01"
  },
  {
    "label": "Arson - With Intent To Endanger Life",
    "value": "31"
  },
  {
    "label": "Article With Blade Or Point In A Public Place",
    "value": "32"
  },
  {
    "label": "Assaulting A Police Officer",
    "value": "33"
  },
  {
    "label": "Avoiding Customs Duty",
    "value": "34"
  },
  {
    "label": "Battery",
    "value": "35"
  },
  {
    "label": "Black Mail",
    "value": "36"
  },
  {
    "label": "Bomb Hoax",
    "value": "37"
  },
  {
    "label": "Breach Of Consumer Credit Legislation",
    "value": "16"
  },
  {
    "label": "Breach Of Consumer Protection Legislation",
    "value": "17"
  },
  {
    "label": "Breach Of Environmental Legislation",
    "value": "13"
  },
  {
    "label": "Breach Of Factories Act",
    "value": "07"
  },
  {
    "label": "Breach Of Health And Safety Legislation",
    "value": "08"
  },
  {
    "label": "Breach Of Quality Or Safety Of Goods Legislation",
    "value": "15"
  },
  {
    "label": "Breach Of The Peace",
    "value": "38"
  },
  {
    "label": "Burglary",
    "value": "09"
  },
  {
    "label": "Burglary - Aggravated",
    "value": "39"
  },
  {
    "label": "Burglary - Going Equipped",
    "value": "40"
  },
  {
    "label": "Children - Cruelty",
    "value": "41"
  },
  {
    "label": "Common Assault",
    "value": "42"
  },
  {
    "label": "Computer -Modifying Equipment Without Authorisation",
    "value": "43"
  },
  {
    "label": "Conspiracy To Defraud",
    "value": "44"
  },
  {
    "label": "Conspiracy To Import/ Export Drugs",
    "value": "45"
  },
  {
    "label": "Conspiracy To Steal",
    "value": "46"
  },
  {
    "label": "Contempt Of Court",
    "value": "47"
  },
  {
    "label": "Criminal Damage",
    "value": "11"
  },
  {
    "label": "Culpable & Reckless Conduct",
    "value": "48"
  },
  {
    "label": "Cultivating Cannabis",
    "value": "49"
  },
  {
    "label": "Death By Dangerous /Reckless Driving",
    "value": "50"
  },
  {
    "label": "Deception",
    "value": "51"
  },
  {
    "label": "Dishonesty Related",
    "value": "10"
  },
  {
    "label": "Domestic Assault",
    "value": "104"
  },
  {
    "label": "Drug Related",
    "value": "18"
  },
  {
    "label": "Drunk & Disorderly",
    "value": "52"
  },
  {
    "label": "DSS Offences",
    "value": "53"
  },
  {
    "label": "Escape From Prison/Custody",
    "value": "54"
  },
  {
    "label": "False Accounting",
    "value": "55"
  },
  {
    "label": "Forgery",
    "value": "02"
  },
  {
    "label": "Fraud",
    "value": "03"
  },
  {
    "label": "Fraud - Insurance",
    "value": "57"
  },
  {
    "label": "Grievous Bodily Harm (GBH)",
    "value": "58"
  },
  {
    "label": "Grievous Bodily Harm With Intent To Harm",
    "value": "59"
  },
  {
    "label": "Gross Indecency",
    "value": "60"
  },
  {
    "label": "Handling Stolen Goods",
    "value": "61"
  },
  {
    "label": "Harassment",
    "value": "63"
  },
  {
    "label": "Harbouring An Escaped Criminal",
    "value": "62"
  },
  {
    "label": "Illegal Importation Of Immigrants",
    "value": "64"
  },
  {
    "label": "Images Of a Minor From The Internet -Downloading",
    "value": "65"
  },
  {
    "label": "Images Of a Minor On The Internet Viewing",
    "value": "66"
  },
  {
    "label": "Import/Export Drugs",
    "value": "67"
  },
  {
    "label": "Incest Involving A Child",
    "value": "68"
  },
  {
    "label": "Incitement To Racial Hatred",
    "value": "69"
  },
  {
    "label": "Indecent Assault",
    "value": "70"
  },
  {
    "label": "Indecent Assault On A Minor",
    "value": "71"
  },
  {
    "label": "Indecent Exposure",
    "value": "72"
  },
  {
    "label": "Insider Trading",
    "value": "73"
  },
  {
    "label": "Kerb Crawling",
    "value": "74"
  },
  {
    "label": "Kidnapping",
    "value": "75"
  },
  {
    "label": "Living On Immoral Earnings",
    "value": "76"
  },
  {
    "label": "Manslaughter",
    "value": "77"
  },
  {
    "label": "Motoring Offence",
    "value": "19"
  },
  {
    "label": "Murder",
    "value": "78"
  },
  {
    "label": "Murder -Threat To",
    "value": "79"
  },
  {
    "label": "Non Endorsable Criminal Motoring Offence (NE99)",
    "value": "21"
  },
  {
    "label": "Obstructing A Police Officer",
    "value": "80"
  },
  {
    "label": "Perjury",
    "value": "81"
  },
  {
    "label": "Perverting The Course Of Justice",
    "value": "82"
  },
  {
    "label": "Possession Of a Class A Drug",
    "value": "83"
  },
  {
    "label": "Possession Of a Class B Drug",
    "value": "84"
  },
  {
    "label": "Possession Of a Class C Drug",
    "value": "85"
  },
  {
    "label": "Possession Of An Offensive Weapon",
    "value": "86"
  },
  {
    "label": "Possession Of Firearms/ Explosive",
    "value": "87"
  },
  {
    "label": "Possession With Intent To Supply a Class A Drug",
    "value": "88"
  },
  {
    "label": "Possession With Intent To Supply a Class B Drug",
    "value": "89"
  },
  {
    "label": "Possession With Intent To Supply a Class C Drug",
    "value": "90"
  },
  {
    "label": "Procuring The Services Of A Prostitute",
    "value": "91"
  },
  {
    "label": "Prostitution",
    "value": "92"
  },
  {
    "label": "Rape",
    "value": "93"
  },
  {
    "label": "Receiving Stolen Goods",
    "value": "04"
  },
  {
    "label": "Robbery",
    "value": "12"
  },
  {
    "label": "Robbery - Armed",
    "value": "94"
  },
  {
    "label": "Shoplifting",
    "value": "105"
  },
  {
    "label": "Terrorism",
    "value": "95"
  },
  {
    "label": "Theft",
    "value": "05"
  },
  {
    "label": "Theft From Employer",
    "value": "96"
  },
  {
    "label": "Threatening Behaviour",
    "value": "97"
  },
  {
    "label": "Trespassing",
    "value": "98"
  },
  {
    "label": "Voyeurism",
    "value": "100"
  },
  {
    "label": "Wounding",
    "value": "102"
  },
  {
    "label": "Wounding A Police Officer",
    "value": "101"
  },
  {
    "label": "Wounding With Intent",
    "value": "103"
  },
  {
    "label": "ZZ - Not Covered By Any Other Item On The List",
    "value": "99"
  }
]