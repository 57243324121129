// =================================================================================================
// Field for entering currency values
// =================================================================================================

import { Checkbox, FormControlLabel, InputAdornment, TextField, TextField as TextFieldMUI } from '@mui/material';
import React, { useState } from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import validator from 'validator';
import { IStringIndex, TFieldChangeHandler, TFormData, TFormField, TValidationResult } from '../../types';
import { useEffect } from 'react';
import { getNumberValue, isOperation } from '../../operations';
import { replacePlaceholders } from '../../formHandling';

// =================================================================================================
// Validation
// =================================================================================================

const getMinimumValue = (field: TFormField, formValues: IStringIndex<any>) => {
  return getNumberValue(field, field.params?.min, formValues);
};

const getMaximumValue = (field: TFormField, formValues: IStringIndex<any>) => {
  return getNumberValue(field, field.params?.max, formValues);
};

export const doCurrencyFieldValidation = (
  field: TFormField,
  value: string,
  formValues: IStringIndex<any>,
): TValidationResult => {
  if (!value || value.length === 0) {
    // Required fields cannot be blank
    if (field.required) {
      return { valid: false, errorMsg: 'This field is required.' };
    } else {
      return { valid: true };
    }
  } else {
    // Is the value valid for the variant?
    if (field.variant === 'float') {
      if (!validator.isFloat(value)) {
        return { valid: false, errorMsg: 'Please enter a valid amount.' };
      }
    } else {
      if (!validator.isInt(value)) {
        return { valid: false, errorMsg: 'Please enter a valid integer amount.' };
      }
    }
    // If we got this far, we can work with the number value
    const numberValue = Number(value);
    const min = getMinimumValue(field, formValues);
    const max = getMaximumValue(field, formValues);
    // Enforce range (both minimum and maximum values)
    if (min !== undefined && max !== undefined && (numberValue > max || numberValue < min)) {
      return {
        valid: false,
        errorMsg: `Please enter an amount from £${min} to £${max}`,
      };
    }
    // Enforce maximum value
    if (max !== undefined && numberValue > max) {
      return {
        valid: false,
        errorMsg: `Amount cannot be greater than £${max}`,
      };
    }
    // Enforce minimum value
    if (min !== undefined && numberValue < min) {
      return {
        valid: false,
        errorMsg: `Amount cannot be smaller than £${min}`,
      };
    }
    // This number is perfectly fine. Probably.
    return { valid: true };
  }
};

// =================================================================================================
// Main component
// =================================================================================================

export const CurrencyField = (props: {
  field: TFormField;
  value: any;
  changeHandler: TFieldChangeHandler;
  formData: TFormData;
}) => {
  // -----------------------------------------------------------------------------------------------
  // Component state
  // -----------------------------------------------------------------------------------------------

  const { field, changeHandler, formData } = props;
  const [value, setValue] = useState(props.value);
  const [validationError, setValidationError] = useState<string | undefined>();
  const fieldChecked = formData?.values?.[`${field?.name}Checked`];
  const [fieldDisabled, setfieldDisabled] = useState(fieldChecked || field.disabled);
  const [checked, setChecked] = useState(fieldChecked);

  // keeps the value formatted when returning to the section
  useEffect(() => {
    if (value && field.variant === 'float') {
      // const formatted = formatToMoney(field, String(value));
      setValue(Number(value).toFixed(2));
    }
  }, []);

  useEffect(() => {
    // If min/max depends on some kind of operation, a change in another field
    // could mean that this field is suddenly valid or invalid
    if (
      value &&
      (typeof field.params?.max === 'string' ||
        typeof field.params?.min === 'string' ||
        isOperation(field.params?.max) ||
        isOperation(field.params?.min))
    ) {
      const validation = doCurrencyFieldValidation(field, String(value), formData.values);
      setValidationError(validation.errorMsg);
      if (!validation.valid && formData.values[field.name] !== undefined) {
        changeHandler(field, undefined);
      }
      if (validation.valid && formData.values[field.name] !== Number(value)) {
        changeHandler(field, Number(value));
      }
    }
  }, [formData.values, field, value, changeHandler]);

  // -----------------------------------------------------------------------------------------------
  // Component change handler
  // -----------------------------------------------------------------------------------------------

  const localChangeHandler = (newValue: string) => {
    setValue(newValue);
    const validation = doCurrencyFieldValidation(field, newValue, formData.values);
    if (validation.valid) {
      changeHandler(field, Number(newValue));
      if (validationError?.length) {
        setValidationError(undefined);
      }
    } else {
      setValidationError(validation.errorMsg);
      changeHandler(field, undefined);
    }
  };

  const handleCheckboxChange = (e: any) => {
    const value = e.target.checked;
    setChecked(value);
    if (value) {
      const checkedValue = field?.params?.checkboxProperties?.valueWhenChecked;
      const valueWhenChecked =
        typeof checkedValue === 'string' && checkedValue?.startsWith('${')
          ? replacePlaceholders(props.formData.values, checkedValue)
          : checkedValue;

      console.log({ valueWhenChecked });
      changeHandler(field, valueWhenChecked ?? 'N/A');
      changeHandler({ name: `${field.name}Checked` } as any, value);
      setValue(null);
      setfieldDisabled(true);
    } else {
      setfieldDisabled(field.disabled);
      changeHandler(field, undefined);
      changeHandler({ name: `${field.name}Checked` } as any, value);
    }
  };

  // -----------------------------------------------------------------------------------------------
  // Main render
  // -----------------------------------------------------------------------------------------------

  return (
    <>
      {field?.params?.hasCheckbox && (
        <FormControlLabel
          sx={{
            color: '#565E73',
            fontSize: 14,
            fontWeight: 500,
          }}
          control={
            <Checkbox size="small" color="primary" onChange={handleCheckboxChange} checked={checked} value={checked} />
          }
          label={field?.params?.checkboxProperties?.label}
        />
      )}
      <TextField
        autoComplete="autocomplete_off_randString"
        disabled={fieldDisabled}
        fullWidth
        label={field?.title}
        value={value}
        variant="outlined"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          localChangeHandler(event.target.value);
        }}
        name="numberformat"
        id="formatted-numberformat-input"
        InputProps={{
          startAdornment: field?.params?.adornment?.start && (
            <InputAdornment position="start">{field?.params?.adornment?.start}</InputAdornment>
          ),
          endAdornment: field?.params?.adornment?.end && (
            <InputAdornment position="end">{field?.params?.adornment?.end}</InputAdornment>
          ),
          inputComponent: NumericFormatCustom as any,
          inputProps: { clear: checked },
        }}
        helperText={validationError || ''}
        error={Boolean(validationError)}
      />
    </>
  );

  // return (
  //   <TextFieldMUI
  //     disabled={field.disabled}
  //     fullWidth
  //     label={field.title}
  //     variant="outlined"
  //     onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
  //       localChangeHandler(event.target.value);
  //     }}
  //     InputProps={{
  //       startAdornment: <InputAdornment position="start">£</InputAdornment>,
  //     }}
  //     onBlur={field.variant === 'float' ? (e: any) => handleFormatValue(field, e) : undefined}
  //     required={field.required}
  //     error={Boolean(validationError)}
  //     helperText={validationError || ''}
  //     value={value || ''}
  //   />
  // );
};

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  clear: boolean;
}
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, clear, ...other } = props;

  return (
    <NumericFormat
      {...other}
      autoComplete="autocomplete_off_randString"
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    />
  );
});

// const formatToMoney = (field: TFormField, number: any) => {
//   const unformatted = Number(unformat(field, number));
//   return new Intl.NumberFormat("en-UK", {
//     minimumFractionDigits: 2,
//     maximumFractionDigits: 2,
//   }).format(unformatted);
// };

// function unformat(field: TFormField, newValue: string) {
//   return newValue
//     ? parseFloat(newValue.replace(/,/g, "")).toFixed(field.variant === "float" ? 2 : 0)
//     : newValue;
// }
