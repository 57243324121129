import SearchIcon from '@mui/icons-material/Search';
import { Alert, AlertTitle, Button, Stack, TextField } from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { vehicleSearch } from '../../../apiCalls';
import {
  TAlert,
  TBlacklistItem,
  TFieldChangeHandler,
  TFormField,
  TJourneyConfig,
  TVehicleParams,
} from '../../../types';
import { VehicleDetails } from './VehicleDetails';

export type TVehicle = {
  vehicle_make?: string;
  vehicle_range?: string;
  vehicle_model?: string;
  vehicle_year?: string;
  num_of_doors?: string;
  num_of_seats?: string;
  body?: string;
  vehicle_colour?: string;
  transmission?: string;
  fuel_type?: string;
  engineCC?: string;
  vehicle_trim?: string;
  registration_date?: string;
  registration_year?: string;
  registration_number?: string;
  age?: string;
  vehicle_make_model?: string;
  manufactureYear?: string;
  driverPosition?: string;
  abiBrokerNetCode?: string;
};

enum FLOW {
  CHOOSE = 0,
  SEARCHING,
  MANUAL_ENTRY,
  COMPLETE,
}

const getVehicleAge = (vehicle_year?: string) => {
  if (!vehicle_year) {
    return undefined;
  }
  const match = vehicle_year!.match(/(19|20)\d{2}/g);
  const vehicleYear = match && match.length === 1 ? match[0] : undefined;
  const vehicleAge = vehicleYear ? moment().diff(vehicleYear, 'years') : undefined;
  if (vehicleAge === 0) {
    return 1;
  }
  return vehicleAge;
};

export const isBlacklisted = (vehicle: TVehicle, blacklist: TBlacklistItem[]) => {
  for (let item of blacklist) {
    if (item.make.toLowerCase() === vehicle.vehicle_make?.toLowerCase()) {
      // Empty "models" array means all models are blacklisted for this make
      if (item.models.length === 0) {
        console.log(`%cBlacklisted make "${item.make}"`, 'color: orange');
        return true;
      }
      // Pattern match on make/model
      for (let model of item.models) {
        const r =
          item.make.toUpperCase() === 'VOLKSWAGEN'
            ? RegExp(`\\bR\\b(?!-| LINE)`, 'i')
            : new RegExp(`\\b${model}\\b`, 'i');
        if (r.test(vehicle.vehicle_make_model ?? 'UNKNOWN')) {
          console.log(`%cBlacklisted make "${item.make}" model "${model}"`, 'color: orange');
          return true;
        }
      }
    }
  }
  return false;
};

export const doVehicleFieldValidation = (vehicle: TVehicle, vehicleParams?: TVehicleParams) => {
  const allCompleted =
    vehicle.vehicle_make &&
    vehicle.vehicle_model &&
    vehicle.vehicle_year &&
    vehicle.num_of_doors &&
    // vehicle.transmission &&
    vehicle.fuel_type &&
    vehicle.registration_date;

  if (!allCompleted) {
    return false;
  }

  const vehicleAge = getVehicleAge(vehicle.vehicle_year);
  const regAge = vehicle.registration_date
    ? moment().diff(moment(vehicle.registration_date, 'DD-MM-YYYY'), 'years')
    : undefined;

  // Enforce maximum vehicle age
  if (vehicleAge !== undefined && vehicleParams?.maxModelAge !== undefined && vehicleAge > vehicleParams.maxModelAge) {
    return false;
  }

  // Enforce maximum registration age
  if (
    regAge !== undefined &&
    vehicleParams?.maxRegistrationAge !== undefined &&
    regAge > vehicleParams.maxRegistrationAge
  ) {
    return false;
  }

  // Enforce registration date must be younger than vehicle age
  if (regAge !== undefined && vehicleAge !== undefined && regAge > vehicleAge) {
    return false;
  }

  // Enforce vehicle cannot be blacklisted
  if (vehicleParams?.blacklist) {
    return !isBlacklisted(vehicle, vehicleParams.blacklist);
  }

  // Must be fine, surely?
  return true;
};

export const VehicleField = (props: {
  field: TFormField;
  value: any;
  config: TJourneyConfig;
  changeHandler: TFieldChangeHandler;
}) => {
  const { field, changeHandler, config } = props;
  const [flow, setFlow] = useState<FLOW>(props.value ? FLOW.COMPLETE : FLOW.CHOOSE);

  const [vehicle, setVehicle] = useState<TVehicle | undefined>(props.value);
  const [alert, setAlert] = useState<TAlert | undefined>();

  const vehicleSelected = (vehicle: TVehicle) => {
    const vehicleAge = getVehicleAge(vehicle.vehicle_year);
    const newVehicle = {
      ...vehicle,
      registration_number: '',
      age: vehicleAge ? vehicleAge + '' : undefined,
    };
    setVehicle(newVehicle);
    changeHandler(field, newVehicle);
    setFlow(FLOW.COMPLETE);
  };

  const changeVehicle = () => {
    setVehicle(undefined);
    changeHandler(field, undefined);
    setFlow(FLOW.CHOOSE);
  };

  const doVehicleSearch = async () => {
    if (vehicle?.registration_number && vehicle.registration_number.trim().length >= 1) {
      setFlow(FLOW.SEARCHING);
      const results = await vehicleSearch(vehicle?.registration_number);
      if (typeof results === 'string') {
        setAlert({ severity: 'error', body: results });
        setFlow(FLOW.CHOOSE);
      } else {
        const vehicleAge = getVehicleAge(results.vehicle_year);
        const newVehicle = {
          ...results,
          age: vehicleAge !== undefined ? vehicleAge + '' : undefined,
        };
        setVehicle(newVehicle);
        changeHandler(field, newVehicle);
        setFlow(FLOW.COMPLETE);
      }
    }
  };

  useEffect(() => {
    if (flow !== FLOW.CHOOSE) {
      setAlert(undefined);
    }
  }, [flow]);

  const v1Component = () => {
    return (
      <>
        {/* {field.vehicleParams?.maxModelAge && (
          <p>
            To qualify for this product, your vehicle must not be older than{" "}
            {field.vehicleParams.maxModelAge} years.
          </p>
        )} */}
        <p>{field?.title ?? 'Please enter your vehicle registration'}</p>
        <Stack direction="row" spacing={3}>
          <TextField
            autoComplete="autocomplete_off_randString"
            value={vehicle?.registration_number || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setVehicle({
                registration_number: event.target.value
                  ? event.target.value.toUpperCase().replace(/[^0-9a-z]/gi, '')
                  : '',
              })
            }
            label="Registration"
            required
            fullWidth
            variant="outlined"
            inputProps={{ style: { fontWeight: 'bold' } }}
          />
          <Button
            startIcon={<SearchIcon />}
            variant="contained"
            onClick={doVehicleSearch}
            disabled={!vehicle?.registration_number || vehicle.registration_number.trim().length < 1}
          >
            Find
          </Button>
        </Stack>
      </>
    );
  };

  const v2Component = () => {
    return (
      <>
        <Stack direction="column" spacing={3}>
          <TextField
            autoComplete="autocomplete_off_randString"
            value={vehicle?.registration_number || ''}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setVehicle({
                registration_number: event.target.value
                  ? event.target.value.toUpperCase().replace(/[^0-9a-z]/gi, '')
                  : '',
              })
            }
            label="Vehicle Registration Number"
            required
            fullWidth
            variant="outlined"
            inputProps={{ style: { fontWeight: 'bold' } }}
          />
          <Button
            startIcon={<SearchIcon />}
            variant="contained"
            onClick={doVehicleSearch}
            style={{ flexShrink: 1, alignSelf: 'end', textTransform: 'none', padding: '8px 16px', borderRadius: '8px' }}
            disabled={!vehicle?.registration_number || vehicle.registration_number.trim().length < 1}
          >
            Find car
          </Button>
        </Stack>
      </>
    );
  };

  const renderRegistrationNumberInput = () => {
    return (
      <Stack spacing={3}>
        {config?.renderVersion === 'V2' ? v2Component() : v1Component()}
        {alert && (
          <Alert
            severity={alert.severity}
            sx={{ marginTop: 2, border: '1px solid rgba(0,0,0,0.5)' }}
            onClose={() => setAlert(undefined)}
          >
            {alert.title && <AlertTitle>{alert.title}</AlertTitle>}
            {alert.body}
          </Alert>
        )}
      </Stack>
    );
  };

  switch (flow) {
    case FLOW.CHOOSE:
      return (
        <Stack spacing={3}>
          {renderRegistrationNumberInput()}
          {/* 
          
          Disabling temporarily.

          <Divider sx={{ width: "100%" }}>OR</Divider>
          <Button
            onClick={() => setFlow(FLOW.MANUAL_ENTRY)}
            variant="outlined"
            style={{ textTransform: "none" }}
            disabled={field.disabled}
          >
            I don't know the registration
          </Button> */}
        </Stack>
      );
    case FLOW.SEARCHING:
      return (
        <Stack justifyContent="center" alignItems="center">
          <img src="img/loading.gif" />
        </Stack>
      );
    // case FLOW.MANUAL_ENTRY:
    //   return (
    //     <VehicleManualEntry
    //       onSelect={vehicleSelected}
    //       vehicleParams={field.vehicleParams}
    //       onAbort={() => setFlow(FLOW.CHOOSE)}
    //     />
    //   );
    case FLOW.COMPLETE:
      return (
        <VehicleDetails
          disabled={field.disabled}
          vehicleParams={field.vehicleParams}
          vehicle={vehicle!}
          onChange={changeVehicle}
          onRegistrationChange={(registrationDate) => {
            const newVehicle = {
              ...vehicle,
              registration_date: registrationDate,
            };
            setVehicle(newVehicle);
            changeHandler(field, newVehicle);
          }}
          useV2Renderer={config?.renderVersion === 'V2'}
        />
      );
  }
};
